<!--
  PACKAGE_NAME : src/pages/euc/chineseWall/policy
  FILE_NAME : exception.vue
  AUTHOR : jhcho
  DATE : 2024-06-11
  DESCRIPTION :
-->
<template>
  <div class="locker_setting_list sub_new_style01 sub_ui_box1">
    <Search
      codeKey="null"
      :customSearchList="searchType.list"
      :searchObj="searchType.obj"
      @change-search-type="$_changeSearchType"
      @onSearchClick="searchData($event)"
    >
      <template v-slot:before>
        <DxSelectBox
          v-model="searchType.customTypes.eceptGd"
          :items="getExceptionType"
          display-expr="label"
          value-expr="value"
          :styling-mode="stylingMode"
          :width="150"
          :height="30"
          @value-changed="$_changeSearchCustomType('eceptGd', $event)"
        />
      </template>
    </Search>
    <esp-dx-data-grid :data-grid="dataGrid" ref="exceptionGrid" />
  </div>
</template>
<script>
  import Search from '@/components/common/search.vue';
  import { formatDate, isEmpty, isSuccess } from '@/plugins/common-lib';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: {
      EspDxDataGrid,
      DxSelectBox,
      Search,
    },
    data() {
      return {
        stylingMode: 'outlined',
        searchType: {
          list: [
            {
              codeNm: this.$_lang('UC.WORD.USER_NO', { defaultValue: '사번' }),
              codeValue: 'tagetUser',
            },
          ],
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            eceptGd: null,
            tagetUser: null,
          },
          paramsData: null,
        },
        dataGrid: {
          excel: {
            title: this.$_lang('UC.WORD.EXCEPTION_POLICY', { defaultValue: '예외 정책' }),
          },
          refName: 'exceptionGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          height: 'calc(100vh - 225px)', // 주석처리시 100%
          apiActionNm: {},
          showActionButtons: {
            excel: true,
          },
          customEvent: {},
          isDuplicateConfigKey: false, //설정키 중복 체크
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: true, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '구분',
              i18n: 'COMPONENTS.TYPE',
              dataField: 'eceptGd',
              width: 250,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              lookup: {
                dataSource: [
                  {
                    label: 'IPT 예외 사용자',
                    value: 'CTC',
                  },
                  {
                    label: '차이니즈월',
                    value: 'CHW',
                  },
                ],
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
            {
              caption: '대상',
              i18n: 'UC.WORD.EXCEPTION_SETTING',
              dataField: 'tagetUser',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '등록일',
              i18n: 'COMPONENTS.REGISTRATION_DATE',
              dataField: 'regDt',
              width: 250,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              calculateCellValue: data => this.formatDt(data?.regDt),
            },
          ],
        },
      };
    },
    computed: {
      getExceptionType() {
        return [
          {
            label: '전체',
            value: null,
          },
          {
            label: 'IPT 예외 사용자',
            value: 'CTC',
          },
          {
            label: '차이니즈월',
            value: 'CHW',
          },
        ];
      },
    },
    methods: {
      searchData(paramsData) {
        if (paramsData) {
          this.searchType.paramsData = { ...this.searchType.paramsData, ...paramsData };
        } else {
          let codeValues = this.searchType.list.map(d => d.codeValue);
          if (!isEmpty(this.searchType.paramsData)) {
            Object.keys(this.searchType.paramsData).forEach(key => {
              if (codeValues.includes(key)) {
                delete this.searchType.paramsData[key];
              }
            });
          }
        }
        this.selectDataList();
      },
      async selectDataList(sort = '-regDt') {
        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);
            if (!params.sort) {
              params.sort = sort;
            }

            params = { ...params, ...this.searchType.paramsData };

            const payload = {
              actionName: 'EUC_EXCEP_POLICY_SELECT',
              data: params,
              loading: false,
            };

            const rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await this.CALL_EUC_API(payload);
            if (isSuccess(res)) {
              rtnData.data = res.data.data;
              rtnData.totalCount = res.data.header.totalCount;
            }
            return rtnData;
          },
        });
      },
      /** @description: 일자 데이터 출력 */
      formatDt(date) {
        if (date) {
          return formatDate(date, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
        } else {
          return '-';
        }
      },
    },
    mounted() {
      this.selectDataList();
    },
    created() {},
  };
</script>
<style scoped></style>