export default {
  MESSAGE: {
    WORK_SCHEDULE_CANNOT_MODIFY_PAST_DATES: 'Past dates cannot be modified for work changes.',
    SELECT_COUNSELOR_TO_MODIFY: 'Please select a counselor to modify.',
    CONFIRM_REVOKE_ORDER: 'Do you want to cancel the order?',
    REQUIRED_AGENT_ID: 'Please enter the agent ID.',
    REQUIRED_AGENT_NM: 'Please enter the agent name.',
    REQUIRED_AGENT_DN: 'Please enter the extension number.',
    REQUIRED_DEPT: 'Please select a department.',
    REQUIRED_JIKGUP: 'Please select a rank.',
    REQUIRED_JIKWEE: 'Please select a position.',
    REQUIRED_AUTH_ID: 'Please select account permissions.',
    REQUIRED_WORKGROUP: 'Please select a workgroup.',
    REQUIRED_USED_CTI_FL: 'Please select the CTI use status.',
    REQUIRED_LOGIN_FL: 'Please select the login integration status.',
  },
  WORD: {
    ATTENDANCE_STATUS_NORMAL: 'Normal',
    ATTENDANCE_STATUS_LATE: 'Late',
    ATTENDANCE_STATUS_EARLY_LEAVE: 'Early Leave',
    ATTENDANCE_STATUS_ABSENCE: 'Absent',
  },
};
