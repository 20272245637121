<!--
  PACKAGE_NAME : src\pages\ewm\hr\agent\appointment\hr.vue
  FILE_NAME : hr
  AUTHOR : devyoon91
  DATE : 2024-07-15
  DESCRIPTION : 발령관리 - 인사이동 탭
-->
<template>
  <div>
    <div class="page_search_box line_bottom_1px">
      <div class="inner alL">
        <DxSelectBox
          styling-mode="outlined"
          ref="approvalSelectBox"
          display-expr="label"
          value-expr="value"
          :items="personnelAppointmentSelectBox"
          :value="personnelAppointmentSelectBox[0].value"
          placeholder="발령상태 선택"
          width="100px"
          class="mr-4"
          @value-changed="onPASelectBoxValueChange"
        />
        <DateRangeBox ref="dateRangeBox" label="발령 일자" :start-dt="dayStart" :end-dt="dayEnd" />
        <DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="onSearch" />
      </div>
    </div>
    <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" @cell-prepared="onCellPrepared" />
    <DxToolTip :ref="tooltipRefName" position="bottom">
      <div>
        <div>{{ tooltipCurrentAgentInfo }}</div>
      </div>
    </DxToolTip>
    <ModalAddAgent
      :isOpen="modalAddAgent.visible"
      :showModalTitle="true"
      :selectedIdList="modalAddAgent.selectedIdList"
      saveBtnTxt="다음"
      title="인사 발령"
      @closeModal="onModalAddAgentClose"
      @saveModal="onModalAddAgentSave"
    />
    <ModalTransferAgent
      :isOpen="modalTransferAgent.visible"
      @closeModal="onModalTransferAgentClose"
      @saveModal="onModalTransferAgentSave"
      :agent-data-list="modalTransferAgent.agentDataList"
    />

    <DxPopup
      :title="codeDiff.title"
      :show-close-button="true"
      :hide-on-outside-click="true"
      :width="800"
      :height="450"
      :visible="codeDiff.visible"
      @hiding="isOpenModal(false, null)"
    >
      <template #default>
        <div style="height: 100%; width: 100%">
          <CodeDiff
            :old-string="codeDiff.oldString"
            :new-string="codeDiff.newString"
            diff-style="word"
            :context="10"
            output-format="side-by-side"
            max-height="100%"
            language="json"
          />
        </div>
      </template>
    </DxPopup>
  </div>
</template>

<script>
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { getPastFromToday, isSuccess } from '@/plugins/common-lib';
  import { DxButton } from 'devextreme-vue/button';
  import DateRangeBox from '@/components/devextreme/esp-dx-date-range-box.vue';
  import moment from 'moment/moment';
  import ModalAddAgent from '@/components/ewm/hr/modal-add-agent.vue';
  import ModalTransferAgent from '@/pages/ewm/hr/agent/appointment/popup/modal-transfer-agent.vue';
  import DxToolTip from 'devextreme-vue/tooltip';
  import { on } from 'devextreme/events';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';
  import CodeDiff from '@/plugins/code-diff';
  import { DxPopup } from 'devextreme-vue/popup';

  export default {
    components: {
      EspDxDataGrid,
      DxToolTip,
      ModalTransferAgent,
      ModalAddAgent,
      DxSelectBox,
      DateRangeBox,
      DxButton,
      CodeDiff,
      DxPopup,
    },
    props: {},
    data() {
      return {
        codeDiff: {
          title: '발령정보',
          visible: false,
          oldString: '',
          newString: '',
        },
        tooltipRefName: 'tooltipRef',
        tooltipCurrentAgentInfo: '',
        dayStart: getPastFromToday(14, 'days'),
        dayEnd: moment().add(30, 'days').format('YYYYMMDD'),
        selectedPersonnelAppointmentState: 'ALL',
        personnelAppointmentSelectBox: [
          {
            label: '전체',
            value: 'ALL',
          },
          ...this.$_enums.ewm.personnelAppointmentState.values,
        ],
        modalAddAgent: {
          visible: false,
          selectedIdList: [],
        },
        modalTransferAgent: {
          visible: false,
          agentDataList: [],
        },
        dataGrid: {
          keyExpr: 'paId', // 그리드 키값
          refName: 'personnelTransferGrid', // 그리드 컴포넌트 참조명
          callApi: 'CALL_EWM_API', // 그리드 데이터 호출 API
          excel: {
            title: '인사이동', // 엑셀 다운로드 시 파일명
            autoFilterEnabled: false, // 엑셀 필터 사용 유무
          },
          focusedRowEnabled: false, // 포커스 행 표시 유무
          allowColumnReordering: false, // 컬럼 위치 변경 유무
          allowColumnResizing: false, // 컬럼 사이즈 조절 유무
          columnResizingMode: 'nextColumn', // 컬럼 사이즈 조절 모드 : ['nextColumn', 'widget']
          columnAutoWidth: false, // 컬럼 사이즈 자동 조절 유무
          columnMinWidth: 80, // 컬럼 최소 사이즈
          showBorders: false, // 그리드 테두리 유무
          showColumnHeaders: true, // 컬럼 헤더 유무
          showColumnLines: false, // 컬럼 세로선 유무
          showRowLines: true, // 컬럼 가로선 유무
          rowAlternationEnabled: false, //행 배경색 교차 유무
          dataSource: [], // 그리드 데이터
          apiActionNm: {}, // api 호출시 사용할 액션명(ESP - API URL 관리) : ['update', 'merge', 'delete', 'select']
          scrolling: {
            mode: 'standard', // 스크롤 모드 : ['standard', 'virtual', 'infinite']
          },
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
            cellPrepared: true, // 셀 이벤트
            rowClick: true, // 행 클릭 이벤트
            toolbarPreparing: false, // 툴바 이벤트
          },
          showActionButtons: {
            excel: false, // 엑셀 다운로드 버튼
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  text: '발령 등록',
                  elementAttr: { class: 'default filled txt_XS add1 ml-1' },
                  width: 100,
                  height: 30,
                  onClick: () => {
                    this.modalAddAgent.visible = true;
                  },
                },
                location: 'before',
              },
              {
                widget: 'dxButton',
                options: {
                  text: '발령 취소',
                  elementAttr: { class: 'btn_XS white light_filled ml-1' },
                  width: 100,
                  height: 30,
                  onClick: this.onCancel,
                },
                location: 'before',
              },
            ], // 그리드 커스텀 버튼 생성
          },
          isDuplicateConfigKey: false, // 설정키 중복 체크
          grouping: {
            contextMenuEnabled: true, // 그룹핑 컨텍스트 메뉴 사용유무
            autoExpandAll: true, // 그룹핑시 전체 펼침 여부
            allowCollapsing: true, // 그룹핑시 접기 허용 여부
            expandMode: 'rowClick', // 그룹핑 펼침 모드 : ['rowClick', 'buttonClick']
          },
          groupPanel: {
            visible: false, // 그룹패널 표시 여부
          },
          columnChooser: {
            enabled: false, // 컬럼 선택 팝업 표시 여부
          },
          loadPanel: {
            enabled: true, // 로딩 패널 표시 여부
          },
          sorting: {
            mode: 'multiple', // 정렬 모드 : ['none', 'single', 'multiple']
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false, // 페이징 사용 유무
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, // 페이지 사이즈 선택 버튼 표시 여부
            allowedPageSizes: [], // 페이지 사이즈 선택 버튼에 표시할 항목
            displayMode: 'compact', // 페이저 표시 모드 : ['adaptive', 'compact', 'full']
            showInfo: false, // 페이지 정보 표시 여부
            showNavigationButtons: false, // 페이지 이동 버튼 표시 여부
          },
          filterRow: {
            visible: false, // 필터 행 표시 여부
          },
          headerFilter: {
            visible: false, // 헤더 필터 표시 여부
          },
          editing: {
            allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false, // 삭제 버튼을 없애고 싶으면 false설정(Row 마다 휴지통 생성)
            mode: 'batch', // 행 편집 모드 : ['cell', 'row', 'batch']
            startEditAction: 'click', // 행 편집 시작 액션 : ['click', 'dblClick']
            selectTextOnEditStart: false, // 편집 시작시 텍스트 선택 여부
          },
          selecting: {
            mode: 'multiple', // 행 선택 모드 : ['none', 'single', 'multiple']
            selectAllMode: 'page', // 전체 선택 모드 : ['page', 'allPages']
            showCheckBoxesMode: 'always', // 체크박스 표시 모드 : ['none', 'onClick', 'onLongTap', 'always']
            deferred: false, // 선택 지연 여부
            allowSelectAll: false, // 전체 선택 허용 여부
          },
          searchPanel: {
            visible: false, // 검색 패널 표시 여부
          },
          columns: [
            {
              caption: '발령상태',
              dataField: 'paStateCd',
              alignment: 'center',
              width: 150,
              visible: true,
              sortOrder: 'none',
              allowEditing: false,
              allowHeaderFiltering: true,
              allowFiltering: false,
              lookup: {
                dataSource: this.$_enums.ewm.personnelAppointmentState.values,
                valueExpr: 'value',
                displayExpr: 'label',
              },
            },
            {
              caption: '상담원',
              dataField: 'agent',
              visible: true,
              sortOrder: 'none',
              allowEditing: false,
              allowHeaderFiltering: true,
              allowFiltering: true,
              allowGrouping: false,
              calculateCellValue: this.mergeAgentInfo,
            },
            {
              caption: '발령정보',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              cellTemplate: (container, options) => {
                const button = new DxButton({
                  propsData: {
                    elementAttr: { class: 'btn_XS white light_filled mr-0' },
                    text: '상세보기',
                    width: 80,
                    height: 30,
                    onClick: () => {
                      this.isOpenModal(true, options.data);
                    },
                  },
                });

                button.$mount();
                container.append(button.$el);
              },
            },
            {
              dataType: 'date',
              format: 'yyyy-MM-dd',
              caption: '발령일자',
              dataField: 'baseDt',
              visible: true,
              sortOrder: 'none',
              alignment: 'center',
              allowEditing: true,
              allowHeaderFiltering: false,
              allowFiltering: true,
              allowGrouping: false,
            },
            {
              caption: '등록자',
              dataField: 'regId',
              visible: true,
              sortOrder: 'none',
              alignment: 'center',
              allowEditing: false,
              allowHeaderFiltering: false,
              allowFiltering: false,
              allowGrouping: false,
            },
            {
              dataType: 'date',
              format: 'yyyy-MM-dd HH:mm:ss',
              caption: '발령 등록 일시',
              dataField: 'regDt',
              visible: true,
              sortOrder: 'none',
              alignment: 'center',
              allowEditing: false,
              allowHeaderFiltering: false,
              allowFiltering: false,
              allowGrouping: false,
            },
          ], // 컬럼 정보
        },
      };
    },
    computed: {},
    methods: {
      /**
       * @description : 발령상태 셀렉트박스 변경 이벤트
       * @param e
       */
      onPASelectBoxValueChange(e) {
        this.selectedPersonnelAppointmentState = e.value;
        this.onSearch();
      },
      /** @description : 상담사 이름 + 상담사 로그인아이디 병합  */
      mergeAgentInfo(rowData) {
        if (rowData === null || rowData === undefined) {
          return '';
        }
        return `${rowData.agtNm} [${rowData.agtid}]`;
      },
      /**
       * @description : 인사이동 발령 조회
       * @return {Promise<*>}
       */
      async onSearch() {
        let vm = this;
        this.dataGrid.dataSource = new CustomStore({
          key: 'paId',
          async load() {
            const payload = {
              actionName: 'EWM_PERS_APPT_LIST',
              data: {
                paStateCd: vm.selectedPersonnelAppointmentState === 'ALL' ? null : vm.selectedPersonnelAppointmentState,
                paCategoryCd: vm.$_enums.ewm.personnelAppointmentCategory.TRANSFER.value,
                baseDt: vm.$refs.dateRangeBox.getStringDate(),
              },
              loading: false,
            };

            let rtnData = [];
            const res = await vm.CALL_EWM_API(payload);
            if (isSuccess(res)) {
              rtnData = res.data.data;
            } else {
              vm.$_Toast(vm.$_lang('COMMON.MESSAGE.CMN_ERROR_INTERNAL_SERVER', { defaultValue: '서버 오류 입니다.' }));
            }
            return rtnData;
          },
        });
      },
      /**
       * @description : 인사 발령 팝업 열기
       */
      onModalAddAgentClose() {
        this.modalAddAgent.visible = false;
      },
      /**
       * @description : 인사 발령 팝업 다음 버튼 클릭 이벤트
       * @param e
       */
      onModalAddAgentSave(e) {
        this.modalAddAgent.visible = false;
        this.modalTransferAgent.agentDataList = e;
        this.modalTransferAgent.visible = true;
      },
      onModalTransferAgentClose() {
        this.modalTransferAgent.visible = false;
      },
      onModalTransferAgentSave(e) {
        this.modalTransferAgent.visible = false;
        this.$refs.personnelTransferGrid.getInstance.clearSelection();
        this.$refs.personnelTransferGrid.getInstance.refresh();
      },
      /**
       * @description : 발령 취소
       */
      async onCancel() {
        if (await this.$_Confirm(this.$_lang('EWM.MESSAGE.CONFIRM_REVOKE_ORDER', { defaultValue: '발령을 취소하시겠습니까?' }))) {
          const rowKeys = this.$refs.personnelTransferGrid.getInstance.getSelectedRowKeys();
          if (rowKeys.length === 0) {
            this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
            return;
          }

          const payload = {
            actionName: 'EWM_PERS_APPT_CANCEL',
            data: rowKeys.map(rowKey => {
              return { paId: rowKey };
            }),
          };
          const res = await this.CALL_EWM_API(payload);
          if (isSuccess(res)) {
            this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUCCESS', { defaultValue: '정상적으로 처리되었습니다.' }));
            this.$refs.personnelTransferGrid.getInstance.clearSelection();
            this.$refs.personnelTransferGrid.getInstance.refresh();
          } else {
            this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_ERROR_INTERNAL_SERVER', { defaultValue: '서버 오류 입니다.' }));
          }
        }
      },
      /**
       * @description : 셀 이벤트, 상담사 정보 툴팁
       * @param e
       */
      onCellPrepared(e) {
        if (e.rowType === 'data' && (e.column.dataField === 'beforeAgentInfo' || e.column.dataField === 'afterAgentInfo')) {
          const self = this;
          let agentInfo = '';

          if (e.column.dataField === 'beforeAgentInfo') {
            agentInfo = JSON.stringify(e?.data?.beforeAgentInfo);
          } else if (e.column.dataField === 'afterAgentInfo') {
            agentInfo = JSON.stringify(e?.data?.afterAgentInfo);
          }

          on(e.cellElement, 'mouseover', arg => {
            this.tooltipCurrentAgentInfo = agentInfo;
            self.$refs[this.tooltipRefName].instance.show(arg.target);
          });

          on(e.cellElement, 'mouseout', arg => {
            self.$refs[this.tooltipRefName].instance.hide();
          });
        }
      },
      isOpenModal(visible, data) {
        if (data !== null) {
          this.codeDiff.title = `발령정보(${data.agtNm})`;
          this.codeDiff.oldString = JSON.stringify(data.beforeAgentInfo, null, 2);
          this.codeDiff.newString = JSON.stringify(data.afterAgentInfo, null, 2);
        }
        this.codeDiff.visible = visible;
      },
    },
    mounted() {
      this.$refs.dateRangeBox.initDate(); // 날짜 초기화
      this.onSearch();
    },
  };
</script>

<style lang="scss" scoped></style>
