import store from '@/store';

/**
 * @description : onStoreMessageReceived 에서 동적으로 사용됨
 * */
const onSystemListUpdate = (data) => {
    let systemList = store.getters.getSystemList || [];
    for (const payload of data) {
        if (systemList?.length) {
            const target = systemList.find(system => system.id === payload.id);
            if (target) {
                systemList = systemList.map(item => {
                    if (item.id === payload.id) {
                        return { ...item, ...payload }; // 기존 객체와 payload를 병합하여 새로운 객체 생성
                    }
                    return item; // id가 일치하지 않으면 그대로 반환
                });
            } else {
                systemList.push(payload);
            }
        } else {
            systemList.push(payload);
        }
    }
    store.commit('setSystemList', systemList);
};

/**
 * @description : onStoreMessageReceived 에서 동적으로 사용됨
 * */
const onSystemListDelete = (data) => {
    let systemList = store.getters.getSystemList || [];
    for (const payload of data) {
        if (systemList?.length) {
            systemList = systemList.filter(system => system.id !== payload.id);
        }
    }
    store.commit('setSystemList', systemList);
};

/**
 * @description : 메뉴 리스트 변경
 * */
const onMenuListChange = (data) => {
    // @TODO: 메뉴 리스트 변경 시 처리
}

export const SOCKET_ACTIONS = {
    onSystemListUpdate,
    onSystemListDelete,
    onMenuListChange
};