<template>
  <div class="flex flex-wrap space-x-4 mb-6">
    <span v-for="item in items" :key="item.value" class="flex items-center pt-1.5 space-x-1.5">
      <DxCheckBox
        :key="item.id"
        :text="item.alt"
        :value="item.value"
        v-model="item.checked"
        :disabled="disabled"
        @value-changed="handleChangedValue"
      />
    </span>
  </div>
</template>
<script>
  import { DxCheckBox } from 'devextreme-vue/check-box';

  export default {
    name: 'days-box.vue',
    components: { DxCheckBox },
    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
      changedValue: {
        type: Function,
        required: false,
        default: () => {},
      },
    },
    data() {
      return {
        items: [
          { id: 'MON', alt: '월', value: '2', checked: true },
          { id: 'TUE', alt: '화', value: '3', checked: true },
          { id: 'WED', alt: '수', value: '4', checked: true },
          { id: 'THU', alt: '목', value: '5', checked: true },
          { id: 'FRI', alt: '금', value: '6', checked: true },
          { id: 'SUT', alt: '토', value: '7', checked: true },
          { id: 'SUN', alt: '일', value: '1', checked: true },
          { id: 'HOLI', alt: '공휴일', value: 'Y', checked: true },
        ],
      };
    },
    methods: {
      /** 요일 및 공휴일 변경 시 실행되는 함수 */
      handleChangedValue() {
        this.changedValue(this.items);
      },
      /** 시작 및 종료 시간, 분 (startH, startM, endH, endM) 조회 */
      getFormDataList() {
        return this.items;
      },
      updateFormData(days, holi) {
        if (this.disabled) {
          return; // 비활성화 시 초기값으로 적용
        }
        this.items.forEach(item => {
          let checked = false;
          if (days.includes(item.value)) {
            checked = true;
          }
          item.checked = checked;
        });
        // 공휴일 체크
        this.items[7].checked = holi === 'Y';
      },
    },
    mounted() {},
  };
</script>
<style scoped></style>
