<!--
  PACKAGE_NAME : src\pages\ai\llm-tester\work-config
  FILE_NAME : question-save
  AUTHOR : hpmoon
  DATE : 2024-11-13
  DESCRIPTION : AI > LLM > LLM Tester > 프로젝트 작업 설정 > 질의 등록/수정
-->
<template>
  <DxPopup
    :show-title="true"
    :title="modal.title"
    :min-width="modal.minWidth"
    :width="modal.width"
    :max-height="modal.maxHeight"
    :height="modal.height"
    :drag-enabled="modal.dragEnabled"
    :resize-enabled="modal.resizeEnabled"
    :hide-on-outside-click="modal.closeOnOutsideClick"
    :show-close-button="modal.showCloseButton"
    :visible="isOpen"
    @hiding="closeModal"
  >
    <template #content>
      <DxScrollView>
        <div class="page-sub-box">
          <table class="table_form line-bin">
            <colgroup>
              <col style="width: 130px" />
              <col style="width: auto" />
            </colgroup>

            <tbody>
            <tr>
              <th scope="row">
                <label for="label5">{{ $_lang('LLM_TESTER.WORD.QUERY_TYPE', { defaultValue: '질문 유형' }) }} <span class="icon_require">필수항목</span></label>
              </th>
              <td>
                <DxTagBox
                  :value="tagBoxValue"
                  placeholder=""
                  :items="categoryList"
                  :max-length="limitNumberTexts.maxLengths.category"
                  :search-enabled="true"
                  :hide-selected-items="true"
                  :accept-custom-value="true"
                  @value-changed="handleTagValueChanged"
                  :styling-mode="stylingMode"
                  class="mar_ri10"
                  :width="600"
                  @key-up="handleKeyUpTabBox"
                >
                  <DxValidator>
                    <DxRequiredRule validation-group="validationGroupName"
                                    :message="$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS',
                                  { value: $_lang('LLM_TESTER.WORD.QUERY_TYPE', {defaultValue: '질문 유형'}), defaultValue: '[질문 유형] 은/는 필수값 입니다' })" />
                  </DxValidator>
                </DxTagBox>
                <span>
                {{
                    limitNumberTexts.textLengths.category
                      ? limitNumberTexts.textLengths.category
                      : formData.category
                        ? formData.category.length
                        : 0
                  }}
              </span>/{{ limitNumberTexts.maxLengths.category }}
              </td>
            </tr>

            <tr>
              <th scope="row">
                <label for="label5">{{ $_lang('LLM_TESTER.WORD.QUERY', { defaultValue: '질문' }) }} <span class="icon_require">필수항목</span></label>
              </th>
              <td>
                <DxTextArea
                  v-model="formData.query"
                  :max-length="limitNumberTexts.maxLengths.query"
                  :styling-mode="stylingMode"
                  class="mar_ri10 alB"
                  :width="600"
                  :height="85"
                  @key-up="$_checkLimitTextLength($event, formData, limitNumberTexts, 'query')"
                >
                  <DxValidator>
                    <DxRequiredRule validation-group="validationGroupName"
                                    :message="$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS',
                                  { value: $_lang('LLM_TESTER.WORD.QUERY', {defaultValue: '질문'}), defaultValue: '[질문] 은/는 필수값 입니다' })" />
                  </DxValidator>
                </DxTextArea>
                <span>
                {{
                    limitNumberTexts.textLengths.query
                      ? limitNumberTexts.textLengths.query
                      : formData.query
                        ? formData.query.length
                        : 0
                  }}
              </span>/{{ limitNumberTexts.maxLengths.query }}
              </td>
            </tr>

            <tr>
              <th scope="row">
                <label for="label5">{{ $_lang('LLM_TESTER.WORD.CORRECT_ANSWER', { defaultValue: '올바른 답변' }) }}</label>
              </th>
              <td>
                <DxTextArea
                  v-model="formData.correct_answer"
                  :max-length="limitNumberTexts.maxLengths.correct_answer"
                  :styling-mode="stylingMode"
                  class="mar_ri10 alB"
                  :width="600"
                  :height="85"
                  @key-up="$_checkLimitTextLength($event, formData, limitNumberTexts, 'correct_answer')"
                />
                <span>
                {{
                    limitNumberTexts.textLengths.correct_answer
                      ? limitNumberTexts.textLengths.correct_answer
                      : formData.correct_answer
                        ? formData.correct_answer.length
                        : 0
                  }}
              </span>/{{ limitNumberTexts.maxLengths.correct_answer }}
              </td>
            </tr>

            <tr>
              <th scope="row">
                <label for="label5">{{ $_lang('LLM_TESTER.WORD.SUPPORTING_DOCUMENT', { defaultValue: '근거문서' }) }}</label>
              </th>
              <td>
                <!-- 파일 목록 DropDown -->
                <DxDropDownBox
                  :disabled="!formData.search_chunks"
                  :opened="dropdownOpened"
                  :onOpened="() => (dropdownOpened = true)"
                  :onClosed="() => (dropdownOpened = false)"
                  :width="510"
                  class="dis_inblock mar_bo5 docs-box"
                  content-template="dropDownContentTemplate"
                >
                  <template #dropDownContentTemplate>
                    <div>
                      <!-- 파일 검색 결과 List -->
                      <DxList
                        :data-source="indexFileList"
                        display-expr="name"
                        :search-enabled="true"
                        search-mode="contains"
                        search-expr="name"
                        selection-mode="single"
                        pageLoadMode="scrollBottom"
                        :no-data-text="getNoDataText"
                        :height="300"
                        @selectionChanged="onItemSelected"
                      />
                    </div>
                  </template>
                </DxDropDownBox>

                <DxCheckBox
                  :text="$_lang('LLM_TESTER.WORD.SEARCH_DOCUMENT', { defaultValue: '문서 검색' })"
                  class="mar_le10 dis_inblock"
                  v-model="formData.search_chunks"
                  @value-changed="onChangedSearchChunks"
                />
                <EspDxDataGrid :data-grid="dataGrid" />
              </td>
            </tr>

            <tr>
              <th scope="row">
                <label for="label5">{{ $_lang('LLM_TESTER.WORD.REPEAT_COUNT', { defaultValue: '반복 횟수' }) }} <span class="icon_require">필수항목</span></label>
              </th>
              <td>
                <DxNumberBox
                  v-model="formData.repeat_count"
                  :min="1"
                  :max="100"
                  :show-spin-buttons="true"
                  validation-message-position="right"
                  class="mar_ri10"
                  :width="200"
                  :styling-mode="stylingMode"
                  format="#"
                >
                  <DxValidator>
                    <DxRequiredRule validation-group="validationGroupName"
                                    :message="$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS',
                                  { value: $_lang('LLM_TESTER.WORD.REPEAT_COUNT', {defaultValue: '반복 횟수'}), defaultValue: '[반복 횟수] 은/는 필수값 입니다' })" />
                  </DxValidator>
                </DxNumberBox>
              </td>
            </tr>

            <tr>
              <th scope="row">
                <label for="label5">{{ $_lang('LLM_TESTER.WORD.SYNTHETIC_OPTION', { defaultValue: '변형 옵션' }) }}</label>
              </th>
              <td>
              <span class="check-type col">
                <DxCheckBox :text="$_lang('LLM_TESTER.WORD.SUBSTANTIVE_SYNONYM', { defaultValue: '체언 유의어' })" v-model="formData.substantive_synonym" />
              </span>
                <span class="check-type col">
                <DxCheckBox :text="$_lang('LLM_TESTER.WORD.SUBSTANTIVE_TYPO', { defaultValue: '체언 오타' })" v-model="formData.substantive_typo" />
              </span>
                <span class="check-type col">
                <DxCheckBox :text="$_lang('LLM_TESTER.WORD.PREDICATE_SYNONYM', { defaultValue: '용언 유의어' })" v-model="formData.predicate_synonym" />
              </span>
                <span class="check-type col">
                <DxCheckBox :text="$_lang('LLM_TESTER.WORD.PREDICATE_TYPO', { defaultValue: '용언 오타' })" v-model="formData.predicate_typo" />
              </span>
                <span class="check-type col">
                <DxCheckBox :text="$_lang('LLM_TESTER.WORD.PREDICATE_ENDING', { defaultValue: '용언 어미' })" v-model="formData.predicate_ending" />
              </span>
              </td>
            </tr>

            <tr>
              <th scope="row">
                <label for="label5">{{ $_lang('LLM_TESTER.WORD.SYNTHETIC_COUNT', { defaultValue: '변형 횟수' }) }} <span class="icon_require">필수항목</span></label>
              </th>
              <td>
                <DxNumberBox
                  v-model="formData.synthetic_query_count"
                  :disabled="isCheckedSyntheticOptions"
                  :min="1"
                  :max="100"
                  :show-spin-buttons="true"
                  validation-message-position="right"
                  class="mar_ri10"
                  :width="200"
                  :styling-mode="stylingMode"
                  format="#"
                >
                  <DxValidator>
                    <DxRequiredRule validation-group="validationGroupName"
                                    :message="$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS',
                                  { value: $_lang('LLM_TESTER.WORD.SYNTHETIC_COUNT', {defaultValue: '변형 횟수'}), defaultValue: '[변형 횟수] 은/는 필수값 입니다' })" />
                  </DxValidator>
                </DxNumberBox>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </DxScrollView>
    </template>

    <DxToolbarItem
      widget="dxButton"
      toolbar="bottom"
      location="center"
      :visible="true"
      :options="{
            elementAttr: { class: 'default filled txt_S medium' },
            text: this.$_lang('COMPONENTS.SAVE', { defaultValue: '저장' }),
            width: '120',
            height: '40',
            useSubmitBehavior: true,
            onClick: saveModal,
          }"
    />
    <DxToolbarItem
      widget="dxButton"
      toolbar="bottom"
      location="center"
      :visible="true"
      :options="{
            elementAttr: { class: 'white filled txt_S medium' },
            text: this.$_lang('COMPONENTS.CANCEL', { defaultValue: '취소' }),
            width: '120',
            height: '40',
            onClick: closeModal,
          }"
    />
  </DxPopup>
</template>

<script>
  import DxList from 'devextreme-vue/list';
  import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
  import DxTextArea from "devextreme-vue/text-area";
  import { DxNumberBox } from "devextreme-vue/number-box";
  import { DxCheckBox } from "devextreme-vue/check-box";
  import DxDropDownBox from 'devextreme-vue/drop-down-box';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';
  import { DxPopup, DxToolbarItem } from "devextreme-vue/popup";
  import { cloneObj } from "@/plugins/common-lib";
  import { DxScrollView } from "devextreme-vue/scroll-view";
  import { DxTagBox } from "devextreme-vue/tag-box";

  export default {
    components: {
      DxTagBox,
      DxScrollView,
      DxPopup,
      DxToolbarItem,
      EspDxDataGrid,
      DxCheckBox,
      DxNumberBox,
      DxTextArea,
      DxDropDownBox,
      DxList,
      DxValidator,
      DxRequiredRule,
    },

    props: {
      isOpen: Boolean,
      project_id: String,
      indexFileListOrg: Array,
      saveType: String,
      contentData: Object,
      categoryList: Array,
    },

    watch: {
      formData: {
        handler(val) {
          let filterData = val;
          if (filterData) {
            this.$emit('input', filterData);
          }
        },
        deep: true,
      },
    },

    data() {
      return {
        dataGrid: {
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: true, //border 유무
          showRowLines: true, //컬럼 가로선 유무
          disableTotalCount: true,
          dataSource: [],
          width: '600',     // 주석처리시 100%
          height: '200',    // 주석처리시 100%
          remoteOperations: { filtering: false, sorting: false, grouping: false, paging: false },
          pager: { visible: false },
          filterRow: { visible: false },
          headerFilter: { visible: false },
          editing: { allowUpdating: false, allowDeleting: false, allowAdding: false },
          customEvent: {},
          dragging: {
            sortColumn: 'sort',
            allowReordering: false,
            dropFeedbackMode: 'push', // 설정하면 dragging 할때 기존리스트가 아래로 움직이는 효과
          },
          columns: [
            {
              caption: 'sort',
              width: 50,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              allowSorting: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              cellTemplate: (container, options) => {
                container.append(options.rowIndex + 1);
              },
            },
            {
              caption: 'name',
              dataField: 'name',
              height: 40,
              alignment: 'left', // left center right
              visible: true,
              allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
            },
            {
              caption: '삭제',
              i18n: 'COMPONENTS.DELETE',
              width: 60,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              cellTemplate: (container, options) => {
                let buttonTag = document.createElement('button');
                buttonTag.className = 'btn-icon close';
                buttonTag.setAttribute('type', 'button');
                buttonTag.addEventListener('click', () => {
                  this.onRemoveDocs(options.data);
                });
                container.append(buttonTag);
              },
            },
          ]
        },

        modal: {
          title: this.saveType === 'add'
            ? this.$_lang('LLM_TESTER.WORD.QUERY_ADD', { defaultValue: '질의 등록' })
            : this.$_lang('LLM_TESTER.WORD.QUERY_UPDATE', { defaultValue: '질의 수정' }),
          minWidth: '900',
          width: '900',
          maxHeight: '100vh',
          height: '820',
          dragEnabled: true,
          resizeEnabled: true,
          closeOnOutsideClick: false,
          showCloseButton: true,
        },

        dropdownOpened: false,  // 드롭다운 열림 여부

        tagBoxValue: [],

        indexFileList: [],

        stylingMode: 'outlined', //[outlined, filled, underlined]

        formData: {
          project_id: null,
          category: null,               // 질문 유형
          query: null,                  // 질문
          correct_answer: null,         // 올바른 답변
          search_chunks: true,          // 근거 문서 선택 여부
          knowledge_ids: [],            // 근거 문서 선택 파일 IDs
          repeat_count: 1,              // 반복 횟수
          substantive_synonym: false,   // 체언 유의어 선택 여부
          substantive_typo: false,      // 체언 오타 선택 여부
          predicate_synonym: false,     // 용언 유의어 선택 여부
          predicate_typo: false,        // 용언 오타 선택 여부
          predicate_ending: false,      // 용언 어미 선택 여부
          synthetic_query_count: 1,     // 변형 횟수
        },

        limitNumberTexts: {
          textLengths: {
            category: 0
          },
          maxLengths: {
            category: 20,
            query: 1000,
            correct_answer: 1000,
          },
        },
      };
    },

    computed: {
      /** @description 근거 문서 리스트 NoDataText */
      getNoDataText() {
        if (this.$_commonlib.isEmpty(this.indexFileList) && this.$_commonlib.isEmpty(this.indexFileListOrg)) {
          return this.$_lang('LLM_TESTER.MESSAGE.PLEASE_ADD_INDEX', { defaultValue: '등록된 인덱스가 없습니다. 새 인덱스를 추가해주세요.' });
        } else if (this.$_commonlib.isEmpty(this.indexFileList)) {
          return this.$_lang('LLM_TESTER.MESSAGE.DONT_EXIST_FILE', { defaultValue: '선택할 근거 문서가 없습니다.' });
        } else {
          return this.$_lang('LLM_TESTER.MESSAGE.DONT_EXITS_SEARCH_RESULT', { defaultValue: '검색 결과가 존재하지 않습니다.' });
        }
      },

      /** @description 변형 옵션 선택 여부 */
      isCheckedSyntheticOptions() {
        return !this.formData.substantive_synonym && !this.formData.substantive_typo &&
          !this.formData.predicate_synonym && !this.formData.predicate_typo &&
          !this.formData.predicate_ending;
      }
    },

    methods: {
      /** @description 모달 저장 */
      async saveModal(e) {
        if (!e.validationGroup.validate().isValid) {
          e.validationGroup.validate().validators.forEach((validator) => {
            validator.$element().addClass("dx-state-focused");
          });
          return;
        }

        const payload = {
          actionName: this.saveType === 'add' ? 'LLM_TESTER_QUERY_SET_INSERT' : 'LLM_TESTER_QUERY_SET_UPDATE',
          data: this.formData,
          loading: true,
        };

        this.formData.knowledge_ids = this.dataGrid.dataSource.map(file => file.id);

        const res = await this.CALL_LLM_TESTER_API(payload);
        if (res.status === 200) {
          this.$emit('saveModal');
        } else {
          this.$_Msg(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          return false;
        }
      },

      /** @description 질문 유형 TagBox 값 변경 Handler */
      handleTagValueChanged(e) {
        if (e.value.length > 1) {
          e.component.option("value", [e.value.pop()]);
        } else if (e.value.length === 0) {
          this.formData.category = '';
        } else {
          this.formData.category = e.value[0];
        }
        this.limitNumberTexts.textLengths.category = this.formData.category.length;
      },

      /** @description 질문 유형 TagBoxKey Up Handler */
      handleKeyUpTabBox(e) {
        this.limitNumberTexts.textLengths.category = e.event.currentTarget.value.length;
      },

      /** @description 근거문서 체크값 변경 */
      async onChangedSearchChunks(e) {
        if (!e.value && this.dataGrid.dataSource.length !== 0) {
          if (await this.$_Confirm(this.$_lang('LLM_TESTER.MESSAGE.UNSELECT_SEARCH_DOCUMENT_ALERT', { defaultValue: '문서 검색 해제시 근거 문서가 초기화 됩니다. <br/>해제 하시겠습니까?' }))) {
            this.dataGrid.dataSource = [];
            this.indexFileList = cloneObj(this.indexFileListOrg);
          } else {
            this.formData.search_chunks = true;
          }
        }
      },

      /** @description 드롭다운 아이템 선택 */
      onItemSelected({ addedItems }) {
        if (addedItems.length > 0) {
          const selectedItem = addedItems[0];
          selectedItem.sort = this.dataGrid.dataSource.length === 0 ? 1 : Math.max(...this.dataGrid.dataSource.map((obj) => obj["sort"])) + 1;
          this.dataGrid.dataSource.push(selectedItem);
          this.dropdownOpened = false;  // 선택 후 드롭다운 닫기
          this.indexFileList = this.indexFileList.filter(d => d.id !== selectedItem.id);
        }
      },

      /** @description 근거문서 리스트 삭제 */
      onRemoveDocs(data) {
        this.dataGrid.dataSource = this.dataGrid.dataSource.filter(d => d.id !== data.id);
        this.indexFileList.push(data);
      },

      /** @description 모달 닫기 */
      closeModal() {
        this.$emit('closeModal');
      },
    },

    /** @description 라이프사이클 created 시 호출되는 메서드 */
    created() {
      this.indexFileList = cloneObj(this.indexFileListOrg);
      this.formData.project_id = this.project_id;
      this.formData = JSON.parse(JSON.stringify({ ...this.formData, ...this.contentData }));
      if (this.formData.category){
        this.tagBoxValue = cloneObj([this.formData.category]);
      }

      this.formData.knowledge_ids.forEach(id => {
        const file = this.indexFileList.filter(d => d.id === id);
        this.indexFileList = this.indexFileList.filter(d => d.id !== id);
        this.dataGrid.dataSource.push(file[0]);
      });
    },
  };
</script>

<style lang="scss" scoped>
  ::v-deep {
    .dx-placeholder {
      padding-left: 20px;
    }

    .item-context > div {
      padding-left: 10px;
      float: left;
    }

    .dx-datagrid .dx-row-lines > td {
      border-bottom: 1px solid #efefef !important;
    }

    .docs-box {
      border-radius: 4px;
      border: 1px solid #dcdcdc;
      background-color: transparent;
    }

    .dx-texteditor.dx-editor-filled.dx-state-hover::after {
      border-bottom: unset;
    }

    .dx-tagbox.dx-editor-filled .dx-tag-container, .dx-tagbox.dx-editor-outlined .dx-tag-container {
      padding: 5px 8px 2px;
    }
  }

  .page-sub-box {
    padding: 0 !important;
  }
</style>
