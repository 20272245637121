<!--
  PACKAGE_NAME : src\pages\esp\system\menu\list.vue
  FILE_NAME : list
  AUTHOR : devyoon91
  DATE : 2024-08-26
  DESCRIPTION : 메뉴 설정
-->
<template>
  <div class="page-sub-box" style="padding-top: 20px">
    <div class="menu-tree-box">
      <div class="menu-tree-between-box">
        <esp-dx-tree-list :tree-list="treeList" :ref="treeList.refName" @row-click="handleClickRow" />
      </div>
      <div class="menu-tree-between-box">
        <menu-add-form
          ref="menuAddFormRef"
          :menu-data="selectedMenuData"
          @handleOpenPageDataModal="handleOpenPageDataModal"
          @handleOpenAddManuModal="handleOpenAddManuModal"
          @handleSaveButton="handleFromSaveButton"
          @handleCancelButton="handleFromCancelButton"
        />
      </div>
    </div>

    <!-- 메뉴 등록 모달 -->
    <modal-menu-config
      :isOpen="modalMenuConfig.isOpened"
      @saveModal="handleSaveRootMenu"
      @closeModal="modalMenuConfig.isOpened = false"
      :modal-option="modalMenuConfig.propOption"
      :menu-data="selectedMenuData"
      :ref="modalMenuConfig.refName"
    />

    <!-- 메뉴 복사 모달 -->
    <modal-menu-copy
      :isOpen="modalMenuCopy.isOpened"
      @saveModal="handleSaveCopyMenu"
      @closeModal="modalMenuCopy.isOpened = false"
      :menu-data="modalMenuCopy.menuData"
    />

    <!-- 페이지 데이터 모달 -->
    <modal-menu-page-data
      :isOpen="modalMenuPageData.isOpened"
      @saveModal="handleSavePageData"
      @closeModal="modalMenuPageData.isOpened = false"
      :menu-data="selectedMenuData"
    />
  </div>
</template>

<script>
  import { DxButton } from 'devextreme-vue/button';
  import ModalMenuCopy from '@/pages/esp/system/menu/modal-menu-copy.vue';
  import EspDxTreeList from '../../../../components/devextreme/esp-dx-tree-list-v2.vue';
  import MenuAddForm from './menu-add-form.vue';
  import { cloneObj, isSuccess } from '@/plugins/common-lib';
  import ModalMenuConfig from './modal-menu-config.vue';
  import ModalMenuPageData from '@/pages/esp/system/menu/modal-menu-page-data.vue';

  export default {
    components: {
      ModalMenuPageData,
      ModalMenuConfig,
      MenuAddForm,
      EspDxTreeList,
      ModalMenuCopy,
    },
    data() {
      return {
        treeList: {
          refName: 'menuRefName',
          dataSource: [],
          dataSourceDefaultSortColumn: '+menuOrd', // 주석처리하면 keyExpr 컬럼으로 sorting됨 + 오름차순 - 내림차순1
          sortKey: 'menuOrd', // 주석처리하면 keyExpr 컬럼으로 sorting됨 + 오름차순 - 내림차순1
          apiActionNm: {},
          showColumnHeaders: false, // 컬럼 헤더 표시 여부
          showBorders: true, // 테두리 표시 여부
          showActionButtons: {
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  text: this.$_lang('COMPONENTS.ROOT_MENU_ADD', { defaultValue: '대메뉴 추가' }),
                  elementAttr: { class: 'btn_XS default filled add1' },
                  height: 30,
                  onClick: () => {
                    // 트리 선택 초기화
                    this.$refs.modalMenuConfigRef.initData(); // 모달 데이터 초기화

                    // 모달 기본 옵션 설정
                    this.modalMenuConfig.propOption.isRoot = true;
                    this.modalMenuConfig.propOption.title = this.$_lang('COMPONENTS.ROOT_MENU_ADD', { defaultValue: '대메뉴 추가' });
                    this.modalMenuConfig.isOpened = true;
                  },
                },
                location: 'before',
                sortIndex: 5,
              },
            ],
          },
          editing: {
            allowAdding: false, // 상단 추가버튼 / 컬럼 하위추가버튼 표시 여부
            allowUpdating: false,
          },
          filterRow: {
            //필터 설정
            visible: false,
          },
          selection: {
            //로우 선택 설정
            allowSelectAll: false, //헤더 체크박스 선택(전체선택) 허용 여부
            mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple', 'none']
            recursive: false, //상위 노드 선택시 하위 노드도 선택 여부(true: 하위 노드도 선택, false: 하위 노드 선택 안하고 독립적)
          },
          rowDragging: {
            enabled: false,
            allowDropInsideItem: false, // 드래그로 아이템 안에 드롭 허용 여부
            allowReordering: false, // 드래그로 순서 변경 허용 여부
            showDragIcons: false, // 드래그 아이콘 표시 여부
          },
          columns: [
            {
              i18n: 'COMPONENTS.MENU_NAME',
              caption: '메뉴명',
              dataField: 'menuNm',
              alignment: 'left',
              allowSorting: false,
            },
            {
              dataField: 'id',
              alignment: 'center',
              width: 80,
              cellTemplate: (container, options) => {
                const button = new DxButton({
                  propsData: {
                    text: '',
                    elementAttr: { class: 'mdi mdi-trash-can' },
                    onClick: () => {
                      this.deleteMenu(options.data);
                    },
                  },
                });
                button.$mount();
                container.append(button.$el);

                const button2 = new DxButton({
                  propsData: {
                    text: '',
                    elementAttr: { class: 'mdi mdi-content-copy' },
                    onClick: () => {
                      this.modalMenuCopy.menuData = {
                        id: options.data.id,
                        menuNm: options.data.menuNm,
                      };
                      this.modalMenuCopy.isOpened = true;
                    },
                  },
                });
                button2.$mount();
                container.append(button2.$el);
              },
            },
          ],
        },
        selectedMenuData: null,
        originPageData: null,
        modalMenuCopy: {
          // 메뉴 복사 모달
          isOpened: false,
          menuData: null,
        },
        modalMenuConfig: {
          // 메뉴 등록 모달
          refName: 'modalMenuConfigRef',
          isOpened: false,
          propOption: {
            title: '메뉴 등록',
            isRoot: false, // 대메뉴 등록 여부
          },
        },
        modalMenuPageData: {
          // 페이지 데이터 모달
          isOpened: false,
        },
      };
    },
    methods: {
      /**
       * @description 트리 로우 클릭 이벤트
       * @param e
       */
      handleClickRow(e) {
        const rowData = e.data;
        if (rowData) {
          this.selectedMenuData = cloneObj(rowData); // 양방향 바인딩 방지를 위해 데이터 복사
          this.modalMenuConfig.propOption.isRoot = false;
        }
      },
      /**
       * 메뉴 리스트 가져오기 (부모에게 상속받은 메뉴만)
       * @returns {Promise<void>}
       */
      async getMenu() {
        const payload = {
          actionName: 'MENU_LIST_ALL',
          data: {
            authId: this.$store.getters.getAuthId,
            authPermFl: this.$_enums.common.stringUsedFlag.YES.value, // 부모 권한 포함 여부
          },
          loading: true,
        };
        const res = await this.CALL_API(payload);
        if (isSuccess(res)) {
          this.treeList.dataSource = res.data.data;
          this.$refs.menuRefName.getInstance.clearSelection();
          this.$refs.menuRefName.getInstance.refresh();
        }
      },
      /**
       * 선택된 메뉴의 하위메뉴를 삭제한다.
       */
      async deleteMenu() {
        const selectionNodeKeys = this.$refs.menuRefName.getInstance.getSelectedRowKeys();

        if (selectionNodeKeys.length === 0) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }

        if (
          await this.$_Confirm(
            this.$_lang('COMMON.MESSAGE.DELETE_WITH_SUB', {
              defaultValue: '하위 메뉴도 같이 삭제됩니다. 삭제하시겠습니까?',
              target: '메뉴',
            }),
          )
        ) {
          const selectionParentNodeKey = selectionNodeKeys[0];
          const childIds = await this.getAllRelatedMenuIds(selectionParentNodeKey);

          const payload = {
            actionName: 'MENU_LIST_DELETE',
            data: [selectionParentNodeKey, ...childIds],
            useErrorPopup: true,
          };

          const res = await this.CALL_API(payload);
          if (isSuccess(res)) {
            this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUC_DELETE', { defaultValue: '정상적으로 삭제되었습니다.' }));
            this.$refs.menuAddFormRef.initData();
            await this.initData();
          }
        }
      },
      /**
       * @description 선택된 메뉴의 하위 메뉴를 찾는다.
       * @param parentId {number}
       * @return {Promise<any[]>}
       */
      async getAllRelatedMenuIds(parentId) {
        const childIds = [];
        const queue = [parentId]; // BFS 탐색 큐

        while (queue.length > 0) {
          const currentId = queue.shift();
          const children = this.$refs.menuRefName.getDataSource.filter(node => node.parentId === currentId);

          // 자식 노드 찾기
          children.forEach(child => {
            childIds.push(child.id); // 자식 ID 추가
            queue.push(child.id); // 자식을 다음 탐색 대상으로 추가
          });
        }
        return childIds;
      },
      /**
       * @description 데이터 초기화, 라우팅 메뉴 재설정
       * @return {Promise<void>}
       */
      async initData() {
        this.selectedMenuData = null;
        this.originPageData = null;
        await this.getMenu(); // 메뉴 리스트 가져오기
        await this.$_setSessionStorageMenu(); // 메뉴 sessionStorage 재설정
      },
      /**
       * @description 메뉴 복사 저장 이벤트
       */
      async handleSaveCopyMenu() {
        this.modalMenuCopy.isOpened = false;
        await this.initData();
      },
      /**
       * @description 페이지 데이터 모달 저장 이벤트
       * @param pageData {object}
       */
      async handleSavePageData(pageData) {
        this.modalMenuPageData.isOpened = false;
        this.selectedMenuData.pageData = pageData;
      },
      /**
       * @description 대메뉴 등록 모달 저장 이벤트
       * @return {Promise<void>}
       */
      async handleSaveRootMenu() {
        this.modalMenuConfig.isOpened = false;
        await this.initData();
      },
      /**
       * @description 하위메뉴 추가 모달 오픈
       */
      handleOpenAddManuModal() {
        this.modalMenuConfig.propOption.isRoot = false;
        this.modalMenuConfig.propOption.title = this.$_lang('COMPONENTS.MENU_ADD', { defaultValue: '메뉴 추가' });
        this.modalMenuConfig.isOpened = true;
      },
      /**
       * @description 페이지 데이터 모달 오픈
       */
      handleOpenPageDataModal() {
        this.modalMenuPageData.isOpened = true;
      },
      /**
       * @description 메뉴폼 저장 이벤트
       * @return {Promise<void>}
       */
      async handleFromSaveButton() {
        await this.initData();
      },
      /**
       * @description 메뉴폼 취소 이벤트
       */
      async handleFromCancelButton() {
        await this.initData();
      },
    },
    async created() {
      await this.initData();
    },
  };
</script>
<style scoped>
  #menu_config_menu_icon .dx-texteditor-input {
    font-size: 13px;
    padding-top: 10px;
    padding-left: 4px;
  }

  .menu-tree-box {
    display: flex;
    justify-content: space-between;
  }

  .menu-tree-between-box {
    width: 50%;
  }

  .menu-tree-between-box:last-child {
    padding-left: 10px;
  }
</style>
