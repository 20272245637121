<!--
  PACKAGE_NAME : src/pages/cc/ivr/notice/config.vue
  FILE_NAME : config
  AUTHOR : hmlee
  DATE : 2024-09-11
  DESCRIPTION : 공지사항 상세 페이지
-->
<template>
	<div class="container">
    <div class="page-sub-box clearfix ui-glid-box top-box">
      <div class="fl">
        <table class="table_form line-bin">
          <caption>
            <strong>{{ $_lang('CC.WORD.NOTICE', { defaultValue: '공지사항'}) }} {{ $_lang('COMPONENTS.DETAIL', { defaultValue: '상세보기' }) }}</strong>
          </caption>
          <colgroup>
            <col style="width: 130px" />
            <col style="width: auto" />
          </colgroup>

          <thead class="sub_title_txt">
            <tr>
              <td colspan="2"><h2>{{ $_lang('CC.WORD.NOTICE', { defaultValue: '공지사항'}) }} {{ $_lang('COMMON.WORD.CONTENT', { defaultValue: '내용' }) }}</h2></td>
            </tr>
          </thead>

          <tbody>
            <template>
              <tr>
                <th scope="row">
                  <label>
                    {{ $_lang('CC.WORD.NOTICE', { defaultValue: '공지사항'}) }} {{ $_lang('COMPONENTS.TITLE', { defaultValue: '제목' }) }}
                    <span class="icon_require">*</span>
                  </label>
                </th>
                <td>
                  <DxTextBox
                    v-model="formData.noticeNm"
                    :max-length="limitNumberTexts.maxLengths.noticeNm"
                    :styling-mode="config.stylingMode"
                    :width="config.textBox.width"
                    @key-up="$_checkLimitTextLength($event, formData, limitNumberTexts, 'noticeNm')"
                  >
                    <DxValidator>
                      <DxRequiredRule :message="$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS', { value: '공지사항 제목' })" />
                    </DxValidator>
                  </DxTextBox>
                  <span class="ml-2">
                    {{
                    limitNumberTexts.textLengths.noticeNm
                      ? limitNumberTexts.textLengths.noticeNm
                      : formData.noticeNm
                      ? formData.noticeNm.length
                      : 0
                    }}/{{ limitNumberTexts.maxLengths.noticeNm }}자
                  </span>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <label>
                    {{ $_lang('COMMON.WORD.CONTENT', { defaultValue: '내용' }) }}
                    <span class="icon_require">*</span>
                  </label>
                </th>
                <td>
                  <DxTextArea
                    v-model="formData.contents"
                    :max-length="limitNumberTexts.maxLengths.contents"
                    :styling-mode="config.stylingMode"
                    :width="config.textArea.width"
                    :height="config.textArea.height"
                    @key-up="$_checkLimitTextLength($event, formData, limitNumberTexts, 'contents')"
                  >
                    <DxValidator>
                      <DxRequiredRule :message="$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS', { value: '내용' })" />
                    </DxValidator>
                  </DxTextArea>
                  <span class="absolute right-4 bottom-4">
                    {{
                    limitNumberTexts.textLengths.contents
                      ? limitNumberTexts.textLengths.contents
                      : formData.contents
                      ? formData.contents.length
                      : 0
                    }}/{{ limitNumberTexts.maxLengths.contents }}자
                  </span>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <label>
                    {{ $_lang('COMPONENTS.PRE_LISTENING', { defaultValue: '미리듣기' }) }}
                  </label>
                </th>
                <td>
                  <div class="center">
                    <DxButton
                      :text="$_lang('COMPONENTS.LISTENING', { defaultValue: '듣기' })"
                      type="button"
                      class="btn_XS white light_filled listen"
                      :height="config.formButton.height"
                      @click="onClickListenNotice(formData.contents)"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <label>
                    {{ $_lang('CC.WORD.DISPLAY_PERIOD', { defaultValue: '노출기간' }) }}
                    <span class="icon_require">*</span>
                  </label>
                </th>
                <td>
                  <DxDateBox
                    class="mr-1"
                    :edit-enabled=config.dtDateBox.editEnabled
                    :styling-mode="config.stylingMode"
                    :width="config.dtDateBox.width"
                    :type="config.dtDateBox.type"
                    :max="formData.endDt"
                    :display-format="config.dtDateBox.displayFormat"
                    :date-serialization-format="config.dtDateBox.dateSerializationFormat"
                    :dateOutOfRangeMessage="config.dtDateBox.endDateOutOfRangeMessage"
                    v-model="formData.startDt"
                  >
                    <DxValidator>
                      <DxRequiredRule :message="$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS', { value: '시작일' })" />
                    </DxValidator>
                  </DxDateBox>
                  <DxDateBox
                    :edit-enabled=config.timeDateBox.editEnabled
                    :styling-mode="config.stylingMode"
                    :width="config.timeDateBox.width"
                    :type="config.timeDateBox.type"
                    :picker-type="config.timeDateBox.pickerType"
                    :interval="config.timeDateBox.interval"
                    :display-format="config.timeDateBox.displayFormat"
                    :date-serialization-format="config.timeDateBox.dateSerializationFormat"
                    v-model="formData.startTime"
                  >
                    <DxValidator>
                      <DxRequiredRule :message="$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS', { value: '시작시간' })" />
                    </DxValidator>
                  </DxDateBox>
                  <span class="ml-2 mr-2"> ~ </span>
                  <DxDateBox
                    class="mr-1"
                    :edit-enabled=config.dtDateBox.editEnabled
                    :styling-mode="config.stylingMode"
                    :width="config.dtDateBox.width"
                    :type="config.dtDateBox.type"
                    :min="formData.startDt"
                    :display-format="config.dtDateBox.displayFormat"
                    :date-serialization-format="config.dtDateBox.dateSerializationFormat"
                    :dateOutOfRangeMessage="config.dtDateBox.endDateOutOfRangeMessage"
                    v-model="formData.endDt"
                  >
                    <DxValidator>
                      <DxRequiredRule :message="$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS', { value: '종료일' })" />
                    </DxValidator>
                  </DxDateBox>
                  <DxDateBox
                    :edit-enabled=config.timeDateBox.editEnabled
                    :styling-mode="config.stylingMode"
                    :width="config.timeDateBox.width"
                    :type="config.timeDateBox.type"
                    :picker-type="config.timeDateBox.pickerType"
                    :interval="config.timeDateBox.interval"
                    :display-format="config.timeDateBox.displayFormat"
                    :date-serialization-format="config.timeDateBox.dateSerializationFormat"
                    v-model="formData.endTime"
                  >
                    <DxValidator>
                      <DxRequiredRule :message="$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS', { value: '종료시간' })" />
                    </DxValidator>
                  </DxDateBox>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <label>
                    {{ $_lang('CC.WORD.END_TYPE', { defaultValue: '종료여부' }) }}
                    <span class="icon_require">*</span>
                  </label>
                </th>
                <td>
                  <DxSelectBox
                    :placeholder="config.selectBox.placeholder"
                    :show-clear-button="config.selectBox.showClearButton"
                    :styling-mode="config.stylingMode"
                    :width="config.selectBox.width"
                    :items="$_enums.cc.stringEndTypeFlag.values"
                    display-expr="label"
                    value-expr="value"
                    v-model="formData.endTypeFl"
                    class="w-full"
                  >
                    <DxValidator>
                      <DxRequiredRule :message="$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS', { value: '종료여부' })" />
                    </DxValidator>
                  </DxSelectBox>
                </td>
              </tr>
              <tr>
                <th scope="row"><label for="label3">{{ $_lang('COMPONENTS.USE_STATUS', { defaultValue: '사용여부' }) }}</label></th>
                <td>
                  <DxSwitch @value-changed="onChangedViewFl($event)" :value="getViewFl" />
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>

		<section class="terms bottom-btn-box">
			<div class="page-sub-box">
				<div class="bottom-btn-wrap">
					<DxButton
						:text="$_lang('COMPONENTS.SAVE', { defaultValue: '저장' })"
						:width="config.bottomButton.width"
						:height="config.bottomButton.height"
						class="default filled txt_S medium"
						:use-submit-behavior="config.bottomButton.useSubmitBehavior"
						@click="onSaveFormData"
            :disabled="this.savingFlag == true"
					/>
					<DxButton
            :text="$_lang('COMPONENTS.CANCEL', { defaultValue: '취소' })"
            :width="config.bottomButton.width"
            :height="config.bottomButton.height"
            class="white filled txt_S medium"
            @click="onCancelFormData"
          />
				</div>
			</div>
		</section>

    <!-- 공지사항 설정 -->
    <ModalListenNotice
      v-if="modal.visible"
      :is-open="modal.visible"
      :notice-file="modal.noticeFile"
      @closeModal="onCloseModal"
    />
	</div>
</template>

<script>
import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
import { DxTextBox } from 'devextreme-vue/text-box';
import DxDateBox from 'devextreme-vue/date-box';
import { DxTextArea } from 'devextreme-vue/text-area';
import { DxButton } from 'devextreme-vue/button';
import {getPastFromToday, isSuccess, formatDate} from '@/plugins/common-lib';
import DxSwitch from "devextreme-vue/switch";
import {DxPopup} from "devextreme-vue/popup";
import ModalListenNotice from '@/pages/cc/ivr/notice/modal-listen-notice.vue';
import {DxSelectBox} from "devextreme-vue/select-box";

export default {
	components: {
    DxSelectBox,
    DxPopup,
    DxSwitch,
		DxValidator,
		DxRequiredRule,
		DxTextBox,
    DxDateBox,
		DxTextArea,
		DxButton,
    ModalListenNotice,
	},

	props: {},

	watch: {},

	data() {
		return {
			config: {
        isUpdated: false,
				stylingMode: 'outlined', //[outlined, filled, underlined]
        textBox: {
          width: 500,
        },
        textArea: {
          width: 500,
          height: 100,
        },
        formButton: {
          height: 30,
        },
        bottomButton: {
          width: 120,
          height: 40,
          useSubmitBehavior: true,
        },
        dtDateBox: {
          editEnabled: false,
          width: 120,
          type: 'date',
          displayFormat: 'yyyy.MM.dd',
          dateSerializationFormat: 'yyyyMMdd',
          endDateOutOfRangeMessage: this.$_lang('CC.MESSAGE.END_DATE_OUT_OF_RANGE', { defaultValue: '종료일은 시작일보다 크거나 같아야 합니다.' }),
          startDateOutOfRangeMessage: this.$_lang('CC.MESSAGE.START_DATE_OUT_OF_RANGE', { defaultValue: '시작일은 종료일보다 작거나 같아야 합니다.' }),
        },
        timeDateBox: {
          editEnabled: false,
          width: 100,
          type: 'time',
          displayFormat: 'HH:mm',
          dateSerializationFormat: 'HH:mm',
          pickerType: 'list',
          interval: 10,
          endDateOutOfRangeMessage: this.$_lang('CC.MESSAGE.END_DATE_OUT_OF_RANGE', { defaultValue: '종료일은 시작일보다 크거나 같아야 합니다.' }),
          startDateOutOfRangeMessage: this.$_lang('CC.MESSAGE.START_DATE_OUT_OF_RANGE', { defaultValue: '시작일은 종료일보다 작거나 같아야 합니다.' }),
        },
        selectBox: {
          placeholder: this.$_lang('COMPONENTS.SELECT', { defaultValue: '선택' }),
          showClearButton: false,
          width: 200,
          noticeList: [],
        },
			},
      modal: {
        visible: false,
        noticeFile: {},
      },
      dnisList: [], // 대표번호 리스트
      formData: {
        id: null,
        noticeNm: null,
        contents: null,
        startDt: getPastFromToday(0, 'days', 'YYYY-MM-DD'),
        startTime: '09:00',
        endDt: getPastFromToday(0, 'days', 'YYYY-MM-DD'),
        endTime: '18:00',
        endTypeFl: 'Y',
        viewFl: 'Y',
        dnisNotices: [],
      },
      limitNumberTexts: {
        textLengths: {},
        maxLengths: {
          noticeNm: 50,
          contents: 200,
        },
      },
      savingFlag: false,
		};
	},

	computed: {
    /** @description: 사용여부 */
    getViewFl() {
      return this.formData.viewFl === 'Y';
    },
	},
	methods: {
    /** @description : 팝업 닫기 */
    onCloseModal() {
      this.modal.visible = false;
      this.modal.noticeFile = {};
    },
    /** @description : 대표번호 리스트 조회 메서드 */
    async selectDnisList() {
      const payload = {
        actionName: 'CC_IVR_DNIS_LIST',
        useErrorPopup: true,
      };

      const res = await this.CALL_CC_API(payload);
      if (isSuccess(res)) {
        this.dnisList = res.data.data;
      }
    },
		/** @description : 데이터 조회 메서드 */
		async selectFormData() {
			const payload = {
				actionName: 'CC_IVR_NOTICE_LIST',
				data: { id: this.formData.id },
				loading: true,
			};
			const res = await this.CALL_CC_API(payload);
			if (isSuccess(res)) {
        this.formData = res.data.data[0];
        this.formData.startTime = formatDate(this.formData.startTime, 'HHmm', 'HH:mm');
        this.formData.endTime = formatDate(this.formData.endTime, 'HHmm', 'HH:mm');
        const dnisNotices = this.dnisList.filter(dnis => dnis.noticeId === this.formData.id);
        this.formData.dnisNotices = [];
        if( dnisNotices.length > 0 ) {
          this.formData.dnisNotices = dnisNotices;
        }
			}
		},
    /** @description : 공지사항 미리듣기 이벤트
      * @params contents : 공지사항 내용 */
    onClickListenNotice(contents) {
      if(contents === null || contents === undefined || contents === "") {
        return this.$_Msg(this.$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS', { value: '내용' }));
      }
      
      // TODO : API에서 TTS 연동 후 wav 파일명 전달 ex) sample.wav
      this.modal.noticeFile = 'sample-3s.wav';
      this.modal.visible = true;
    },
		/** @description : 데이터 저장 메서드 */
		async onSaveFormData(e) {
			if (!e.validationGroup.validate().isValid) {
				return false;
			}

      if( this.formData.viewFl === 'N' && this.formData.dnisNotices.length > 0 ){
        return this.$_Msg(this.$_lang('CC.MESSAGE.NO_DISABLED_NOT_USED_DNIS_NOTICE', { defaultValue: '대표번호에 설정된 공지사항은 미사용 상태로 변경할 수 없습니다.' }));
      }
      const formData = { ...this.formData };
      formData.startDt = formatDate(this.formData.startDt, 'YYYY-MM-DD', 'YYYYMMDD');
      formData.endDt = formatDate(this.formData.endDt, 'YYYY-MM-DD', 'YYYYMMDD');
      formData.startTime = formatDate(this.formData.startTime, 'HH:mm', 'HHmm');
      formData.endTime = formatDate(this.formData.endTime, 'HH:mm', 'HHmm');

      if ((await this.$_Confirm(this.$_lang('CMN_CFM_SAVE_SELECTED', {defaultValue: '선택한 데이터를 저장하시겠습니까?'})))) {
        const payload = {
          actionName: 'CC_IVR_NOTICE_MERGE',
          data: [formData],
          useErrorPopup: true,
        };
        
        this.savingFlag = true;

        const res = await this.CALL_CC_API(payload);
        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
          await this.$router.push('/cc/ivr/notice/list');
          this.savingFlag = false;
        } else {
          this.savingFlag = false;
        }
      } else { 
        return;
      }
		},
    /** @description : 사용여부 이벤트 */
    onChangedViewFl(e) {
      if (e.value) this.formData.viewFl = 'Y';
      else this.formData.viewFl = 'N';
    },
    /** @description : 취소 버튼 클릭시 리스트 이동 */
    onCancelFormData() {
      this.$router.push('/cc/ivr/notice/list');
    },
	},
	async created() {
    this.reqParams = this.$store.getters.getDetailParams;
    if( this.reqParams ) { //수정
      this.config.isUpdated = true;
      this.formData.id = this.reqParams.id;
      await this.selectDnisList();
      await this.selectFormData();
    }else { //등록
      this.config.isUpdated = false;
    }
	},
	destroyed() {
    this.$store.commit('setDetailParams', null);
	},
};
</script>
