var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"locker_setting_list sub_new_style01 sub_ui_box1"},[_c('Search',{attrs:{"codeKey":"search_task_type","searchObj":_vm.searchType.obj,"useKeywordType":true},on:{"change-search-type":_vm.$_changeSearchType,"onSearchClick":function($event){return _vm.$_searchData($event, 'search_task_type')}}}),_c('esp-dx-data-grid',{ref:"taskGrid",attrs:{"data-grid":_vm.dataGrid}}),(_vm.modal.isOpened)?_c('DxPopup',{attrs:{"show-title":true,"title":_vm.modal.initData ? _vm.modal.initData.title : null,"width":_vm.modal.initData ? _vm.modal.initData.width : null,"height":_vm.modal.initData ? _vm.modal.initData.height : null,"drag-enabled":true,"resize-enabled":true,"show-close-button":true,"hide-on-outside-click":false,"visible":_vm.modal.isOpened},on:{"hiding":function($event){return _vm.isOpenModal(false)}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',[(_vm.modal.isOpened)?_c('DxScrollView',{ref:"scrollViewWidget",attrs:{"id":"scrollview","scroll-by-content":_vm.scroll.scrollByContent,"scroll-by-thumb":_vm.scroll.scrollByThumb,"show-scrollbar":_vm.scroll.showScrollbar,"bounce-enabled":_vm.scroll.pullDown,"height":550}},[(_vm.modal.sendData)?_c(_vm.modal.currentComponent,{ref:"modalRef",tag:"component",attrs:{"modalData":_vm.modal.sendData,"isModal":_vm.modal.isModal}}):_vm._e()],1):_vm._e()],1)]},proxy:true}],null,false,528868428)},[_c('DxToolbarItem',{attrs:{"widget":"dxButton","toolbar":"bottom","location":"center","options":{
        elementAttr: {
          class: 'default filled txt_S medium',
        },
        text: _vm.modal.initData.buttons.save.text,
        width: '120',
        height: '40',
        onClick: () => {
          this.onSaveModal();
        },
      }}}),_c('DxToolbarItem',{attrs:{"widget":"dxButton","toolbar":"bottom","location":"center","options":{
        elementAttr: {
          class: 'white filled txt_S medium',
        },
        text: _vm.modal.initData.buttons.cancel.text,
        width: '120',
        height: '40',
        onClick: () => {
          _vm.isOpenModal(false);
        },
      }}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }