<template>
  <div class="page-sub-box">
    <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName"/>
  </div>
</template>

<script>
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid-v2.vue";

export default {
  components: {
    EspDxDataGrid,
  },
  props: {},
  watch: {},
  data() {
    return {
      dataGrid: {
        callApi: 'CALL_CC_API',
        refName: 'attGrid',
        dataSourceDefaultSortColumn: '+attOrd',
        dataSource: [],
        apiActionNm: {
          select: 'CC_ATT_LIST',
          merge: 'CC_ATT_MERGE',
        },
        showActionButtons: {
          add: false,
          save: true,
          delete: false,
        },
        columns: [
          {
            caption: '사이트명',
            i18n: 'COMMON.WORD.SITE_NM',
            dataField: 'siteId',
            allowEditing: false,
            lookup: {
              dataSource: this.$store.getters.getSiteList,
              displayExpr: "siteNm",
              valueExpr: "id"
            },
          },
          {
            caption: '센터명',
            i18n: 'COMMON.WORD.TENANT_NM',
            dataField: 'tenantId',
            lookup: {
              dataSource: this.$store.getters.getTenantList,
              displayExpr: "tenantNm",
              valueExpr: "id"
            },
            requiredRule: {},
          },
          {
            caption: '속성ID',
            i18n: 'CC.WORD.ATTRIBUTE'+'ID',
            dataField: 'id',
            allowEditing: false,
          },
          {
            caption: '속성명',
            i18n: 'CC.WORD.ATTRIBUTE_NAME',
            dataField: 'attNm',
            requiredRule: {},
          },
          {
            caption: '속성',
            i18n: 'CC.WORD.ATTRIBUTE',
            dataField: 'att',
            allowEditing: false,
          },
          {
            caption: '설명',
            i18n: 'CC.WORD.DESCRIPTION',
            dataField: 'description',
            calculateCellValue: this.description,
          },
          {
            caption: '순서',
            i18n: 'COMPONENTS.ORDER',
            dataField: 'attOrd',
          },
          {
            caption: '사용여부',
            i18n: 'COMPONENTS.USE_STATUS',
            dataField: 'viewFl',
            lookup: {
              dataSource: this.$_enums.common.stringUsedFlag.values,
              displayExpr: 'label',
              valueExpr: 'value',
            },
          },
        ]
      },
    }
  },
}
</script>
