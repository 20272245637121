<template>
    <transition>
        <div>
            <DxDataGrid 
                class="grid-box"
                ref="editManagerGrid"
                :allow-column-reordering="true"
                :data-source="editManagerGrid.dataSource"
                :selected-row-keys="editManagerGrid.selectedRowKeys"
                :allow-column-resizing="true"
                :column-resizing-mode="'nextColumn'"
                :show-borders="false"
                :show-column-headers="true"
                :show-column-lines="true"
                :show-row-lines="true"
                :hover-state-enabled="true"
                :word-wrap-enabled="true"
                :no-data-text="this.$_lang('CMN_NO_DATA')"
                @selectionChanged="handleSelectionChanged"
                width="100%"
                height="100%"
            >

                <DxFilterRow :visible="false" />
                <DxSelection mode="single" />

                <DxColumn
                    data-field="agtOrd"
                    caption="No"
                    alignment="center" 
                    width="60"
                />
                <DxColumn
                    data-field="agtid"
                    caption="ID"
                    alignment="center" 
                />
				<DxColumn
                    data-field="agtNm"
                    caption="이름"
                    alignment="center" 
                />
                
            </DxDataGrid>
        </div>
    </transition>
</template>

<script>
import { 
    DxDataGrid, 
	DxColumn, 
	DxSelection, 
	DxFilterRow, 
} from 'devextreme-vue/data-grid';

export default {
    components: {
        DxDataGrid, 
        DxColumn, 
        DxSelection, 
        DxFilterRow,
    },
    props: {
        contentData: Array,
    },
    watch: {
        /** @description: 넘어갈 데이터 */
        'editManagerGrid.selectedRowKeys': {
            handler(val){
                this.$emit('input', val );
            },
            deep: true,
        },
    },
    data() {
        return {
            editManagerGrid: {
				dataSource: null,
				selectedRowsData: [],
				selectedRowKeys: [],
			},
            
        }
    },
    computed: {
        
    },
    methods: {
        getGridData(){
            this.contentData.forEach((e, index) => {
                e.agtOrd = index+1;
            });
            this.editManagerGrid.dataSource = this.contentData;
        },
        handleSelectionChanged(e) {
            this.editManagerGrid.selectedRowKeys = e.selectedRowKeys;
        },
    },
    created() {
        this.getGridData();
    },
    mounted() {
        
    },
}
</script>