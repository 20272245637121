<template>
  <div class="page_search_box line_bottom_1px">
    <div class="inner alL">
      <slot name="before"></slot>
      <DxSelectBox
        :placeholder="getSearchSelectionList[0].codeNm"
        :items="getSearchSelectionList"
        display-expr="codeNm"
        value-expr="codeValue"
        :value="formData.selectedValue.value ? formData.selectedValue.value : getSearchSelectionList[0].codeValue"
        :styling-mode="config.stylingMode"
        :width="
					searchObj && searchObj.selectedValues && searchObj.selectedValues.width
						? searchObj.selectedValues.width
						: formData.selectedValue.width
				"
        :height="
					searchObj && searchObj.selectedValues && searchObj.selectedValues.height
						? searchObj.selectedValues.height
						: formData.selectedValue.height
				"
        @value-changed="onChangeSearchType"
      />
      <DxTextBox
        placeholder="검색어 입력"
        :value="formData.searchText.value"
        :styling-mode="config.stylingMode"
        :width="
					searchObj && searchObj.searchTexts && searchObj.searchTexts.width
						? searchObj.searchTexts.width
						: formData.searchText.width
				"
        :height="
					searchObj && searchObj.searchTexts && searchObj.searchTexts.height
						? searchObj.searchTexts.height
						: formData.searchText.height
				"
        @value-changed="onChangeSearchText"
        @enter-key="onSearchClick"
      />
      <DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="onSearchClick"/>

      <div class="spec-type-box">
        <button
          class="mdi mdi-help-circle"
          id="searchTooltip"
          @mouseenter="setTooltips('search')"
          @mouseleave="setTooltips('search')"
        ></button>
        <DxPopover
          target="#searchTooltip"
          :visible="config.tooltip.isVisible.search"
          :hide-on-outside-click="config.tooltip.closeOnOutsideClick"
        >
          <span v-html="config.tooltip.contents"></span>
        </DxPopover>

        <DxSelectBox
          :placeholder="getSearchSpecList[0].codeNm"
          :items="getSearchSpecList"
          display-expr="codeNm"
          value-expr="codeValue"
          v-model="formData.specValue.value"
          styling-mode="underlined"
          :width="
						searchObj && searchObj.specValues && searchObj.specValues.width
							? searchObj.specValues.width
							: formData.specValue.width
					"
          :height="
						searchObj && searchObj.specValues && searchObj.specValues.height
							? searchObj.specValues.height
							: formData.specValue.height
					"
          @value-changed="onChangedSpec"
        />
      </div>

      <slot name="after"></slot>
    </div>
  </div>
</template>

<script>
import {DxSelectBox} from 'devextreme-vue/select-box';
import {DxTextBox} from 'devextreme-vue/text-box';
import {DxButton} from 'devextreme-vue/button';
import {DxPopover} from 'devextreme-vue/popover';

export default {
  components: {
    DxPopover,
    DxSelectBox,
    DxTextBox,
    DxButton,
  },
  props: {
    codeKey: {
      type: String,
      default: '',
    },
    searchObj: {
      type: Object,
    },
    customSearchList: {
      type: Array,
      default: () => [],
    },
    paramsData: {
      type: Object,
    },
  },
  watch: {
    selectedTabIndex: {
      //탭 변경시 해당 탭의 공통 검색조건/검색어  이력 가져오기
      handler(newVal, oldVal) {
        //탭 변경시 목록 캐시 true로 변경
        if (!this.$_commonlib.isEmpty(oldVal)) {
          this.$store.commit('setListCacheFl', true);
        }

        let currentPath = this.$router.currentRoute.path;
        //목록 캐시 여부 || 검색 캐시 저장 여부 true && 해당 페이지의 탭 변경시 이력 가져오기
        if (
          this.$store.getters.getListCacheFl ||
          (this.config.searchCacheFl && newVal === this.$store.getters.getTabHists[currentPath])
        ) {
          if (
            !this.$_commonlib.isEmpty(this.$store.getters.getSearchHists) &&
            Object.prototype.hasOwnProperty.call(this.$store.getters.getSearchHists, currentPath)
          ) {
            //탭의 변경되면 초기화
            let paramsData = null;
            this.formData.selectedValue.value = null;
            this.formData.searchText.value = null;

            let getSearchHists = this.$_commonlib.cloneObj(this.$store.getters.getSearchHists);
            let searchHistsByCurPage = this.$store.getters.getSearchHists[currentPath]; //해당 페이지 검색 이력
            let searchValIdx = null;
            if (searchHistsByCurPage.length > 0 && Object.prototype.hasOwnProperty.call(searchHistsByCurPage[0], 'tab')) {
              //탭이 있는 페이지의 검색 이력
              searchHistsByCurPage.forEach((d1, index1) => {
                if (d1.tab === newVal) {
                  if (d1.search === null || Object.keys(d1.search).length === 0) {
                    //해당 탭의 검색 이력이 빈 값이면 삭제
                    this.$log.debug('검색 이력이 빈 값이면 삭제');
                    getSearchHists[currentPath].splice(index1, 1);
                    this.$store.commit('setSearchHists', getSearchHists);
                  } else {
                    //해당 탭의 검색 이력 값이 있으면 셋팅
                    this.$log.debug('탭 있는 페이지의 검색 이력 값이 있으면 셋팅');
                    //공통 검색조건/검색어 인덱스 찾기
                    //let codeValues = this.$parent.$parent.tabs[val].$children.find(d => d.getSearchSelectionList).getSearchSelectionList.map(d2 => d2.codeValue);
                    //tab 컴포넌트가 페이지 마다 다를 수 있어서 root에서 찾는 걸로 변경
                    //let currentTabComponent = (this.$root.$children[0].$children.find(d => Object.prototype.hasOwnProperty.call(d.$refs, 'tabs')).$refs).tabs.tabs[val];
                    //let codeValues = currentTabComponent.$children.find(d => d.getSearchSelectionList).getSearchSelectionList.map(d2 => d2.codeValue);
                    //공통 검색조건/검색어 인덱스 찾기
                    //검색조건 키값 list
                    let codeValues = this.getSearchSelectionList.map(d2 => d2.codeValue);
                    if (!this.$_commonlib.isEmpty(d1.search)) {
                      Object.keys(d1.search).forEach((d2, index2) => {
                        if (codeValues.includes(d2)) {
                          searchValIdx = index2;
                        }
                      });
                    }
                    if (searchValIdx !== null) {
                      this.$set(this.formData.selectedValue, 'value', Object.keys(d1.search)[searchValIdx]);
                      this.$set(
                        this.formData.searchText,
                        'value',
                        Object.values(d1.search)[searchValIdx].replace(/\%/g, ''),
                      );

                      //specType 세팅
                      this.formData.specValue.value = d1.specType;

                      let searchText = this.getSearchTextBySpec(); //specification에 따른 검색어
                      paramsData = {[this.formData.selectedValue.value]: searchText};
                    }
                  }

                  this.$emit('onSearchClick', paramsData);
                }
              });
            }
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      config: {
        searchCacheFl: null, //검색 캐시 저장 여부
        stylingMode: 'outlined',
        tooltip: {
          isVisible: {
            search: false,
          },
          closeOnOutsideClick: false,
          contents: `시작 : 검색 키워드로 시작하는 항목
                        <br/>
                        포함 : 검색 키워드를 포함하는 항목
                        <br/>
                        끝 : 검색 키워드로 끝나는 항목
                        <br/>
                        일치 : 검색 키워드와 정확히 일치하는 항목
                        <br/>
                        <br/>
                        ※ 일치 검색일 경우 쉼표(,)로 구분하여 두 가지 이상의 조건으로 검색할 수 있습니다.
                        `,
        },
      },
      formData: {
        defaultObj: {id: 'ROOT', codeValue: 'ROOT', codeNm: '전체'},
        defaultValue: 'root',
        selectedValue: {
          width: 150,
          height: 30,
          value:
            this.searchObj && this.searchObj.selectedValues && this.searchObj.selectedValues.value
              ? this.searchObj.selectedValues.value
              : null,
        },
        searchText: {
          width: 494,
          height: 30,
          value:
            this.searchObj && this.searchObj.searchTexts && this.searchObj.searchTexts.value
              ? this.searchObj.searchTexts.value
              : null,
        },
        specValue: {
          width: 100,
          height: 30,
          value:
            this.searchObj && this.searchObj.searchSpecs && this.searchObj.searchSpecs.value
              ? this.$_getCode('search_spec')[0].codeValue
              : null,
        },
      },
    };
  },
  computed: {
    /** @description: 검색조건 리스트 */
    getSearchSelectionList() {
      let searchSelections = this.codeKey === 'null' ? this.customSearchList : this.$_getCode(this.codeKey);
      this.formData.selectedValue.value = searchSelections[0].codeValue;
      return searchSelections;
    },
    selectedTabIndex() {
      return this.searchObj && this.searchObj.selectedTabIndex ? this.searchObj.selectedTabIndex : 0;
    },
    /** @description: 검색 스펙 리스트 */
    getSearchSpecList() {
      let searchSpecList = this.$_getCode('search_spec');
      this.formData.specValue.value = this.$_getSystemData('search_option_default_value')
        ? this.$_getSystemData('search_option_default_value').configValue.toLowerCase()
        : searchSpecList[0].codeValue;
      return searchSpecList;
    },
  },
  methods: {
    /** @description: 검색조건 selectbox 변경 이벤트 */
    onChangeSearchType(event) {
      if (event.event) {
        //검색조건 변경시
        this.formData.selectedValue.value = event.value; //검색 조건 셋팅

        if (event.previousValue !== null) {
          //검색조건 전체로 변경시
          //store의 이전 검색 이력 삭제
          let currentPath = this.$router.currentRoute.path;
          let getSearchHists = this.$_commonlib.cloneObj(this.$store.getters.getSearchHists);
          if (Object.prototype.hasOwnProperty.call(this.$store.getters.getSearchHists, currentPath)) {
            //해당 페이지 검색 이력이 있는지 체크
            let searchHistsByCurPage = this.$store.getters.getSearchHists[currentPath]; //해당 페이지 검색 이력
            if (Object.prototype.hasOwnProperty.call(searchHistsByCurPage[0], 'tab')) {
              //탭이 있는 검색 이력인 경우
              searchHistsByCurPage.forEach((d, index) => {
                let tabIndex = this.$store.getters.getTabHists[currentPath];
                if (d.tab === tabIndex) {
                  //해당 탭의 이전 검색 조건 삭제
                  this.$delete(getSearchHists[currentPath][index].search, event.previousValue);
                }
              });
            } else {
              //탭이 없는 검색 이력인 경우
              //이전 검색 조건 삭제
              this.$delete(getSearchHists[currentPath][0].search, event.previousValue);
            }

            this.$store.commit('setSearchHists', getSearchHists);
          }
        }

        this.$emit('change-search-type', this.getSearchSelectionList);
      }
    },
    /** @description: 검색어 입력 이벤트 */
    onChangeSearchText(event) {
      //검색어 변경시 이벤트
      this.formData.searchText.value = event.value; //검색어 셋팅
    },
    /** @description: 검색 버튼 클릭 이벤트 */
    onSearchClick() {
      //검색 selectbox 값이 null이거나 전체(root)일 경우
      let paramsData = null;
      if (
        this.$_commonlib.isEmpty(this.formData.selectedValue.value) ||
        this.formData.selectedValue.value.toLowerCase() === this.formData.defaultValue
      ) {
        this.formData.selectedValue.value = null;
        paramsData = null;
      } else {
        if (this.$_commonlib.isEmpty(this.formData.searchText.value)) {
          paramsData = null;
        } else {
          let searchText = this.getSearchTextBySpec(); //specification에 따른 검색어
          paramsData = {[this.formData.selectedValue.value]: searchText};
        }
      }

      this.$emit('onSearchClick', paramsData);

      //store에 검색 이력 저장
      let currentPath = this.$router.currentRoute.path;
      let searchHist = {};
      let specType = this.formData.specValue.value;
      //해당 페이지 검색 이력 있으면 변경
      if (
        this.$store.getters.getSearchHists !== null &&
        Object.prototype.hasOwnProperty.call(this.$store.getters.getSearchHists, currentPath)
      ) {
        this.$log.debug('해당 페이지에 검색 이력이 있으면');
        let searchHistsByCurPage = this.$store.getters.getSearchHists[currentPath]; //해당 페이지 검색 이력 데이터
        if (Object.prototype.hasOwnProperty.call(this.$store.getters.getTabHists, currentPath)) {
          //탭이 있는 페이지의 검색 이력이 있는 경우
          let tabIndex = this.$store.getters.getTabHists[currentPath];
          let searchHistByTab = searchHistsByCurPage.find(d => d.tab === tabIndex);
          if (searchHistByTab) {
            //이미 한번 검색한 이력이 있음(해당 탭의 검색 이력 데이터 변경)
            this.$log.debug('이미 한번 검색한 이력이 있음(해당 탭의 검색 이력 데이터 변경)');
            //해당 탭의 이전 검색 조건 이력 삭제
            let codeValues = this.getSearchSelectionList.map(d => d.codeValue);
            //Object.keys(searchHistByTab.search).forEach((key, index) => {
            if (!this.$_commonlib.isEmpty(searchHistByTab.search)) {
              Object.keys(searchHistByTab.search).forEach((key, index) => {
                if (codeValues.includes(key)) {
                  delete searchHistByTab.search[key]; //검색 조건 키 삭제
                }
              });
            }
            //현재 검색조건 이력을 merge
            searchHistByTab.search = {...searchHistByTab.search, ...paramsData};
            searchHistByTab.specType = specType;
          } else {
            //해당 탭의 검색 이력이 없으면 데이터 추가
            this.$log.debug('해당 탭의 검색 이력이 없으면 데이터 추가');
            searchHistsByCurPage.push({tab: tabIndex, search: paramsData, specType: specType});
            searchHist = {[currentPath]: searchHistsByCurPage};
          }
        } else {
          //탭이 없는 페이지의 검색 이력이 있는 경우
          this.$log.debug('탭이 없는 페이지의 검색 이력이 있는 경우');
          if (
            this.$_commonlib.isEmpty(searchHistsByCurPage[0].search) ||
            Object.keys(searchHistsByCurPage[0].search).length === 0
          ) {
            //검색 이력은 있지만 빈 오브젝트 일 경우
            searchHist = {[currentPath]: [{search: paramsData, specType: specType}]};
          } else {
            //검색 이력이 있는 경우

            //해당 탭의 이전 검색 조건 이력 삭제 2022-06-10 추가
            let codeValues = this.getSearchSelectionList.map(d => d.codeValue);
            if (!this.$_commonlib.isEmpty(searchHistsByCurPage[0].search)) {
              Object.keys(searchHistsByCurPage[0].search).forEach((key, index) => {
                if (codeValues.includes(key)) {
                  delete searchHistsByCurPage[0].search[key]; //검색 조건 키 삭제
                }
              });
            }
            searchHistsByCurPage[0].search = {...searchHistsByCurPage[0].search, ...paramsData};
            searchHistsByCurPage[0].specType = specType;
          }
        }
      } else {
        //해당 페이지 검색 이력이 없으면 추가
        this.$log.debug('해당 페이지 검색 이력이 없으면');
        if (Object.prototype.hasOwnProperty.call(this.$store.getters.getTabHists, currentPath)) {
          //탭이 페이지의 검색 이력이 없는 경우
          let tabIndex = this.$store.getters.getTabHists[currentPath];
          searchHist = {[currentPath]: [{tab: tabIndex, search: paramsData, specType: specType}]};
        } else {
          //탭이 없는 페이지의 검색 이력이 없는 경우
          searchHist = {[currentPath]: [{search: paramsData, specType: specType}]};
        }
      }

      //store에 검색 이력 저장
      let searchHists = {...this.$store.getters.getSearchHists, ...searchHist};
      this.$log.debug(searchHists);
      this.$store.commit('setSearchHists', searchHists);
    },
    /** @description: 검색 spec 변경시 */
    onChangedSpec(e) {
    },
    /** @description: 검색 spec 툴팁 이벤트 */
    setTooltips(key) {
      this.config.tooltip.isVisible[key] = !this.config.tooltip.isVisible[key];
    },
    /** @description: specification에 따른 검색어 */
    getSearchTextBySpec() {
      let specValue = this.formData.specValue.value;
      let searchText = this.formData.searchText.value;
      if (searchText) {
        switch (specValue) {
          case 'like':
            searchText = `%${searchText}%`;
            break;
          case 'equal':
            searchText = this.formData.searchText.value;
            break;
          case 'or':
            searchText = this.formData.searchText.value;
            break;
          case 'not':
            searchText = `<>${searchText}`;
            break;
          case 'startWith':
            searchText = `${searchText}%`;
            break;
          case 'endWith':
            searchText = `%${searchText}`;
            break;
          default:
            searchText = `%${searchText}%`;
            break;
        }
      }
      return searchText;
    },
    getNotTabSearchHist() {
      //탭이 없는 페이지의 공통 검색조건/검색어 데이터 바인딩 및 해당 페이지로 emit
      let currentPath = this.$router.currentRoute.path;
      let getSearchHists = this.$_commonlib.cloneObj(this.$store.getters.getSearchHists);
      this.$log.debug(this.$store.getters.getSearchHists);
      this.$log.debug(this.$_commonlib.isEmpty(this.$store.getters.getSearchHists));
      if (
        !this.$_commonlib.isEmpty(this.$store.getters.getSearchHists) &&
        Object.prototype.hasOwnProperty.call(this.$store.getters.getSearchHists, currentPath)
      ) {
        let searchHistsByCurPage = this.$store.getters.getSearchHists[currentPath]; //해당 페이지 검색 이력
        if (searchHistsByCurPage.length > 0 && !Object.prototype.hasOwnProperty.call(searchHistsByCurPage[0], 'tab')) {
          //탭이 없는 페이지의 검색 이력 셋팅
          let paramsData = null;
          //검색 이력이 빈 값이면 현재 페이지 검색 이력 object 제거
          if (searchHistsByCurPage[0].search === null || Object.keys(searchHistsByCurPage[0].search).length === 0) {
            this.$delete(getSearchHists, currentPath);
            this.$store.commit('setSearchHists', getSearchHists);
          } else {
            //탭이 없는 페이지의 검색 이력이 있는 경우

            //공통 검색조건/검색어 인덱스 찾기
            let searchValIdx = null;
            let codeValues = this.getSearchSelectionList.map(d => d.codeValue);
            Object.keys(searchHistsByCurPage[0].search).forEach((d1, index) => {
              if (codeValues.includes(d1)) {
                searchValIdx = index;
              }
            });

            if (searchValIdx !== null) {
              this.formData.selectedValue.value = Object.keys(searchHistsByCurPage[0].search)[searchValIdx];
              this.formData.searchText.value = Object.values(searchHistsByCurPage[0].search)[searchValIdx].replace(/\%/g, '');

              //specType 세팅
              this.formData.specValue.value = searchHistsByCurPage[0].specType;

              let searchText = this.getSearchTextBySpec(); //specification에 따른 검색어
              paramsData = {[this.formData.selectedValue.value]: searchText};
            }
          }
          this.$emit('onSearchClick', paramsData);
        }
      }
    },
    mountedData() {
      //검색 캐시 저장 여부
      let searchCacheFl = this.$_getSystemData('search_cache_fl') ? this.$_getSystemData('search_cache_fl').configValue : null;
      this.config.searchCacheFl = this.$_commonlib.isTrue(searchCacheFl);
      // 목록 캐시 저장 여부 true || 검색 캐시 저장 true시
      if (this.$store.getters.getListCacheFl || this.config.searchCacheFl) {
        // 탭이 없는 페이지의 공통 검색조건/검색어 데이터 바인딩 및 해당 페이지로 emit
        // 탭이 있는 페이지의 검색 이력 데이터 바인딩은 watch로 함(탭의 index 값에 따라 재설정해야 되므로)
        this.getNotTabSearchHist(); //캐시 저장 이력 가져오기
      }
      // TODO : 검색 캐시 저장 false시 검색 이력 삭제 필요
    },
  },
  created() {
  },
  mounted() {
    this.mountedData();
  },
};
</script>

<style scoped>
.page_search_box .inner div {
  display: inline-block;
}

.page_search_box .inner > div:not(.box-btn-search) {
  vertical-align: middle;
  margin-right: 10px;
}

.page_search_box .spec-type-box {
  display: inline-block;
}

.page_search_box .inner .box-btn-search {
  margin-right: 15px;
}

.page_search_box #searchTooltip {
  margin-right: 5px;
  font-size: 22px;
  color: #808080;
}
</style>
