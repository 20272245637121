<!--
  PACKAGE_NAME : src\pages\esp\system\menu\main-page\list.vue
  FILE_NAME : list
  AUTHOR : devyoon91
  DATE : 2024-08-29
  DESCRIPTION : 메인 페이지 설정
-->
<template>
  <div class="page-sub-box">
    <div class="flex gap-x-6 h-auto">
      <div class="w-6/12">
        <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" @row-click="handleRowClick" />
      </div>
      <div class="w-6/12 pad_top10">
        <edit-form
          :key="selectedDataRowId"
          ref="editForm"
          :disableInfo="disableInfo"
          :selectedData="selectedData"
          :dashBoardList="dashBoardList"
          @saveEditForm="onSaveEditForm"
          @closeEditForm="onCloseEditForm"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import EditForm from './edit-form.vue';
  import { isSuccess } from '@/plugins/common-lib';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid-v2.vue';

  export default {
    components: {
      EspDxDataGrid,
      EditForm,
    },
    data() {
      return {
        dashBoardList: [],
        itemList: [],
        dataGrid: {
          keyExpr: 'id',
          refName: 'mainSettingList',
          dataSource: [],
          apiActionNm: {},
          page: {
            enabled: true,
          },
          showActionButtons: {
            // 그리드 버튼 노출 설정값
            update: false,
            delete: false,
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: this.$_lang('COMPONENTS.ADD', { defaultValue: '추가' }),
                  elementAttr: { class: 'btn_XS default filled add1' },
                  width: 60,
                  height: 30,
                  onClick: () => {
                    this.handleRefreshGridData();
                  },
                },
                location: 'before',
              },
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: this.$_lang('COMPONENTS.DELETE', { defaultValue: '삭제' }),
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 60,
                  height: 30,
                  onClick: () => {
                    this.deleteMainPage();
                  },
                },
                location: 'before',
              },
            ],
          },
          editing: {
            allowUpdating: false,
            allowDeleting: false,
            allowAdding: false,
            mode: 'row',
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'onClick', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: 'ID',
              dataField: 'id',
              width: 80,
            },
            {
              caption: '설정 대상',
              i18n: 'COMMON.WORD.SETTING_TARGET',
              dataField: 'targetField',
              lookup: {
                dataSource: this.$_enums.common.mainTargetField.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
            {
              caption: '메인 페이지 타입',
              i18n: 'COMMON.WORD.MAIN_PAGE_TYPE',
              dataField: 'pageType',
              lookup: {
                dataSource: this.$_enums.common.mainPageType.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
            {
              caption: '수정일시',
              i18n: 'COMPONENTS.MODIFY_DATE_TIME',
              dataType: 'date',
              dataField: 'editDt',
              format: 'yyyy-MM-dd HH:mm:ss',
            },
          ],
        },
        selectedDataRowId: 0,
        selectedData: {},
      };
    },
    computed: {
      disableInfo() {
        const items = this.itemList || [];
        return items.reduce(
          (acc, item) => {
            acc[item.targetField] = acc[item.targetField] || [];
            const validValues = item.targetIds.split(',').filter(value => value.trim() !== '');
            acc[item.targetField].push(...validValues);
            return acc;
          },
          {
            [this.$_enums.common.mainTargetField.AUTH_ID.value]: [],
            [this.$_enums.common.mainTargetField.LOGIN_ID.value]: [],
          },
        );
      },
    },
    methods: {
      /**
       * @description 그리드 클릭 이벤트
       * @param e
       */
      handleRowClick(e) {
        this.selectedDataRowId = e.data.id;
        this.selectedData = e.data;
      },
      /**
       * @description 그리드 데이터 갱신
       */
      handleRefreshGridData() {
        this.$refs.mainSettingList.refreshData();
        this.$refs.mainSettingList.clearSelection();
        this.selectedDataRowId = 0;
        this.selectedData = null;
        this.itemList = [...this.$refs.mainSettingList?.getItems];
      },
      /**
       * @description 데이터 삭제 이벤트
       * @return {Promise<void>}
       */
      async deleteMainPage() {
        const selectedRows = this.$refs.mainSettingList.getInstance.getSelectedRowsData();
        if (selectedRows.length === 0) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }

        if (await this.$_Confirm(this.$_lang('CMN_CFM_DELETE_SELECTED', { defaultValue: '선택한 데이터를 삭제하시겠습니까?' }))) {
          const payload = {
            actionName: 'MENU_MAIN_PAGE_DELETE',
            data: {
              data: selectedRows.map(row => row.id),
            },
            useErrorPopup: true,
          };

          const res = await this.CALL_API(payload);
          if (isSuccess(res)) {
            this.handleRefreshGridData();
          }
        }
      },
      /**
       * 메인 페이지 설정 정보 조회
       *
       * @return {Promise<{data: *, totalCount}>}
       */
      async onSearch() {
        const vm = this;
        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          async load(loadOptions) {
            const params = vm.$_getDxDataGridParam(loadOptions);

            const payload = {
              actionName: 'MENU_MAIN_PAGE_LIST',
              data: { ...params },
              loading: false,
              useErrorPopup: true,
            };

            let rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await vm.CALL_API(payload);
            if (isSuccess(res)) {
              rtnData = {
                data: res.data.data,
                totalCount: res.data.header.totalCount,
              };
            }
            return rtnData;
          },
        });
      },
      /**
       * @description 수정폼 저장 이벤트
       */
      onSaveEditForm() {
        this.handleRefreshGridData();
      },
      /**
       * @description 수정폼 닫기 이벤트
       */
      onCloseEditForm() {
        this.handleRefreshGridData();
      },
    },
    created() {},
    mounted() {
      this.onSearch();
    },
  };
</script>
<style scoped></style>
