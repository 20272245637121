<!--
  PACKAGE_NAME : src/pages/esp/system/menu
  FILE_NAME : menu-add-form.vue
  AUTHOR : devyoon91
  DATE : 2025-01-06
  DESCRIPTION : 
-->
<template>
  <div class="menu-add-form-container">
    <menu-add-default-form
      :menu-data="menuData"
      :ref="menuAddDefaultForm.refName"
      :validation-group-name="menuAddDefaultForm.validationGroupName"
      :show-title="menuAddDefaultForm.showTitle"
      @handleOpenPageDataModal="handleOpenPageDataModal"
      @handleOpenAddManuModal="handleOpenAddManuModal"
    />
    <div class="toolbar-bottom">
      <div class="toolbar-item">
        <dx-button
          :text="$_lang('COMPONENTS.SAVE', { defaultValue: '저장' })"
          @click="handleSaveModal"
          class="default filled txt_S medium"
          width="120"
          height="40"
          :validation-group="menuAddDefaultForm.validationGroupName"
        />
        <dx-button
          :text="$_lang('COMPONENTS.CANCEL', { defaultValue: '취소' })"
          @click="handleCloseModal"
          class="white filled txt_XS medium"
          width="120"
          height="40"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import MenuAddDefaultForm from './menu-add-default-form.vue';
  import { DxButton } from 'devextreme-vue/button';
  import { isSuccess } from '@/plugins/common-lib';

  export default {
    components: { MenuAddDefaultForm, DxButton },
    props: {
      menuData: Object,
    },
    data() {
      return {
        menuAddDefaultForm: {
          refName: 'menuAddFromRef',
          validationGroupName: 'menuAddFromValidationGroup',
          showTitle: true,
        },
      };
    },
    computed: {
      enums() {
        return this.$_enums;
      },
      theme() {
        return this.$_theme;
      },
    },
    methods: {
      /**
       * 페이지 데이터 모달 오픈
       */
      handleOpenPageDataModal() {
        this.$emit('handleOpenPageDataModal');
      },
      /**
       * 하위메뉴 추가 모달 오픈
       */
      handleOpenAddManuModal() {
        this.$emit('handleOpenAddManuModal');
      },
      /**
       * @description 메뉴 저장
       * @return {Promise<boolean>}
       */
      async saveMenu() {
        const formData = this.$refs[this.menuAddDefaultForm.refName].getFormData();
        const payload = {
          actionName: 'MENU_LIST_SAVE',
          data: [formData],
          useErrorPopup: true,
        };

        const res = await this.CALL_API(payload);
        return !!isSuccess(res);
      },
      /**
       * @description 저장 버튼 클릭
       * @param e
       * @return {boolean}
       */
      async handleSaveModal(e) {
        // 메뉴가 선택되어 있지 않은 경우
        if (!this.menuData?.id) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }

        // 유효성 검사
        if (!e.validationGroup.validate().isValid) {
          return false;
        }

        const result = await this.saveMenu(e);
        if (result) {
          this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
          this.$refs[this.menuAddDefaultForm.refName].initData();
          this.$emit('handleSaveButton');
        }
      },
      /**
       * @description 취소 버튼 클릭
       */
      handleCloseModal() {
        this.$refs[this.menuAddDefaultForm.refName].initData();
        this.$emit('handleCancelButton');
      },
      /**
       * @description 데이터 초기화
       */
      initData() {
        this.$refs[this.menuAddDefaultForm.refName].initData();
      },
    },
    created() {},
    mounted() {},
  };
</script>

<style lang="scss" scoped>
  .menu-add-form-container {
    display: flex;
    flex-direction: column; /* 위에서 아래로 정렬 */
    height: 100%; /* 컨테이너가 부모에 꽉 차도록 설정 */
  }

  .toolbar-bottom {
    margin-top: auto; /* 푸터 영역처럼 항상 맨 아래로 위치 */
    padding-top: 10px; /* 추가 여백 */
    display: flex; /* Flexbox 적용 */
    justify-content: center; /* 자식 요소(버튼들)를 수평 중앙 정렬 */
  }

  .toolbar-item {
    gap: 8px;
    display: flex;
    justify-content: center; /* 수평 가운데 정렬 */
    align-items: center; /* 수직 가운데 정렬 */
  }
</style>
