import { mapActions, mapGetters } from 'vuex';
import { espApi } from '@/api';
import { isSuccess } from '@/plugins/common-lib';
import { setAddDynamicRoute } from '@/router/router-utils';
import { getRegexPattern } from './common-lib';

const mixin = {
  components: {},
  data() {
    return {
      config: {
        // pageData 관련 설정
        pageSetting: {
          config: {},
          contentsData: {},
        },
      },
    };
  },
  computed: {
    ...mapGetters([]),
    // FIXME : report_start_dt가 여기에 등록해서 쓸 정도로 필요한 설정인지 검토 필요
    $_getReportStartDt() {
      return this.$_getSystemData('report_start_dt')?.configValue || null;
    },
    // FIXME : U_CODE.VIEW_CD 사용 하고 있음 제거 필요
    /** @description: 사용여부 코드 값에서 사용인 데이터 가져오기 */
    $_getUsedCode() {
      return this.$_getCode('view').find(d => Number(d.codeValue) === 1);
    },
    // FIXME : U_CODE.VIEW_CD 사용 하고 있음 제거 필요
    /** @description: 사용여부 코드 값에서 미사용인 데이터 가져오기 */
    $_getUnUsedCode() {
      return this.$_getCode('view').find(d => Number(d.codeValue) === 0);
    },
  },
  methods: {
    ...mapActions([
      'CALL_API',
      'CALL_EWM_API',
      'CALL_EUC_API',
      'CALL_REPORT_API',
      'CALL_CC_API',
      'CALL_LLM_TESTER_API',
      'CALL_FORECASTING_API',
      'CALL_EMC_API',
    ]),
    /**
     * @description : store에서 codeKey에 해당하는 codeList를 가져옴
     * @param {*} codeKey
     * @returns
     */
    $_getCode(codeKey) {
      return Object.values(this.$store.getters.getCodeList)
        .flatMap(item => item.codes)
        .filter(d => d.codeKey === codeKey)
        .sort((a, b) => a.codeOrd - b.codeOrd);
    },
    /**
     * @description : store에서 configKey에 해당하는 systemList 데이터 가져옴
     * @param configKey
     * @returns {*} {configKey, configValue, configDesc}
     */
    $_getSystemData(configKey) {
      return this.$store.getters.getSystemList.find(d => d.configKey === configKey);
    },
    /**
     * @description : 팝업 메시지 내용 조회
     * @param messageKey : string 등록된 메세지키 입력
     * @param options : object 메세지 내용에 대한 옵션
     * @returns {*}
     */
    $_lang(messageKey, options = {}) {
      const pattern = /^[A-Z]+[.]/;
      if (!pattern.test(messageKey)) {
        // 메세지 키가 알파벳. 으로 시작하지 않을 경우 COMMON.MESSAGE. 를 붙여줌
        messageKey = `COMMON.MESSAGE.${messageKey}`;
      }

      // 메세지 키가 존재하는지 확인
      if (this.$i18n.te(messageKey)) {
        return this.$i18n.t(messageKey, options);
      } else {
        return options?.defaultValue ? options.defaultValue : messageKey;
      }
    },
    /**
     * @description : DxDataGrid에서 각 옵션에 해당하는 params 데이터를 반환
     * @param {*} loadOptions
     * @returns
     */
    $_getDxDataGridParam(loadOptions) {
      let params = {};
      const options = ['take', 'skip', 'requireTotalCount', 'requireGroupCount', 'sort', 'filter', 'totalSummary', 'group', 'groupSummary'];

      options.forEach(i => {
        if (i in loadOptions && loadOptions[i]) {
          switch (i) {
            case 'take':
              params.pagesize = loadOptions[i];
              break;

            case 'skip':
              params.currentpage = Math.round(loadOptions[i] / params.pagesize + 1);
              break;

            case 'sort':
              params.sort = loadOptions[i].map(el => `${el.desc ? '-' : '+'}${el.selector}`).join(',');
              break;

            case 'group':
              params.selector = loadOptions[i][0].selector;
              break;

            case 'filter':
              if (Array.isArray(loadOptions[i][0])) {
                let depth1Op = loadOptions[i][1];

                loadOptions[i].forEach(el => {
                  if (Array.isArray(el)) {
                    let depth2Op = el[1];

                    el.forEach(d => {
                      if (Array.isArray(d)) {
                        params[d[0]] = this.setFilter(d[0], d[1], d[2]);

                        if (depth2Op === 'or' && d[1] === '=') {
                          params[d[0]] += params[d[0]] && params[d[0]].split(',').length === 1 ? 'and,' + d[2] : ',' + d[2];
                        }
                      }
                    });
                  } else {
                    params[el[0]] = this.setFilter(el[0], el[1], el[2]);

                    if (depth1Op === 'or' && el[1] === '=') {
                      params[el[0]] += params[el[0]] && params[el[0]].split(',').length === 1 ? 'and,' + el[2] : ',' + el[2];
                    }
                  }
                });
              } else {
                params[loadOptions[i][0]] = this.setFilter(loadOptions[i][0], loadOptions[i][1], loadOptions[i][2]);
              }
              break;
          }
        }
      });

      if (!params.currentpage) {
        params.currentpage = 1;
      }

      Object.keys(params).forEach(el => {
        if (isNaN(params[el])) {
          params[el] = params[el].replace('undefined,', '');
        }
      });

      return params;
    },
    /**
     * $_getDxDataGridParam 에서 사용되는 filter 적용
     * */
    setFilter(field, operator, value) {
      switch (operator) {
        case 'contains':
          return `%${value}%`;
        case '=':
          return value;
        case 'notcontains':
          return `!%${value}`;
        case 'startswith':
          return `${value}%`;
        case 'endswith':
          return `%${value}`;
        case '<>':
          return `<>${value}`;
        case '<=':
          return `<=${value}`;
        case '>=':
          return `>=${value}`;
        case '<':
          return `<${value}`;
        case '>':
          return `>${value}`;
      }
    },
    /**
     * @description : store에 커스텀 검색조건 이력 값 저장
     * @param {*} : search paramsData
     */
    $_setSearchHistsCustomType(paramsData) {
      let currentPath = this.$router.currentRoute.path;
      let searchHist = {};
      //해당 페이지 검색 이력 있으면 변경
      if (
        this.$store.getters.getSearchHists !== null &&
        Object.prototype.hasOwnProperty.call(this.$store.getters.getSearchHists, currentPath)
      ) {
        this.$log.debug('해당 페이지에 검색 이력이 있으면');
        let searchHistsByCurPage = this.$store.getters.getSearchHists[currentPath]; //해당 페이지 검색 이력 데이터
        if (Object.prototype.hasOwnProperty.call(this.$store.getters.getTabHists, currentPath)) {
          //탭이 있는 페이지의 검색 이력이 있는 경우
          let tabIndex = this.$store.getters.getTabHists[currentPath];
          let searchHistByTab = searchHistsByCurPage.find(d => d.tab === tabIndex);
          if (searchHistByTab) {
            //이미 한번 검색한 이력이 있음(해당 탭의 검색 이력 데이터 변경)
            this.$log.debug('이미 한번 검색한 이력이 있음(해당 탭의 검색 이력 데이터 변경)');
            searchHistByTab.search = paramsData;
          } else {
            //해당 탭의 검색 이력이 없으면 데이터 추가
            this.$log.debug('해당 탭의 검색 이력이 없으면 데이터 추가');
            searchHistsByCurPage.push({ tab: tabIndex, search: paramsData });
            searchHist = { [currentPath]: searchHistsByCurPage };
          }
        } else {
          //탭이 없는 페이지의 검색 이력이 있는 경우
          searchHist = { [currentPath]: [{ search: paramsData }] };
          /* if( Object.keys(searchHistsByCurPage[0].search).length === 0 ){ //검색 이력은 있지만 빈 오브젝트 일 경우
                        searchHist = { [currentPath]: [{ search: paramsData }] };
                    }else { //검색 이력이 있는 경우
                        //공통 검색조건/검색어 인덱스 찾기
                        Object.entries(searchHistsByCurPage[0].search).forEach((key, value) => {
                            if( key === this.formData.selectedValue.value ){
                                value = this.formData.searchText.value;
                            }else {
                                searchHistsByCurPage[0].search = { ...searchHistsByCurPage[0].search, ...paramsData };
                            }
                        });
                    } */
        }
      } else {
        //해당 페이지 검색 이력이 없으면 추가
        this.$log.debug('해당 페이지 검색 이력이 없으면');
        if (Object.prototype.hasOwnProperty.call(this.$store.getters.getTabHists, currentPath)) {
          //탭이 페이지의 검색 이력이 없는 경우
          let tabIndex = this.$store.getters.getTabHists[currentPath];
          searchHist = { [currentPath]: [{ tab: tabIndex, search: paramsData }] };
        } else {
          //탭이 없는 페이지의 검색 이력이 없는 경우
          searchHist = { [currentPath]: [{ search: paramsData }] };
        }
      }
      this.$log.debug(searchHist);

      //store에 검색 이력 저장
      let searchHists = { ...this.$store.getters.getSearchHists, ...searchHist };
      this.$store.commit('setSearchHists', searchHists);
    },
    /**
     * @description : store에서 커스텀 검색조건 이력 가져옴
     * @param {*} customTypes: 커스텀 검색조건 obj
     * @param {*} tabIndex: 탭 인덱스
     */
    $_getSearchHistsCustomType(customTypes, tabIndex) {
      //커스텀 검색 이력 불러오기
      let currentPath = this.$router.currentRoute.path;
      // let getSearchHists = this.$_commonlib.cloneObj(this.$store.getters.getSearchHists);     //해당 페이지 검색 이력 데이터
      // this.$delete(getSearchHists, currentPath);
      // this.$store.commit('setSearchHists', getSearchHists);

      if (this.$store.getters.getSearchHists === null) {
        return false;
      }

      //검색 캐시 저장 여부
      let searchCacheFl = this.$_getSystemData('search_cache_fl') ? this.$_getSystemData('search_cache_fl').configValue : null;
      searchCacheFl = this.$_commonlib.isTrue(searchCacheFl);

      // 목록 캐시 저장 여부 true || 검색 캐시 저장 true시
      if (this.$store.getters.getListCacheFl || searchCacheFl) {
        if (Object.prototype.hasOwnProperty.call(this.$store.getters.getSearchHists, currentPath)) {
          let searchHistsByCurPage = this.$store.getters.getSearchHists[currentPath];
          if (!Object.prototype.hasOwnProperty.call(this.$store.getters.getTabHists, currentPath)) {
            //탭이 없으면 0으로 셋팅
            tabIndex = 0;
          }
          searchHistsByCurPage.forEach(d => {
            //let tabIndex = this.$store.getters.getTabHists[currentPath];
            if (!Object.prototype.hasOwnProperty.call(d, 'tab') || d.tab === tabIndex) {
              //해당 페이지에 탭이 없거나, 탭이 있으면 해당 탭인 경우에만
              if (d.search) {
                //검색 이력이 있으면 커스텀 검색 조건 값 셋팅
                //this.$log.debug(d.search);
                Object.entries(d.search).forEach(([key1, value1]) => {
                  Object.entries(customTypes).forEach(([key2]) => {
                    if (key1 === key2) {
                      customTypes[key1] = value1; //커스텀 검색 조건 값 셋팅
                    }
                  });
                });
              }
            }
          });

          //커스텀 검색조건 값이 null이면 key 제거
          Object.entries(customTypes).forEach(([key, value]) => {
            if (value === null) {
              delete customTypes[key];
            }
          });

          this.searchType.paramsData = { ...this.searchType.paramsData, ...customTypes };
        }
      }
    },
    /**
     * @description : 커스텀 검색 조건 값 변경시 이벤트
     * @param {*} key: 커스텀 검색조건 키 값
     * @param {*} $event: $event
     */
    $_changeSearchCustomType(key, event) {
      //커스텀 검색 조건 값 변경시 이벤트
      if (
        this.searchType.customTypes[key] === null ||
        this.searchType.customTypes[key] === undefined ||
        this.searchType.customTypes[key] === this.searchType.defaultValue
      ) {
        delete this.searchType.paramsData[key];
      } else {
        this.searchType.paramsData = { ...this.searchType.paramsData, [key]: this.searchType.customTypes[key] };
      }

      if (event.event) {
        //store에 커스텀 검색 조건 값 담기
        this.$_setSearchHistsCustomType(this.searchType.paramsData);
      }
      this.selectDataList();
    },
    /**
     * @description : 공통 검색 조건 변경시 초기화
     * @param {*} searchTypes: 공통 검색조건 리스트 값
     */
    $_changeSearchType(searchTypes) {
      //공통 검색 조건 변경시 초기화
      let paramsData = this.$_commonlib.cloneObj(this.searchType.paramsData);
      if (paramsData !== null) {
        //변경 이전 검색 조건 값 삭제
        searchTypes.forEach(d => {
          Object.prototype.hasOwnProperty.call(paramsData, d.codeValue) ? this.$delete(paramsData, d.codeValue) : null;
        });
        this.searchType.paramsData = paramsData;
      }
    },
    /**
     * @description : 검색 버튼 클릭시 이벤트
     * @param {*} paramsData: search paramsData
     * @param {*} codeKey: 검색조건 코드키
     */
    $_searchData(paramsData, codeKey) {
      //검색 버튼 클릭시 이벤트
      if (paramsData) {
        this.searchType.paramsData = { ...this.searchType.paramsData, ...paramsData };
      } else {
        //검색 키워드의 값이 없으면
        //검색키는 설정하고, 검색어를 입력하지 않으면, 전체 목록 출력
        //검색 조건 이력 삭제 2022-06-10 추가
        let searchSelectionList = this.$_getCode(codeKey);
        let codeValues = searchSelectionList.map(d => d.codeValue);
        if (!this.$_commonlib.isEmpty(this.searchType.paramsData)) {
          Object.keys(this.searchType.paramsData).forEach(key => {
            if (codeValues.includes(key)) {
              delete this.searchType.paramsData[key]; //검색 조건 키 삭제
            }
          });
        }
      }
      this.selectDataList();
    },
    /** @description: 페이징의 페이지 사이즈 이력 저장
     *  @param pageSize : 페이지사이즈
     *  @param delFlag : 삭제플래그(pageSize에 따라 pageIndex 초기화)
     *                  row 개수 12개 경우
     *                  10개씩보기 / 2 페이지 에서 -> 20개씩 보기로 변경하면 페이지는 1로 초기화
     */
    $_setPageSizePagingHists(pageSize, delFlag = false) {
      let currentPath = this.$router.currentRoute.path;
      let pagingHistByCurPage = [];
      //해당 페이지의 페이징 처리 이력 있으면 변경
      if (
        this.$store.getters.getPagingHists !== null &&
        Object.prototype.hasOwnProperty.call(this.$store.getters.getPagingHists, currentPath)
      ) {
        pagingHistByCurPage = this.$_commonlib.cloneObj(this.$store.getters.getPagingHists[currentPath]);
        //탭이 있는 페이지
        if (Object.prototype.hasOwnProperty.call(this.$store.getters.getTabHists, currentPath)) {
          let tabIndex = this.$store.getters.getTabHists[currentPath];
          let pagingHistByTab = pagingHistByCurPage.find(d => d.tab === tabIndex);
          if (pagingHistByTab) {
            //이미 페이징 이력이 있으면
            if (delFlag) {
              //pageSize가 default 값인 10이면 pageSize 삭제
              delete pagingHistByTab.pageSize;
            } else {
              pagingHistByTab.pageSize = pageSize;
            }
          } else {
            //페이징 이력이 없으면
            let pageHist = { tab: tabIndex, pageSize: pageSize };
            pagingHistByCurPage.push(pageHist);
          }
        } else {
          //탭이 없는 페이지
          pagingHistByCurPage.forEach(pageHist => {
            if (delFlag) {
              delete pageHist.pageSize;
            } else {
              pageHist.pageSize = pageSize;
            }
          });
        }
      } else {
        //해당 페이지의 페이징 처리 이력이 없으면 추가
        //탭이 있는 페이지
        if (Object.prototype.hasOwnProperty.call(this.$store.getters.getTabHists, currentPath)) {
          let tabIndex = this.$store.getters.getTabHists[currentPath];
          pagingHistByCurPage = [{ tab: tabIndex, pageSize: pageSize }];
        } else {
          //탭이 없는 페이지
          pagingHistByCurPage = [{ pageSize: pageSize }];
        }
      }
      let pagingHist = { [currentPath]: pagingHistByCurPage };

      //store에 페이징의 페이지 사이즈 이력 저장
      let pagingHists = { ...this.$store.getters.getPagingHists, ...pagingHist };
      this.$store.commit('setPagingHists', pagingHists);
    },
    /** @description: 페이징의 페이지 인덱스 이력 저장
     *  @param pageIndex : 페이지인덱스
     *  @param delFlag : 삭제플래그(pageSize에 따라 pageIndex 초기화)
     */
    $_setPageIndexPagingHists(pageIndex, delFlag = false) {
      let currentPath = this.$router.currentRoute.path;
      //let pagingHist = {}
      let pagingHistByCurPage = [];
      //해당 페이지의 페이징 처리 이력 있으면 변경
      if (
        this.$store.getters.getPagingHists !== null &&
        Object.prototype.hasOwnProperty.call(this.$store.getters.getPagingHists, currentPath)
      ) {
        pagingHistByCurPage = this.$_commonlib.cloneObj(this.$store.getters.getPagingHists[currentPath]);
        //탭이 있는 페이지
        if (Object.prototype.hasOwnProperty.call(this.$store.getters.getTabHists, currentPath)) {
          let tabIndex = this.$store.getters.getTabHists[currentPath];
          //pagingHist = { [currentPath] : [{ tab : tabIndex, pageIndex : pageIndex }] };
          //pagingHistByCurPath.tab = tabIndex;
          let pagingHistByTab = pagingHistByCurPage.find(d => d.tab === tabIndex);
          if (pagingHistByTab) {
            //이미 페이징 이력이 있으면
            //pagingHistByTab.pageIndex = pageIndex;
            if (delFlag) {
              //pageIndex가 default 값인 0이면 pageIndex 삭제
              delete pagingHistByTab.pageIndex;
            } else {
              pagingHistByTab.pageIndex = pageIndex;
            }
          } else {
            //페이징 이력이 없으면
            let pageHist = { tab: tabIndex, pageIndex: pageIndex };
            pagingHistByCurPage.push(pageHist);
            /*pagingHistByTab.forEach(pageHist => {
                            pageHist.tab = tabIndex;
                            pageHist.pageIndex = pageIndex;
                        });*/
          }
        } else {
          //탭이 없는 페이지
          //pagingHist = { [currentPath] : [{ pageIndex : pageIndex }] };
          pagingHistByCurPage.forEach(pageHist => {
            //pageHist.pageIndex = pageIndex;
            if (delFlag) {
              //pageIndex가 default 값인 0이면 pageIndex 삭제
              delete pageHist.pageIndex;
            } else {
              pageHist.pageIndex = pageIndex;
            }
          });
        }
      } else {
        //해당 페이지의 페이징 처리 이력이 없으면 추가
        //탭이 있는 페이지
        if (Object.prototype.hasOwnProperty.call(this.$store.getters.getTabHists, currentPath)) {
          let tabIndex = this.$store.getters.getTabHists[currentPath];
          pagingHistByCurPage = [{ tab: tabIndex, pageIndex: pageIndex }];
        } else {
          //탭이 없는 페이지
          pagingHistByCurPage = [{ pageIndex: pageIndex }];
        }
      }
      let pagingHist = { [currentPath]: pagingHistByCurPage };

      //store에 페이징의 페이지 인덱스 이력 저장
      let pagingHists = { ...this.$store.getters.getPagingHists, ...pagingHist };
      this.$store.commit('setPagingHists', pagingHists);
    },
    /**
     * @description: store에서 페이징 관련 이력 가져오기
     * @param {*} dataGrid 데이터 그리드 객체
     */
    async $_getPagingHists(dataGrid) {
      const currentPath = this.$router.currentRoute.path;
      if (this.$store.getters.getPagingHists && Object.prototype.hasOwnProperty.call(this.$store.getters.getPagingHists, [currentPath])) {
        let pagingHistsByCurPage = this.$_commonlib.cloneObj(this.$store.getters.getPagingHists[currentPath]);
        pagingHistsByCurPage.forEach(pageHist => {
          //빈 obj 체크
          if (this.$_commonlib.isEmpty(pageHist)) {
            return false;
          }

          // 그리드 ver2
          if (dataGrid?.page) {
            dataGrid.page.pageSize = pageHist?.pageSize ? pageHist.pageSize : dataGrid.page.pageSize;
            dataGrid.page.pageIndex = pageHist?.pageIndex ? pageHist.pageIndex : dataGrid.page.pageIndex;
          }

          // 그리드 ver1
          if (dataGrid?.pageSize) {
            dataGrid.pageSize = pageHist?.pageSize ? pageHist.pageSize : dataGrid.pageSize;
            dataGrid.pageIndex = pageHist?.pageIndex ? pageHist.pageIndex : dataGrid.pageIndex;
          }
        });
      }
    },
    /**
     * @description : 글자수 체크하는 메서드
     * @param e : $event
     * @param textObj : v-model 필드 값, 페이지마다 필드 값이 다르기 때문에 필요
     * @param limitNumberTexts : textLengths(현재 텍스트 길이), maxLengths(텍스트 제한 길이) Object
     * @param textKey : text 키 값
     */
    $_checkLimitTextLength(e, textObj, limitNumberTexts, textKey) {
      //한글 글자수 입력 제한을 위해 설정
      let value = e.event.currentTarget.value;
      let limitLength = limitNumberTexts.maxLengths[textKey];
      if (value.length > limitLength) {
        value = value.slice(0, limitLength);
      }
      this.$set(textObj, [textKey], value);

      //입력시 Dom에 글자 수를 바로 반영하기 위해 설정
      if (this.$_commonlib.isEmpty(limitNumberTexts.textLengths[textKey])) {
        //초기 설정
        this.$set(limitNumberTexts.textLengths, [textKey], 0);
      }
      this.$set(limitNumberTexts.textLengths, [textKey], value.length);
    },
    /** @description : 메뉴 저장시 sessionStorage 재설정 */
    async $_setSessionStorageMenu() {
      const payload = {
        actionName: 'MENU_LIST_ALL',
        data: {
          authId: this.$store.getters.getAuthId,
          viewFl: this.$_enums.common.stringViewFlag.YES.value,
          isCompact: true, // 간략 조회 여부
        },
        useErrorPopup: true,
      };

      const res = await this.CALL_API(payload);
      if (isSuccess(res)) {
        this.$store.commit('setMenuList', res.data.data);
      }

      if (this.$store.getters.getMenuList.length > 0) {
        const menuList = this.$store.getters.getMenuList.filter(d => d.pageUrl !== null);
        setAddDynamicRoute(this.$router, menuList);
        sessionStorage.setItem('menuList', JSON.stringify(menuList));
      }
    },
    /** @description : 사이트, 센터 저장시 store 재설정 */
    async $_setStoreSiteTenant() {
      let paramsData = {
        loginId: this.$store.getters.getLoginId,
      };

      let apiList = [];
      let payload = {};

      payload = {
        actionName: 'SITE_LIST_ALL',
        data: { params: paramsData },
        loading: false,
      };
      apiList.push(await this.CALL_API(payload));

      payload = {
        actionName: 'TENANT_LIST_ALL',
        data: { params: paramsData },
        loading: false,
      };
      apiList.push(await this.CALL_API(payload));

      const res = await Promise.all(apiList);
      if (res) {
        this.$store.commit('setSiteList', res[0].data.data);
        this.$store.commit('setTenantList', res[1].data.data);
      }
    },
    /** @description: pageData pageSetting config 설정 */
    $_setPageData() {
      const menuObj = this.$store.getters.getMenuList.find(v => v.pageUrl === this.$router.currentRoute.path);
      // const pageData = this.$_commonlib.isEmpty(menuObj.pageData) ? menuObj.pageData : menuObj.pageData.trim();
      try {
        if (!this.$_commonlib.isEmpty(menuObj.pageData)) {
          const pageData = menuObj.pageData.trim();
          const pageDataFunc = new Function(`return ${pageData}`).bind(this)();
          const _that = this;
          Object.entries(pageDataFunc).forEach(([key, value]) => (_that.PAGE_DATA[key] = value));
        }
      } catch (e) {
        console.error('해당 페이지 내 PAGE_DATA 설정을 확인해주시기 바랍니다.', e);
      }
    },
    /** @description: pageData pageSetting config 설정 */
    $_setPageSettingConfig() {
      const menuObj = this.$store.getters.getMenuList.find(d => d.pageUrl === this.$router.currentRoute.path);
      if (!this.$_commonlib.isEmpty(menuObj.pageData)) {
        const pageData = menuObj.pageData.trim();
        const componentData = new Function('return ' + pageData).bind(this)();
        this.config.pageSetting = componentData ? componentData.pageSetting : null;
        this.config.pageSetting.config = this.config.pageSetting ? this.config.pageSetting.config : null;
      }
    },
    /** @description: pageData pageSetting formData 설정 - 삭제 예정 */
    $_setPageSettingFormData() {
      const menuObj = this.$store.getters.getMenuList.find(d => d.pageUrl === this.$router.currentRoute.path);
      if (!this.$_commonlib.isEmpty(menuObj.pageData)) {
        const pageData = menuObj.pageData.trim();
        const componentData = new Function('return ' + pageData).bind(this)();
        this.config.pageSetting.formData = componentData ? componentData.pageSetting.formData : null;
      }
    },
    /** @description: pageData pageSetting contentsData(리스트, 상세 페이지 항목들 관련) 설정  */
    $_setPageSettingContentsData() {
      const menuObj = this.$store.getters.getMenuList.find(d => d.pageUrl === this.$router.currentRoute.path);
      if (!this.$_commonlib.isEmpty(menuObj.pageData)) {
        const pageData = menuObj.pageData.trim();
        const componentData = new Function('return ' + pageData).bind(this)();
        this.config.pageSetting.contentsData = componentData ? componentData.pageSetting.contentsData : null;
      }
    },
    /** @description: 계정 라이센스 수 가져오기 */
    async $_getMemberLicenseCnt() {
      const payload = {
        actionName: 'MEMBER_LICENSE_COUNT',
        useErrorPopup: true,
      };

      let rtnData = [];
      const res = await this.CALL_API(payload);
      if (isSuccess(res)) {
        rtnData = res.data.data;
      }
      return rtnData;
    },
    /** @description: 이전 페이지 되돌아가기 */
    $_goPrePage() {
      this.$router.go(-1);
    },
    $_goMainPage() {
      window.location.href = '/';
    },
    /**
     * @description : 공통코드 뷰 테이블 조회(리스트 타입 고정)
     * @param rootKeyArr CODE_V.ROOT_KEY
     * @return {Object} CODE_V.ROOT_KEY에 해당하는 코드 리스트
     * */
    async $_getCodeMapList(rootKeyArr) {
      if (!rootKeyArr) {
        return {};
      }
      return rootKeyArr.split(',').reduce((acc, key) => {
        acc[key] = this.$store.getters.getCodeList[key];
        return acc;
      }, {});
    },
    /**
     * @description : 특정 뎁스의 코드 리스트 추출
     * @param codeMap
     * @param depth
     * */
    $_fetchCodesByDepth(codeMap, depth) {
      if (codeMap === null || codeMap.maxDepths < depth) {
        return [];
      }

      let result = [];
      codeMap.codes.forEach(c => {
        if (c.depth === depth) {
          result.push(c);
        }
      });
      return result;
    },
    /**
     * @description : getCodeMapList 마지막 뎁스 리스트 추출
     * @param codeMap
     * */
    $_fetchCodesByMaxDepth(codeMap) {
      if (codeMap === null || codeMap?.maxDepths === undefined) {
        return [];
      }
      return this.$_fetchCodesByDepth(codeMap, codeMap.maxDepths);
    },
    /**
     * @description : COMMON_ATTACHED_FILE_DELETE 액션을 호출하는 공통 첨부파일 삭제 함수
     * @param {string} fileGroupId - 파일 그룹 ID로 파일을 식별하기 위한 값
     * @param {string} fileName - 다운로드 할 파일의 이름
     * */
    async $_deleteAttachFile(fileGroupId, fileName) {
      const payload = {
        actionName: 'COMMON_ATTACHED_FILE_DELETE',
        path: `/${fileGroupId}/${fileName}`,
        useErrorPopup: true,
      };

      const res = await this.CALL_API(payload);
      if (isSuccess(res)) {
        this.$_Msg(this.$_lang('CMN_SUC_DELETE', { defaultValue: '정상적으로 삭제되었습니다.' }));
      }
    },
    /**
     * @description : COMMON_ATTACHED_FILE_GROUP_DELETE 액션을 호출하는 공통 그룹 첨부파일 삭제 함수
     * @param {string} fileGroupId - 파일 그룹 ID로 파일을 식별하기 위한 값
     * */
    async $_deleteGroupAttachFile(fileGroupId) {
      const payload = {
        actionName: 'COMMON_ATTACHED_FILE_GROUP_DELETE',
        path: `/${fileGroupId}`,
        useErrorPopup: true,
      };

      const res = await this.CALL_API(payload);
      if (isSuccess(res)) {
        this.$_Msg(this.$_lang('CMN_SUC_DELETE', { defaultValue: '정상적으로 삭제되었습니다.' }));
      }
    },
    /**
     * @description : COMMON_ATTACHED_FILE_COPY 액션을 호출하는 공통 그룹 첨부파일 복제 함수
     * @param {string} fileGroupId - 파일 그룹 ID로 파일을 식별하기 위한 값
     * */
    async $_copyGroupAttachFile(fileGroupId) {
      const payload = {
        actionName: 'COMMON_ATTACHED_FILE_COPY',
        path: `/${fileGroupId}`,
        useErrorPopup: true,
      };

      const res = await this.CALL_API(payload);
      if (isSuccess(res)) {
        this.$_Msg(this.$_lang('CMN_SUC_DELETE', { defaultValue: '정상적으로 복제되었습니다.' }));
      }
    },
    /**
     * @description COMMON_ATTACHED_FILE_DOWNLOAD 액션을 호출하는 공통 첨부파일 다운로드 함수
     * @param {any} fileGroupId - 파일 그룹 ID로 파일을 식별하기 위한 값 (파일 그룹 ID를 넣을 수 없는 경우, null 또는 undefined)
     * @param {string} fileName - 다운로드 할 파일의 이름
     * @throws {Error} '다운로드 오류 발생' - 요청에 문제가 있거나 응답 처리 중에 문제가 발생하는 경우 이 오류를 던집니다.
     */
    $_downloadAttachFile(fileGroupId, fileName) {
      if (typeof fileGroupId === 'undefined' || fileGroupId == null) {
        fileGroupId = 'file';
      }

      let disposition = '';
      let download = 'unknown';
      espApi
        .getFileDownload({
          fileGroupId: fileGroupId,
          fileName: fileName,
        })
        .then(res => {
          if (res.status !== 200) {
            this.$_Toast('다운로드 오류');
            throw new Error('response error');
          }
          disposition = res.headers['content-disposition'].replace(/\+/g, '%20'); // decodeURIComponent() 가 공백을 +로 치환하는 문제 해결
          disposition = decodeURIComponent(disposition);
          return res.data;
        })
        .then(blob => {
          if (disposition && disposition.indexOf('attachment') !== -1) {
            let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            let matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              download = matches[1].replace(/['"]/g, '');
            }
          }
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = download; // 다운로드될 파일의 이름 설정
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        })
        .catch(err => {
          console.error('error', err);
          this.$_Toast('다운로드 오류');
        });
    },
    /**
     * @description COMMON_ATTACHED_FILE_LIST 액션을 호출하는 공통 첨부파일 목록 조회 함수
     * @param {string} fileGroupId - 파일 그룹 ID로 파일을 식별하기 위한 값
     * @return {Promise<Array>} - 파일 목록을 반환하는 프로미스
     */
    async $_getListAttachFile(fileGroupId) {
      if (this.$_commonlib.isEmpty(fileGroupId)) return []; // 파일 그룹 ID가 없으면 빈 배열 반환
      const payload = {
        actionName: 'COMMON_ATTACHED_FILE_LIST',
        path: `/${fileGroupId}`,
        useErrorPopup: true,
      };

      let rtnData = [];
      const res = await this.CALL_API(payload);
      if (isSuccess(res)) {
        rtnData = res.data.data;
      }
      return rtnData;
    },
    /**
     * @description 첨부파일 다운로드 URL 생성
     * @param {any} fileGroupId - 파일 그룹 ID로 파일을 식별하기 위한 값 (파일 그룹 ID를 넣을 수 없는 경우, null 또는 undefined)
     * @param {string} fileName - 다운로드 할 파일의 이름
     * @return {string} - 다운로드 URL을 반환
     * * */
    $_getAttachFileURL(fileGroupId, fileName) {
      if (typeof fileGroupId === 'undefined' || fileGroupId == null) {
        fileGroupId = 'file';
      }
      const host = process.env.VUE_APP_ESP_SERVER_URL ? process.env.VUE_APP_ESP_SERVER_URL : window.location.origin;
      const apiData = this.$store.getters.getRoutingInfos.find(d => d.actionNm === 'COMMON_ATTACHED_FILE_DOWNLOAD');
      const apiUrl = apiData.host ? apiData.host : host + apiData.path;
      return `${apiUrl}/${fileGroupId}/${fileName}`;
    },
    /**
     * @description 클립보드에 텍스트 복사
     * @param text
     * @returns {Promise<void>}
     */
    async $_copyToClipboard(text) {
      try {
        // 클립보드에 텍스트를 쓰기 위해 navigator.clipboard.writeText 호출
        await navigator.clipboard.writeText(text);
        this.$_Toast('클립보드에 복사되었습니다.', { icon: 'success' });
      } catch (error) {
        this.$_Toast('복사에 실패하였습니다.', { icon: 'error' });
      }
    },
    /**
     * @description 패스워드 정책 데이터 가져오기
     * @return {Array} - 패스워드 정책 데이터 반환
     */
    $_getPwdSystemData() {
      const keys = [
        'pwd_consecutive_len',
        'pwd_include_id',
        'pwd_include_nm',
        'pwd_min_len',
        'pwd_max_len',
        'pwd_min_category',
        'pwd_allow_char',
      ];
      return keys.map(key => this.$_getSystemData(key)?.configValue);
    },
    /**
     * @description 패스워드 정책 검사 함수
     * @param {string} loginPwd - 로그인 패스워드
     * @param {string} loginId - 로그인 ID
     * @param {string} loginNm - 로그인 이름
     * @return {string} - 패스워드 정책에 대한 유효성 검사 결과 메시지 반환, 공백은 유효한 패스워드
     */
    $_validatePasswordMessage(loginPwd, loginId, loginNm) {
      if (!loginPwd) {
        return '패스워드를 입력해 주세요.';
      }

      const [consecutiveLength, includeLoginId, includeLoginNm, minLength, maxLength, minCategories, specialChars] =
        this.$_getPwdSystemData();

      const consecutivePattern = new RegExp(`(\\w)\\1{${consecutiveLength - 1},}`);
      if (consecutivePattern.test(loginPwd)) {
        return `${consecutiveLength}자리 이상 연속된 문자 또는 숫자는 패스워드로 사용할 수 없습니다`;
      }

      if (includeLoginId && loginPwd.includes(loginId)) {
        return '아이디를 패스워드로 사용할 수 없습니다.';
      }

      if (includeLoginNm && loginPwd.includes(loginNm)) {
        return '사용자의 이름은 패스워드로 사용할 수 없습니다.';
      }

      if (loginPwd.length < minLength) {
        return `패스워드는 최소 ${minLength}자리 이상입니다.`;
      }

      if (loginPwd.length > maxLength) {
        return `패스워드는 최대 ${maxLength}자리까지 허용됩니다.`;
      }

      let categoriesCount = 0;
      if (/[a-z]/.test(loginPwd)) {
        categoriesCount++; // 소문자 확인
      }

      if (/[A-Z]/.test(loginPwd)) {
        categoriesCount++; // 대문자 확인
      }

      if (/\d/.test(loginPwd)) {
        categoriesCount++; // 숫자 확인
      }

      if (specialChars.split('').some(char => loginPwd.includes(char))) {
        categoriesCount++; // 특수문자 확인
      }

      if (categoriesCount < minCategories) {
        return `대문자, 소문자, 숫자, 특수문자 중 ${minCategories}가지 이상을 조합하세요.`;
      }

      const hangulCheck = getRegexPattern('checkHangul');
      if (hangulCheck.test(loginPwd)) {
        return this.$_lang('COMMON.MESSAGE.PWD_NON_KR', { defaultValue: '패스워드에 한글을 사용할 수 없습니다.' });
      }


      return '';
    },
    /**
     * @description : style 태그 전역 설정 on/off
     * @param css : style 안에 특정 class를 생성해줘야함
     * @param flag : false 켜기, true 끄기
     */
    $_styleControl(css, flag) {
      const styleElements = document.querySelectorAll('style');
      styleElements.forEach(styleElement => {
        if (styleElement.innerHTML.includes(css)) {
          styleElement.disabled = flag;
        }
      });
    },
  },
  created() {},
  updated() {},
};

export default mixin;
