export default {
  MESSAGE: {
    INPUT_GROUP_NAME: '그룹명을 입력해주세요.',
    REQUIRED_GROUP_NAME: '그룹명은 필수입니다.',
    SELECT_GROUP: '그룹을 선택해주세요.',
    DUPLICATE_NAME: '이미 등록되어 있는 명칭입니다.',
    CFM_UPDATE_INFO: '정보를 수정하시겠습니까?',
    CFM_DELETE_SELECTED_WITH_CHILD: '선택한 데이터를 삭제하시겠습니까?<br/>하위 데이터도 함께 삭제됩니다.',
    RESET_IBG: '인입그룹 설정 초기화',
    RESET_MAIN_NUMBER: '대표번호 설정 초기화',
    NO_DELETE_DNIS_NOTICE: '대표번호에 게시 중인 공지사항이 있어 삭제할 수 없습니다.',
    NO_DELETE_DNIS_SCHEDULE: '대표번호에 게시 중인 운영 스케줄이 있어 삭제할 수 없습니다.',
    START_DATE_OUT_OF_RANGE: '시작일은 종료일보다 작거나 같아야 합니다.',
    END_DATE_OUT_OF_RANGE: '종료일은 시작일보다 크거나 같아야 합니다.',
    START_TIME_OUT_OF_RANGE: '시작시간은 종료시간보다 작거나 같아야 합니다.',
    END_TIME_OUT_OF_RANGE: '종료시간은 시작시간보다 크거나 같아야 합니다.',
    NO_DISABLED_NOT_USED_DNIS_NOTICE: '대표번호에 설정된 공지사항은 미사용 상태로 변경할 수 없습니다.',
    NO_DISPLAY_USED_DNIS_NOTICE: '이미 다른 공지사항이 설정된 대표번호는<br>목록에서 노출되지 않습니다.',
    DUPLICATE_REST_TIME: '휴식시간이 겹칩니다.',
    DUPLICATE_HOLIDAY_DATE: '동일한 일자의 휴일이 존재합니다.<br/>다시 확인하세요.',
    DUPLICATE_OPERATION_DATE: '동일한 일자의 휴일이 존재합니다.<br/>다시 확인하세요.',
  },
  WORD: {
    GROUP_INFO: '그룹정보',
    TH_GROUP_NAME: '차 그룹명',
    GROUP_NAME: '그룹명',
    CHILD_GROUP: '하위 그룹',
    CHILD: '하위',
    REGISTER_1ST_GROUP: '1차 그룹 등록',
    REGISTER_2RD_GROUP: '2차 그룹 등록',
    ADD_IBG: '인입그룹 추가',
    ADD_TEAM: '팀 추가',
    ADD_SKL: '상담그룹 추가',
    IBG: '인입그룹',
    IBG_NAME: '인입그룹명',
    TEAM: '팀',
    TEAM_NAME: '팀명',
    SKL: '상담그룹',
    SKL_NAME: '상담그룹명',
    ATTRIBUTE: '속성',
    ATTRIBUTE_NAME: '속성명',
    CONTACT_TYPE: '컨택타입',
    CONTACT_TYPE_NAME: '컨택타입명',
    NREASON_CODE: '이석사유코드',
    NREASON_NAME: '이석사유명',
    NREASON_FIELD: '이석사유필드',
    ERS_NAME: 'ERS 이름',
    CTI_NAME: 'CTI 이름',
    VIEW_DNIS_USE: '사용중인 DNIS만 보기',
    OPERATION_SETTINGS: '운영 설정',
    IBG_SETTINGS: '인입그룹 설정',
    CONFIG_SETTINGS: '환경 설정',
    IVR_NAME: 'IVR명',
    MAIN_NUMBER: '대표번호',
    MAIN_NUMBER_SETTING: '대표번호 설정',
    DNIS_TITLE: 'DNIS명',
    SVC_TP_TITLE: '서비스 템플릿',
    OP_SCHEDULE: '운영스케줄',
    SERVICECODE: '서비스코드',
    NOTICE: '공지사항',
    DISPLAY_PERIOD: '노출기간',
    END_TYPE: '종료여부',
    CONFIG_SETTING: '환경설정',
    KEY: '키',
    VALUE: '값',
    DESCRIPTION: '설명',
    SCHEDULE_NAME: '스케줄명',
    BASIC_INFO: '기본정보',
    BASIC_OP_TIME_SETTING: '기본 운영시간 설정',
    OP_SCHEDULE_NAME: '운영 스케줄명',
    OP_DAY_OF_WEEK: '운영 요일',
    OP_TIME: '운영시간',
    BREAK_TIME_APPLY_TYPE: '휴식시간 적용 여부',
    BREAK_TIME: '휴식시간',
    TWENTY_FOUR_HOUR: '24시',
    WORK_FLAG: '운영여부',
    AGT_TEAM_NM: '상담사 팀명',
    AGT_ID: '상담사 ID',
    AGT_NM: '상담사 이름',
    TEMPLATE: '템플릿',
    SERVICECODE_NM: '서비스코드명',
    NOTICE_FLAG: '공지여부',
  },
};
