<template>
  <div>
    <div class="page-sub-box ewm">
      <div class="locker_setting_list sub_new_style01 sub_ui_box1">
        <div class="page_search_box line_bottom_1px pb-0 mb-0">
          <div class="flex space-x-2 inner items-center">
            <div>기준년월 (최대 1년)</div>
            <DxDateBox
              styling-mode="outlined"
              width="100"
              height="30"
              v-model="searchType.customTypes.startDt"
              type="date"
              dateSerializationFormat="yyyyMMdd"
              display-format="yyyy.MM"
              date-out-of-range-message="시작일은 종료일보다 작거나 같아야 합니다."
              :max="searchType.customTypes.endDt"
              invalid-date-message="입력 데이터가 유효하지 않습니다."
              :calendar-options="{
                zoomLevel: 'year',
                minZoomLevel: 'year',
                maxZoomLevel: 'year',
              }"
              @value-changed="onDayStartChanged"
            >
              <DxValidator validation-group="validationSearch">
                <DxRequiredRule message="기준년월은 필수입니다." />
              </DxValidator>
            </DxDateBox>
            <div>~</div>
            <DxDateBox
              styling-mode="outlined"
              width="100"
              height="30"
              v-model="searchType.customTypes.endDt"
              type="date"
              dateSerializationFormat="yyyyMMdd"
              display-format="yyyy.MM"
              date-out-of-range-message="종료일은 시작일보다 크거나 같아야 합니다."
              :min="searchType.customTypes.startDt"
              invalid-date-message="입력 데이터가 유효하지 않습니다."
              :calendar-options="{
                zoomLevel: 'year',
                minZoomLevel: 'year',
                maxZoomLevel: 'year',
              }"
              @value-changed="onDayEndChanged"
            >
              <DxValidator validation-group="validationSearch">
                <DxRequiredRule message="기준년월은 필수입니다." />
              </DxValidator>
            </DxDateBox>
            <div class="pl-2">대상자 선택 (최대 {{ popupOptions.maximumSelectionLength }}명)</div>
            <DxTextBox styling-mode="filled" :width="200" :read-only="true" v-model="firstTarget">
              <DxValidator validation-group="validationSearch">
                <DxRequiredRule message="대상자는 필수입니다." />
              </DxValidator>
            </DxTextBox>
            <DxButton icon="search" text="" class="btn-search" type="button" :height="30" @click="onOpen" />
            <DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="onSearch" />
          </div>
        </div>
      </div>

      <div class="flex flex-col gap-y-6 divide-y">
        <div class="flex gap-4 mt-4">
          <div class="w-5/6 border">
            <DxChart id="performResultChart" :data-source="performResultChart.dataSource">
              <DxSize :height="350" />
              <DxMargin :top="14" :bottom="14" :left="14" :right="14" />
              <DxCommonSeriesSettings type="line" argument-field="scheYmd" />
              <DxCommonAxisSettings>
                <DxGrid :visible="true" />
              </DxCommonAxisSettings>
              <DxSeries
                v-for="(perform, index) in performResultChart.series"
                :key="index"
                :value-field="perform.agtid"
                :name="perform.agtNm"
                :ignore-empty-points="true"
              >
                <DxLabel :visible="false" />
                <DxPoint :size="10" />
              </DxSeries>
              <DxMargin :bottom="0" />
              <DxArgumentAxis :allow-decimals="false" :axis-division-factor="60">
                <DxLabel>
                  <DxFormat type="decimal" />
                </DxLabel>
              </DxArgumentAxis>
              <DxLegend vertical-alignment="bottom" horizontal-alignment="center" item-text-position="bottom" />
              <DxExport :enabled="false" />
              <DxTooltip :enabled="true" />
            </DxChart>
          </div>
          <div class="w-1/6">
            <DxDataGrid
              :data-source="getEventDivisions"
              ref="eventType"
              :allow-column-resizing="true"
              :column-resizing-mode="'nextColumn'"
              :show-borders="false"
              :show-column-headers="true"
              :show-column-lines="true"
              :show-row-lines="true"
              :row-alternation-enabled="false"
              :hover-state-enabled="true"
              :word-wrap-enabled="true"
              :no-data-text="this.$_lang('CMN_NO_DATA')"
              width="100%"
              @selection-changed="onSelectionChanged"
            >
              <DxSelection mode="multiple" show-check-boxes-mode="always" />

              <DxColumn
                caption="이벤트구분"
                alignment="center"
                :allowEditing="false"
                :allow-sorting="false"
                :calculate-cell-value="rowData => `${rowData.eventDivisionDescription}`"
              />
              <!--
            <DxColumn
                caption="이벤트구분"
                data-field="performEvalDivision"
                :allowEditing="false"
                :calculate-cell-value="getPerformEvalDivision"
            />
            -->
            </DxDataGrid>
          </div>
        </div>

        <div>
          <DxDataGrid
            ref="metricsResultGrid"
            :data-source="metricsResultGrid.dataSource"
            :allow-column-resizing="true"
            :column-resizing-mode="'nextColumn'"
            :show-borders="false"
            :show-column-headers="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :row-alternation-enabled="false"
            :hover-state-enabled="true"
            :word-wrap-enabled="true"
            :no-data-text="this.$_lang('CMN_NO_DATA')"
            height="calc(100vh - 720px)"
            @row-click="metricsResultGridRowClick"
          >
            <DxFilterRow
              :visible="true"
              :operationDescriptions="{
                contains: '포함',
              }"
            />
            <DxHeaderFilter :visible="true" />
            <DxLoadPanel :enabled="true" />
            <DxScrolling mode="standard" />
            <DxColumn
              caption="기준년월"
              data-field="scheYmd"
              alignment="center"
              width="140"
              :allow-editing="false"
              :allow-sorting="true"
              :calculate-cell-value="rowData => formatDate(rowData.scheYmd, 'YYYY-MM-DD', 'YYYY.MM')"
            >
            </DxColumn>
            <DxColumn
              caption="상담사"
              data-field="agtid"
              alignment="center"
              width="200"
              :allow-editing="false"
              :allow-sorting="true"
              :calculate-cell-value="rowData => `${rowData.agtNm}[${rowData.agtid}]`"
            >
            </DxColumn>

            <DxColumn
              caption="면담"
              :visible="isInterViewVisible"
              width="80"
              data-field="isInterview"
              alignment="center"
              :allow-editing="false"
              :allow-sorting="false"
              :calculate-cell-value="getIsInterview"
            />

            <DxColumn
              caption="코칭"
              :visible="isCoachingVisible"
              width="80"
              data-field="isCoaching"
              alignment="center"
              :allow-editing="false"
              :allow-sorting="false"
              :calculate-cell-value="getIsCoaching"
            />

            <DxColumn
              caption="교육"
              :visible="isEduVisible"
              width="80"
              data-field="isEdu"
              alignment="center"
              :allow-editing="false"
              :allow-sorting="false"
              :calculate-cell-value="getIsEdu"
            />

            <DxColumn
              caption="시험"
              :visible="isExamVisible"
              width="80"
              data-field="isExam"
              alignment="center"
              :allow-editing="false"
              :allow-sorting="false"
              :calculate-cell-value="getIsExam"
            />

            <DxColumn
              caption="성과계획명"
              data-field="scheNm"
              alignment="left"
              :allow-editing="false"
              :allow-header-filtering="false"
              :allow-sorting="true"
            />
            <DxColumn
              caption="실제점수(총점)"
              data-field="totalScore"
              alignment="center"
              width="140"
              :allow-editing="false"
              :allow-header-filtering="false"
              :allow-sorting="true"
              :calculate-display-value="
                rowData => {
                  return `${rowData.totalScore}(${rowData.totalMerticsScore})`;
                }
              "
            />
            <DxColumn
              caption="환산점수(100점)"
              data-field="totalConvertScore"
              alignment="center"
              width="140"
              :allow-editing="false"
              :allow-header-filtering="false"
              :allow-sorting="true"
            />
            <DxColumn
              caption="점수변화"
              data-field="gap"
              alignment="center"
              width="140"
              :allow-editing="false"
              :allow-header-filtering="false"
              :allow-sorting="true"
              cell-template="gapTemplate"
            />

            <template #gapTemplate="{ data }">
              <div v-if="data.value > 0" class="text-red-500">+{{ data.value }}</div>
              <div v-else-if="data.value < 0" class="text-blue-500">
                {{ data.value }}
              </div>
              <div v-else>
                {{ data.value }}
              </div>
            </template>
          </DxDataGrid>
        </div>
      </div>

      <modal-add-agent
        :isOpen="popupOptions.visible"
        :selectedIdList="popupOptions.selectedIdList"
        :maximumSelectionLength="popupOptions.maximumSelectionLength"
        saveBtnTxt="선택"
        @closeModal="onClose(false)"
        @saveModal="onSelectedTargets"
      />
    </div>
  </div>
</template>

<script>
  import { DxButton } from 'devextreme-vue/button';
  import { DxDateBox } from 'devextreme-vue/date-box';
  import { DxTextBox } from 'devextreme-vue/text-box';
  import { DxRequiredRule, DxValidator } from 'devextreme-vue/validator';
  import validationEngine from 'devextreme/ui/validation_engine';
  import { DxColumn, DxDataGrid, DxFilterRow, DxHeaderFilter, DxLoadPanel, DxScrolling, DxSelection } from 'devextreme-vue/data-grid';
  import {
    DxArgumentAxis,
    DxChart,
    DxCommonAxisSettings,
    DxCommonSeriesSettings,
    DxExport,
    DxFormat,
    DxGrid,
    DxLabel,
    DxLegend,
    DxMargin,
    DxPoint,
    DxSeries,
    DxSize,
    DxTooltip,
  } from 'devextreme-vue/chart';
  import { formatDate, getPastFromToday, isSuccess, setCalculateDate, setGridSingleSelection } from '@/plugins/common-lib';
  import ModalAddAgent from '@/components/ewm/hr/modal-add-agent.vue';

  let vm = null;

  export default {
    components: {
      DxButton,
      DxDateBox,
      DxTextBox,
      DxRequiredRule,
      DxValidator,
      DxColumn,
      DxDataGrid,
      DxFilterRow,
      DxHeaderFilter,
      DxSelection,
      DxLoadPanel,
      DxScrolling,
      DxChart,
      DxMargin,
      DxSeries,
      DxSize,
      DxArgumentAxis,
      DxCommonSeriesSettings,
      DxCommonAxisSettings,
      DxExport,
      DxGrid,
      DxLabel,
      DxLegend,
      DxPoint,
      DxTooltip,
      DxFormat,
      ModalAddAgent,
    },
    props: {},
    watch: {},
    data() {
      return {
        isInterViewVisible: false,
        isCoachingVisible: false,
        isEduVisible: false,
        isExamVisible: false,
        firstTarget: null,
        targets: [],
        popupOptions: {
          visible: false,
          selectedIdList: [],
          maximumSelectionLength: 10,
        },
        searchType: {
          customTypes: {
            startDt: getPastFromToday(11, 'months'),
            endDt: getPastFromToday(0, 'months'),
          },
        },
        metricsResultGrid: {
          dataSource: null,
          selectedRowsData: [],
          selectedRowKeys: [],
        },
        performEvalDivision: {
          dataSource: null,
        },
        performResultChart: {
          dataSource: [],
          series: [],
        },
      };
    },
    computed: {
      getEventDivisions() {
        return [
          { eventDivisionDescription: '면담' },
          { eventDivisionDescription: '코칭' },
          { eventDivisionDescription: '교육' },
          { eventDivisionDescription: '시험' },
        ];
      },
    },
    methods: {
      formatDate,
      getMinStartDt() {
        const endDt = formatDate(this.searchType.customTypes.endDt, 'YYYYMMDD', 'YYYY-MM-DD');
        const minStartDt = setCalculateDate('month', endDt, -12);
        return minStartDt;
      },
      getMaxEndDt() {
        const strDt = formatDate(this.searchType.customTypes.startDt, 'YYYYMMDD', 'YYYY-MM-DD');
        const maxEndDt = setCalculateDate('month', strDt, 12);
        return maxEndDt;
      },
      getIsCoaching(rowData) {
        const { isCoaching } = rowData;
        return isCoaching == this.$_enums.common.stringUsedFlag.YES.value ? 'O' : '';
      },
      getIsEdu(rowData) {
        const { isEdu } = rowData;
        return isEdu == this.$_enums.common.stringUsedFlag.YES.value ? 'O' : '';
      },
      getIsExam(rowData) {
        const { isExam } = rowData;
        return isExam == this.$_enums.common.stringUsedFlag.YES.value ? 'O' : '';
      },
      getIsInterview(rowData) {
        const { isInterview } = rowData;
        return isInterview == this.$_enums.common.stringUsedFlag.YES.value ? 'O' : '';
      },
      getMetricsResultGridInstance() {
        return this.$refs.metricsResultGrid.instance;
      },
      onSelectionChanged(e) {
        /* if (this.targets.length === 0) {
				this.$_Msg('대상자를 선택해 주세요.');
				e.component.deselectRows(e.currentSelectedRowKeys);
				return;
			} */

        const visibilityMap = {
          면담: 'isInterViewVisible',
          코칭: 'isCoachingVisible',
          교육: 'isEduVisible',
          시험: 'isExamVisible',
        };

        const setVisibility = (rows, isVisible) => {
          rows.forEach(row => {
            const key = visibilityMap[row.eventDivisionDescription];
            if (key) {
              vm[key] = isVisible;
            }
          });
        };

        setVisibility(e.selectedRowsData, true);
        setVisibility(e.currentDeselectedRowKeys, false);
      },
      metricsResultGridRowClick(e) {
        setGridSingleSelection(e);
      },
      getChartData(items) {
        return items.map(item => {
          const { totalConvertScore, scheYmd } = item;
          return {
            [item.agtid]: totalConvertScore,
            scheYmd: scheYmd,
          };
        });
      },
      getSeries() {
        return this.targets.map(target => {
          return {
            agtid: target.agtid,
            agtNm: target.agtNm,
          };
        });
      },
      convertScore(score, maxScore, newMaxScore = 100) {
        return Math.round((score / maxScore) * newMaxScore);
      },
      async onSearch() {
        if (!validationEngine.validateGroup('validationSearch').isValid) {
          return;
        }

        const selectedDivisionRows = this.$refs.eventType.instance.getSelectedRowsData();
        const oneYearAgo = setCalculateDate('year', formatDate(vm.searchType.customTypes.endDt, 'YYYYMMDD', 'YYYY-MM-DD'), -1);
        const validStrDate = setCalculateDate('day', oneYearAgo, -1);
        const cvtStrDate = new Date(formatDate(vm.searchType.customTypes.startDt, 'YYYYMMDD', 'YYYY-MM-DD'));

        if (validStrDate > cvtStrDate) {
          this.$_Msg('최대 1년간 검색이 가능합니다.');
          return;
        }

        /* if (this.targets.length === 0) {
				this.$_Msg('대상자를 선택해 주세요.');
				return;
			} */

        let agtids = [];
        this.targets.forEach(target => {
          agtids.push(target.agtid);
        });

        const payload = {
          actionName: 'EWM_PERFORMANCE_REPORT_SELECT',
          data: {
            startDt: vm.searchType.customTypes.startDt,
            endDt: vm.searchType.customTypes.endDt,
            agtids: agtids,
            performEvalDivisions: selectedDivisionRows.map(select => select.performEvalDivision),
          },
          loading: false,
        };

        const res = await this.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          const items = res.data.data;

          const groupedItems = items.reduce((acc, item) => {
            const key = `${item.agtid}-${item.scheYmd}`;

            if (!acc[key]) {
              acc[key] = { ...item, totalScoreSum: 0, totalMerticsScoreSum: 0 };
            }
            //acc[key].totalScore += item.totalScore;
            //acc[key].totalMerticsScore += item.totalMerticsScore;
            acc[key].totalScoreSum += item.totalScore;
            acc[key].totalMerticsScoreSum += item.totalMerticsScore;
            acc[key].totalConvertScore = vm.convertScore(acc[key].totalScoreSum, acc[key].totalMerticsScoreSum);

            return acc;
          }, {});

          const aggregatedData = Object.values(groupedItems);

          const groupedById = aggregatedData.reduce((acc, item) => {
            if (!acc[item.agtid]) {
              acc[item.agtid] = [];
            }
            acc[item.agtid].push(item);
            return acc;
          }, {});

          for (let agtid in groupedById) {
            groupedById[agtid].sort((a, b) => new Date(a.scheYmd) - new Date(b.scheYmd));
          }

          const gapResultArray = aggregatedData.map(item => {
            const group = groupedById[item.agtid];
            const index = group.findIndex(i => i.scheYmd === item.scheYmd);
            let gap = null;

            if (index > 0) {
              const previousScore = group[index - 1].totalConvertScore;
              gap = item.totalConvertScore - previousScore;
            }

            return { ...item, gap };
          });

          const result = gapResultArray.reduce((acc, item) => {
            const key = `${item.agtid}-${item.scheYmd}`;
            acc[key] = item;
            return acc;
          }, {});

          items.map(item => {
            const key = `${item.agtid}-${item.scheYmd}`;

            //item.totalScore = result[key].totalScore;
            //item.totalMerticsScore = result[key].totalMerticsScore;
            item.totalConvertScore = result[key].totalConvertScore;
            item.gap = result[key].gap;

            return item;
            //return { ...item, score: groupedItems[key].score };
          });

          // binding chart-grid
          const charts = vm.getChartData(gapResultArray);
          this.$set(this.performResultChart, 'dataSource', charts);

          // binding chart series
          let chartSeries = vm.getSeries();
          this.$set(this.performResultChart, 'series', chartSeries);

          // binding data-grid
          //const tableDataGrid = vm.getTableDataGrid(items);
          this.$set(this.metricsResultGrid, 'dataSource', items);
        }
      },
      onOpen() {
        this.popupOptions.visible = true;
        this.popupOptions.selectedIdList = [];
      },
      onClose() {
        this.popupOptions.visible = false;
        this.popupOptions.selectedIdList = [];
      },
      onSelectedTargets(selDataList) {
        if (!selDataList || selDataList.length === 0) return;

        this.firstTarget = `${selDataList[0].agtNm}[${selDataList[0].agtid}] 외 ${selDataList.length - 1}명`;

        this.targets = selDataList.map(item => ({
          deptNmPath: item.deptNmPath,
          agtid: item.agtid,
          agtNm: item.agtNm,
          regId: this.$store.getters.getLoginId,
          regNm: this.$store.getters.getLoginNm,
          regDt: new Date(),
        }));

        this.popupOptions.visible = false;
      },
      onDayStartChanged(e) {
        this.searchType.customTypes.startDt = e.value.slice(0, 6) + '01';
      },
      onDayEndChanged(e) {
        this.searchType.customTypes.endDt = e.value.slice(0, 6) + '29';
      },
      async initCodeMap() {},
      async createdData() {
        vm = this;
        await this.initCodeMap().then(() => {});
      },
    },
    created() {
      this.createdData();
    },
    mounted() {},
  };
</script>
