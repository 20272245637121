<!--
  PACKAGE_NAME : src/components/euc
  FILE_NAME : license-status.vue
  AUTHOR : jhcho
  DATE : 2024-06-27
  DESCRIPTION :
-->
<template>
  <DxScrollView
      :scroll-by-content="scroll.scrollByContent"
      :scroll-by-thumb="scroll.scrollByThumb"
      :show-scrollbar="scroll.showScrollbar"
      :bounce-enabled="scroll.pullDown"
      width="100%"
      height="100%"
  >
    <div class="w-full h-full grid gap-4 grid-cols-3" style="padding-top: 20px;">
      <div v-for="(data, index) in dataList" :key="index">
        <LicenseCard :model="data.model" :modelNm="data.modelNm" :cnt="data.cnt" />
      </div>
    </div>
  </DxScrollView>
</template>
<script>
import {getPastFromToday, isSuccess} from "@/plugins/common-lib";
import LicenseCard from "@/components/euc/license/license-card.vue";
import {DxScrollView} from "devextreme-vue/scroll-view";

export default {
  components: {
    DxScrollView,
    LicenseCard
  },
  data() {
    return {
      dataList: [],
      scroll: {
        scrollByContent: false,
        scrollByThumb: true,
        showScrollbar: 'onHover',  //[onScroll, onHover, always, never]
        pullDown: false,
      },
      lastSearchDate: getPastFromToday(0, 'days')
    }
  },
  computed: {},
  methods: {
    async selectDataList() {
      const payload = {
        actionName: 'EUC_CISCO_LICENSE_STATUS_SELECT',
        data: {},
        loading: false,
      }
      this.lastSearchDate = getPastFromToday(0, 'days');
      const res = await this.CALL_EUC_API(payload);
      if(isSuccess(res)) {
        this.dataList = res.data.data;
      }
    },
  },
  mounted() {
    this.selectDataList();
  },
  created() {
  }
}
</script>
<style scoped>

</style>