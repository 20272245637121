<template>
  <div class="page-sub-box sub_new_style01 sub_ui_box1">
    <!--"sub_new_style01" class적용시 상단라인 삭제-->
    <!--검색영역-->
    <Search
      codeKey="search_sync_history_type"
      :searchObj="searchType.obj"
      :useKeywordType="true"
      @change-search-type="$_changeSearchType"
      @onSearchClick="e => $_searchData(e, 'search_sync_history_type')"
    >
      <template v-slot:before>
        <div class="ui-datepicker-item">
          <DxDateBox
            :edit-enabled="false"
            :styling-mode="stylingMode"
            :width="120"
            v-model="searchType.customTypes.dayStart"
            type="date"
            display-format="yyyy.MM.dd"
            @value-changed="onChangeSearchDay"
            dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
          >
          </DxDateBox>
        </div>
        <div class="ui-datepicker period">
          <span class="dash">~</span>
        </div>
        <div class="ui-datepicker-item">
          <DxDateBox
            :styling-mode="stylingMode"
            :width="120"
            v-model="searchType.customTypes.dayEnd"
            type="date"
            display-format="yyyy.MM.dd"
            @value-changed="onChangeSearchDay"
            dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
          >
          </DxDateBox>
        </div>
        <DxSelectBox
          v-model="searchType.customTypes.status"
          placeholder="처리상태 선택"
          :items="getStatus"
          display-expr="codeNm"
          value-expr="codeValue"
          :styling-mode="stylingMode"
          :width="120"
          :height="30"
          @value-changed="$_changeSearchCustomType('status', $event)"
        />
      </template>
    </Search>
    <!-- //검색영역-->

    <esp-dx-data-grid :data-grid="dataGrid" ref="batchCheckRefName"/>
    <DxPopup
      :show-title="true"
      :title="modal.initData ? modal.initData.title : null"
      :min-width="modal.initData ? modal.initData.width : null"
      :width="modal.initData ? modal.initData.width : null"
      :min-height="modal.initData ? modal.initData.height : null"
      :height="modal.initData ? modal.initData.height : null"
      :drag-enabled="true"
      :resize-enabled="true"
      :show-close-button="true"
      :hide-on-outside-click="false"
      :visible="modal.isOpened"
      @hiding="isOpenModal(false)"
    >
      <template #content>
        <div>
          <component
            :is="modal.currentComponent"
            :contentData="modal.contentData"
            v-model="modal.contentData"
          ></component>
        </div>
      </template>

      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="
          modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('cancel')
              ? modal.initData.buttons.hasOwnProperty('cancel')
              : !modal.initData.buttons.hasOwnProperty('cancel')
            : false
        "
        :options="{
          elementAttr: {
            class: 'white filled txt_S medium',
          },
          text: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('cancel')
              ? modal.initData.buttons.cancel.text
              : ''
            : '',
          width: '120',
          height: '40',
          onClick: () => {
            isOpenModal(false);
          },
        }"
      />
    </DxPopup>
  </div>
</template>

<script>
  import Search from '@/components/common/search.vue';
  import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
  import ModalResulMessage from './modal-result-message.vue';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { DxTextBox } from 'devextreme-vue/text-box';
  import {
    DxChart,
    DxSeries,
    DxCommonSeriesSettings,
    DxLabel,
    DxFormat,
    DxLegend,
    DxExport,
  } from 'devextreme-vue/chart';
  import DxDateBox from 'devextreme-vue/date-box';
  import DxButton from 'devextreme-vue/button';
  import { isSuccess, getPastFromToday, downloadFile } from '@/plugins/common-lib';
  import CustomStore from "devextreme/data/custom_store";
  import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid.vue";

  export default {
    components: {
      EspDxDataGrid,
      DxChart,
      DxSeries,
      DxCommonSeriesSettings,
      ModalResulMessage,
      DxLabel,
      DxFormat,
      DxTextBox,
      DxLegend,
      DxExport,
      DxDateBox,
      DxSelectBox,
      DxButton,
      DxPopup,
      DxToolbarItem,
      Search,
    },
    data() {
      return {
        stylingMode: 'outlined', //outlined, underlined, filled
        searchType: {
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            dayStart: getPastFromToday(30, 'days'),
            dayEnd: getPastFromToday(0, 'days'),
            reqDt: null,
            status: null,
          },
          paramsData: null,
        },
        dataGrid: {
          // callApi: 'ECSS_EUC_API',
          refName: 'batchCheckRefName',
          excel: {
            title: '동기화 이력',
          },
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          //   height: '650', // 주석처리시 100%
          height: 'calc(100vh - 350px)', // 주석처리시 100%
          apiActionNm: {
            loading: true,
          },
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
            optionChanged: true,
          },
          showActionButtons: {
            excel: true,
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '삭제',
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 60,
                  height: 30,
                  onClick: () => {
                    this.onDeleteData();
                  },
                },
                location: 'before',
              },
            ],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowDeleting: false,
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '타이틀',
              dataField: 'title',
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              allowSorting: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
            },
            {
              caption: 'URL',
              dataField: 'appUrl',
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              allowSorting: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
            },
            {
              caption: '파일',
              dataField: 'fileNm',
              width: 100,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              allowSorting: false,
              allowExporting: false,
              cellTemplate: (container, options) => {
                if (options.value === null || options.value.trim() === '') {
                  container.append('-');
                } else {
                  const imgTag = document.createElement('img');
                  imgTag.src = require('@/assets/images/icon/download_icon.png');
                  imgTag.style.cursor = 'pointer';
                  imgTag.onclick = async () => {
                    const headers = {
                      'Content-Type': 'application/x-www-form-urlencoded',
                    };

                    const formData = new FormData();
                    const fileName = options.value.split('/');
                    formData.append('subFilePathConfigNm', 'HR_DOWNLOAD_DIR');
                    formData.append('secondPath', fileName[0]);

                    const payload = {
                      actionName: 'FILE_DOWNLOAD',
                      data: formData,
                      path: `/${fileName[1]}`,
                      loading: false,
                      headers: headers,
                      responseType: 'arraybuffer',
                    };

                    const res = await this.CALL_API(payload);
                    if (res.status === 200) {
                      if (!res.data) {
                        this.$_Msg('다운로드 실패');
                      } else {
                        downloadFile(res);
                      }
                    } else {
                      this.$_Msg('다운로드 실패');
                    }
                  };

                  container.append(imgTag);
                }
              },
            },
            {
              caption: '처리상태',
              dataField: 'status',
              width: 120,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowSorting: false,
              cellTemplate: (container, options) => {
                const code = this.$_getCode('process_status').find(code => code.codeValue === options.value);
                const status = code?.codeNm || options.value;
                container.append(status);
              },
            },
            {
              caption: '결과',
              dataField: 'result',
              width: 120,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              allowSorting: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
            },
            {
              caption: '원인',
              dataField: 'cause',
              width: 120,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              allowExporting: false,
              cellTemplate: (container, options) => {
                if (options.value !== 'NONE') {
                  let button = new DxButton({
                    propsData: {
                      text: '보기',
                      elementAttr: { class: 'btn_XS white light_filled' },
                      width: 100,
                      value: options.value,
                      onClick: () => {
                        //되는 스크립트
                        this.onOpenModal(
                          'ModalResulMessage',
                          {
                            title: '오류 내용',
                            buttons: {
                              cancel: { text: '닫기' },
                            },
                            width: 550,
                            height: 450,
                          },
                          options.data,
                        );
                      },
                    },
                  });
                  button.$mount();
                  container.append(button.$el);
                }
              },
            },
            {
              caption: '등록자',
              dataField: 'reqUser',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '등록일',
              dataField: 'reqDt',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              calculateCellValue: this.formatReqDt,
            },
            {
              caption: '완료일',
              dataField: 'cmpltDt',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              calculateCellValue: this.formatCmpltDt,
            },
          ],
        },
        bulkworkUploadList: [],
        stateCodeList: [],
        searchList: ['title', 'abcd'],
        startDate: null,
        endDate: null,
        stateCode: null,
        searchOpt: null,
        searchData: null,
        sortColumn: 'reqDt',
        sortOrderDesc: true,
        modal: {
          isOpened: false,
          currentComponent: null,
          initData: {},
          contentData: null,
        },
      };
    },

    computed: {
      getStatus() {
        return [{ codeValue: null, codeNm: '전체' }, ...this.$_getCode('process_status')];
      },
    },
    methods: {
      async onDeleteData() {
        const selectedRowsData = this.$refs.batchCheckRefName.selectedRowsData;
        if (selectedRowsData?.length) {
          if (await this.$_Confirm(this.$_lang('COMMON.MESSAGE.CMN_CFM_DELETE_SELECTED', { defaultValue: '선택한 데이터를 삭제하시겠습니까?' }))) {
            const ids = selectedRowsData.map(row => {
              return { id: row.id };
            });

            const payload = {
              actionName: 'EUC_BULKWORK_DELETE',
              data: { data: ids },
              loading: true,
            };

            const res = await this.CALL_EUC_API(payload);
            if (isSuccess(res)) {
              this.$_Toast(this.$_lang('CMN_SUC_DELETE'), { icon: 'success' });
              this.$refs.batchCheckRefName.refreshData();
            }
          }
        } else {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
        }
      },
      formatReqDt(rowData) {
        if (rowData?.reqDt) {
          return this.$_commonlib.formatDate(rowData.reqDt, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
        }
      },
      formatCmpltDt(rowData) {
        if (rowData?.cmpltDt) {
          return this.$_commonlib.formatDate(rowData.cmpltDt, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
        }
      },
      onOpenModal(componentNm, componentInitData, data) {
        this.modal.currentComponent = componentNm; //set dynamic component name in modal body slot
        this.modal.initData = componentInitData; //set init modal templet
        this.modal.contentData = data;

        this.isOpenModal(true);
      },
      /** @description: 팝업 오픈 체크 메서드 */
      isOpenModal(data) {
        this.modal.isOpened = data;
        if (!data) {
          this.modal.currentComponent = null;
          this.modal.initData = {};
        }
      },
      async selectDataList(sort = '-reqDt') {
        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);
            if (!params.sort) {
              params.sort = sort;
            }

            params = { ...params, ...this.searchType.paramsData };
            const payload = {
              actionName: 'EUC_BULKWORK_LIST_ALL',
              data: params,
              loading: false,
            };

            const rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await this.CALL_EUC_API(payload);
            if(isSuccess(res)) {
              rtnData.data = res.data.data;
              rtnData.totalCount = res.data.header.totalCount;
            }
            return rtnData;
          },
        });
      },
      onChangeSearchDay() {
        const reqDt =
          this.searchType.customTypes.dayStart + '000000' + '~' + this.searchType.customTypes.dayEnd + '235959';
        this.searchType.paramsData = { ...this.searchType.paramsData, reqDt };

        this.$_setSearchHistsCustomType(this.searchType.paramsData);
      },
      mountedData() {
        this.selectDataList();
      },
      createdData() {
        this.onChangeSearchDay();
      },
    },
    mounted() {
      this.mountedData();
    },
    created() {
      this.createdData();
    },
  };
</script>
