<template>
  <div class="page-sub-box">
    <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" />
  </div>
</template>

<script>
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid-v2.vue";

export default {
  components: {
    EspDxDataGrid,
  },
  props: {},
  data() {
    return {
      dataGrid: {
        callApi: 'CALL_CC_API',
        refName: 'nreasonGrid',
        dataSourceDefaultSortColumn: '+nreasonOrd',
        dataSource: [],
        apiActionNm: {
          select: 'CC_NREASON_LIST',
          merge: 'CC_NREASON_MERGE',
        },
        showActionButtons: {
          add: false,
          save: true,
          delete: false,
        },
        columns: [
          {
            caption: '사이트명',
            i18n: 'COMMON.WORD.SITE_NM',
            dataField: 'siteId',
            allowEditing: false,
            lookup: {
              dataSource: this.$store.getters.getSiteList,
              displayExpr: "siteNm",
              valueExpr: "id",
            },
          },
          {
            caption: '이석사유코드',
            i18n: 'CC.WORD.NREASON_CODE',
            dataField: 'nrsncode',
            allowEditing: false,
          },
          {
            caption: '이석사유명',
            i18n: 'CC.WORD.NREASON_NAME',
            dataField: 'nrsnNm',
            requiredRule: {},
          },
          {
            caption: '이석사유필드',
            i18n: 'CC.WORD.NREASON_FIELD',
            dataField: 'nrsn',
            lookup: {
              dataSource: this.$_getCode('cc_nreason_field').filter(item => item.delFl === 'N'),
              displayExpr: "codeNm",
              valueExpr: "codeValue",
            },
          },
          {
            caption: '순서',
            i18n: 'COMPONENTS.ORDER',
            dataField: 'nreasonOrd',
          },
          {
            caption: '사용여부',
            i18n: 'COMPONENTS.USE_STATUS',
            dataField: 'viewFl',
            lookup: {
              dataSource: this.$_enums.common.stringUsedFlag.values,
              displayExpr: 'label',
              valueExpr: 'value',
            },
          },
        ]
      },
    }
  },
}
</script>
