<!--
  PACKAGE_NAME : src/pages/esp/auth
  FILE_NAME : auth-tree-list.vue
  AUTHOR : devyoon91
  DATE : 2024-12-10
  DESCRIPTION : 권한 트리리스트 컴포넌트
-->
<template>
  <div>
    <esp-dx-tree-list
      :tree-list="treeList"
      :ref="treeList.refName"
      @selection-changed="handleSelectionChanged"
      @cell-prepared="handleCellPrepared"
    />

    <modal-add-auth
      v-if="modal.visible"
      :is-open="modal.visible"
      :auth-info="modal.authInfo"
      :auth-data-list="modal.authDataList"
      :is-edit="modal.isEdit"
      @saveModal="handleSaveModal"
      @closeModal="handleCloseModal"
    />
  </div>
</template>

<script>
  import ModalAddAuth from './modal-add-auth.vue';
  import EspDxTreeList from '../../../components/devextreme/esp-dx-tree-list-v2.vue';
  import {isSuccess} from "@/plugins/common-lib";

  export default {
    components: { EspDxTreeList, ModalAddAuth },
    data() {
      return {
        modal: {
          isOpened: false,
          visible: false,
          authInfo: {},
          authDataList: [],
          isEdit: false,
        },
        treeList: {
          keyExpr: 'id',
          refName: 'authTreeList',
          dataSource: [], // 권한 데이터
          height: 'calc(100vh - 560.5px)',
          showBorders: true,
          showColumnHeaders: false,
          showActionButtons: {
            sort: false,
            toggleExpand: false,
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  text: this.$_lang('COMPONENTS.ADD', { defaultValue: '추가' }),
                  elementAttr: { class: 'btn_XS default filled add1' },
                  width: 60,
                  height: 30,
                  onClick: () => {
                    this.handleClickAddAuth(false);
                  },
                },
                location: 'before',
              },
              {
                widget: 'dxButton',
                options: {
                  text: this.$_lang('COMPONENTS.EDIT', { defaultValue: '수정' }),
                  elementAttr: { class: 'btn_XS default filled modify' },
                  width: 60,
                  height: 30,
                  onClick: () => {
                    this.handleClickAddAuth(true);
                  },
                },
                location: 'before',
              },
              {
                widget: 'dxButton',
                options: {
                  text: this.$_lang('COMPONENTS.DELETE', { defaultValue: '삭제' }),
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 60,
                  height: 30,
                  onClick: () => {
                    this.deleteAuth();
                  },
                },
                location: 'before',
              },
            ],
          },
          editing: {
            allowUpdating: false,
            allowDeleting: false,
            allowAdding: false,
          },
          filterRow: {
            visible: false,
          },
          rowDragging: {
            enabled: false,
            allowDropInsideItem: false, // 드래그로 아이템 안에 드롭 허용 여부
            allowReordering: false, // 드래그로 순서 변경 허용 여부
            showDragIcons: false, // 드래그 아이콘 표시 여부
          },
          columns: [
            { dataField: 'id', caption: 'id', visible: false },
            { dataField: 'parentId', caption: 'parentId', visible: false },
            { dataField: 'authNm', caption: '권한', allowSorting: false, alignment: 'left' },
          ],
        },
      };
    },
    methods: {
      /**
       * @description 권한 선택 변경 이벤트
       * @param e
       * @return {Promise<void>}
       */
      async handleSelectionChanged(e) {
        this.$emit('auth-tree-selection-changed', e);
      },
      /** @description : 트리 헤더 재설정 */
      handleCellPrepared(cellInfo) {
        let element = cellInfo.cellElement;
        if (cellInfo.rowType === 'header') {
          element.style.textAlign = 'center';
          if (cellInfo.columnIndex === 0) {
            element.setAttribute('colspan', 2);
          } else {
            element.style.display = 'none';
          }
        }
      },
      isNotSelected() {
        const selectedData = this.$refs.authTreeList.getInstance.getSelectedRowsData();
        return selectedData.length === 0;
      },
      getSelectedId() {
        return this.$refs.authTreeList.getInstance.getSelectedRowKeys()[0];
      },
      initData() {
        this.$refs.authTreeList.getInstance.clearSelection();
      },
      /**
       * 권한 사용자 부여 여부 확인
       *
       * @param authIds {Array} - 권한 id 배열
       * @return {Promise<boolean>}
       */
      async checkAuthMember(authIds) {
        if (authIds.length === 0) return false; // 권한이 없을 경우 false 반환

        const payload = {
          actionName: 'MEMBER_LIST_ALL',
          data: { authId: authIds.join(',') },
          useErrorPopup: true,
        };

        const res = await this.CALL_API(payload);

        if (isSuccess(res)) {
          return res.data.data.length > 0;
        }
        return false;
      },
      /**
       * 현재 권한과 관련된 모든 권한 id들을 반환
       *
       * @param authId
       * @return {Promise<any[]>}
       */
      async getAllRelatedAuthIds(authId) {
        const relatedIds = new Set();
        const queue = [authId]; // 초기 큐에 authId를 추가

        while (queue.length > 0) {
          const currentId = queue.shift(); // 큐에서 현재 id를 꺼냄

          if (relatedIds.has(currentId)) {
            continue; // 이미 처리된 id는 무시
          }

          relatedIds.add(currentId); // 관련된 id Set에 현재 id 추가

          // 현재 id와 관련된 자식 id들을 찾음
          const children = this.treeList.dataSource.filter(node => node.parentId === currentId);

          // 자식 id들을 큐에 추가
          for (const child of children) {
            queue.push(child.id);
          }
        }

        return Array.from(relatedIds); // 중복 배제 후 관계된 id들을 배열로 변환하여 반환
      },
      /**
       * 권한 삭제
       *
       * @return {Promise<void>}
       */
      async deleteAuth() {
        if (this.isNotSelected()) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }

        const authIds = await this.getAllRelatedAuthIds(this.getSelectedId());

        // 사용자에게 권한이 부여된 경우 삭제 불가
        if (await this.checkAuthMember(authIds)) {
          this.$_Msg(
            this.$_lang('CANNOT_BE_DEL_ACCOUNT_AUTH', { defaultValue: '사용자에게 부여된 권한이 있어 삭제가 불가능합니다.' }),
          );
          return;
        }

        if (
          await this.$_Confirm(
            this.$_lang('COMMON.MESSAGE.CONFIRM_DELETE_WITH_SUB_PERMISSIONS', {
              defaultValue: '삭제 시 하위권한까지 일괄 삭제됩니다. <br>삭제하시겠습니까?',
            }),
          )
        ) {
          await this.deleteAuthById(authIds);
        }
      },
      /**
       * 권한 삭제
       *
       * @param authIds {Array} - 권한 id 배열
       * @return {Promise<void>}
       */
      async deleteAuthById(authIds) {
        const payload = {
          actionName: 'AUTH_DELETE',
          data: authIds,
          useErrorPopup: true,
        };

        const res = await this.CALL_API(payload);

        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('CMN_SUC_DELETE', { defaultValue: '선택한 데이터가 삭제되었습니다.' }));
          await this.getAuthMenuSub();
        }
      },
      /**
       * @description 권한 추가 버튼 클릭 이벤트
       * @param isEdit {boolean} - 수정 여부
       */
      handleClickAddAuth(isEdit = false) {
        if (isEdit === true && this.isNotSelected()) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }

        this.modal.visible = true; // 모달 오픈

        // 권한 정보 전달
        this.modal.authInfo = this.$refs.authTreeList.getInstance.getSelectedRowsData()[0];
        this.modal.authDataList = this.$_commonlib.cloneObj(this.treeList.dataSource);
        this.modal.isEdit = isEdit; // 수정 여부
      },
      /** @description 권한 추가 모달 저장 이벤트 */
      async handleSaveModal() {
        await this.initModalData();
        await this.getAuthMenuSub();
      },
      /** @description : 팝업 닫기 */
      handleCloseModal() {
        this.initModalData();
      },
      /** @description : 모달 데이터 초기화 */
      initModalData() {
        this.modal.visible = false;
        this.modal.authInfo = {};
        this.modal.authDataList = [];
        this.modal.isEdit = false;
      },
      /**
       * @description 권한 조회
       * @return {Promise<void>}
       */
      async getAuthMenuSub() {
        const authId = this.$store.getters.getAuthId;
        const payload = {
          actionName: 'AUTH_MENU_SUB_LIST',
          data: {
            authId: authId,
          },
          useErrorPopup: true,
        };

        let rtnData = [];
        const res = await this.CALL_API(payload);
        if (isSuccess(res)) {
          rtnData = res.data.data;
        }
        this.treeList.dataSource = rtnData;
      },
    },
    mounted() {
      this.getAuthMenuSub();
    },
  };
</script>

<style lang="scss" scoped></style>
