import { EnumEntry, EnumMap } from '@/configs/enums/util';

/**
 * @description 사용여부 문자열 플래그 Enum 클래스
 */
class StringUsedFlag extends EnumEntry {
  YES = {
    ...EnumMap,
    key: 'COMMON_STRING_USED_FLAG_YES',
    value: 'Y',
    label: '사용',
    group: 'COMMON_STRING_USED_FLAG',
    group_label: '사용여부(문자열)',
    equals: value => {
      return this.YES.value === value;
    },
  };

  NO = {
    ...EnumMap,
    key: 'COMMON_STRING_USED_FLAG_NO',
    value: 'N',
    label: '미사용',
    group: 'COMMON_STRING_USED_FLAG',
    group_label: '사용여부(문자열)',
    equals: value => {
      return this.NO.value === value;
    },
  };

  values = [this.YES, this.NO];

  /**
   * @description 사용여부 문자열 플래그 값 반환
   * @param value {string}
   * @return {*|string|string} 일치하지 않으면 '' 반환
   */
  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };
}

/**
 * @description 삭제여부 반대 문자열 플래그 Enum 클래스
 */
class StringDelFlag extends EnumEntry {
  YES = {
    ...EnumMap,
    key: 'COMMON_STRING_DEL_FLAG_YES',
    value: 'Y',
    label: '삭제',
    group: 'COMMON_STRING_DEL_FLAG',
    group_label: '삭제여부(문자열)',
    equals: value => {
      return this.YES.value === value;
    },
  };

  NO = {
    ...EnumMap,
    key: 'COMMON_STRING_DEL_FLAG_NO',
    value: 'N',
    label: '미삭제',
    group: 'COMMON_STRING_DEL_FLAG',
    group_label: '삭제여부(문자열)',
    equals: value => {
      return this.NO.value === value;
    },
  };

  values = [this.YES, this.NO];

  /**
   * @description 삭제여부 문자열 플래그 값 반환
   * @param value {string}
   * @return {*|string|string} 일치하지 않으면 '' 반환
   */
  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };
}

/**
 * @description 사용여부 정수형 플래그 Enum 클래스
 */
class IntegerUsedFlag extends EnumEntry {
  YES = {
    ...EnumMap,
    key: 'COMMON_INTEGER_USED_FLAG_YES',
    value: 1,
    label: '사용',
    group: 'COMMON_INTEGER_USED_FLAG',
    group_label: '사용여부(정수형)',
    equals: value => {
      return this.YES.value === value;
    },
  };

  NO = {
    ...EnumMap,
    key: 'COMMON_INTEGER_USED_FLAG_NO',
    value: 0,
    label: '미사용',
    group: 'COMMON_INTEGER_USED_FLAG',
    group_label: '사용여부(정수형)',
    equals: value => {
      return this.NO.value === value;
    },
  };

  values = [this.YES, this.NO];

  /**
   * @description 사용여부 정수 플래그 값 반환
   * @param value {number}
   * @return {number} 일치하지 않으면 -1 반환
   */
  getValue = value => {
    if (!value) {
      return -1;
    }
    return this.values.find(item => item.value === value)?.value || -1;
  };
}

/**
 * @description 표시여부 문자열 플래그 Enum 클래스
 */
class StringViewFlag extends EnumEntry {
  YES = {
    ...EnumMap,
    key: 'COMMON_STRING_VIEW_FLAG_YES',
    value: 'Y',
    label: '표시',
    group: 'COMMON_STRING_VIEW_FLAG',
    group_label: '표시여부(문자열)',
    equals: value => {
      return this.YES.value === value;
    },
  };

  NO = {
    ...EnumMap,
    key: 'COMMON_STRING_VIEW_FLAG_NO',
    value: 'N',
    label: '미표시',
    group: 'COMMON_STRING_VIEW_FLAG',
    group_label: '표시여부(문자열)',
    equals: value => {
      return this.NO.value === value;
    },
  };

  values = [this.YES, this.NO];

  /**
   * @description 표시여부 문자열 플래그 값 반환
   * @param value {string}
   * @return {*|string|string} 일치하지 않으면 '' 반환
   */
  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };
}

/**
 * @description 표시여부 정수형 플래그 Enum 클래스
 */
class IntegerViewFlag extends EnumEntry {
  YES = {
    ...EnumMap,
    key: 'COMMON_INTEGER_VIEW_FLAG_YES',
    value: 1,
    label: '표시',
    group: 'COMMON_INTEGER_VIEW_FLAG',
    group_label: '표시여부(정수형)',
    equals: value => {
      return this.YES.value === value;
    },
  };

  NO = {
    ...EnumMap,
    key: 'COMMON_INTEGER_VIEW_FLAG_NO',
    value: 0,
    label: '미표시',
    group: 'COMMON_INTEGER_VIEW_FLAG',
    group_label: '표시여부(정수형)',
    equals: value => {
      return this.NO.value === value;
    },
  };

  values = [this.YES, this.NO];

  /**
   * @description 표시여부 정수 플래그 값 반환
   * @param value {number}
   * @return {number} 일치하지 않으면 -1 반환
   */
  getValue = value => {
    if (!value) {
      return -1;
    }
    return this.values.find(item => item.value === value)?.value || -1;
  };
}

/**
 * @description 승인여부 문자형 플래그 Enum 클래스
 */
class StringApprovedFlag extends EnumEntry {
  UNAPPROVED = {
    ...EnumMap,
    key: 'COMMON_STRING_APPROVED_FLAG_UNAPPROVED',
    value: 'N',
    label: '미승인',
    group: 'COMMON_STRING_APPROVED_FLAG',
    group_label: '승인여부(문자형)',
    equals: value => {
      return this.UNAPPROVED.value === value;
    },
  };

  APPROVED = {
    ...EnumMap,
    key: 'COMMON_STRING_APPROVED_FLAG_APPROVED',
    value: 'Y',
    label: '승인',
    group: 'COMMON_STRING_APPROVED_FLAG',
    group_label: '승인여부(문자형)',
    equals: value => {
      return this.APPROVED.value === value;
    },
  };

  values = [this.UNAPPROVED, this.APPROVED];

  /**
   * @description 승인여부 문자열 플래그 값 반환
   * @param value {string}
   * @return {*|string|string} 일치하지 않으면 '' 반환
   */
  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };
}

/**
 * @description 처리여부 문자형 플래그 Enum 클래스
 */
class StringCompleteFlag extends EnumEntry {
  APPROVAL = {
    ...EnumMap,
    key: 'COMMON_STRING_COMPLETE_FLAG_APPROVAL',
    value: 'APPROVAL',
    label: '승인완료',
    group: 'COMMON_STRING_COMPLETE_FLAG',
    group_label: '처리여부(문자형)',
    equals: value => {
      return this.APPROVAL.value === value;
    },
  };

  COMPLETE = {
    ...EnumMap,
    key: 'COMMON_STRING_COMPLETE_FLAG_COMPLETE',
    value: 'COMPLETE',
    label: '처리완료',
    group: 'COMMON_STRING_COMPLETE_FLAG',
    group_label: '처리여부(문자형)',
    equals: value => {
      return this.COMPLETE.value === value;
    },
  };

  values = [this.APPROVAL, this.COMPLETE];

  /**
   * @description 처리여부 문자열 플래그 값 반환
   * @param value {string}
   * @return {*|string|string} 일치하지 않으면 '' 반환
   */
  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };
}

/**
 * @description 메인연결 페이지 타입 Enum 클래스
 */
class MainPageType extends EnumEntry {
  MENU = {
    ...EnumMap,
    key: 'MAIN_PAGE_TYPE_MENU',
    value: 'MENU',
    label: '메뉴',
    group: 'MAIN_PAGE_TYPE',
    group_label: '메인설정 값종류',
    equals: value => {
      return this.MENU.value === value;
    },
  };

  DASHBOARD = {
    ...EnumMap,
    key: 'MAIN_PAGE_TYPE_DASHBOARD',
    value: 'DASHBOARD',
    label: '대시보드',
    group: 'MAIN_PAGE_TYPE',
    group_label: '메인설정 값종류',
    equals: value => {
      return this.DASHBOARD.value === value;
    },
  };

  NONE = {
    ...EnumMap,
    key: 'MAIN_PAGE_TYPE_NONE',
    value: 'NONE',
    label: '없음',
    group: 'MAIN_PAGE_TYPE',
    group_label: '메인설정 값종류',
    equals: value => {
      return this.NONE.value === value;
    },
  };

  values = [this.MENU, this.DASHBOARD, this.NONE];

  /**
   * @description 메인설정 값종류 탐색 후 값 반환
   * @param value {string}
   * @return {*|string|string} 일치하지 않으면 '' 반환
   */
  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };
}

/**
 * @description 메인설정 적용대상 Enum 클래스
 */
class MainTargetField extends EnumEntry {
  AUTH_ID = {
    ...EnumMap,
    key: 'MAIN_SETTING_TARGET_TYPE_AUTH_ID',
    value: 'AUTH_ID',
    label: '권한ID',
    group: 'MAIN_SETTING_TARGET_TYPE',
    group_label: '메인설정 적용대상',
    equals: value => {
      return this.AUTH_ID.value === value;
    },
  };

  LOGIN_ID = {
    ...EnumMap,
    key: 'MAIN_SETTING_TARGET_TYPE_LOGIN_ID',
    value: 'LOGIN_ID',
    label: '로그인ID',
    group: 'MAIN_SETTING_TARGET_TYPE',
    group_label: '메인설정 적용대상',
    equals: value => {
      return this.LOGIN_ID.value === value;
    },
  };

  values = [this.AUTH_ID, this.LOGIN_ID];

  /**
   * @description 메인설정 적용대상 탐색 후 값 반환
   * @param value {string}
   * @return {*|string|string} 일치하지 않으면 '' 반환
   */
  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };
}

/**
 * @description 로그인 이력 이벤트 타입
 */
class LoginHistoryEventType extends EnumEntry {
  LOGIN = {
    ...EnumMap,
    key: 'LOGIN_HISTORY_EVENT_TYPE_LOGIN',
    value: 'LOGIN',
    label: '로그인',
    group: 'LOGIN_HISTORY_EVENT_TYPE',
    group_label: '로그인 이력 이벤트 타입',
    equals: value => {
      return this.LOGIN.value === value;
    },
  };

  LOGOUT = {
    ...EnumMap,
    key: 'LOGIN_HISTORY_EVENT_TYPE_LOGOUT',
    value: 'LOGOUT',
    label: '로그아웃',
    group: 'LOGIN_HISTORY_EVENT_TYPE',
    group_label: '로그인 이력 이벤트 타입',
    equals: value => {
      return this.LOGOUT.value === value;
    },
  };

  DIRECT_LOGIN = {
    ...EnumMap,
    key: 'LOGIN_HISTORY_EVENT_TYPE_DIRECT_LOGIN',
    value: 'DIRECT_LOGIN',
    label: '다이렉트 로그인',
    group: 'LOGIN_HISTORY_EVENT_TYPE',
    group_label: '로그인 이력 이벤트 타입',
    equals: value => {
      return this.DIRECT_LOGIN.value === value;
    },
  };

  values = [this.LOGIN, this.LOGOUT, this.DIRECT_LOGIN];
}

/**
 * @description 사용자 계정 상태 Enum 클래스
 */
class MemberState extends EnumEntry {
  ACTIVE = {
    ...EnumMap,
    key: 'MEMBER_STATE_ACTIVE',
    value: 'ACTIVE',
    label: '활성',
    group: 'MEMBER_STATE',
    group_label: '사용자 상태',
    equals: value => {
      return this.ACTIVE.value === value;
    },
  };

  TEMPORARY = {
    ...EnumMap,
    key: 'MEMBER_STATE_TEMPORARY',
    value: 'TEMPORARY',
    label: '임시',
    group: 'MEMBER_STATE',
    group_label: '사용자 상태',
    equals: value => {
      return this.TEMPORARY.value === value;
    },
  };

  LOCKED = {
    ...EnumMap,
    key: 'MEMBER_STATE_LOCKED',
    value: 'LOCKED',
    label: '잠금',
    group: 'MEMBER_STATE',
    group_label: '사용자 상태',
    equals: value => {
      return this.LOCKED.value === value;
    },
  };

  DORMANT = {
    ...EnumMap,
    key: 'MEMBER_STATE_DORMANT',
    value: 'DORMANT',
    label: '휴면',
    group: 'MEMBER_STATE',
    group_label: '사용자 상태',
    equals: value => {
      return this.DORMANT.value === value;
    },
  };

  DELETED = {
    ...EnumMap,
    key: 'MEMBER_STATE_DELETED',
    value: 'DELETED',
    label: '삭제',
    group: 'MEMBER_STATE',
    group_label: '사용자 상태',
    equals: value => {
      return this.DELETED.value === value;
    },
  };

  EXPIRED = {
    ...EnumMap,
    key: 'MEMBER_STATE_EXPIRED',
    value: 'EXPIRED',
    label: '패스워드 만료',
    group: 'MEMBER_STATE',
    equals: value => {
      return this.EXPIRED.value === value;
    },
  };

  values = [this.ACTIVE, this.TEMPORARY, this.LOCKED, this.DORMANT, this.DELETED, this.EXPIRED];

  /**
   * @description 사용자 상태에 따른 툴팁 반환
   * @param value
   * @return {*|string|string}
   */
  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };
}

/**
 * @description 메뉴 유형 Enum 클래스
 */
class MenuType extends EnumEntry {
  NORMAL_MENU = {
    ...EnumMap,
    key: 'MENU_TYPE_NORMAL_MENU',
    value: 'NORMAL_MENU',
    label: '일반 메뉴',
    group: 'MENU_TYPE',
    group_label: '메뉴 타입',
    equals: value => {
      return this.NORMAL_MENU.value === value;
    },
  };

  NORMAL_PAGE = {
    ...EnumMap,
    key: 'MENU_TYPE_NORMAL_PAGE',
    value: 'NORMAL_PAGE',
    label: '일반 페이지',
    group: 'MENU_TYPE',
    group_label: '메뉴 타입',
    equals: value => {
      return this.NORMAL_PAGE.value === value;
    },
  };

  REPORT = {
    ...EnumMap,
    key: 'MENU_TYPE_REPORT',
    value: 'REPORT',
    label: '보고서',
    group: 'MENU_TYPE',
    group_label: '메뉴 타입',
    equals: value => {
      return this.REPORT.value === value;
    },
  };

  LINK = {
    ...EnumMap,
    key: 'MENU_TYPE_LINK',
    value: 'LINK',
    label: '링크',
    group: 'MENU_TYPE',
    group_label: '메뉴 타입',
    equals: value => {
      return this.LINK.value === value;
    },
  };

  values = [this.NORMAL_MENU, this.NORMAL_PAGE, this.REPORT, this.LINK];

  /**
   * @description 메뉴 타입에 따른 값 반환
   * @param value
   * @return {*|string}
   */
  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };

  /**
   * @description 메뉴 타입에 따른 툴팁 반환
   * @param type
   * @return {string}
   */
  getMenuTypeTooltip = type => {
    let tooltip = '';
    switch (type) {
      case this.NORMAL_MENU.value:
        tooltip = '일반 페이지의 상위 메뉴(대메뉴/중메뉴/소메뉴)';
        break;
      case this.NORMAL_PAGE.value:
        tooltip = '일반 페이지';
        break;
      case this.REPORT.value:
        tooltip = '보고서 관련 메뉴';
        break;
      case this.LINK.value:
        tooltip = '링크 이동을 위한 메뉴';
        break;
      default:
        break;
    }
    return tooltip;
  };
}

/**
 * @description 휴일 타입 Enum 클래스
 */
class HolidayType extends EnumEntry {
  FIXED = {
    ...EnumMap,
    key: 'HOLIDAY_TYPE_FIXED',
    value: 'FIXED',
    label: '고정휴일',
    group: 'HOLIDAY_TYPE',
    group_label: '휴일 타입',
    equals: value => {
      return this.FIXED.value === value;
    },
  };

  FLOATING = {
    ...EnumMap,
    key: 'HOLIDAY_TYPE_FLOATING',
    value: 'FLOATING',
    label: '변동휴일',
    group: 'HOLIDAY_TYPE',
    group_label: '휴일 타입',
    equals: value => {
      return this.FLOATING.value === value;
    },
  };

  values = [this.FIXED, this.FLOATING];

  /**
   * @description 휴일 타입에 따른 값 반환
   * @param value
   * @return {*|string|string}
   */
  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };
}

/**
 * @description 요일(String) Enum 클래스
 */
class StringDayOfWeek extends EnumEntry {
  MONDAY = {
    ...EnumMap,
    key: 'STRING_DAY_OF_WEEK_MON',
    value: 'MON',
    label: '월',
    group: 'STRING_DAY_OF_WEEK',
    group_label: '요일(String)',
    sort: 1,
    equals: value => {
      return this.MONDAY.value === value;
    },
  };

  TUESDAY = {
    ...EnumMap,
    key: 'STRING_DAY_OF_WEEK_TUE',
    value: 'TUE',
    label: '화',
    group: 'STRING_DAY_OF_WEEK',
    group_label: '요일(String)',
    sort: 2,
    equals: value => {
      return this.TUESDAY.value === value;
    },
  };

  WEDNESDAY = {
    ...EnumMap,
    key: 'STRING_DAY_OF_WEEK_WED',
    value: 'WED',
    label: '수',
    group: 'STRING_DAY_OF_WEEK',
    group_label: '요일(String)',
    sort: 3,
    equals: value => {
      return this.WEDNESDAY.value === value;
    },
  };

  THURSDAY = {
    ...EnumMap,
    key: 'STRING_DAY_OF_WEEK_THU',
    value: 'THU',
    label: '목',
    group: 'STRING_DAY_OF_WEEK',
    group_label: '요일(String)',
    sort: 4,
    equals: value => {
      return this.THURSDAY.value === value;
    },
  };

  FRIDAY = {
    ...EnumMap,
    key: 'STRING_DAY_OF_WEEK_FRI',
    value: 'FRI',
    label: '금',
    group: 'STRING_DAY_OF_WEEK',
    group_label: '요일(String)',
    sort: 5,
    equals: value => {
      return this.FRIDAY.value === value;
    },
  };

  SATURDAY = {
    ...EnumMap,
    key: 'STRING_DAY_OF_WEEK_SAT',
    value: 'SAT',
    label: '토',
    group: 'STRING_DAY_OF_WEEK',
    group_label: '요일(String)',
    sort: 6,
    equals: value => {
      return this.SATURDAY.value === value;
    },
  };

  SUNDAY = {
    ...EnumMap,
    key: 'STRING_DAY_OF_WEEK_SUN',
    value: 'SUN',
    label: '일',
    group: 'STRING_DAY_OF_WEEK',
    group_label: '요일(String)',
    sort: 7,
    equals: value => {
      return this.SUNDAY.value === value;
    },
  };

  HOLIDAY = {
    ...EnumMap,
    key: 'STRING_DAY_OF_WEEK_HOLI',
    value: 'HOLI',
    label: '공휴일',
    group: 'STRING_DAY_OF_WEEK',
    group_label: '요일(String)',
    sort: 8,
    equals: value => {
      return this.HOLIDAY.value === value;
    },
  };

  values = [this.MONDAY, this.TUESDAY, this.WEDNESDAY, this.THURSDAY, this.FRIDAY, this.SATURDAY, this.SUNDAY, this.HOLIDAY];

  /**
   * @description 요일에 따른 값 반환
   * @param value
   * @return {*|string|string}
   */
  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };
}

/**
 * @description 요일(Integer) Enum 클래스
 */
class IntegerDayOfWeek extends EnumEntry {
  MONDAY = {
    ...EnumMap,
    key: 'INTEGER_DAY_OF_WEEK_MON',
    value: 2,
    label: '월',
    group: 'INTEGER_DAY_OF_WEEK',
    group_label: '요일(INTEGER)',
    sort: 1,
    equals: value => {
      return this.MONDAY.value === value;
    },
  };

  TUESDAY = {
    ...EnumMap,
    key: 'INTEGER__DAY_OF_WEEK_TUE',
    value: 3,
    label: '화',
    group: 'INTEGER_DAY_OF_WEEK',
    group_label: '요일(INTEGER)',
    sort: 2,
    equals: value => {
      return this.TUESDAY.value === value;
    },
  };

  WEDNESDAY = {
    ...EnumMap,
    key: 'INTEGER_DAY_OF_WEEK_WED',
    value: 4,
    label: '수',
    group: 'INTEGER_DAY_OF_WEEK',
    group_label: '요일(INTEGER)',
    sort: 3,
    equals: value => {
      return this.WEDNESDAY.value === value;
    },
  };

  THURSDAY = {
    ...EnumMap,
    key: 'INTEGER_DAY_OF_WEEK_THU',
    value: 5,
    label: '목',
    group: 'INTEGER_DAY_OF_WEEK',
    group_label: '요일(INTEGER)',
    sort: 4,
    equals: value => {
      return this.THURSDAY.value === value;
    },
  };

  FRIDAY = {
    ...EnumMap,
    key: 'INTEGER_DAY_OF_WEEK_FRI',
    value: 6,
    label: '금',
    group: 'INTEGER_DAY_OF_WEEK',
    group_label: '요일(INTEGER)',
    sort: 5,
    equals: value => {
      return this.FRIDAY.value === value;
    },
  };

  SATURDAY = {
    ...EnumMap,
    key: 'INTEGER_DAY_OF_WEEK_SAT',
    value: 7,
    label: '토',
    group: 'INTEGER_DAY_OF_WEEK',
    group_label: '요일(INTEGER)',
    sort: 6,
    equals: value => {
      return this.SATURDAY.value === value;
    },
  };

  SUNDAY = {
    ...EnumMap,
    key: 'INTEGER_DAY_OF_WEEK_SAT',
    value: 1,
    label: '일',
    group: 'INTEGER_DAY_OF_WEEK',
    group_label: '요일(INTEGER)',
    sort: 7,
    equals: value => {
      return this.SUNDAY.value === value;
    },
  };

  HOLIDAY = {
    ...EnumMap,
    key: 'INTEGER_DAY_OF_WEEK_HOLI',
    value: 8,
    label: '공휴일',
    group: 'INTEGER_DAY_OF_WEEK',
    group_label: '요일(INTEGER)',
    sort: 8,
    equals: value => {
      return this.HOLIDAY.value === value;
    },
  };

  values = [this.MONDAY, this.TUESDAY, this.WEDNESDAY, this.THURSDAY, this.FRIDAY, this.SATURDAY, this.SUNDAY, this.HOLIDAY];

  /**
   * @description 요일에 따른 값 반환
   * @param value
   * @return {*|string|string}
   */
  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };
}

/**
 * @description 요일(Char) Enum 클래스
 */
class CharDayOfWeek extends EnumEntry {
  MONDAY = {
    ...EnumMap,
    key: 'CHAR_DAY_OF_WEEK_MON',
    value: '2',
    label: '월',
    group: 'CHAR_DAY_OF_WEEK',
    group_label: '요일(CHAR)',
    sort: 1,
    equals: value => {
      return this.MONDAY.value === value;
    },
  };

  TUESDAY = {
    ...EnumMap,
    key: 'CHAR_DAY_OF_WEEK_TUE',
    value: '3',
    label: '화',
    group: 'CHAR_DAY_OF_WEEK',
    group_label: '요일(CHAR)',
    sort: 2,
    equals: value => {
      return this.TUESDAY.value === value;
    },
  };

  WEDNESDAY = {
    ...EnumMap,
    key: 'CHAR_DAY_OF_WEEK_WED',
    value: '4',
    label: '수',
    group: 'CHAR_DAY_OF_WEEK',
    group_label: '요일(CHAR)',
    sort: 3,
    equals: value => {
      return this.WEDNESDAY.value === value;
    },
  };

  THURSDAY = {
    ...EnumMap,
    key: 'CHAR_DAY_OF_WEEK_THU',
    value: '5',
    label: '목',
    group: 'CHAR_DAY_OF_WEEK',
    group_label: '요일(CHAR)',
    sort: 4,
    equals: value => {
      return this.THURSDAY.value === value;
    },
  };

  FRIDAY = {
    ...EnumMap,
    key: 'CHAR_DAY_OF_WEEK_FRI',
    value: '6',
    label: '금',
    group: 'CHAR_DAY_OF_WEEK',
    group_label: '요일(CHAR)',
    sort: 5,
    equals: value => {
      return this.FRIDAY.value === value;
    },
  };

  SATURDAY = {
    ...EnumMap,
    key: 'CHAR_DAY_OF_WEEK_SAT',
    value: '7',
    label: '토',
    group: 'CHAR_DAY_OF_WEEK',
    group_label: '요일(CHAR)',
    sort: 6,
    equals: value => {
      return this.SATURDAY.value === value;
    },
  };

  SUNDAY = {
    ...EnumMap,
    key: 'CHAR_DAY_OF_WEEK_SAT',
    value: '1',
    label: '일',
    group: 'CHAR_DAY_OF_WEEK',
    group_label: '요일(CHAR)',
    sort: 7,
    equals: value => {
      return this.SUNDAY.value === value;
    },
  };

  HOLIDAY = {
    ...EnumMap,
    key: 'CHAR_DAY_OF_WEEK_HOLI',
    value: 'Y',
    label: '공휴일',
    group: 'CHAR_DAY_OF_WEEK',
    group_label: '요일(CHAR)',
    sort: 8,
    equals: value => {
      return this.HOLIDAY.value === value;
    },
  };

  values = [this.MONDAY, this.TUESDAY, this.WEDNESDAY, this.THURSDAY, this.FRIDAY, this.SATURDAY, this.SUNDAY, this.HOLIDAY];

  /**
   * @description 요일에 따른 값 반환
   * @param value
   * @return {*|string|string}
   */
  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };
}

/**
 * @description 페이징 사이즈 Enum 클래스
 */
class PagingSizeList extends EnumEntry {
  ROW_10 = {
    ...EnumMap,
    key: 'PAGING_SIZE_ROW_10',
    value: 10,
    label: '10개씩 보기',
    group: 'PAGING_SIZE',
    group_label: '페이징 사이즈',
    equals: value => {
      return this.ROW_10.value === value;
    },
  };

  ROW_20 = {
    ...EnumMap,
    key: 'PAGING_SIZE_ROW_20',
    value: 20,
    label: '20개씩 보기',
    group: 'PAGING_SIZE',
    group_label: '페이징 사이즈',
    equals: value => {
      return this.ROW_20.value === value;
    },
  };

  ROW_30 = {
    ...EnumMap,
    key: 'PAGING_SIZE_ROW_30',
    value: 30,
    label: '30개씩 보기',
    group: 'PAGING_SIZE',
    group_label: '페이징 사이즈',
    equals: value => {
      return this.ROW_30.value === value;
    },
  };

  ROW_50 = {
    ...EnumMap,
    key: 'PAGING_SIZE_ROW_50',
    value: 50,
    label: '50개씩 보기',
    group: 'PAGING_SIZE',
    group_label: '페이징 사이즈',
    equals: value => {
      return this.ROW_50.value === value;
    },
  };

  ROW_100 = {
    ...EnumMap,
    key: 'PAGING_SIZE_ROW_100',
    value: 100,
    label: '100개씩 보기',
    group: 'PAGING_SIZE',
    group_label: '페이징 사이즈',
    equals: value => {
      return this.ROW_100.value === value;
    },
  };

  ROW_300 = {
    ...EnumMap,
    key: 'PAGING_SIZE_ROW_300',
    value: 300,
    label: '300개씩 보기',
    group: 'PAGING_SIZE',
    group_label: '페이징 사이즈',
    equals: value => {
      return this.ROW_300.value === value;
    },
  };

  values = [this.ROW_10, this.ROW_20, this.ROW_30, this.ROW_50, this.ROW_100, this.ROW_300];

  /**
   * @description 페이지 사이즈에 따른 레이블 반환
   * @return {(number|*)[]}
   */
  getPageValues = () => {
    return this.values.map(item => item.value);
  };
}

/**
 * 모든 Enum 코드를 반환
 * @returns {*[]}
 */
const getEnumAllCodes = () => {
  return [].concat(
    new StringUsedFlag().values,
    new StringDelFlag().values,
    new IntegerUsedFlag().values,
    new StringViewFlag().values,
    new IntegerViewFlag().values,
    new StringApprovedFlag().values,
    new StringCompleteFlag().values,
    new MainPageType().values,
    new MainTargetField().values,
    new LoginHistoryEventType().values,
    new MemberState().values,
    new MenuType().values,
    new HolidayType().values,
    new StringDayOfWeek().values,
    new CharDayOfWeek().values,
    new IntegerDayOfWeek().values,
    new PagingSizeList().values,
  );
};

export default {
  getEnumAllCodes: getEnumAllCodes(),
  stringUsedFlag: new StringUsedFlag(),
  stringDelFlag: new StringDelFlag(),
  integerUsedFlag: new IntegerUsedFlag(),
  stringViewFlag: new StringViewFlag(),
  integerViewFlag: new IntegerViewFlag(),
  stringApprovedFlag: new StringApprovedFlag(),
  stringCompleteFlag: new StringCompleteFlag(),
  mainPageType: new MainPageType(),
  mainTargetField: new MainTargetField(),
  loginHistoryEventType: new LoginHistoryEventType(),
  memberState: new MemberState(),
  menuType: new MenuType(),
  holidayType: new HolidayType(),
  stringDayOfWeek: new StringDayOfWeek(),
  charDayOfWeek: new CharDayOfWeek(),
  integerDayOfWeek: new IntegerDayOfWeek(),
  pagingSizeList: new PagingSizeList(),
};
