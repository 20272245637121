<template>
  <div class="container" id="personalContainer">
    <div class="page-sub-box locker_setting_list sub_new_style01 sub_ui_box1">
      <Search
        codeKey="search_type_user"
        :searchObj="searchType.prosData"
        @change-search-type="$_changeSearchType"
        @onSearchClick="$_searchData($event, 'search_type_user')"
      >
        <template v-slot:before>
          <div id="searchDept">
            <span class="title">부서 선택</span>
            <DxButton
              ref="filterBtn"
              icon="filter"
              :height="30"
              :diabled="false"
              class="white light_filled"
              @click="onOpenedDeptFilter"
            />
          </div>
          <DxSelectBox
            placeholder="직위 선택"
            :items="config.gradeList"
            display-expr="gradeNm"
            value-expr="id"
            v-model="searchType.customTypes.gradeId"
            :styling-mode="config.stylingMode"
            :width="140"
            :height="30"
            @value-changed="$_changeSearchCustomType('gradeId', $event)"
          />
          <DxSelectBox
            placeholder="계정상태 선택"
            :items="getUserStateCdSearchList"
            display-expr="codeNm"
            value-expr="id"
            v-model="searchType.customTypes.userStateCd"
            :styling-mode="config.stylingMode"
            :width="140"
            :height="30"
            @value-changed="$_changeSearchCustomType('userStateCd', $event)"
          />
          <DxSelectBox
            placeholder="View노출 선택"
            :items="getViewCdList"
            display-expr="label"
            value-expr="value"
            v-model="searchType.customTypes.viewCd"
            :styling-mode="config.stylingMode"
            :width="140"
            :height="30"
            @value-changed="$_changeSearchCustomType('viewCd', $event)"
          />
        </template>
      </Search>

      <div class="cusmain-table-wrap">
        <esp-dx-data-grid :data-grid="dataGrid" ref="personalGrid"></esp-dx-data-grid>
      </div>
    </div>

    <DxPopup
      :show-title="true"
      :title="modal.initData ? modal.initData.title : null"
      :min-width="modal.initData ? modal.initData.width : null"
      :width="modal.initData ? modal.initData.width : null"
      :min-height="modal.initData ? modal.initData.height : null"
      :height="modal.initData ? modal.initData.height : null"
      :drag-enabled="true"
      :resize-enabled="true"
      :show-close-button="true"
      :hide-on-outside-click="false"
      :visible="modal.isOpened"
      @hiding="isOpenModal(false)"
    >
      <template #content>
        <div>
          <component
            :is="modal.currentComponent"
            :contentData="modal.contentData"
            v-model="modal.contentData"
          ></component>
        </div>
      </template>

      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="
          modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('save')
              ? modal.initData.buttons.hasOwnProperty('save')
              : !modal.initData.buttons.hasOwnProperty('save')
            : false
        "
        :options="{
          elementAttr: {
            class: 'default filled txt_S medium',
          },
          text: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('save')
              ? modal.initData.buttons.save.text
              : ''
            : '',
          //type: 'default',
          width: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('save')
              ? modal.initData.buttons.save.width
              : '120'
            : '120',
          height: '40',
          useSubmitBehavior: true,
          onClick: e => {
            onConfirmModal(e);
          },
        }"
      />
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="
          modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('cancel')
              ? modal.initData.buttons.hasOwnProperty('cancel')
              : !modal.initData.buttons.hasOwnProperty('cancel')
            : false
        "
        :options="{
          elementAttr: {
            class: 'white filled txt_S medium',
          },
          text: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('cancel')
              ? modal.initData.buttons.cancel.text
              : ''
            : '',
          width: '120',
          height: '40',
          onClick: () => {
            isOpenModal(false);
          },
        }"
      />
    </DxPopup>
  </div>
</template>

<script>
  import Search from '@/components/common/search.vue';

  import ModalAddUser from '@/pages/euc/organize/personal/modal-add-user.vue';
  import ModalProgressUser from '@/pages/euc/organize/personal/modal-progress-user.vue';
  import ModalAddMember from '@/pages/euc/organize/personal/modal-add-member.vue';
  import ModalChangeState from '@/pages/euc/organize/personal/modal-change-state.vue';
  import ModalFilterDept from '@/pages/euc/organize/personal/modal-filter-dept.vue';

  import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';

  import { DxTextBox } from 'devextreme-vue/text-box';
  import { DxButton } from 'devextreme-vue/button';
  import { DxDropDownButton } from 'devextreme-vue/drop-down-button';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { isSuccess, deleteObjectKey } from '@/plugins/common-lib';
  import commonEnum from '@/configs/enums/common';
  import CustomStore from "devextreme/data/custom_store";
  import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid.vue";

  export default {
    components: {
      EspDxDataGrid,
      Search,
      ModalAddUser,
      ModalProgressUser,
      ModalAddMember,
      ModalChangeState,
      ModalFilterDept,
      DxPopup,
      DxToolbarItem,
      DxTextBox,
      DxButton,
      DxDropDownButton,
      DxSelectBox,
    },
    props: {},
    watch: {},
    data() {
      return {
        config: {
          pageSetting: {
            config: {},
            formData: {},
            contentsData: {},
          },
          gradeList: null,
          isKeepDeptFilter: false,
          memberLicenseCnt: 0, //계정 라이센스 수
          bulkwork: {
            syncUser: {
              appUrl: '/v2/ecs/insa/sync',
            },
            downloadExcel: {
              appUrl: '/v2/ecs/insa/download',
            },
          },
        },
        modal: {
          isOpened: false,
          currentComponent: null,
          initData: {},
          contentData: null,
        },
        searchType: {
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            deptId: null,
            gradeId: null,
            appointCd: null,
            userStateCd: null,
            viewCd: null,
          },
          paramsData: null,
          prosData: {
            searchTexts: {
              width: 230,
            },
          },
        },
        dataGrid: {
          refName: 'personalGridRefName',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          height: '650px',
          apiActionNm: {
            selectUser: 'USER_SELECT',
            selectGrade: 'GRADE_LIST_ALL',
            insertUserBulkwork: 'USER_BULKWORK_LIST_INSERT',
            updateUserViewCd: 'USER_VIEWCD_UPDATE',
          },
          showActionButtons: {
            select: false,
            copy: false,
            delete: false,
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: this.$_lang('UC.WORD.SYNC', { defaultValue: '동기화' }),
                  elementAttr: { class: 'btn_XS default filled' },
                  width: 60,
                  height: 30,
                  onClick: () => {
                    this.onOrganizeSync();
                  },
                },
                location: 'before',
              },
            ],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [5, 10, 15, 20], //페이지 사이즈 선택 박스
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowDeleting: false,
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '사번',
              dataField: 'userNo',
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              allowExporing: true,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
              cellTemplate: (container, options) => {
                const aTag = document.createElement('a');
                aTag.innerText = options.value;
                aTag.addEventListener('click', () => {
                  this.onUpdateData(options.data);
                });
                container.append(aTag);
              },
            },
            {
              caption: '이름',
              dataField: 'userNm',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              allowExporing: true,
            },
            {
              caption: '직위',
              dataField: 'gradeNm',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              allowExporing: true,
            },
            {
              dataField: 'dept1Nm',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              allowExporing: true,
              headerCellTemplate: container => {
                const div = document.createElement('div');
                div.innerHTML = `<b>${this.deptTitleByDepth('depth1')}</b>`;
                container.append(div);
              },
              cellTemplate: (container, options) => {
                if (options.data?.nameTree) {
                  const dept = options.data.nameTree.split('|');
                  container.append(dept[0] || '');
                }
              },
            },
            {
              dataField: 'dept2Nm',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              allowExporing: true,
              headerCellTemplate: container => {
                const div = document.createElement('div');
                div.innerHTML = `<b>${this.deptTitleByDepth('depth2')}</b>`;
                container.append(div);
              },
              cellTemplate: (container, options) => {
                if (options.data?.nameTree) {
                  const dept = options.data.nameTree.split('|');
                  container.append(dept[1] || '');
                }
              },
            },
            {
              dataField: 'dept3Nm',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              allowExporing: true,
              headerCellTemplate: container => {
                const div = document.createElement('div');
                div.innerHTML = `<b>${this.deptTitleByDepth('depth3')}</b>`;
                container.append(div);
              },
              cellTemplate: (container, options) => {
                if (options.data?.nameTree) {
                  const dept = options.data.nameTree.split('|');
                  container.append(dept[2] || '');
                }
              },
            },
            {
              caption: '내선번호',
              dataField: 'dn',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              allowExporing: true,
            },
            {
              caption: '직통번호',
              i18n: 'UC.WORD.HOT_LINE',
              dataField: 'hotLine',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              allowExporing: true,
            },
            {
              caption: '녹취 권한',
              dataField: 'recFl',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              allowExporing: true,
              lookup: {
                dataSource: this.$_enums.common.stringUsedFlag.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
            {
              caption: '계정 상태',
              dataField: 'userStateCd',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              allowExporing: true,
              lookup: {
                dataSource: this.$_getCode('user_state'),
                displayExpr: 'codeNm',
                valueExpr: 'codeId',
              },
            },
            {
              caption: '사용여부',
              dataField: 'viewCd',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              allowExporing: true,
              lookup: {
                dataSource: commonEnum.integerUsedFlag.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
          ],
        },
      };
    },

    computed: {
      /** @description: 시스템 설정의 유저 라이센스 개수 */
      getSystemMemberLicenseCnt() {
        return Number(this.$_getSystemData('member_license_cnt').configValue);
      },
      /** @description: 계정상태 선택 */
      getUserStateCdSearchList() {
        return [
          {
            codeId: null,
            codeNm: `${this.$_lang('COMPONENTS.USER_STATE', { defaultValue: '계정 상태' })} ${this.$_lang(
              'COMPONENTS.ALL',
              { defaultValue: '전체' },
            )}`,
          },
          ...this.$_getCode('user_state'),
        ];
      },
      /** @description: View 노출 선택 */
      getViewCdList() {
        return [
          {
            value: null,
            label: `${this.$_lang('COMPONENTS.USE_STATUS', { defaultValue: '사용여부' })} ${this.$_lang(
              'COMPONENTS.ALL',
              { defaultValue: '전체' },
            )}`,
          },
          ...commonEnum.integerUsedFlag.values,
        ];
      },
      /** @description: 부서 타이틀 가져오기 */
      deptTitles() {
        return this.$_getCode('euc_dept_title').filter(d => d.delFl === 'N');
      },
    },
    methods: {
      async onOrganizeSync() {
        const payload = {
          actionName: 'EUC_ORGANIZE_PERSONAL_SYNC',
          data: {},
          loading: true,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          this.$_Toast(
            this.$_lang('COMMON.MESSAGE.CMN_SUCCESS', { defaultValue: '정상적으로 처리되었습니다.' }),
            { icon: 'success' },
          );
        } else {
          this.$_Toast(
            this.$_lang('COMMON.MESSAGE.CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }),
            { icon: 'error' },
          );
        }
      },
      /** @description: 팝업 오픈시 메서드 */
      onOpenModal(componentNm, componentInitData, data) {
        this.modal.currentComponent = componentNm; //set dynamic component name in modal body slot
        this.modal.initData = componentInitData; //set init modal templet
        this.modal.contentData = data;

        this.isOpenModal(true);
      },
      /** @description: 팝업 오픈 체크 메서드 */
      isOpenModal(data) {
        this.modal.isOpened = data;
        if (!data) {
          this.modal.currentComponent = null;
          this.modal.initData = {};
        }
      },
      /** @description: 팝업 확인 버튼 이벤트 */
      async onConfirmModal(e) {
        //해당 모달 컴포넌트에서 데이터 저장
        const promise = new Promise((resolve, reject) => {
          this.$_eventbus.$emit(`${this.modal.currentComponent}:onSaveData`, e, resolve, reject);
        });

        promise
          .then(res => {
            if (res.status === 200) {
              this.onProcessModalComponent(res?.componentNm, res.rst);
            } else {
              this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
            }
          })
          .catch(() => {
            // handle error
            this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          });
      },
      onProcessModalComponent(componentNm, data) {
        switch (componentNm) {
          case 'ModalAddUser':
            this.handleModalAddUser();
            break;
          case 'ModalFilterDept':
            this.handleModalFilterDept(data);
            break;
          default:
            this.refreshData();
        }
      },
      // ModalAddUser 처리 로직
      handleModalAddUser() {
        this.isOpenModal(false);
        this.onOpenModal(
          'ModalProgressUser',
          {
            title: '일괄 등록 진행 중',
            buttons: {
              save: { text: '동기화 이력 이동', width: 150 },
              cancel: { text: '닫기' },
            },
            width: 600,
            height: 220,
          },
          { apiActionNm: this.config.pageSetting.config.apiActionNm },
        );
      },
      // ModalFilterDept 처리 로직
      handleModalFilterDept({ isAllDept, isSaved, selectedDeptDatas }) {
        this.clearDeptParams();
        if (!isAllDept) {
          this.setDeptParams(isSaved, selectedDeptDatas);
        }
        this.checkKeepDeptFilter();
        this.selectDataList();
        this.isOpenModal(false);
      },
      // 기존 부서 검색 파라미터 초기화
      clearDeptParams() {
        if (this.searchType?.paramsData) {
          Object.keys(this.searchType.paramsData).forEach(key => {
            console.log('key : ', key);
            if (key.includes(`||dept`)) {
              delete this.searchType.paramsData[key];
            }
          });
        }
      },
      // 부서 검색 파라미터 설정
      setDeptParams(isSaved, selectedDeptDatas) {
        this.searchType.paramsData = { ...selectedDeptDatas, ...this.searchType.paramsData };
        const currentPath = this.$router.currentRoute.path;
        const listParams = this.updateListParams(currentPath, isSaved, selectedDeptDatas);
        this.$store.commit('setListParams', listParams);
      },
      /**
       * @description 부서 검색 설정 저장 여부 확인
       *
       * @param {string} currentPath - 현재 라우터 경로
       * @param {boolean} isSaved - 부서 검색 설정 저장 여부
       * @param {Object} deptFilterDatas - 부서 필터 데이터
       * @returns {Object} 업데이트된 검색 파라미터
       */
      updateListParams(currentPath, isSaved, deptFilterDatas) {
        let listParams = { ...this.$store.getters.getListParams };
        if (isSaved) {
          listParams[currentPath] = { isSavedDeptFilter: isSaved, deptFilterDatas };
        } else {
          let currentData = listParams[currentPath] || {};
          currentData = deleteObjectKey(currentData, 'isSavedDeptFilter');
          currentData = deleteObjectKey(currentData, 'deptFilterDatas');
          listParams[currentPath] = currentData;
        }
        console.log('listParams : ', listParams);
        return listParams;
      },

      /** @description: 부서 선택 필터 팝업 오픈 */
      async onOpenedDeptFilter() {
        //커스텀 검색 재설정
        const customTypes = this.searchType.paramsData;
        let deptIdDepthDatas = [];
        if (customTypes) {
          //커스텀 검색 이력 불러오기
          this.$_getSearchHistsCustomType(customTypes);

          Object.entries(customTypes).forEach(([key, value]) => {
            if (key === 'deptId') {
              deptIdDepthDatas.push(value);
            }
          });
        }

        //설정계속저장 관련
        const currentPath = this.$router.currentRoute.path;
        const listParams = this.$store.getters.getListParams?.[currentPath];
        const isSavedDeptFilter = listParams?.isSavedDeptFilter || false;

        this.onOpenModal(
          'ModalFilterDept',
          {
            title: '부서 검색 조건',
            buttons: {
              save: { text: '확인' },
              cancel: { text: '취소' },
            },
            width: 800,
            height: 450,
          },
          {
            isSaved: isSavedDeptFilter, //설정계속유지
            deptIdDepthDatas: deptIdDepthDatas, //검색한 부서선택 데이터를 유지하기 위해 여기서 params 데이터를 보냄
          },
        );
      },
      /** @description: 부서필터 검색 이력 가져오기 */
      getDeptFilterHists() {
        const currentPath = this.$router.currentRoute.path;
        const listParams = this.$store.getters.getListParams?.[currentPath];
        if (listParams?.isSavedDeptFilter) {
          this.searchType.paramsData = { ...this.searchType.paramsData, ...listParams.deptFilterDatas };
        }
      },
      /** @description: 부서필터 검색 설정계속유지인지 체크 및 색상 표시 */
      checkKeepDeptFilter() {
        //부서필터 조건 체크
        this.config.isKeepDeptFilter = false;
        if (this.searchType.paramsData) {
          Object.keys(this.searchType.paramsData).forEach(key => {
            if (key.includes('deptId')) {
              //부서필터 조건이 있으면
              this.config.isKeepDeptFilter = true;
            }
          });
        }

        this.setColorDeptFilter(); //설정계속유지시 필터 버튼 색상 표시
      },
      /** @description: 부서필터 검색 설정계속유지에 따라 필터 버튼 색성 표시 */
      setColorDeptFilter() {
        const filterBtn = this.$refs.filterBtn.$el;
        const filterIcon = filterBtn.querySelector('.dx-icon-filter');

        if (this.config.isKeepDeptFilter) {
          filterBtn.classList.add('light-default', 'filled');
          filterBtn.classList.remove('white', 'light_filled');
          filterIcon.classList.add('check-color');
          filterIcon.classList.remove('default');
        } else {
          filterBtn.classList.add('white', 'light_filled');
          filterBtn.classList.remove('light-default', 'filled');
          filterIcon.classList.add('default');
          filterIcon.classList.remove('check-color');
        }
      },
      /** @description : 등록 페이지 이동 */
      onAddData() {
        const params = { updateYn: false };
        this.$store.commit('setDetailParams', params);
        this.$router.push('/organize/personal/config');
      },
      /** @description: 인사정보 일괄 등록 팝업 이벤트 */
      onAddUser() {
        this.onOpenModal(
          'ModalAddUser',
          {
            title: '인사 관리 일괄 등록',
            buttons: {
              save: { text: '일괄 등록 실행' },
              cancel: { text: '닫기' },
            },
            width: 830,
            height: 660,
          },
          {
            apiActionNm: this.config.pageSetting.config.apiActionNm,
          },
        );
      },
      /** @description: 인사동기화 이벤트 */
      async onSyncUser() {
        if (await this.$_Confirm('인사동기화 실행 시, 작업을 취소할 수 없습니다.<br/>계속 하시겠습니까?')) {
          const data = {
            data: {
              bulkworkType: 'sync', //API에서 분기 처리
              bulkwork: [this.config.bulkwork.syncUser],
            },
          };

          const payload = {
            actionName: 'USER_BULKWORK_LIST_INSERT',
            data: data,
            loading: false,
          };

          const res = await this.CALL_API(payload);
          if (isSuccess(res)) {
            this.onOpenModal('ModalProgressUser', {
              title: '인사동기화 진행 중',
              buttons: {
                save: { text: '동기화 이력 이동', width: 150 },
                cancel: { text: '닫기' },
              },
              width: 600,
              height: 220,
            });
          }
        }
      },
      /** @description: 계정 생성 팝업 이벤트 */
      async onAddMember() {
        const selectedRowsData = this.$refs.personalGrid.selectedRowsData;

        if (!selectedRowsData?.length) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return false;
        }

        if (selectedRowsData.length > this.getSystemMemberLicenseCnt - this.config.memberLicenseCnt) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.OVER_LICENSE_COUNT'));
          return false;
        }

        const isMemberDatas = selectedRowsData.some(d => d.memberId);
        if (isMemberDatas) {
          this.$_Msg(this.$_lang('EXIST_ACCOUNT'));
          return false;
        }

        this.onOpenModal(
          'ModalAddMember',
          {
            title: '일괄 계정 생성',
            buttons: {
              save: { text: '계정 등록' },
              cancel: { text: '닫기' },
            },
            width: 850,
            height: 650,
          },
          {
            selectedRowsData,
            apiActionNm: this.config.pageSetting.config.apiActionNm,
          },
        );
      },
      /** @description: 재직상태 변경 팝업 이벤트 */
      onUpdateStatus() {
        const selectedRowsData = this.$refs.personalGrid.selectedRowsData;

        if (!selectedRowsData?.length) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return false;
        }

        this.onOpenModal(
          'ModalChangeState',
          {
            title: ' 재직 상태 변경',
            buttons: {
              save: { text: '확인' },
              cancel: { text: '닫기' },
            },
            width: 500,
            height: 350,
          },
          {
            selectedRowsData,
            apiActionNm: this.config.pageSetting.config.apiActionNm,
          },
        );
      },
      /** @description : 수정 페이지 이동 */
      onUpdateData(data) {
        const params = { id: data.id, memberId: data.memberId, updateYn: true };
        this.$store.commit('setDetailParams', params);
        this.$router.push({ path: '/organize/personal/config' });
      },
      /** @description: 인사동기화 엑셀 다운로드 */
      async onDownloadExcel() {
        if (await this.$_Confirm('엑셀다운로드 실행 시, 작업을 취소할 수 없습니다.<br/>계속 하시겠습니까?')) {
          const data = {
            data: {
              bulkworkType: 'download', //API에서 분기 처리
              bulkwork: [this.config.bulkwork.downloadExcel],
            },
          };

          const payload = {
            actionName: 'USER_BULKWORK_LIST_INSERT',
            data: data,
            loading: true,
          };

          const res = await this.CALL_API(payload);
          if (isSuccess(res)) {
            this.onOpenModal('ModalProgressUser', {
              title: '엑셀다운로드 진행 중',
              buttons: {
                save: { text: '동기화 이력 이동', width: 150 },
                cancel: { text: '닫기' },
              },
              width: 600,
              height: 220,
            });
          }
        }
      },
      /** @description: 뎁스별 부서 타이틀 */
      deptTitleByDepth(depth) {
        return this.deptTitles.find(d => d.codeValue === depth).codeNm;
      },
      /** @description: 직위 데이터 */
      async selectGradeDatas(sort = '+gradeOrd') {
        const params = { viewCd: 1 };

        if (!params.sort) {
          params.sort = sort;
        }

        const payload = {
          actionName: 'GRADE_LIST_SELECT',
          data: { params },
          loading: false,
          useErrorPopup: true,
        };

        const res = await this.CALL_API(payload);
        if (isSuccess(res)) {
          this.config.gradeList = [
            {
              id: null,
              gradeNm: `${this.$_lang('COMPONENTS.GRADE', { defaultValue: '직위' })} ${this.$_lang(
                'COMPONENTS.ALL',
                { defaultValue: '전체' },
              )}`,
            },
            ...res.data.data,
          ];
        }
      },
      /** @description : 데이터 조회 메서드 */
      async selectDataList(sort = '+dn') {
        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);

            if (!params.sort) {
              params.sort = sort;
            }

            params = { ...this.searchType.paramsData, ...params };

            const payload = {
              // actionName: 'USER_LIST_ALL_VIEW',
              actionName: 'USER_LIST_DUPLICATE_VIEW',
              data: { params },
              loading: false,
            };

            const res = await this.CALL_API(payload);
            if (isSuccess(res)) {
              const data = await this.makeDataList(res.data.data);

              const rtnData = {
                data: data,
                totalCount: res.data.header.totalCount,
              };

              return rtnData;
            }
          },
        });
      },
      /** @description : 부서 트리구조 데이터 가공 */
      makeDataList(data) {
        data.forEach(d => {
          let deptArr = d.nameTree?.split('|');
          deptArr?.forEach((dept, idx) => {
            d[`dept${idx + 1}`] = dept;
          });
        });
        return data;
      },
      /** @description : View 노출 변경 메서드 */
      async onUpdateViewCd(isView) {
        if (this.$refs.personalGrid.selectedRowsData.length === 0) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return false;
        }

        let selectedUserIds = this.$refs.personalGrid.selectedRowsData.map(d => {
          return { id: d.id };
        });

        const msg = isView ? '노출' : '';
        const viewCd = isView ? this.$_getUsedCode.id : this.$_getUnUsedCode.id;

        if (await this.$_Confirm(`인사 정보를 ${msg}로 변경하시겠습니까?`)) {
          let apiList = [];

          //인사정보 삭제
          const payload = {
            actionName: 'USER_VIEWCD_UPDATE',
            data: {
              data: selectedUserIds,
              viewCd: viewCd,
            },
            loading: false,
          };
          apiList.push(await this.CALL_API(payload));

          let resList = await Promise.all(apiList);
          //console.log('resList >>> ',resList);
          resList.forEach((res, index) => {
            if (index === resList.length - 1) {
              if (isSuccess(res)) {
                this.$_Toast(this.$_lang('CMN_SUC_UPDATE'));
                this.refreshGridList();
              }
            }
          });
        } else {
          return false;
        }
      },
      /** @description: View 노출 상태 변경 이벤트 */
      async onChangedViewCd(rowData) {
        let data = [rowData];

        let viewCd;
        if (rowData.viewCd === this.$_getUsedCode.id) {
          //사용이면
          viewCd = this.$_getUnUsedCode.id; //미사용으로 스위치
        } else {
          //미사용이면
          viewCd = this.$_getUsedCode.id; //사용으로 스위치
        }

        const payload = {
          actionName: 'USER_VIEWCD_UPDATE',
          data: {
            data: data,
            viewCd: viewCd,
          },
          loading: true,
        };
        const res = await this.CALL_API(payload);
        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('CMN_SUC_UPDATE'));
          this.refreshGridList();
        }
      },
      /** @description: grid refresh 이벤트 */
      refreshGridList() {
        this.$refs.personalGrid.refreshData();
        this.$refs.personalGrid.getInstance.deselectAll(); //선택 해제
      },
      /** @description : 데이터 없을 경우 출력 메서드 */
      noDataText(length) {
        if (!length) {
          if (this.isSearchClick) {
            return '검색 결과가 없습니다';
          } else {
            return '추가된 데이터가 없습니다.';
          }
        }
      },
      /** @description: 고객유형 출력 */
      customerTypeCd(rowData) {
        let customerType = '';
        if (rowData.customerTypeCode) {
          customerType = rowData.customerTypeCode.codeNm;
        }
        return customerType;
      },
      /** @description: 내선번호 출력 */
      ext(rowData) {
        return rowData?.ext || '-';
      },
      /** @description: 직위 출력 */
      gradeNm(rowData) {
        return rowData?.gradeNm || '-';
      },
      /** @description: 통계 적용 출력 */
      stastFl(rowData) {
        return rowData?.stastFl === 'Y' ? '대상' : '비대상';
      },
      /** @description: 인포푸시 사용여부 출력 */
      infopushFl(rowData) {
        return rowData?.infopushFl === 'Y' ? '사용' : '미사용';
      },
      /** @description: 직책 출력 */
      position(rowData) {
        return rowData?.position || '-';
      },
      /** @description: View 노출 출력 */
      viewCd(rowData) {
        return rowData.viewCd === this.$_getUsedCode.id;
      },
      /** @description: refresh Data */
      refreshData() {
        this.refreshGridList();
        this.isOpenModal(false); //팝업 close
      },
      /** @description: 그리드 상단 커스텀 버튼 설정(pageData에서 onClick 이벤트의 vm을 찾지 못하여 여기서 설정)  */
      setGridCustomButtons() {
        let customButtons = [
          {
            widget: 'dxButton',
            options: {
              icon: '',
              text: '등록',
              elementAttr: { class: 'btn_XS default filled add1' },
              width: 60,
              height: 30,
              onClick: () => {
                this.onAddData();
              },
            },
            location: 'before',
          },
          {
            widget: 'dxButton',
            options: {
              icon: '',
              text: '계정 생성',
              elementAttr: { class: 'btn_XS white light_filled' },
              height: 30,
              onClick: () => {
                this.onAddMember();
              },
            },
            location: 'before',
          },
          {
            widget: 'dxButton',
            visible: false,
            options: {
              icon: '',
              text: '재직상태 변경',
              elementAttr: { class: 'btn_XS white light_filled' },
              height: 30,
              onClick: () => {
                this.onUpdateStatus();
              },
            },
            location: 'before',
          },
          {
            widget: 'dxDropDownButton',
            options: {
              items: [
                {
                  id: 1,
                  title: 'View 일괄 노출',
                },
                {
                  id: 2,
                  title: 'View 일괄 비노출',
                },
              ],
              keyExpr: 'id',
              displayExpr: 'title',
              icon: '',
              text: 'View노출 변경',
              elementAttr: { class: 'btn_XS white light_filled' },
              height: 30,
              onSelectionChanged: e => {
                const isView = e.item.id === 1 ? true : false;
                this.onUpdateViewCd(isView);
              },
            },
            location: 'before',
          },
          {
            widget: 'dxButton',
            options: {
              icon: '',
              text: '일괄 등록',
              elementAttr: { class: 'btn_XS white light_filled' },
              height: 30,
              onClick: () => {
                this.onAddUser();
              },
            },
            location: 'before',
          },
          {
            widget: 'dxButton',
            options: {
              icon: '',
              text: '인사동기화',
              elementAttr: { class: 'btn_XS white light_filled' },
              height: 30,
              onClick: () => {
                this.onSyncUser();
              },
            },
            location: 'before',
          },
          {
            widget: 'dxButton',
            options: {
              icon: '',
              text: '엑셀',
              elementAttr: { class: 'btn_XS white light_filled excel' },
              height: 30,
              onClick: () => {
                this.onDownloadExcel();
              },
            },
            location: 'before',
          },
        ];

        this.dataGrid.showActionButtons.customButtons = customButtons;
      },
      /** @description : 라이프사이클 mounted시 호출되는 메서드 */
      async mountedData() {
        //유저 라이센스 개수 가져오기
        this.config.memberLicenseCnt = await this.$_getMemberLicenseCnt();
        this.selectDataList(); //grid 리스트 데이터

        //부서선택 검색 이력 불러오기
        this.getDeptFilterHists();

        //커스텀 검색 이력 불러오기
        this.$_getSearchHistsCustomType(this.searchType.customTypes);

        //부서필터 검색조건 계속설정유지 인지 체크
        this.checkKeepDeptFilter();

        this.selectGradeDatas(); //직위 데이터
      },
    },
    created() {},
    mounted() {
      this.mountedData();
    },
  };
</script>

<style scoped>
  #searchDept .title {
    display: inline-block;
    margin-right: 10px;
  }
  #searchDept .dx-button.dx-button-has-icon:not(.dx-button-has-text):not(.dx-shape-standard) {
    border-radius: 4px;
  }
  #personalContainer .empty-line {
    display: block;
    margin-right: 0;
    margin-bottom: 5px;
  }
  .sub_new_style01 .page_search_box .inner div {
    display: inline-block;
  }
  .sub_new_style01 .page_search_box .inner > div {
    vertical-align: middle;
    margin-right: 10px;
  }
</style>
<style>
  #searchDept .dx-button .dx-icon-filter.default {
    color: rgba(0, 0, 0, 0.87);
  }
  #searchDept .dx-button .dx-icon-filter.check-color {
    color: #fff;
  }
</style>
