export default {
  MESSAGE: {
    CHECK_30_DAYS: '최대 30일 조회만 가능합니다.',
    CHECK_12_MONTHS: '최대 12개월 조회만 가능합니다.',
    PLEASE_SELECT_SKILL: '스킬별 조회시 스킬을 선택하여 주세요.',
    FORECAST_EVENT_INFO_MESSAGE1: '예측값과 실제값 사이에 큰 차이가 발생하는 경우에는 이벤트로 간주됩니다.',
    FORECAST_EVENT_INFO_MESSAGE2: '사용자가 미리 이벤트로 판단되는 일자를 추가할 수도 있습니다.',
    FORECAST_EVENT_INFO_MESSAGE3: '이벤트의 원인을 분석하고 유형별로 관리하면, 콜 예측의 정확성을 더욱 높일 수 있습니다.',
    FORECAST_MODEL_INFO_MESSAGE1:
      '일일 예측이 5회 연속으로, 월 예측이 3회 연속으로 실제 값과 현저한 차이를 보일 경우 예측 모델이 자동으로 변경됩니다.',
    FORECAST_MODEL_INFO_MESSAGE2: '인입 이력 차트에서는 연속 실패 시점을 포함해 지난 2주간의 유입 현황을 확인할 수 있습니다.',
    REQUIRED_EVENT_TYPE: '이벤트 등록 시 이벤트 유형은 필수 입니다.',
    PLEASE_SELECT_EVENT_TYPE: '이벤트 유형을 선택하여 주세요.',
  },
  WORD: {
    FORECASTED_OFFER: '예측 콜',
    ACTUAL_OFFER: '실제 콜',
    FULL_TIME_EQUIVALENT: '예측 근무 시간',
    EVENT_TYPE: '이벤트 유형',
    SELECT_EVENT_TYPE: '이벤트 유형 선택',
    EVENT_DATE: '이벤트 날짜',
    ADDED_DATE: '추가 일자',
    DAILY: '일별',
    MONTHLY: '월별',
    BY_SKILL: '스킬별',
    SKILL_GROUP: '스킬 그룹',
    SEARCHING_FORECAST: '예측 조회',
    FORECAST_CYCLE: '예측 주기',
    REPLACEMENT_DATE: '교체 날짜',
    FORECAST_FAIL_PERIOD: '예측 실패 기간',
    AVERAGE_ACCURACY: '평균 정확도',
    MODEL: '모델',
    HYPERPARAMETERS: '하이퍼 파라미터',
    OFFER_HISTORY: '인입 이력',
    FORECAST_FAIL_OFFER_HISTORY: '예측 실패 인입 이력',
    EXCEL: '엑셀',
    ALL_EVENTS_HISTORY: '이벤트 전체 이력',
  },
};
