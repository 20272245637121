<!--
  PACKAGE_NAME : src\pages\esp\user\management\modal-active-member.vue
  FILE_NAME : modal-active-member
  AUTHOR : devyoon91
  DATE : 2024-08-29
  DESCRIPTION : 계정 활성화 모달
-->
<template>
  <transition>
    <div ref="contentsWrap" class="contents-wrap" id="contentsWrap">
      <div class="contents-box">
        <table class="table_form line-bin">
          <caption>
            <strong>계정 활성화</strong>
          </caption>
          <colgroup>
            <col style="width: 160px" />
            <col style="width: auto" />
          </colgroup>

          <thead class="sub_title_txt"/>

          <tbody>
            <tr>
              <th scope="row">
                <label for="label01">처리 구분</label>
              </th>
              <td>
                <dx-radio-group
                  class="check-type col"
                  :items="config.processType.items"
                  :value="getProcessType"
                  :layout="config.processType.layout"
                  @value-changed="handleRadioButton"
                />
              </td>
            </tr>
            <tr v-if="getProcessTypeId === 0">
              <th scope="row">
                <label for="label01">
                  패스워드 변경 유형
                  <span class="icon_require">필수항목</span>
                </label>
              </th>
              <td>
                <dx-select-box
                  v-model="formData.passwordType"
                  placeholder="패스워드 변경 유형"
                  :items="config.passwordTypeList"
                  display-expr="title"
                  value-expr="value"
                  @value-changed="handlePasswordType"
                  :styling-mode="config.stylingMode"
                  width="90%"
                  :height="30"
                >
                  <dx-validator>
                    <dx-required-rule message="패스워드 변경 유형은 필수값입니다." />
                  </dx-validator>
                </dx-select-box>
                <button
                  class="btn-question"
                  id="tooltips1"
                  @mouseenter="setTooltips('changePwdType')"
                  @mouseleave="setTooltips('changePwdType')"
                ></button>
                <dx-popover
                  target="#tooltips1"
                  :visible="config.isTooltip.changePwdType"
                  :hide-on-outside-click="false"
                >
                  <span>
                    ‘새 패스워드’는 사용자가 수정된 패스워드를 계속 사용하도록 허용합니다.<br />
                    ‘1회용 임시 패스워드’는 사용자의 로그인 직후 패스워드 변경이 반드시 필요합니다.
                  </span>
                </dx-popover>
              </td>
            </tr>
            <tr v-if="getProcessTypeId === 0">
              <th scope="row">
                <label for="label01">
                  패스워드
                  <span class="icon_require">필수항목</span>
                </label>
              </th>
              <td>
                <dx-text-box
                  v-model="formData.loginPwd"
                  placeholder="패스워드"
                  mode="password"
                  :disabled="formData.isDisabledPwd"
                  :show-clear-button="true"
                  :max-length="limitNumberTexts.maxLengths.loginPwd"
                  :styling-mode="config.stylingMode"
                  width="90%"
                  :height="30"
                  class="mar_ri10"
                  style="vertical-align: middle"
                >
                  <dx-validator>
                    <dx-required-rule message="패스워드는 필수값입니다." />
                  </dx-validator>
                </dx-text-box>
              </td>
            </tr>
            <tr v-if="getProcessTypeId === 0">
              <th scope="row">
                <label for="label01">
                  패스워드 확인
                  <span class="icon_require">필수항목</span>
                </label>
              </th>
              <td>
                <dx-text-box
                  v-model="formData.loginPwdConfirm"
                  placeholder="패스워드"
                  mode="password"
                  :disabled="formData.isDisabledPwd"
                  :show-clear-button="true"
                  :max-length="limitNumberTexts.maxLengths.loginPwdConfirm"
                  :styling-mode="config.stylingMode"
                  width="90%"
                  :height="30"
                  class="mar_ri10"
                  style="vertical-align: middle"
                >
                  <dx-validator>
                    <dx-required-rule message="패스워드 확인은 필수값입니다." />
                    <dx-compare-rule :comparison-target="passwordComparison" message="패스워드값과 일치하지 않습니다." />
                  </dx-validator>
                </dx-text-box>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="container-bottom-box">
        <template v-if="getProcessTypeId === 0">
          선택한 <span class="length-txt">{{ contentData.selectedRowsData.length }}</span
          >개 계정의 패스워드를 변경하고, 계정을 활성화하시겠습니까?<br />
          (패스워드 변경 시, 패스워드 오류 회수는 초기화됩니다.)
        </template>
        <template v-else>
          선택한 <span class="length-txt">{{ contentData.selectedRowsData.length }}</span
          >개 계정의 패스워드는 유지하고 계정은 활성화하시겠습니까?
        </template>
      </div>
    </div>
  </transition>
</template>

<script>
  import { DxTextBox } from 'devextreme-vue/text-box';
  import { DxValidator, DxRequiredRule, DxCompareRule } from 'devextreme-vue/validator';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import DxRadioGroup from 'devextreme-vue/radio-group';
  import { DxPopover } from 'devextreme-vue/popover';
  import { isSuccess, encryptPassword } from '@/plugins/common-lib';

  export default {
    components: {
      DxTextBox,
      DxValidator,
      DxRequiredRule,
      DxCompareRule,
      DxSelectBox,
      DxRadioGroup,
      DxPopover,
    },
    props: {
      contentData: Object,
      iconData: Array,
    },
    watch: {},
    data() {
      return {
        config: {
          stylingMode: 'outlined', //[outlined, filled, underlined]
          processType: {
            layout: 'horizontal',
            items: [
              { id: 0, text: '패스워드 변경 + 계정 활성화' },
              { id: 1, text: '계정 활성화' },
            ],
            value: 0,
          },
          isTooltip: {
            //tooltip 관련
            changePwdType: false,
          },
          passwordTypeList: [
            //패스워드 변경 유형 여부
            {
              title: '새 패스워드',
              value: 'NEW',
            },
            {
              title: '1회용 임시 패스워드',
              value: 'TEMP',
            },
          ],
          passwordReg: '', //패스워드 정책 관련 config
          passwordRegMsg: '', //패스워드 정책 관련 description
        },
        formData: {
          loginPwd: '',
          loginPwdConfirm: '',
          changedPwdFl: true,
          isDisabledPwd: true,
          passwordType: null,
        },
        limitNumberTexts: {
          textLengths: {},
          maxLengths: {
            loginPwd: 50,
            loginPwdConfirm: 50,
          },
        },
      };
    },
    computed: {
      /** @description: 처리구분 선택된 데이터 */
      getProcessType() {
        return this.config.processType.items.find(d => d.id === this.config.processType.value);
      },
      /** @description: 처리구분 Radio value */
      getProcessTypeId() {
        return this.getProcessType.id;
      },
      /** @description : 시스템 설정의 default_pwd 가져오기 */
      getSystemDefaultPwd() {
        return this.$_getSystemData('default_pwd')?.configValue;
      },
    },
    methods: {
      /** @description: 처리구분 라디오 버튼 클릭 이벤트 */
      handleRadioButton(e) {
        this.$set(this.config.processType, 'value', e.value.id);
        if (this.getProcessTypeId === 0) {
          this.$set(this.formData, 'changedPwdFl', true);
        } else {
          this.formData.passwordType = null; // 패스워드 변경 유형 초기화
          this.$set(this.formData, 'changedPwdFl', false);
        }
      },
      /** @description: 패스워드 변경 유형 이벤트 */
      handlePasswordType(e) {
        // 새 패스워드
        if (e.value === 'NEW') {
          this.formData.changedPwdFl = true;
          this.formData.isDisabledPwd = false;
          this.formData.loginPwd = '';
          this.formData.loginPwdConfirm = '';
        } else if (e.value === 'TEMP') { // 1회용 임시 패스워드
          this.formData.changedPwdFl = true;
          this.formData.isDisabledPwd = true;
          this.formData.loginPwd = this.getSystemDefaultPwd;
          this.formData.loginPwdConfirm = this.getSystemDefaultPwd;
        }
      },
      /** @description: 패스워드 변경 유형 or 권한선택 툴팁 이벤트 */
      setTooltips(key) {
        this.config.isTooltip[key] = !this.config.isTooltip[key];
      },
      /** @description : 패스워드 일치하는지 체크하는 이벤트 */
      passwordComparison() {
        return this.formData.loginPwd;
      },
      /** @description : 라이프사이클 mounted시 호출되는 메서드 */
      mountedData() {
        this.$_eventbus.$on('ModalActiveMember:onSaveData', async (e, resolve) => {
          if (!e.validationGroup.validate().isValid) {
            return false;
          }

          let changedPwdFl = this.formData.changedPwdFl;

          //멤버 데이터 셋팅
          let members = this.contentData.selectedRowsData.map(d => {
            if (this.getProcessTypeId === 0 && changedPwdFl) {
              d.loginPwd = this.formData.loginPwd;
            }

            return { ...d };
          });

          //패스워드 validation 정규식 체크
          if (this.getProcessTypeId === 0) {
            for (let data of members) {
              const validatePasswordMessage = this.$_validatePasswordMessage(data.loginPwd, data.loginId, data.loginNm);
              if (validatePasswordMessage !== '') {
                return;
              }
            }
          }

          if (
            await this.$_Confirm(
              this.$_lang('COMMON.MESSAGE.ACTIVATE_SELECTED_ACCOUNT', { defaultValue: '선택한 계정을 활성화하시겠습니까?' }),
            )
          ) {
            const payload = {
              actionName: 'MEMBER_STATE_ACTIVATION',
              data: members.map(item => {
                let password = '';

                // 패스워드 암호화
                if (changedPwdFl) {
                  password = encryptPassword(item.loginId, item.loginPwd, this.$store.getters.getEncryptionType);
                }

                return {
                  memberId: item.id,
                  loginPwd: password,
                  passwordType: this.formData.passwordType,
                };
              }),
            };
            const res = await this.CALL_API(payload);
            if (isSuccess(res)) {
              this.$_Toast(
                this.$_lang('CHANGED_ACTIVE', { defaultValue: '선택한 계정을 활성 상태로 변경되었습니다.' }),
              );
              resolve(200);
            } else {
              this.$_Toast(res?.data?.header?.resMsg);
              resolve(500);
            }
          }
        });
      },
    },
    created() {},
    mounted() {
      this.mountedData();
    },
    destroyed() {
      this.$_eventbus.$off('ModalActiveMember:onSaveData');
    },
  };
</script>

<style scoped>
  .contents-title-box {
    height: 40px;
    position: relative;
    border-bottom: 1px solid #ccc;
    line-height: 40px;
  }

  .contents-title-box .contents-title {
    height: 40px;
    padding-left: 5px;
    display: inline-block;
    font-size: 0.9em;
  }

  .contents-box {
    width: 100%;
    background-color: #fff;
  }

  .contents-box .table_form td {
    padding: 10px 0;
  }

  .conts-row:not(:last-child) {
    margin-bottom: 20px;
  }

  .conts-row .conts-box {
    display: inline-block;
  }

  .conts-row .conts-box > div {
    display: inline-block;
  }

  .conts-row .conts-box .conts-title {
    width: 120px;
    display: inline-block;
  }

  .conts-row .conts-box .conts-input {
    display: -webkit-inline-box;
  }

  .container-bottom-box {
    margin-top: 20px;
    font-size: 0.9em;
    text-align: center;
  }

  .container-bottom-box .length-txt {
    color: tomato;
  }
  #modal_menu_config_menuicon .dx-texteditor-input {
    font-size: 15px;
    padding-top: 0px;
    padding-left: 2px;
  }
</style>
