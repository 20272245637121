<template>
  <div class="search-container">
    <form name="search" method="get" @submit.prevent>
      <div class="search-bar" ref="searchBar" :class="{ 'search-bar--active': isSearchBarOpen }">
        <!-- 검색 버튼 -->
        <button type="button" @click="handleSearchClick" class="search-button" />

        <!-- 검색어 입력 -->
        <input
          type="text"
          id="searchInput"
          autocomplete="off"
          :placeholder="$_lang('COMMON.WORD.MENU_SEARCH', { defaultValue: '메뉴검색' })"
          v-model="searchText"
          @focus="handleOpenSearchLayer"
          @input="handleSearchValueChanged"
          class="search-input"
        />

        <!-- 삭제 버튼 -->
        <button type="button" @click="handleClearSearchText" class="clear-button" v-show="handleShowDeleteButton" />
      </div>

      <!-- 자동완성 목록 -->
      <div v-show="isKeywordLayerOpen" class="autocomplete-layer" ref="autocompleteLayer">
        <div class="autocomplete-content">
          <ul class="autocomplete-list">
            <li
              v-for="(item, index) in searchedMenuList"
              :key="index"
              class="autocomplete-item"
              @click="goMenuUrl(item)"
              :title="item.menuPath"
            >
              {{ item.menuNm }}
            </li>
            <li v-if="searchedMenuList.length === 0" class="autocomplete-no-results">
              {{ $_lang('COMMON.MESSAGE.NOT_FOUND_SEARCH_DATA', { defaultValue: '검색 결과가 존재하지 않습니다.' }) }}
            </li>
          </ul>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        searchText: '', // 검색어
        searchedMenuList: [], // 자동완성 결과
        isKeywordLayerOpen: false, // 자동완성 레이어 활성화 여부
        isSearchBarOpen: false, // 검색 바 활성화 여부
      };
    },
    computed: {
      /**
       * @description 검색어 입력 필드에 삭제 버튼 표시 여부
       */
      handleShowDeleteButton() {
        return this.searchText.trim() !== '';
      },
    },
    methods: {
      /**
       * @description 검색어 입력 값 변경 이벤트
       */
      handleSearchValueChanged() {
        if (this.searchText.trim() !== '') {
          this.setMenuList();
          this.isKeywordLayerOpen = true;
        } else {
          this.searchedMenuList = [];
          this.isKeywordLayerOpen = false;
        }
      },
      /**
       * @description 검색 바 열기 이벤트
       */
      handleOpenSearchLayer() {
        this.isSearchBarOpen = true;
        if (this.searchText.trim() !== '') {
          this.isKeywordLayerOpen = true;
        }
      },
      /**
       * @description 검색 바 닫기 이벤트
       */
      handleOutsideClick(event) {
        const autocompleteLayer = this.$refs.autocompleteLayer;
        const searchBar = this.$refs.searchBar;

        // 클릭한 대상이 autocomplete-layer 또는 search-bar 내부라면 닫지 않음
        if ((autocompleteLayer && autocompleteLayer.contains(event.target)) || (searchBar && searchBar.contains(event.target))) {
          return;
        }

        // 외부 클릭 시 레이어 닫기
        this.isKeywordLayerOpen = false;
      },
      /**
       * @description 검색어 삭제 버튼 클릭 이벤트
       */
      handleClearSearchText() {
        this.searchText = '';
        this.searchedMenuList = [];
        this.isKeywordLayerOpen = false;
      },
      /**
       * @description 검색 버튼 클릭 이벤트
       */
      handleSearchClick() {
        if (this.searchText.trim() === '') {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.NOT_FOUND_SEARCH_DATA', { defaultValue: '검색 결과가 존재하지 않습니다.' }));
        }
      },
      /**
       * @description 메뉴 페이지로 이동
       * @param menu
       */
      goMenuUrl(menu) {
        this.$router.push(menu.pageUrl);
      },
      getFullMenuPath(menu) {
        const path = [];
        let currentMenu = menu;

        // parentId를 따라가며 상위 메뉴를 탐색
        while (currentMenu) {
          path.unshift(currentMenu.menuNm); // 메뉴명을 경로 맨 앞에 추가
          if (!currentMenu.parentId) {
            break; // 더 이상 부모가 없으면 종료
          }
          currentMenu = this.$store.getters.getMenuList.find(item => item.id === currentMenu.parentId);
        }

        return path.join(' > '); // 경로를 ' > '로 연결하여 반환
      },
      /**
       * @description 검색어에 따라 메뉴 목록을 설정합니다. (일반메뉴 제외)
       */
      setMenuList() {
        const menuList = this.$store.getters.getMenuList.filter(menu => this.validationMenu(menu));
        this.searchedMenuList = menuList.map(menu => ({
          menuNm: menu.menuNm,
          pageUrl: menu.pageUrl,
          menuPath: `${this.getFullMenuPath(menu)}`, // 메뉴 경로
        }));
      },
      /**
       * @description 메뉴 유효성 검사
       * @param menu
       * @return {boolean}
       */
      validationMenu(menu) {
        // 메뉴명에 검색어가 포함되어 있지 않으면 false 반환
        if (!menu?.menuNm.includes(this.searchText)) {
          return false;
        }

        // 일반 메뉴인 경우 false 반환
        if (menu?.menuTypeCd === this.$_enums.common.menuType.NORMAL_MENU.value) {
          return false;
        }

        // 권한 사용 여부가 'N'인 경우 false 반환
        return menu?.authUseFl !== this.$_enums.common.stringUsedFlag.NO.value;
      },
    },
    mounted() {
      window.addEventListener('click', this.handleOutsideClick);
    },
    destroyed() {
      window.removeEventListener('click', this.handleOutsideClick);
    },
  };
</script>

<style scoped>
  /* 전체 컨테이너 스타일 */
  .search-container {
    margin: 0 auto;
    width: 100%;
    max-width: 500px;
  }

  /* 검색 바 */
  .search-bar {
    display: flex;
    align-items: center;
    width: 300px;
    height: 34px;
    background: #fff;
    border: 1px solid #ededed;
    border-radius: 17px;
  }

  /* 검색 버튼 (왼쪽 위치) */
  .search-button {
    width: 30px;
    height: 30px;
    margin: 0 10px 0 10px;
    background: url('../assets/images/ecs_gnb_sprite.png') no-repeat -50px -81px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
  }

  .search-button:hover {
    background-color: #f2f2f2;
  }

  /* 검색어 입력 필드 */
  .search-input {
    flex-grow: 1;
    height: 38px;
    font-size: 13px;
    border: 2px transparent solid;
    border-right: none;
    border-radius: 0;
    padding: 0 50px 0 20px;
    background: transparent;
  }

  .search-input::placeholder {
    color: #b1b1b1;
  }

  .search-input:hover {
    background: none;
  }

  .search-input:focus {
    outline: auto;
  }

  .search-input:focus::placeholder {
    color: transparent;
  }

  /* 삭제 버튼 */
  .clear-button {
    position: absolute;
    top: calc(50% - 10px);
    right: 15px;
    width: 21px;
    height: 21px;
    background: none;
    border: none;
    cursor: pointer;
  }

  .clear-button::before {
    content: '';
    position: absolute;
    top: 10px;
    left: 4px;
    width: 13px;
    height: 1px;
    background: #666;
    transform: rotateZ(45deg);
  }

  .clear-button::after {
    content: '';
    position: absolute;
    top: 4px;
    left: 10px;
    width: 1px;
    height: 13px;
    background: #666;
    transform: rotateZ(45deg);
  }

  /* 자동완성 레이어 */
  .autocomplete-layer {
    position: absolute;
    top: 36px;
    left: 0;
    width: 100%;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    z-index: 1000;
  }

  .autocomplete-content {
    max-height: 200px;
    overflow-y: auto;
    padding: 10px;
  }

  .autocomplete-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .autocomplete-item {
    padding: 5px 10px;
    font-size: 13px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
    position: relative;
    text-overflow: ellipsis;
    overflow: hidden;
    box-sizing: border-box;
  }

  /* 툴팁 스타일 */
  .autocomplete-item:hover::after {
    content: attr(data-menu-path); /* "data-menu-path" 속성 읽기 */
    position: absolute;
    left: 100%; /* 툴팁을 오른쪽 위치에 표시 */
    top: 0;
    margin-left: 10px;
    background: #333;
    color: #fff;
    padding: 4px 8px;
    border-radius: 4px;
    white-space: nowrap;
    font-size: 12px;
    z-index: 1000;
  }

  .autocomplete-no-results {
    font-size: 14px;
    text-align: center;
    color: #999;
  }
</style>
