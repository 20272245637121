<template>
  <transition>
    <div>
      <DxDataGrid
        ref="manualAddGrid"
        key-expr="id"
        :allow-column-reordering="true"
        :show-column-headers="true"
        :show-borders="false"
        :column-auto-width="true"
        :show-row-lines="true"
        :show-column-lines="false"
        :no-data-text="this.$_lang('CMN_NO_DATA')"
        :data-source="dataGrid.dataSource"
        :selected-row-keys="dataGrid.selectedRowKeys"
        @row-click="onRowClick"
        @cell-hover-changed="onCellHoverChanged"
        @selection-changed="onSelectionChanged"
        height="550"
      >
        <DxScrolling mode="virtual" />
        <DxSelection mode="multiple" />
        <DxFilterRow :visible="true" />
        <DxHeaderFilter :visible="true" />

        <DxColumn
          caption="문항구분"
          data-field="questionDivisionCd"
          alignment="center"
          :allow-sorting="true"
          :calculate-sort-value="data => calculateSortValue(data, 'questionDivisionCd')"
        >
          <DxLookup :data-source="codes.questionDivisionCd.dataSource" value-expr="codeId" display-expr="codeNm" />
        </DxColumn>
        <DxColumn
          caption="문항구분상세"
          data-field="questionDivisionDetailCd"
          alignment="center"
          :allow-sorting="true"
          :calculate-sort-value="data => calculateSortValue(data, 'questionDivisionDetailCd')"
        >
          <DxLookup :data-source="codes.questionDivisionDetailCd.dataSource" value-expr="codeId" display-expr="codeNm" />
        </DxColumn>
        <DxColumn
          caption="문항내용"
          data-field="questionNm"
          alignment="left"
          :allow-sorting="true"
          :allow-header-filtering="false"
          cell-template="htmlTextTemplate"
        />
        <template #htmlTextTemplate="{ data }">
          <div v-html="data.data.questionNm"></div>
        </template>
        <DxColumn
          caption="첨부파일"
          data-field="fileId"
          alignment="center"
          width="110"
          :allow-sorting="false"
          cell-template="downloadCellTemplate"
        />
        <template #downloadCellTemplate="{ data }">
          <div v-if="data.data.fileGroupId">
            <DxButton class="download" @click="downloadFile(data.data.fileGroupId)" />
          </div>
        </template>

        <DxColumn
          caption="정답유형"
          data-field="answerType"
          alignment="center"
          width="110"
          :allow-sorting="true"
          :calculate-sort-value="data => calculateSortValue(data, 'answerType')"
        >
          <DxLookup :data-source="ewmCodes.answerType.values" value-expr="value" display-expr="label" />
        </DxColumn>
        <DxColumn
          caption="난이도"
          data-field="examLevel"
          alignment="center"
          width="110"
          :allow-sorting="true"
          :calculate-sort-value="data => calculateSortValue(data, 'examLevel')"
        >
          <DxLookup :data-source="ewmCodes.examLevel.values" value-expr="value" display-expr="label" />
        </DxColumn>
        <DxColumn
          caption="채점방식"
          data-field="examScoringType"
          alignment="center"
          width="110"
          :allow-sorting="true"
          :calculate-sort-value="data => calculateSortValue(data, 'examScoringType')"
        >
          <DxLookup :data-source="ewmCodes.examScoringType.values" value-expr="value" display-expr="label" />
        </DxColumn>
        <DxColumn caption="출제횟수" data-field="questionCnt" alignment="center" width="110" :allow-sorting="true" />
        <DxColumn caption="배점" data-field="score" alignment="center" width="110" :allow-sorting="true" />
      </DxDataGrid>
    </div>
  </transition>
</template>

<script>
  import { isSuccess } from '@/plugins/common-lib';
  import { DxButton } from 'devextreme-vue/button';
  import { DxColumn, DxDataGrid, DxFilterRow, DxHeaderFilter, DxLookup, DxScrolling, DxSelection } from 'devextreme-vue/data-grid';

  export default {
    components: {
      DxButton,
      DxDataGrid,
      DxColumn,
      DxLookup,
      DxScrolling,
      DxSelection,
      DxFilterRow,
      DxHeaderFilter,
    },
    props: {
      contentData: Object,
    },
    data() {
      return {
        codes: {
          questionDivisionCd: {
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
          questionDivisionDetailCd: {
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
        },
        isSelectionStopped: true,
        dataGrid: {
          dataSource: [],
          selectedRowsData: [],
          selectedRowKeys: [],
        },
      };
    },
    computed: {
      ewmCodes() {
        return this.$_enums.ewm;
      },
    },
    methods: {
      /** @description : 소트설정 */
      calculateSortValue(data, clsCd) {
        if (clsCd === 'questionDivisionCd') {
          return this.codes.questionDivisionCd.dataSource.find(e => data.questionDivisionCd === e.codeId).codeNm;
        } else if (clsCd === 'questionDivisionDetailCd') {
          const questionDivisionDetailCdDataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_edu_exam_question_division'], 3);
          return questionDivisionDetailCdDataSource.find(e => data.questionDivisionDetailCd === e.codeId).codeNm;
        } else if (clsCd === 'answerType') {
          return this.$_enums.ewm.answerType.values.find(e => data.answerType === e.value).label;
        } else if (clsCd === 'examLevel') {
          return this.$_enums.ewm.examLevel.values.find(e => data.examLevel === e.value).label;
        } else if (clsCd === 'examScoringType') {
          return this.$_enums.ewm.examScoringType.values.find(e => data.examScoringType === e.value).label;
        }
      },
      /**
       * 파일 다운로드
       *
       * @param fileGroupId
       */
      async downloadFile(fileGroupId) {
        const fileList = await this.$_getListAttachFile(fileGroupId);

        if (!fileList || fileList.length === 0) {
          this.$_Msg('다운로드 할 파일이 없습니다.');
          return;
        }

        fileList.forEach(fileInfo => {
          this.$_downloadAttachFile(fileInfo.fileGroupId, fileInfo.fileName);
        });
      },
      onRowClick(e) {
        let keys = e.component.getSelectedRowKeys();
        let index = keys.indexOf(e.key);

        if (index > -1) {
          keys.splice(index, 1);
        } else {
          keys.push(e.key);
        }

        e.component.selectRows(keys);
      },
      onCellHoverChanged(e) {
        const event = e.event;
        if (event.buttons === 1) {
          if (this.isSelectionStopped) {
            this.isSelectionStopped = false;
            this.selectedRange = {};
          }

          if (this.selectedRange.startRowIndex === undefined) {
            this.selectedRange.startRowIndex = e.rowIndex;
          }

          if (!this.selectedRange.startColumnIndex) {
            this.selectedRange.startColumnIndex = e.columnIndex;
          }

          this.selectedRange.endRowIndex = e.rowIndex;
          this.selectedRange.endColumnIndex = e.columnIndex;

          let start = Math.min(this.selectedRange.startRowIndex, this.selectedRange.endRowIndex);
          let end = Math.max(this.selectedRange.startRowIndex, this.selectedRange.endRowIndex);

          let indexes = [];
          for (let i = start; i <= end; i++) {
            indexes.push(i);
          }
          e.component.selectRowsByIndexes(indexes);
        } else {
          this.isSelectionStopped = true;
        }
      },
      onSelectionChanged({ selectedRowsData }) {
        this.$emit('input', selectedRowsData);
      },
      async selectDataList() {
        const payload = {
          actionName: 'EWM_EXAM_QUESTION_LIST',
        };

        let rtnData = [];
        const res = await this.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          rtnData = res.data.data.filter(d => d.viewFl === 'Y');
          this.dataGrid.selectedRowKeys = this.contentData.selectedIdList;
        }
        this.dataGrid.dataSource = rtnData;
      },
      /** @description : 셀렉트 박스 초기화 */
      async initCodeMap() {
        this.codeMap = await this.$_getCodeMapList('root_ewm_edu_exam_question_division');
      },
    },
    mounted() {
      this.initCodeMap().then(() => {
        this.codes.questionDivisionCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_edu_exam_question_division'], 2);
        this.codes.questionDivisionDetailCd.dataSource = options => {
          return {
            store: this.$_fetchCodesByMaxDepth(this.codeMap['root_ewm_edu_exam_question_division']),
            filter: options.data ? ['parentId', '=', options.data.questionDivisionCd] : null,
          };
        };
      });
      this.selectDataList();
    },
  };
</script>
