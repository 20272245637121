<!--
  PACKAGE_NAME : src/pages/esp/auth
  FILE_NAME : search-target-page.vue
  AUTHOR : devyoon91
  DATE : 2024-12-12
  DESCRIPTION : 검색 대상 페이지
-->
<template>
  <div>
    <!-- 제목과 버튼 영역 -->
    <div class="header-container">
      <dx-button
        :text="$_lang('COMPONENTS.SAVE', { defaultValue: '저장' })"
        class="btn_XS default filled txt_S medium"
        :width="60"
        :height="30"
        @click="handleSave"
      />
    </div>

    <div class="dropdown-container">
      <!-- 좌측 드롭다운 -->
      <div class="dropdown-box">
        <div class="dropdown-title">사이트 선택</div>
        <dx-drop-down-box
          ref="siteDropDown"
          v-model="authGroupData.selectedIds['site']"
          :data-source="authGroupData.defaultDataList.site"
          display-expr="siteNm"
          value-expr="id"
          placeholder="사이트 선택"
          :defer-rendering="false"
          :show-clear-button="true"
          :styling-mode="'outlined'"
          height="30px"
          class="mar_ri10"
        >
          <template #content="{}">
            <dx-data-grid
              ref="siteGrid"
              :data-source="authGroupData.defaultDataList.site"
              :selected-row-keys="authGroupData.selectedDataList.site"
              :hover-state-enabled="true"
              @selection-changed="handleSiteSelectionChanged"
            >
              <dx-column caption="사이트" data-field="siteNm" alignment="center" />
              <dx-selection mode="multiple" show-check-boxes-mode="always" />
              <dx-paging :enabled="false" :page-size="10" />
              <dx-scrolling mode="virtual" />
              <dx-filter-row :visible="false" />
            </dx-data-grid>
          </template>
        </dx-drop-down-box>
      </div>

      <!-- 우측 드롭다운 -->
      <div class="dropdown-box">
        <div class="dropdown-title">센터 선택</div>
        <dx-drop-down-box
          ref="tenantDropDown"
          v-model="authGroupData.selectedIds['tenant']"
          :data-source="authGroupData.defaultDataList.tenant"
          display-expr="tenantNm"
          value-expr="id"
          placeholder="센터 선택"
          :defer-rendering="false"
          :show-clear-button="true"
          :styling-mode="'outlined'"
          height="30px"
          class="mar_ri10"
        >
          <template #content="{}">
            <dx-data-grid
              ref="tenantGrid"
              :data-source="authGroupData.defaultDataList.tenant"
              :selected-row-keys="authGroupData.selectedDataList.tenant"
              :hover-state-enabled="true"
              @selection-changed="handleTenantSelectionChanged"
            >
              <dx-column caption="센터" data-field="tenantNm" alignment="center" />
              <dx-selection mode="multiple" show-check-boxes-mode="always" />
              <dx-paging :enabled="false" :page-size="10" />
              <dx-scrolling mode="virtual" />
              <dx-filter-row :visible="false" />
            </dx-data-grid>
          </template>
        </dx-drop-down-box>
      </div>
    </div>
    <div style="display: flex" class="unit-list channel-acco-list-box">
      <search-data-grid
        v-for="authGroupDataGrid in authGroupDataGrids"
        :key="authGroupDataGrid.dataField"
        :data-grid="authGroupDataGrid"
        :data-source="authGroupData.dataSources[authGroupDataGrid.dataField]"
        :target="authGroupData.selectedDataList"
        :total-count="
          authGroupData.dataSources[authGroupDataGrid.dataField] !== undefined
            ? authGroupData.dataSources[authGroupDataGrid.dataField].length
            : 0
        "
        @selectedRow="handleSelectedRow"
        @valueChanged="handleValueChanged"
        :height="'calc(100% - 186px)'"
      />
    </div>
  </div>
</template>

<script>
  import SearchDataGrid from './search-data-grid.vue';
  import { DxColumn, DxFilterRow, DxScrolling } from 'devextreme-vue/tree-list';
  import { DxDataGrid, DxPaging, DxSelection } from 'devextreme-vue/data-grid';
  import DxDropDownBox from 'devextreme-vue/drop-down-box';
  import { isSuccess } from '@/plugins/common-lib';
  import { DxButton } from 'devextreme-vue/button';

  export default {
    components: {
      DxButton,
      DxDropDownBox,
      DxFilterRow,
      DxDataGrid,
      DxColumn,
      DxPaging,
      DxScrolling,
      SearchDataGrid,
      DxSelection,
    },
    data() {
      return {
        selectedAuthId: null,
        authGroupDataGrids: [
          {
            dataSource: [],
            visible: true,
            dataField: 'ibg',
            id: 'ibg',
            ref: 'refIbgDataGrid',
            keyExpr: 'id',
            height: 300,
            showBorders: true,
            showColumnHeaders: true,
            showColumnLines: false,
            showRowLines: true,
            rowAlternationEnabled: false,
            allowColumnReordering: true,
            columns: [
              {
                caption: 'id',
                dataField: 'id',
                alignment: 'center',
                visible: false,
              },
              {
                caption: '인입그룹',
                dataField: 'ibgNm',
                alignment: 'center',
                visible: true,
              },
            ],
            selection: {
              allowSelectAll: true,
              selectAllMode: 'allPages',
              showCheckBoxesMode: 'always',
              mode: 'multiple',
            },
            scrolling: {
              mode: 'standard', // ['infinite', 'standard', 'virtual']
            },
          },
          {
            dataSource: [],
            visible: true,
            dataField: 'skl',
            id: 'skl',
            ref: 'refSklDataGrid',
            height: 300,
            keyExpr: 'id',
            showBorders: true,
            showColumnHeaders: true,
            showColumnLines: false,
            showRowLines: true,
            rowAlternationEnabled: false,
            allowColumnReordering: true,
            columns: [
              {
                caption: 'id',
                dataField: 'id',
                alignment: 'center',
                visible: false,
              },
              {
                caption: '상담그룹',
                dataField: 'sklNm',
                alignment: 'center',
                visible: true,
              },
            ],
            selection: {
              allowSelectAll: true,
              selectAllMode: 'allPages',
              showCheckBoxesMode: 'always',
              mode: 'multiple',
            },
            scrolling: {
              mode: 'standard',
            },
          },
          {
            dataSource: [],
            visible: true,
            dataField: 'agtteam',
            id: 'agtteam',
            ref: 'refAgtteamDataGrid',
            height: 300,
            keyExpr: 'id',
            showBorders: true,
            showColumnHeaders: true,
            showColumnLines: false,
            showRowLines: true,
            rowAlternationEnabled: false,
            allowColumnReordering: true,
            columns: [
              {
                caption: 'id',
                dataField: 'id',
                alignment: 'center',
                visible: false,
              },
              {
                caption: '팀',
                dataField: 'agtteamNm',
                alignment: 'center',
                visible: true,
              },
            ],
            selection: {
              allowSelectAll: true,
              selectAllMode: 'allPages',
              showCheckBoxesMode: 'always',
              mode: 'multiple',
            },
            scrolling: {
              mode: 'standard',
            },
          },
          {
            dataSource: [],
            visible: true,
            dataField: 'ivrdnis',
            id: 'dnis',
            ref: 'refIvrdnisDataGrid',
            height: 300,
            keyExpr: 'id',
            showBorders: true,
            showColumnHeaders: true,
            showColumnLines: false,
            showRowLines: true,
            rowAlternationEnabled: false,
            allowColumnReordering: true,
            columns: [
              {
                caption: 'id',
                dataField: 'id',
                alignment: 'center',
                visible: false,
              },
              {
                caption: '대표번호',
                dataField: 'dnisNm',
                alignment: 'center',
                visible: true,
              },
            ],
            selection: {
              allowSelectAll: true,
              selectAllMode: 'allPages',
              showCheckBoxesMode: 'always',
              mode: 'multiple',
            },
            scrolling: {
              mode: 'standard',
            },
          },
        ],
        authGroupData: {
          // 검색 대상 권한 데이터 prop
          // 검색대상노출권한 초기 default data
          defaultDataList: {
            site: [],
            tenant: [],
            ibg: [],
            skl: [],
            agtteam: [],
            ivrdnis: [],
            vdn: [],
          },
          dataSources: {
            site: [],
            tenant: [],
            ibg: [],
            skl: [],
            agtteam: [],
            ivrdnis: [],
            vdn: [],
          },
          //선택된 그룹 데이터
          selectedDataList: {
            site: [],
            tenant: [],
            ibg: [],
            skl: [],
            agtteam: [],
            ivrdnis: [],
            vdn: [],
          },
          //선택된 그룹 id를 담은 데이터
          selectedIds: {
            site: [],
            tenant: [],
          },
        },
      };
    },
    methods: {
      tenantGridInstance() {
        return this.$refs?.tenantGrid?.instance;
      },
      siteGridInstance() {
        return this.$refs?.siteGrid?.instance;
      },
      fetchDataOrEmptyArray(result) {
        return result.status === 'fulfilled' && isSuccess(result.value) ? result.value.data.data : [];
      },
      /**
       * @description 검색권한 그리드 선택 이벤트
       *
       * @param targetSelectedRowKeys
       * @param dataField
       * @param selectedKeys
       */
      handleSelectedRow(targetSelectedRowKeys, dataField, selectedKeys) {
        targetSelectedRowKeys[dataField] = selectedKeys;
      },
      // grid 그룹 헤더 체크 이벤트
      handleValueChanged(e, key, groupData) {
        if (e.value) {
          this.authGroupData.selectedDataList[key] = groupData.items.map(d => d.id);
        } else {
          this.authGroupData.selectedDataList[key] = [];
        }
      },
      /**
       * 검색 대상 권한 데이터 조회
       *
       * @param authId
       * @return {Promise<void>}
       */
      async setAuthGroup(authId = null) {
        // 선택 초기화
        this.selectedAuthId = authId;
        this.authGroupData.selectedDataList = {
          site: [],
          tenant: [],
          ibg: [],
          skl: [],
          agtteam: [],
          vdn: [],
          ivrdnis: [],
        };

        if (this.selectedAuthId === null) {
          return;
        }

        const selectedTenantIds = this.authGroupData.selectedIds.tenant || null;
        const yesFlag = this.$_enums.common.stringViewFlag.YES.value;
        const payload = {
          actionName: 'AUTH_GROUP_LIST', // 메뉴그룹별 권한,
          data: {
            authId: this.selectedAuthId,
          },
          useErrorPopup: true,
        };

        const authGroupResponse = await this.CALL_API(payload);

        const apiCalls = {
          siteList: this.CALL_API({
            actionName: 'SITE_LIST_ALL',
          }),
          tenantList: this.CALL_API({
            actionName: 'TENANT_LIST_ALL',
          }),
          ibgList: this.CALL_CC_API({
            actionName: 'CC_IBG_LIST',
            data: {
              tenantId: selectedTenantIds,
              sort: '+ibgNm',
              viewFl: yesFlag,
            },
          }),
          sklList: this.CALL_CC_API({
            actionName: 'CC_SKL_LIST',
            data: {
              tenantId: selectedTenantIds,
              sort: '+sklNm',
              viewFl: yesFlag,
            },
          }),
          agtteamList: this.CALL_CC_API({
            actionName: 'CC_AGTTEAM_LIST',
            data: {
              tenantId: selectedTenantIds,
              sort: '+agtteamNm',
              viewFl: yesFlag,
            },
          }),
          ivrDnisList: this.CALL_CC_API({
            actionName: 'CC_IVR_DNIS_LIST',
            data: {
              tenantId: selectedTenantIds,
              viewFl: yesFlag,
            },
          }),
        };

        const resList = await Promise.allSettled(Object.values(apiCalls));
        const resultOfExternalCall = Object.keys(apiCalls).reduce((acc, key, index) => {
          acc[key] = resList[index];
          return acc;
        }, {});

        if (isSuccess(authGroupResponse)) {
          this.setAuthGroupData(resultOfExternalCall); // 검색 대상 권한 초기 데이터 설정
          const selectedRows = {};
          let selectedKeywords = [];

          const authGroupData = authGroupResponse.data?.data;
          Object.keys(authGroupData).forEach(key => {
            if (!selectedRows[key]) {
              selectedRows[key] = [];
              selectedKeywords.push(key);
            }
            selectedRows[key].push(...authGroupData[key].map(item => item.groupId));
          });

          for (let key of selectedKeywords) {
            this.authGroupData.selectedDataList[key] = selectedRows[key];
          }
          this.setChangedSite(selectedRows?.site);
          this.setChangedTenant(selectedRows?.tenant);
        }
      },
      /**
       * @description 검색 대상 권한 초기 데이터 설정
       * @param resultOfExternalCall
       */
      setAuthGroupData(resultOfExternalCall) {
        const site = this.fetchDataOrEmptyArray(resultOfExternalCall.siteList);
        const tenant = this.fetchDataOrEmptyArray(resultOfExternalCall.tenantList);
        const ibg = this.fetchDataOrEmptyArray(resultOfExternalCall.ibgList);
        const skl = this.fetchDataOrEmptyArray(resultOfExternalCall.sklList);
        const agtteam = this.fetchDataOrEmptyArray(resultOfExternalCall.agtteamList);
        const ivrdnis = this.fetchDataOrEmptyArray(resultOfExternalCall.ivrDnisList);
        this.authGroupData.defaultDataList = { site, tenant, ibg, skl, agtteam, ivrdnis };
        this.authGroupData.dataSources = { site, tenant, ibg, skl, agtteam, ivrdnis };
      },
      /**
       * 사이트 선택 셀렉트 박스 번경 이벤트
       *
       * @param selectedIds
       */
      setChangedSite(selectedIds) {
        if (selectedIds === null || selectedIds === undefined || selectedIds?.length === 0) {
          this.authGroupData.selectedIds.site = [];
          this.authGroupData.selectedDataList.site = [];
          this.authGroupData.selectedIds.tenant = [];
          this.authGroupData.selectedDataList.tenant = [];
          return;
        }
        this.authGroupData.selectedIds.site = selectedIds;
        this.authGroupData.selectedDataList.site = this.authGroupData.defaultDataList.site.filter(d => selectedIds.includes(d.id));
        this.authGroupData.selectedDataList.tenant = this.authGroupData.defaultDataList.tenant.filter(d => selectedIds.includes(d.siteId));
        this.siteGridInstance().refresh();
      },
      /**
       * 센터 선택 셀렉트박스 변경 이벤트
       *
       * @param selectedIds
       */
      setChangedTenant(selectedIds) {
        if (selectedIds === null || selectedIds === undefined || selectedIds?.length === 0) {
          this.authGroupData.selectedIds.tenant = [];
          this.authGroupData.selectedDataList.tenant = [];
          this.authGroupData.dataSources.ibg = [];
          this.authGroupData.dataSources.skl = [];
          this.authGroupData.dataSources.agtteam = [];
          this.authGroupData.dataSources.ivrdnis = [];
          this.tenantGridInstance().refresh();
          return;
        }

        this.authGroupData.selectedIds.tenant = selectedIds;
        this.authGroupData.selectedDataList.tenant = this.authGroupData.defaultDataList.tenant.filter(d => selectedIds.includes(d.id));
        this.authGroupData.dataSources.ibg = this.authGroupData.defaultDataList.ibg.filter(d => selectedIds.includes(d.tenantId));
        this.authGroupData.dataSources.skl = this.authGroupData.defaultDataList.skl.filter(d => selectedIds.includes(d.tenantId));
        this.authGroupData.dataSources.agtteam = this.authGroupData.defaultDataList.agtteam.filter(d => selectedIds.includes(d.tenantId));
        this.authGroupData.dataSources.ivrdnis = this.authGroupData.defaultDataList.ivrdnis.filter(d => selectedIds.includes(d.tenantId));
        this.tenantGridInstance().refresh();
      },
      /**
       * @description 사이트 로우 선택시 이벤트
       * @param e
       */
      handleSiteSelectionChanged(e) {
        if (e.selectedRowKeys.length === 0) {
          return; // 빈 리스트일 때는 아무런 작업도 하지 않음
        }
        const selectedIds = e.selectedRowKeys.map(d => d.id);
        this.setChangedSite(selectedIds);
      },
      /**
       * @description 센터 로우 선택시 이벤트
       * @param e
       */
      handleTenantSelectionChanged(e) {
        if (e.selectedRowKeys.length === 0) {
          return; // 빈 리스트일 때는 아무런 작업도 하지 않음
        }
        const selectedIds = e.selectedRowKeys.map(d => d.id);
        this.setChangedTenant(selectedIds);
      },
      /**
       * @description 저장 이벤트
       */
      handleSave() {
        this.saveAuthGroup();
      },
      /**
       * @description 검색대상 권한 저장 API 호출
       * @return {Promise<void>}
       */
      async saveAuthGroup() {
        const payload = {
          actionName: 'AUTH_GROUP_SAVE',
          data: {
            site: this.authGroupData.selectedDataList.site.map(d => d.id),
            tenant: this.authGroupData.selectedDataList.tenant.map(d => d.id),
            ibg: this.authGroupData.selectedDataList.ibg,
            skl: this.authGroupData.selectedDataList.skl,
            agtteam: this.authGroupData.selectedDataList.agtteam.map(d => Number(d)),
            ivrdnis: this.authGroupData.selectedDataList.ivrdnis,
          },
          useErrorPopup: true,
          path: this.selectedAuthId,
        };

        const res = await this.CALL_API(payload);

        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
          await this.setAuthGroup(this.selectedAuthId);
        }
      },
    },
    created() {},
    mounted() {},
  };
</script>

<style lang="scss" scoped>
  /* 상단 영역 - 제목과 버튼 배치 */
  .header-container {
    display: flex;
    justify-content: flex-end; /* 오른쪽 끝 */
    align-items: center; /* 세로 정렬 */
    padding: 4px 0 0 0;
  }

  ::v-deep .header-container > .btn_XS {
    padding: 0 10px;
    font-size: 13px;
    min-height: 28px;
    line-height: 26px;
    margin: 0;
  }

  /* 상단 제목 영역 스타일 */
  .header-title {
    font-size: 18px; /* 제목 크기 */
    font-weight: bold;
  }

  /* 드롭다운 컨테이너 - Flex로 좌우 배치 */
  .dropdown-container {
    display: flex;
    align-items: flex-start;
    width: 100%; /* 전체 화면 너비 사용 */
  }

  /* 각각의 드롭다운 박스 */
  .dropdown-box {
    flex: 1;
    box-sizing: border-box;
  }

  .dropdown-box:last-child {
    padding-left: 10px; /* 우측 드롭다운과의 간격 */
  }

  /* 드롭다운에 소제목(설명) 스타일 */
  .dropdown-title {
    padding-bottom: 10px; /* 드롭다운과의 간격 */
    font-weight: bold; /* 강조된 텍스트 */
    font-size: 14px; /* 적절한 크기 조정 */
  }
</style>
