var render = function render(){var _vm=this,_c=_vm._self._c;return _c('dx-popup',{attrs:{"visible":_vm.isOpen,"show-title":_vm.propOption.showTitle,"title":_vm.propOption.title,"min-width":_vm.propOption.minWidth,"width":_vm.propOption.width,"min-height":_vm.propOption.minHeight,"height":_vm.propOption.height,"resize-enabled":_vm.propOption.resizeEnabled,"drag-enabled":_vm.propOption.dragEnabled,"show-close-button":_vm.propOption.showCloseButton,"hide-on-outside-click":_vm.propOption.closeOnOutsideClick},on:{"hiding":_vm.closeModal}},[_c('div',{staticClass:"flex-modal-container"},[_c('div',{staticClass:"flex-modal-item"},[_c('div',{staticClass:"editor-style"},[_c('codemirror-editor',{attrs:{"value":_vm.cmEditor.pageData,"beautyType":"js"}})],1)])]),_c('dx-toolbar-item',{attrs:{"widget":"dxButton","toolbar":"bottom","location":"center","options":{
      elementAttr: {
        class: 'default filled txt_S medium',
      },
      text: _vm.propOption.saveBtnTxt,
      width: '120',
      height: '40',
      onClick: e => _vm.handleSaveModal(e),
    }}}),_c('dx-toolbar-item',{attrs:{"widget":"dxButton","toolbar":"bottom","location":"center","options":{
      elementAttr: {
        class: 'white filled txt_XS medium',
      },
      text: _vm.propOption.cancelBtnTxt,
      width: '120',
      height: '40',
      onClick: e => _vm.handleCloseModal(e),
    }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }