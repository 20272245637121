<!--
  PACKAGE_NAME : src/pages/euc/phone/request
  FILE_NAME : modal-user-select.vue
  AUTHOR : jhcho
  DATE : 2024-05-20
  DESCRIPTION :
-->
<template>
  <DxTreeList
      ref="userListRef"
      :data-source="tree.deptList"
      key-expr="id"
      parent-id-expr="parentId"
      items-expr="items"
      data-structure="tree"
      :root-value="-1"
      :expanded-row-keys="[]"
      :selectedRowKeys="tree.selectedRowKeys"
      :columns="tree.columns"
      :show-column-headers="true"
      :show-row-lines="true"
      :show-column-lines="false"
      :show-borders="false"
      :column-auto-width="true"
      :noDataText="getNoDataText"
      height="calc(100% - 65px)"
  >
    <DxSearchPanel
        :visible="true"
        :highlightSearchText="true"
        :searchVisibleColumnsOnly="false"
        placeholder=""
    />
    <DxSelection :recursive="tree.recursive" mode="single" />
    <DxColumn
        caption="부서"
        data-field="deptNm"
        alignment="left"
        :visible="true"
    />
    <DxColumn
        caption="이름"
        data-field="userNm"
        alignment="center"
        :visible="true"
    />
    <DxColumn
        caption="직위"
        data-field="gradeNm"
        alignment="center"
        :visible="true"
    />
    <DxColumn
        caption="상태"
        data-field="userStateCd"
        alignment="center"
        :visible="true"
     >
      <DxLookup
          :data-source="$_getCode('user_state_cd')"
          display-expr="codeNm"
          value-expr="codeId"
      />
    </DxColumn>
  </DxTreeList>
</template>
<script>
import { isSuccess } from "@/plugins/common-lib";
import {
  DxColumn, DxLookup, DxSearchPanel,
  DxSelection,
  DxTreeList,
} from "devextreme-vue/tree-list";

export default {
  components: {
    DxLookup,
    DxSearchPanel,
    DxTreeList,
    DxColumn,
    DxSelection,
  },
  props: {
    modalData: {
      type: Object
    }
  },
  computed: {
    getNoDataText() {
      switch (this.modalData.privilege) {
        case 'approver':
          return '승인 권한을 가진 사용자가 없습니다.';
        case 'worker':
          return '작업 권한을 가진 사용자가 없습니다.';
        default:
          return '사용자가 없습니다.';
      }
    }
  },
  data() {
    return {
      deptList: [],
      tree: {
        deptList: [],
        userList: [],
        selectedRowKeys: [],
        columns: [
          { dataField: 'id', caption: 'id', visible: false },
          { dataField: 'parentId', caption: 'parentId', visible: false },
          { dataField: 'deptNm', caption: '부서' },
          { dataField: 'userNm', caption: '사용자' },
          { dataField: 'gradeNm', caption: '직위' },
          { dataField: 'userStateCd', caption: '상태' },
        ],
        focusedRowKey: null,
        selectedRowsData: [],
        recursive: true,
        selectionMode: 'all', // 'all' or 'excludeRecursive' or 'leavesOnly'
        allowDropInsideItem: false,
        allowReordering: true,
        showDragIcons: false,
        selectedData: null,
      },
    }
  },
  methods: {
    getSelectUser() {
      return this.$refs.userListRef.instance.getSelectedRowsData();
    },
    async selectDataList() {
      const params = {
        sort: '+deptId',
      }

      if(this.modalData?.privilege) {
        params.authId = this.$_getCode('user_privilege').find((item) => item.codeNm === this.modalData.privilege)?.codeValue;
      }

      if(!params?.authId) {
        delete params.authId;
      }

      const payload = {
        actionName: 'USER_LIST_ALL_VIEW',
        data: params
      };

      const res = await this.CALL_API(payload);
      if(isSuccess(res)) {
        const users = res.data.data;

        const deptMap = new Map();
        this.deptList.forEach(dept => {
          if (!deptMap.has(dept.deptId)) {
            deptMap.set(dept.deptId, {
              id: `dept_${dept.deptId}`,
              parentId: `dept_${dept.parentId}`,
              deptNm: dept.deptNm,
              userNm: '',
              gradeNm: '',
              userStateCd: null,
              isUser: false,
              items: []
            });
          }
        });

        // 사용자를 해당 부서의 items 배열에 추가
        users.forEach(user => {
          const dept = deptMap.get(user.deptId);
          dept.items.push({
            id: user.id,
            parentId: `dept_${user.deptId}`,
            userNo: user.userNo,
            userNm: user.userNm,
            deptId: user.deptId,
            deptNm: user.deptNm,
            gradeId: user.gradeId,
            gradeNm: user.gradeNm,
            userStateCd: user.userStateCd,
            isUser: true
          });
        });

        // 하위 부서를 상위 부서의 items 배열에 추가
        deptMap.forEach(dept => {
          const number = Number(dept.parentId.replace('dept_', ''));
          if (dept.parentId && deptMap.has(number)) {
            const parentDept = deptMap.get(number);
            parentDept.items.push(dept);
          }
        });

        // 최상위 부서 목록 생성
        const rootDepts = Array.from(deptMap.values()).filter(dept => !dept.parentId || dept.parentId === 'dept_-1');

        // 현재 사용자의 부서를 최상위로 정렬
        const currentUser = this.$store.getters.getUserInfo;
        if(currentUser?.deptId) {
          const currentUserDeptId = `dept_${currentUser.deptId}`;
          rootDepts.sort((a, b) => {
            if (a.id === currentUserDeptId) return -1;
            if (b.id === currentUserDeptId) return 1;

            const aUser = a.items.some(item => item.id === currentUserDeptId);
            const bUser = b.items.some(item => item.id === currentUserDeptId);

            if (aUser && !bUser) return -1;
            if (bUser && !aUser) return 1;

            return 0;
          });
        }

        this.tree.deptList = rootDepts;
      }
    },
    clearSelection() {
      this.$refs.userListRef.instance.clearSelection();
    },
    async selectDeptList() {
      const payload = {
        actionName: 'DEPT_LIST_ALL_VIEW',
        data: {
          sort: '+deptOrd,+parentId'
        }
      }

      const res = await this.CALL_API(payload);
      if(isSuccess(res)) {
        this.deptList = res.data.data;
      }
    }
  },
  async mounted() {
    await this.selectDeptList();
    await this.selectDataList();
  },
  created() {
  }
}
</script>
<style scoped>

</style>