<!--
  PACKAGE_NAME : src\components\ewm\hr\modal-add-agent.vue
  FILE_NAME : modal-add-agent
  AUTHOR : bykim
  DATE : 2023-11-28
  DESCRIPTION : (모달) 공통 상담원 조회
-->
<template>
	<div>
		<DxPopup
			:visible="isOpen"
			:show-title="showModalTitle"
			:title="propOption.title"
			:min-width="propOption.minWidth"
			:width="propOption.width"
			:min-height="propOption.minHeight"
			:height="propOption.height"
			:resize-enabled="false"
			:drag-enabled="true"
			:show-close-button="true"
			:hide-on-outside-click="false"
			@hiding="closeModal"
		>
			<template>
				<DxDataGrid
					:key-expr="agtDataGrid.keyExpr"
					:id="agtDataGrid.id"
					:ref="agtDataGrid.refName"
					:allow-column-reordering="true"
					:data-source="agtDataGrid.dataSource"
					:show-column-headers="true"
					:show-borders="true"
					:column-auto-width="true"
					:show-row-lines="true"
					:show-column-lines="false"
					:no-data-text="noDataText()"
					@selection-changed="onSelectionChanged"
					@row-click="onRowClick"
					@cell-hover-changed="onCellHoverChanged"
					height="450"
					width="100%"
				>
					<DxHeaderFilter :visible="true" />
					<DxFilterRow :visible="true" />
					<DxSearchPanel :visible="true" :width="140" placeholder="" />
					<DxSelection select-all-mode="allPages" show-check-boxes-mode="always" mode="multiple" />
					<DxLoadPanel :enabled="false" />
					<DxColumn
						caption="부서"
						dataField="deptNmPath"
						:visible="true"
						sortOrder="none"
						:allowHeaderFiltering="false"
						:allowFiltering="true"
						:allowGrouping="false"
					/>
					<DxColumn
						caption="상담원"
						dataField="agent"
						:visible="true"
						sortOrder="none"
						:allowHeaderFiltering="true"
						:allowFiltering="true"
						:allowGrouping="false"
						:calculateCellValue="mergeAgentInfo"
					/>
					<DxColumn
						caption="직급"
						dataField="jikgupNm"
						:visible="true"
						sortOrder="none"
						alignment="center"
						:allowHeaderFiltering="true"
						:allowFiltering="true"
						:allowGrouping="false"
					/>
					<DxColumn
						caption="직위"
						dataField="jikweeNm"
						:visible="true"
						sortOrder="none"
						alignment="center"
						:allowHeaderFiltering="true"
						:allowFiltering="true"
						:allowGrouping="false"
					/>
					<DxColumn
						caption="업무그룹"
						dataField="workgroupNm"
						:visible="true"
						sortOrder="none"
						alignment="center"
						:allowHeaderFiltering="true"
						:allowFiltering="true"
						:allowGrouping="false"
					/>
					<DxColumn
						caption="계정권한"
						dataField="authNm"
						:visible="true"
						sortOrder="none"
						alignment="center"
						:allowHeaderFiltering="true"
						:allowFiltering="true"
						:allowGrouping="false"
						:calculateCellValue="setAuthNm"
					/>
					<DxColumn
						caption="재직상태"
						dataField="userStateCd"
						:visible="true"
						sortOrder="none"
						alignment="center"
						:allowHeaderFiltering="true"
						:allowFiltering="false"
						:allowGrouping="false"
						:lookup="{
							dataSource: this.$_enums.ewm.userState.values,
							displayExpr: 'label',
							valueExpr: 'value',
						}"
					/>
				</DxDataGrid>
			</template>
			<DxToolbarItem
				widget="dxButton"
				toolbar="bottom"
				location="center"
				:visible="propOption.useSaveBtn"
				:options="{
					elementAttr: {
						class: 'default filled txt_S medium',
					},
					text: propOption.btnTxt.save,
					width: '120',
					height: '40',
					useSubmitBehavior: false,
					onClick: () => send(),
				}"
			/>
			<DxToolbarItem
				widget="dxButton"
				toolbar="bottom"
				location="center"
				:visible="propOption.useCancelBtn"
				:options="{
					elementAttr: {
						class: 'white filled txt_XS medium',
					},
					text: propOption.btnTxt.cancel,
					width: '120',
					height: '40',
					onClick: () => closeModal(),
				}"
			/>
		</DxPopup>
	</div>
</template>

<script>
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import { DxDataGrid, DxSearchPanel, DxColumn, DxFilterRow, DxLoadPanel, DxSelection, DxHeaderFilter } from 'devextreme-vue/data-grid';
import { isSuccess } from '@/plugins/common-lib';
import CustomStore from "devextreme/data/custom_store";


export default {
	components: {
		DxHeaderFilter,
		DxDataGrid,
		DxSearchPanel,
		DxColumn,
		DxFilterRow,
		DxLoadPanel,
		DxSelection,
		DxToolbarItem,
		DxPopup,
	},
	props: {
		// 팝업 옵션, 컴포넌트에 직접 옵션을 주입하지 않을 경우 기본값으로 설정
		title: {
			default: '상담원 정보',
			type: String,
		},
		width: {
			default: 950,
			type: Number,
		},
		height: {
			default: 600,
			type: Number,
		},
		minWidth: {
			default: 850,
			type: Number,
		},
		minHeight: {
			default: 600,
			type: Number,
		},
		// 팝업 저장 버튼 유무
		useSaveBtn: {
			default: true,
			type: Boolean,
		},
		// 팝업 취소 버튼 유무
		useCancelBtn: {
			default: true,
			type: Boolean,
		},
		// 팝업 저장 버튼 텍스트
		saveBtnTxt: {
			default: '저장',
			type: String,
		},
		// 팝업 취소 버튼 텍스트
		cancelBtnTxt: {
			default: '취소',
			type: String,
		},
		// 팝업 오픈 유무
		isOpen: {
			default: false,
			type: Boolean,
		},
		// 팝업 타이틀 유무
		showModalTitle: {
			default: true,
			type: Boolean,
		},
		// 상담사 선택 데이터(그리드 선택 데이터)
		selectedIdList: {
			default: () => {
				return [];
			},
			type: Array,
		},
		// 상담사 선택 최소 갯수(최소 갯수를 넘지 않으면 저장 이벤트 발생X)
		minimumSelectionLength: {
			default: 0,
			type: Number,
		},
		// 상담사 선택 최대 갯수
		maximumSelectionLength: {
			default: 0,
			type: Number,
		},
	},
	data() {
		return {
			PAGE_DATA: {
				// 메뉴 페이지 데이터 설정, pageSize 하드 코딩(변경시 외부 주입필요)
				pageDto: {
					pagesize: 10000,
				},
			},
			propOption: {
				title: this.title,
				width: this.width,
				height: this.height,
				minWidth: this.minWidth,
				minHeight: this.minHeight,
				useSaveBtn: this.useSaveBtn,
				useCancelBtn: this.useCancelBtn,
				btnTxt: {
					save: this.saveBtnTxt,
					cancel: this.cancelBtnTxt,
				},
				maximumSelectionLength: this.maximumSelectionLength,
			},
			selAgtIdsList: [],
			agtDataGrid: {
				keyExpr: 'id',
				id: 'agtDataGrid',
				refName: 'agtDataGrid',
				dataSource: [],
			},
			isSelectionStopped: true,
			cellInfos: [],
			selectedIndexes: [],
			authIdList: [],
		};
	},
	watch: {
		/** @description : 팝업 옵션 변경 감지  */
		propOption() {
			this.propOption = {
				title: this.title,
				width: this.width,
				height: this.height,
				minWidth: this.minWidth,
				minHeight: this.minHeight,
				useSaveBtn: this.useSaveBtn,
				useCancelBtn: this.useCancelBtn,
				btnTxt: {
					save: this.saveBtnTxt,
					cancel: this.cancelBtnTxt,
				},
			};
		},
		/** @description : 팝업 오픈 감지  */
		isOpen() {
			this.selAgtIdsList = this.selectedIdList;
			this.dataGrid.clearSelection();
			this.dataGrid.clearFilter();
			this.dataGrid.clearSorting();

			// 부모 컴포넌트가 선택한 상담사가 있을 경우 && 팝업 오픈 상태 -> 상담사 선택
			if (this.selAgtIdsList.length > 0 && this.isOpen === true) {
				this.dataGrid.selectRows(this.selAgtIdsList, true); // 상담사 선택
			} else {
				this.selAgtIdsList = [];
			}
		},
	},
	computed: {
		ewmCodes() {
			return this.$_enums.ewm;
		},
		dataGrid: function () {
			return this.$refs[this.agtDataGrid.refName].instance;
		},
	},
	methods: {
		/** @description : 그리드 데이터 없음  */
		noDataText(isNoData) {
			if (isNoData) {
				return '데이터가 없습니다.';
			}
		},
		/** @description : 팝업 저장 버튼 액션  */
    send() {
      const selectedRowsData = this.dataGrid.getSelectedRowsData();
      if (selectedRowsData.length === 0) {
        this.$_Msg('상담원을 선택해주세요.');
        return;
      }
      if (this.minimumSelectionLength && selectedRowsData.length < this.minimumSelectionLength) {
        this.$_Msg(`최소 ${this.minimumSelectionLength}명 이상 선택해주세요.`);
        return;
      }
      if (this.maximumSelectionLength && selectedRowsData.length > this.maximumSelectionLength) {
        this.$_Msg(`최대 ${this.maximumSelectionLength}명 이하 선택해주세요.`);
        return;
      }
      this.$emit('saveModal', selectedRowsData);
    },
		/** @description : 팝업 취소 버튼 액션  */
		closeModal() {
			this.$emit('closeModal');
		},
		/** @description : 상담사 이름 + 상담사 로그인아이디 병합  */
		mergeAgentInfo(rowData) {
			if (rowData === null || rowData === undefined) {
				return '';
			}
			return `${rowData.agtNm} [${rowData.agtid}]`;
		},
		/** @description : 상담사 데이터 검색  */
		async selectDataList() {
			let vm = this;
			this.agtDataGrid.dataSource = new CustomStore({
				key: 'agtid',
				async load(loadOptions) {
					const payload = {
						actionName: 'EWM_AGENT_SEARCH_LIST_ALL',
						data: [],
						loading: false,
					};

					const res = await vm.CALL_EWM_API(payload);
					if (isSuccess(res)) {
						return res.data.data;
					}
				},
				insert(values) {
					//return false;
				},
				update(key, values) {
					//return false;
				},
				totalCount: opts => {
					return new Promise((resolve, reject) => {
						resolve(0);
					});
				},
			});
		},
		/** @description : 상담사 선택 변경 감지  */
		onSelectionChanged(e) {
			// 선택된 상담사가 있는 경우 갯수 제한X
			if (this.selectedIdList.length > 0) {
				return;
			}

			// 상담사 선택 갯수 제한
			if (this.maximumSelectionLength !== 0 && e.selectedRowKeys.length > this.maximumSelectionLength) {
				e.component.deselectRows(e.currentSelectedRowKeys);
				this.$_Msg(`최대 ${this.maximumSelectionLength}명까지 선택 가능합니다.`);
			}
		},
		/** @description : 상담사 클릭 이벤트  */
		onRowClick(e) {
			let keys = e.component.getSelectedRowKeys();
			let index = keys.indexOf(e.key);

			if (index > -1) {
				keys.splice(index, 1);
			} else {
				keys.push(e.key);
			}

			e.component.selectRows(keys);
		},
		/** @description : 상담사 셀 호버 이벤트  */
		onCellHoverChanged(e) {
			const event = e.event;
			if (event.buttons === 1) {
				if (this.isSelectionStopped) {
					this.isSelectionStopped = false;
					this.selectedRange = {};
				}

				if (this.selectedRange.startRowIndex === undefined) {
					this.selectedRange.startRowIndex = e.rowIndex;
				}

				if (!this.selectedRange.startColumnIndex) {
					this.selectedRange.startColumnIndex = e.columnIndex;
				}

				this.selectedRange.endRowIndex = e.rowIndex;
				this.selectedRange.endColumnIndex = e.columnIndex;

				let start = Math.min(this.selectedRange.startRowIndex, this.selectedRange.endRowIndex);
				let end = Math.max(this.selectedRange.startRowIndex, this.selectedRange.endRowIndex);

				let indexes = [];
				for (let i = start; i <= end; i++) {
					indexes.push(i);
				}
				e.component.selectRowsByIndexes(indexes);
			} else {
				this.isSelectionStopped = true;
			}
		},
		/** @description : 권한 데이터 전체 조회 **/
		async getAuthIdList() {
			const payload = {
				actionName: 'AUTH_LIST',
				loading: false,
			};

			return this.CALL_API(payload)
				.then(res => {
					if (isSuccess(res)) {
						return res.data.data;
					}
					return [];
				})
				.catch(err => {
					console.log(err);
					this.$_Toast('권한 데이터 조회 오류');
					return [];
				});
		},
		/** @description : 계정권한 이름 설정 **/
		setAuthNm(rowData) {
			const authId = rowData.authId;
			const authNm = this.authIdList.find(auth => auth.id === authId);
			return authNm ? authNm.authNm : '';
		},
		// onRowSelected(e) {
		//   let rowIndex = e.rowIndex;
		//
		//   console.log('this.selectedIndexes', this.selectedIndexes);
		//   let index = this.selectedIndexes.indexOf(rowIndex);
		//
		//   if (index !== -1) {
		//     this.selectedIndexes.splice(index, 1);
		//   } else {
		//     this.selectedIndexes.push(rowIndex);
		//   }
		// }
	},
	created() {},
	async mounted() {
		await this.selectDataList();
		this.getAuthIdList().then(res => {
			this.authIdList = res;
		});
	},
};
</script>

<style lang="scss" scoped>
// 드래그 방지 CSS
div {
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none
}
</style>
