export default {
  MESSAGE: {
    DUPLICATE_NAME: 'This name is already registered.',
    CFM_UPDATE_INFO: 'Would you like to edit the information?',
    CFM_DELETE_SELECTED_WITH_CHILD: `Are you sure you want to delete the selected data?<br/>Child data will also be deleted.`,
    START_DATE_OUT_OF_RANGE: 'Start date must be less than or equal to end date.',
    END_DATE_OUT_OF_RANGE: 'The end date must be greater than or equal to the start date.',
    START_TIME_OUT_OF_RANGE: 'Start time must be less than or equal to end time.',
    END_TIME_OUT_OF_RANGE: 'The end time must be greater than or equal to the start time.\n',
  },
  WORD: {
    NREASONID: 'NReason Id',
    NREASONCODE: 'NReason Code',
    NREASONTEXT: 'NReason Text',
    DESCRIPTION: 'Description',
    SKL: 'Skill',
    SKL_ID: 'Skill ID',
    SKL_NAME: 'Skill Name',
    TYPE: 'Type',
    PRIORITY: 'Priority',
    AGTID: 'Agent ID',
    AGTNAME: 'Agent Name',
    AGTTYPE: 'Agent Type',
    AGTTEAM: 'Agent Team',
  },
};
