export default {
  MESSAGE: {
    WARNING_DELETE_PROJECT: 'When deleting a project, all data is deleted together.<br>Do you want to delete?',
    UNSAVED_PROMPT_CHANGE_ALERT: 'Your prompt work has not been saved. <br>Do you want to change it?',
    UNSAVED_PROMPT_MOVE_ALERT: 'Your prompt work has not been saved. <br>Do you want to move?',
    NEW_PROMPT_ADD_ALERT: 'A new prompt is created without modifying the selected prompt.',
    NEW_PROMPT_NAME_ALERT: 'Enter a title for the new prompt.',
    ENCRYPTED_INDEX_FILE: 'For security reasons, password-protected files cannot be uploaded. Please unlock the password and try again.',
    SUPPORTED_INDEX_FILE: 'Supported file formats : {extension}',
    DUPLICATE_INDEX_FILE: 'Duplicate file is already registered.',
    DELETE_INDEX_FILE_ALERT1: 'When deleting an index file, the supporting documents used in the query are also deleted.',
    DELETE_INDEX_FILE_ALERT2: 'Do you want to delete the selected {value} files?',
    DUPLICATE_MODEL: 'A model with the same conditions already exists.',
    UNSET_PROMPT: 'No prompt is selected.',
    UNSET_CONFIG: 'Setting value does not exist.',
    PLEASE_ADD_INDEX: 'There are no registered indexes. Please add a new index.',
    DONT_EXIST_FILE: 'There are no supporting documents to select.',
    DONT_EXITS_SEARCH_RESULT: 'No search results exist\n.',
    UNSELECT_SEARCH_DOCUMENT_ALERT:
      'When document search is canceled, the supporting documents are initialized. <br/>Do you want to turn it off?',
    INPUT_1_TO_2000: 'Only integers from 1 to 2000 can be entered.',
    RUNNING_TEST_ALERT1: 'The testing process may take some time.',
    RUNNING_TEST_ALERT2: 'Do you want to run the test?',
    CANCEL_TEST_ALERT: 'Are you sure you want to cancel this test?',
    SUC_CANCEL: 'Canceled successfully.',
    EXIST_PLAYGROUND_SESSION: 'Playground is currently unavailable because another user is working on it.',
    DO_ENTER_QUERY: 'Enter your question to use Playground',
    RESET_SESSION_ALERT: 'When you reset the session, all current questions and answers will be deleted.<br/> Do you really want to reset?',
    DISCONNECT_SESSION_ALERT: 'The Playground session has ended. <br/>Please re-enter the page to use.',
  },
  WORD: {
    PROJECT: 'Project',
    PROJECT_ID: 'Project ID',
    WORK_CONFIG: 'Work Config',
    WORK: 'Work',
    RUNNING_TEST: 'Running Test',
    GO_TO_LIST: 'GoTo List',
    TEST_WORK: 'Test Work',
    DETAIL: 'Detail',
    PROMPT: 'Prompt',
    SYSTEM_PROMPT: 'System Prompt',
    EVALUATION_PROMPT: 'Evaluation Prompt',
    PROMPT_ADD: 'Add New Prompt',
    SYSTEM_PROMPT_ADD: 'Add New System Prompt',
    EVALUATION_PROMPT_ADD: 'Add New Evaluation Prompt',
    SELECT_PROMPT: 'Select Prompt',
    QUERY_CONFIG: 'Query Config',
    INDEX_CONFIG: 'Index Config',
    INSERT_DATE: 'Insert Date',
    PARSING_RESULT: 'Parsing Result',
    QUERY_TYPE: 'Category',
    QUERY: 'Query',
    CORRECT_ANSWER: 'Correct Answer',
    SUPPORTING_DOCUMENT: 'Supporting Documnet',
    SEARCH_DOCUMENT: 'Searching Documnet',
    REPEAT_COUNT: 'Repeat Count',
    SYNTHETIC_OPTION: 'Synthetic Option',
    SUBSTANTIVE_SYNONYM: 'Substantive Synonym',
    SUBSTANTIVE_TYPO: 'Substantive Typo',
    PREDICATE_SYNONYM: 'Predicate Synonym',
    PREDICATE_TYPO: 'Predicate Typo',
    PREDICATE_ENDING: 'Predicate Ending',
    SYNTHETIC_COUNT: 'Synthetic Count',
    QUERY_ADD: 'Add New Query',
    QUERY_UPDATE: 'Update Query',
    COPY_ADD: 'New Add',
    CONTENT: 'Content',
    MODEL_CONFIG: 'Model Config',
    MODEL: 'Model',
    RUN: 'Run',
    TEST_NAME: 'Test Name',
    STATUS: 'Status',
    CANCEL_TEST: 'Cancel Test',
    RESULT: 'Result',
    TEST_END_DATE_TIME: 'Test Ended Date',
    RETURN: 'Return',
    RESET_SESSION: 'Reset Session',
    SESSION_ID: 'Session ID',
    SEARCH_FLAG: 'Search Flag',
    SELECT_PROJECT: 'Select Project',
    ENTER_QUERY: 'Enter Query',
    SHOW_PROMPT: 'Show Prompt',
    DOCUMENT_NAME: 'Document Name',
    CHUNK_ORDER: 'Chunk Order',
    SHOW_DOCUMENT: 'Show Document List',
    CLOSE_DOCUMENT: 'Close Document List',
    SUPPORTING_DOCUMENT_NAME: 'Document Name',
  },
};
