<template>
  <div>
    <div class="page-sub-box ewm">
      <div class="locker_setting_list sub_new_style01 sub_ui_box1">
        <div class="page_search_box line_bottom_1px">
          <div class="flex space-x-2 inner">
            <div class="mt-2">기준년월</div>
            <DxDateBox
              :edit-enabled="false"
              :styling-mode="stylingMode"
              width="100"
              height="30"
              v-model="searchType.customTypes.dayStart"
              type="date"
              dateSerializationFormat="yyyyMMdd"
              display-format="yyyy.MM"
              dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
              :max="searchType.customTypes.dayEnd"
              maxLength="7"
              invalid-date-message="입력 데이터가 유효하지 않습니다."
              :calendar-options="{
                zoomLevel: 'year',
                minZoomLevel: 'year',
                maxZoomLevel: 'year',
              }"
              @value-changed="onDayStartChanged"
            >
              <DxValidator validation-group="validationSearch">
                <DxRequiredRule message="생성일자 기간은 필수입니다." />
              </DxValidator>
            </DxDateBox>
            <div class="mt-1">~</div>
            <DxDateBox
              :styling-mode="stylingMode"
              width="100"
              height="30"
              v-model="searchType.customTypes.dayEnd"
              type="date"
              dateSerializationFormat="yyyyMMdd"
              display-format="yyyy.MM"
              dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
              maxLength="7"
              :min="searchType.customTypes.dayStart"
              invalid-date-message="입력 데이터가 유효하지 않습니다."
              :calendar-options="{
                zoomLevel: 'year',
                minZoomLevel: 'year',
                maxZoomLevel: 'year',
              }"
              @value-changed="onDayEndChanged"
            >
              <DxValidator validation-group="validationSearch">
                <DxRequiredRule message="생성일자 기간은 필수입니다." />
              </DxValidator>
            </DxDateBox>
            <DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="selectDataList" />
          </div>
        </div>
      </div>

      <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" />

      <DxPopup
        :show-title="true"
        :title="modal.initData ? modal.initData.title : null"
        :min-width="modal.initData ? modal.initData.width : null"
        :width="modal.initData ? modal.initData.width : null"
        :min-height="modal.initData ? modal.initData.height : null"
        :height="modal.initData ? modal.initData.height : null"
        :drag-enabled="true"
        :resize-enabled="true"
        :show-close-button="true"
        :hide-on-outside-click="false"
        v-model="modal.isOpened"
        :visible="modal.isOpened"
        @hiding="isOpenModal(false)"
      >
        <template #content>
          <div>
            <component :is="modal.currentComponent" :contentData="modal.contentData" v-model="modal.contentData"></component>
          </div>
        </template>
        <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          :visible="modal.saveButtonVisible"
          :options="{
            elementAttr: {
              class: 'default filled txt_S medium',
            },
            text: modal.initData.hasOwnProperty('buttons')
              ? modal.initData.buttons.hasOwnProperty('save')
                ? modal.initData.buttons.save.text
                : ''
              : '',
            width: '120',
            height: '40',
            onClick: this.onConfirmModal,
          }"
        />
        <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          :visible="
            modal.initData.hasOwnProperty('buttons')
              ? modal.initData.buttons.hasOwnProperty('cancel')
                ? modal.initData.buttons.hasOwnProperty('cancel')
                : !modal.initData.buttons.hasOwnProperty('cancel')
              : false
          "
          :options="{
            elementAttr: {
              class: 'white filled txt_S medium',
            },
            text: modal.initData.hasOwnProperty('buttons')
              ? modal.initData.buttons.hasOwnProperty('cancel')
                ? modal.initData.buttons.cancel.text
                : ''
              : '',
            width: '120',
            height: '40',
            onClick: this.isOpenModal,
          }"
        />
      </DxPopup>
    </div>
  </div>
</template>

<script>
  import { DxDateBox } from 'devextreme-vue/date-box';
  import { DxButton } from 'devextreme-vue/button';
  import { DxColumn, DxDataGrid, DxSelection } from 'devextreme-vue/data-grid';
  import {formatDate, getPastFromToday, isSuccess} from '@/plugins/common-lib';
  import { DxPopover } from 'devextreme-vue/popover';
  import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
  import { DxRequiredRule, DxValidator } from 'devextreme-vue/validator';
  import validationEngine from 'devextreme/ui/validation_engine';
  import ModalPersonalFeedback from '@/pages/ewm/personal/education/modal-personal-feedback.vue';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  let vm = this;

  export default {
    components: {
      EspDxDataGrid,
      DxDateBox,
      DxButton,
      DxDataGridx: DxDataGrid,
      DxSelection,
      DxColumn,
      DxPopover,
      DxPopup,
      DxToolbarItem,
      DxRequiredRule,
      DxValidator,
      ModalPersonalFeedback,
    },
    props: {},
    watch: {},
    data() {
      return {
        teacherSelectionChanged: false,
        stylingMode: 'outlined', //outlined, underlined, filled
        searchType: {
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            dayStart: getPastFromToday(11, 'months'),
            dayEnd: getPastFromToday(0, 'days'),
          },
          paramsData: null,
        },
        modal: {
          isOpened: false,
          currentComponent: null,
          initData: {},
          contentData: null,
          saveButtonVisible: false,
        },
        codes: {
          placeCd: {
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
          processCd: {
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
          divisionCd: {
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
          typeCd: {
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
          joinCd: {
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
        },
        dataGrid: {
          keyExpr: 'subjectRegId',
          refName: 'educationProgress',
          showMenuColumns: true, //컬럼 표시
          allowColumnResizing: true, //컬럼 사이즈 허용
          columnResizingMode: 'widget',
          columnAutoWidth: true,
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false, //행 배경색
          hoverStateEnabled: true, //마우스커서 행 강조
          dataSource: [],
          showActionButtons: {},
          customEvent: {},
          callApi: 'CALL_EWM_API',
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          scrolling: {
            // 미사용시 주석처리
            mode: 'standard', //스크롤 모드 : ['infinite', 'standard', 'virtual']
          },
          remoteOperations: {
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: false,
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [5, 10, 15, 20], //페이지 사이즈 선택 박스
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: false,
            allowDeleting: false,
            allowAdding: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: 'No',
              dataField: 'eduOrd',
              alignment: 'center',
              width: 40,
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              allowFiltering: false,
              allowSorting: true,
            },
            {
              caption: '교육계획명',
              dataField: 'scheNm',
              alignment: 'left',
              visible: true,
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: false,
              allowSorting: true,
            },
            {
              caption: '교육강의명',
              dataField: 'eduNm',
              alignment: 'left',
              visible: true,
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: false,
              allowSorting: true,
            },
            {
              caption: '강의일',
              dataField: 'eduDt',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: false,
              allowSorting: true,
              width: 110,
              calculateCellValue: rowData => {
                return `${formatDate(rowData.eduDt, 'YYYY-MM-DDTHH:mm:ss', 'YYYY.MM.DD')}`;
              },
            },
            {
              caption: '강의시간',
              dataField: 'subjectTime',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              allowFiltering: true,
              allowSorting: true,
              width: 100,
              calculateCellValue: rowData => {
                return `${this.formTime(rowData.subjectTimeStart)} ~ ${this.formTime(rowData.subjectTimeEnd)}`;
              },
            },
            {
              caption: '교육장소',
              dataField: 'placeCd',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: true,
              allowFiltering: true,
              allowSorting: true,
              width: 120,
              lookup: {},
            },
            {
              caption: '진행상태',
              dataField: 'processCd',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: true,
              allowFiltering: true,
              allowSorting: true,
              width: 140,
              calculateSortValue: data => this.$_enums.ewm.eduEducationProcess.values.find(e => data.processCd === e.value).label,
              lookup: {
                dataSource: this.$_enums.ewm.eduEducationProcess.values,
                valueExpr: 'value',
                displayExpr: 'label',
              },
            },
            {
              caption: '진행방식',
              dataField: 'divisionCd',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: true,
              allowFiltering: true,
              allowSorting: true,
              width: 110,
              calculateSortValue: data => this.$_enums.ewm.eduEducationProcessDivision.values.find(e => data.divisionCd === e.value).label,
              lookup: {
                dataSource: this.$_enums.ewm.eduEducationProcessDivision.values,
                valueExpr: 'value',
                displayExpr: 'label',
              },
            },
            {
              caption: '강의유형',
              dataField: 'typeCd',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: true,
              allowFiltering: true,
              allowSorting: true,
              width: 130,
              calculateSortValue: data => vm.calculateSortValue(data, 'typeCd'),
              lookup: {},
            },
            {
              caption: '강사',
              dataField: 'teachers',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              allowFiltering: false,
              allowSorting: false,
              width: 120,
              cellTemplate: (container, options) => {
                if (!options.value) return;
                let innerText = '';
                let popoverText = '';
                let spanTag = document.createElement('span');

                if (options.value.length === 1) {
                  innerText = `${options.value[0].teacherNm}[${options.value[0].teacherId}]`;
                  popoverText = `<li>${options.value[0].teacherNm}[${options.value[0].teacherId}]</li>`;
                } else if (options.value.length > 1) {
                  innerText = `${options.value.length}명`;
                  options.value.forEach(val => {
                    popoverText += `<li>${val.teacherNm}[${val.teacherId}]</li>`;
                  });
                }
                spanTag.innerText = innerText;

                const dxPopover = new DxPopover({
                  propsData: {
                    width: 'auto',
                    showEvent: 'mouseenter',
                    hideEvent: 'mouseleave',
                    target: container,
                    position: 'top',
                    contentTemplate: `<ul>${popoverText}</ul>`,
                  },
                });

                dxPopover.$mount();
                container.append(spanTag);
                container.append(dxPopover.$el);
              },
            },
            {
              caption: '교육자료',
              dataField: 'fileGroupId',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowFiltering: false,
              allowHeaderFiltering: false,
              allowSorting: false,
              width: 90,
              cellTemplate: (container, options) => {
                if (options.value) {
                  let button = new DxButton({
                    propsData: {
                      text: '',
                      elementAttr: { class: 'download' },
                      width: 30,
                      height: 30,
                      value: options.value,
                      onClick: () => {
                        vm.onDataDownload(options.value);
                      },
                    },
                  });
                  button.$mount();
                  container.append(button.$el);
                }
              },
            },
            {
              caption: '참여정보',
              dataField: 'joinCd',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: false,
              allowSorting: true,
              width: 90,
              calculateSortValue: data => this.$_enums.ewm.join.values.find(e => data.joinCd === e.value).label,
              lookup: {
                dataSource: this.$_enums.ewm.join.values,
                valueExpr: 'value',
                displayExpr: 'label',
              },
            },
            {
              caption: '교육',
              dataField: 'viewResult',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowFiltering: false,
              allowHeaderFiltering: false,
              allowSorting: false,
              width: 60,
              cellTemplate: (container, options) => {
                let eduButton = new DxButton({
                  propsData: {
                    text: '',
                    icon: 'search',
                    elementAttr: { class: 'white light_filled txt_S' },
                    value: options.data,
                    onClick: () => {
                      vm.onParticipateEdu(options.data);
                    },
                  },
                });
                eduButton.$mount();

                let feedbackButton = new DxButton({
                  propsData: {
                    text: '',
                    icon: 'search',
                    elementAttr: { class: 'white light_filled txt_S' },
                    value: options.data,
                    onClick: () => {
                      vm.onClickFeedback(options.data);
                    },
                  },
                });
                feedbackButton.$mount();

                if (
                  options.data.processCd === this.$_enums.ewm.eduEducationProcess.PROGRESS.value &&
                  options.data.joinCd === this.$_enums.ewm.join.NO.value
                ) {
                  container.append(eduButton.$el);
                } else if (
                  options.data.processCd === this.$_enums.ewm.eduEducationProcess.FEEDBACK.value ||
                  options.data.processCd === this.$_enums.ewm.eduEducationProcess.FEEDBACK_COMPLETE.value
                ) {
                  if (options.data.joinCd === this.$_enums.ewm.join.YES.value) {
                    container.append(feedbackButton.$el);
                  } else {
                    container.innerHTML = '-';
                  }
                } else {
                  container.innerHTML = '-';
                }
              },
            },
          ],
        },
      };
    },
    methods: {
      /** @description : 소트설정 */
      calculateSortValue(data, clsCd) {
        if (clsCd === 'typeCd') {
          return this.codes.typeCd.dataSource.find(e => data.typeCd === e.codeId).codeNm;
        }
      },
      /** @description : 교육참가 버튼 */
      async onParticipateEdu(data) {
        if (await this.$_Confirm('교육에 참석 하시겠습니까?', { title: `<h2>교육진행정보</h2>` })) {
          let params = {
            subjectRegId: data.subjectRegId,
            agtId: data.agtId,
            joinCd: this.$_enums.ewm.join.YES.value,
          };
          const payload = {
            actionName: 'EWM_PERSONAL_EDUCATION_CHANGE_JOIN',
            data: params,
            loading: true,
          };
          const res = await this.CALL_EWM_API(payload);
          if (isSuccess(res)) {
            this.$_Toast(this.$_lang('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
            await this.selectDataList();
          } else {
            this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          }
        }
      },
      /** @description : 피드백 버튼 */
      onClickFeedback(data) {
        this.modal.saveButtonVisible = data.processCd === this.$_enums.ewm.eduEducationProcess.FEEDBACK.value;

        this.onOpenModal(
          'ModalPersonalFeedback',
          {
            title: '피드백 보기',
            buttons: {
              save: { text: '저장' },
              cancel: { text: '닫기' },
            },
            width: 600,
            height: 600,
          },
          data,
        );
      },
      onOpenModal(componentNm, componentInitData, data) {
        this.modal.currentComponent = componentNm;
        this.modal.initData = componentInitData;
        this.modal.contentData = data;

        this.isOpenModal(true);
      },
      /** @description: 팝업 오픈 체크 메서드 */
      isOpenModal(data) {
        this.modal.isOpened = data;
        if (!data) {
          this.modal.currentComponent = null;
          this.modal.initData = {};
        }
      },
      /** @desc: 모달창이 close될 때, modal 객체 설정 값 초기화 */
      setInitModal() {
        this.modal.isOpened = false;
        this.modal.currentComponent = null;
        this.modal.initData = {};
      },
      /** @description: 피드백 저장 버튼 이벤트 */
      async onConfirmModal() {
        if (
          !(await this.$_Confirm('변경한 데이터를 저장하시겠습니까?', {
            title: `<h2>알림</h2>`,
          }))
        ) {
          return;
        }

        let params = {
          id: this.modal.contentData.targetId,
          feedbackRequest: this.modal.contentData.feedbakRequest,
        };

        const payload = {
          actionName: 'EWM_PERSONAL_EDUCATION_FEEDBACK_REQUEST_SAVE',
          data: params,
          loading: true,
        };
        const res = await this.CALL_EWM_API(payload);
        this.setInitModal();
        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
        }
      },
      /** @description : 교육자료 다운로드 */
      async onDataDownload(fileGroupId) {
        const fileList = await this.$_getListAttachFile(fileGroupId);

        if (!fileList || fileList.length === 0) {
          this.$_Msg('다운로드 할 파일이 없습니다.');
          return;
        }

        fileList.forEach(fileInfo => {
          this.$_downloadAttachFile(fileInfo.fileGroupId, fileInfo.fileName);
        });
      },
      onChangeSearchDay() {
        const regDt = this.searchType.customTypes.dayStart + '000000' + '~' + this.searchType.customTypes.dayEnd + '235959';
        this.searchType.paramsData = { ...this.searchType.paramsData, regDt };

        this.$_setSearchHistsCustomType(this.searchType.paramsData);
      },
      /** @description : 날짜 형식 변환 메서드 */
      formatDt(rowData) {
        if (rowData?.surDt) {
          return formatDate(rowData.surDt, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
        }
      },
      formTime(timeString) {
        const hours = timeString.substr(0, 2);
        const minutes = timeString.substr(2, 2);

        return `${hours}:${minutes}`;
      },
      /** @description : 데이터 검색 메서드 */
      async selectDataList() {
        if (!validationEngine.validateGroup('validationSearch').isValid) {
          return;
        }

        this.dataGrid.dataSource = new CustomStore({
          key: 'subjectRegId',
          async load(loadOptions) {
            let params = vm.$_getDxDataGridParam(loadOptions);

            vm.searchType.paramsData['frScheYmd'] = vm.searchType.customTypes.dayStart;
            vm.searchType.paramsData['toScheYmd'] = vm.searchType.customTypes.dayEnd;

            params = { ...params, ...vm.searchType.paramsData };

            const payload = {
              actionName: 'EWM_PERSONAL_EDUCATION',
              data: params,
              loading: false,
              useErrorPopup: true,
            };

            let rtnData = {
              data: [],
              totalCount: 0,
            };
            const res = await vm.CALL_EWM_API(payload);

            if (isSuccess(res)) {
              let data = res.data.data;
              data.forEach((d, index) => {
                d.eduOrd = index + 1;
              });
              rtnData = {
                data: data,
                totalCount: res.data.header.totalCount,
              };
            }
            return rtnData;
          },
        });
      },
      onDayStartChanged(e) {
        this.searchType.customTypes.dayStart = e.value.slice(0, 6) + '01';
      },
      onDayEndChanged(e) {
        this.searchType.customTypes.dayEnd = e.value.slice(0, 6) + '29';
      },
      /** @description : 셀렉트 박스 초기화 */
      async initCodeMap() {
        this.codeMap = await this.$_getCodeMapList(
          'root_ewm_edu_education_place,root_ewm_edu_education_process,root_ewm_edu_education_process_division,root_ewm_edu_education_type,root_join',
        );
      },
    },
    created() {
      vm = this;
    },
    async mounted() {
      await this.selectDataList();
      this.initCodeMap().then(() => {
        this.codes.placeCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_edu_education_place'], 2);
        this.codes.typeCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_edu_education_type'], 2);
        this.$refs.educationProgress.getInstance.columnOption('placeCd', 'lookup', this.codes.placeCd);
        this.$refs.educationProgress.getInstance.columnOption('typeCd', 'lookup', this.codes.typeCd);
      });
      this.onChangeSearchDay();
    },
  };
</script>

<style scoped>
  .sub_new_style01 .page_search_box .inner div {
    display: inline-block;
  }

  .sub_new_style01 .page_search_box .inner > div {
    vertical-align: middle;
  }
</style>
