export default {
  MESSAGE: {
    DUPLICATE_NAME: '이미 등록되어 있는 명칭입니다.',
    CFM_UPDATE_INFO: '정보를 수정하시겠습니까?',
    CFM_DELETE_SELECTED_WITH_CHILD: '선택한 데이터를 삭제하시겠습니까?<br/>하위 데이터도 함께 삭제됩니다.',
    START_DATE_OUT_OF_RANGE: '시작일은 종료일보다 작거나 같아야 합니다.',
    END_DATE_OUT_OF_RANGE: '종료일은 시작일보다 크거나 같아야 합니다.',
    START_TIME_OUT_OF_RANGE: '시작시간은 종료시간보다 작거나 같아야 합니다.',
    END_TIME_OUT_OF_RANGE: '종료시간은 시작시간보다 크거나 같아야 합니다.',
  },
  WORD: {
    NREASONID: '이석코드 아이디',
    NREASONCODE: '이석코드',
    NREASONTEXT: '이석 텍스트',
    DESCRIPTION: '설명',
    SKL: '스킬',
    SKL_ID: '스킬ID',
    SKL_NAME: '스킬명',
    TYPE: '타입',
    PRIORITY: '우선순위',
    AGTID: '상담원',
    AGTNAME: '상담원 이름',
    AGTTYPE: '상담원 유형',
    AGTTEAM: '팀 정보',
  },
};