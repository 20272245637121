var render = function render(){var _vm=this,_c=_vm._self._c;return _c('dx-popup',{attrs:{"visible":_vm.isOpen,"show-title":_vm.propOption.showTitle,"title":_vm.propOption.title,"min-width":_vm.propOption.minWidth,"width":_vm.propOption.width,"min-height":_vm.propOption.minHeight,"height":_vm.propOption.height,"resize-enabled":_vm.propOption.resizeEnabled,"drag-enabled":_vm.propOption.dragEnabled,"show-close-button":_vm.propOption.showCloseButton,"hide-on-outside-click":_vm.propOption.closeOnOutsideClick},on:{"hiding":_vm.handleCloseModal}},[_c('div',{staticClass:"dx-fieldset"},[_c('div',{staticClass:"dx-field"},[_c('div',{staticClass:"dx-field-label"},[_vm._v("선택한 메뉴명")]),_c('div',{staticClass:"dx-field-value"},[_vm._v(" "+_vm._s(_vm.formData.menuNm)+" ")])]),_c('div',{staticClass:"dx-field"},[_c('div',{staticClass:"dx-field-label"},[_vm._v("하위메뉴 복사")]),_c('div',{staticClass:"dx-field-value"},[_c('dx-check-box',{model:{value:(_vm.formData.isCopyChildren),callback:function ($$v) {_vm.$set(_vm.formData, "isCopyChildren", $$v)},expression:"formData.isCopyChildren"}})],1)])]),_c('dx-toolbar-item',{attrs:{"widget":"dxButton","toolbar":"bottom","location":"center","options":{
      elementAttr: {
        class: 'default filled txt_S medium',
      },
      text: _vm.propOption.saveBtnTxt,
      width: '120',
      height: '40',
      onClick: e => _vm.handleSaveModal(e),
    }}}),_c('dx-toolbar-item',{attrs:{"widget":"dxButton","toolbar":"bottom","location":"center","options":{
      elementAttr: {
        class: 'white filled txt_XS medium',
      },
      text: _vm.propOption.cancelBtnTxt,
      width: '120',
      height: '40',
      onClick: e => _vm.handleCloseModal(e),
    }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }