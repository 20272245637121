import { EventBus } from '@/event-bus';

/**
 * ESP 디버깅 로거 (운영 환경에서는 제외)
 *
 * @param actionName API 호출 시 사용하는 actionName
 * @param response ESP API 응답 결과
 */
export const responseConsoleLogger = (actionName, response) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log('[DEBUG] CALL ESP API > ' + actionName, {
      actionName: actionName,
      status: response.status,
      resCode: response.data?.header?.resCode,
      resMsg: response.data?.header?.resMsg,
      resDt: response.data?.header?.resDt,
      totalCount: response.data?.header?.totalCount,
      totalPages: response.data?.header?.totalPages,
      baseURL: response.config.baseURL,
      data: response.data.data,
    });
  }
};

/**
 * ESP 규격에 맞추지 않은 응답에 대한 디버깅 로거 (운영 환경에서는 제외)
 *
 * @param actionName
 * @param response
 */
export const otherResponseConsoleLogger = (actionName, response) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log('[DEBUG] CALL API > ' + actionName, response);
  }
};

/**
 * ESP 오류 팝업 처리
 *
 * @param error
 * @param payload
 */
export const errorPopup = (error, payload) => {
  const useErrorPopup = payload?.useErrorPopup ?? true; // null 또는 undefined 일 경우 기본값 true

  if (useErrorPopup) {
    EventBus.$emit('app:errMsgOn', [error.response?.data?.header?.resMsg ?? 'API 호출 오류.']);
  }
};
