<!--
  PACKAGE_NAME : src\pages\esp\user\profile.vue
  FILE_NAME : profile
  AUTHOR : devyoon91
  DATE : 2024-08-29
  DESCRIPTION : 유저 프로필
-->
<template>
  <div class="profile_holder">
    <div class="profile_header"></div>
    <div id="profile_change_info" class="profile_content">
      <template v-for="key in settingOrder">
        <div :key="key" v-if="isShowField(key)" class="profile_content_row">
          <div class="profile_content_row_header">
            <span :class="[{ required: isRequiredField(key) }]">{{ settingColumns[key].displayName }}</span>
            <esp-dx-popover
              v-if="settingColumns[key].type === 'password' && defaultSetColumn.changePwd.visible"
              target="tooltips"
              :html="config.changePwdTooltipTxt"
            />
          </div>
          <div class="profile_content_row_content">
            <template v-if="settingColumns[key].change">
              <DxTextBox
                class="profile_input vertical-align-middle"
                v-model="changeInfo[key]"
                :disabled="settingColumns[key].disable"
                :mode="settingColumns[key].type"
                :styling-mode="stylingMode"
                :width="settingColumns[key].width"
                :height="settingColumns[key].height"
                :maxLength="settingColumns[key].type === 'password' ? 10 : null"
                :placeholder="settingColumns[key].placeholder"
              >
                <DxValidator validation-group="profileValidate" v-if="isShowField(key)">
                  <DxRequiredRule v-if="isRequiredField(key)" :trim="true" :message="requireMsg(key)" />
                  <DxCustomRule
                    v-if="settingColumns[key].type === 'password' && key === 'changePwd'"
                    :reevaluate="false"
                    :validation-callback="e => validatePassword(key, e)"
                    :message="getValidationMessage(key)"
                  >
                  </DxCustomRule>
                </DxValidator>
              </DxTextBox>
              <template v-if="settingColumns[key].type === 'password'">
                <DxButton
                  class="btn_XS white light_filled margin-left-default"
                  :text="key === 'password' ? '패스워드 변경' : '취소'"
                  :height="settingColumns[key].height"
                  @click="() => togglePassword(key)"
                />
              </template>
            </template>
            <template v-else>
              <span :width="settingColumns[key].width" :height="settingColumns[key].height">{{ showInfo[key] }}</span>
            </template>
            <!-- 패스워드일때는 버튼 추가됨.-->
          </div>
        </div>
        <!-- 중복체크만 하는애 -->
        <div :key="key + '_same'" v-if="isShowField(key) && isCheckSameField(key)" class="profile_content_row">
          <div class="profile_content_row_header">
            <span :class="[{ required: isRequiredField(key) }]">{{ settingColumns[key].displayName + ' 확인' }}</span>
          </div>
          <div class="profile_content_row_content">
            <DxTextBox
              class="vertical-align-middle"
              v-model="sameCheckInfo[key]"
              :disabled="settingColumns[key].disable"
              :mode="settingColumns[key].type"
              :styling-mode="stylingMode"
              :width="settingColumns[key].width"
              :height="settingColumns[key].height"
              :maxLength="settingColumns[key].type === 'password' ? 10 : null"
            >
              <DxValidator validation-group="profileValidate" v-if="isShowField(key)">
                <DxRequiredRule v-if="isRequiredField(key)" :trim="true" :message="requireMsg(key)" />
                <DxCompareRule
                  v-if="settingColumns[key].type === 'password' && key === 'changePwd'"
                  :comparison-target="() => compareSameValue(key)"
                  :ignoreEmptyValue="true"
                  :message="sameCheckMsg(key)"
                />
              </DxValidator>
            </DxTextBox>
          </div>
        </div>
      </template>
    </div>

    <!--국세청전용 팝업-->
    <DxPopup
      :show-title="true"
      :title="modal.initData ? modal.initData.title : null"
      :min-width="modal.initData ? modal.initData.width : null"
      :width="modal.initData ? modal.initData.width : null"
      :min-height="modal.initData ? modal.initData.height : null"
      :height="modal.initData ? modal.initData.height : null"
      :drag-enabled="true"
      :resize-enabled="true"
      :show-close-button="true"
      :hide-on-outside-click="false"
      :visible="modal.isOpened"
      @hiding="isOpenModal(false)"
    >
      <template #content>
        <div>
          <component :is="modal.currentComponent" :contentData="modal.contentData" v-model="modal.contentData"> </component>
        </div>
      </template>

      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="modal.initData.hasOwnProperty('buttons')"
        :options="{
          elementAttr: {
            class: 'default filled txt_S medium',
          },
          text: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('save')
              ? modal.initData.buttons.save.text
              : ''
            : '',
          //type: 'default',
          width: '120',
          height: '40',
          useSubmitBehavior: true,
          onClick: e => {
            onConfirmModal(e);
          },
        }"
      />
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="modal.initData.hasOwnProperty('buttons')"
        :options="{
          elementAttr: {
            class: 'white filled txt_S medium',
          },
          text: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('cancel')
              ? modal.initData.buttons.cancel.text
              : ''
            : '',
          width: '120',
          height: '40',
          onClick: () => {
            isOpenModal(false);
          },
        }"
      />
    </DxPopup>
  </div>
</template>

<script>
  import ModalSelectDept from '@/pages/euc/organize/personal/modal-select-dept.vue';
  import { isSuccess } from '@/plugins/common-lib';
  import { DxButton } from 'devextreme-vue/button';
  import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
  import { DxTextBox } from 'devextreme-vue/text-box';
  import { DxCompareRule, DxCustomRule, DxRequiredRule, DxValidator } from 'devextreme-vue/validator';
  import validationEngine from 'devextreme/ui/validation_engine';
  import EspDxPopover from "@/components/devextreme/esp-dx-popover.vue";

  export default {
    components: {
      ModalSelectDept,
      DxValidator,
      DxRequiredRule,
      DxCompareRule,
      DxCustomRule,
      DxTextBox,
      DxButton,
      DxPopup,
      DxToolbarItem,
      EspDxPopover,
    },
    props: {},
    watch: {},
    data() {
      return {
        changePwdFlag: false, // 패스워드 변경시 로그아웃 처리를 위한.
        pwdChConfirmMsg: '패스워드 변경 즉시, 새로운 패스워드로 재로그인해야합니다. <br/> 계속하시겠습니까?',
        stylingMode: 'outlined', //[outlined, filled, underlined]
        changeInfo: {},
        sameCheckInfo: {},
        showInfo: {},
        userInfo: {},
        defaultShowType: ['text', 'password'],
        defaultOrder: ['loginId', 'loginNm', 'password', 'changePwd', 'deptId'],
        defaultSetColumn: {
          loginId: {
            type: 'text',
            displayName: '아이디',
            visible: true,
            change: false,
            sameCheck: false,
            required: true,
            width: 200,
            height: 30,
          },
          loginNm: {
            type: 'text',
            displayName: '이름',
            visible: true,
            change: true,
            sameCheck: false,
            required: true,
            width: 200,
            height: 30,
          },
          password: {
            type: 'password',
            displayName: '패스워드',
            visible: true,
            change: true,
            disable: true,
            sameCheck: false,
            required: true,
            width: 200,
            height: 30,
            placeholder: '*********',
          },
          changePwd: {
            type: 'password',
            displayName: '새 패스워드',
            visible: false,
            change: true,
            sameCheck: true,
            required: true,
            width: 200,
            height: 30,
          }, // 추후 이벤트로 true로 변경

          // dept_info 의 경우 국세청 전용기능으로 셋팅함.
          deptId: {
            type: 'ntsdept',
            displayName: '부서',
            visible: true,
            change: true,
            sameCheck: false,
            required: false,
            width: 80,
            height: 28,
          },
        },
        settingOrder: [],
        settingColumns: {},
        //
        deptList: [],

        config: {
          pageSetting: {
            config: {},
          },
          changePwdTooltipTxt: '', // 패스워드 정책 문구
        },
        modal: {
          isOpened: false,
          currentComponent: null,
          initData: {},
          contentData: null,
        },
      };
    },
    computed: {},
    methods: {
      isShowField(key) {
        const fieldType = this.settingColumns[key].type ?? 'null';
        return this.defaultShowType.includes(fieldType) && this.settingColumns[key].visible;
      },
      isCheckSameField(key) {
        return this.settingColumns[key].sameCheck ?? false;
      },
      isRequiredField(key) {
        if (this.settingColumns[key].disable) {
          return false;
        }

        return this.settingColumns[key].required ?? false;
      },
      compareSameValue(key) {
        return this.changeInfo[key];
      },

      getValidationMessage(key) {
        return this.settingColumns[key].validationMessage ?? `[${this.settingColumns[key].displayName}] 의 값이 잘못 되었습니다.`;
      },

      requireMsg(key, sameFlag = false) {
        return `[${this.settingColumns[key].displayName + (sameFlag ? ' 확인' : '')}] 은/는 필수값 입니다.`;
      },
      sameCheckMsg(key) {
        return `[${this.settingColumns[key].displayName}] 와/과 같지 않습니다.`;
      },

      togglePassword(key) {
        this.settingColumns[key].visible = false;
        this.sameCheckInfo[key] = '';
        this.changeInfo[key] = '';
        key === 'password' ? (this.settingColumns['changePwd'].visible = true) : (this.settingColumns['password'].visible = true);
      },

      // 국세청 부서선택모달
      onOpenModal(componentNm, componentInitData) {
        this.modal.currentComponent = componentNm; //set dynamic component name in modal body slot
        this.modal.initData = componentInitData; //set init modal templet
        this.isOpenModal(true);
      },

      isOpenModal(data) {
        this.modal.isOpened = data;
        if (!data) {
          this.modal.currentComponent = null;
          this.modal.initData = {};
        }
      },

      onConfirmModal(e) {
        //해당 모달 컴포넌트에서 데이터 저장
        let promise = new Promise((resolve, reject) => {
          this.$_eventbus.$emit(`${this.modal.currentComponent}:onSaveData`, e, resolve, reject);
        });

        promise
          .then(res => {
            if (res.status === 200) {
              this.settingDeptInfo(res.rst.deptId);
              this.isOpenModal(false);
            } else {
              this.$_Msg('부서정보를 가져오는데 실패 했습니다.');
            }
          })
          .catch(err => {
            console.log('err', err);
            this.$_Msg('부서선택 중 오류가 발생했습니다.');
          });
      },

      getSelectedDept(deptId) {
        let deptInfo = this.deptList.find(d => d.id === deptId) ?? null;
        if (!deptInfo) {
          return;
        }

        let deptCodeInfo = this.deptList.find(d => d.id === deptId);
        return {
          deptShowNm: deptCodeInfo.nameTree.replaceAll('|', ' > '),
        };
      },
      async initSetting() {
        const [consecutiveLength, includeLoginId, includeLoginNm, minLength, maxLength, minCategories, specialChars] = this.$_getPwdSystemData();

        let messageId = 'COMMON.MESSAGE.PASSWORD_POLICY_NONE';
        if (includeLoginId && includeLoginNm) {
          messageId = 'COMMON.MESSAGE.PASSWORD_POLICY';
        } else if (includeLoginId) {
          messageId = 'COMMON.MESSAGE.PASSWORD_POLICY_ID';
        } else if (includeLoginNm) {
          messageId = 'COMMON.MESSAGE.PASSWORD_POLICY_NAME';
        }

        this.config.changePwdTooltipTxt = this.$_lang(messageId, {
          consecutiveLength,
          minLength,
          maxLength,
          minCategories,
          specialChars,
          defaultValue: `대문자, 소문자, 숫자, 특수문자 중 ${ minCategories }가지 이상 조합<br>
                        최소 ${ minLength }자 이상, 최대 ${ maxLength }자 이하<br>
                        연속된 문자나 숫자 ${ consecutiveLength }자 이상 사용 불가<br>
                        ${ includeLoginId ? '아이디 포함 불가' : '' }<br>
                        ${ includeLoginNm ? '이름 포함 불가' : '' }<br>
                        사용 가능 특수문자 ${ specialChars }<br>
                        예) Abc123!@#`,
        });

        this.setPageDataConfig(); // 페이지데이터 사용
        this.setColumnInfo(); // 페이지 데이터에서 가져온 내용이랑 default column 정보 합침

        // 유저정보, 부서정보 들고옴.
        const resultList = await this.callPromiseAllInitData();

        // 유저정보 + 초기보여주는 정보 셋팅
        this.initUserInfo(resultList[0]);
        this.settingShowInfoAndChangeInfo();
      },

      setPageDataConfig() {
        const menuObj = this.$store.getters.getMenuList.find(d => d.pageUrl === '/profile');
        const pageData = menuObj?.pageData;
        if (!pageData) {
          return;
        }

        const componentData = new Function('return ' + pageData).bind(this)();
        this.config.pageSetting = componentData ? componentData.pageSetting : null;
        this.config.pageSetting.config = this.config.pageSetting ? this.config.pageSetting.config : null;

        const pwdChConfirmMsg = this.config.pageSetting.config?.pwdChConfirmMsg ?? '';
        if (pwdChConfirmMsg) {
          this.pwdChConfirmMsg = pwdChConfirmMsg;
        }
      },

      initUserInfo(res) {
        this.changePwdFlag = false;

        if (isSuccess(res)) {
          this.userInfo = res.data.data[0];
        }
      },

      setColumnInfo() {
        // 1. 보일 칼럼 키 먼저 셋팅
        let keys = this.config.pageSetting.config?.displayOrder?.length
          ? [...this.config.pageSetting.config.displayOrder]
          : [...this.defaultOrder];

        // 실제 데이터 셋팅
        this.settingColumns = { ...this.defaultSetColumn };
        const displayColumns = this.config.pageSetting.config?.columnInfo;
        if (displayColumns) {
          for (const key in displayColumns) {
            if (Object.prototype.hasOwnProperty.call(this.defaultSetColumn, key)) {
              this.settingColumns[key] = { ...this.defaultSetColumn[key], ...displayColumns[key] };
            }
          }
        }

        // 다시하면 있는 애들만 보이게 셋팅
        this.settingOrder = Object.keys(this.settingColumns).filter(key => keys.includes(key));
      },

      // 애는 무조건 userInfo 에서 셋팅한다.
      settingShowInfoAndChangeInfo() {
        this.showInfo = {};
        this.changeInfo = {};
        for (const key in this.userInfo) {
          if (key !== 'password' && Object.prototype.hasOwnProperty.call(this.settingColumns, key)) {
            this.$set(this.showInfo, key, this.userInfo[key]);
            this.$set(this.changeInfo, key, this.userInfo[key]);
          }
        }
      },

      resetAllInfo() {
        this.changeInfo = {};
        this.sameCheckInfo = {};
        this.togglePassword('changePwd');
        this.settingShowInfoAndChangeInfo();

        // 국세청전용
        this.settingDeptInfo(this.userInfo.deptId);
      },

      // 국세청 전용
      settingDeptInfo(deptId) {
        if (!deptId) {
          return;
        }

        const deptInfo = this.getSelectedDept(deptId);

        if (deptInfo) {
          this.$set(this.showInfo, 'deptNm', deptInfo.deptShowNm);
          if (this.userInfo.deptId !== deptId) this.changeInfo.deptId = deptId;
        }
      },

      getHashPassword(password) {
        return this.$_commonlib.encryptPassword(this.$store.getters.getLoginId, password, this.$store.getters.getEncryptionType);
      },

      // save action
      checkChangeInfo() {
        const validationResult = validationEngine.validateGroup('profileValidate');
        if (!validationResult.isValid) {
          const firstError = validationResult.brokenRules[0];
          const result = this.$_Msg(firstError.message);
          result.then(() => {
            setTimeout(() => {
              firstError.validator.focus();
            }, 500);
          });

          return false;
        }

        return true;
      },
      // emit 시킬꺼
      async updateProfile() {
        if (!this.checkChangeInfo()) {
          return false;
        }

        // changePwd가 있으면 체크해서 처리 --> newPassword 로변경함.
        this.changePwdFlag = false;
        this.changeInfo['newPassword'] = this.changeInfo['changePwd'] ? this.getHashPassword(this.changeInfo['changePwd']) : null;

        if (this.changeInfo['newPassword']) {
          this.changePwdFlag = await this.$_Confirm(this.pwdChConfirmMsg);
          if (!this.changePwdFlag) {
            return false;
          }
        }

        const res = await this.callUpdateProfile(this.changeInfo);
        if (isSuccess(res)) {
          if (this.changeInfo.loginNm !== this.$store.getters.getLoginNm) {
            this.$store.commit('setLoginNm', this.changeInfo.loginNm);
          }
          this.$_Msg(this.$_lang('CMN_SUC_UPDATE', { defaultValue: '정상적으로 변경되었습니다.' }));
        } else {
          this.$_Msg(`정보수정 중 문제가 발생했습니다.\n${res.data.header.resMsg ?? ''}`);
          return false;
        }

        return true;
      },

      validatePassword(key, event) {
        const targetStr = this.changeInfo[key].toString();
        //시스템 설정값의 패스워드 정규식 체크
        const checkValueOrErrMsg = this.$_validatePasswordMessage(
          targetStr,
          this.$store.getters.getLoginId.toString(),
          this.$store.getters.getLoginNm.toString(),
        );

        if (checkValueOrErrMsg !== '') {
          event.rule.message = '유효하지 않는 패스워드 입니다.';
          return false;
        } else if (checkValueOrErrMsg === '') {
          event.rule.message = checkValueOrErrMsg;
          return false;
        }

        const hangulcheck = this.$_commonlib.getRegexPattern('checkHangul');
        if (targetStr.search(/\s/) !== -1) {
          event.rule.message = '패스워드는 공백 없이 입력해주세요.';
          return false;
        } else if (hangulcheck.test(targetStr)) {
          event.rule.message = '패스워드에 한글을 사용 할 수 없습니다.';
          return false;
        }

        return true;
      },
      // API 전용
      async callPromiseAllInitData() {
        let apiList = [];
        apiList.push(await this.CALL_API(this.setPayloadMember())); //해당 계정 데이터 조회 payload
        return await Promise.all(apiList);
      },

      setPayloadMember() {
        let paramsData = { loginId: this.$store.getters.getLoginId };
        return {
          actionName: 'MEMBER_LIST_ALL',
          data: { params: paramsData },
          loading: true,
        };
      },

      async callUpdateProfile(changInfo) {
        let paramsData = { ...changInfo };
        delete paramsData.password;
        delete paramsData.changePwd;
        const payload = {
          actionName: 'MEMBER_LIST_INSERT',
          data: [
            {
              id: this.userInfo.id,
              authId: this.userInfo.authId,
              loginId: this.userInfo.loginId,
              loginNm: paramsData.loginNm,
              loginPwd: paramsData.newPassword,
              memberState: this.userInfo.memberStateCd,
            },
          ],
          useErrorPopup: true,
        };
        return this.CALL_API(payload);
      },
    },

    beforeCreate() {},
    created() {
      this.initSetting();
    },
    mounted() {},
    beforeDestroy() {},
  };
</script>

<style scoped>
  .profile_holder {
    width: 100%;
    height: 100%;
  }

  .profile_content {
    height: 90%;
    width: 95%;
    margin: 0 2.5% auto;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .profile_content::-webkit-scrollbar {
    width: 8px; /* 스크롤바의 너비 */
  }

  .profile_content::-webkit-scrollbar-thumb {
    height: 20%; /* 스크롤바의 길이 */
    background: #00c3c8; /* 스크롤바의 색상 */

    border-radius: 10px;
  }

  .profile_content::-webkit-scrollbar-track {
    background: rgb(40, 158, 161, 0.1); /*스크롤바 뒷 배경 색상*/
  }

  .profile_content_row {
    display: block;
    clear: both;
    width: 100%;
    height: auto;
    min-height: 52px;
    font-size: 15px;
  }

  .profile_content_row_header,
  .profile_content_row_content {
    display: inline-block;
    height: 28px;
    line-height: 28px;
  }

  .profile_input input.dx-placeholder {
    line-height: 30px;
  }

  .profile_content_row_header {
    width: 20%;
  }

  .profile_content_row_header span.required::after {
    content: '*';
    padding-left: 2px;
    display: inline-block;
    text-align: center;
    margin: 0 auto;
    height: 28px;
    vertical-align: middle;
    line-height: 32px;
  }

  .profile_content_row_content {
    width: 80%;
    padding: 5px 0px 0px 5px;
  }

  .profile_content_row_content .vertical-align-middle {
    vertical-align: middle;
  }

  .margin-left-default {
    margin-left: 10px;
  }
</style>
