<!--
  PACKAGE_NAME : src\pages\report\ewm
  FILE_NAME : forecasting-model-history
  AUTHOR : hpmoon
  DATE : 2024-10-29
  DESCRIPTION : 보고서 > 예측 > 예측모델 변경이력
-->
<template>
  <div class="content-themes">
    <div class="page-sub-box">
      <EspDxDataGrid id="forecastingModelHistoryGrid" :data-grid="dataGrid" ref="dataGrid" ></EspDxDataGrid>
      <div class="content-info-box01 pad_top10 mar_to10">
        {{ $_lang('FORECASTING.MESSAGE.FORECAST_MODEL_INFO_MESSAGE1', {defaultValue: '일일 예측이 5회 연속으로, 월 예측이 3회 연속으로 실제 값과 현저한 차이를 보일 경우 예측 모델이 자동으로 변경됩니다.'}) }}<br/>
        {{ $_lang('FORECASTING.MESSAGE.FORECAST_MODEL_INFO_MESSAGE2', {defaultValue: '인입 이력 차트에서는 연속 실패 시점을 포함해 지난 2주간의 유입 현황을 확인할 수 있습니다.'}) }}
      </div>
    </div>

    <!-- 이벤트 유형 선택 -->
    <ForecastFailHistoryChart
      v-if="modal.forecastingFailHistoryChart.visible"
      :is-open="modal.forecastingFailHistoryChart.visible"
      :data="modal.forecastingFailHistoryChart.data"
      @closeModal="onCloseModal('forecastingFailHistoryChart')"
    />
  </div>
</template>

<script>
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';
  import DxButton from 'devextreme-vue/button';
  import ForecastFailHistoryChart from "@/pages/report/ewm/forecasting-fail-history-chart.vue";
  import { isEmpty } from "@/plugins/common-lib";

  export default {
    components: {
      EspDxDataGrid,
      DxButton,
      ForecastFailHistoryChart,
    },
    data() {
      return {
        config: {
          stylingMode: 'outlined', //outlined, underlined, filled,
        },

        dataGrid: {
          refName: 'dataGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: true, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // width:'500',     // 주석처리시 100%
          height: '100%', // 주석처리시 100%
          apiActionNm: {},
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
          },
          showActionButtons: {
            //툴바 버튼 / 순서(sortIndex) 설정 default : [ 복사: 10, 조회 20, 추가: 30, 저장: 40, 취소: 50, 삭제: 60, 커스텀 버튼: 70번대(71, 72, 73..), 엑셀다운로드: 100 ]
            customButtons: [
            ],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: false, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: true,
            pageSize: 20,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: false, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowDeleting: false,
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'none', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '예측 주기',
              i18n: 'FORECASTING.WORD.FORECAST_CYCLE',
              dataField: 'frequency',
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              allowSorting: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
              lookup: {
                dataSource: [],
                displayExpr: "codeNm",
                valueExpr: "codeValue"
              },
            },
            {
              caption: '교체 날짜',
              i18n: 'FORECASTING.WORD.REPLACEMENT_DATE',
              dataField: 'insert_date',
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              allowSorting: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              calculateCellValue: data => this.$_commonlib.formatDate(data.insert_date, 'YYYYMMDDHHmmssSSS', 'YYYY-MM-DD'),
            },
            {
              caption: '예측 실패 기간',
              i18n: 'FORECASTING.WORD.FORECAST_FAIL_PERIOD',
              dataField: 'fail_start_date',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              calculateDisplayCellValue: rowData => {
                return `${this.$_commonlib.formatDate(rowData.fail_start_date, 'YYYYMMDDHHmmssSSS', 'YYYY-MM-DD')} ~
                ${this.$_commonlib.formatDate(rowData.fail_end_date, 'YYYYMMDDHHmmssSSS', 'YYYY-MM-DD')}`;
              },
            },
            {
              caption: '평균 정확도',
              i18n: 'FORECASTING.WORD.AVERAGE_ACCURACY',
              dataField: 'accuracy',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              calculateDisplayCellValue: rowData => {
                return `${Math.round(rowData.accuracy)}%`;
              },
            },
            {
              caption: '모델',
              i18n: 'FORECASTING.WORD.MODEL',
              dataField: 'name',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              allowSorting: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              lookup: {
                dataSource: [],
                displayExpr: "codeNm",
                valueExpr: "codeValue"
              },
            },
            {
              caption: '하이퍼 파라미터',
              i18n: 'FORECASTING.WORD.HYPERPARAMETERS',
              dataField: 'hyperparameters',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              calculateCellValue: data => isEmpty(data.hyperparameters) ? '-' :JSON.stringify(data.hyperparameters),
            },
            {
              caption: '인입 이력',
              i18n: 'FORECASTING.WORD.OFFER_HISTORY',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              cellTemplate: (container, options) => {
                const button = new DxButton({
                  propsData: {
                    text: '차트',
                    elementAttr: { class: 'btn_XS white light_filled' },
                    width: 60,
                    height: 30,
                    onClick: () => {
                      this.onOpenModal('forecastingFailHistoryChart', options.data);
                    },
                  },
                });
                button.$mount();
                container.append(button.$el);
              },
            },
          ],
        },

        modal: {
          forecastingFailHistoryChart: {
            visible: false,
            data: null,
          },
        },
      };
    },
    computed: {

    },

    methods: {
      /** @description 데이터 조회 메서드 */
      async selectDataList() {
        const payload = {
          actionName: 'FORECASTING_MODEL_LIST',
          loading: true,
        }

        const res = await this.CALL_FORECASTING_API(payload);
        if(res.status === 200) {
          this.dataGrid.dataSource = res.data;
        } else {
          this.$_Msg(this.$_lang('CMN_ERROR'));
        }
      },

      /**
       * @description 팝업 열기
       * @param modalType 팝업 모달 타입(forecastingFailHistoryChart)
       * @param data props data
       * */
      onOpenModal(modalType, data) {
        this.modal[modalType].data = data;
        this.modal[modalType].visible = true;
      },

      /**
       * @description 팝업 닫기
       * @param modalType 팝업 모달 타입(forecastingFailHistoryChart)
       * */
      onCloseModal(modalType) {
        this.modal[modalType].visible = false;
      },

      /** @description Grid 높이 계산 */
      setGridHeight() {
        let height = this.topElement('#forecastingModelHistoryGrid') + this.heightElement('.dx-datagrid-header-panel') + 60;
        this.$refs.dataGrid.grid.height = "calc(100vh - " + height + "px)";
      },
      topElement(e) {
        const divElement = document.querySelector(e);
        const rect = divElement.getBoundingClientRect();
        return rect.top;
      },
      heightElement(e) {
        const divElement = document.querySelector(e);
        const computedStyle = window.getComputedStyle(divElement);
        const divHeight = divElement.offsetHeight;
        const marginTop = parseFloat(computedStyle.marginTop);
        const marginBottom = parseFloat(computedStyle.marginBottom);
        return divHeight + marginTop + marginBottom;
      },
    },

    /** @description 라이프사이클 created시 호출되는 메서드 */
    created() {

    },

    /** @description 라이프사이클 mounted시 호출되는 메서드 */
    mounted() {
      this.selectDataList();
      this.setGridHeight();

      this.dataGrid.columns[0].lookup.dataSource = this.$_getCode('forecasting_frequency');

      this.dataGrid.columns[4].lookup.dataSource = this.$_getCode('forecasting_model');
    },
  };
</script>

<style lang="scss" scoped>
</style>
