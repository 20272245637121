<!--
  PACKAGE_NAME : src/pages/euc/contact
  FILE_NAME : modal-excel-upload.vue
  AUTHOR : jhcho
  DATE : 2024-06-24
  DESCRIPTION :
-->
<template>
  <div class="container">
    <div class="text-center text-lg">csv 파일은 UTF-8 인코딩만 지원됩니다.</div>
    <DxFileUploader
      ref="fileUploaderRef"
      :selectButtonText="$_lang('COMPONENTS.FILE_SELECT', { defaultValue: '파일 선택' })"
      uploadMode="useButtons"
      accept="text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      :multiple="true"
      :show-file-list="true"
      @value-changed="onValueChanged"
    />
  </div>
</template>
<script>
import {
  DxFileUploader
} from 'devextreme-vue/file-uploader';
const ExcelJS = require('exceljs');

export default {
  components: {
    DxFileUploader
  },
  data() {
    return {}
  },
  methods: {
    /**
     * 엑셀 파일에서 데이터 추출
     * @async
     * @returns {Promise<Array>} 엑셀 파일에서 추출한 데이터를 담은 배열을 반환
     */
    async getExcelData() {
      this.$emit('app:progress', true);
      const files = this.$refs.fileUploaderRef.instance.option('value');
      const dataPromises = files.map((file) => {
        return file.type === 'text/csv'
            ? this.extractDataFromCsvFile(file)
            : this.extractDataFromExcelFile(file);
      });
      const dataArrays = await Promise.all(dataPromises);
      this.$emit('app:progress', false);
      return dataArrays.flat();
    },
    /**
     * Excel 파일에서 데이터를 추출
     * @async
     * @param {File} file - 데이터를 추출할 Excel 파일
     * @returns {Promise<Array>} Excel 파일에서 추출한 데이터를 담은 배열을 반환
     * @throws 파일 읽기 또는 데이터 추출 중 오류가 발생하면 오류를 로그에 기록하고 빈 배열을 반환
     */
    async extractDataFromExcelFile(file) {
      const workbook = new ExcelJS.Workbook();
      try {
        const arrayBuffer = await this.readFileAsArrayBuffer(file);
        await workbook.xlsx.load(arrayBuffer);

        const worksheet = workbook.getWorksheet(1);

        const { columnMapping, headerRowIndex } = this.findHeaderRow(worksheet);
        const data = this.extractDataFromWorksheet(worksheet, columnMapping, headerRowIndex);

        return data;
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    /**
     * @param {Object} worksheet - 헤더 행을 찾을 워크시트 객체
     * @returns {Object} 헤더 행의 인덱스와 열 이름과 열 번호의 매핑을 담은 객체를 반환
     */
    findHeaderRow(worksheet) {
      let columnMapping = {};
      let headerRowIndex;
      for(let i = 1; i <= worksheet.rowCount; i++) {
        const row = worksheet.getRow(i);
        if(row.getCell(1).value.includes('사번') || row.getCell(1).value.includes('이름') || row.getCell(1).value === '그룹명') {
          headerRowIndex = i;
          row.eachCell((cell, colNumber) => {
            columnMapping[cell.value] = colNumber;
          });
          break;
        }
      }
      return { columnMapping, headerRowIndex };
    },
    /**
     * @param {Object} worksheet - 데이터를 추출할 워크시트 객체
     * @param {Object} columnMapping - 열 이름과 열 번호의 매핑
     * @param {number} headerRowIndex - 헤더 행의 인덱스
     * @returns {Array} 워크시트에서 추출한 데이터를 담은 배열을 반환
     */
    extractDataFromWorksheet(worksheet, columnMapping, headerRowIndex) {
      const data = [];
      for(let i = headerRowIndex + 1; i <= worksheet.rowCount; i++) {
        const row = worksheet.getRow(i);
        let contact = {
          groupNm: null,
          personNm: null,
          gradeNm: null,
          companyNm: null,
          deptNm: null,
          number1: null,
          number2: null,
          memo: null,
        };

        if(this.$store.getters.getIsAdminUser) {
          contact.userNo = null;
        }
        if(columnMapping['그룹명']) {
          contact.groupNm = row.getCell(columnMapping['그룹명'])?.value || null;
        }
        if(columnMapping['이름']) {
          contact.personNm = row.getCell(columnMapping['이름'])?.value || null;
        }
        if(columnMapping['직위']) {
          contact.gradeNm = row.getCell(columnMapping['직위'])?.value || null;
        }
        if(columnMapping['회사']) {
          contact.companyNm = row.getCell(columnMapping['회사'])?.value || null;
        }
        if(columnMapping['부서명']) {
          contact.deptNm = row.getCell(columnMapping['부서명'])?.value || null;
        }
        if(columnMapping['전화번호1']) {
          contact.number1 = row.getCell(columnMapping['전화번호1'])?.value || null;
        } else if(columnMapping['연락처1']) {
          contact.number1 = row.getCell(columnMapping['연락처1'])?.value || null;
        }
        if(columnMapping['전화번호2']) {
          contact.number2 = row.getCell(columnMapping['전화번호2'])?.value || null;
        } else if(columnMapping['연락처2']) {
          contact.number2 = row.getCell(columnMapping['연락처2'])?.value || null;
        }
        if(columnMapping['메모']) {
          contact.memo = row.getCell(columnMapping['메모'])?.value || null;
        }
        if(columnMapping['사번'] && this.$store.getters.getIsAdminUser) {
          contact.userNo = row.getCell(columnMapping['사번'])?.value || null;
        }

        data.push(contact);
      }
      return data;
    },
    /**
     * 파일 선택 시 호출
     * @param {Object} e
     * */
    async onValueChanged(e) {
      const filePromises = e.value.map(async (file) => {
        const isEncrypted = await this.isExcelFileEncrypted(file);
        if(isEncrypted) {
          this.handleEncryptedFile(file);
          return null; // 암호화된 파일은 제외
        }
        return file; // 암호화되지 않은 파일은 포함
      });

      const files = await Promise.all(filePromises);
      const validFiles = files.filter(file => file !== null); // null 값을 제외한 파일만 선택
      e.value = validFiles;
    },
    /**
     * 암호화된 파일 처리
     * @param {File} file
     * */
    handleEncryptedFile(file) {
      this.$_Msg(
          this.$_lang(
              'UC.MESSAGE.ENCRYPTED_FILE_UPLOAD_NOT_ALLOWED',
              { defaultValue: '암호화된 파일은 업로드할 수 없습니다.' }
          ),
          {
            icon: 'error',
            title: file.name,
            showCloseButton: false
          }
      )
      this.$refs.fileUploaderRef.instance.removeFile(file);
    },
    /**
     * 파일을 텍스트로 읽어오기
     * @param {File} file
     * @returns {Promise<string>}
     * */
    async readFileAsText(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = () => reject(new Error("File reading failed"));
        reader.readAsText(file);
      });
    },
    /**
     * csv 파일 파싱
     * @param {string} text
     * @returns {Promise<Array>}
     * */
    parseCsv(text) {
      return new Promise((resolve, reject) => {
        try {
          const lines = text.split('\n');
          const headerIndex = lines.findIndex(line => line.includes('이름,'));
          const headers = lines[headerIndex].split(',');

          let csvData = lines.slice(headerIndex + 1).map(line => {
            const values = line.split(',');
            let contact = {
              groupNm: null,
              personNm: null,
              gradeNm: null,
              companyNm: null,
              deptNm: null,
              number1: null,
              number2: null,
              memo: null,
            };

            headers.forEach((header, index) => {
              switch (header) {
                case '사번':
                  if(this.$store.getters.getIsAdminUser) {
                    contact.userNo = values[index] || null;
                  }
                  break;
                case '그룹명':
                  contact.groupNm = values[index] || null;
                  break;
                case '이름':
                  contact.personNm = values[index] || null;
                  break;
                case '직위':
                  contact.gradeNm = values[index] || null;
                  break;
                case '회사':
                  contact.companyNm = values[index] || null;
                  break;
                case '부서명':
                  contact.deptNm = values[index] || null;
                  break;
                case '전화번호1':
                case '연락처1':
                  contact.number1 = values[index] || null;
                  break;
                case '전화번호2':
                case '연락처2':
                  contact.number2 = values[index] || null;
                  break;
                case '메모':
                  contact.memo = values[index] || null;
                  break;
              }
            });

            return contact;
          });

          // 모두 다 null 일 경우 제외
          csvData = csvData.filter(contact =>
              !(contact.groupNm === null &&
                  contact.personNm === null &&
                  contact.gradeNm === null &&
                  contact.companyNm === null &&
                  contact.deptNm === null &&
                  contact.number1 === null &&
                  contact.number2 === null &&
                  contact.memo === null)
          );
          resolve(csvData);
        } catch (error) {
          reject(error);
        }
      });
    },
    /**
     * csv 파일에서 데이터 추출
     * @param {File} file
     * @returns {Promise<Array>}
     * */
    async extractDataFromCsvFile(file) {
      try {
        const text = await this.readFileAsText(file);
        return await this.parseCsv(text);
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    /**
     * 파일을 ArrayBuffer로 읽어오기
     * @param {File} file
     * @returns {Promise<ArrayBuffer>}
     * */
    async readFileAsArrayBuffer(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = event => resolve(event.target.result);
        reader.onerror = () => reject(new Error("File reading failed"));
        reader.readAsArrayBuffer(file);
      });
    },
    /**
     * 암호화 여부 확인
     * @param {File} file
     * @returns {Promise<boolean>} 암호화 상태라면 true, 아니라면 false (csv 는 우선 true로 처리)
     * */
    async isExcelFileEncrypted(file) {
      if(file.type === 'text/csv') {
        try {
          await this.extractDataFromCsvFile(file);
          return false;
        } catch (error) {
          return true;
        }
      } else {
        const workbook = new ExcelJS.Workbook();
        try {
          const arrayBuffer = await this.readFileAsArrayBuffer(file);
          await workbook.xlsx.load(arrayBuffer);
          return false;
        } catch (error) {
          return true;
        }
      }
    },
  },
}
</script>
<style scoped>
:global(.dx-fileuploader-files-container) {
  max-height: 200px;
  overflow-y: auto;
}
</style>