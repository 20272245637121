<template>
  <div class="contents-wrap">
    <div class="flex flex-col justify-between">
      <div>
        <table class="tableList">
          <colgroup>
            <col style="width: 20%" />
            <col style="width: 25%" />
            <col style="width: 20%" />
            <col style="width: 25%" />
          </colgroup>
          <tbody>
            <tr>
              <th scope="row" class="p8">
                <label for="label5">
                  <span>문항구분</span>
                  <span class="icon_require">필수항목</span>
                </label>
              </th>
              <td class="clearfix p8">
                <DxSelectBox
                  placeholder="선택"
                  display-expr="codeNm"
                  value-expr="codeId"
                  :width="135"
                  :styling-mode="stylingMode"
                  :items="getDivisionType"
                  v-model="questionDivisionCd"
                  @selection-changed="e => onSelectionChanged(e)"
                >
                  <DxValidator validation-group="validationGroupName">
                    <DxRequiredRule message="문항구분은 필수입니다." />
                  </DxValidator>
                </DxSelectBox>
              </td>
              <th scope="row" class="p8">
                <label for="label5">
                  <span>문항구분상세</span>
                  <span class="icon_require">필수항목</span>
                </label>
              </th>
              <td class="p8">
                <DxSelectBox
                  placeholder="선택"
                  :width="135"
                  display-expr="codeNm"
                  value-expr="codeId"
                  :styling-mode="stylingMode"
                  :items="formData.divisionDetailCdList"
                  v-model="questionDivisionDetailCd"
                >
                  <DxValidator validation-group="validationGroupName">
                    <DxRequiredRule message="문항구분상세는 필수입니다." />
                  </DxValidator>
                </DxSelectBox>
              </td>
            </tr>
            <tr>
              <th scope="row" class="p8">
                <label for="label5">
                  <span>난이도</span>
                  <span class="icon_require">필수항목</span>
                </label>
              </th>
              <td class="clearfix p8">
                <DxSelectBox
                  placeholder="난이도 선택"
                  display-expr="label"
                  value-expr="value"
                  :width="135"
                  :styling-mode="stylingMode"
                  :items="ewmCodes.examLevel.values"
                  v-model="examLevel"
                >
                  <DxValidator validation-group="validationGroupName">
                    <DxRequiredRule message="난이도는 필수입니다." />
                  </DxValidator>
                </DxSelectBox>
              </td>
              <th scope="row" class="p8">
                <label for="label5">
                  <span>정답유형</span>
                  <span class="icon_require">필수항목</span>
                </label>
              </th>
              <td class="p8">
                <DxSelectBox
                  placeholder="정답유형 선택"
                  display-expr="label"
                  value-expr="value"
                  :width="135"
                  :styling-mode="stylingMode"
                  :items="ewmCodes.answerType.values"
                  v-model="answerType"
                  @value-changed="onChangeAnswerType"
                >
                  <DxValidator validation-group="validationGroupName">
                    <DxRequiredRule message="정답유형은 필수입니다." />
                  </DxValidator>
                </DxSelectBox>
              </td>
            </tr>
            <tr>
              <th scope="row" class="p8">
                <label for="label5">
                  <span>사용여부</span>
                  <span class="icon_require">필수항목</span>
                </label>
              </th>
              <td class="clearfix p8">
                <DxSelectBox
                  placeholder="사용여부 선택"
                  display-expr="label"
                  value-expr="value"
                  :width="135"
                  :height="30"
                  :styling-mode="stylingMode"
                  :items="commonCodes.stringUsedFlag.values"
                  v-model="useFl"
                >
                  <DxValidator validation-group="validationGroupName">
                    <DxRequiredRule message="사용여부는 필수입니다." />
                  </DxValidator>
                </DxSelectBox>
              </td>
              <th scope="row" class="p8">
                <label for="label5">
                  <span>채첨방식</span>
                  <span class="icon_require">필수항목</span>
                </label>
              </th>
              <td>
                <DxSelectBox
                  placeholder="채점방식 선택"
                  display-expr="label"
                  value-expr="value"
                  :width="135"
                  :height="30"
                  :styling-mode="stylingMode"
                  :items="ewmCodes.examScoringType.values"
                  v-model="examScoringType"
                >
                  <DxValidator validation-group="validationGroupName">
                    <DxRequiredRule message="채점방식은 필수입니다." />
                  </DxValidator>
                </DxSelectBox>
              </td>
            </tr>
            <tr>
              <th scope="row" class="p8">
                <label for="label5">
                  <span>첨부파일</span>
                </label>
              </th>
              <td class="p8">
                <div :v-show="this.originalFileName">{{ this.originalFileName }}</div>
                <DxButton
                  text="파일추가"
                  :visible="!this.originalFileName"
                  type="button"
                  @click="uploadFiles"
                  class="btn_XS white light_filled"
                  :height="15"
                />
                <DxFileUploader
                  ref="fileUploader"
                  id="file-uploader"
                  :multiple="false"
                  upload-mode="useButtons"
                  :visible="false"
                  @value-changed="onChangedAttachedFiles"
                />
              </td>

              <th scope="row" class="p8">
                <label for="label5">
                  <span>배점</span>
                </label>
              </th>
              <td class="p8">
                <span>{{ this.score }}</span>
              </td>
            </tr>
            <tr>
              <th scope="row" class="p8">
                <label for="label5">
                  <span>문항내용</span>
                  <span class="icon_require">필수항목</span>
                </label>
              </th>
              <td colspan="3" class="p18">
                <DxHtmlEditor :height="140" :styling-mode="stylingMode" v-model="questionNm" >
                  <DxToolbar>
                    <DxItem name="undo" />
                    <DxItem name="redo" />
                    <DxItem name="font" />
                    <DxItem name="separator" />
                    <DxItem name="header" />
                    <DxItem name="separator" />
                    <DxItem name="bold" />
                    <DxItem name="italic" />
                    <DxItem name="strike" />
                    <DxItem name="underline" />
                    <DxItem name="separator" />
                    <DxItem name="image" />
                    <DxItem name="separator" />
                    <DxItem name="insertTable" />
                  </DxToolbar>
                  <DxValidator validation-group="validationGroupName" >
                    <DxRequiredRule message="문항내용은 필수입니다." />
                  </DxValidator>
                </DxHtmlEditor>
              </td>
            </tr>
            <tr>
              <th scope="row" class="p8">
                <label for="label5">
                  <span>정답해설</span>
                </label>
              </th>
              <td colspan="3" class="p8">
                <DxTextBox width="100%" :styling-mode="stylingMode" v-model="description" />
              </td>
            </tr>
          </tbody>
        </table>

        <div class="page-bin mar_b10"></div>

        <div class="sub_title_txt clearfix">
          <div class="fr">
            <DxButton
              text="보기추가"
              :disabled="questionCnt > 0"
              :visible="true"
              type="button"
              :height="30"
              class="btn_XS white light_filled add2"
              @click="addAnswer"
            />
          </div>
        </div>

        <div class="page-bin mar_b10"></div>

        <div style="min-height: 230px">
          <table class="tableList">
            <colgroup>
              <col />
              <col />
              <col />
              <col />
            </colgroup>
            <thead class="inner-header">
              <tr>
                <th scope="col" class="p10_20">No</th>
                <th scope="col" style="width: 50%">
                  <span>보기명</span>
                  <span class="icon_require">필수항목</span>
                </th>
                <th scope="col" class="p10_20">
                  <span>배점</span>
                  <span class="icon_require">필수항목</span>
                </th>
                <th scope="col" class="p10_20"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(detail, index) in details" :key="detail.detailId">
                <td class="p10_20">{{ detail.questionNumber }}</td>
                <td style="width: 50%">
                  <DxTextBox
                    placeholder="보기를 입력해주세요."
                    class="mar_ri10"
                    :styling-mode="stylingMode"
                    v-model="detail.detailDescription"
                  >
                    <DxValidator validation-group="validationGroupName">
                      <DxRequiredRule message="보기는 필수입니다." />
                    </DxValidator>
                  </DxTextBox>
                </td>
                <td class="p10_20">
                  <DxNumberBox
                    placeholder="점수"
                    class="mar_ri10"
                    :styling-mode="stylingMode"
                    v-model="detail.score"
                    @value-changed="onScoreKeyUp"
                  >
                    <DxValidator validation-group="validationGroupName">
                      <DxRequiredRule message="배점은 필수입니다." />
                    </DxValidator>
                  </DxNumberBox>
                </td>
                <td class="p10_20">
                  <DxButton
                    text=""
                    class="btn-icon trash dx-button dx-button-normal dx-button-mode-contained dx-widget"
                    :disabled="questionCnt > 0"
                    @click="onDeleteAnswerRow(index)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="text-center pt-2">
        <DxButton
          text="저 장"
          :disabled="questionCnt > 0"
          :width="120"
          :height="40"
          class="btn_XS default filled"
          style="margin-right: 10px"
          @click="onSubmitForm"
        />
        <DxButton text="닫 기" :width="120" :height="40" class="btn_XS white light_filled" @click="onCloseForm" />
      </div>
    </div>
  </div>
</template>

<script>
  import { DxFileUploader } from 'devextreme-vue/file-uploader';
  import { DxTextBox } from 'devextreme-vue/text-box';
  import { DxNumberBox } from 'devextreme-vue/number-box';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { DxRequiredRule, DxValidator } from 'devextreme-vue/validator';
  import validationEngine from 'devextreme/ui/validation_engine';
  import { DxButton } from 'devextreme-vue/button';
  import { DxHtmlEditor, DxItem, DxToolbar } from 'devextreme-vue/html-editor';
  import { isSuccess } from '@/plugins/common-lib';
  import { espApi } from '@/api';

  export default {
    components: {
      DxTextBox,
      DxSelectBox,
      DxFileUploader,
      DxHtmlEditor,
      DxToolbar,
      DxItem,
      DxButton,
      DxRequiredRule,
      DxValidator,
      DxNumberBox,
    },
    props: {},
    watch: {},
    data() {
      return {
        editorValue: '<p>Content with an image</p>',
        codeMap: {},
        questionDivisionCdDataSource: [],
        questionDivisionDetailCdDataSource: [],
        attacheFiles: null,
        questionId: null,
        questionDivisionCd: null,
        questionDivisionDetailCd: null,
        questionNm: null,
        fileGroupId: null,
        levelCd: null,
        examLevel: null,
        answerTypeCd: null,
        answerType: null,
        viewCd: null,
        viewFl: null,
        useFl: null,
        scoringTypeCd: null,
        examScoringType: null,
        originalFileName: null,
        score: 0, // 총점
        description: null,
        originalName: null,
        questionCnt: 0,
        stylingMode: 'outlined',
        formData: {
          divisionDetailCdList: null,
          originalFileName: null,
          uploadFile: null,
          score: 0,
          questionDivisionDetailCd: null,
        },
        details: [],
      };
    },
    computed: {
      commonCodes() {
        return this.$_enums.common;
      },
      ewmCodes() {
        return this.$_enums.ewm;
      },
      getDivisionType() {
        //return this.$_getCode('ewm_edu_exam_question_division');
        return this.questionDivisionCdDataSource;
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountData();
    },
    methods: {
      uploadFiles() {
        if (this.questionCnt > 0) {
          this.$_Msg('출제횟수가 있는 문항에 대해서는 삭제 및 수정이 불가능 합니다.');
          return;
        }
        this.$refs.fileUploader.instance._selectButtonClickHandler();
      },
      async onSubmitForm() {
        const validationResult = validationEngine.validateGroup('validationGroupName');
        if (!validationResult.isValid) {
          return;
        }
        if (this.questionCnt > 0) {
          this.$_Msg('출제횟수가 있는 문항에 대해서는 삭제 및 수정이 불가능 합니다.');
          return;
        }

        let fileGroupId = null;
        let originalFileName = null;
        if (this.attacheFiles) {
          const uploadedFiles = await this.onUploadedAttachedFile(this.attacheFiles);
          fileGroupId = uploadedFiles.data?.data[0].fileGroupId;
          originalFileName = uploadedFiles.data?.data[0].originalFileName;

          this.fileGroupId = fileGroupId;
          this.originalFileName = originalFileName;
        }

        const answerType = this.answerType;
        const answerSize = this.details?.length || 0;

        // 객관식: 5개까지 등록가능
        //if(answerTypeCd == 994 && answerSize == 5) {
        //	this.$_Msg("객관식은 5개까지 등록가능합니다.");
        //	return false;
        //}
        const limitAnswerSize = this.getLimitAnswerSize();

        if (answerSize < 1) {
          this.$_Msg('보기는 최소 1개 이상 등록되어야 합니다.');
          return false;
        }
        // OX 보기 2개까지 등록가능
        if (answerType === this.$_enums.ewm.answerType.OX.value && answerSize != limitAnswerSize) {
          this.$_Msg(`정답유형 OX는 보기 ${limitAnswerSize}개를 등록 해야 합니다.`);
          return false;
        }

        // 단단형, 서술형 보기 한개만 등록가능
        if (
          (answerType === this.$_enums.ewm.answerType.SHORT.value || answerType === this.$_enums.ewm.answerType.LONG.value) &&
          answerSize !== limitAnswerSize
        ) {
          this.$_Msg(`정답유형 단답형, 서술형은 보기 ${limitAnswerSize}개를 등록 해야 합니다.`);
          return false;
        }

        this.getSumScore();

        if (
          !(await this.$_Confirm(this.$_lang('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' }), {
            title: `<h2>알림</h2>`,
          }))
        ) {
          return;
        }

        let params = {
          questionId: this.questionId,
          questionDivisionCd: this.questionDivisionCd,
          questionDivisionDetailCd: this.questionDivisionDetailCd,
          questionNm: this.questionNm,
          fileGroupId: fileGroupId,
          levelCd: this.levelCd,
          examLevel: this.examLevel,
          answerTypeCd: this.answerTypeCd,
          answerType: this.answerType,
          viewCd: this.viewCd,
          viewFl: this.viewFl,
          useFl: this.useFl,
          scoringTypeCd: this.scoringTypeCd,
          examScoringType: this.examScoringType,
          originalFileName: this.originalFileName,
          score: this.score, // 총점
          description: this.description,
          originalName: originalFileName,
          details: this.details,
        };

        const payload = {
          actionName: 'EWM_EXAM_QUESTION_MERGE',
          data: params,
          loading: false,
        };

        const res = await this.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
          this.onCloseForm();
        } else {
          this.$_Msg(`데이터 저장 실패 \n 원인 : [${res.data?.header?.resMsg ?? 'HTTP 에러'}]`);
        }
      },
      initForm() {
        this.questionId = null;
        this.questionDivisionCd = null;
        this.questionDivisionDetailCd = null;
        this.questionNm = null;
        this.levelCd = null;
        this.examLevel = null;
        this.answerTypeCd = null;
        this.answerType = null;
        this.viewCd = null;
        this.viewFl = this.$_enums.common.stringUsedFlag.YES.value;
        this.useFl = this.$_enums.common.stringUsedFlag.NO.value;
        this.scoringTypeCd = null;
        this.scoringType = null;
        this.originalFileName = null;
        this.score = 0; // 총점
        this.description = null;
        this.originalName = null;
        this.fileGroupId = null;
        this.details = [];
        this.formData.divisionDetailCdList = null;
        this.questionCnt = 0;
        this.attacheFiles = null;

        validationEngine.resetGroup('validationGroupName');
      },
      loadFormData(rowdata, detailInfo) {
        this.details = detailInfo;
        this.questionId = rowdata?.id;
        this.questionNm = rowdata?.questionNm;
        this.levelCd = rowdata?.levelCd;
        this.examLevel = rowdata?.examLevel;
        this.answerTypeCd = rowdata?.answerTypeCd;
        this.answerType = rowdata?.answerType;
        this.viewCd = rowdata?.viewCd;
        this.viewFl = rowdata?.viewFl;
        this.useFl = rowdata?.useFl;
        this.scoringTypeCd = rowdata?.scoringTypeCd;
        this.examScoringType = rowdata?.examScoringType;
        this.originalFileName = rowdata?.originalFileName;
        this.score = rowdata?.score; // 총점
        this.description = rowdata?.description;
        this.originalName = rowdata?.originalName;
        this.fileGroupId = rowdata?.fileGroupId;
        this.questionCnt = rowdata?.questionCnt;
        this.questionDivisionCd = rowdata?.questionDivisionCd;

        this.onChangeDivisionType(this.questionDivisionCd).then(() => {
          this.questionDivisionDetailCd = rowdata?.questionDivisionDetailCd;
        });
      },
      onCloseForm() {
        this.initForm();
        this.$emit('onCloseAnswerForm');
        this.$emit('onSelectList');
      },
      getSumScore() {
        let sum = 0;
        const details = this.details || [];
        for (let value of details) {
          sum += Number(value.score);
        }
        this.score = sum;
      },
      async onDeleteAnswerRow(idx) {
        if (this.questionCnt > 0) {
          this.$_Msg('출제횟수가 있는 문항에 대해서는 삭제 및 수정이 불가능 합니다.');
          return;
        }
        // row 삭제
        this.details.splice(idx, 1);
        this.details.some((val, idx) => {
          this.details[idx].questionNumber = idx + 1;
        });

        this.getSumScore();
      },
      onScoreKeyUp() {
        this.getSumScore();
      },
      getLimitAnswerSize() {
        // 정답유형별 row개수 제한
        // -- 1. 서술형, 단답형 1개까지 입력가능
        // -- 2. OX 2개까지 입력가능
        // -- 3. 객관식 5개까지 입력가능

        let limitAnswerSize = 0;
        // 객관식 5개까지 등록가능
        if (this.answerType === this.$_enums.ewm.answerType.SHORT.value || this.answerType === this.$_enums.ewm.answerType.LONG.value) {
          limitAnswerSize = 1;
        } else if (this.answerType === this.$_enums.ewm.answerType.OX.value) {
          limitAnswerSize = 2;
        } else {
          limitAnswerSize = 5;
        }
        return limitAnswerSize;
      },
      addAnswer() {
        const answerSize = this.details?.length || 0;

        const limitAnswerSize = this.getLimitAnswerSize();

        if (answerSize + 1 > limitAnswerSize) {
          this.$_Msg(`본 정답유형은 ${limitAnswerSize}개까지 등록 가능합니다.`);
          return false;
        }

        let length = answerSize;
        const addIndex = ++length;
        let questionNumber = {
          questionNumber: addIndex,
        };
        if (answerSize === 0) {
          this.details = [];
        }

        this.details?.push(questionNumber);
      },

      onChangeAnswerType() {
        const limitAnswerSize = this.getLimitAnswerSize();

        if (this.details?.length && this.details.length > limitAnswerSize) {
          while (limitAnswerSize < this.details.length) {
            this.onDeleteAnswerRow(limitAnswerSize);
          }
        }
      },

      onChangedAttachedFiles(e) {
        console.log('e :', e);
        if (this.questionCnt > 0) {
          this.$_Msg('출제횟수가 있는 문항에 대해서는 삭제 및 수정이 불가능 합니다.');
          return;
        }
        if (e.value.length > 1) {
          this.$_Msg('한 개의 이미지 파일을 올려주세요.');
          return false;
        }
        this.attacheFiles = e.value;
        this.originalFileName = e.value[0].name;
      },
      async onUploadedAttachedFile(files) {
        this.attacheFiles = files;
        this.formData.uploadFile = files[0];
        this.formData.originalFileName = files[0].name;
        return await espApi.fileUpload(files);
      },
      onSelectionChanged(e) {
        if (!e.selectedItem) {
          return;
        }

        const selectedDivisionCd = e.selectedItem.codeId;
        this.questionDivisionDetailCd = null;
        this.onChangeDivisionType(selectedDivisionCd);
      },
      async onChangeDivisionType(selectedDivisionCd) {
        if (selectedDivisionCd) {
          this.formData.divisionDetailCdList = this.questionDivisionDetailCdDataSource.filter(d => d.parentId === selectedDivisionCd);
        }
      },
      /** @description : 코드 로드 */
      async initCodeMap() {
        this.codeMap = await this.$_getCodeMapList('root_ewm_edu_exam_question_division');
      },
      createdData() {
        this.initCodeMap().then(() => {
          this.questionDivisionCdDataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_edu_exam_question_division'], 2);
          this.questionDivisionDetailCdDataSource = this.$_fetchCodesByMaxDepth(this.codeMap['root_ewm_edu_exam_question_division']);
        });
      },
      mountData() {},
    },
  };
</script>

<style lang="scss">
  /* scoped 없이 진행 */
  .dx-htmleditor-content img {
    z-index: -1;
  }
</style>
<style lang="scss" scoped>
  .tableList {
    position: relative;
    width: 100%;
  }

  .tableList thead tr th {
    border-bottom: 1px solid #ddd;
    font-size: 10px;
    font-weight: normal;
    background: #fff;
    color: #545454;
    text-align: center;
    min-height: 50px;
  }

  .tableList thead tr:first-child th:first-child {
    border-left: none;
  }

  .tableList tbody tr td {
    position: relative;
    border-bottom: 1px solid #ddd;
    color: #545454;
    font-size: 11px;
    font-weight: normal;
    text-align: center;
  }

  .tableList tbody tr th {
    position: relative;
    border-bottom: 1px solid #ddd;
    color: #545454;
    font-size: 12px;
    font-weight: normal;
  }

  .tableList tbody tr td,
  .tableList tbody tr td > a,
  .tableList tbody tr th > a {
    font-size: 13px;
  }

  .p10_20 {
    padding: 8px 35px;
  }

  .p8 {
    padding: 8px;
    text-align: left;
  }
  .p18 {
    padding: 18px;
  }

</style>
