var render = function render(){var _vm=this,_c=_vm._self._c;return _c('esp-dx-modal-popup',{attrs:{"option":{
    title: _vm.modal.title,
    width: _vm.modal.width,
    height: _vm.modal.height,
    minWidth: _vm.modal.minWidth,
    minHeight: _vm.modal.minHeight,
    closeOnOutsideClick: _vm.modal.hideOnOutsideClick,
  },"isOpen":_vm.isOpen},on:{"saveModal":_vm.handleSaveModal,"closeModal":_vm.handleCloseModal},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('esp-dx-data-grid',{ref:_vm.dataGrid.refName,attrs:{"data-grid":_vm.dataGrid}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }