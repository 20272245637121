var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"locker_setting_list sub_new_style01 sub_ui_box1"},[_c('Search',{attrs:{"codeKey":"null","customSearchList":_vm.searchType.list,"searchObj":_vm.searchType.obj},on:{"change-search-type":_vm.$_changeSearchType,"onSearchClick":function($event){return _vm.searchData($event)}}}),_c('esp-dx-data-grid',{ref:"modelGrid",attrs:{"data-grid":_vm.dataGrid}}),_c('DxPopup',{attrs:{"show-title":true,"title":_vm.modal.title,"width":_vm.modal.width,"height":_vm.modal.height,"drag-enabled":true,"resize-enabled":true,"show-close-button":true,"hide-on-outside-click":false,"visible":_vm.modal.isOpened},on:{"hiding":function($event){return _vm.isOpenModal(false)}}},[[_c('esp-dx-data-grid',{ref:"expansionGrid",attrs:{"data-grid":_vm.expansionGrid}}),_c('DxToolbarItem',{attrs:{"widget":"dxButton","toolbar":"bottom","location":"center","visible":true,"options":{
          elementAttr: {
            class: 'default filled txt_S medium',
          },
          text: '확인',
          //type: 'default',
          width: '120',
          height: '40',
          useSubmitBehavior: true,
          onClick: () => {
            _vm.setExpansionId();
          },
        }}})]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }