<!--
  PACKAGE_NAME : src/pages/cc/ivr/dnis/modal-setting.vue
  FILE_NAME : modal-notice
  AUTHOR : hmlee
  DATE : 2024-09-04
  DESCRIPTION : 환경설정 설정 모달
-->
<template>
  <DxPopup
    :show-title="showTitle"
    :title="modal.title"
    :min-width="modal.minWidth"
    :width="modal.width"
    :min-height="modal.minHeight"
    :height="modal.height"
    :drag-enabled="modal.dragEnabled"
    :resize-enabled="modal.resizeEnabled"
    :show-close-button="modal.showCloseButton"
    :hide-on-outside-click="modal.hideOnOutsideClick"
    :visible="isOpen"
    @hiding="closeModal"
  >
    <template #content>
      <div class="grid grid-cols-1 gap-x-8">
        <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" @init-new-row="handleInitNewRow"></esp-dx-data-grid>
      </div>
    </template>

    <DxToolbarItem
      widget="dxButton"
      toolbar="bottom"
      location="center"
      :visible="true"
      :options="{
					elementAttr: {
						class: 'white filled txt_S medium',
					},
					text: this.$_lang('COMPONENTS.CLOSE', { defaultValue: '닫기' }),
					width: '120',
					height: '40',
					onClick: this.closeModal,
				}"
    />
  </DxPopup>
</template>

<script>
import {DxPopup, DxToolbarItem} from 'devextreme-vue/popup';
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid-v2.vue";
import CustomStore from "devextreme/data/custom_store";
import {isSuccess} from "@/plugins/common-lib";

export default {
  components: {
    EspDxDataGrid,
    DxPopup,
    DxToolbarItem,
  },
  props: {
    isOpen: {
      default: false,
      type: Boolean,
    },
    settingData: {
      default: null,
      type: Object,
    },
  },
  watch: {},
  computed: {
    /** @description: 모달 타이틀 출력 여부 */
    showTitle() {
      return !!this.modal.title;
    },
  },
  data() {
    return {
      modal: {
        title: this.$_lang('CC.WORD.CONFIG_SETTINGS', { defaultValue: '환경설정' }),
        minWidth: '300',
        width: '800',
        minHeight: '300',
        height: '500',
        dragEnabled: true,
        resizeEnabled: true,
        showCloseButton: true,
        hideOnOutsideClick: false,
      },
      config: {
        stylingMode: 'outlined',    //[outlined, filled, underlined]
        showClearButton: true,
        width: '100%',
        noticeList: [],
      },
      dataGrid: {
        callApi: 'CALL_CC_API',
        refName: 'settingGrid',
        dataSource: [], //dataSource
        height: '350',    // 주석처리시 100%
        apiActionNm: {
          //select: 'CC_IVR_DNIS_SETTING_LIST',
          merge: 'CC_IVR_DNIS_SETTING_MERGE',
          delete: 'CC_IVR_DNIS_SETTING_DELETE',
        },
        customEvent: {
          initNewRow: true,
        },
        showActionButtons: {
          select: false,
          update: true,
          delete: true,
          copy: false,
        },
        page: {
          enabled: false,
        },
        columns: [
          {
            caption: 'dnisId',
            dataField: 'dnisId',
            visible: false,
          },
          {
            caption: '키',
            i18n: 'CC.WORD.KEY',
            dataField: 'settingKey',
            requiredRule: {},
          },
          {
            caption: '값',
            i18n: 'CC.WORD.VALUE',
            dataField: 'settingValue',
            requiredRule: {},
          },
          {
            caption: '설명',
            i18n: 'CC.WORD.DESCRIPTION',
            dataField: 'description',
            width: '50%',
          },
          {
            caption: '사용여부',
            i18n: 'COMPONENTS.USE_STATUS',
            dataField: 'viewFl',
            lookup: {
              dataSource: this.$_enums.common.stringUsedFlag.values,
              displayExpr: 'label',
              valueExpr: 'value',
            },
          },
        ]
      },
    }
  },
  methods: {
    /** @description: 모달 닫기 */
    closeModal() {
      this.$emit('closeModal');
    },
    /** @description: 그리드 행 추가시 초기 설정 */
    handleInitNewRow(e) {
      e.data.dnisId = this.settingData.id;
      e.data.viewFl = 'Y';
    },
    /** @description: 그리드 데이터 조회 */
    selectDataList(sort = '+id') {
      this.dataGrid.dataSource = new CustomStore({
        key: 'id',
        load: async(loadOptions) => {
          const params = this.$_getDxDataGridParam(loadOptions);

          if (!params.sort) {
            params.sort = sort;
          }

          //dnis
          params.dnisId = this.settingData.id;

          const payload = {
            actionName: 'CC_IVR_DNIS_SETTING_LIST',
            data: params,
          }

          const res = await this.CALL_CC_API(payload);
          let rtnData = {
            data: [],
            totalCount: 0,
          }
          if (isSuccess(res)) {
            rtnData = {
              data: res.data.data,
              totalCount: res.data.header.totalCount,
            }
            this.$refs[this.dataGrid.refName].totalCount = rtnData.totalCount;
          }
          return rtnData;
        }
      });
    },
  },
  created() {
    this.selectDataList();
  },
}
</script>