<!--
  PACKAGE_NAME : src\pages\report\bi\modal-select-template.vue
  FILE_NAME : modal-select-template
  AUTHOR : dwlee
  DATE : 2024-12-11
  DESCRIPTION : BI 보고서 템플릿 선택 모달
-->
<template>
  <div>
    <div class="h-12">
      <DxButton
          text="템플릿 추가"
          class="btn_XS default filled add1"
          type="button"
          :height="30"
          @click="handleAddTemplate"
      />
      <DxButton
          text="템플릿 삭제"
          class="btn_XS white light_filled trash"
          :height="30"
          @click="handleDeleteTemplate"
      />
      <input
          type="file"
          ref="fileInput"
          class="hidden"
          accept=".xlsx, .xls"
          @change="handleFileChange"
      />
    </div>
    <div class="mb-3">
      <table class="table_form line-bin h-14">
        <colgroup>
          <col style="width:130px" />
          <col style="width:auto" />
        </colgroup>
        <tbody>
        <tr>
          <th scope="row">
            <label>템플릿</label>
          </th>
          <td>
            <div>
              <DxSelectBox
                placeholder="선택"
                :items="biTemplateList"
                display-expr="name"
                value-expr="id"
                :width="242"
                :value="templateId"
                :styling-mode="stylingMode"
                @value-changed="handleChangeTemplate"
              >
                <DxValidator ref="validator">
                  <DxRequiredRule
                      :message="$_lang('COMMON.MESSAGE.PLEASE_SELECT_TARGET', { defaultValue: '대상을 선택해주세요.' })"
                  />
                </DxValidator>
              </DxSelectBox>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-show="spreadJS.isShow === false" class="w-full border flex items-center justify-center" style="height:451px">
      <p class="text-center">템플릿을 선택하세요.</p>
    </div>
    <div v-show="spreadJS.isShow">
      <gc-spread-sheets
          ref="spreadSheet"
          style="width:860px;height:451px;"
          @workbookInitialized="initWorkbook"
      />
    </div>
  </div>
</template>

<script>
import {DxButton} from "devextreme-vue/button";
import {DxSelectBox} from "devextreme-vue/select-box";
import {getResData, isSuccess} from "@/plugins/common-lib";
import {DxRequiredRule, DxValidator} from "devextreme-vue/validator";

  export default {
    name: 'ModalSelectTemplate',
    components: {
      DxRequiredRule, DxValidator,
      DxButton,
      DxSelectBox,
    },
    props: {
      modalData: {
        type: Object,
        default: () => ({})
      },
    },
    data() {
      return {
        stylingMode: 'outlined',
        templateId: null,
        biTemplateList: [],
        spreadJS: {
          instance: null,
          workbook: null,
          excelIO: null,
          isShow: false,
        }
      };
    },
    methods: {
      /** 스프레드JS 임포트 */
      async initSpreadJS() {
        try {
          this.spreadJS.instance = this.modalData.spreadInstance;
          this.spreadJS.excelIO = this.modalData.spreadExcelIO;
        } catch (error) {
          console.error('SpreadJS initialization fail:', error);
          this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      /** 워크북 초기화 */
      async initWorkbook(spread) {
        this.spreadJS.workbook = spread;
      },
      /** 템플릿 목록 조회 */
      async getBiTemplateList() {
        const res = await this.CALL_REPORT_API({
          actionName: 'BI_TEMPLATE_LIST_BY_USER',
          loading: true,
        });
        this.biTemplateList = getResData(res);
      },
      /** 템플릿 추가 */
      handleAddTemplate() {
        this.$refs.fileInput.click();
      },
      /** 파일 변경 */
      async handleFileChange(e) {
        const file = e.target.files[0];
        if (!file) {
          return;
        }
        try {
          // 시트 초기화
          this.spreadJS.workbook.clearSheets();

          const reader = new FileReader();
          reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const blob = new Blob([data], { type: file.type });
            this.spreadJS.excelIO.open(blob,
              (json) => {
                // 스프레드 JS 적용
                this.setWorkbook(json);
                // DB에 저장
                this.saveBiTemplate(file.name, json);
              }, (error) => {
                console.error('File loading error:', error);
                  this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
                this.spreadJS.isShow = false;
              }
            );
          };
          reader.readAsArrayBuffer(file);
        } catch (error) {
          console.error('File loading error:', error);
          this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          this.spreadJS.isShow = false;
        }
      },
      /** 템플릿 삭제 */
      async handleDeleteTemplate(e) {
        if (this.templateId === null && !e.validationGroup.validate().isValid) {
          return this.$_Toast(this.$_lang('PLEASE_SELECT_TARGET', { defaultValue: '대상을 선택해주세요.' }));
        }

        if (!await this.$_Confirm(this.$_lang('CMN_CFM_DELETE_SELECTED', { defaultValue: '선택한 데이터를 삭제하시겠습니까?' }))) {
          return false;
        }

        const res = await this.CALL_REPORT_API({
          actionName: 'BI_TEMPLATE_DELETE',
          path: `/${this.templateId}`,
          data: {},
          loading: true,
        });

        if (isSuccess(res)) {
          await this.initBiTemplate();
          return this.$_Toast(this.$_lang('CMN_SUC_DELETE', { defaultValue: '정상적으로 삭제되었습니다.' }));
        }

        this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
      },
      /** 템플릿 초기화 */
      async initBiTemplate() {
        await this.getBiTemplateList();
        this.spreadJS.isShow = false;
        this.templateId = null;
        this.$refs.fileInput.value = '';
        this.$refs.validator.instance.reset();
      },
      /** 템플릿 변경 */
      async handleChangeTemplate(e) {
        this.templateId = e.value;

        if (!this.templateId) {
          this.spreadJS.isShow = false;
          return;
        }

        const template = this.biTemplateList.find(item => item.id === this.templateId);

        if(!template.content) {
          const res = await this.CALL_REPORT_API({
            actionName: 'BI_TEMPLATE_REPORT_SELECT',
            path: `/${this.templateId}`,
            data: {},
            loading: true,
          });

          if (!isSuccess(res)) {
            return;
          }

          template.content = getResData(res)[0].content;
        }

        const json = JSON.parse(template.content);
        this.setWorkbook(json);
      },
      /** 템플릿 미리보기 적용 */
      setWorkbook(json) {
        this.spreadJS.workbook.fromJSON(json);
        this.spreadJS.workbook.getActiveSheet().setColumnCount(200);
        this.spreadJS.isShow = true;
      },
      /** 템플릿 저장 */
      async saveBiTemplate(name, json) {
        const res = await this.CALL_REPORT_API({
          actionName: 'BI_TEMPLATE_SAVE',
          data: {
            name: name,
            content: JSON.stringify(json)
          },
          loading: true,
        });

        if (isSuccess(res)) {
          const target = getResData(res)[0];
          this.biTemplateList.push(target);
          this.templateId = target.id;
          return this.$_Toast(this.$_lang('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
        }
        this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
      },
      /** 템플릿 선택 */
      mountedData() {
        this.$_eventbus.$on('ModalSelectTemplate:onSaveData', async (e, resolve) => {
          if (this.templateId === null && !this.$refs.validator.instance.validate().isValid) {
            return this.$_Toast(this.$_lang('PLEASE_SELECT_TARGET', { defaultValue: '대상을 선택해주세요.' }));
          }

          if (!await this.$_Confirm('새 템플릿을 불러오면 현재 문서의 내용이 모두 삭제되고 덮어 씌워집니다. 계속 하시겠습니까?')) {
            return false;
          }

          resolve({
            status: 200,
            selectedRowsData: this.biTemplateList.find(item => item.id === this.templateId),
          });
          this.beforeDestroyedData();
        });
      },
      beforeDestroyedData() {
        this.$_eventbus.$off('ModalSelectTemplate:onSaveData');
      },
    },
    async created() {
      await this.initSpreadJS();
      await this.getBiTemplateList();
    },
    mounted() {
      this.mountedData();
    },
    beforeDestroy() {
      this.beforeDestroyedData();
    }
  };
</script>

<style scoped></style>
