<template>
	<transition>
		<div ref="contentsWrap" class="contents-wrap" id="contentsWrap">
			<!-- <div class="contents-title-box">
                <div class="contents-title">부서 정보</div>
            </div> -->
			<div class="contents-box ui-glid-box">
				<table class="table_form line-bin">
					<caption>
						<strong>직제순서 일괄변경</strong>
					</caption>
					<colgroup>
						<col style="width:130px;" />
						<col style="width:auto;" />
					</colgroup>

					<!-- <thead class="sub_title_txt">
                        <tr>
                            <td colspan="2">
                                <h2>
                                    대상 선택
                                </h2>
                            </td>
                        </tr>
                    </thead> -->

					<tbody>
						<tr>
							<!-- <th scope="row">
                                <label for="label01">
                                    찾을 부서명
                                    <span class="icon_require">필수항목</span>
                                </label>
                            </th> -->
							<td>
								<div class="grid-box">
									<DxCheckBox
										class="checkbox"
										v-model="config.isUpdated.depth1"
										text="수정"
										@value-changed="onCheckedUpdate($event, 1)"
									>
									</DxCheckBox>

									<DxDataGrid
										:data-source="deptData.defaultData.depth1List"
										:show-borders="true"
										:show-column-headers="true"
										:show-column-lines="false"
										:show-row-lines="true"
										:row-alternation-enabled="false"
										:allow-column-reordering="true"
										:no-data-text="noDataText()"
										width="100%"
										:height="400"
										:disabled="!config.isUpdated.depth1"
										key-expr="deptNm"
										@selection-changed="onSelectionData($event, 1)"
									>
										<DxColumn
											:caption="deptTitleByDepth('depth1')"
											data-field="deptNm"
											:width="120"
											alignment="left"
											:visible="true"
										/>

										<DxColumn caption="순서" data-field="deptOrd" :width="100" alignment="center" :visible="false" />

										<DxColumn
											caption=""
											data-field="newFl"
											cell-template="newFlTemplate"
											:width="30"
											alignment="center"
											:visible="true"
										/>
										<template #newFlTemplate="{ data }">
											<span v-if="data.value === 'Y'" class="new-flag">
												NEW
											</span>
										</template>

										<DxScrolling row-rendering-mode="virtual" />
										<DxPaging :enabled="false" :page-size="10" />

										<DxRowDragging :on-reorder="onReorderDepth1" :allow-reordering="true" :show-drag-icons="true" />
										<DxSelection mode="single" />
									</DxDataGrid>

									<div class="orderbtn-box">
										<DxButton
											template="<span class='mdi mdi-chevron-double-up'></span>"
											:width="25"
											:height="25"
											type="button"
											class="btn_XS white outlined"
											@click="onSetItemOrder('onItemTop', 1)"
										/>
										<DxButton
											template="<span class='mdi mdi-chevron-up'></span>"
											:width="25"
											:height="25"
											type="button"
											class="btn_XS white outlined"
											@click="onSetItemOrder('onItemUp', 1)"
										/>
										<DxButton
											template="<span class='mdi mdi-chevron-down'></span>"
											:width="25"
											:height="25"
											type="button"
											class="btn_XS white outlined"
											@click="onSetItemOrder('onItemDown', 1)"
										/>
										<DxButton
											template="<span class='mdi mdi-chevron-double-down'></span>"
											:width="25"
											:height="25"
											type="button"
											class="btn_XS white outlined"
											@click="onSetItemOrder('onItemBottom', 1)"
										/>
									</div>
								</div>

								<div class="grid-box">
									<DxCheckBox
										class="checkbox"
										v-model="config.isUpdated.depth2"
										text="수정"
										@value-changed="onCheckedUpdate($event, 2)"
									>
									</DxCheckBox>

									<DxDataGrid
										:data-source="deptData.defaultData.depth2List"
										:show-borders="true"
										:show-column-headers="true"
										:show-column-lines="false"
										:show-row-lines="true"
										:row-alternation-enabled="false"
										:allow-column-reordering="true"
										:no-data-text="noDataText()"
										width="100%"
										:height="400"
										:disabled="!config.isUpdated.depth2"
										key-expr="deptNm"
										@selection-changed="onSelectionData($event, 2)"
									>
										<DxColumn
											:caption="deptTitleByDepth('depth2')"
											data-field="deptNm"
											:width="120"
											alignment="left"
											:visible="true"
										/>

										<DxColumn caption="순서" data-field="deptOrd" :width="100" alignment="center" :visible="false" />

										<DxColumn
											caption=""
											data-field="newFl"
											cell-template="newFlTemplate"
											:width="30"
											alignment="center"
											:visible="true"
										/>
										<template #newFlTemplate="{ data }">
											<span v-if="data.value === 'Y'" class="new-flag">
												NEW
											</span>
										</template>

										<DxScrolling row-rendering-mode="virtual" />
										<DxPaging :enabled="false" :page-size="10" />
										<DxRowDragging
											:on-reorder="onReorderDepth2"
											:allow-reordering="config.dragging.allowReordering"
											:show-drag-icons="config.dragging.showDragIcons"
										/>
										<DxSelection mode="single" />
									</DxDataGrid>

									<div class="orderbtn-box">
										<DxButton
											template="<span class='mdi mdi-chevron-double-up'></span>"
											:width="25"
											:height="25"
											type="button"
											class="btn_XS white outlined"
											@click="onSetItemOrder('onItemTop', 2)"
										/>
										<DxButton
											template="<span class='mdi mdi-chevron-up'></span>"
											:width="25"
											:height="25"
											type="button"
											class="btn_XS white outlined"
											@click="onSetItemOrder('onItemUp', 2)"
										/>
										<DxButton
											template="<span class='mdi mdi-chevron-down'></span>"
											:width="25"
											:height="25"
											type="button"
											class="btn_XS white outlined"
											@click="onSetItemOrder('onItemDown', 2)"
										/>
										<DxButton
											template="<span class='mdi mdi-chevron-double-down'></span>"
											:width="25"
											:height="25"
											type="button"
											class="btn_XS white outlined"
											@click="onSetItemOrder('onItemBottom', 2)"
										/>
									</div>
								</div>

								<div class="grid-box">
									<DxCheckBox
										class="checkbox"
										v-model="config.isUpdated.depth3"
										text="수정"
										@value-changed="onCheckedUpdate($event, 3)"
									>
									</DxCheckBox>

									<DxDataGrid
										:data-source="deptData.defaultData.depth3List"
										:show-borders="true"
										:show-column-headers="true"
										:show-column-lines="false"
										:show-row-lines="true"
										:row-alternation-enabled="false"
										:allow-column-reordering="true"
										:no-data-text="noDataText()"
										width="100%"
										:height="400"
										:disabled="!config.isUpdated.depth3"
										key-expr="deptNm"
										@selection-changed="onSelectionData($event, 3)"
									>
										<DxColumn
											:caption="deptTitleByDepth('depth3')"
											data-field="deptNm"
											:width="100"
											alignment="left"
											:visible="true"
										/>

										<DxColumn caption="순서" data-field="deptOrd" :width="100" alignment="center" :visible="false" />

										<DxColumn
											caption=""
											data-field="newFl"
											cell-template="newFlTemplate"
											:width="50"
											alignment="center"
											:visible="true"
										/>
										<template #newFlTemplate="{ data }">
											<span v-if="data.value === 'Y'" class="new-flag">
												NEW
											</span>
										</template>

										<DxScrolling row-rendering-mode="virtual" />
										<DxPaging :enabled="false" :page-size="10" />
										<DxRowDragging
											:on-reorder="onReorderDepth3"
											:allow-reordering="config.dragging.allowReordering"
											:show-drag-icons="config.dragging.showDragIcons"
										/>
										<DxSelection mode="single" />
									</DxDataGrid>

									<div class="orderbtn-box">
										<DxButton
											template="<span class='mdi mdi-chevron-double-up'></span>"
											:width="25"
											:height="25"
											type="button"
											class="btn_XS white outlined"
											@click="onSetItemOrder('onItemTop', 3)"
										/>
										<DxButton
											template="<span class='mdi mdi-chevron-up'></span>"
											:width="25"
											:height="25"
											type="button"
											class="btn_XS white outlined"
											@click="onSetItemOrder('onItemUp', 3)"
										/>
										<DxButton
											template="<span class='mdi mdi-chevron-down'></span>"
											:width="25"
											:height="25"
											type="button"
											class="btn_XS white outlined"
											@click="onSetItemOrder('onItemDown', 3)"
										/>
										<DxButton
											template="<span class='mdi mdi-chevron-double-down'></span>"
											:width="25"
											:height="25"
											type="button"
											class="btn_XS white outlined"
											@click="onSetItemOrder('onItemBottom', 3)"
										/>
									</div>
								</div>

								<div class="grid-box">
									<DxCheckBox
										class="checkbox"
										v-model="config.isUpdated.depth4"
										text="수정"
										@value-changed="onCheckedUpdate($event, 4)"
									>
									</DxCheckBox>

									<DxDataGrid
										:data-source="deptData.defaultData.depth4List"
										:show-borders="true"
										:show-column-headers="true"
										:show-column-lines="false"
										:show-row-lines="true"
										:row-alternation-enabled="false"
										:allow-column-reordering="true"
										:no-data-text="noDataText()"
										width="100%"
										:height="400"
										:disabled="!config.isUpdated.depth4"
										key-expr="deptNm"
										@selection-changed="onSelectionData($event, 4)"
									>
										<DxColumn
											:caption="deptTitleByDepth('depth4')"
											data-field="deptNm"
											:width="100"
											alignment="left"
											:visible="true"
										/>

										<DxColumn caption="순서" data-field="deptOrd" :width="100" alignment="center" :visible="false" />

										<DxColumn
											caption=""
											data-field="newFl"
											cell-template="newFlTemplate"
											:width="50"
											alignment="center"
											:visible="true"
										/>
										<template #newFlTemplate="{ data }">
											<span v-if="data.value === 'Y'" class="new-flag">
												NEW
											</span>
										</template>

										<DxScrolling row-rendering-mode="virtual" />
										<DxPaging :enabled="false" :page-size="10" />
										<DxRowDragging
											:on-reorder="onReorderDepth4"
											:allow-reordering="config.dragging.allowReordering"
											:show-drag-icons="config.dragging.showDragIcons"
										/>
										<DxSelection mode="single" />
									</DxDataGrid>

									<div class="orderbtn-box">
										<DxButton
											template="<span class='mdi mdi-chevron-double-up'></span>"
											:width="25"
											:height="25"
											type="button"
											class="btn_XS white outlined"
											@click="onSetItemOrder('onItemTop', 4)"
										/>
										<DxButton
											template="<span class='mdi mdi-chevron-up'></span>"
											:width="25"
											:height="25"
											type="button"
											class="btn_XS white outlined"
											@click="onSetItemOrder('onItemUp', 4)"
										/>
										<DxButton
											template="<span class='mdi mdi-chevron-down'></span>"
											:width="25"
											:height="25"
											type="button"
											class="btn_XS white outlined"
											@click="onSetItemOrder('onItemDown', 4)"
										/>
										<DxButton
											template="<span class='mdi mdi-chevron-double-down'></span>"
											:width="25"
											:height="25"
											type="button"
											class="btn_XS white outlined"
											@click="onSetItemOrder('onItemBottom', 4)"
										/>
									</div>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</transition>
</template>

<script>
import {
	DxDataGrid,
	DxSelection,
	DxScrolling,
	DxPaging,
	DxRowDragging,
	DxColumn,
} from 'devextreme-vue/data-grid';
import { DxButton } from 'devextreme-vue/button';
import { DxCheckBox } from 'devextreme-vue/check-box';

import { isSuccess, cloneObj, setChangeOrder } from "@/plugins/common-lib";

export default {
	components: {
		DxDataGrid,
		DxSelection,
		DxScrolling,
		DxPaging,
		DxRowDragging,
		DxColumn,
		DxButton,
		DxCheckBox,
	},
	props: {
		contentData: Object,
		deptList: Array,
	},
	watch: {},
	data() {
		return {
			config: {
				stylingMode: 'outlined',
				isUpdated: {
					depth1: false,
					depth2: false,
					depth3: false,
					depth4: false,
				},
				dragging: {
					allowDropInsideItem: false,
					allowReordering: true,
					showDragIcons: true,
				},
			},
			deptData: {
				originList: [],
				originData: {
					//depth별 원본 데이터
					depth1List: [],
					depth2List: [],
					depth3List: [],
					depth4List: [],
				},
				defaultData: {
					//depth별 기본 데이터
					depth1List: [],
					depth2List: [],
					depth3List: [],
					depth4List: [],
				},
				clickData: {
					//depth별 선택한 데이터
					depth1List: {},
					depth2List: {},
					depth3List: {},
					depth4List: {},
				},
				updatedData: {
					//뎁스별 순서 업데이트된 데이터
					depth1List: [],
					depth2List: [],
					depth3List: [],
					depth4List: [],
				},
				updatedMergeList: [], //순서 업데이트된 데이터 머지 리스트
			},
		};
	},
	computed: {
		/** @description: props 리스트에서 선택한 데이터 */
		getApiActionNm() {
			return this.contentData.apiActionNm;
		},
		/** @description: 부서 타이틀 가져오기 */
		deptTitles() {
			return this.$_getCode('euc_dept_title').filter(d => d.delFl === 'N');
		},
	},
	methods: {
		/** @description: 툴팁 이벤트 */
		setTooltips(key) {
			this.config.isTooltip[key] = !this.config.isTooltip[key];
		},
		/** @description: 뎁스별 부서 타이틀 */
		deptTitleByDepth(depth) {
			return this.deptTitles.find(d => d.codeValue === depth).codeNm;
		},
		/** @description: 수정 체크박스 이벤트 */
		onCheckedUpdate() {
		},
		/** @description : 트리 로우 데이터 클릭 이벤트 */
		onSelectionData(e, depth) {
			this.deptData.clickData[`depth${depth}List`] = e.selectedRowsData[0];
		},
		/** @description: 순서 변경 메서드 */
		onSetItemOrder(type, depth) {
			let selectedRow = cloneObj([this.deptData.clickData[`depth${depth}List`]]);
			if (selectedRow.length === 1) {
				let deptList = this.deptData.defaultData[`depth${depth}List`];
				let index = deptList.findIndex(d1 => selectedRow.some(d2 => d1.idx === d2.idx));
				switch (type) {
					case 'onItemTop':
						setChangeOrder(deptList, index, 0);
						break;
					case 'onItemUp':
						setChangeOrder(deptList, index, index - 1);
						break;
					case 'onItemDown':
						setChangeOrder(deptList, index, index + 1);
						break;
					case 'onItemBottom':
						setChangeOrder(deptList, index, deptList.length - 1);
						break;
				}
			} else {
				return this.$_Msg(this.$_lang('CMN_ONE_SELECT'));
			}
		},
		/** @description: depth1 드래그 순서 변경 이벤트  */
		onReorderDepth1(e) {
			let depth = 1;
			this.setReorder(e, depth);
		},
		/** @description: depth2 드래그 순서 변경 이벤트  */
		onReorderDepth2(e) {
			let depth = 2;
			this.setReorder(e, depth);
		},
		/** @description: depth3 드래그 순서 변경 이벤트  */
		onReorderDepth3(e) {
			let depth = 3;
			this.setReorder(e, depth);
		},
		/** @description: depth2 드래그 순서 변경 이벤트  */
		onReorderDepth4(e) {
			let depth = 4;
			this.setReorder(e, depth);
		},
		/** @description: 드래그 순서 변경 이벤트  */
		setReorder(e, depth) {
			let visibleRows = e.component.getVisibleRows();
			let sourceData = e.itemData;
			let targetData = visibleRows[e.toIndex].data;
			let deptList = [...this.deptData.defaultData[`depth${depth}List`]];

			if (e.dropInsideItem) {
				e.itemData.Head_ID = targetData.deptNm;
				e.component.refresh();
			} else {
				let sourceIndex = deptList.indexOf(sourceData),
					targetIndex = deptList.indexOf(targetData);

				if (sourceData.Head_ID !== targetData.Head_ID) {
					sourceData.Head_ID = targetData.Head_ID;
					if (e.toIndex > e.fromIndex) {
						targetIndex++;
					}
				}

				deptList.splice(sourceIndex, 1);
				deptList.splice(targetIndex, 0, sourceData);
				this.deptData.defaultData[`depth${depth}List`] = deptList;
			}
		},

		/** @description : 초기 데이터 promise all로 가져오기 */
		async callPromiseAllInitData() {
			let payload;
			let apiList = [];

			payload = this.callApiPayloadAsyncDeptData();
			apiList.push(await this.CALL_API(payload));

			payload = this.callApiPayloadDeptDataByDepth(1);
			apiList.push(await this.CALL_API(payload));

			payload = this.callApiPayloadDeptDataByDepth(2);
			apiList.push(await this.CALL_API(payload));

			payload = this.callApiPayloadDeptDataByDepth(3);
			apiList.push(await this.CALL_API(payload));

			payload = this.callApiPayloadDeptDataByDepth(4);
			apiList.push(await this.CALL_API(payload));

			payload = this.callApiPayloadDeptData();
			apiList.push(await this.CALL_API(payload));

			let returnDatas = await Promise.all(apiList);

			this.asyncDeptDatas(returnDatas[0]);
			this.selectDepth1Datas(returnDatas[1]);
			this.selectDepth2Datas(returnDatas[2]);
			this.selectDepth3Datas(returnDatas[3]);
			this.selectDepth4Datas(returnDatas[4]);
			this.selectDeptDatas(returnDatas[5]);
		},
		/** @description: dept 데이터 조회 payload */
		callApiPayloadDeptData() {
			let paramsData = { sort: '+depth,+deptOrd', pagesize: 100000 };
			let payload = {
				actionName: 'DEPT_LIST_ALL_VIEW',
				data: { params: paramsData },
				loading: true,
			};

			return payload;
		},
		/** @description: dept 테이블의 데이터를 동기화 처리 */
		callApiPayloadAsyncDeptData() {
			let payload = {
				actionName: 'DEPT_ORD_LIST_ASYNC',
				loading: true,
			};

			return payload;
		},
		/** @description: depth별 dept 데이터 조회 payload */
		callApiPayloadDeptDataByDepth(depth) {
			let paramData = { depth: depth, sort: '+deptOrd' };

			let payload = {
				actionName: 'DEPT_ORD_LIST_ALL',
				data: { params: paramData },
				loading: true,
			};

			return payload;
		},
		/** @description: 부서 테이블의 데이터 동기화 */
		async asyncDeptDatas(res) {
      if( isSuccess(res) ) {
          console.log('부서 테이블 데이터 동기화 >>> ', res.data.data);
      }
		},
		/** @description: depth1 부서 정보 가져오기 */
		async selectDepth1Datas(res) {
      if( isSuccess(res) ) {
        this.deptData.defaultData.depth1List = res.data.data.map((d, i) => {
          d.idx = i;
          return { ...d };
        });

        this.deptData.originData.depth1List = cloneObj(this.deptData.defaultData.depth1List);
      }
		},
		/** @description: depth2 부서 정보 가져오기 */
		async selectDepth2Datas(res) {
      if( isSuccess(res) ) {
        this.deptData.defaultData.depth2List = res.data.data.map((d, i) => {
          d.idx = i;
          return { ...d };
        });

        this.deptData.originData.depth2List = cloneObj(this.deptData.defaultData.depth2List);
      }
		},
		/** @description: depth3 부서 정보 가져오기 */
		async selectDepth3Datas(res) {
      if( isSuccess(res) ) {
        this.deptData.defaultData.depth3List = res.data.data.map((d, i) => {
          d.idx = i;
          return { ...d };
        });

        this.deptData.originData.depth3List = cloneObj(this.deptData.defaultData.depth3List);
      }
		},
		/** @description: depth4 부서 정보 가져오기 */
		async selectDepth4Datas(res) {
      if( isSuccess(res) ) {
        this.deptData.defaultData.depth4List = res.data.data.map((d, i) => {
          d.idx = i;
          return { ...d };
        });

        this.deptData.originData.depth4List = cloneObj(this.deptData.defaultData.depth4List);
      }
		},
		/** @description: 부서 정보 가져오기 */
		async selectDeptDatas(res) {
      if( isSuccess(res) ) {
        //기존 테이블과 view 테이블의 PK 컬럼명이 달라 id로 맞춰줌
        let data = cloneObj(res.data.data).map(d => {
          d.id = d.deptId;
          return { ...d };
        });
        this.deptData.originList = data;
      }
		},
		/** @description: 데이터 없는 경우 */
		noDataText(length) {
			if (length === 0) {
				return '데이터를 불러오는 중입니다.';
			}
		},
		/** @description : 라이프사이클 creaed시 호출되는 메서드 */
		createdData() {
			this.callPromiseAllInitData();
		},
		/** @description : 라이프사이클 mounted시 호출되는 메서드 */
		mountedData() {
			this.$_eventbus.$on('ModalUpdateOrganOrder:onSaveData', async (e, resolve) => {
				let checkDepths = [];
				Object.entries(this.config.isUpdated).map(([key, value]) => {
					if (value) {
						checkDepths.push(Number(key.split('depth')[1]));
					}
				});

				//뎁스별 부서 수정체크박스 체크
				if (checkDepths.length === 0) {
					return this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
				}

				//체크된 뎁스 순서 변경
				checkDepths.forEach(depth => {
					let updatedData = cloneObj(this.deptData.defaultData[`depth${depth}List`]);
					this.deptData.updatedData[`depth${depth}List`] = updatedData.map((d, index) => {
						let json = {};
						json.id = d.id;
						json.idx = index;
						json.depth = d.depth;
						json.deptOrd = index + 1;
						json.deptNm = d.deptNm;
						json.newFl = 'N';
						return { ...json };
					});
				});

				//직제순서 데이터 변경 체크
				let isUpdatedOrd = false;
				for (let depth of checkDepths) {
					isUpdatedOrd = this.deptData.originData[`depth${depth}List`].some(item1 => {
						return !this.deptData.updatedData[`depth${depth}List`].some(
							item2 => item1.deptNm === item2.deptNm && item1.deptOrd === item2.deptOrd,
						);
					});
					//해당 뎁스 수정체크 && 직제 순서 변경 체크
					if (this.deptData.clickData[`depth${depth}List`] && !isUpdatedOrd) {
						return this.$_Msg(`해당 뎁스의 변경된 부서 순서 데이터가 없습니다.`);
					}
				}

				//체크된 뎁스 리스트 하나로 merge
				this.deptData.updatedMergeList = [];
				checkDepths.forEach(depth => {
					this.deptData.updatedMergeList = [...this.deptData.updatedMergeList, ...this.deptData.updatedData[`depth${depth}List`]];
				});

				let data = {
					data: this.deptData.updatedMergeList,
				};

				if (await this.$_Confirm('직제순서를 일괄 변경 하시겠습니까?')) {
					let payload;

					payload = {
						actionName: 'DEPT_ORD_LIST_UPDATE',
						data: data,
						loading: true,
					};

					let res = await this.CALL_API(payload);
          if( isSuccess(res) ) {
              this.$_Toast('직제순서가 일괄 변경되었습니다.');
              resolve(200);
          }
				}
			});
		},
		/** @description : 라이프사이클 destroyed시 호출되는 메서드 */
		destroyedData() {
			this.$_eventbus.$off('ModalUpdateOrganOrder:onSaveData');
		},
	},
	created() {
		this.createdData();
	},
	mounted() {
		this.mountedData();
	},
	destroyed() {
		this.destroyedData();
	},
};
</script>

<style scoped>
.contents-title-box {
	height: 40px;
	position: relative;
	border-bottom: 1px solid #ccc;
	line-height: 40px;
}
.contents-title-box .contents-title {
	height: 40px;
	padding-left: 5px;
	display: inline-block;
	font-size: 0.9em;
}

.contents-box {
	width: 100%;
	background-color: #fff;
}
.contents-box .table_form td {
	padding: 10px 0;
}

.table_form td > .grid-box {
	width: 230px;
	position: relative;
	display: inline-block;
	vertical-align: middle;
}
.table_form td > .grid-box .orderbtn-box {
	position: absolute;
	right: 0;
	margin-top: 4px;
	display: inline-block;
}
.table_form td > .grid-box .orderbtn-box .btn_XS {
	padding: 0;
}
.table_form td > div:not(:last-child) {
	margin-right: 10px;
}

.new-flag {
	color: tomato;
	font-size: 0.8em;
}
</style>
