<template>
	<div class="page-sub-box">
		<div class="clearfix ui-glid-box top-box">
			<div class="fl per90" id="textClass">
				<table class="table_form line-bin">
					<caption>
						<strong>위젯 기본 설정</strong>
					</caption>
					<colgroup>
						<col style="width: 20%" />
						<col style="width: 50%" />
						<col style="width: 20%" />
						<col style="width: 50%" />
					</colgroup>
					<thead class="sub_title_txt line_bottom_1px">
						<tr>
							<td colspan="4">
								<h2 class="px-6">위젯 기본 설정</h2>
							</td>
						</tr>
					</thead>
					<tbody>
						<tr>
							<th scope="col">
								<label for="label5">
									<span>구분</span>
									<span class="icon_require">필수항목</span>
								</label>
							</th>
							<td class="clearfix">
								<DxSelectBox
									:items="page.dashboardCdDataList"
									v-model="search.dashboardType"
									placeholder="구분 선택"
									display-expr="name"
									value-expr="id"
									:styling-mode="stylingMode"
									:disabled="this.mode === 'loadDashBoard'"
									@value-changed="onChangeSearchDashboardType"
									width="300px"
								>
									<DxValidator validation-group="validationGroupName">
										<DxRequiredRule message="구분선택은 필수 입니다." />
									</DxValidator>
								</DxSelectBox>
							</td>
							<th scope="col">
								<label for="label5">
									<span>위젯 유형</span>
									<span class="icon_require">필수항목</span>
								</label>
							</th>
							<td class="clearfix">
								<DxSelectBox
									:items="page.widgetCdDataList"
									v-model="search.widgetType"
									placeholder="위젯 유형 선택"
									display-expr="name"
									value-expr="id"
									:styling-mode="stylingMode"
									:disabled="this.mode === 'loadDashBoard'"
									@value-changed="onChangeDataSet(search)"
									width="300px"
								>
									<DxValidator validation-group="validationGroupName">
										<DxRequiredRule message="위젯유형 선택은 필수 입니다." />
									</DxValidator>
								</DxSelectBox>
							</td>
						</tr>
						<tr>
							<th scope="col">
								<label for="label5">
									<span>위젯명</span>
									<span class="icon_require">필수항목</span>
								</label>
							</th>
							<td class="clearfix">
								<DxTextBox
									v-model="actionData.title"
									placeholder="위젯명 입력"
									width="300px"
									:styling-mode="stylingMode"
									class="mar_ri10"
								>
									<DxValidator validation-group="validationGroupName">
										<DxRequiredRule message="위젯명 입력은 필수 입니다." />
									</DxValidator>
								</DxTextBox>
							</td>
							<th scope="col">
								<label for="label5">
									<span>사용여부</span>
								</label>
							</th>
							<td>
								<DxSwitch v-model="viewCd" />
							</td>
						</tr>
						<tr>
							<th scope="col">
								<label for="label5">
									<span>위젯 사이즈 사용자 편집</span>
								</label>
							</th>
							<td>
								<DxRadioGroup
										v-model="resizeFl"
										:items="resizeData"
										value-expr="id"
										display-expr="text"
										layout="horizontal"
								/>
								<!-- <DxCheckBox v-model="resizeFl"></DxCheckBox> -->
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>

		<div class="clearfix ui-glid-box top-box">
			<div class="fl per90" style="height: 106px">
				<table class="table_form line-bin">
					<caption>
						<strong>데이터 설정</strong>
					</caption>
					<colgroup>
						<col style="width: 20%" />
						<col style="width: 50%" />
						<col style="width: 20%" />
						<col style="width: 50%" />
					</colgroup>
					<thead class="sub_title_txt line_bottom_1px">
						<tr>
							<td colspan="4">
								<h2 class="px-6">데이터 설정</h2>
							</td>
						</tr>
					</thead>
					<tbody v-if="dataDisplay">
						<tr>
							<th scope="col">
								<label for="label5">
									<span>조회 데이터</span>
									<span class="icon_require">필수항목</span>
								</label>
							</th>
							<td class="clearfix">
								<DxSelectBox
									placeholder="조회 데이터 선택"
									:items="tab1.page.targetQuery_item"
									:display-expr="targetQueryDisplayExpr"
									value-expr="name"
									v-model="tab1.page.targetQuery_item_value"	
									width="300px"
									:styling-mode="stylingMode"
									:height="30"
									:disabled="this.mode === 'loadDashBoard'"
									@value-changed="onChangeSearchTypeRefresh"
								>
									<DxValidator validation-group="validationGroupName">
										<DxRequiredRule message="조회 데이터는 필수 입니다." />
									</DxValidator>
								</DxSelectBox>
							</td>
							<!-- 정렬을 위한 th, td 값 -->
							<td></td>
							<td class="clearfix"><div style="width: 300px"></div></td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div class="clearfix ui-glid-box top-box" v-if="dataDisplay">
			<div class="fl per100" style="height: 378px">
				<div id="searchFirst" class="page-sub-box w-full pl-5" style="height: 200px">
					<table class="line-bin" style="padding: 0px">
						<colgroup>
							<col style="width: 30%" />
							<col style="width: 1%" />
							<col style="width: 30%" />
							<col style="width: 1%" />
							<col style="width: 30%" />
						</colgroup>
						<thead class="sub_title_txt">
							<tr>
								<td>
									<h2>조회 데이터</h2>
								</td>
								<td>
									<h2></h2>
								</td>
								<td>
									<h2>조회 그룹</h2>
								</td>
								<td>
									<h2></h2>
								</td>
								<td>
									<h2>조회 항목</h2>
								</td>
							</tr>
						</thead>

						<tbody>
							<tr>
								<td style="border: 1ch">
									<DxDataGrid
										class="grid-box"
										id="grid"
										:data-source="tab1.grid.columnList"
										:show-borders="true"
										:show-column-headers="true"
										:show-column-lines="false"
										:show-row-lines="true"
										:row-alternation-enabled="false"
										:allow-column-reordering="true"
										:no-data-text="noDataTextTarget(tab1.grid.columnList.length, 1)"
										:selected-row-keys="tab1.grid.selectedItemKeys"
										@selection-changed="tab1.grid.selectionChanged"
										width="75%"
										height="300"
									>
										<DxPaging :page-size="2000" />
										<DxColumn
											caption="항목명"
											data-field="caption"
											:fixed="false"
											alignment="center"
											:visible="true"
											cell-template="titleTemplate"
										/>
										<template #titleTemplate="{ data }">
											{{ data.value }}
										</template>
										<DxColumn caption="구분값" 기 data-field="axisName" :fixed="false" alignment="center" />
										<DxColumn
											caption="적용"
											cell-template="moveTemplate"
											:fixed="false"
											alignment="center"
											width="80"
										/>
										<template #moveTemplate="{ data }">
											<button
												class="btn_XS white2 add3"
												type="button"
												style="padding-left: 19px;" 
												@click="onRemoveItemButton({ data })"
											></button>
										</template>
									</DxDataGrid>
								</td>
<!--								 <td><img src="@/assets/images/icon/arrow_icon02.png" /></td> -->
								<td></td>
								<td style="border: 1ch">
									<DxDataGrid
										class="grid-box"
										id="grid"
										:data-source="tab2.grid.columnList"
										:show-borders="true"
										:show-column-headers="true"
										:show-column-lines="false"
										:show-row-lines="true"
										:row-alternation-enabled="false"
										:allow-column-reordering="true"
										:no-data-text="noDataTextTarget(tab2.grid.columnList.length, 2)"
										:selected-row-keys="tab2.grid.selectedItemKeys"
										@selection-changed="tab2.grid.selectionChanged"
										width="75%"
										height="300"
									>
										<DxPaging :page-size="2000" />
										<!-- <DxRowDragging :on-add="onAddItem" group="tasksGroup" /> -->
										<!-- <DxColumn :group-index="0" data-field="format" /> -->
										<DxColumn
											caption="항목명"
											data-field="caption"
											:fixed="false"
											alignment="center"
											:visible="true"
											cell-template="titleTemplate"
										/>
										<template #titleTemplate="{ data }">
											{{ data.value }}
										</template>
										<!-- <DxColumn
											caption="영문명"
											기
											data-field="dataField"
											:fixed="false"
											alignment="center"
										/> -->
										<DxColumn
											caption="해제"
											cell-template="moveTemplate"
											:width="80"
											:fixed="false"
											alignment="center"
										/>
										<template #moveTemplate="{ data }">
											<button class="btn_XS white2 del1" type="button" style="padding-left: 19px;" @click="onAddItemButton({ data })"></button>
										</template>
									</DxDataGrid>
								</td>
								<!-- <td><img src="@/assets/images/icon/arrow_icon02.png" /></td> -->
								<td></td>
								<td style="border: 1ch">
									<DxDataGrid
										class="grid-box"
										id="grid"
										:data-source="tab3.grid.columnList"
										:show-borders="true"
										:show-column-headers="true"
										:show-column-lines="false"
										:show-row-lines="true"
										:row-alternation-enabled="false"
										:allow-column-reordering="true"
										:no-data-text="noDataTextTarget(tab3.grid.columnList.length, 3)"
										:selected-row-keys="tab3.grid.selectedItemKeys"
										@selection-changed="tab3.grid.selectionChanged"
										width="75%"
										height="300"
									>
										<DxPaging :page-size="2000" />
										<!-- <DxRowDragging :on-add="onAddItem" group="tasksGroup" /> -->
										<!-- <DxColumn :group-index="0" data-field="format" /> -->
										<DxColumn
											caption="항목명"
											data-field="caption"
											:fixed="false"
											alignment="center"
											:visible="true"
											cell-template="titleTemplate"
										/>
										<template #titleTemplate="{ data }">
											{{ data.value }}
										</template>
										<!-- <DxColumn
											caption="영문명"
											기
											data-field="dataField"
											:fixed="false"
											alignment="center"
										/> -->
										<DxColumn
											caption="해제"
											cell-template="moveTemplate"
											:width="80"
											:fixed="false"
											alignment="center"
										/>
										<template #moveTemplate="{ data }">
											<button class="btn_XS white2 del1" style="padding-left: 19px;" type="button" @click="onAddItemButton({ data })"></button>
										</template>
									</DxDataGrid>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>

		<div class="clearfix ui-glid-box top-box" v-if="dataDisplay">
			<div class="fl per90">
				<table class="table_form line-bin">
					<caption>
						<strong>위젯 기본 설정</strong>
					</caption>
					<colgroup>
						<col style="width: 18%" />
						<col style="width: 82%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row">
								<label for="label5">
									<span>날짜/시간 유형</span>
									<span class="icon_require">필수항목</span>
								</label>
							</th>
							<td class="clearfix">
								<DxSelectBox
									v-model="search.dateGroupType"
									width="300px"
									placeholder="선택"
									:items="page.dateCdDateList"
									display-expr="name"
									value-expr="id"
									:styling-mode="stylingMode"
									@value-changed="onChangeSearchDateType"
								>
									<DxValidator validation-group="validationGroupName">
										<DxRequiredRule message="날짜/시간 선택은 필수 입니다." />
									</DxValidator>
								</DxSelectBox>
							</td>
							<!-- 정렬을 위한 th, td 값 -->
							<td></td>
							<td></td>
						</tr>
						<tr>
							<th scope="row">
								<label for="label5">
									<span>기본 조회 기간</span>
									<span class="icon_require">필수항목</span>
								</label>
							</th>
							<td class="clearfix fl">
								<DxSelectBox
									v-model="search.durationType"
									width="300px"
									placeholder="기간 유형 선택"
									:items="page.durationDataList"
									display-expr="name"
									value-expr="id"
									:styling-mode="stylingMode"
									@value-changed="onChangeDuration"
									:disabled="search.durationSelectBoxCheck"
								>
									<DxValidator validation-group="validationGroupName">
										<DxRequiredRule message="기간 유형 선택은 필수 입니다." />
									</DxValidator>
								</DxSelectBox>
							</td>
							<td ref="period1" class="clearfix fl" :style="{ display: isVisible ? 'table-cell' : 'none' }">
								<label for="label5">
									<span>최근</span>
								</label>
								<div class="inline-block pl-2 pr-2">
                  <DxNumberBox
                    v-model="search.recentValue"
                    placeholder="입력"
                    :min="0"
                    format="#0"
                    :show-spin-buttons="true"
                    :show-clear-button="true"
                    :styling-mode="stylingMode"
                    :width="120"
                  />
								</div>
								{{ periodStr }}
							</td>
							<td></td>
							<td></td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>

		<section class="terms bottom-btn-box">
			<div class="page-sub-box">
				<div class="bottom-btn-wrap">
					<DxButton
						:text="this.mode != 'loadDashBoard' ? '등 록' : '저 장'"
						:width="120"
						:height="40"
						class="default filled txt_S medium"
						validation-group="validationGroupName"
						@click="onSave"
					/>
					<DxButton text="취 소" :width="120" :height="40" class="white filled txt_S medium" @click="goBack" />
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxNumberBox } from 'devextreme-vue/number-box';
import { DxSwitch } from 'devextreme-vue/switch';
import { DxButton } from 'devextreme-vue/button';
import { isSuccess } from '@/plugins/common-lib';
import { DxRadioGroup } from 'devextreme-vue/radio-group';
import { DxDataGrid, DxColumn, DxPaging } from 'devextreme-vue/data-grid';
import { DASHBOARD_SEARCH_BOX } from '@/pages/report/dashboard';

import moment from 'moment';

const currentByFormat = format => {
	return moment().format(format);
};

export default {
	components: {
		DxValidator,
		DxRequiredRule,
		DxButton,
		DxSwitch,
		DxTextBox,
		DxSelectBox,
		DxNumberBox,
		DxDataGrid,
		DxColumn,
		DxPaging,
		DxRadioGroup,
	},
	props: {
		sendData: {
			type: Object,
		},
		mode: {
			type: String,
		},
	},
	watch: {
		'actionData.siteId': function (newVal) {
			this.getImageList(newVal);
		},
		'actionData.prologueImgId': function (newVal) {
			if (newVal) {
				this.selectedPrologue = this.imageList.find(image => image.id === newVal);
			} else {
				this.selectedPrologue = {};
			}
		},
		'actionData.epilogueImgId': function (newVal) {
			if (newVal) {
				this.selectedEpilogue = this.imageList.find(image => image.id === newVal);
			} else {
				this.selectedEpilogue = {};
			}
		},
	},
	computed: {},
	data() {
		return {
			stylingMode: 'outlined',
			actionData: {
				prologueImgId: null,
				epilogueImgId: null,
			},
			dataDisplay: false,
			xPixel: '',
			yPixel: '',
			isChecked: false,
			viewCd: true,
			resizeFl: 0, // 기본 사이즈
			widgetId: 0,
			imageList: [],
			selectedPrologue: {},
			selectedEpilogue: {},
			prologueImageSource: null,
			epilogueImageSource: null,
			periodStr: '',
			accumulateStr: '',
			resultColumn: [],
			isVisible: false,  // 초기값을 false로 설정하여 td가 보이지 않게 함
			page: {
				dashboardCdDataList: [],
				reportGubunCdDataList: [],
				widgetCdDataList: [],
				dateCdDateList: [],
				durationDataList: [],
			},
			search: {
				defaultObj: { id: '0', codeNm: '전체' },
				defaultValue: null,
				dashboardType: null,
				dateGroupType: null,
				widgetType: null,
				DateTerm: null,
				dateType: null,
				text: null,
				durationType: null,
				durationSelectBoxCheck: true,
				yearStart: null,
				yearEnd: null,
				monthStart: null,
				monthEnd: null,
				dayStart: new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().substr(0, 10),
				dayEnd: new Date(new Date().setDate(new Date().getDate())).toISOString().substr(0, 10),
				bePeriodYear: null,
				afPeriodYear: null,
				bePeriodMonth: null,
				afPeriodMonth: null,
				currentDate: currentByFormat('YYYY-MM-DD'),
				currentMonth: currentByFormat('MM'),
				currentYear: currentByFormat('YYYY'),
				recentValue: null,
			},
			resizeData : [
				{id : 0, text: "기본 사이즈로 고정"},
				{id : 1, text: "사이즈 변경 허용"},
			],
			tab1: {
				search: {
					defaultObj: { id: 'all', menuNm: '전체' },
					defaultValue: 'all',
					gubunType: 'all', //tab2
					reportGroup_item_value: null,
					reportName: null,
					subMenu_item_value: '',
					subMenu_item_value_load: '',
					target_text: '',
					type_load: null,
					obj_type_list: null,
				},

				page: {
					sizeList: [],
					size: null,
					reportGubunCdDataList: [],
					reportGroup_item: [],
					subMenu_item: [],
					reportGroup_item_load: [],
					targetQuery_item: [],
					targetQuery_item_value: null,
					column_config_flag: '',
					column_data: {},
					roles: [],
				},

				grid: {
					columnList: [],
					//previewData: [],
					selectedItemKeys: [],
					selectionChanged: data => {
						this.tab1.grid.selectedItemKeys = data.selectedRowKeys;
					},
				},
			},

			tab2: {
				page: {
					reportStatus_item: [
						{ codeNm: '전체', codeValue: '' },
						{ codeNm: '임시저장', codeValue: '0' },
						{ codeNm: '저장완료', codeValue: '1' },
					],
				},
				grid: {
					columnList: [],
					ReportList: [],
					infoList: [],
					selectedItemKeys: [],
					selectionChanged: data => {
						//this.$log.debug(data);
						this.tab2.grid.selectedItemKeys = data.selectedRowKeys;
					},
				},
				search: {
					defaultObj: { id: 'all', menuNm: '전체' },
					defaultValue: 'all',
					gubunType: 'all', //tab2
					reportGroup_item_value: null,
					reportName: null,
					subMenu_item_value: '',
					subMenu_item_value_load: '',
					target_text: '',
					type_load: null,
				},
			},

			tab3: {
				page: {
					reportStatus_item: [
						{ codeNm: '전체', codeValue: '' },
						{ codeNm: '임시저장', codeValue: '0' },
						{ codeNm: '저장완료', codeValue: '1' },
					],
				},
				grid: {
					columnList: [],
					infoList: [],
					selectedItemKeys: [],
					selectionChanged: data => {
						//this.$log.debug(data);
						this.tab2.grid.selectedItemKeys = data.selectedRowKeys;
					},
				},
				search: {
					defaultObj: { id: 'all', menuNm: '전체' },
					defaultValue: 'all',
					gubunType: 'all', //tab2
					reportGroup_item_value: null,
					reportName: null,
					subMenu_item_value: '',
					subMenu_item_value_load: '',
					target_text: '',
					type_load: null,
				},
			},
			ctiType: '',
		};
	},
	methods: {
		async loadDashBoard(val) {
			this.mode = 'loadDashBoard';
			this.updateYn = false;
			this.dataDisplay = true;
			this.reqParams = val.data === undefined ? val : val.data.row.data;
			this.tab1.page.targetQuery_item_value = this.reqParams.targetQuery;
			this.loadReportNm = this.reqParams.menuNm;
			this.widgetId = this.reqParams.id;
			await this.selectDataList(this.reqParams);
			await this.onChangeSearchTypeRefresh();
		},
		/**
		 * selectDataList 보고서 항목 리스트 세팅
		 */
		async selectDataList(params) {
			let selectData = [];

			const payload = {
				actionName: 'WIDGETTP_LIST_ALL',
				data: { id: params.id },
				loading: true,
			};

			const res = await this.CALL_REPORT_API(payload);
			if (isSuccess(res)) {
				selectData = res.data.data;
			}

			this.search.dashboardType = selectData[0].widgetShapeTypeFl;
			this.search.widgetType = String(selectData[0].chartTypeCd);
			this.actionData.title = selectData[0].title;
			this.actionData.columnList = selectData[0].columnList;
			this.actionData.columnNameList = selectData[0].columnNameList;
			this.actionData.columnGroupList = selectData[0].columnGroupList;
			this.actionData.columnGroupNameList = selectData[0].columnGroupNameList;
			// this.xPixel = selectData[0].width;
			// this.yPixel = selectData[0].height;
			this.viewCd = selectData[0].viewCd == 1 ? true : false;
			this.resizeFl = selectData[0].resizeFl;
			this.search.dateGroupType = String(selectData[0].dateGroupCode);
			this.search.durationType = selectData[0].durationType;
			this.widgetId = selectData[0].id;
			
			// 212:년별 213:시간별 211:월별 210:일별
			// 최근조회
			if (selectData[0].durationType === '1130') {
				this.search.recentValue = selectData[0].recent;
				// 누적 기간 조회
			} 
		},
		async onSave(e) {
			let searchValue = null;
			let str = '';
			let date = new Date();
			let saveFlag = false;

			if (!e.validationGroup.validate().isValid) return;

			if (this.tab2.grid.columnList.length === 0) {
				this.$_Toast('조회 그룹 ' + this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
				return;
			} else if (this.tab3.grid.columnList.length === 0) {
				this.$_Toast('조회 항목 ' + this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
				return;
			}

			if (this.mode !== 'loadDashBoard') {
				this.getWidgetId();
				str = '생성';
			} else {
				str = '수정';
			}

			// 212:년별 213:시간별 211:월별 210:일별
			// 최근조회
			if (this.search.durationType === '1130') {
				searchValue = this.search.recentValue;

				let dayAgo = new Date(date);
				let year, month, day;
				if (this.search.dateGroupType === '210' || this.search.dateGroupType === '213') {
					dayAgo.setDate(date.getDate() - Number(searchValue));
				} 
				year = dayAgo.getFullYear().toString();
				month = (dayAgo.getMonth() + 1).toString().padStart(2, '0'); // 월은 0부터 시작하므로 +1을 해줌
				day = dayAgo.getDate().toString().padStart(2, '0');
				dayAgo = year + month + day;
				searchValue = dayAgo + '~' + this.$_commonlib.formatDate(date, 'YYYYMMDDHHmmss', 'YYYYMMDD');
			} 

			if (isNaN(searchValue)) {
				if (Number(searchValue.replaceAll('-', '').split('~')[0]) > Number(searchValue.replaceAll('-', '').split('~')[1])) {
					saveFlag = true;
				}
			} else {
				if (Number(searchValue.split('~')[0]) > Number(searchValue.split('~')[1])) {
					saveFlag = true;
				}
			}

			if (saveFlag) {
				await this.$_Toast('시작날짜가 종료날짜보다 더 큽니다. 다시 설정해 주세요.');
				return;
			}

			//this.resultColumn = [...this.tab2.grid.columnList, ...this.tab3.grid.columnList];

			if (!(await this.$_Confirm('선택한 데이터를 ' + str + ' 하시겠습니까?'))) return;

			let columnList = this.tab3.grid.columnList.map(e => e.dataField).join();
			let columnNameList = this.tab3.grid.columnList.map(e => e.description).join();
			let columnGroupList = this.tab2.grid.columnList.map(e => e.dataField).join();
			let columnGroupNameList = this.tab2.grid.columnList.map(e => e.description).join();

			let widgetParam = {
				id: this.widgetId,
				widgetShapeTypeFl: this.search.dashboardType,
				chartTypeCd: this.search.widgetType,
				title: this.actionData.title,
				width: this.search.dashboardType === '1229' ? 19 : 30,
				height: this.search.dashboardType === '1229' ? 9 : 20,
				viewCd: this.viewCd === true ? 1 : 2,
				resizeFl: this.resizeFl === 0 ? 0 : 1,
				targetQuery: this.tab1.page.targetQuery_item_value,
				dateGroupCode: this.search.dateGroupType,
				durationType: this.search.durationType,
				searchConditionValue: searchValue.toString().replaceAll('-', ''),
				recent: this.search.durationType === '1130' ? this.search.recentValue : '',
				seriesColors: '#004225,#F5F5DC,#FFB000,#4fe4e0,#009966',
				updateCycle: 60,
				positiony: 0,
				positionx: 2,
				regId: this.$store.getters.getLoginId,
				columnList: columnList,
				columnNameList: columnNameList,
				columnGroupList: columnGroupList,
				columnGroupNameList: columnGroupNameList,
				solution: 'ers',
				subPath: this.ctiType,
			};

			const res = await this.CALL_REPORT_API({
				actionName: 'DASHBOARD_LIST_UPDATE_XML',
				data: { data: [widgetParam] },
				loading: true,
			});
			if (isSuccess(res)) {
				await this.$_Toast('정상적으로 ' + str + '되었습니다.');
				await this.$router.push('/report/config/dash-board');
			}
		},
		async getWidgetId() {
			let paramsData = {};

			let payload = {
				actionName: 'WIDGETTP_ID_SELECT',
				data: { params: paramsData },
				loading: true,
			};

			let res = await this.CALL_REPORT_API(payload);

			if (isSuccess(res)) {
        this.widgetId = Number(res.data.data);
			}
		},
		/** @description : 라이프사이클 created시 호출되는 메서드 */
		async createdData() {
			this.settargetQuery_item();
		},
		/**@description : 취소 클릭 시 이전 페이지로 이동 메서드 */
		goBack() {
			this.$router.go(-1);
		},
		onReportType() {
			this.settargetQuery_item();
		},
		async getDashboardType() {
			this.page.dashboardCdDataList = DASHBOARD_SEARCH_BOX.getChartTypes();
			this.page.widgetCdDataList = DASHBOARD_SEARCH_BOX.getWidgetTypes();
		},
		async getDurationType() {
			this.page.durationDataList = DASHBOARD_SEARCH_BOX.getSearchTypes();
		},
		async onChangeSearchDashboardType(e) {
			const selectedValue = e.value;
			this.page.widgetCdDataList = DASHBOARD_SEARCH_BOX.getWidgetTypes().filter(widget => widget.parent === selectedValue);

			if (this.search.dashboardType === null || this.search.dashboardType === this.search.defaultValue) {
				this.$delete(this.search.paramsData, 'widgetShapeTypeFl');
			} else {
				this.search.paramsData = { ...this.search.paramsData, widgetShapeTypeFl: `${this.search.dashboardType}` };
			}

			this.createdData();
		},
		async getDateType() {
			this.page.dateCdDateList = DASHBOARD_SEARCH_BOX.getDateTypes();
		},
		/**
		 * onAddItem : 쿼리 리스트 -> 항목설정으로 드래그시 이벤트
		 */
		onAddItem(e) {
			//e.itemData.reportId = 'common';
			this.tab1.grid.columnList.push(e.itemData);
			this.tab1.grid.ReportList = this.tab1.grid.ReportList.filter(d => d.id != e.itemData.id);
		},
		onChangeDataSet(search) {
			this.dataDisplay = true;
		},
		/**
		 * onRemoveItemButton : 조회데이터 -> 조회 그룹
		 */
		onRemoveItemButton(data) {
			let moveData = data.data.data;

			if (moveData.axis === 'X') {
				//if(this.tab2.grid.columnList.length === 2) {
				//this.$_Toast('3개 이상의 조회그룹을 입력할 수 없습니다.');
				//return;
				//}
				this.tab2.grid.columnList.push(moveData);
				this.resultColumn.push(moveData);
			} else {
				if (this.tab3.grid.columnList.length === 5) {
					this.$_Toast('조회항목은 5개를 초과할 수 없습니다.');
					return;
				}
				this.tab3.grid.columnList.push(moveData);
				this.resultColumn.push(moveData);
			}

			this.tab1.grid.columnList = this.tab1.grid.columnList.filter(d => d.id != moveData.id);
		},
		onAddItemButton(data) {
			let moveData = data.data.data;
			//moveData.reportId = 'common';
			this.tab1.grid.columnList.push(moveData);

			if (moveData.axis === 'X') {
				this.tab2.grid.columnList = this.tab2.grid.columnList.filter(d => d.id != moveData.id);
				this.resultColumn = this.resultColumn.filter(d => d.id != moveData.id);
			} else {
				this.tab3.grid.columnList = this.tab3.grid.columnList.filter(d => d.id != moveData.id);
				this.resultColumn = this.resultColumn.filter(d => d.id != moveData.id);
			}
		},
		targetQueryDisplayExpr(item) {
			return item && item.description ? `${item.name} - ${item.description}` : item ? item.name : '';
		},
		getSystemName() {
			return this.$_getSystemData('cti_system_type')?.configValue;
		},
		async onChangeSearchTypeRefresh() {
			this.tab2.grid.columnList = [];
			this.tab3.grid.columnList = [];
			const isloadDashBoard = 'loadDashBoard' === this.mode;

			let params = null;
			let payload = { loading: false };

			if (isloadDashBoard) {
				params = {
					widgetId: this.reqParams.id,
					solution: 'ers',
					subPath: this.ctiType,
					targetQuery: this.tab1.page.targetQuery_item_value,
				};
			} else {
				params = { targetQuery: this.tab1.page.targetQuery_item_value, solution: 'ers', subPath: this.ctiType };
			}

			// Master XML과 비교
			payload.data = params;
			payload.actionName = 'DASHBOARD_COLUMN_MASTER_LIST_XML';
			const secondRes = await this.CALL_REPORT_API(payload); // 1. XML 내 컬럼 조회
			let xmlMasterColumns = secondRes.data.data; // XML에 정의된 컬럼 리스트

			const columnList = this.actionData.columnList?.split(',');
			const columnGroupList = this.actionData.columnGroupList?.split(',');

			//해당 위젯의 조회항목과 조회그룹
			const cols = columnList?.concat(columnGroupList);

			const xmlDefinedColumns = xmlMasterColumns.filter(xe => cols?.some(ce => ce === xe.dataField));

			this.tab1.grid.columnList = xmlMasterColumns.filter(xe => !cols?.includes(xe.dataField));

			if (isloadDashBoard) {
				xmlDefinedColumns.forEach(col => {
					const axisName = col.axisName;
					if (axisName === '조회 그룹') {
						this.tab2.grid.columnList.push(col);
					} else {
						this.tab3.grid.columnList.push(col);
					}
				});
			}

			this.getDateType();
			this.getDurationType();
		},
		/**
		 * settargetQuery_item 타겟쿼리 selectbox 데이터 세팅
		 */
		async settargetQuery_item() {
			let paramsData = {
				type: 'dashboard',
				subPath: this.ctiType,
			};

			let payload = {
				actionName: 'MASTER_QUERY_LIST',
				data: { 
					params: paramsData ,
					type: 'DS'
				},
				loading: true,
			};

			this.tab1.page.targetQuery_item = [];
			this.tab1.grid.columnList = [];
			let res = await this.CALL_REPORT_API(payload);

			if (isSuccess(res)) {
				this.tab1.page.targetQuery_item = res.data.data;
			}
		},
		noDataTextTarget(length, num) {
			let rtnString = '';
			if (length === 0) {
				if (this.isSearchClick) {
					rtnString = '검색 결과가 없습니다';
				} else {
					if (num === 1) {
						rtnString = '조회 데이터를 먼저 선택하세요.';
					} else if (num === 2) {
						rtnString = '데이터를 선택하세요.';
					} else if (num === 3) {
						rtnString = '조회그룹을 선택하세요.';
					}
				}
				return rtnString;
			}
		},
		setDateGubun() {
			const dateGroupType = this.search.dateGroupType;
			const duration = this.search.durationType;

			// 일 210
			if (dateGroupType === '210') {
				// 최근 조회
				if (duration === '1130') {
					this.periodStr = '일';
					this.isVisible = true;
				} 
			// 시간 213
			} else if (dateGroupType === '213') {
				if (duration === '1130') {
					this.periodStr = '일';
					this.isVisible = true;
				} 
			}
		},
		onChangeSearchDateType() {
			this.search.durationSelectBoxCheck = false;
			this.setDateGubun();
		},
		onChangeDuration() {
			this.setDateGubun();
		},
		setPriodMonthAndYear() {
			const months = new Array(12).fill().map((v, i) => {
				const month = i + 1;
				const value = month < 10 ? `0${month}` : month.toString();
				return { name: `${month}월`, value };
			});

			const years = [];
			const ersInitYear = this.$_getReportStartDt.substring(0, 4);
			let year = moment().format('YYYY');
			while (ersInitYear <= year) {
				years.push({ name: year + '년', value: year });
				year--;
			}

			this.search.bePeriodMonth = months;
			this.search.afPeriodMonth = months;
			this.search.bePeriodYear = years;
			this.search.afPeriodYear = years;
		},
		initMounted() {
			// this.setTimeList();
			this.setPriodMonthAndYear();
		},
		onChangePeriod(e, type) {
			const { periodType } = this.status;
			if (['DAY', 'RANGE'].includes(periodType)) {
				this.changeDay();
			} else if ('MONTH' === periodType && ['MONTH', 'YEAR'].includes(type)) {
				this.changeMonth();
			} else if ('YEAR' === periodType) {
				this.changeYear();
			}
		},
		changeMonth() {
			const periodYear = this.search.currentYear;
			const periodMonth = this.search.currentMonth;
			const YYYYMM = `${periodYear}-${periodMonth}`;
			const startDt = `${YYYYMM}-01`;
			const endDt = moment(startDt).endOf('month').format('YYYY-MM-DD');
			this.setSearchParam('dayStart', startDt);
			this.setSearchParam('dayEnd', endDt);
		},
		changeYear() {
			const bePeriodYear = this.search.currentYear;
			const afPeriodYear = this.search.currentYear;
			const startDt = `${bePeriodYear}-01-01`;
			const endDt = `${afPeriodYear}-12-31`;
			this.setSearchParam('dayStart', startDt);
			this.setSearchParam('dayEnd', endDt);
		},
		getCtiType() {
			this.ctiType = this.$_getSystemData('cti_system_type')?.configValue.toLowerCase();
		},
	},
	created() {
		this.createdData();
		this.getDashboardType();
		this.getCtiType();

		// Update
		if (this.$route?.query?.updateYn) {
			//메뉴 리스트에서 넘어온거라면
			this.updateYn = this.$route.query.updateYn;
			this.reqParams = this.$route.query.data;
			this.tab1.search.reportName = this.reqParams?.menuNm;
			this.tab1.search.subMenu_item_value = this.reqParams?.parentId;
			// Add
		}
	},
	mounted() {
		this.initMounted();
		if (this.updateYn) {
			this.loadDashBoard(this.reqParams);
		}
	},
};
</script>

<style scoped>
.ui-glid-box > div.fl {
	border-right: 0;
}
.top-box {
	height: auto;
}
.top-box > div {
	height: auto;
}

.table_form td > div {
	position: relative;
	vertical-align: middle;
}

.sub_title_txt {
  position: sticky;
  top: 0;
  background-color: white; /* 필요에 따라 배경색을 설정 */
  z-index: 1; /* 필요에 따라 z-index를 설정 */
}
</style>
