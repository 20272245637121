<!--
  PACKAGE_NAME : src/pages/cc/ivr/schedule/config/default-time.vue
  FILE_NAME : default-time
  AUTHOR : hmlee
  DATE : 2024-10-8
  DESCRIPTION : 운영스케줄관리 설정 - 기본운영시간 설정 컴포넌트
-->
<template>
  <div>
    <div class="radio-style01 pad_top15">
      <dx-radio-group
        class="check-type col"
        :items="config.basicConfigTypes"
        :value="getConfigType"
        layout="horizontal"
        @value-changed="handleChangedBasicConfigTypes"
      />
    </div>

    <!--공통/개별 설정 라디오 그룹-->
    <div class="cusmain-table-wrap">

      <table class="table_list">
        <caption>
          <strong>{{ $_lang('CC.WORD.BASIC_OP_TIME_SETTING', { defaultValue: '기본 운영시간 설정' }) }}</strong>
        </caption>
        <colgroup>
          <col/>
          <col/>
          <col/>
          <col/>
        </colgroup>
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col">{{ $_lang('CC.WORD.OP_TIME', { defaultValue: '운영시간' }) }}</th>
            <th scope="col">{{ $_lang('CC.WORD.BREAK_TIME_APPLY_TYPE', { defaultValue: '휴식시간 적용 여부' }) }}</th>
            <th scope="col">{{ $_lang('CC.WORD.BREAK_TIME', { defaultValue: '휴식시간' }) }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in basicTime.listData" :key="index">
            <template v-if="basicTime.configType === 0">
              <td>{{ $_lang('COMMON.WORD.COMMON', { defaultValue: '공통' }) }}</td>
            </template>
            <template v-else>
              <td>{{ getDayCdNm(item.dayCd) }}</td>
            </template>
            <td class="taC">
              <div class="ui-datepicker period">
                <div class="ui-datepicker-item">
                  <dx-date-box
                    :edit-enabled="config.timeDateBox.editEnabled"
                    :styling-mode="config.stylingMode"
                    :width="config.timeDateBox.width"
                    :type="config.timeDateBox.type"
                    :max="item.workEnd"
                    :picker-type="config.timeDateBox.pickerType"
                    :interval="config.timeDateBox.interval"
                    :display-format="config.timeDateBox.displayFormat"
                    :date-serialization-format="config.timeDateBox.dateSerializationFormat"
                    :disabled="item.checkedDays"
                    v-model="item.workStart"
                  >
                    <dx-validator>
                      <dx-required-rule :message="$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS', { value: '시작시간' })" />
                    </dx-validator>
                  </dx-date-box>
                </div>

                <span class="dash">~</span>
                <div class="ui-datepicker-item">
                  <dx-date-box
                    :edit-enabled="config.timeDateBox.editEnabled"
                    :styling-mode="config.stylingMode"
                    :width="config.timeDateBox.width"
                    :type="config.timeDateBox.type"
                    :min="item.workStart"
                    :picker-type="config.timeDateBox.pickerType"
                    :interval="config.timeDateBox.interval"
                    :display-format="config.timeDateBox.displayFormat"
                    :date-serialization-format="config.timeDateBox.dateSerializationFormat"
                    :disabled="item.checkedDays"
                    v-model="item.workEnd"
                  >
                    <dx-validator>
                      <dx-required-rule :message="$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS', { value: '종료시간' })" />
                    </dx-validator>
                  </dx-date-box>
                </div>
                <dx-check-box
                  class="check-type col mar_le15"
                  :text="$_lang('CC.MESSAGE.TWENTY_FOUR_HOUR', { defaultValue: '24시' })"
                  v-model="item.checkedDays"
                  @value-changed="handleChangedCheckedDays(item)"
                >
                </dx-check-box>
              </div>
            </td>
            <td class="locker_switch_box clearfix">
              <dx-switch
                class="locker_switch t_in_swutch"
                v-model="item.isBreakTime"
                @value-changed="handleChangeIsBreakTime(item)"
              />
            </td>
            <td class="taC">
              <div
                class="clearfix view-picker-box"
                v-for="(item2, index2) in item.breakTimeList"
                :key="index2"
              >
                <div class="ui-datepicker period fl">
                  <div class="ui-datepicker-item">
                    <dx-date-box
                      :edit-enabled="config.timeDateBox.editEnabled"
                      :styling-mode="config.stylingMode"
                      :width="config.timeDateBox.width"
                      :type="config.timeDateBox.type"
                      :max="item2.breakEnd"
                      :picker-type="config.timeDateBox.pickerType"
                      :interval="config.timeDateBox.interval"
                      :display-format="config.timeDateBox.displayFormat"
                      :date-serialization-format="config.timeDateBox.dateSerializationFormat"
                      :disabled="!item.isBreakTime"
                      v-model="item2.breakStart"
                    >
                      <dx-validator>
                        <dx-required-rule
                          :max="item2.breakEnd"
                          :message="$_lang('CC.MESSAGE.START_TIME_OUT_OF_RANGE', { defaultValue: '시작시간은 종료시간보다 작거나 같아야 합니다.' })"
                        />
                      </dx-validator>
                    </dx-date-box>
                  </div>
                  <span class="dash">~</span>
                  <div class="ui-datepicker-item">
                    <dx-date-box
                      :edit-enabled="config.timeDateBox.editEnabled"
                      :styling-mode="config.stylingMode"
                      :width="config.timeDateBox.width"
                      :type="config.timeDateBox.type"
                      :min="item2.breakStart"
                      :picker-type="config.timeDateBox.pickerType"
                      :interval="config.timeDateBox.interval"
                      :display-format="config.timeDateBox.displayFormat"
                      :date-serialization-format="config.timeDateBox.dateSerializationFormat"
                      :disabled="!item.isBreakTime"
                      v-model="item2.breakEnd"
                    >
                      <dx-validator>
                        <dx-required-rule
                          :min="item2.breakStart"
                          :message="$_lang('CC.MESSAGE.END_TIME_OUT_OF_RANGE', { defaultValue: '종료시간은 시작시간보다 크거나 같아야 합니다.' })"
                        />
                      </dx-validator>
                    </dx-date-box>
                  </div>
                </div>
                <template v-if="index2 === 0 && item.isBreakTime">
                  <i class="time_add adminicon_02" @click="handleAddBreakTime(item)"></i>
                </template>
                <template v-if="index2 !== 0 && item.isBreakTime">
                  <i
                    class="minus-ico adminicon_02"
                    @click="handleRemoveBreakTime('basicTime', index, index2)"
                  ></i>
                </template>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { DxRangeRule, DxRequiredRule, DxValidator } from 'devextreme-vue/validator';
import { DxCheckBox } from 'devextreme-vue/check-box';
import { DxRadioGroup } from 'devextreme-vue/radio-group';
import { DxSwitch } from 'devextreme-vue/switch';
import { DxDateBox } from 'devextreme-vue/date-box';
import { cloneObj } from "@/plugins/common-lib";

export default {
	components: {
		DxValidator,
		DxRequiredRule,
		DxRangeRule,
		DxCheckBox,
		DxRadioGroup,
		DxSwitch,
		DxDateBox,
	},
	props: {
    /** @description: 선택된 요일 */
    checkedDays: {
      type: Array,
    },
    /** @description: 기본설정/개별설정 */
    basicConfigType: {
      type: Number,
      default() {
        return 0;
      },
    },
    /** @description: 기본운영시간 설정 데이터 */
    basicTimeList: {
      type: Array,
    }
  },
	data() {
		return {
      config: {
        stylingMode: 'outlined', //[outlined, filled, underlined]
        basicConfigTypes: [
          { idx: 0, text: '공통설정' },
          { idx: 1, text: '개별설정' },
        ],
        timeDateBox: {
          editEnabled: false,
          width: 120,
          type: 'time',
          displayFormat: 'a HH:mm',
          dateSerializationFormat: 'HH:mm',
          pickerType: 'list',
          inputAttr: "{ 'aria-label': 'Time' }",
          interval: 10,
          startTimeOutOfRangeMessage: this.$_lang('CC.MESSAGE.START_TIME_OUT_OF_RANGE', { defaultValue: '시작시간은 종료시간보다 작거나 같아야 합니다.' }),
          endTimeOutOfRangeMessage: this.$_lang('CC.MESSAGE.END_TIME_OUT_OF_RANGE', { defaultValue: '종료시간은 시작시간보다 크거나 같아야 합니다.' }),
        },
        defaultTimeData: { // 기본 시간 기초 데이터
          workStart: '09:00',
          workEnd: '18:00',
          checkedDays: false, //24시
          isBreakTime: true,
          breakTimeList: [
            {
              breakStart: '12:00',
              breakEnd: '13:00',
            },
          ],
        },
      },
      formCheckedDays: [], // 체크된 요일
      basicTime: {
        configType: 0,
        listData: [],
      },
		};
	},
	computed: {
		/** @description: 공통설정/개별설정 Radio value */
		getConfigType() {
			return this.config.basicConfigTypes.find(d => d.idx === this.basicTime.configType);
		},
	},
  watch: {
    /** @description: 선택된 요일 */
    checkedDays: {
      handler(newValue) {
        this.formCheckedDays = [...newValue];
        this.setBasicConfigType();
      },
      immediate: true,
    },
    /** @description: 기본설정/개별설정 */
    basicTimeList: {
      handler(newValue) {
        this.basicTime.listData = JSON.parse(JSON.stringify(newValue));
      },
      immediate: true,
    },
    /** @description: 기본운영시간 설정 데이터 */
    basicConfigType: {
      handler(newValue) {
        this.basicTime.configType = newValue;
      },
      immediate: true,
    }
  },
	methods: {
		/** @description : 기본운영시간설정 -> 공통설정/개별설정 Radio Button 변경시 이벤트 */
    handleChangedBasicConfigTypes(event) {
      if (event) { //이벤트 변경시
        this.basicTime.configType = event.value.idx;
        this.setBasicConfigType();
      } else { //초기 설정
        this.basicTime.configType = this.getConfigType.idx;
        this.setBasicConfigType();
      }
		},
		/** @description : 기본운영시간설정 -> 공통설정/개별설정 Radio Button 설정 */
		setBasicConfigType() {
      if (this.basicTime.configType === 0) { // 공통설정 선택시
        this.setCommonConfig();
			} else if (this.basicTime.configType === 1) { //개별설정 선택시
        this.setEachConfig();
			}
		},
    /** @description : 운영요일 공통설정 */
    setCommonConfig() {
      if (this.basicTime.listData.length === 0) { //등록시 기본 데이터 설정
        const defaultTimeData = cloneObj(this.config.defaultTimeData);
        this.basicTime.listData = [defaultTimeData];
      } else { //수정시
        const basicTimeData = cloneObj(this.basicTime.listData[0]);
        this.basicTime.listData = [basicTimeData];
      }
    },
		/** @description : 운영요일 개별설정 */
    setEachConfig() {
      if (this.basicTime.listData.length === 0) { //등록시 기본 데이터 설정
        const defaultTimeData = cloneObj(this.config.defaultTimeData);
        this.basicTime.listData = cloneObj(this.formCheckedDays.map(day => {
          defaultTimeData.dayCd = day;
          return { ...defaultTimeData };
        }));
      } else { //수정시
        //listData에 체크 안된 요일 제거
        this.basicTime.listData = this.basicTime.listData.filter(basicTime => this.formCheckedDays.includes(basicTime.dayCd));

        //listData에 체크된 요일 값 추가
        this.formCheckedDays.forEach(day => {
          if (!this.basicTime.listData.some(basicTime => basicTime.dayCd === day)) {
            const defaultTimeData = cloneObj(this.config.defaultTimeData);
            defaultTimeData.dayCd = day;
            this.basicTime.listData.push(defaultTimeData);
          }
        });
      }
		},
		/** @description : 기본운영시간설정 운영시간 24시 체크에 따른 운영시간 설정 */
		handleChangedCheckedDays(data) {
			let workStart;
			let workEnd;
			if (data.checkedDays) {
				workStart = '00:00';
				workEnd = '00:00';
			} else {
				workStart = '09:00';
				workEnd = '18:00';
			}
			data.workStart = workStart;
			data.workEnd = workEnd;
		},
		/** @description : 휴식시간 여부 변경 이벤트 */
		handleChangeIsBreakTime(data) {
			data.breakTimeList = [];
			let breakTime = [];
			if (data.isBreakTime) {
				breakTime = [
					{
						breakStart: '12:00',
						breakEnd: '13:00',
					},
				];
			} else {
				breakTime = [
					{
						breakStart: null,
						breakEnd: null,
					},
				];
			}
			data.breakTimeList = breakTime;
		},
		/** @description : 기본운영시간 설정 휴식시간 추가 이벤트 */
		handleAddBreakTime(data) {
			if (data.breakTimeList.length > 2) {
				return false;
			}
			const defaultData = cloneObj(this.config.defaultTimeData.breakTimeList[0]);
			data.breakTimeList.push(defaultData);
		},
		/** @description : 기본운영시간 설정 휴식시간 삭제 이벤트  */
		handleRemoveBreakTime(key, index1, index2) {
			if (this[key].listData[index1].breakTimeList.length > 1) {
				this[key].listData[index1].breakTimeList.splice(index2, 1);
      }
		},
		/** @description: 요일 코드명 출력 */
		getDayCdNm(dayOfWeek) {
			return this.$_enums.common.integerDayOfWeek.values?.find(day => day.value === dayOfWeek)?.label;
		},
	},
	created() {
    this.handleChangedBasicConfigTypes(); //공통설정/개별설정 Radio Button 설정
	},
};
</script>
<style lang="scss" scoped>
.table_list {
  width: 100%;

  thead {

    tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }
  }

  tbody {
    width: 100%;
    height: calc(100vh - 610px);
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
    tr {
      display: table;
      width: 100%;
      table-layout: fixed;

      td {
        padding: 3px 0;
      }
    }
  }

  /* hover 요소가 잘리지 않도록 */
  tbody tr td > * {
    z-index: 2;
  }

  /* 요일 */
  th:first-child,
  td:first-child {
    width: 80px;
  }

  /* 휴식시간 적용여부 */
  th:nth-child(3),
  td:nth-child(3) {
    width: 150px;
  }
}
</style>
