<!--
  PACKAGE_NAME : src\pages\ewm\hr\agent\management\form.vue
  FILE_NAME : form
  AUTHOR : bykim
  DATE : 2023-11-28
  DESCRIPTION : 상담원관리 상담원등록 컴포넌트
-->
<template>
  <div>
    <div class="sub_title_txt pt-5 pb-3">
      <h2>상담원 정보</h2>
    </div>
    <DxForm
      :form-data="formData"
      :label-location="formLabelLocation"
      :label-mode="formLabelMode"
      :min-col-width="formMinColWidth"
      :disabled="formDisabled"
      :read-only="formReadOnly"
      @initialized="saveFormInstance"
    >
      <DxGroupItem :col-count="2">
        <DxSimpleItem :col-span="2" template="agentStateTemplate" />
        <DxSimpleItem :col-span="2" :editor-options="deptCdOptions" editor-type="dxSelectBox">
          <DxLabel text="소속" />
          <DxRequiredRule message="필수값 입니다." />
        </DxSimpleItem>
        <DxSimpleItem :col-span="1" data-field="agtid" :editor-options="agtidOptions">
          <DxLabel text="상담원ID" />
          <DxRequiredRule message="필수값 입니다." />
        </DxSimpleItem>
        <DxSimpleItem :col-span="1" data-field="agtNm" :editor-options="agtNmOptions">
          <DxLabel text="상담원명" />
          <DxRequiredRule message="필수값 입니다." />
        </DxSimpleItem>
        <DxSimpleItem :col-span="1" :editor-options="jikgupCdOptions" editor-type="dxSelectBox">
          <DxLabel text="직급" />
          <DxRequiredRule message="필수값 입니다." />
        </DxSimpleItem>
        <DxSimpleItem :col-span="1" :editor-options="jikweeCdOptions" editor-type="dxSelectBox">
          <DxLabel text="직위" />
          <DxRequiredRule message="필수값 입니다." />
        </DxSimpleItem>
        <DxSimpleItem :col-span="1" :editor-options="workgroupCdOptions" editor-type="dxSelectBox">
          <DxLabel text="업무그룹" />
          <DxRequiredRule message="필수값 입니다." />
        </DxSimpleItem>
        <DxSimpleItem :col-span="1" :editor-options="authIdOptions" editor-type="dxSelectBox">
          <DxLabel text="계정권한" />
          <DxRequiredRule message="필수값 입니다." />
        </DxSimpleItem>
        <DxSimpleItem :col-span="1" template="joinDtCalenderTemplate">
          <DxLabel text="입사일자" />
          <DxRequiredRule message="필수값 입니다." />
        </DxSimpleItem>
        <DxEmptyItem :col-span="1" />
        <DxSimpleItem :col-span="1" data-field="agtdn">
          <DxLabel text="내선번호" />
          <DxNumericRule message="숫자만 입력 가능합니다." />
        </DxSimpleItem>
        <DxEmptyItem :col-span="1" />
        <DxSimpleItem :col-span="1" data-field="ctiId" :editor-options="ctiIdOptions">
          <DxLabel text="CTI ID" />
          <DxNumericRule message="숫자만 입력 가능합니다." />
          <DxAsyncRule
            :reevaluate="false"
            :ignoreEmptyValue="true"
            :validation-callback="asyncCtiIdValidation"
            message="중복된 CTI ID 입니다."
          />
        </DxSimpleItem>
        <DxSimpleItem :col-span="1" :editor-options="ctiUseFlOptions" editor-type="dxCheckBox"/>
        <DxSimpleItem :col-span="2" :editor-options="viewFlOptions" editor-type="dxCheckBox">
          <DxLabel text="사용여부" />
        </DxSimpleItem>
        <DxSimpleItem :col-span="2" :editor-options="loginFlOptions" editor-type="dxCheckBox">
          <DxLabel text="로그인 연동" />
        </DxSimpleItem>
        <DxSimpleItem :col-span="2" template="buttonTemplate" />
      </DxGroupItem>
      <template #agentStateTemplate="{}">
        <div class="form-top-button-wrapper">
          <div :class="fromTopClassName">
            <DxButton :class="stateTabClassName" width="130" height="28" :text="userStateNm" hint="상태" :visible="updateMode" />
          </div>
        </div>
      </template>
      <template #buttonTemplate="{}">
        <div class="form-bottom-button-wrapper">
          <DxButton class="btn_XS default filled" text="저장" width="120" height="40" @click="onSave" :visible="!updateMode" />
          <DxButton class="btn_XS default filled" text="수정" width="120" height="40" @click="onUpdate" :visible="updateMode" />
          <DxButton class="btn_XS white light_filled" text="취소" width="120" height="40" @click="onCancel" />
        </div>
      </template>
      <template #joinDtCalenderTemplate="{}">
        <DxDateBox
          styling-mode="outlined"
          type="date"
          v-model="formData.joinDt"
          dateSerializationFormat="yyyy-MM-dd"
          display-format="yyyy.MM.dd"
          placeholder="yyyy.MM.dd"
          :disabled="updateMode"
          @value-changed="onChangeJoinDt"
          @Initialized="joinDtInitialized"
          hint="당일 또는 과거일자일 경우 즉시 등록 됩니다"
        />
      </template>
    </DxForm>
    <!--    <modal-agent-card ref="agentCard"/>-->
  </div>
</template>

<script>
  import { DxEmptyItem, DxForm, DxGroupItem, DxLabel, DxSimpleItem, DxRequiredRule, DxNumericRule, DxAsyncRule } from 'devextreme-vue/form';
  import { DxSwitch } from 'devextreme-vue/switch';
  import { isSuccess, isEmpty, formatDate } from '@/plugins/common-lib';
  import { DxDateBox } from 'devextreme-vue/date-box';
  import { DxButton } from 'devextreme-vue/button';

  export default {
    components: {
      DxForm,
      DxEmptyItem,
      DxSimpleItem,
      DxGroupItem,
      DxLabel,
      DxDateBox,
      DxButton,
      DxRequiredRule,
      DxNumericRule,
      DxAsyncRule,
      DxSwitch,
    },
    props: {
      authIdList: {
        type: Array,
        default: () => [],
      },
    },
    watch: {
      authIdList: {
        handler: function (newVal, oldVal) {
          this.option('authIdInstance', 'dataSource', newVal);
        },
        deep: true,
      },
    },
    data() {
      return {
        codeMap: {},
        formData: {
          deptCd: '',
          agtid: '',
          agtNm: '',
          jikgupCd: '',
          jikweeCd: '',
          workgroupCd: '',
          authId: '',
          joinDt: '',
          viewFl: true,
          ctiId: '',
          ctiUseFl: false,
          loginFl: true,
          agtdn: '',
        },
        agtidOptions: {
          onInitialized: e => {
            this.agtidInstance = e;
          },
        },
        agtNmOptions: {
          onInitialized: e => {
            this.agtNmInstance = e;
          },
        },
        deptCdOptions: {
          dataSource: [],
          valueExpr: 'codeId',
          displayExpr: 'pathNmFull',
          placeholder: '선택',
          showClearButton: true,
          stylingMode: 'outlined',
          width: '100%',
          value: '',
          onSelectionChanged: e => {
            if (!isEmpty(e.selectedItem)) {
              this.formData.deptCd = e.selectedItem.codeId;
              return;
            }
            this.formData.deptCd = '';
          },
          onInitialized: e => {
            this.deptCdInstance = e;
          },
        },
        jikgupCdOptions: {
          dataSource: [],
          valueExpr: 'codeId',
          displayExpr: 'codeNm',
          placeholder: '선택',
          showClearButton: true,
          stylingMode: 'outlined',
          value: '',
          // width: '100px',
          onSelectionChanged: e => {
            if (!isEmpty(e.selectedItem)) {
              this.formData.jikgupCd = e.selectedItem.codeId;
              return;
            }
            this.formData.jikgupCd = '';
          },
          onInitialized: e => {
            this.jikgupCdInstance = e;
          },
        },
        jikweeCdOptions: {
          dataSource: [],
          valueExpr: 'codeId',
          displayExpr: 'codeNm',
          placeholder: '선택',
          showClearButton: true,
          stylingMode: 'outlined',
          value: '',
          onSelectionChanged: e => {
            if (!isEmpty(e.selectedItem)) {
              this.formData.jikweeCd = e.selectedItem.codeId;
              return;
            }
            this.formData.jikweeCd = '';
          },
          onInitialized: e => {
            this.jikweeCdInstance = e;
          },
        },
        workgroupCdOptions: {
          dataSource: [],
          valueExpr: 'codeId',
          displayExpr: 'codeNm',
          placeholder: '선택',
          showClearButton: true,
          stylingMode: 'outlined',
          width: '100%',
          value: '',
          onSelectionChanged: e => {
            if (!isEmpty(e.selectedItem)) {
              this.formData.workgroupCd = e.selectedItem.codeId;
              return;
            }
            this.formData.workgroupCd = '';
          },
          onInitialized: e => {
            this.workgroupCdInstance = e;
          },
        },
        viewFlOptions: {
          hint: '상담사계정 사용여부를 설정합니다',
          value: true,
          onValueChanged: e => {
            this.formData.viewFl = e.value;
          },
          onInitialized: e => {
            this.viewFlInstance = e;
          },
        },
        ctiUseFlOptions: {
          hint: '상담사정보를 CTI에 연동합니다',
          value: false,
          onValueChanged: e => {
            // CTI ID Disabled
            const useFl = e.value;
            if (this.getValue('ctiIdInstance') === '' && useFl) {
              this.option('ctiIdInstance', 'readOnly', false);
            } else {
              this.option('ctiIdInstance', 'readOnly', true);
            }

            // CTI 연동 플래그 false 인경우 ctiId '' 처리
            if (useFl === false) {
              this.formData.ctiId = '';
            }
            this.formData.ctiUseFl = useFl;
          },
          onInitialized: e => {
            this.ctiUseFlInstance = e;
          },
        },
        loginFlOptions: {
          hint: '상담사 로그인 계정 생성',
          value: true,
          onValueChanged: e => {
            this.formData.loginFl = e.value;
          },
          onInitialized: e => {
            this.loginFlInstance = e;
          },
        },
        authIdOptions: {
          dataSource: [],
          valueExpr: 'id',
          displayExpr: 'authNm',
          placeholder: '선택',
          showClearButton: true,
          hint: '로그인 연동 후 필요한 사용자 권한 입니다',
          stylingMode: 'outlined',
          value: '',
          onSelectionChanged: e => {
            if (!isEmpty(e.selectedItem)) {
              this.formData.authId = e.selectedItem.id;
              return;
            }
            this.formData.authId = '';
          },
          onInitialized: e => {
            this.authIdInstance = e;
          },
        },
        ctiIdOptions: {
          readOnly: true,
          value: '',
          onValueChanged: e => {
            this.formData.ctiId = e.value;
          },
          onInitialized: e => {
            this.ctiIdInstance = e;
          },
        },
        formLabelMode: 'outside',
        formLabelLocation: 'left',
        formMinColWidth: 400,
        formDisabled: false, // 저장 할때 form 일시적으로 disabled 하는 값
        formReadOnly: false, // form Read
        updateMode: false, // 업데이트 모드 유무
        agtidInstance: null, // 상담사 아이디 인스턴스
        agtNmInstance: null, // 상담사명 인스턴스
        deptCdInstance: null, // 소속 셀렉트박스 인스턴스
        jikgupCdInstance: null, // 직급 셀렉트박스 인스턴스
        jikweeCdInstance: null, // 직위 셀렉트박스 인스턴스
        workgroupCdInstance: null, // 업무그룹 셀렉트박스 인스턴스
        authIdInstance: null, // 계정권한 인스턴스
        viewFlInstance: null, // 사용여부 인스턴스
        ctiUseFlInstance: null, // CTI 사용여부 인스턴스
        ctiIdInstance: null, // CTI ID 인스턴스
        loginFlInstance: null, // 로그인연동 인스턴스
        joinDtInstance: null, // 입사일자 캘린더 인스턴스
        formInstance: null, // Form 인스턴스
        userStateNm: '',
        authMapList: {}, // 계정권한 데이터
        stateTabClassName: 'tab-white', // 상태창 탭 클래스명
        fromTopClassName: 'form-top',
      };
    },
    computed: {},
    methods: {
      /** @description : 라이프사이클 created시 호출되는  */
      async createdData() {
        await this.initCodeMap().then(() => {
          this.option('deptCdInstance', 'dataSource', this.$_fetchCodesByMaxDepth(this.codeMap['root_ewm_hr_dept'])); // 부서
          this.option('jikgupCdInstance', 'dataSource', this.$_fetchCodesByMaxDepth(this.codeMap['root_ewm_hr_jikgup'])); // 직급
          this.option('jikweeCdInstance', 'dataSource', this.$_fetchCodesByMaxDepth(this.codeMap['root_ewm_hr_jikwee'])); // 직위
          this.option('workgroupCdInstance', 'dataSource', this.$_fetchCodesByMaxDepth(this.codeMap['root_ewm_hr_workgroup'])); // 업무그룹
        });
        this.option('authIdInstance', 'dataSource', this.authIdList); // 계정권한
      },
      /** @description : form 인스턴스 저장  */
      saveFormInstance(e) {
        this.formInstance = e.component;
      },
      /** @description : 셀렉트 박스 초기화 */
      async initCodeMap() {
        this.codeMap = await this.$_getCodeMapList('root_ewm_hr_dept,root_ewm_hr_jikgup,root_ewm_hr_jikwee,root_ewm_hr_workgroup');
      },
      /** @description : 입력폼에 데이터 전달  */
      loadFormData(param) {
        this.initForm();
        this.updateMode = true; // 입력폼 비활성화
        this.setValue('deptCdInstance', param.deptCd); // 소속정보 선택
        this.setValue('jikgupCdInstance', param.jikgupCd); // 직급정보 선택
        this.setValue('jikweeCdInstance', param.jikweeCd); // 직위정보 선택
        this.setValue('workgroupCdInstance', param.workgroupCd); // 업무그룹 선택
        this.setValue('authIdInstance', param.authId); // 계정권한 선택
        this.setValue('agtidInstance', param.agtid); // 상담원ID
        this.setValue('agtNmInstance', param.agtNm); // 상담원명
        this.setValue('joinDtInstance', param.joinDt); // 입사일자
        this.formInstance.updateData('agtdn', param.agtdn); // 내선번호
        const isConnectedCtiId = param.ctiUseFl === this.$_enums.common.stringUsedFlag.YES.value;
        this.setValue('ctiIdInstance', param.ctiid === null ? '' : param.ctiid); // CTI ID
        this.setValue('ctiUseFlInstance', isConnectedCtiId); // CTI 연동여부
        this.setValue('viewFlInstance', param.viewFl === this.$_enums.common.stringUsedFlag.YES.value); // 사용여부 체크

        // 상태창 업데이트
        if (param.userStateCd === this.$_enums.ewm.userState.RETIRED.value) {
          this.userStateNm = `${this.$_enums.ewm.userState.RETIRED.label}(${param.retireDt})`;
          this.stateTabClassName = 'tab-gray';
        } else if (param.userStateCd === this.$_enums.ewm.userState.EMPLOYED.value) {
          this.userStateNm = this.$_enums.ewm.userState.EMPLOYED.label;
          this.stateTabClassName = 'tab-green';
        } else if (param.userStateCd === this.$_enums.ewm.userState.ON_LEAVE.value) {
          this.userStateNm = this.$_enums.ewm.userState.ON_LEAVE.label;
          this.stateTabClassName = 'tab-white';
        } else {
          this.userStateNm = '';
          this.stateTabClassName = 'tab-white';
        }

        // 일부 아이템 비활성화
        this.setDisabledOptions(true);

        // CTI 연동 비활성화
        this.option('ctiUseFlInstance', 'disabled', isConnectedCtiId);

        // 로그인 연동 비활성화
        const isLoginUser = param.loginFl === 'Y';
        this.setValue('loginFlInstance', isLoginUser); // 로그인연동 체크
        this.option('loginFlInstance', 'disabled', isLoginUser);
      },
      /** @description : 상담사 정보 저장 */
      async onSave() {
        let vm = this;

        // Form 데이터 validate 체크
        if (!this.formInstance.validate()?.isValid) {
          this.$_Toast(this.$_lang('COMMON.MESSAGE.REQUIRED_VALUE_VALIDATION_ERROR', { defaultValue: '필수값을 입력해주세요.' }));
          return;
        }

        if (await this.$_Confirm(this.$_lang('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' }))) {
          this.formDisabled = true; // 데이터 저장 과정중 일시적으로 form disablea
          let data = this.formInstance.option('formData');

          if (!isEmpty(data['joinDt'])) {
            data['joinDt'] = formatDate(data['joinDt'], 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD');
          }
          data['viewFl'] = this.replaceBooleanToString(data['viewFl']);
          data['ctiUseFl'] = this.replaceBooleanToString(data['ctiUseFl']);
          data['loginFl'] = this.replaceBooleanToString(data['loginFl']);

          const payload = {
            actionName: 'EWM_AGENT_SAVE',
            data: data,
            loading: false,
            useErrorPopup: true,
          };
          const res = await vm.CALL_EWM_API(payload);
          if (isSuccess(res)) {
            this.$_Toast(this.$_lang('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
            this.initForm();
            this.isSubmitSuccess(); // 상담사정보 그리드 갱신
          }
          // 저장 후 form disable false
          this.formDisabled = false;
        }
      },
      /** @description : 상담사 정보 업데이트 */
      async onUpdate() {
        if (await this.$_Confirm(this.$_lang('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' }))) {
          this.formDisabled = true; // 데이터 저장 과정중 일시적으로 form disable
          const payload = {
            actionName: 'EWM_AGENT_UPDATE',
            data: {
              agtid: this.formData.agtid,
              agtNm: this.formData.agtNm,
              authId: this.formData.authId,
              agtdn: this.formData.agtdn,
              ctiid: this.formData.ctiId,
              ctiUseFl: this.replaceBooleanToString(this.formData.ctiUseFl),
              viewFl: this.replaceBooleanToString(this.formData.viewFl),
              loginFl: this.replaceBooleanToString(this.formData.loginFl),
            },
            loading: true,
            useErrorPopup: true,
          };

          const res = await this.CALL_EWM_API(payload);
          if (isSuccess(res)) {
            this.$_Toast(this.$_lang('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
            this.initForm();
            this.isSubmitSuccess(); // 상담사정보 그리드 갱신
          }
          // 저장 후 form disable false
          this.formDisabled = false;
        }
      },
      /** @description : Form 데이터 취소 */
      onCancel() {
        this.initForm();
        this.$emit('showFormDiv', false);
      },
      /** @description : Form 초기화 */
      initForm() {
        this.updateMode = false;
        this.formInstance.resetValues();
        this.userStateNm = '';
        this.setValue('joinDtInstance', '');
        // 일부 아이템 비활성화 해제
        this.setDisabledOptions(false);
        this.option('ctiUseFlInstance', 'disabled', false);
        this.option('loginFlInstance', 'disabled', false);
        this.setValue('viewFlInstance', true);
        this.setValue('loginFlInstance', true);
      },
      isSubmitSuccess() {
        this.$emit('isSubmitSuccess');
      },
      /** @description : 입사일자 이벤트 변경 */
      onChangeJoinDt(e) {
        this.setValue('joinDtInstance', e.value);
      },
      /**
       * @description : 입력폼 컴포넌트 disabled, readonly 처리
       * @param value boolean
       * */
      setDisabledOptions(value) {
        this.option('agtidInstance', 'disabled', value);
        this.option('deptCdInstance', 'disabled', value);
        this.option('jikgupCdInstance', 'disabled', value);
        this.option('jikweeCdInstance', 'disabled', value);
        this.option('workgroupCdInstance', 'disabled', value);
      },
      /**@description : Boolean을 String Flag 값으로 변환 **/
      replaceBooleanToString(value) {
        return value === true ? 'Y' : 'N';
      },
      /**@description : CTI ID 유효성 검사 **/
      async asyncCtiIdValidation(e) {
        // CTI ID 연동됨 && 상담사정보 업데이트 모드 == true
        if (this.getValue('ctiUseFlInstance') && this.updateMode) {
          return true;
        }

        const payload = {
          actionName: 'EWM_AM_CTI_VALIDATION_CHECK',
          path: '/' + e.value,
          loading: false,
        };

        const res = await this.CALL_EWM_API(payload);
        return isSuccess(res);
      },
      /**
       * @description : option api 호출 <br>
       * this.instance.component.option(key) or this.instance.component.option(key,value)
       * @param instance String
       * @param key String
       * @param value String
       * **/
      option(instance, key, value) {
        this.$data[instance]?.component.option(key, value);
      },
      /**
       * @description : option api - value 값 변경
       * @param instance String
       * @param value String
       * **/
      setValue(instance, value) {
        this.$data[instance]?.component.option('value', value);
      },
      /**
       * @description : option api - value 값 조회
       * @param instance String
       * **/
      getValue(instance) {
        return this.$data[instance]?.component.option('value');
      },
      getDataSource(instance) {
        return this.$data[instance]?.component.getDataSource();
      },
      /**@description : 입사날짜 캘린터 인스턴스 초기화 **/
      joinDtInitialized(e) {
        this.joinDtInstance = e;
      },
    },
    async mounted() {
      await this.createdData();
    },
  };
</script>
<style lang="scss" scoped>
  ::v-deep {
    .dx-field-item:not(.dx-field-item-has-group):not(.dx-field-item-has-tabs):not(.dx-first-row):not(.dx-label-v-align) {
      padding-top: 10px !important;
    }

    .dx-switch {
      margin-left: 10px;
    }

    .dx-button-has-icon.dx-button-has-text .dx-button-content {
      padding: 0;
    }
  }

  .form-bottom-button-wrapper {
    width: 100%;
    margin-top: 10px;
    text-align: center;
  }

  .form-top-button-wrapper {
    width: 100%;
    margin-top: 10px;
  }

  .form-top {
    width: 100%;
    float: right;
    border-bottom: 1px solid #dddddd;
  }

  .retire-state {
    color: #f7665f;
    font-weight: 600;
    cursor: pointer;
  }

  .normal-state {
    color: green;
    font-weight: 600;
    cursor: pointer;
  }

  .rest-state {
    color: #7aa4d6;
    font-weight: 600;
    cursor: pointer;
  }

  .tab-btn-white {
    border-top: 1px solid #dddddd;
    border-left: 1px solid #dddddd;
    border-right: 2px solid #dddddd;
    background-color: white;
    float: left;
  }

  .tab-white {
    border-top: 1px solid #dddddd;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    float: right;
    text-align: center;
    border-start-end-radius: 5px;
    border-start-start-radius: 5px;
    cursor: auto;
  }

  .tab-green {
    border-top: 1px solid #dddddd;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    float: right;
    text-align: center;
    border-start-end-radius: 5px;
    border-start-start-radius: 5px;
    cursor: auto;
  }

  .tab-gray {
    border-top: 1px solid #dddddd;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    float: right;
    text-align: center;
    border-start-end-radius: 5px;
    border-start-start-radius: 5px;
    cursor: auto;
  }
</style>
