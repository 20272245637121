<!-- bar/stacked bar/full stacked bar 차트의 회전형일 경우의 컴포넌트 입니다 -->
<template v-if="widget.rotated">
	<DxChart :ref="`chart${widget.id}`" :rotated="true" :id="`chart${widget.id}`" :data-source="widgetDataSource">
		<DxCommonSeriesSettings :type="widget.chartType" :argument-field="widget.columnGroupList.join()" />
		<DxCommonAxisSettings>
			<DxGrid :visible="true" />
		</DxCommonAxisSettings>
		<DxSeries
			v-for="chatDataSeries in chart.series"
			:key="chatDataSeries.value"
			:value-field="chatDataSeries.value"
			:name="chatDataSeries.name"
			:color="chatDataSeries.color"
		/>
		<DxMargin :bottom="0" />
		<DxArgumentAxis :allow-decimals="false" :axis-division-factor="60">
			<DxLabel>
				<DxFormat type="decimal" />
			</DxLabel>
		</DxArgumentAxis>
		<DxLegend vertical-alignment="bottom" horizontal-alignment="center" />
		<DxExport :enabled="false" />
		<DxLoadingIndicator :show="true" :enabled="true" background-color="none" text="로딩중...">
			<DxFont :size="18" :weight="1000" family="sans-serif" color="#939292" />
		</DxLoadingIndicator>
		<DxTooltip :enabled="true" content-template="defaultTooltip">
			<DxFormat type="fixedPoint" :precision="0" />
		</DxTooltip>
		<template #defaultTooltip="{ data }">
			<DefaultTooltip :point="data" />
		</template>
		<DxAdaptiveLayout :keep-labels="true" :width="100" />
	</DxChart>
</template>
<script>
import {
	DxAdaptiveLayout,
	DxArgumentAxis,
	DxChart,
	DxCommonAxisSettings,
	DxCommonSeriesSettings,
	DxExport,
	DxFont,
	DxFormat,
	DxGrid,
	DxLabel,
	DxLegend,
	DxLoadingIndicator,
	DxMargin,
	DxSeries,
	DxTooltip,
} from 'devextreme-vue/chart';
import moment from 'moment';
import DefaultTooltip from '@/components/report/dashboard/widget/tooltip/defaultTooltip.vue';
import { cloneObj } from "@/plugins/common-lib";
export default {
	components: {
		DefaultTooltip,
		DxLoadingIndicator,
		DxFont,
		DxGrid,
		DxExport,
		DxTooltip,
		DxChart,
		DxMargin,
		DxFormat,
		DxCommonAxisSettings,
		DxCommonSeriesSettings,
		DxLegend,
		DxLabel,
		DxAdaptiveLayout,
		DxSeries,
		DxArgumentAxis,
	},
	props: {
		widget: {
			type: Object,
		},
		data: {
			type: Array,
		},
	},
	data() {
		return {
			chart: {
				series: [],
				type: '',
			},
		};
	},
	computed: {
    widgetDataSource() {
      // 날짜 재정의 및 포맷팅
      let dayStart;
      let dayEnd;
      if (this.widget.durationType === this.duration.recent) {
        const modifiedDate = this.dayStartChangeWhenRecent();
        dayStart = new Date(this.dateObjectDayFormating(modifiedDate));
        dayEnd = new Date();
      }

      //TODO : 2개일 때에 대한 로직 분기 필요
      const columnGroupList = this.widget.columnGroupList.join();

      // 재정의한 날짜를 통해 필터링
      let filteredData;
      // 조회항목이 YMD가 아닐경우 기간별 필터링이 필요없으므로 분기 // TODO : 모든 위젯 타입 공통 적용
      if(columnGroupList === 'YMD'){
        filteredData = this.data.filter(item => {
          const itemDate = new Date(this.dateObjectDayFormating(item[columnGroupList]));
          return itemDate >= dayStart && itemDate <= dayEnd;
        });

        // sortType에 따라서 정렬
        if (this.widget.sortType === 1) {
          filteredData.sort((a, b) => new Date(this.dateObjectDayFormating(a.YMD)) - new Date(this.dateObjectDayFormating(b.YMD)));
        } else if (this.widget.sortType === 0) {
          filteredData.sort((a, b) => new Date(this.dateObjectDayFormating(b.YMD)) - new Date(this.dateObjectDayFormating(a.YMD)));
        }
      }if(columnGroupList === 'TIMESTAMP'){
        filteredData = this.data.filter(item => {
          const itemDate = new Date(this.dateObjectFormating(item[columnGroupList]));
          return itemDate >= dayStart && itemDate <= dayEnd;
        });

        // '20240720 00:00:00' 형태의 문자열을 Date 객체로 변환
        const parseDate = (dateString) => {
          const [datePart, timePart] = dateString.split(' ');
          const formattedDate = `${datePart.substring(0, 4)}-${datePart.substring(4, 6)}-${datePart.substring(6, 8)}T${timePart}`;
          return new Date(formattedDate);
        };
        if (this.widget.sortType === 1) {
          filteredData.sort((a, b) => parseDate(a.TIMESTAMP) - parseDate(b.TIMESTAMP));
        } else if (this.widget.sortType === 0) {
          filteredData.sort((a, b) => parseDate(b.TIMESTAMP) - parseDate(a.TIMESTAMP));
        }
      }if(columnGroupList === 'AGTID'){
        filteredData = cloneObj(this.data);
        // sortType에 따라서 정렬
        if (this.widget.sortType === 1) {
          filteredData.sort((a, b) => new Date(this.dateObjectDayFormating(a.AGTID)) - new Date(this.dateObjectDayFormating(b.AGTID)));
        } else if (this.widget.sortType === 0) {
          filteredData.sort((a, b) => new Date(this.dateObjectDayFormating(b.AGTID)) - new Date(this.dateObjectDayFormating(a.AGTID)));
        }
      }

      // sortType에 따라서


      return filteredData;
    },
	},
	methods: {
    //날짜 포맷 변경 메서드
    dateObjectDayFormating(dateString) {
      let formattedDate = dateString.substring(0, 4) + '-' + dateString.substring(4, 6) + '-' + dateString.substring(6, 8);
      return formattedDate;
    },
    //최근 ( )일 조회일 경우 날짜 재정의 메서드
		dayStartChangeWhenRecent() {
			//최근 ( )일
			const recent = this.widget.recent;
			let date = new Date();
			const dateGroupCode = this.widget.dateGroupCode;
			let dayStart = '';
			if (dateGroupCode === this.dateFormat.daily || dateGroupCode === this.dateFormat.hour) {
				const daysAgo = date.getDate() - (recent-1);
				//date = date.setDate(daysAgo); 이렇게 설정하는 순간 타임스탬프 형식으로 변경됨
				date.setDate(daysAgo);
				dayStart = moment(date).format('YYYYMMDD');
			}
			return dayStart;
		},
		dateObjectFormating(dateString) {
			let formattedDate = dateString.substring(0, 4) + '-' + dateString.substring(4, 6) + '-' + dateString.substring(6, 8);
			return formattedDate;
		},
		//차트 Y축 컬럼 및 속성값(컬러, 변수)셋팅 메서드
		async setChatDataSeries() {
			this.chart.series = [];
			//TODO : 조회항목이 없는 경우 setChatDataSeries 메서드 종료시켜야함, 로직 수정 필요
			if ((await this.widget.columnList) === null) {
				return;
			}
      //FIXME: props 로 넘어온 변수를 let, const로 쓸 경우 use strict 모드에서 읽기 속성값이라는 오류 뿜기 때문에 var로 설정했으나 use strict 제거 작업 필요
			('use strict');
			var ayIds = Object.freeze(this.widget.columnList);
			var ayNms = Object.freeze(this.widget.columnNameList);
			var arrayColumnIds = [];
			var arrayColumnNms = [];

			if (typeof ayIds === 'undefined' || typeof ayNms === 'undefined') {
				return;
			}

			//템플릿에 따른 재료
			arrayColumnIds = Array.isArray(ayIds) ? ayIds : ayIds?.split(',');
			arrayColumnNms = Array.isArray(ayNms) ? ayNms : ayNms?.split(',');

			let columnList;

			if (typeof this.widget.columnList === 'undefined') {
				return;
			}

			if (!Array.isArray(this.widget.columnList)) {
				columnList = this.widget.columnList?.split(',');
			} else {
				columnList = this.widget.columnList;
			}

			const arraySeriesColors = this.widget.widgetTp.seriesColors ? this.widget.widgetTp.seriesColors.split(',') : null;
			var series = this.chart.series;

			if (series.length === 0) {
				for (let i = 0; i < columnList.length; i++) {
					let data = {};

					let columnIdx = '';
					arrayColumnIds.find((e, idx) => {
						if (e === columnList[i]) {
							columnIdx = idx;
							data['value'] = columnList[i];
						}
					});

					data['name'] = arrayColumnNms[columnIdx];

					if (arraySeriesColors) {
						data['color'] = arraySeriesColors[columnIdx];
					}
					this.chart.series.push(data);
				}
			}
		},
		//차트 리스트 초기 세팅 메서드
		async selectChatDataList() {
			this.setChatDataSeries();
			this.chart.type = this.widget.chartType;
		},
	},
	mounted() {
		this.selectChatDataList();
	},
};
</script>