<template>
  <div class="container">
    <div class="page-sub-box ewm">
      <div class="locker_setting_list sub_new_style01 sub_ui_box1">
        <div class="page_search_box line_bottom_1px">
          <div class="flex space-x-2 inner">
            <div class="mt-2">기준년월</div>
            <DxDateBox
              styling-mode="outlined"
              width="100"
              v-model="searchType.customTypes.frScheYmd"
              type="date"
              dateSerializationFormat="yyyyMMdd"
              display-format="yyyy.MM"
              maxLength="7"
              date-out-of-range-message="시작일은 종료일보다 작거나 같아야 합니다."
              invalid-date-message="입력 데이터가 유효하지 않습니다."
              :max="searchType.customTypes.toScheYmd"
              :calendar-options="{
                zoomLevel: 'year',
                minZoomLevel: 'decade',
                maxZoomLevel: 'year',
              }"
              @value-changed="onDayStartChanged"
            >
              <DxValidator validation-group="validationSearch">
                <DxRequiredRule message="기준년월은 필수입니다." />
              </DxValidator>
            </DxDateBox>
            <div class="mt-1">~</div>
            <DxDateBox
              styling-mode="outlined"
              width="100"
              v-model="searchType.customTypes.toScheYmd"
              type="date"
              dateSerializationFormat="yyyyMMdd"
              display-format="yyyy.MM"
              maxLength="7"
              date-out-of-range-message="종료일은 시작일보다 크거나 같아야 합니다."
              invalid-date-message="입력 데이터가 유효하지 않습니다."
              :min="searchType.customTypes.frScheYmd"
              :calendar-options="{
                zoomLevel: 'year',
                minZoomLevel: 'decade',
                maxZoomLevel: 'year',
              }"
              @value-changed="onDayEndChanged"
            >
              <DxValidator validation-group="validationSearch">
                <DxRequiredRule message="기준년월은 필수입니다." />
              </DxValidator>
            </DxDateBox>

            <DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="selectScheduleDataList" />
          </div>
        </div>
      </div>

      <div class="flex gap-x-6 items-stretch">
        <div class="w-2/5">
          <div class="mt-4">
            <DxDataGrid
              key-expr="id"
              class="grid-box eval-grid"
              ref="evalScheduleGrid"
              :data-source="scheduleGridData.dataSource"
              :allow-column-resizing="true"
              :column-resizing-mode="'nextColumn'"
              :show-borders="false"
              :show-column-headers="true"
              :show-column-lines="true"
              :show-row-lines="true"
              :row-alternation-enabled="false"
              :hover-state-enabled="true"
              :word-wrap-enabled="true"
              :no-data-text="this.$_lang('CMN_NO_DATA')"
              :selected-row-keys="scheduleGridData.selectedRowKeys"
              @selection-changed="onScheduleGridSelectionChanged"
              width="100%"
              height="calc(100vh - 370px)"
            >
              <DxFilterRow :visible="true">
                <DxOperationDescriptions contains="포함" />
              </DxFilterRow>
              <DxHeaderFilter :visible="true" />
              <DxLoadPanel :enabled="true" />
              <DxPaging :enabled="false" />
              <DxScrolling mode="standard" />
              <DxSelection mode="single" :allow-select-all="false" show-check-boxes-mode="always" select-all-mode="allPages" />
              <DxColumn caption="평가구분" data-field="evalDivisionCd" alignment="center" :allow-sorting="true">
                <DxLookup :data-source="dataGridLoopup.evalDivisionCd.dataSource" value-expr="codeId" display-expr="codeNm" />
              </DxColumn>
              <DxColumn caption="평가계획명" data-field="scheNm" :allow-header-filtering="false" :allow-sorting="true" />
              <DxColumn caption="평가상태" data-field="evalProcessCd" alignment="center" :allow-sorting="true">
                <DxLookup :data-source="dataGridLoopup.evalProcessCd.dataSource" value-expr="value" display-expr="label" />
              </DxColumn>
              <DxColumn
                caption="기준년월"
                data-field="scheYmd"
                alignment="center"
                :allow-header-filtering="false"
                :allow-sorting="true"
                :calculate-display-value="rowData => formatDate(rowData.scheYmd, 'YYYY-MM-DD', 'YYYY.MM')"
              />
            </DxDataGrid>
          </div>
        </div>

        <div class="w-2/3 flex flex-col gap-y-6 divide-y">
          <div>
            <h3 class="text-xl font-medium my-4">평가 정보</h3>
            <table class="table_form line-bin min-w-max max-w-screen-xl">
              <colgroup>
                <col style="width: 120px" />
                <col style="width: auto" />
                <col style="width: 120px" />
                <col style="width: auto" />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">
                    <label for="label01">평가계획:</label>
                  </th>
                  <td>
                    {{ evalScheduleInfo.scheNm }}
                  </td>
                  <th scope="row">
                    <label for="label01">평가표:</label>
                  </th>
                  <td>
                    {{ evalScheduleInfo.sheetNm }}
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="label01">평가기간:</label>
                  </th>
                  <td>{{ evalScheduleInfo.evalStartDt }} ~ {{ evalScheduleInfo.evalEndDt }}</td>
                  <th scope="row">
                    <label for="label01">이의제기 기간:</label>
                  </th>
                  <td>{{ evalScheduleInfo.evalFeedbackStartDt }} ~ {{ evalScheduleInfo.evalFeedbackEndDt }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div>
            <h3 class="text-xl font-medium my-3">대상자 정보</h3>
            <div>
              <div class="flex justify-between">
                <div>
                  <DxButton
                    template="<span class='mdi mdi-folder-open'></span>"
                    type="button"
                    hint="목록 펼치기"
                    class="btn_XS white outlined"
                    :height="30"
                    @click="onOpenTree"
                  />
                  <DxButton
                    template="<span class='mdi mdi-folder'></span>"
                    type="button"
                    hint="목록 접기"
                    class="btn_XS white outlined"
                    :height="30"
                    @click="onFoldTree"
                  />
                </div>
                <div>
                  <DxCheckBox
                    text="피드백만 보기"
                    v-model="searchType.customTypes.isFeedback"
                    :value="searchType.customTypes.isFeedback"
                    @value-changed="onIsFeedbackValueChanged"
                  />
                </div>
              </div>
              <div class="mt-4 tree-grid">
                <DxTreeList
                  key-expr="key"
                  parent-id-expr="parentKey"
                  class="grid-box eval-grid"
                  ref="evalTargetGrid"
                  :data-source="targetGridData.dataSource"
                  :allow-column-resizing="true"
                  :column-resizing-mode="'nextColumn'"
                  :show-borders="true"
                  :show-column-headers="true"
                  :show-column-lines="true"
                  :show-row-lines="true"
                  :row-alternation-enabled="false"
                  :hover-state-enabled="true"
                  :word-wrap-enabled="true"
                  :no-data-text="this.$_lang('CMN_NO_DATA')"
                  :expanded-row-keys="targetGridData.expandedRowKeys"
                  :auto-expand-all="true"
                  width="100%"
                  height="calc(100vh - 600px)"
                >
                  <DxFilterRow :visible="true">
                    <DxOperationDescriptions contains="포함" />
                  </DxFilterRow>
                  <DxHeaderFilter :visible="true" />
                  <DxLoadPanel :enabled="true" />
                  <DxPaging :enabled="false" />
                  <DxColumn caption="부서정보" data-field="targetDeptNmPath" :allow-sorting="true" />
                  <DxColumn
                    caption="평가대상자"
                    data-field="targetNm"
                    alignment="center"
                    :allow-header-filtering="false"
                    :allow-sorting="true"
                    :calculate-cell-value="
                      rowData => (rowData.evalRound === 0 ? `${rowData.targetNm}[${rowData.targetId}]` : `${rowData.evalRound}회차`)
                    "
                  />
                  <DxColumn
                    caption="평가자"
                    data-field="managerNm"
                    alignment="center"
                    :allow-header-filtering="false"
                    :allow-sorting="true"
                    :calculate-cell-value="rowData => (rowData.managerId ? `${rowData.managerNm}[${rowData.managerId}]` : null)"
                  />
                  <DxColumn
                    caption="평가점수"
                    data-field="totalScore"
                    alignment="center"
                    width="90"
                    :allow-header-filtering="false"
                    :allow-sorting="true"
                  />
                  <DxColumn
                    caption="진행률(%)"
                    data-field="progressRate"
                    alignment="center"
                    width="100"
                    :allow-header-filtering="false"
                    :allow-sorting="true"
                  />
                  <DxColumn
                    caption="추출방식"
                    data-field="recDrawTypeCd"
                    alignment="center"
                    width="95"
                    :allow-header-filtering="false"
                    :allow-sorting="true"
                  >
                    <DxLookup :data-source="dataGridLoopup.recDrawTypeCd" value-expr="codeId" display-expr="codeNm" />
                  </DxColumn>
                  <DxColumn caption="진행상태" data-field="targetProcessCd" alignment="center" :allow-sorting="true">
                    <DxLookup :data-source="dataGridLoopup.evalProcessCd.dataSource" value-expr="value" display-expr="label" />
                  </DxColumn>
                  <DxColumn
                    caption="평가진행"
                    data-field="id"
                    alignment="center"
                    cell-template="evalStartTemplate"
                    width="90"
                    :allow-filtering="false"
                    :allow-header-filtering="false"
                    :allow-sorting="true"
                  />
                  <template #evalStartTemplate="{ data }">
                    <DxButton
                      text=""
                      :element-attr="{ class: 'btn_XS white outlined mr-0' }"
                      height="30"
                      hint="평가진행"
                      template="<span class='mdi mdi-file-document-edit-outline'></span>"
                      :visible="data.data.managerId ? true : false"
                      @click="onEval($event, data.data)"
                    />
                  </template>
                </DxTreeList>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { DxButton } from 'devextreme-vue/button';
  import { DxCheckBox } from 'devextreme-vue/check-box';
  import { DxDateBox } from 'devextreme-vue/date-box';
  import {
    DxColumn,
    DxDataGrid,
    DxFilterRow,
    DxHeaderFilter,
    DxLoadPanel,
    DxLookup,
    DxOperationDescriptions,
    DxPaging,
    DxScrolling,
    DxSelection,
  } from 'devextreme-vue/data-grid';
  import { DxTreeList } from 'devextreme-vue/tree-list';
  import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
  import validationEngine from 'devextreme/ui/validation_engine';
  import { cloneObj, formatDate, getPastFromToday, isSuccess } from '@/plugins/common-lib';

  let vm = null;

  export default {
    components: {
      DxButton,
      DxCheckBox,
      DxDateBox,

      DxColumn,
      DxDataGrid,
      DxFilterRow,
      DxHeaderFilter,
      DxLoadPanel,
      DxLookup,
      DxOperationDescriptions,
      DxPaging,
      DxScrolling,
      DxSelection,

      DxTreeList,

      DxValidator,
      DxRequiredRule,
    },
    props: {},
    watch: {},
    data() {
      return {
        codeMap: {},
        dataGridLoopup: {
          evalDivisionCd: {
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
          evalProcessCd: {
            dataSource: this.$_enums.ewm.evalProcess.values,
            displayExpr: 'label',
            valueExpr: 'value',
          },
          recDrawTypeCd: [],
        },
        config: {
          stylingMode: 'outlined', //[outlined, filled, underlined]
        },
        searchType: {
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            frScheYmd: getPastFromToday(11, 'months'),
            toScheYmd: getPastFromToday(0, 'days'),
            isFeedback: false,
          },
          paramsData: {},
        },
        scheduleGridData: {
          dataSource: null,
          selectedRowsData: [],
          selectedRowKeys: [],
        },
        targetGridData: {
          dataSource: null,
          selectedRowsData: [],
          selectedRowKeys: [],
          expandedRowKeys: [],
          isLoaded: true,
        },
        evalScheduleInfo: {
          id: null,
          evalDivisionCd: null,
          evalDivisionNm: null,
          scheNm: null,
          evalSheetId: null,
          sheetNm: null,
          scheYmd: null,
          evalStartDt: null,
          evalEndDt: null,
          evalFeedbackStartDt: null,
          evalFeedbackEndDt: null,
          evalProcessCd: null,
          evalProcessNm: null,
          viewFl: this.$_enums.common.stringUsedFlag.YES.value,
          delFl: this.$_enums.common.stringUsedFlag.NO.value,
        },
        evalScheduleInfoDefaultData: {
          id: null,
          evalDivisionCd: null,
          evalDivisionNm: null,
          scheNm: null,
          evalSheetId: null,
          sheetNm: null,
          scheYmd: null,
          evalStartDt: null,
          evalEndDt: null,
          evalFeedbackStartDt: null,
          evalFeedbackEndDt: null,
          evalProcessCd: null,
          evalProcessNm: null,
          viewFl: this.$_enums.common.stringUsedFlag.YES.value,
          delFl: this.$_enums.common.stringUsedFlag.NO.value,
        },
      };
    },
    computed: {},
    methods: {
      formatDate,
      /** @description : 평가계획 조회 */
      async selectScheduleDataList() {
        if (!validationEngine.validateGroup('validationSearch').isValid) {
          return;
        }
        this.scheduleGridData.selectedRowKeys = [];

        vm.searchType.paramsData['frScheYmd'] = vm.searchType.customTypes.frScheYmd;
        vm.searchType.paramsData['toScheYmd'] = vm.searchType.customTypes.toScheYmd;

        const payload = {
          actionName: 'EWM_EVALUATION_STATUS_SCHE_LIST',
          data: vm.searchType.paramsData,
          loading: false,
        };

        const res = await vm.CALL_EWM_API(payload);
        if (isSuccess(res)) {
          this.evalScheduleInfo = cloneObj(this.evalScheduleInfoDefaultData);
          this.scheduleGridData.dataSource = res.data.data;

          this.targetGridData.dataSource = [];
        }
      },
      /** @description : 평가계획 선택 변경 시 */
      onScheduleGridSelectionChanged(e) {
        this.evalScheduleInfo = cloneObj(this.evalScheduleInfoDefaultData);
        this.targetGridData.dataSource = [];
        this.searchType.customTypes.isFeedback = false;

        if (e.selectedRowsData.length) {
          this.evalScheduleInfo = e.selectedRowsData[0];

          this.evalScheduleInfo.evalProcessNm = this.$_enums.ewm.evalProcess.values.find(
            c => c.value === this.evalScheduleInfo.evalProcessCd,
          ).label;

          this.evalScheduleInfo.evalDivisionNm = this.$_fetchCodesByDepth(this.codeMap['root_ewm_eval_division'], 2).find(
            c => c.codeId === this.evalScheduleInfo.evalDivisionCd,
          ).codeNm;

          this.selectTargetDataList();
        }
      },
      /** @description : 평가대상자 조회 */
      async selectTargetDataList() {
        if (!vm.evalScheduleInfo.id || !this.targetGridData.isLoaded) {
          return;
        }

        this.targetGridData.isLoaded = false;
        const payload = {
          actionName: 'EWM_EVALUATION_STATUS_TARGET_LIST',
          data: {
            scheId: vm.evalScheduleInfo.id,
            sheetId: vm.evalScheduleInfo.evalSheetId,
            isFeedback: vm.searchType.customTypes.isFeedback
              ? this.$_enums.common.stringUsedFlag.YES.value
              : this.$_enums.common.stringUsedFlag.NO.value,
          },
          loading: false,
        };

        const res = await vm.CALL_EWM_API(payload);
        if (isSuccess(res)) {
          this.targetGridData.dataSource = res.data.data;
          this.targetGridData.isLoaded = true;
          this.onOpenTree();
        }
      },
      /** @description: 트리 노드 펼치기 */
      onOpenTree() {
        const rootIds = this.targetGridData.dataSource.map(d => d.key);
        this.targetGridData.expandedRowKeys = rootIds;
      },
      /** @description: 트기 노드 닫기 */
      onFoldTree() {
        this.targetGridData.expandedRowKeys = [];
      },
      /** @description: 피드백만 보기 */
      onIsFeedbackValueChanged(e) {
        if (!vm.evalScheduleInfo.id) {
          return;
        }

        if (!e.value || this.targetGridData.dataSource?.length > 0) {
          this.selectTargetDataList();
        }
      },
      /** @description : 닫기 버튼 클릭 메서드 */
      onCancelFormData() {
        this.$router.push('/ewm/evaluation/implementation/status');
      },
      onEval(e, data) {
        const params = {
          ...this.evalScheduleInfo,
          ...{
            roundId: data.roundId,
            targetAgtId: data.targetId,
            targetId: data.id,
          },
        };
        this.$store.commit('setDetailParams', params);
        this.$router.push({ path: '/ewm/evaluation/implementation/status/evaluation' });
      },
      onDayStartChanged(e) {
        const changed = e.value.slice(0, 6) + '01';
        this.searchType.customTypes.frScheYmd = changed;
      },
      onDayEndChanged(e) {
        const changed = e.value.slice(0, 6) + '29';
        this.searchType.customTypes.toScheYmd = changed;
      },
      /** @description : 코드 로드 */
      async initCodeMap() {
        this.codeMap = await this.$_getCodeMapList('root_ewm_eval_division,root_ewm_eval_process,root_ewm_eval_rec_draw_type');
      },
      async createdData() {
        vm = this;

        await this.initCodeMap().then(() => {
          this.dataGridLoopup.evalDivisionCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_eval_division'], 2);
          this.dataGridLoopup.recDrawTypeCd = this.$_fetchCodesByMaxDepth(this.codeMap['root_ewm_eval_rec_draw_type']);
        });

        await this.selectScheduleDataList();
        this.reqParams = this.$store.getters.getDetailParams;
        if (this.reqParams) {
          vm.scheduleGridData.selectedRowKeys = [this.reqParams.id];
        }
      },
    },
    created() {
      this.createdData();
    },
    mounted() {},
  };
</script>
<style lang="scss" scoped>
  .page_search_box .inner div {
    display: inline-block;
  }

  .page_search_box .inner > div:not(.box-btn-search) {
    vertical-align: middle;
    margin-right: 10px;
  }

  .page_search_box .spec-type-box {
    display: inline-block;
  }

  .page_search_box .inner .box-btn-search {
    margin-right: 15px;
  }

  .page_search_box #searchTooltip {
    margin-right: 5px;
    font-size: 22px;
    color: #808080;
  }
</style>
