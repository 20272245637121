import {resetStateKeys} from './state.js';

export const mutations = {
  setAccessToken(state, payload) {
    state.accessToken = payload;
  },
  setRoutingInfo(state, payload) {
    state.routingInfos = payload?.length ? payload : [];
  },
  setMenuList(state, payload) {
    state.menuList = payload?.length ? payload : [];
  },
  setCodeList(state, payload) {
    state.codeList = payload ? payload : {};
  },
  setLoginId(state, payload) {
    state.loginId = payload;
  },
  setLoginNm(state, payload) {
    state.loginNm = payload;
  },
  setSiteList(state, payload) {
    state.siteList = payload?.length ? payload : [];
  },
  setTenantList(state, payload) {
    state.tenantList = payload?.length ? payload : [];
  },
  setIsKeepLogin(state, payload) {
    state.isKeepLogin = payload ? payload : 'N';
  },
  setHolidayList(state, payload) {
    state.holidayList = payload?.length ? payload : [];
  },
  setThemeSetting(state, payload) {
    state.themeSetting = payload;
  },
  setIsShowMyLayerLayout(state, payload) {
    state.isShowMyLayerLayout = payload ? payload : false;
  },
  setSystemList(state, payload) {
    state.systemList = payload?.length ? payload : [];
  },
  setEncryptionType(state, payload) {
    state.encryptionType = payload;
  },
  setAuthId(state, payload) {
    state.authId = payload;
  },
  setUserInfo(state, payload) {
    state.userInfo = payload;
  },
  setFavoriteMenu(state, payload) {
    state.favoriteMenu = payload?.length ? payload : [];
  },
  setMemberState(state, payload) {
    state.memberState = payload;
  },
  setIsAdminUser(state, payload) {
    state.isAdminUser = payload;
  },
  setUserMainPage(state, payload) {
    state.userMainPage = payload;
  },
  resetState(state) {
    resetStateKeys.forEach(key => {
      state[key] = null;
    })
  },
  resetStateByKey(state, key) {
    state[key] = null;
  },
  setStompClient(state, client) {
    state.stompClient = client;
  },
  setSessionId(state, sessionId) {
    state.sessionId = sessionId;
  },
};