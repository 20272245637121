<template>
  <div class="space-y-4 md:space-y-2 lg:space-y-0 lg:flex lg:items-center lg:space-x-4 mb-4">
    <div class="flex space-x-1">
      <button
        v-for="({ name, type }, index) in visiblePeriodButtons"
        ref="periodButton"
        class="btn_XXS blue2"
        :key="index"
        :data-type="type"
        @click="handleClickPeriodButton(type)"
      >
        {{ name }}
      </button>
    </div>
    <div class="space-y-2 lg:space-y-0 lg:flex lg:items-center lg:space-x-2 w-full lg:w-auto">
      <!-- 년, 월 -->
      <div v-show="['MONTH', 'YEAR'].includes(selected.periodType)" class="flex flex-wrap space-x-1.5">
        <DxSelectBox
          v-model="selected.year"
          :data-source="dataSource.years"
          display-expr="name"
          value-expr="value"
          :styling-mode="dxStyled"
          @value-changed="handleChangePeriod"
          width="123px"
          :height="30"
        />
        <DxSelectBox
          v-show="['MONTH'].includes(selected.periodType)"
          v-model="selected.month"
          :data-source="dataSource.months"
          display-expr="name"
          value-expr="value"
          :styling-mode="dxStyled"
          @value-changed="handleChangePeriod"
          width="100px"
          :height="30"
        />
      </div>
      <!-- 일, 기간선택 -->
      <div v-show="['DAY', 'RANGE'].includes(selected.periodType)" class="flex flex-wrap items-center space-x-2">
        <!-- 일 -->
        <div class="ui-datepicker period flex items-center space-x-1.5">
          <DxDateBox
            ref="startDt"
            v-model="form.startDt"
            :styling-mode="dxStyled"
            :openOnFieldClick="true"
            width="123px"
            @value-changed="handleChangePeriod(false)"
            type="date"
            display-format="yyyy-MM-dd"
            dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
          />
          <!-- 기간선택 -->
          <div v-show="['RANGE'].includes(selected.periodType)" class="flex items-center space-x-1.5">
            <span>~</span>
            <DxDateBox
              ref="endDt"
              v-model="form.endDt"
              :styling-mode="dxStyled"
              width="123px"
              :openOnFieldClick="true"
              @value-changed="handleChangePeriod(false)"
              type="date"
              display-format="yyyy-MM-dd"
              dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { DxDateBox } from 'devextreme-vue/date-box';
  import moment from 'moment/moment';
  import { getPastFromToday } from '@/plugins/common-lib';

  export default {
    name: 'date-box.vue',
    components: { DxSelectBox, DxDateBox },
    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
      changedValue: {
        type: Function,
        required: false,
        default: () => {},
      },
    },
    data() {
      return {
        dxStyled: 'outlined',
        form: {
          startDt: this.getToday(),
          endDt: this.getToday(),
        },
        selected: {
          year: getPastFromToday(0, 'days', 'YYYY'),
          month: getPastFromToday(0, 'days', 'MM'),
          periodType: 'DAY', // 버튼 없을 시 다른 값으로 대체 해야 함
        },
        dataSource: {
          years: [],
          months: [],
        },
        periodButtons: [
          { name: '일', type: 'DAY', visible: true },
          { name: '월', type: 'MONTH', visible: true },
          { name: '년', type: 'YEAR', visible: true },
          { name: '기간선택', type: 'RANGE', visible: true },
        ],
      };
    },
    computed: {
      visiblePeriodButtons() {
        return this.periodButtons.filter(button => button.visible);
      },
    },
    methods: {
      /** 상위 컴포넌트에서 실행할 함수 */
      handleChangedValue() {
        this.changedValue();
      },
      /** 시작 및 종료 시간, 분 (startH, startM, endH, endM) 조회 */
      getFormData() {
        return this.form;
      },
      /** 버튼 컬러 변경 */
      changeButtonColor(type) {
        const buttons = this.$refs.periodButton;
        buttons.forEach(button => {
          const isOnButton = button.dataset.type === type;
          button.classList.remove('on');
          if (isOnButton) {
            button.classList.add('on');
          }
        });
      },
      /** 외부에 의해 날짜 변경 */
      updateFormData(startDt, endDt) {
        const periodButtonType = startDt === endDt ? 'DAY' : 'RANGE';
        this.handleClickPeriodButton(periodButtonType);

        this.form.startDt = startDt;
        this.form.endDt = endDt;
      },
      /** 버튼 클릭 시 동작하는 함수  */
      handleClickPeriodButton(type) {
        this.selected.periodType = type;
        this.changeButtonColor(type);
        this.handleChangePeriod(true);
      },
      /** 날짜 변경 시 동작하는 함수 */
      handleChangePeriod(isClick = false) {
        const type = this.selected.periodType;
        if (['DAY', 'RANGE'].includes(type)) {
          this.updateFormDataByDayOrRange(type, isClick);
        } else if ('MONTH' === type) {
          this.updateFormDataByMonth();
        } else if ('YEAR' === type) {
          this.updateFormDataByYear();
        }
      },
      /** 일 또는 기간선택 버튼으로 변경 시 */
      updateFormDataByDayOrRange(type, isClick = false) {
        const selectedDay = type === 'DAY';
        if (selectedDay && isClick) {
          // 일 버튼 클릭 시 오늘 날짜로 변경
          this.form.endDt = this.getToday();
          this.form.startDt = this.getToday();
        } else if (selectedDay) {
          // 일 버튼인 경우 시작일과 종료일이 같아야 함
          this.form.endDt = this.form.startDt;
        }

        this.handleChangedValue();
      },
      /** 월 변경 시 */
      updateFormDataByMonth() {
        const { year, month } = this.selected;
        const startDt = `${year}-${month}-01`;
        this.form.startDt = startDt;
        this.form.endDt = moment(startDt).endOf('month').format('YYYY-MM-DD');
        this.handleChangedValue();
      },
      /** 년도 변경 시 */
      updateFormDataByYear() {
        const { year } = this.selected;
        this.form.startDt = `${year}-01-01`;
        this.form.endDt = `${year}-12-31`;
        this.handleChangedValue();
      },
      getToday() {
        return getPastFromToday(0, 'days');
      },
      /** 일, 월, 년, 기간선택 버튼 visible 처리 */
      updatePeriodButtonVisible(mapData) {
        this.periodButtons.forEach(button => {
          button.visible = mapData[button.type];
        });
      },
      /** 년 리스트 초기 설정 */
      initializeYears() {
        const reportStartYear = this.$_getReportStartDt.substring(0, 4);

        const years = [];
        let year = getPastFromToday(0, 'days', 'YYYY');
        while (reportStartYear <= year) {
          years.push({ name: year + '년', value: year.toString() });
          year--;
        }

        this.dataSource.years = years;
      },
      /** 월 리스트 초기 설정 */
      initializeMonths() {
        const numberMonths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        this.dataSource.months = numberMonths.map(month => {
          const value = month < 10 ? `0${month}` : month.toString();
          return { name: `${month}월`, value };
        });
      },
    },
    mounted() {
      this.initializeYears();
      this.initializeMonths();
      this.changeButtonColor(this.selected.periodType);
    },
  };
</script>
<style scoped></style>
