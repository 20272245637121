<!--
  PACKAGE_NAME : src/pages/euc/device/hunt
  FILE_NAME : /modal-set-listlinegroup.vue
  AUTHOR : jhsim
  DATE : 2024-05-01
  DESCRIPTION : 헌트관리 - 회선그룹 추가 모달
-->
<template>
  <div class="locker_setting_list sub_new_style01 sub_ui_box1">
    <div class="page-sub-box" style="padding: 0">
      <DxButton
        :text="$_lang('UC.WORD.APPLY')"
        :width="120"
        :height="30"
        class="dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled fr"
        styling-mode="contained"
        type="default"
        @click="onUpdateLineGroup"
      />
      <table class="table_form line-bin">
        <colgroup>
          <col style="width: 30%" />
          <col style="width: 30%" />
          <col style="width: 30%" />
        </colgroup>
        <tr>
          <td style="padding: 0 5">
            <div style="margin: bottom 30px">
              <span style="font-weight: bold; font-size: 16px">{{ this.$_lang('UC.WORD.HUNTPILOT') }}</span>
              <span class="fr" style="font-weight: bold"
                >총 <font class="col_blue2">{{ this.huntpilotGrid.selectedRowCnt }}</font> /
                <font class="col_blue2">{{ this.huntpilotGrid.totalCount }}</font
                >개
              </span>
            </div>
            <esp-dx-data-grid :data-grid="huntpilotGrid" ref="huntpilotGrid"> </esp-dx-data-grid>
          </td>
          <td style="padding: 0 5">
            <div style="margin: bottom 30px">
              <span style="font-weight: bold; font-size: 16px">{{ this.$_lang('UC.WORD.HUNTLIST') }}</span>
              <span class="fr" style="font-weight: bold"
                >총 <font class="col_blue2">{{ this.huntlistGrid.selectedRowCnt }}</font> /
                <font class="col_blue2">{{ this.huntlistGrid.totalCount }}</font
                >개
              </span>
            </div>
            <esp-dx-data-grid :data-grid="huntlistGrid" ref="huntlistGrid"></esp-dx-data-grid>
          </td>
          <td style="padding: 0 5">
            <div style="margin: bottom 30px">
              <span style="font-weight: bold; font-size: 16px">{{ this.$_lang('UC.WORD.LINEGROUP') }}</span>
              <span class="fr" style="font-weight: bold"
                >총 <font class="col_blue2">{{ this.linegroupGrid.selectedRowCnt }}</font> /
                <font class="col_blue2">{{ this.linegroupGrid.totalCount }}</font
                >개
              </span>
            </div>
            <esp-dx-data-grid
              :data-grid="linegroupGrid"
              ref="linegroupGrid"
              @content-ready="onContentReady"
              @selection-changed="onSelectionChanged"
            ></esp-dx-data-grid>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
  import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { DxLookup } from 'devextreme-vue/data-grid';
  import { DxColumn } from 'devextreme-vue/tree-list';
  import { isSuccess } from '@/plugins/common-lib';
  import DxButton from 'devextreme-vue/button';
  import CustomStore from "devextreme/data/custom_store";
  import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid.vue";

  export default {
    components: {
      EspDxDataGrid,
      DxPopup,
      DxToolbarItem,
      DxSelectBox,
      DxColumn,
      DxButton,
      DxLookup,
    },
    props: {
      option: {
        default: () => {
          return {
            width: '80%',
            height: '85%',
            minWidth: null,
            minHeight: null,
            saveBtnVisible: false,
            cancelBtnVisible: true,
          };
        },
        type: Object,
      },
      rowInfo: {
        type: Object,
      },
      isOpened: {
        type: Boolean,
      },
      cmList: {
        type: Array,
      },
    },
    watch: {
      deviceInfo() {},
      async isOpened() {
        if (this.isOpened) {
          await this.mountedData();
        } else {
          this.refreshgGrid();
        }
      },
    },
    data() {
      return {
        dragData: {
          linegroup: [],
        },
        huntpilotGrid: {
          callApi: 'CALL_EUC_API',
          refName: 'huntpilotGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          // allowReordering: true,
          showBorders: true, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          disableTotalCount: true,
          toolbarItems: [],
          selectedRowCnt: 0,
          totalCount: 0,
          dataSource: [],
          // width: '400', // 주석처리시 100%
          // height: '550', // 주석처리시 100%
          height: 'calc(100vh - 350px)', // 주석처리시 100%
          apiActionNm: {},
          customEvent: {
            // initNewRow: true,
            rowClick: false,
            toolbarPreparing: false,
            selectionChanged: true,
            saving: true,
          },
          showActionButtons: {
            customButtons: [],
          },
          isDuplicateConfigKey: false, //설정키 중복 체크
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: false,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'single', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '패턴',
              i18n: 'UC.WORD.PATTERN',
              dataField: 'pattern',
              width: 100,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowExporing: true,
              fixed: false,
            },
            {
              caption: '설명',
              i18n: 'COMPONENTS.DESCRIPTION',
              dataField: 'description',
              // width: 250,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
          ],
        },
        huntlistGrid: {
          callApi: 'CALL_EUC_API',
          refName: 'huntlistGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: true, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          disableTotalCount: true,
          toolbarItems: [],
          selectedRowCnt: 0,
          totalCount: 0,
          // width: '400', // 주석처리시 100%
          // height: '550', // 주석처리시 100%
          height: 'calc(100vh - 350px)', // 주석처리시 100%
          apiActionNm: {
            loading: true,
          },
          customEvent: {
            // initNewRow: true,
            rowClick: false,
            selectionChanged: true,
          },
          showActionButtons: {
            customButtons: [],
          },
          isDuplicateConfigKey: false, //설정키 중복 체크
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'single', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '이름',
              i18n: 'COMPONENTS.NAME',
              dataField: 'name',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowExporing: true,
              fixed: false,
            },
            {
              caption: '설명',
              i18n: 'COMPONENTS.DESCRIPTION',
              dataField: 'description',
              // width: 200,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
          ],
        },
        linegroupGrid: {
          callApi: 'CALL_EUC_API',
          refName: 'linegroupGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          // allowReordering: true,
          showBorders: true, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          disableTotalCount: true,
          toolbarItems: [],
          selectedRowCnt: 0,
          totalCount: 0,
          // width: '400', // 주석처리시 100%
          // height: '550', // 주석처리시 100%
          height: 'calc(100vh - 350px)', // 주석처리시 100%
          apiActionNm: {
            loading: true,
          },
          customEvent: {
            // initNewRow: true,
            rowClick: false,
            selectionChanged: true,
            contentReady: true,
          },
          showActionButtons: {
            customButtons: [],
          },
          isDuplicateConfigKey: false, //설정키 중복 체크
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '이름',
              i18n: 'COMPONENTS.NAME',
              dataField: 'name',
              // width: 200,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowExporing: true,
              fixed: false,
            },
          ],
        },
      };
    },
    computed: {},
    methods: {
      onToolbarPreparing(e) {
        const toolbarItems = e.toolbarOptions.items;
        // Adds a new item

        toolbarItems.forEach(item => {
          if (item.name === 'addRowButton') {
            item.location = 'before';
            item.sortIndex = 10;
            item.options.icon = '';
            item.options.text = this.$_lang('COMPONENTS.ADD');
            item.showText = 'always';
            item.options.elementAttr = { class: 'btn_XS default filled add1' };
            item.options.width = '100';
            item.options.height = '30';
          }
        });
        toolbarItems.push({
          location: 'after',
          html:
            `<div class="btn_XS default filled dx-button dx-button-button dx-button-mode-contained dx-widget fr"
								title="` +
            this.$_lang('COMPONENTS.SAVE') +
            `" role="button" style="height: 30px;">
							<div class="dx-button-content"><span>` +
            this.$_lang('COMPONENTS.SAVE') +
            `</span></div>
							</div>`,
          // html: `<div class="btn_XS default filled dx-button dx-button-button dx-button-mode-contained dx-widget fr"
          // 				title="등록"" role="button" style="height: 30px;">
          // 			<div class="dx-button-content"><span>등록</span></div>
          // 			</div>`,
          onClick: this.onCreateHunt,
        });
        //toolbar sort
        e.toolbarOptions.items = toolbarItems.sort((a, b) => a.sortIndex - b.sortIndex);
      },
      /**@description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {},
      /**@description : 라이프사이클 mounted시 호출되는 메서드 */
      async mountedData() {
        await this.selectHuntpilot();
        await this.selectHuntlist('+huntlistOrd');
        await this.selectLinegroup('+selectionorder');
      },

      /**
       * @description 그리드 신규 row 추가 시 이벤트
       */
      // onInitNewRow(e) {
      // 	//교환기 정보 하나만 있을 경우 값 픽스
      // 	if (this.cmList.length === 1) {
      // 		e.data.cmNm = this.cmList[0].cmNm;
      // 	}
      // },
      /**
       * @description 그리드 데이터 초기화
       */
      refreshgGrid() {
        this.huntpilotGrid.dataSource = [];
        this.huntlistGrid.dataSource = [];
        this.linegroupGrid.dataSource = [];
        // this.dataGrid.dataSource = [];
        // this.$refs.pilotlistAddGrid.getInstance.cancelEditData();
      },
      /** @description : 헌트 파일럿 리스트 조회 */
      async selectHuntpilot(sort = '+huntpilotOrd') {
        // await this.$refs.huntpilotGrid.clearSelection();
        this.huntpilotGrid.dataSource = new CustomStore({
          key: 'id',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);
            let pattern = this.rowInfo?.pilot[0].pattern;
            if (!params.sort) {
              params.sort = sort;
            }

            if (params?.currentpage) {
              delete params['currentpage'];
            }
            params = { ...params, pattern: pattern };

            const payload = {
              actionName: 'EUC_HUNTPILOT_LIST_ALL',
              data: params,
              loading: false,
            };

            const rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await this.CALL_EUC_API(payload);
            if (isSuccess(res)) {
              rtnData.data = res.data.data;
              rtnData.totalCount = res.data.header.totalCount;
              this.huntpilotGrid.totalCount = rtnData.totalCount;
            }
            return rtnData;
          },
        });
      },
      /** @description : 헌트리스트 리스트 조회 */
      async selectHuntlist(sort) {
        this.huntlistGrid.dataSource = new CustomStore({
          key: 'id',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);
            let pilotId = this.rowInfo?.pilot[0].id;
            if (!params.sort) {
              params.sort = sort;
            }
            if (pilotId !== null) {
              params.huntpilotId = pilotId;
            }
            if (params?.currentpage) {
              delete params['currentpage'];
            }
            params = { ...params };

            const payload = {
              actionName: 'EUC_HUNTLIST_LIST_ALL',
              data: params,
              loading: false,
            };

            const rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await this.CALL_EUC_API(payload);
            if (isSuccess(res)) {
              rtnData.data = res.data.data;
              rtnData.totalCount = res.data.header.totalCount;

              this.huntlistGrid.totalCount = rtnData.totalCount;
            }
            return rtnData;
          },
        });
      },
      /** @description : 회선그룹 리스트 조회 */
      async selectLinegroup(sort) {
        this.linegroupGrid.dataSource = new CustomStore({
          key: 'id',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);
            // let huntlistId = this.rowInfo?.list[0].id;
            if (!params.sort) {
              params.sort = sort;
            }
            if (params?.currentpage) {
              delete params['currentpage'];
            }
            params = { ...params };

            const payload = {
              actionName: 'EUC_LINEGROUP_LIST_ALL',
              data: params,
              loading: false,
            };

            const rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await this.CALL_EUC_API(payload);
            if (isSuccess(res)) {
              rtnData.data = res.data.data;
              rtnData.totalCount = res.data.header.totalCount;
              this.linegroupGrid.totalCount = rtnData.totalCount;
            }
            return rtnData;
          },
        });
      },

      onContentReady(e) {
        let keys = [];
        const gridData = e.component.getDataSource()._items;
        for (let i = 0; i < gridData.length; i++) {
          if (this.rowInfo.list[0].id === gridData[i].huntlistId) {
            keys.push(gridData[i].id);
          }
        }
        e.component.selectRows(keys);
      },

      /**@description			그리드 selectRow 이벤트 함수
       * @param 	{Object}	그리드 selction 이벤트 데이터
       * @param 	{String}	그리드 ref 이름
       * @returns {}
       */
      onSelectionChanged(e) {
        this.linegroupGrid.selectedRowCnt = e.selectedRowsData.length;
      },

      /** @description	순서 저장 이벤트
       *  @param {type}	그리드 타입
       */
      async onUpdateLineGroup() {
        const selectRows = this.$refs.linegroupGrid.getInstance.getSelectedRowsData();
        const huntlistRow = this.$refs.huntlistGrid.getItems;
        const huntpilotRow = this.$refs.huntpilotGrid.getItems;
        if (selectRows?.length === 0) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return false;
        }
        let listName = '';
        let listId = 0;
        let cmNm = '';
        if (huntlistRow?.length === 1) {
          listName = huntlistRow[0].name;
          listId = huntlistRow[0].id;
        } else {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NO_DATA'));
          return;
        }
        if (huntpilotRow?.length === 1) {
          cmNm = huntpilotRow[0].cmNm;
        } else {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NO_DATA'));
          return;
        }
        const parseData = [];
        selectRows.forEach((item, index) => {
          parseData.push({ LINEGROUP: item.name, ORDER: index + 1 });
        });

        const params = {
          cmNm: cmNm,
          huntlistId: listId,
          type: 'HUNTLIST',
          huntlist: listName,
          // linegroupList: parseData,
          linegroupList: JSON.stringify(parseData),
          loginId: this.$store.getters.getLoginId,
        };
        const payload = {
          actionName: 'EUC_LINEGROUP_UPDATE',
          data: params,
          loading: true,
        };
        const confirm = this.$_lang('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' });
        if (!(await this.$_Confirm(confirm))) {
          return;
        }
        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          await this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_SUCCESS', { defaultValue: '정상적으로 처리되었습니다.' }));
          this.$emit('closeModal', true);
        } else {
          await this.$_Msg(res.data.header.resMsg);
        }
      },
    },
    async created() {
      this.createdData();
    },
    async mounted() {
      // await this.mountedData();
    },
  };
</script>
