<!--
  PACKAGE_NAME : src\pages\esp\user\password-change.vue
  FILE_NAME : password-change
  AUTHOR : devyoon91
  DATE : 2024-08-29
  DESCRIPTION : 패스워드 변경
-->
<template>
  <div ref="amorWrapper" class="amor_wrapper">
    <div id="viewport" class="viewport">
      <div class="visual-left">
        <img src="../../../assets/images/login/mask_group.png" class="visual-bg" />
        <div class="visual-footer" :style="{ color: theme.cpRightFontColor }">{{ theme.cpRightText }}</div>
      </div>

      <div class="login-wrap">
        <div ref="loginGroup" class="login-group password-inner">
          <div ref="loginBoard" class="login-board">
            <header class="login-header">
              <h1 class="login-text">
                {{ getI18n('COMPONENTS.PASSWORD_CHANGE', '패스워드 변경') }}
                <span
                  v-if="memberState === enums.common.memberState.EXPIRED.value"
                  v-html="
                    getI18n(
                      'COMMON.MESSAGE.PASSWORD_EXPIRED_WARNING',
                      `${pwdChgDay}일 동안 패스워드를 변경하지 않았습니다. <br /> 개인정보를 보호하기 위해 패스워드를 변경하세요.`,
                      { pwdChgDay: pwdChgDay },
                    )
                  "
                >
                </span>
                <span
                  v-if="memberState === enums.common.memberState.TEMPORARY.value"
                  v-html="
                    getI18n(
                      'COMMON.MESSAGE.PASSWORD_TEMPORARY_WARNING',
                      `패스워드 변경이 필요합니다. <br /> 개인정보를 보호하기 위해 패스워드를 변경하세요.`,
                    )
                  "
                >
                </span>
              </h1>
            </header>

            <div class="login-cont">
              <div class="login-form">
                <div class="input id id-box">
                  <label for="passwordInputBoxId">{{ getI18n('COMPONENTS.PASSWORD', '패스워드') }}</label>
                  <input
                    type="password"
                    id="passwordInputBoxId"
                    :placeholder="getI18n('COMPONENTS.PASSWORD', '패스워드')"
                    v-model="loginPwd"
                    @keyup="handlePasswordKeyup"
                  />
                  <i class="login-bak-pw"></i>
                </div>
                <div class="login-password-infobox">
                  <div :class="errMsg.password ? 'password-info-txt on' : 'password-info-txt'">{{ errMsg.password }}</div>
                </div>
                <div class="input pwd pwd-box">
                  <label for="passwordConfirmInputBoxId">{{ getI18n('COMPONENTS.PASSWORD_CONFIRM', '패스워드 확인') }}</label>
                  <input
                    type="password"
                    id="passwordConfirmInputBoxId"
                    :placeholder="getI18n('COMPONENTS.PASSWORD_CONFIRM', '패스워드 확인')"
                    v-model="loginPwdConfirm"
                    @keyup="handlePasswordConfirmKeyup"
                    ref="passwordConfirmRef"
                  />
                  <i class="login-bak-pw"></i>
                </div>
                <div class="login-password-infobox">
                  <div :class="errMsg.passwordConfirm ? 'password-confirm-info-txt on' : 'password-confirm-info-txt'">
                    {{ errMsg.passwordConfirm }}
                  </div>
                </div>
                <div>
                  <div class="pw-adm" v-html="passwordDesc"></div>
                </div>
                <div class="pw-admin-btn-box">
                  <button type="button" class="btn-pw-cancel" @click="passwdChgCancel">
                    {{ getI18n('COMPONENTS.CANCEL', '취소') }}
                  </button>
                  <button type="button" class="btn-pw-find" @click="passwdChgProcess">
                    {{ getI18n('COMPONENTS.SAVE', '저장') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { espApi } from '@/api';
  import { encryptPassword, isSuccess } from '@/plugins/common-lib';
  import enums from '@/configs/enums';

  export default {
    computed: {
      enums() {
        return enums;
      },
    },
    data() {
      return {
        errMsg: {
          // 에러 메시지 객체
          password: null, // 패스워드 에러 메시지
          passwordConfirm: null, //  패스워드 확인 에러 메시지
        },
        loginPwd: '',
        loginPwdConfirm: '',
        loginId: '',
        loginNm: '',
        memberState: '',
        pwdChgDay: 0,
        passwordDesc: null,
        theme: {
          cpRightText: this.$_theme.copyright,
          cpRightFontColor: this.$_theme.copyrightColor,
        },
      };
    },
    methods: {
      /**
       * @description 다국어 메시지 반환
       * @param key {string}
       * @param defaultValue {string}
       * @param params {Object}
       * @return {string}
       */
      getI18n(key, defaultValue, params) {
        return this.$_lang(key, { defaultValue: defaultValue, ...params });
      },
      /**
       * @description 패스워드 유효성 검사
       * @param e
       */
      handlePasswordKeyup(e) {
        this.errMsg.password = this.$_validatePasswordMessage(this.loginPwd, this.loginId, this.loginNm);

        if (e.key === 'Enter' && !this.errMsg.password) {
          this.$refs.passwordConfirmRef.focus();
        }
      },
      /**
       * @description 패스워드 확인 키 입력 이벤트
       * @param e
       */
      handlePasswordConfirmKeyup(e) {
        this.errMsg.passwordConfirm = this.$_validatePasswordMessage(this.loginPwdConfirm.trim(), this.loginId, this.loginNm);

        if (!this.errMsg.passwordConfirm && this.loginPwd.trim() !== this.loginPwdConfirm.trim()) {
          this.errMsg.passwordConfirm = this.$_lang('PASSWORD_MISMATCH', {
            defaultValue: '패스워드가 일치하지 않습니다. 다시 확인하세요.',
          });
        }

        if (e.key === 'Enter' && this.errMsg.passwordConfirm === null) {
          this.passwdChgProcess();
        }
      },
      /**
       * 패스워드 변경 취소
       */
      passwdChgCancel() {
        this.$store.dispatch('LOGOUT', {
          data: {
            loginId: this.$store.getters.getLoginId,
          },
        });
      },
      /**
       * 패스워드 변경 처리
       *
       * @return {Promise<void>}
       * @constructor
       */
      async passwdChgProcess() {
        this.errMsg.password = this.$_validatePasswordMessage(this.loginPwd, this.loginId, this.loginNm);
        this.errMsg.passwordConfirm = this.$_validatePasswordMessage(this.loginPwdConfirm, this.loginId, this.loginNm);

        if (!this.errMsg.passwordConfirm && this.loginPwd.trim() !== this.loginPwdConfirm.trim()) {
          this.errMsg.passwordConfirm = this.$_lang('PASSWORD_MISMATCH', {
            defaultValue: '패스워드가 일치하지 않습니다. 다시 확인하세요.',
          });
        }

        if (!this.errMsg.password && !this.errMsg.passwordConfirm) {
          const payload = {
            loginId: this.loginId,
            loginPwd: encryptPassword(this.loginId, this.loginPwd, this.$store.getters.getEncryptionType),
          };

          const res = await espApi.getPasswdChg(payload);
          if (isSuccess(res)) {
            await this.$router.push(this.$route.query ? this.$route.query.continue : undefined);
          } else {
            this.errMsg.passwordConfirm = res.data.header.resMsg;
          }
        } else {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.PASSWORD_INVALID', { defaultValue: '유효하지 않은 패스워드입니다.' }));
        }
      },
    },
    mounted() {
      this.memberState = this.$store.getters.getMemberState;
      this.pwdChgDay = this.$_getSystemData('pwd_chg_day')?.configValue;
      this.loginId = this.$store.getters.getLoginId;
      this.loginNm = this.$store.getters.getLoginNm;

      const [consecutiveLength, includeLoginId, includeLoginNm, minLength, maxLength, minCategories, specialChars] =
        this.$_getPwdSystemData();

      const rules = [
        `대문자, 소문자, 숫자, 특수문자 중 ${minCategories}가지 이상 조합`,
        `최소 ${minLength}자 이상, 최대 ${maxLength}자 이하`,
        `연속된 문자나 숫자 ${consecutiveLength}자 이상 사용 불가`,
        includeLoginId ? '아이디 포함 불가' : '',
        includeLoginNm ? '이름 포함 불가' : '',
        `사용 가능 특수문자 ${specialChars}`,
        '예) Abc123!@#',
      ];

      this.passwordDesc = rules.filter(rule => rule !== '').join('<br/>');
    },
  };
</script>

<style scoped src="@/assets/css/login.css"></style>
