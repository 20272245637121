<template>
    <transition>
        <div>
            <DxTreeList
                class="grid-box"
                ref="TargetGroupGrid"
                key-expr="codeId"
                display-expr="codeNm"
                :data-source="targetGridData.dataSource"
                :expanded-row-keys="targetGridData.expandedRowKeys"
                :selected-row-keys="targetGridData.selectedRowKeys"
                :show-borders="false"
                :show-column-headers="false"
                :show-column-lines="true"
                :show-row-lines="true"
                :row-alternation-enabled="false"
                :no-data-text="this.$_lang('CMN_NO_DATA')"
                @selection-changed="onSelectionChanged"
                height="500"
                width="100%"
            >
                <DxSelection mode="multiple" :recursive="true"/>

                <DxColumn
                    caption="부서정보"
                    data-field="codeNm"
                    :allow-sorting="false"
                />
            </DxTreeList>
        </div>
    </transition>
</template>
  
<script>
import { DxCheckBox } from 'devextreme-vue/check-box';
import { 
    DxTreeList, 
    DxColumn,
    DxSelection,
} from 'devextreme-vue/tree-list';

let vm = this;

export default {
    components: {
        DxCheckBox,

        DxTreeList,
        DxColumn,
        DxSelection,
    },
    props: {
        contentData: {
            default: () => {
                return [];
            },
            type: Array,
        },
    },
    watch: {},
    data() {
        return {
            targetGridData: {
				dataSource: [],
                selectedRowsData: [],
                selectedRowKeys: [],
                expandedRowKeys: [],
                rootItem: [],
                displayExpr: 'codeNm',
                valueExpr: 'codeId',
			},
            codes: {
				dept0: {
					dataSource: [],
				},
				dept1: {
					dataSource: [],
				},
				dept2: {
					dataSource: [],
				},
				dept3: {
					dataSource: [],
				},
			},
        }
    },
    computed: {

    },
    methods: {
        onSelectionChanged({ selectedRowsData }){
            const data = selectedRowsData;
            this.$emit('input', data);
        },
        /** @description : 코드 로드 */
        async initCodeMap() {
			this.codeMap = await this.$_getCodeMapList('root_ewm_hr_dept');
		},
		async createdData() {
			vm = this;

			await this.initCodeMap().then(() => {
				this.codes.dept1.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_hr_dept'], 2);
				this.codes.dept2.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_hr_dept'], 3);
				this.codes.dept3.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_hr_dept'], 4);

                this.codes.dept0.dataSource = [
					{
						codeId: this.codes.dept1.dataSource.length > 0 ? this.codes.dept1.dataSource[0].parentId : -1,
						codeKey: 'ewm_hr_dept',
						codeNm: '전체',
						codeValue: 'ewm_hr_dept',
						depth: 1,
						parentId: null,
						path: '',
						pathNmFull: '전체',
					},
				];

                this.targetGridData.dataSource = [
					...this.codes.dept3.dataSource,
					...this.codes.dept2.dataSource,
					...this.codes.dept1.dataSource,
					...this.codes.dept0.dataSource,
				];

                this.targetGridData.expandedRowKeys = this.targetGridData.dataSource.map(d => d.codeId);
                this.targetGridData.selectedRowKeys = this.contentData;
                this.targetGridData.rootItem = this.codes.dept0.dataSource.map(d => d.codeId);
			});
		}
    },
    created() {
        this.createdData();
    },
    mounted() {

    },
}
</script>