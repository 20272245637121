<!--
  PACKAGE_NAME : src\pages\ewm\hr\work\extra-allowance-list.vue
  FILE_NAME : extra-allowance-list
  AUTHOR : bykim
  DATE : 2023-12-19
  DESCRIPTION : 근무정보설정 > 추가수당설정 탭 화면
-->
<template>
  <div>
    <esp-dx-data-grid :data-grid="dataGrid" ref="eaGridRef" />
  </div>
</template>

<script>
  import { isSuccess } from '@/plugins/common-lib';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: { EspDxDataGrid },
    props: {},
    data() {
      return {
        dataGrid: {
          keyExpr: 'id', // 그리드 키값
          refName: 'eaGridRef', // 그리드 컴포넌트 참조명
          callApi: 'CALL_EWM_API', // 그리드 데이터 호출 API
          excel: {
            title: '추가수당설정', // 엑셀 다운로드 시 파일명
            autoFilterEnabled: true, // 엑셀 필터 사용 유무
          },
          focusedRowEnabled: true, // 포커스 행 표시 유무
          allowColumnResizing: true, // 컬럼 사이즈 조절 유무
          showBorders: false, // 그리드 테두리 유무
          showColumnHeaders: true, // 컬럼 헤더 유무
          showColumnLines: false, // 컬럼 세로선 유무
          showRowLines: true, // 컬럼 가로선 유무
          rowAlternationEnabled: false, //행 배경색 교차 유무
          dataSource: [], // 그리드 데이터
          apiActionNm: {
            merge: 'EWM_WORK_INFO_EA_MERGE',
            select: 'EWM_WORK_INFO_EA_LIST_ALL',
          }, // api 호출시 사용할 액션명(ESP - API URL 관리)
          scrolling: {
            mode: 'standard', // 스크롤 모드 : ['standard', 'virtual', 'infinite']
          },
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
            cellPrepared: true, // 셀 이벤트
            rowClick: true, // 행 클릭 이벤트
          },
          showActionButtons: {
            excel: true, // 엑셀 다운로드 버튼
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '삭제',
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 60,
                  height: 30,
                  onClick: () => {
                    this.onDeleteData();
                  },
                },
                location: 'before',
              },
            ], // 그리드 커스텀 버튼 생성
          },
          isDuplicateConfigKey: false, // 설정키 중복 체크
          grouping: {
            contextMenuEnabled: false, // 그룹핑 컨텍스트 메뉴 사용유무
            autoExpandAll: false, // 그룹핑시 전체 펼침 여부
            allowCollapsing: true, // 그룹핑시 접기 허용 여부
            expandMode: 'rowClick', // 그룹핑 펼침 모드 : ['rowClick', 'buttonClick']
          },
          groupPanel: {
            visible: false, // 그룹패널 표시 여부
          },
          columnChooser: {
            enabled: false, // 컬럼 선택 팝업 표시 여부
          },
          loadPanel: {
            enabled: true, // 로딩 패널 표시 여부
          },
          sorting: {
            mode: 'multiple', // 정렬 모드 : ['none', 'single', 'multiple']
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false, // 페이징 사용 유무
            // pageSize: 10,
            // pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, // 페이지 사이즈 선택 버튼 표시 여부
            allowedPageSizes: [], // 페이지 사이즈 선택 버튼에 표시할 항목
            displayMode: 'compact', // 페이저 표시 모드 : ['adaptive', 'compact', 'full']
            showInfo: false, // 페이지 정보 표시 여부
            showNavigationButtons: false, // 페이지 이동 버튼 표시 여부
          },
          filterRow: {
            visible: true, // 필터 행 표시 여부
          },
          headerFilter: {
            visible: true, // 헤더 필터 표시 여부
          },
          editing: {
            allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: true, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false, // 삭제 버튼을 없애고 싶으면 false설정
            mode: 'batch', // 행 편집 모드 : ['cell', 'row', 'batch']
            startEditAction: 'click', // 행 편집 시작 액션 : ['click', 'dblClick']
            selectTextOnEditStart: false, // 편집 시작시 텍스트 선택 여부
          },
          selecting: {
            mode: 'multiple', // 행 선택 모드 : ['none', 'single', 'multiple']
            selectAllMode: 'allPages', // 전체 선택 모드 : ['page', 'allPages']
            showCheckBoxesMode: 'always', // 체크박스 표시 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '추가수당설정ID',
              dataField: 'id',
              visible: false,
              sortOrder: 'none',
            },
            {
              caption: '추가수당명',
              dataField: 'allowanceNm',
              visible: true,
              sortOrder: 'none',
            },
            {
              caption: '일 최대시간',
              dataField: 'maxDailyHours',
              visible: true,
              sortOrder: 'none',
            },
            {
              caption: '월 최대시간',
              dataField: 'maxMonthlyHours',
              visible: true,
              sortOrder: 'none',
            },
            {
              caption: '적용 방식',
              dataField: 'calculationMethodCd',
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              lookup: {
                dataSource: this.$_enums.ewm.extraAllowanceCalculator.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
            {
              caption: '추가수당값',
              dataField: 'allowanceValue',
              visible: true,
              sortOrder: 'none',
              alignment: 'right',
              allowHeaderFiltering: false,
              allowFiltering: true,
              allowGrouping: false,
            },
            {
              caption: '수정자',
              dataField: 'editId',
              visible: true,
              sortOrder: 'none',
              allowEditing: false,
              allowHeaderFiltering: false,
              allowFiltering: false,
              alignment: 'center',
            },
            {
              caption: '수정일시',
              dataField: 'editDt',
              dataType: 'date',
              format: 'yyyy-MM-dd HH:mm:ss',
              visible: true,
              sortOrder: 'none',
              allowEditing: false,
              allowHeaderFiltering: false,
              allowFiltering: false,
              alignment: 'center',
            },
            {
              caption: '등록자',
              dataField: 'regId',
              visible: true,
              sortOrder: 'none',
              allowEditing: false,
              allowHeaderFiltering: false,
              allowFiltering: false,
              alignment: 'center',
            },
            {
              caption: '등록일시',
              dataField: 'regDt',
              dataType: 'date',
              format: 'yyyy-MM-dd HH:mm:ss',
              visible: true,
              sortOrder: 'none',
              allowEditing: false,
              allowHeaderFiltering: false,
              allowFiltering: false,
              alignment: 'center',
            },
          ], // 컬럼 정보
        },
      };
    },
    computed: {},
    methods: {
      /** @description: 추가수당설정 삭제 */
      async onDeleteData() {
        const vm = this;
        const selectedRowsData = this.$refs.eaGridRef.selectedRowsData;
        if (selectedRowsData?.length) {
          if (await this.$_Confirm(this.$_lang('CMN_CFM_DELETE_SELECTED', { defaultValue: '선택한 데이터를 삭제하시겠습니까?' }))) {
            let eaIdList = [];
            selectedRowsData.forEach(row => {
              eaIdList.push(row['id']);
            });

            const payload = {
              actionName: 'EWM_WORK_INFO_EA_LIST_DELETE',
              data: { eaIdList: eaIdList },
              useErrorPopup: true,
            };
            const res = await vm.CALL_EWM_API(payload);
            if (isSuccess(res)) {
              this.$refs.eaGridRef.refreshData();
            }
          }
        } else {
          this.$_Msg(this.$_lang('CMN_NOT_SELECTED', { defaultValue: '선택된 데이터가 없습니다.' }));
          return false;
        }
      },
    },
    created() {},
    mounted() {},
  };
</script>

<style lang="scss" scoped></style>
