<template>
  <table class="table_form line-bin">
    <colgroup>
      <col style="width: 150px" />
      <col style="width: auto" />
    </colgroup>
    <tbody>
    <tr>
      <th class="th-bold">URL</th>
      <td>
        <DxSelectBox
          placeholder="Methods"
          :items="httpMethods.list"
          display-expr="valueText"
          v-model="httpMethods.selectedItem"
          value-expr="value"
          :styling-mode="stylingMode"
          :width="100"
          style="margin-left: 26px; margin-right: 10px"
        >
        </DxSelectBox>
        <DxTextBox placeholder="URL" :styling-mode="stylingMode" v-model="loadUrl.url" width="500">
          <DxValidator validation-group="modalValidationGroup2">
            <DxRequiredRule message="데이터를 선택해주세요." />
          </DxValidator>
        </DxTextBox>
      </td>
    </tr>
    <tr>
      <th class="th-bold">Request</th>
      <td>
        <Tabs ref="tabs" @selectedIndex="tabSelectedIndex" :tabType="1" id="myTabs">
          <Tab title="Headers" tabindex="1">
            <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" @saving="onSaving"></esp-dx-data-grid>
          </Tab>
          <Tab title="Params" tabindex="2">
            <esp-dx-data-grid :data-grid="dataGrid2" :ref="dataGrid2.refName" @saving="onSaving2"></esp-dx-data-grid>
          </Tab>
          <Tab title="Body" tabindex="3">
            <esp-dx-data-grid :data-grid="dataGrid3" :ref="dataGrid3.refName" @saving="onSaving3"></esp-dx-data-grid>
          </Tab>
        </Tabs>
      </td>
    </tr>
    </tbody>
  </table>
</template>
<script>
import {DxRequiredRule, DxValidator} from 'devextreme-vue/validator';
import {DxSelectBox} from 'devextreme-vue/select-box';
import Tab from '@/components/common/tab.vue';
import Tabs from '@/components/common/tabs.vue';
import {DxTextBox} from 'devextreme-vue/text-box';
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid.vue";

export default {
  components: {
    EspDxDataGrid,
    DxTextBox,
    Tabs,
    Tab,
    DxSelectBox,
    DxValidator,
    DxRequiredRule,
  },
  props: {},
  data() {
    return {
      stylingMode: 'outlined',
      dataGrid: {
        refName: 'loadUrlHeader',
        allowColumnResizing: true, //컬럼 사이즈 허용
        showBorders: false, //border 유무
        showColumnHeaders: true, //컬럼 헤더 유무
        showColumnLines: false, //컬럼 세로선 유무
        showRowLines: true, //컬럼 가로선 유무
        rowAlternationEnabled: false,
        dataSource: [],
        // width:'200',     // 주석처리시 100%
        height: '300', // 주석처리시 100%
        apiActionNm: {},
        customEvent: {
          saving: true,
        },
        showActionButtons: {
          // 그리드 버튼 노출 설정값
          select: false,
          save: true,
          delete: true,
          update: true,
          customButtons: [],
        },
        grouping: {
          contextMenuEnabled: false,
          autoExpandAll: false,
          allowCollapsing: true,
          expandMode: 'rowClick', // rowClick or buttonClick
        },
        groupPanel: {
          visible: false,
        },
        columnChooser: {
          enabled: false, // 컬럼 Chooser 버튼 사용유무
        },
        loadPanel: {
          enabled: true, // 로딩바 표시 유무
        },
        sorting: {
          mode: 'multiple', // single multiple
        },
        scrolling: {
          // 미사용시 주석처리
          mode: 'standard', //스크롤 모드 : ['infinite', 'standard', 'virtual']
        },
        remoteOperations: {
          // 서버사이드 여부
          filtering: false,
          sorting: false,
          grouping: false,
          paging: false,
        },
        paging: {
          // scrolling 미사용시만 적용됨
          enabled: false,
          pageSize: 10,
          pageIndex: 0, // 시작페이지
        },
        pager: {
          visible: false, //페이저 표시 여부
          showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
          allowedPageSizes: [5, 10, 15, 20], //페이지 사이즈 선택 박스
          displayMode: 'compact', //표시 모드 : ['full', 'compact']
          showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
          showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
        },
        filterRow: {
          visible: false,
        },
        headerFilter: {
          visible: true,
        },

        editing: {
          allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
          allowDeleting: true,
          allowAdding: true, // 추가 버튼을 없애고 싶으면 false설정
          mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
          startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
          selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
        },
        selecting: {
          mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
          selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
          showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
        },
        columns: [
          {
            caption: 'key',
            dataField: 'key',
            //width: 150,
            height: 40,
            alignment: 'center', // left center right
            visible: true,
            allowEditing: true,
            sortOrder: 'none', // acs desc none
            allowHeaderFiltering: false,
            fixed: false, // 컬럼 fix 시 사용
            fixedPosition: 'left', // left or right
          },
          {
            caption: 'value',
            dataField: 'value',
            //width: 150,
            height: 40,
            alignment: 'center', // left center right
            visible: true,
            allowEditing: true,
            sortOrder: 'none', // acs desc none
            allowHeaderFiltering: false,
            fixed: false, // 컬럼 fix 시 사용
            fixedPosition: 'left', // left or right
          },
        ],
      },
      dataGrid2: {
        refName: 'loadUrlParams',
        allowColumnResizing: true, //컬럼 사이즈 허용
        showBorders: false, //border 유무
        showColumnHeaders: true, //컬럼 헤더 유무
        showColumnLines: false, //컬럼 세로선 유무
        showRowLines: true, //컬럼 가로선 유무
        rowAlternationEnabled: false,
        dataSource: [],
        // width:'200',     // 주석처리시 100%
        height: '300', // 주석처리시 100%
        apiActionNm: {},
        customEvent: {
          saving: true,
        },
        showActionButtons: {
          // 그리드 버튼 노출 설정값
          select: false,
          save: true,
          delete: true,
          update: true,
        },
        grouping: {
          contextMenuEnabled: false,
          autoExpandAll: false,
          allowCollapsing: true,
          expandMode: 'rowClick', // rowClick or buttonClick
        },
        groupPanel: {
          visible: false,
        },
        columnChooser: {
          enabled: false, // 컬럼 Chooser 버튼 사용유무
        },
        loadPanel: {
          enabled: true, // 로딩바 표시 유무
        },
        sorting: {
          mode: 'multiple', // single multiple
        },
        scrolling: {
          // 미사용시 주석처리
          mode: 'standard', //스크롤 모드 : ['infinite', 'standard', 'virtual']
        },
        remoteOperations: {
          // 서버사이드 여부
          filtering: false,
          sorting: false,
          grouping: false,
          paging: false,
        },
        paging: {
          // scrolling 미사용시만 적용됨
          enabled: false,
          pageSize: 10,
          pageIndex: 0, // 시작페이지
        },
        pager: {
          visible: false, //페이저 표시 여부
          showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
          allowedPageSizes: [5, 10, 15, 20], //페이지 사이즈 선택 박스
          displayMode: 'compact', //표시 모드 : ['full', 'compact']
          showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
          showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
        },
        filterRow: {
          visible: false,
        },
        headerFilter: {
          visible: true,
        },

        editing: {
          allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
          allowDeleting: true,
          allowAdding: true, // 추가 버튼을 없애고 싶으면 false설정
          mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
          startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
          selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
        },
        columns: [
          {
            caption: 'key',
            dataField: 'key',
            //width: 150,
            height: 40,
            alignment: 'center', // left center right
            visible: true,
            allowEditing: true,
            sortOrder: 'none', // acs desc none
            allowHeaderFiltering: false,
            fixed: false, // 컬럼 fix 시 사용
            fixedPosition: 'left', // left or right
          },
          {
            caption: 'value',
            dataField: 'value',
            //width: 150,
            height: 40,
            alignment: 'center', // left center right
            visible: true,
            allowEditing: true,
            sortOrder: 'none', // acs desc none
            allowHeaderFiltering: false,
            fixed: false, // 컬럼 fix 시 사용
            fixedPosition: 'left', // left or right
          },
        ],
      },
      dataGrid3: {
        refName: 'loadUrlBody',
        allowColumnResizing: true, //컬럼 사이즈 허용
        showBorders: false, //border 유무
        showColumnHeaders: true, //컬럼 헤더 유무
        showColumnLines: false, //컬럼 세로선 유무
        showRowLines: true, //컬럼 가로선 유무
        rowAlternationEnabled: false,
        dataSource: [],
        // width:'200',     // 주석처리시 100%
        height: '300', // 주석처리시 100%
        apiActionNm: {},
        customEvent: {
          saving: true,
        },
        showActionButtons: {
          // 그리드 버튼 노출 설정값
          select: false,
          save: true,
          delete: true,
          update: true,
          customButtons: [],
        },
        grouping: {
          contextMenuEnabled: false,
          autoExpandAll: false,
          allowCollapsing: true,
          expandMode: 'rowClick', // rowClick or buttonClick
        },
        groupPanel: {
          visible: false,
        },
        columnChooser: {
          enabled: false, // 컬럼 Chooser 버튼 사용유무
        },
        loadPanel: {
          enabled: true, // 로딩바 표시 유무
        },
        sorting: {
          mode: 'multiple', // single multiple
        },
        scrolling: {
          // 미사용시 주석처리
          mode: 'standard', //스크롤 모드 : ['infinite', 'standard', 'virtual']
        },
        remoteOperations: {
          // 서버사이드 여부
          filtering: false,
          sorting: false,
          grouping: false,
          paging: false,
        },
        paging: {
          // scrolling 미사용시만 적용됨
          enabled: false,
          pageSize: 10,
          pageIndex: 0, // 시작페이지
        },
        pager: {
          visible: false, //페이저 표시 여부
          showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
          allowedPageSizes: [5, 10, 15, 20], //페이지 사이즈 선택 박스
          displayMode: 'compact', //표시 모드 : ['full', 'compact']
          showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
          showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
        },
        filterRow: {
          visible: false,
        },
        headerFilter: {
          visible: true,
        },

        editing: {
          allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
          allowDeleting: true,
          allowAdding: true, // 추가 버튼을 없애고 싶으면 false설정
          mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
          startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
          selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
        },
        columns: [
          {
            caption: 'key',
            dataField: 'key',
            //width: 150,
            height: 40,
            alignment: 'center', // left center right
            visible: true,
            allowEditing: true,
            sortOrder: 'none', // acs desc none
            allowHeaderFiltering: false,
            fixed: false, // 컬럼 fix 시 사용
            fixedPosition: 'left', // left or right
          },
          {
            caption: 'value',
            dataField: 'value',
            //width: 150,
            height: 40,
            alignment: 'center', // left center right
            visible: true,
            allowEditing: true,
            sortOrder: 'none', // acs desc none
            allowHeaderFiltering: false,
            fixed: false, // 컬럼 fix 시 사용
            fixedPosition: 'left', // left or right
          },
        ],
      },
      //HTTP
      httpMethods: {
        list: [
          {value: 'GET', valueText: 'GET'},
          {value: 'POST', valueText: 'POST'},
          {value: 'PUT', valueText: 'PUT'},
        ],
        selectedItem: 'GET',
      },
      //URL 데이터 관련 변수
      loadUrl: {
        url: 'http://localhost:8093/ecss/v1/loginhistorys',
        header: [],
        params: [],
        body: [],
      },
    };
  },
  methods: {
    tabSelectedIndex(index) {
      console.log('tabSelectedIndex', index);
    },
    onSaving(e) {
      this.loadUrl.header = this.$refs[this.dataGrid.refName].getInstance.getDataSource()._store._array;
    },
    onSaving2(e) {
      this.loadUrl.params = this.$refs[this.dataGrid2.refName].getInstance.getDataSource()._store._array;
    },
    onSaving3(e) {
      this.loadUrl.body = this.$refs[this.dataGrid3.refName].getInstance.getDataSource()._store._array;
    },
  },
  mounted() {
  },
  create() {
  },
  beforeMount() {
  },
};
</script>
<style scoped>
.th-bold {
  font-weight: 400;
}
</style>
