import { EventBus } from '@/event-bus';
import { ccApi } from '@/api';
import { errorPopup, responseConsoleLogger } from '../../api/error-handle';
import { getRoutingInfo, setRoutingPath } from './esp';

/**
 * CC API 호출
 * @param state
 * @param payload
 * @return {Promise<*|AxiosResponse<T>|AxiosInterceptorManager<AxiosResponse>|e.Response<any, Record<string, any>>|AuthenticatorResponse>}
 * @constructor
 */
export const CALL_CC_API = async ({ state }, payload) => {
  let res;
  try {
    const routingInfo = getRoutingInfo({ state }, payload);

    // API 호출 시 필요한 정보
    const requestParam = {
      host: routingInfo.host,
      path: setRoutingPath(routingInfo?.path, payload?.path),
      timeout: payload.timeout || routingInfo.timeout,
      method: routingInfo.method,
      actionName: payload.actionName,
      data: payload.data,
      responseType: payload.responseType,
      headers: payload.headers,
    };

    payload.loading && EventBus.$emit('app:progress', true);

    res = await ccApi.callApi(requestParam);

    // 디버그 로거
    responseConsoleLogger(payload.actionName, res);

    payload.loading && EventBus.$emit('app:progress', false);
    return res;
  } catch (error) {
    payload.loading && EventBus.$emit('app:progress', false);

    // 에러 팝업 처리
    errorPopup(error, payload);
    return error.response || error;
  }
};
