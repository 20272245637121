<template>
  <div v-if="isVisible" :class="['dim-loading', { 'no-background': !showBackground }]" :style="containerStyle">
    <div class="loading">
      <span :style="loaderStyles"></span>
      {{ displayText }}
    </div>
  </div>
</template>

<script>

export default {
  name: 'EspLoader',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    },
    targetSelector: {
      type: String,
      default: 'body'
    },
    hideOnOutsideClick: {
      type: Boolean,
      default: false
    },
    showBackground: {
      type: Boolean,
      default: false
    },
    useThemeColor: {
      type: Boolean,
      default: true
    },
    stopScroll: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isVisible: this.visible,
      displayText: this.text,
      scrollPosition: 0
    }
  },
  watch: {
    visible(val) {
      this.isVisible = val;
    },
    text(val) {
      this.displayText = val || this.$_lang('COMMON.MESSAGE.LOADING', { defaultValue: '로딩중...' });
    },
    isVisible(val) {
      if (this.stopScroll) {
        if (val) {
          this.disableScroll();
        } else {
          this.enableScroll();
        }
      }
    }
  },
  computed: {
    containerStyle() {
      const target = document.querySelector(this.targetSelector);
      if (target && this.targetSelector !== 'body') {
        return {
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%'
        };
      }
      return {
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%'
      };
    },
    themeColor() {
      if (!this.useThemeColor) return '#fca40b';
      return this.$store.getters.getThemeSetting?.themeColor || '#fca40b';
    },
    loaderStyles() {
      return {
        borderTopColor: this.themeColor,
        borderBottomColor: this.themeColor
      };
    }
  },
  methods: {
    handleOutsideClick() {
      if (this.hideOnOutsideClick) {
        this.hide();
      }
    },
    show(text) {
      if (text) this.displayText = text;
      this.isVisible = true;
    },
    hide() {
      this.isVisible = false;
    },
    updateThemeColor() {
      if (!this.useThemeColor) {
        this.themeColor = null;
      }
    },
    disableScroll() {
      // pageYOffset 대신 scrollY 사용
      this.scrollPosition = window.scrollY || window.pageYOffset;
      document.body.classList.add('not-scroll');
    },
    enableScroll() {
      document.body.classList.remove('not-scroll');
      // scrollTo 대신 scrollTo options 사용
      window.scrollTo({
        top: this.scrollPosition,
        behavior: 'instant' // 'auto'나 'smooth'도 사용 가능
      });
    }
  },
  beforeDestroy() {
    // 컴포넌트 제거 시 스크롤 상태 복원
    if (this.stopScroll && this.isVisible) {
      this.enableScroll();
    }
  }
}
</script>

<style scoped>
.no-background {
  background: transparent !important;
}

/* 로더 테마 색상 관련 스타일 */
.dim-loading .loading>span {
  border-top-color: var(--loader-theme-color, #fca40b);
  border-bottom-color: var(--loader-theme-color, #fca40b);
}
</style>