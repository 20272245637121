<!--
  PACKAGE_NAME : src\components\report\search-etc-option.vue
  FILE_NAME : search-etc-option.vue
  AUTHOR : kwmoon
  DATE : 2024-12-27
  DESCRIPTION : 보고서 검색 옵션 컴포넌트
-->
<template>
  <div v-if="options.length > 0" class="bg-gray-50 p-3 space-y-4 border-t border-gray-200">
    <div v-for="(row, rowIndex) in options" :key="rowIndex" class="flex flex-row">
      <!-- 컬럼 반복 -->
      <div
        v-for="(col, colIndex) in row.cols"
        :key="colIndex"
        class="flex flex-wrap items-center gap-6 md:w-1/2 sm:w-full"
        :class="`lg:w-${col.size}/${row.size}`"
      >
        <!-- form [ input, checkbox, select-box, radio-box ] -->
        <template v-for="(form, i) in col.form">
          <!-- Input 섹션 -->
          <div v-if="form.type === 'input'" :key="i" class="flex items-center gap-2">
            <label v-if="form.label" class="text-sm font-semibold">{{ form.label }}</label>
            <div v-for="(item, itemIndex) in form.items" :key="itemIndex" class="flex items-center gap-2">
              <label class="text-sm">{{ item.label }}</label>
              <DxTextBox
                class="w-full"
                styling-mode="outlined"
                v-model="item.value"
                :show-clear-button="true"
                :width="item.width || 120"
                :placeholder="item.placeholder"
                :input-attr="{ style: 'font-size: 11px; padding: 0 5px; height: 25px;' }"
              />
            </div>
          </div>

          <!-- Checkbox 섹션 -->
          <div v-if="form.type === 'checkbox'" :key="i" class="flex items-center gap-2">
            <label v-if="form.label" class="text-sm font-semibold">{{ form.label }}</label>
            <div class="flex items-center gap-3">
              <DxCheckBox
                v-for="(item, itemIndex) in form.items"
                v-model="item.checked"
                :key="itemIndex"
                :text="item.label"
                :value="item.checked"
                :on-content-ready="styledCheckboxFontSize"
              />
            </div>
          </div>

          <!-- RadioBox 섹션 -->
          <div v-if="form.type === 'radio'" :key="i" class="flex items-center gap-2">
            <label v-if="form.label" class="text-sm font-semibold">{{ form.label }}</label>
            <DxRadioGroup
              :items="form.items"
              v-model="form.value"
              displayExpr="label"
              valueExpr="value"
              layout="horizontal"
              :on-content-ready="styledRadioSize"
            />
          </div>

          <!-- SelectBox 섹션 -->
          <div v-if="form.type === 'select'" :key="i" class="flex items-center gap-2">
            <label v-if="form.label" class="text-sm font-semibold">{{ form.label }}</label>
            <DxSelectBox
              v-if="!form.multiple"
              value-expr="value"
              display-expr="label"
              styling-mode="outlined"
              v-model="form.value"
              :items="form.items"
              :height="28"
              :search-enabled="true"
              :width="form.width || 120"
              :show-clear-button="true"
              :placeholder="form.placeholder"
              @initialized="initSelectBox(form)"
            />
            <DxTagBox
              v-if="form.multiple"
              value-expr="value"
              display-expr="label"
              styling-mode="outlined"
              v-model="form.value"
              :items="form.items"
              :height="28"
              :max-displayed-tags="Number(form.maxDisplay || 3)"
              :show-multi-tag-only="false"
              :width="form.width || 300"
              :search-enabled="true"
              :show-selection-controls="true"
              :multiline="false"
              @initialized="initSelectBox(form)"
            />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
  import { DxCheckBox, DxTextBox } from 'devextreme-vue';
  import { DxTagBox } from 'devextreme-vue/tag-box';
  import DxRadioGroup from 'devextreme-vue/radio-group';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { getResData, isEmpty, isSuccess } from '@/plugins/common-lib';

  export default {
    components: { DxSelectBox, DxCheckBox, DxTextBox, DxRadioGroup, DxTagBox },
    props: {
      options: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        /** id 별 초기화 상태 체크 */
        initSelectBoxState: {},
      };
    },
    methods: {
      /** checkbox 폰트 사이즈 text-sm 으로 변경 */
      styledCheckboxFontSize(e) {
        const textElement = e.component.element().querySelector('.dx-checkbox-text');
        if (textElement) {
          textElement.style.fontSize = '0.875rem'; // Tailwind text-sm 크기
        }
      },
      /** radio 폰트 사이즈 text-sm 으로 변경 */
      styledRadioSize(e) {
        const containers = e.component.element().querySelectorAll('.dx-radio-value-container');
        const labels = e.component.element().querySelectorAll('.dx-item-content');
        const buttons = e.component.element().querySelectorAll('.dx-radiobutton');
        for (let i = 0; i < containers.length; i++) {
          labels[i].style.fontSize = '0.875rem';
          containers[i].style.paddingRight = '4px';
          buttons[i].style.marginRight = '0.75rem';
        }
      },
      /** select-box 변경 */
      async initSelectBox(form) {
        if (isEmpty(form.target)) {
          // target 이 없을 경우 XML 에서 강제 옵션 설정하여 사용해야 함
          return;
        }

        // payload 설정
        const payload = {
          actionName: 'REPORT_TARGET_RESULT_LIST',
          data: {
            data: {
              name: form.target,
              solution: form.solution,
              subPath: form.subPath,
              loginId: this.$store.getters.getLoginId,
            },
          },
          loading: false,
          useErrorPopup: false,
        };

        // items 설정
        const res = await this.CALL_REPORT_API(payload);
        if (isSuccess(res)) {
          form.items = getResData(res).map(item => ({
            label: item.TEXT,
            value: item.VALUE,
          }));
        }
      },
      /** 값 추출 */
      getValues() {
        const result = {};
        this.options.forEach(row => {
          row.cols.forEach(col => {
            // col 내 form 변경된 key, value 셋팅
            this.updateFormValue(result, col.form);
          });
        });

        return result;
      },
      /** 전달 받은 객체(result) 에 form (Key, Value) 설정 */
      updateFormValue(result, form) {
        form.forEach(form => {
          // input
          if (form.type === 'input') {
            form.items.forEach(item => {
              result[item.id] = item.value || '';
            });
          }

          // checkbox
          if (form.type === 'checkbox') {
            form.items.forEach(item => {
              result[item.id] = item.checked || false;
            });
          }

          // radio | select
          if (['radio', 'select'].includes(form.type)) {
            result[form.id] = form.value;
          }
        });
      },
      /** 추출된 값 전달용 */
      getOptionFormData() {
        return this.getValues();
      },
    },
  };
</script>
