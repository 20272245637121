<!--
  PACKAGE_NAME : src\pages\ewm\hr\agent\management\modal-agent-card.vue
  FILE_NAME : modal-agent-card
  AUTHOR : bykim
  DATE : 2023-11-28
  DESCRIPTION : (모달) 상담원관리 인사카드
-->
<template>
  <DxCustomPopup
    :option="{
      title: '인사 카드',
      width: '600',
      height: '800',
      minWidth: '600',
      minHeight: '800',
    }"
    :isOpen="popupVisible"
    @closeModal="onClose(false)"
    @saveModal="onSave"
  >
    <template #content>
      <DxForm
        :form-data="formData"
        :label-location="formLabelLocation"
        :label-mode="formLabelMode"
        :min-col-width="formMinColWidth"
        :disabled="formDisabled"
        :read-only="formReadOnly"
        @initialized="saveFormInstance"
      >
        <DxGroupItem :col-count="2">
          <DxSimpleItem :col-span="1" data-field="agt" :editor-options="agtOptions">
            <DxLabel text="상담원ID" />
          </DxSimpleItem>
          <DxEmptyItem :col-span="1"/>
          <DxItem :col-span="1" editorType="dxRadioGroup" :editor-options="maleCdOptions">
            <DxLabel text="성별구분" />
          </DxItem>
          <DxEmptyItem :col-span="1"/>
          <DxItem :col-span="1" editorType="dxRadioGroup" :editor-options="lunarCdOptions">
            <DxLabel text="양력/음력" />
          </DxItem>
          <DxEmptyItem :col-span="1"/>
          <DxSimpleItem :col-span="1" data-field="birthDt" :editor-options="birthDtOptions">
            <DxLabel text="생년월일" />
            <DxPatternRule pattern="([12]\d{3}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01]))" message="잘못된 날짜 포멧 (ex)20230101)" />
          </DxSimpleItem>
          <DxEmptyItem :col-span="1"/>
          <DxSimpleItem :col-span="1" data-field="telNo" :editor-options="telNoOptions">
            <DxLabel text="전화번호" />
          </DxSimpleItem>
          <DxEmptyItem :col-span="1"/>
          <DxSimpleItem :col-span="1" data-field="phoneNo" :editor-options="phoneNoOptions">
            <DxLabel text="휴대폰번호" />
          </DxSimpleItem>
          <DxEmptyItem :col-span="1"/>
          <DxSimpleItem :col-span="2" data-field="email" :editor-options="emailOptions">
            <DxLabel text="이메일" />
          </DxSimpleItem>
          <DxSimpleItem :col-span="1" data-field="postNo" :editor-options="postNoOptions">
            <DxLabel text="우편번호" />
          </DxSimpleItem>
          <DxEmptyItem :col-span="1"/>
          <DxSimpleItem :col-span="2" data-field="address" :editor-options="addressOptions">
            <DxLabel text="주소" />
          </DxSimpleItem>
          <DxSimpleItem :col-span="2" data-field="addressDetail" :editor-options="addressDetailOptions">
            <DxLabel text="상세 주소" />
          </DxSimpleItem>
          <DxSimpleItem :col-span="1" :editor-options="educationLevelCdOptions" editor-type="dxSelectBox">
            <DxLabel text="최종 학력" />
          </DxSimpleItem>
        </DxGroupItem>
      </DxForm>
    </template>
  </DxCustomPopup>
</template>

<script>
import { DxItem, DxEmptyItem, DxForm, DxGroupItem, DxLabel, DxSimpleItem, DxPatternRule } from 'devextreme-vue/form';
import DxCustomPopup from '@/components/devextreme/esp-dx-modal-popup.vue';
import {isEmpty, isSuccess} from "@/plugins/common-lib";

export default {
  components: {
    DxItem,
    DxCustomPopup,
    DxEmptyItem,
    DxForm,
    DxGroupItem,
    DxLabel,
    DxSimpleItem,
    DxPatternRule,
  },
  data() {
    return {
      formInstance: null, // Form 인스턴스
      popupVisible: false, // Popup 표시여부
      closeButtonOptions: {
        text: 'Close',
        onClick: () => {
          this.popupVisible = false;
        },
      },
      formData: {
        agtid: '',
        agtNm: '',
        agt: '',
        educationLevelCd: '',
        maleCd: '',
        lunarCd: '',
      },
      formLabelMode: 'outside',
      formLabelLocation: 'left',
      formMinColWidth: 400,
      formDisabled: false, // 저장 할때 form 일시적으로 disabled 하는 값
      formReadOnly: false, // form Read
      agtOptions: {
        disabled: true,
        stylingMode: "outlined",
        onInitialized: e => {
          this.agtInstance = e;
        },
      },
      birthDtOptions: {
        stylingMode: "outlined",
        placeholder: 'ex) yyyyMMdd',
        onInitialized: e => {
          this.birthDtInstance = e;
        },
      },
      telNoOptions: {
        stylingMode: "outlined",
        onInitialized: e => {
          this.telNoInstance = e;
        },
      },
      phoneNoOptions: {
        stylingMode: "outlined",
        onInitialized: e => {
          this.phoneNoInstance = e;
        },
      },
      emailOptions: {
        stylingMode: "outlined",
        onInitialized: e => {
          this.emailInstance = e;
        },
      },
      postNoOptions: {
        stylingMode: "outlined",
        onInitialized: e => {
          this.postNoInstance = e;
        },
      },
      addressOptions: {
        stylingMode: "outlined",
        onInitialized: e => {
          this.addressInstance = e;
        },
      },
      addressDetailOptions: {
        stylingMode: "outlined",
        onInitialized: e => {
          this.addressDetailInstance = e;
        },
      },
      educationLevelCdOptions: {
        dataSource: [],
        valueExpr: 'codeId',
        displayExpr: 'codeNm',
        placeholder: '선택',
        showClearButton: true,
        stylingMode: "outlined",
        width: '100%',
        value: '',
        onSelectionChanged: e => {
          if (!isEmpty(e.selectedItem)) {
            this.formData.educationLevelCd = e.selectedItem.codeId;
            return;
          }
          this.formData.educationLevelCd = '';
        },
        onInitialized: e => {
          this.educationLevelCdInstance = e;
        },
      },
      // gisuCdOptions: {
      //   dataSource: [],
      //   valueExpr: 'codeId',
      //   displayExpr: 'codeNm',
      //   placeholder: '선택',
      //   showClearButton: true,
      //   stylingMode: "outlined",
      //   width: '100%',
      //   value: '',
      //   onSelectionChanged: e => {
      //     if (!isEmpty(e.selectedItem)) {
      //       this.formData.gisuCd = e.selectedItem.codeId;
      //       return;
      //     }
      //     this.formData.gisuCd = '';
      //   },
      //   onInitialized: e => {
      //     this.gisuCdInstance = e;
      //   },
      // },
      maleCdOptions: {
        dataSource: [],
        layout: 'horizontal',
        valueExpr: 'codeId',
        displayExpr: 'codeNm',
        onInitialized: e => {
          this.maleCdInstance = e;
        },
        onValueChanged: e => {
          this.formData.maleCd = e.value;
        },
      },
      lunarCdOptions: {
        dataSource: [
          {
            codeId: 1,
            codeNm: "양력",
          },
          {
            codeId: 2,
            codeNm: "음력",
          },
        ],
        layout: 'horizontal',
        valueExpr: 'codeId',
        displayExpr: 'codeNm',
        value: '',
        onInitialized: e => {
          this.lunarCdInstance = e;
        },
        onValueChanged: e => {
          this.formData.lunarCd = e.value;
        },
      },
      agtInstance: null,
      birthDtInstance: null,
      telNoInstance: null,
      phoneNoInstance: null,
      emailInstance: null,
      addressInstance: null,
      addressDetailInstance: null,
      educationLevelCdInstance: null,
      maleCdInstance: null,
      lunarCdInstance: null,
      postNoInstance: null,
    }
  },
  methods: {
    /**
     * @description : option api 호출 <br>
     * this.instance.component.option(key) or this.instance.component.option(key,value)
     * @param instance String
     * @param key String
     * @param value String
     * **/
    option(instance, key, value) {
      this.$data[instance]?.component.option(key, value);
    },
    /**
     * @description : option api - value 값 변경
     * @param instance String
     * @param value String
     * **/
    setValue(instance, value) {
      this.$data[instance]?.component.option('value', value);
    },
    /**
     * @description : option api - value 값 조회
     * @param instance String
     * **/
    getValue(instance) {
      return this.$data[instance]?.component.option('value');
    },
    /** @description : 상담사 상세(인사카드) 검색  */
    async selectUserDetail(agtid) {
      this.formDisabled = true; // 데이터 저장 과정중 일시적으로 form disable
      const payload = {
        actionName: 'EWM_AGENT_DETAIL_LIST',
        data: {
          agtid: agtid,
        },
        loading: false,
      };
      const res = await this.CALL_EWM_API(payload);
      if (isSuccess(res)) {
        this.formDisabled = false;
        return res?.data?.data;
      } else  {
        this.formDisabled = false;
        return null;
      }
    },
    /** @description : form 저장  */
    saveFormInstance(e) {
      this.formInstance = e.component;
    },
    /** @description : form 표시  */
    onClose(isVisible) {
      this.formInstance.resetValues(); // form 갱신
      this.popupVisible = isVisible;
    },
    /** @description : form 인스턴스 저장  */
    async onSave() {
      let vm = this;

      if (await this.$_Confirm(this.$_lang('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' }))) {
        this.formDisabled = true; // 데이터 저장 과정중 일시적으로 form disable
        let data = this.formInstance.option('formData');
        if (data['birthDt'] === "") {
          data['birthDt'] = null;
        }
        const payload = {
          actionName: 'EWM_AGENT_DETAIL_MERGE',
          data: data,
          loading: true,
        };
        const res = await vm.CALL_EWM_API(payload);
        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
        } else {
          this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
        // 저장 후 form disable false
        this.formDisabled = false;
      }
    },
    /** @description : 셀렉트 박스 초기화  */
    async init() {
      let codeMap = await this.$_getCodeMapList('root_education_level,root_ewm_hr_gisu,root_gender_type');
      // 학력구분
      this.option('educationLevelCdInstance', 'dataSource', this.$_fetchCodesByMaxDepth(codeMap['root_education_level']));
      // 기수구분
      // this.option('gisuCdInstance', 'dataSource', this.$_fetchCodesByMaxDepth(codeMap['root_ewm_hr_gisu']));
      // 성별구분
      this.option('maleCdInstance', 'dataSource', this.$_fetchCodesByMaxDepth(codeMap['root_gender_type']));
      // this.maleCdInstance.component.getDataSource().reload();
    },
    /** @description : 부모에서 전달 받은 데이터  */
    async passProps(agtid, agtNm) {
      this.formInstance.resetValues();
      this.popupVisible = true;
      this.formData.agtid = agtid;
      this.formData.agtNm = agtNm;
      this.formData.agt = `${agtNm}(${agtid})`;

      await this.selectUserDetail(agtid)
        .then(dataList => {
          if (dataList === null || dataList.length === 0) {
            return;
          }
          const data = dataList[0];
          this.setValue('telNoInstance', data?.telNo);
          this.setValue('phoneNoInstance', data?.phoneNo);
          this.setValue('birthDtInstance', data?.birthDt);
          this.setValue('emailInstance', data?.email);
          this.setValue('postNoInstance', data?.postNo);
          this.setValue('addressInstance', data?.address);
          this.setValue('addressDetailInstance', data?.addressDetail);
          this.setValue('educationLevelCdInstance', data?.educationLevelCd)
          this.setValue('maleCdInstance', data?.maleCd);
          this.setValue('lunarCdInstance', data?.lunarCd);
        }).catch(err => {
          console.log(err);
        });
    },
  },
  async created() {
    await this.init();
  },
  mounted() {},
}
</script>

<style lang="scss" scoped>

</style>