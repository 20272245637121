<!--
  PACKAGE_NAME : src\pages\esp\system\menu\main-page\edit-form.vue
  FILE_NAME : edit-form
  AUTHOR : sskim
  DATE : 2024-07-01
  DESCRIPTION : 메인 설정 -> 우측 설정대상 및 메인유형 선택 컴포넌트
-->
<template>
  <div>
    <table class="table_form line-bin">
      <colgroup>
        <col style="width: 180px" />
        <col style="width: calc(100% - 180px)" />
      </colgroup>
      <tbody>
        <tr>
          <th>
            <label for="label00">설정 ID</label>
          </th>
          <td>
            {{ data.id }}
          </td>
        </tr>
        <tr>
          <th scope="row">
            <label for="label01">
              {{ $_lang('COMMON.WORD.SETTING_TARGET', { defaultValue: '설정 대상' }) }}
              <span class="icon_require"></span>
            </label>
          </th>
          <td>
            <dx-select-box
              id="label01"
              :styling-mode="stylingMode"
              :style="{ marginRight: '9px' }"
              :height="30"
              width="100%"
              :items="enums.common.mainTargetField.values"
              display-expr="label"
              value-expr="value"
              v-model="data.targetField"
              :disabled="isEdit"
            >
              <dx-validator :validation-group="validateGroupName">
                <dx-required-rule :message="getRequireMsg($_lang('COMMON.WORD.SETTING_TARGET', { defaultValue: '설정 대상' }))" />
              </dx-validator>
            </dx-select-box>
          </td>
        </tr>
        <tr>
          <th scope="row">
            <label for="label02"
              >{{ $_lang('COMPONENTS.TARGET', { defaultValue: '대상' }) }}<span class="icon_require"></span
            ></label>
          </th>
          <td height="420">
            <template v-if="!data.targetField">
              <span>{{ $_lang('PLEASE_SELECT_TARGET', { defaultValue: '대상을 선택해주세요.' }) }}</span>
            </template>
            <template v-else>
              <dx-tree-list
                v-if="isAuthField(data.targetField)"
                id="label02"
                ref="authTree"
                :height="400"
                :data-source="tree.auth.authList"
                :columns="tree.auth.authListColumns"
                key-expr="id"
                :selectedRowKeys="tree.auth.selectedRowKeys"
                :no-data-text="$_lang('CMN_NO_DATA', { defaultValue: '데이터가 없습니다.' })"
                :show-column-headers="true"
                :show-column-lines="true"
                :show-borders="true"
                :column-auto-width="true"
                :auto-expand-all="true"
                @cell-prepared="e => handleCellPrepared(e, enums.common.mainTargetField.AUTH_ID.value)"
              >
                <dx-sorting mode="single" />
                <dx-selection mode="multiple" />
                <dx-column
                  :caption="$_lang('COMPONENTS.AUTH', { defaultValue: '권한' })"
                  data-field="id"
                  cell-template="nameTemplate"
                />
                <template #nameTemplate="{ data }">
                  <div style="text-align: left">
                    {{ data.data.authNm }}
                  </div>
                </template>
              </dx-tree-list>
              <dx-tree-list
                v-else-if="isLoginIdField(data.targetField)"
                id="label02"
                ref="userTree"
                :height="400"
                :data-source="tree.user.userList"
                :columns="tree.user.userListColumns"
                key-expr="loginId"
                :selectedRowKeys="tree.user.selectedRowKeys"
                :no-data-text="$_lang('CMN_NO_DATA', { defaultValue: '데이터가 없습니다.' })"
                :show-column-headers="true"
                :show-column-lines="true"
                :show-borders="true"
                :column-auto-width="true"
                :auto-expand-all="true"
                @cell-prepared="e => handleCellPrepared(e, enums.common.mainTargetField.LOGIN_ID.value)"
              >
                <dx-sorting mode="single" />
                <dx-selection mode="multiple" />
                <dx-column
                  :caption="$_lang('COMPONENTS.LOGIN_ID', { defaultValue: '아이디' })"
                  data-field="loginId"
                  cell-template="nameTemplate"
                />
                <template #nameTemplate="{ data }">
                  <div style="text-align: left">
                    {{ `${data.data.loginNm}(${data.data.loginId})` }}
                  </div>
                </template>
              </dx-tree-list>
            </template>
          </td>
        </tr>
        <tr>
          <th scope="row">
            <label for="label03"
              >{{ $_lang('COMMON.WORD.MAIN_PAGE_TYPE', { defaultValue: '메인 페이지 타입' }) }}<span class="icon_require"></span
            ></label>
          </th>
          <td>
            <dx-select-box
              id="label03"
              :styling-mode="stylingMode"
              style="margin-right: 9px"
              :height="30"
              width="100%"
              :items="enums.common.mainPageType.values"
              display-expr="label"
              value-expr="value"
              v-model="data.pageType"
              @value-changed="handleChangedPageType"
              :disabled="isEdit"
            >
              <dx-validator :validation-group="validateGroupName">
                <dx-required-rule
                  :message="getRequireMsg($_lang('COMMON.WORD.MAIN_PAGE_TYPE', { defaultValue: '메인 페이지 타입' }))"
                />
              </dx-validator>
            </dx-select-box>
          </td>
        </tr>
        <tr>
          <th scope="row">
            <label for="label04"
              >{{ $_lang('COMMON.WORD.MAIN_PAGE_ID', { defaultValue: '메인 페이지 ID' }) }}<span class="icon_require"></span
            ></label>
          </th>
          <td>
            <template v-if="!data.pageType">
              <span>{{ $_lang('COMMON.WORD.MAIN_PAGE_TYPE', { defaultValue: '메인 페이지 타입' }) }}</span>
            </template>
            <template v-else>
              <dx-drop-down-box
                id="label04"
                v-model="data.pageId"
                ref="pageTypeSelBoxRef"
                :data-source="getPageTypeItems()"
                :display-expr="getPageTypeDisplayExpr()"
                value-expr="id"
                :placeholder="$_lang('COMMON.WORD.MAIN_PAGE_ID', { defaultValue: '메인 페이지 ID' })"
                :styling-mode="stylingMode"
                width="100%"
                :height="30"
                class="mar_ri10"
                :input-attr="{ 'aria-label': 'Owner' }"
                :defer-rendering="false"
                :show-clear-button="false"
                :opened="config.isTreeBoxOpened"
                @opened="handleOpened"
              >
                <template #content="{}">
                  <dx-tree-view
                    :data-source="getPageTypeItems()"
                    data-structure="plain"
                    key-expr="id"
                    :display-expr="getPageTypeDisplayExpr()"
                    parent-id-expr="parentId"
                    :expand-all-enabled="true"
                    :select-by-click="true"
                    selection-mode="single"
                    @item-click="handleChangedPageId"
                  />
                </template>
                <dx-validator :validation-group="validateGroupName">
                  <dx-required-rule
                    :trim="true"
                    :message="getRequireMsg($_lang('COMMON.WORD.MAIN_PAGE_ID', { defaultValue: '메인 페이지 ID' }))"
                  />
                </dx-validator>
              </dx-drop-down-box>
            </template>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="page-sub-box mt-3">
      <div class="bottom-btn-wrap text-center">
        <dx-button
          :text="$_lang('COMPONENTS.SAVE', { defaultValue: '저장' })"
          :width="120"
          :height="40"
          class="default filled txt_S medium"
          type="default"
          @click="saveMainPage"
        />
        <dx-button
          :text="$_lang('COMPONENTS.CANCEL', { defaultValue: '취소' })"
          :width="120"
          :height="40"
          class="white filled txt_S medium"
          type="normal"
          @click="handleCancel"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { DxColumn, DxSelection, DxSorting, DxTreeList } from 'devextreme-vue/tree-list';
  import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
  import { isEmpty, isSuccess } from '@/plugins/common-lib';
  import validationEngine from 'devextreme/ui/validation_engine';
  import DxTreeView from 'devextreme-vue/tree-view';
  import DxDropDownBox from 'devextreme-vue/drop-down-box';
  import enums from '@/configs/enums';
  import { DxButton } from 'devextreme-vue/button';

  export default {
    components: {
      DxButton,
      DxDropDownBox,
      DxTreeView,
      DxTreeList,
      DxSelection,
      DxColumn,
      DxSorting,
      DxValidator,
      DxRequiredRule,
      DxSelectBox,
    },
    props: {
      selectedData: {
        default: () => {},
        type: Object,
        required: false,
      },
      disableInfo: {
        type: Object,
        required: true,
      },
    },
    watch: {
      selectedData: {
        handler() {
          this.setDataFromProps();
        },
        deep: true, // 객체 내부까지 감지하려면 deep 옵션을 사용
      },
    },
    data() {
      return {
        config: {
          isTreeBoxOpened: false,
        },
        stylingMode: 'outlined', //outlined, underlined, filled
        menuList: this.$store.getters.getMenuList.sort((a, b) => a.menuOrd - b.menuOrd),
        isEdit: true,
        validateGroupName: 'editFormValidateGroup',
        data: {},
        dashBoardList: [],
        tree: {
          // 권한 트리
          auth: {
            selectedRowKeys: [],
            authList: [],
            authListColumns: [
              { dataField: 'id', caption: 'id', visible: false },
              { dataField: 'parentId', caption: 'parentId', visible: false },
              { dataField: 'authNm', caption: '권한', visible: true },
            ],
          },
          user: {
            // 유저 트리
            selectedRowKeys: [],
            userList: [],
            userListColumns: [
              { dataField: 'loginId', caption: 'ID', visible: false },
              { dataField: 'loginNm', caption: '아이디', visible: true },
            ],
          },
        },
      };
    },
    computed: {
      enums() {
        return enums;
      },
    },
    methods: {
      /**
       * @description 권한 필드 여부
       * @param fieldType
       * @return {boolean}
       */
      isAuthField(fieldType) {
        return fieldType === this.$_enums.common.mainTargetField.AUTH_ID.value;
      },
      /**
       * @description 로그인 아이디 필드 여부
       * @param fieldType
       * @return {boolean}
       */
      isLoginIdField(fieldType) {
        return fieldType === this.$_enums.common.mainTargetField.LOGIN_ID.value;
      },
      /**
       * @description REQUIRED_VALUE_IS 다국어 메시지 반환
       * @param key
       * @return {*}
       */
      getRequireMsg(key) {
        return this.$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS', { value: key });
      },
      /**
       * @description 페이지 타입에 따른 아이템 반환
       * @return {*[]|*}
       */
      getPageTypeItems() {
        return this.data.pageType === this.$_enums.common.mainPageType.DASHBOARD.value ? this.dashBoardList : this.menuList;
      },
      /**
       * @description 페이지 타입 표시
       * @return {string}
       */
      getPageTypeDisplayExpr() {
        return this.data.pageType === this.$_enums.common.mainPageType.DASHBOARD.value ? 'dashboardTemplateNm' : 'menuNm';
      },
      /**
       * @description validateGroupName 그룹의 유효성 검사
       * @return {boolean}
       */
      validationCheck() {
        const validationResult = validationEngine.validateGroup(this.validateGroupName);
        if (!validationResult.isValid || this.$refs[`${this.getTreeFieldName()}Tree`].instance.getSelectedRowKeys().length === 0) {
          if (validationResult.isValid) {
            this.$_Msg(this.getRequireMsg(this.$_lang('COMPONENTS.TARGET', { defaultValue: '대상' })));
          } else {
            const firstError = validationResult.brokenRules[0];
            const result = this.$_Msg(firstError.message);
            result.then(() => {
              setTimeout(() => {
                firstError.validator.focus();
              }, 500);
            });
          }
          return false;
        }
        return true;
      },
      /**
       * @description 트리 헤더 재설정 및 disable 처리
       * @param cellInfo
       * @param name
       */
      handleCellPrepared(cellInfo, name = this.$_enums.common.mainTargetField.AUTH_ID.value) {
        let element = cellInfo.cellElement;
        // 헤더 설정
        if (cellInfo.rowType === 'header') {
          element.style.textAlign = 'center';
          if (cellInfo.columnIndex === 0) {
            element.setAttribute('colspan', 2);
          } else {
            element.style.display = 'none';
          }
          return;
        }

        const cellId = String(this.isAuthField(name) ? cellInfo.data?.id : cellInfo.data?.loginId);
        // cellInfo.data가 null이거나 수정 중인 경우 종료
        if (!cellId || this.isEdit) {
          return;
        }

        if (this.disableInfo[name]?.includes(cellId)) {
          element.style.color = 'gray';
          element.style.pointerEvents = 'none';
          element.style.opacity = '0.5';
          element.setAttribute('disabled', 'true');
        }
      },
      /**
       * @description 트리 필드명 반환
       * @return {string}
       */
      getTreeFieldName() {
        return this.isAuthField(this.data.targetField) ? 'auth' : 'user';
      },
      /**
       * @description props 데이터 설정
       */
      setDataFromProps() {
        if (this.selectedData === null) {
          this.data = {};
          return;
        }

        this.data = { ...this.selectedData };
        this.tree[this.getTreeFieldName()].selectedRowKeys = this.data?.targetIds?.split(',') ?? [];
      },
      /**
       * @description 현재 선택된 데이터 반환
       * @return {} 현재 선택된 데이터 | null
       */
      getSelectionData() {
        if (!this.validationCheck()) {
          return null;
        }

        this.data.targetIds = this.$refs[`${this.getTreeFieldName()}Tree`].instance.getSelectedRowKeys()?.join(',') ?? '';
        return {
          id: this.data.id,
          targetField: this.data.targetField,
          targetIds: this.data.targetIds,
          pageType: this.data.pageType,
          pageId: this.data.pageId,
          menuTypeCd: this.data.menuTypeCd,
        };
      },
      /** @description : 권한조회 */
      async getAuth() {
        const payload = {
          actionName: 'AUTH_LIST',
          useErrorPopup: true,
        };

        const res = await this.CALL_API(payload);
        if (isSuccess(res)) {
          this.tree.auth.authList = res.data.data;
        }
      },
      /** @description : 사용자 리스트 조회 */
      async getMember() {
        const payload = {
          actionName: 'MEMBER_LIST_ALL',
          useErrorPopup: true,
        };
        const res = await this.CALL_API(payload);
        if (isSuccess(res)) {
          this.tree.user.userList = res.data.data;
        }
      },
      /**
       * @description 대시보드 리스트 조회
       * @return {Promise<void>}
       */
      async getDashBoardTemplate() {
        let payload = {
          actionName: 'MENU_MAIN_PAGE_DASHBOARD_TEMPLATE_LIST',
          loading: true,
        };

        const res = await this.CALL_API(payload);
        if (isSuccess(res)) {
          this.dashBoardList = res.data.data;
        } else {
          this.dashBoardList = [];
        }
      },
      /** @description : 메인 유형 변경 이벤트 */
      handleChangedPageType() {
        if (!this.isEdit) {
          this.data.pageId = null;
        }
      },
      /** @description : 메인 선택 DropDownBox 오픈 이벤트 */
      handleOpened() {
        this.$refs.pageTypeSelBoxRef.instance.option('value', this.data.pageId);
        this.config.isTreeBoxOpened = true;
      },
      /** @description : 메인 페이지 선택 변경 이벤트 */
      handleChangedPageId(e) {
        if (!e.itemData) {
          return;
        }

        if (this.data.pageType === this.$_enums.common.mainPageType.DASHBOARD.value) {
          this.data.pageId = e.itemData.id;
          this.data.menuTypeCd = '';
          this.config.isTreeBoxOpened = false;
        } else {
          this.data.pageId = e.itemData.id;
          this.data.menuTypeCd = e.itemData.menuTypeCd;
          this.config.isTreeBoxOpened = false;
        }
      },
      /**
       * @description 메인설정 저장 이벤트
       */
      async saveMainPage() {
        const saveData = this.getSelectionData();

        // 메뉴 타입 체크 (일반페이지, 보고서)
        if (saveData?.menuTypeCd && !this.validateMenuType(saveData?.menuTypeCd)) {
          this.$_Msg(
            this.$_lang('COMMON.MESSAGE.MAIN_SETTING_MENU_TYPE_ERROR', {
              defaultValue: '메뉴타입이 올바르지 않습니다. (일반페이지, 보고서만 가능합니다.)',
            }),
          );
          return;
        }

        if (this.$_commonlib.isEmpty(saveData)) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }

        const payload = {
          actionName: 'MENU_MAIN_PAGE_SAVE',
          data: [saveData],
          useErrorPopup: true,
        };

        const res = await this.CALL_API(payload);
        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
          this.$emit('saveEditForm');
        }
      },
      /**
       * 메뉴타입 유효성 체크
       *
       * @param menuTypeCd
       * @return {boolean} 유효성 여부
       */
      validateMenuType(menuTypeCd) {
        return menuTypeCd === this.$_enums.common.menuType.NORMAL_PAGE.value || menuTypeCd === this.$_enums.common.menuType.REPORT.value;
      },
      /**
       * @description 메인설정 취소 이벤트
       */
      handleCancel() {
        this.$emit('closeEditForm');
      },
      /**
       * @description 데이터 초기화
       * @return {Promise<void>}
       */
      async initData() {
        await this.getDashBoardTemplate();
        await this.getAuth();
        await this.getMember();
        this.isEdit = !isEmpty(this.selectedData);
        this.setDataFromProps();
      },
    },
    created() {},
    mounted() {
      this.initData();
    },
  };
</script>

<style scoped></style>
