<template>
  <div>
    <table class="table_form line-bin fl" style="width: 100%">
      <thead style="border-top: 1px solid; border-bottom: 1px solid; border-color: rgba(229, 231, 235)">
        <tr>
          <th>{{ $_lang('UC.WORD.EXCHANGER') }}</th>
          <td>{{ getCmNm(this.deviceInfo.cmname) }}</td>
          <th>{{ $_lang('UC.WORD.DEVICE_NAME') }}</th>
          <td>{{ this.deviceInfo.name }}</td>
          <th>{{ $_lang('UC.WORD.NUMBER') }}</th>
          <td>{{ this.rowInfo.number }}</td>
          <th>{{ $_lang('UC.WORD.PARTITION') }}</th>
          <td>{{ this.rowInfo.routepartitionname }}</td>
        </tr>
      </thead>
    </table>
    <div class="locker_setting_list sub_new_style01 sub_ui_box1">
      <div v-if="authority != 'user'" style="padding-top: 50px">
        <Search
          :key="componentkey"
          codeKey="search_type_pickup"
          :searchObj="searchType.obj"
          :useKeywordType="true"
          @change-search-type="$_changeSearchType"
          @onSearchClick="$_searchData($event, 'search_type_pickup')"
        >
        </Search>
      </div>
    </div>
    <table class="table_form line-bin">
      <tbody>
        <div class="pickupGrid">
          <esp-dx-data-grid
            :data-grid="dataGrid"
            ref="pickupGrid"
            :auto-width="true"
            :selected-row-keys="dataGrid.selectedRowKeys"
            @selection-changed="onSelectionChanged"
            @row-prepared="onRowPrepared"
          />
        </div>
      </tbody>
    </table>
  </div>
</template>
<script>
  import { isSuccess } from '@/plugins/common-lib';
  import Search from '@/components/common/search.vue';
  import CustomStore from "devextreme/data/custom_store";
  import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid.vue";
  export default {
    components: {
      EspDxDataGrid,
      Search,
    },
    props: {
      option: {
        default: () => {
          return {
            width: '50%',
            height: '85%',
            minWidth: null,
            minHeight: null,
            saveBtnVisible: false,
            cancelBtnVisible: true,
          };
        },
        type: Object,
      },

      deviceInfo: {
        type: Object,
      },
      rowInfo: {
        type: Object,
      },
      isOpened: {
        type: Boolean,
      },
      cmList: {
        type: Array,
      },
      partitionList: {
        type: Array,
      },
      gridNumber: {
        type: String,
      },
      authority: {
        type: String,
      },
      pickupKeyword: {
        type: String,
      },
    },
    watch: {
      rowInfo() {},
      pickupKeyword() {
        // this.searchType.obj.searchTexts.value = this.pickupKeyword;
        // this.searchType.paramsData.description = '%' + this.pickupKeyword + '%';
        // this.componentkey += 1;
        // this.searchType.obj.searchTexts.value = this.pickupKeyword;
        // } else {
        // 	console.log('검색어 초기화');
        // 	this.searchType.obj.searchTexts.value = '';
        // }
      },
      async isOpened() {
        if (this.isOpened) {
          // this.searchType.obj.searchTexts.value = this.pickupKeyword;
          // this.searchType.paramsData.description = '%' + this.pickupKeyword + '%';
          // this.componentkey += 1;
          await this.selectDataList();
        } else {
          this.resetGridDataSource();
        }
      },
    },
    data() {
      return {
        flag: {
          select: false,
          rowUpate: false,
        },
        // searchTexts.value
        componentkey: 0,
        searchKeyword: '',
        stylingMode: 'outlined', //outlined, underlined, filled
        searchType: {
          obj: {
            searchTexts: {
              value: '',
            },
          },
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            cm: null,
            model: null,
            status: null,
          },
          paramsData: null,
        },
        pageList: [],
        totalCount: 0,
        dataGrid: {
          callApi: 'CALL_EUC_API',
          refName: 'pickupGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: true, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          disableTotalCount: false,
          selectedRowKeys: [], //선택된 로우키들
          selectedRowsData: [],
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          height: '500', // 주석처리시 100%
          apiActionNm: {
            loading: true,
          },
          customEvent: {
            rowPrepared: true,
            // contentReady: true,
            // toolbarPreparing: true,
            selectionChanged: true,
          },
          showActionButtons: {
            customButtons: [],
          },
          isDuplicateConfigKey: false, //설정키 중복 체크
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: true, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '교환기',
              i18n: 'UC.WORD.EXCHANGER',
              dataField: 'cmNm',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: this.authority !== 'user' ? true : false,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              calculateCellValue: row => this.getCmNm(row?.cmNm),
            },
            {
              caption: '그룹명',
              i18n: 'COMPONENTS.GROUP_NAME',
              dataField: 'name',
              width: 200,
              height: 40,
              alignment: 'center',
              visible: this.authority !== 'user' ? true : false,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowExporing: true,
              fixed: false,
              requiredRule: {
                message: '필수 항목입니다.',
              },
            },
            {
              caption: '그룹번호',
              i18n: 'UC.WORD.GROUP_NUMBER',
              dataField: 'pattern',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: this.authority !== 'user' ? true : false,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '설명',
              i18n: 'COMPONENTS.DESCRIPTION',
              dataField: 'description',
              width: 200,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '파티션',
              i18n: 'UC.WORD.PARTITION',
              dataField: 'routepartitionname',
              width: 150,
              height: 40,
              alignment: 'center', // left center right
              visible: this.authority !== 'user' ? true : false,
              allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
            },
            {
              caption: '등록 내선',
              i18n: 'UC.WORD.REG_NUMBER',
              dataField: 'directorylist',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '수정일',
              i18n: 'COMPONENTS.MODIFY_DATE',
              dataField: 'editDt',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: this.authority !== 'user' ? true : false,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              calculateCellValue: row => this.formatDt(row?.regDt),
            },
          ],
        },
      };
    },
    computed: {},
    methods: {
      /**@description : 라이프사이클 created시 호출되는 메서드 */
      async createdData() {
        // this.searchType.obj.searchTexts.value = this.pickupKeyword;
        this.dataGrid.showActionButtons.customButtons = this.getCustomButtons();
      },
      /**@description : 라이프사이클 mounted시 호출되는 메서드 */
      async mountedData() {},
      async selectDataList(sort = '+callpickupGroupOrd , +id') {
        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);
            if (!params.sort) {
              params.sort = sort;
            }
            params = { ...params, ...this.searchType.paramsData, loginId: this.$store.getters.getLoginId };
            if (this.authority == 'user') {
              // 사용자 권한일때 부서명으로 강제검색
              params = { ...params, description: '%' + this.pickupKeyword + '%' };
            }
            // if (this.pickupKeyword != '') {
            // 	params = { ...params, description: '%' + this.pickupKeyword + '%' };
            // }
            const payload = {
              actionName: 'EUC_PICKUP_LIST_ALL',
              data: params,
              loading: false,
            };

            const rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await this.CALL_EUC_API(payload);
            if (isSuccess(res)) {
              rtnData.data = res.data.data;
              rtnData.totalCount = res.data.header.totalCount;
              // await this.selectPickup(rtnData.data);
            }
            return rtnData;
          },
        });
      },
      getCustomButtons() {
        const buttons = [
          {
            widget: 'dxButton',
            options: {
              icon: '',
              // text: '적용',
              text: this.$_lang('UC.WORD.APPLY'),
              elementAttr: {
                class:
                  'dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled',
              },
              width: 60,
              height: 30,
              onClick: () => {
                this.onClickPickup();
              },
            },
            location: 'before',
          },
        ];

        return buttons;
      },
      onRowUpdating() {
        this.flag.rowUpate = true;
      },
      /**
       * @description 당겨받기설정 그리드 데이터 리턴
       */
      getGridDataSource() {
        return this.$refs['pickupGrid'].getInstance.getDataSource()._items;
      },
      /** @description : 날짜 형식 변환 메서드 */
      formatDt(date) {
        if (date) {
          return this.$_commonlib.formatDate(date, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
        }
      },
      /** @description : 교환기 이름 변환 메서드 */
      getCmNm(data) {
        if (data) {
          let cmNm = this.cmList.filter(d => d.cmNm == data);
          return cmNm[0].description;
        }
      },
      /** @description : 라이프사이클 computed에서 this data를 변경하기 위한 메서드 */
      setCustomTypes(key, value) {
        this.searchType.customTypes[key] = value;
      },
      /**@description 그리드 데이터 초기화 */
      resetGridDataSource() {
        this.dataGrid.dataSource = [];
        this.$refs.pickupGrid.clearSelection();
      },
      /**@description 그리드 row 선택 변경 이벤트 */
      onSelectionChanged(e) {
        let gridData = [];
        if (e.currentSelectedRowKeys.length > 0 && e.currentSelectedRowKeys.length == 1) {
          let currentSelectedRowKey = e.currentSelectedRowKeys;
          e.component.selectRows(currentSelectedRowKey);
          if (e.selectedRowsData.length > 1) {
            // let data = e.selectedRowsData.filter(item => !e.selectedRowKeys.includes(item['id']));
            e.selectedRowsData.forEach(item => {
              if (item.id == e.currentSelectedRowKeys[0]) {
                gridData.push(item);
              }
            });
            this.dataGrid.selectedRowsData = gridData;
          } else if (e.selectedRowsData.length == 1) {
            this.dataGrid.selectedRowsData = e.selectedRowsData;
          }
        }
      },
      /**@description : 당겨받기 적용버튼 클릭 메서드 */
      async onClickPickup() {
        const selectedRowCnt = this.dataGrid.selectedRowsData;
        if (selectedRowCnt.length > 1) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_ONE_SELECT'));
        } else if (selectedRowCnt.length == 0) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
        } else if (selectedRowCnt.length == 1) {
          const confirm = this.$_lang('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' });
          if (!(await this.$_Confirm(confirm))) {
            return;
          }
          const pickupName = this.dataGrid.selectedRowsData[0].name;
          this.$emit('closePickup', this.rowInfo, pickupName, this.gridNumber);
        } else {
          await this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_ERROR_INTERNAL_SERVER', { defaultValue: '서버 오류 입니다.' }));
        }
      },
      /** @description: 그리드 행 관련 준비 이벤트 */
      onRowPrepared(e) {
        let keys = [];
        const gridData = e.component.getDataSource()._items;
        for (let i = 0; i < gridData.length; i++) {
          if (this.rowInfo.pickupgroupname === gridData[i].name) {
            keys.push(gridData[i].id);
          }
        }
        e.component.selectRows(keys);
      },
    },
    async created() {
      this.createdData();
    },
    async mounted() {
      await this.mountedData();
    },
  };
</script>
