<template>
  <transition>
    <div ref="contentsWrap" class="container contents-wrap" id="contentsWrap">
      <!-- <div class="contents-title-box">
                <div class="contents-title">부서 정보</div>
            </div> -->
      <div class="contents-box page-sub-box clearfix ui-glid-box">
        <table class="top_table table_form line-bin">
          <caption>
            <strong>일괄 계정 생성</strong>
          </caption>
          <colgroup>
            <col style="width: 130px" />
            <col style="width: auto" />
          </colgroup>

          <thead class="sub_title_txt">
            <tr>
              <td colspan="2"><h2>선택 대상</h2></td>
            </tr>
          </thead>

          <tbody>
            <tr>
              <th scope="row">
                {{ getSelectedUserList }}
              </th>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="contents-box page-sub-box clearfix ui-glid-box">
        <div class="fl per60">
          <table class="table_form line-bin">
            <caption>
              <strong>일괄 계정 생성</strong>
            </caption>
            <colgroup>
              <col style="width: 100px" />
              <col style="width: auto" />
            </colgroup>

            <thead class="sub_title_txt">
              <tr>
                <td colspan="2">
                  <h2>
                    계정 정보
                    <button
                      class="btn-question"
                      id="memberInfo"
                      @mouseenter="setTooltips('memberInfo')"
                      @mouseleave="setTooltips('memberInfo')"
                    ></button>
                    <DxPopover target="#memberInfo" :visible="config.isTooltip.memberInfo" :hide-on-outside-click="false">
                      <span>
                        사용자는 임시 패스워드로 직접 로그인한 후 패스워드 설정을 해야합니다.<br />
                        일괄 계정 생성은 ‘일반 사용자’계정 등록만 가능합니다
                      </span>
                    </DxPopover>
                  </h2>
                </td>
              </tr>
            </thead>

            <tbody>
              <tr>
                <th scope="row">
                  <label for="label01">
                    임시 패스워드
                    <!-- <span class="icon_require">필수항목</span> -->
                  </label>
                </th>
                <td>설정된 초기 패스워드</td>
              </tr>
              <tr>
                <th scope="row">
                  <label for="label01">
                    계정 권한
                    <span class="icon_require">필수항목</span>
                  </label>
                </th>
                <td>
                  <DxSelectBox
                    v-model="formData.authId"
                    placeholder="계정 권한 선택"
                    :items="config.authMenuList"
                    display-expr="authNm"
                    value-expr="id"
                    @value-changed="onChangedAuthMenu"
                    :styling-mode="config.stylingMode"
                    :width="150"
                    :height="30"
                    class="mar_ri10"
                  >
                    <!-- @value-changed="onChangedAuthMenu" -->
                    <!-- @selection-changed="onChangedAuthMenu" -->
                    <DxValidator>
                      <DxRequiredRule message="계정 권한은 필수값입니다." />
                    </DxValidator>
                  </DxSelectBox>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="fr per40">
          <table class="table_form line-bin">
            <caption>
              <strong>일괄 계정 생성</strong>
            </caption>
            <colgroup>
              <col style="width: 100px" />
              <col style="width: auto" />
            </colgroup>

            <thead class="sub_title_txt">
              <tr>
                <td colspan="2">
                  <h2>
                    메뉴 접근 미리보기
                    <button
                      class="btn-question"
                      id="menuInfo"
                      @mouseenter="setTooltips('menuInfo')"
                      @mouseleave="setTooltips('menuInfo')"
                    ></button>
                    <DxPopover target="#menuInfo" :visible="config.isTooltip.menuInfo" :hide-on-outside-click="false">
                      <span>
                        선택한 계정권한의 메뉴접근 정보를 볼수 있으며,<br />
                        개인화를 위한 변경은 해당 팝업에서는 불가합니다.
                      </span>
                    </DxPopover>
                  </h2>
                </td>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td class="clearfix">
                  <DxTreeList
                    ref="treeList"
                    :data-source="menuDatas.defaultDatas"
                    :show-borders="true"
                    :show-column-headers="true"
                    :show-column-lines="false"
                    :show-row-lines="true"
                    :column-auto-width="true"
                    :auto-expand-all="true"
                    :height="330"
                    :no-data-text="noDataText()"
                    :selected-row-keys="menuDatas.selectedIds"
                    @cell-prepared="onCellPrepared"
                  >
                    <!-- :selected-row-keys="pageSettingTopRight.treeList.selectedRowKeys" -->
                    <!-- @selection-changed="pageSettingTopRight.treeList.selectionChanged" -->
                    <!-- @selection-changed="onChangedMenu" -->
                    <!-- key-expr="id"
                                        parent-id-expr="parentId"
                                        :expanded-row-keys="menuDatas.expandedRowKeys" -->
                    <!-- :disabled="true" -->
                    <!-- @cell-prepared="onCellPrepared" -->
                    <!-- <DxScrolling mode="standard"/> -->
                    <DxColumn caption="메뉴명" data-field="menuNm" />

                    <DxSelection :recursive="menuDatas.recursive" :mode="menuDatas.mode" />
                  </DxTreeList>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="container-bottom-box">
        선택된 <span class="length-txt">{{ contentData.selectedRowsData.length }}</span
        >개의 인사정보를 등록합니다.
      </div>
    </div>
  </transition>
</template>

<script>
  import { DxTooltip } from 'devextreme-vue/tooltip';
  import { DxPopover } from 'devextreme-vue/popover';
  import {
    DxColumn,
    DxDataGrid,
    DxFilterPanel,
    DxFilterRow,
    DxHeaderFilter,
    DxOperationDescriptions,
    DxScrolling,
    DxSelection,
  } from 'devextreme-vue/data-grid';
  import { DxTreeList } from 'devextreme-vue/tree-list';
  import CheckBox from 'devextreme/ui/check_box';
  import { DxTextBox } from 'devextreme-vue/text-box';
  //import { DxButton } from 'devextreme-vue/button';
  import { DxRequiredRule, DxValidator } from 'devextreme-vue/validator';
  import DxSwitch from 'devextreme-vue/switch';
  import { DxSelectBox } from 'devextreme-vue/select-box';

  import { getAllLastChildrenDatas, isSuccess, makeTreeData } from '@/plugins/common-lib';

  export default {
    components: {
      DxTooltip,
      DxPopover,
      DxDataGrid,
      DxTreeList,
      DxSelection,
      DxScrolling,
      DxColumn,
      DxFilterRow,
      DxOperationDescriptions,
      DxFilterPanel,
      DxHeaderFilter,
      CheckBox,
      DxTextBox,
      //DxButton,

      DxValidator,
      DxRequiredRule,
      DxSwitch,
      DxSelectBox,
    },
    props: {
      contentData: Object,
      iconData: Array,
    },
    watch: {},
    data() {
      return {
        config: {
          stylingMode: 'outlined',
          authMenuList: [], //메뉴권한 selectbox data list
          isTooltip: {
            //tooltip 관련
            memberInfo: false,
            menuInfo: false,
          },
        },
        formData: {
          //loginId : "",
          //loginNm : "",
          loginPwd: null,
          authId: null,
          changedPwdFl: true,
          regId: this.$store.getters.getLoginId,
          tempPwdFl: 1,
          //viewCd: 1,
        },
        //메뉴 접근 관련 데이터
        menuDatas: {
          recursive: true,
          mode: 'multiple',
          defaultDatas: null, //메뉴접근 TreeList default data
          lastChildrenDatas: [], //마지막 뎁스의 children 데이터 리스트
          selectedDatas: [], //메뉴접근 TreeList selectedData(선택 이벤트를 사용시 선택되는 데이터)
          selectedIds: [], //메뉴접근 TreeList selected menu id(DB에 저장된 데이터를 출력하기 위해)
        },
      };
    },
    computed: {
      /** @description: props 리스트에서 선택한 데이터 */
      selectedRowsData() {
        return this.contentData.selectedRowsData;
      },
      /** @description: 선택 대상 사번 리스트 출력 */
      getSelectedUserList() {
        let userNoList = '';
        this.selectedRowsData.forEach((d, idx) => {
          userNoList += `${d.userNo} ( ${d.userNm} )`;
          if (idx < this.selectedRowsData.length - 1) {
            userNoList += ', ';
          }
        });

        return userNoList;
      },
      /** @description: props 리스트에서 선택한 데이터 */
      getApiActionNm() {
        return this.contentData.apiActionNm;
      },
    },
    methods: {
      /** @description: 계정정보 or 메뉴접근미리보기 툴팁 이벤트 */
      setTooltips(key) {
        this.config.isTooltip[key] = !this.config.isTooltip[key];
      },
      /** @description: 메뉴접근 미리보기 cell prepared시 disabled 처리 */
      onCellPrepared(e) {
        if (e.columnIndex === 0) {
          let element = e.cellElement.querySelector('.dx-select-checkbox');
          let checkBox = CheckBox.getInstance(element);
          checkBox && checkBox.option('disabled', true);
        }
      },
      /** @description : 메뉴권한 selectbox 변경 이벤트
       * 선택된 데이터 셋팅(실제 DB에 저장된 데이터, 메뉴접근: menuDatas.selectedIds)
       */
      onChangedAuthMenu(e) {
        if (e.event) {
          //변경 이벤트 발생시에만 셋팅
          let authId = e.value;
          this.callPromiseAllSelectedMenuDatas(authId);
        }
      },
      /** @description : 초기 데이터 promise all로 가져오기 */
      async callPromiseAllInitData() {
        let payload;
        let apiList = [];

        //menu default 데이터 조회 payload
        payload = this.callApiPayloadMenu();
        apiList.push(await this.CALL_API(payload));

        let returnDatas = await Promise.all(apiList);

        this.selectDefaultMenuDatas(returnDatas[0]);
      },
      /** @description: menu 관련 데이터 조회 페이로드 */
      callApiPayloadMenu() {
        return {
          actionName: 'MENU_LIST_ALL',
          data: { viewFl: 'Y', isCompact: true },
          loading: false,
        };
      },
      /** @description : 메뉴접근 default Data 가져오기 */
      async selectDefaultMenuDatas(res) {
        if (isSuccess(res)) {
          this.menuDatas.defaultDatas = res.data.data;

          //children Object가 있는 Tree 구조로 변경
          let makeTreeDatas = makeTreeData(this.menuDatas.defaultDatas);

          //마지막 뎁스의 children 데이터를 가져옴
          this.menuDatas.lastChildrenDatas = getAllLastChildrenDatas(makeTreeDatas, []);
        }
      },
      /** @description: 선택된 메뉴와 데이터 promies all로 api 호출 */
      async callPromiseAllSelectedMenuDatas(authId) {
        let payload;
        const apiList = [];

        //메뉴 데이터 api 호출
        payload = this.callApiPayloadSelectedMenu(authId);
        apiList.push(await this.CALL_API(payload));

        const returnDatas = await Promise.all(apiList);

        await this.selectSelectedMenuDatas(returnDatas[0]);
      },
      /** @description: 선택된 메뉴 데이터 조회 페이로드 */
      callApiPayloadSelectedMenu(authId) {
        return {
          actionName: 'MENU_LIST_ALL',
          data: { sort: '+id', authId: authId, isCompact: true },
          loading: false,
        };
      },
      /** @description : 선택된 메뉴 데이터 가져오기(DB에 저장된 데이터, menuDatas.selectedIds로 관리) */
      async selectSelectedMenuDatas(res) {
        if (isSuccess(res)) {
          this.menuDatas.selectedDatas = []; //초기화
          this.menuDatas.selectedIds = []; //초기화
          res.data.data.forEach(x => {
            this.menuDatas.lastChildrenDatas.forEach(d => {
              if (x.menuId === d.id) {
                //부모를 제외한 마지막 레벨의 children 값만 담음
                this.menuDatas.selectedDatas.push(d);
                this.menuDatas.selectedIds.push(x.menuId);
              }
            });
          });
        }
      },
      /** @description: 데이터가 없을 경우 출력 */
      noDataText() {
        return `계정 권한을 선택하세요.`;
      },
      /** @description : 라이프사이클 creaed시 호출되는 메서드 */
      createdData() {
        this.formData.loginPwd = this.$_getSystemData('init_pwd')?.configValue;

        //초기 데이터 호출
        this.callPromiseAllInitData();
      },
      /** @description : 라이프사이클 mounted시 호출되는 메서드 */
      mountedData() {
        this.$_eventbus.$on('ModalAddMember:onSaveData', async (e, resolve) => {
          if (!e.validationGroup.validate().isValid) return;

          let formDatas = this.contentData.selectedRowsData.map(d => {
            let loginId = d.userNo;
            let loginNm = d.userNm;
            let userId = d.id;
            let deptId = d.deptId;
            let loginPwd = this.formData.loginPwd;
            let authId = this.formData.authId;
            let regId = this.formData.regId;
            let tempPwdFl = this.formData.tempPwdFl;
            //let viewCd = this.formData.viewCd;
            return { loginId, loginNm, userId, deptId, loginPwd, authId, regId, tempPwdFl };
          });
          //console.log('formDatas >>> ', formDatas);

          if (await this.$_Confirm('계정을 일괄 생성 하시겠습니까?')) {
            let data = {
              data: {
                member: formDatas,
              },
            };
            //let actionName = this.getApiActionNm.insertMember;
            let payload = {
              actionName: 'MEMBER_LIST_INSERT_V1',
              data: data,
              loading: true,
            };

            let res = await this.CALL_API(payload);
            if (isSuccess(res)) {
              this.$_Toast(this.$_lang('CMN_SUCCESS'));
              resolve({ status: 200 });
            }
          }
        });
      },
      /** @description : 라이프사이클 destroyed시 호출되는 메서드 */
      destroyedData() {
        this.$_eventbus.$off('ModalAddMember:onSaveData');
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountedData();
    },
    destroyed() {
      this.destroyedData();
    },
  };
</script>

<style scoped>
  .page-sub-box {
    padding: 0 0px;
  }
  .table_form td > div {
    display: inline-block;
    vertical-align: middle;
  }
  .ui-glid-box > div.fl,
  .ui-glid-box > div.fr {
    border-right: 0;
  }
  .ui-glid-box .table_form tbody tr > th {
    min-width: 120px;
  }
  .contents-box .top_table .sub_title_txt tr td {
    padding: 10px 0 10px 15px;
  }
  .contents-box .top_table th {
    padding: 5px 0 15px 15px;
    font-size: 0.9em;
  }
  .contents-box .fl .sub_title_txt tr td {
    padding: 10px 0 10px 15px;
  }
  .contents-box .fr .sub_title_txt tr td {
    padding: 10px 0 10px 20px;
  }
  .container-bottom-box {
    margin-top: 20px;
    font-size: 0.9em;
    text-align: center;
  }
  .container-bottom-box .length-txt {
    color: tomato;
  }
</style>
<style></style>