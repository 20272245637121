<!--
  PACKAGE_NAME : src\pages\esp\system\site\list.vue
  FILE_NAME : list
  AUTHOR : devyoon91
  DATE : 2024-08-29
  DESCRIPTION : 사이트 설정 목록
-->
<template>
  <div>
    <div class="page-sub-box">
      <esp-dx-data-grid
        :data-grid="dataGrid"
        :ref="dataGrid.refName"
        @init-new-row="handleInitNewRow"
        @saving="handleSaving"
        @row-validating="handleRowValidating"
      />
    </div>
  </div>
</template>

<script>
  import { isSuccess } from '@/plugins/common-lib';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid-v2.vue';

  export default {
    components: { EspDxDataGrid },
    props: {
      codeKey: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        originalData: {}, // 원본 데이터를 임시 저장할 객체
        dataGrid: {
          keyExpr: 'id',
          refName: 'siteGrid',
          dataSource: [],
          showActionButtons: {
            copy: false,
            delete: false,
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '삭제',
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 60,
                  height: 30,
                  onClick: () => this.deleteSite(),
                },
                location: 'before',
              },
            ],
          },
          headerFilter: {
            //헤더필터 설정
            visible: false,
          },
          columns: [
            {
              caption: '사이트',
              dataField: 'site',
              requiredRule: {
                message: '필수값 입니다.',
              },
            },
            {
              caption: '사이트명',
              dataField: 'siteNm',
              requiredRule: {
                message: '필수값 입니다.',
              },
            },
            {
              caption: 'CTI 구분',
              dataField: 'ctiType',
              sortOrder: 'none',
            },
            {
              caption: 'CTI 버전',
              dataField: 'ctiVer',
              width: 110,
            },
            {
              caption: 'CTI 연결 정보',
              dataField: 'ctiConnstr',
            },
            {
              caption: 'ERS 스키마',
              dataField: 'ersSchema',
              width: 150,
            },
            {
              caption: '솔루션형식',
              dataField: 'solutionType',
              width: 120,
            },
            {
              caption: 'psdualFl',
              dataField: 'psdualFl',
              width: 110,
              lookup: {
                dataSource: this.$_enums.common.stringUsedFlag.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
            {
              caption: 'SORT',
              dataField: 'siteOrd',
              width: 110,
            },
            {
              caption: 'EMC 사용여부',
              dataField: 'emcFl',
              width: 110,
              lookup: {
                dataSource: this.$_enums.common.stringViewFlag.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
            {
              caption: '사용여부',
              dataField: 'viewFl',
              width: 110,
              lookup: {
                dataSource: this.$_enums.common.stringViewFlag.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
          ],
        },
      };
    },
    methods: {
      /**
       * @description: 사이트 목록 조회
       * @return {Promise<(function(): never)|*|{data, totalCount}>}
       */
      async getSite() {
        let vm = this;

        vm.dataGrid.dataSource = new CustomStore({
          key: 'id',
          async load(loadOptions) {
            let params = vm.$_getDxDataGridParam(loadOptions);

            if (!params.sort) {
              params.sort = '+siteOrd';
            }

            params = { ...params };

            const payload = {
              actionName: 'SITE_LIST_ALL',
              data: { params: params },
            };

            const res = await vm.CALL_API(payload);
            if (isSuccess(res)) {
              let rtnData = {
                data: res.data.data,
                totalCount: res.data.header.totalCount,
              };

              vm.$refs.siteGrid.totalCount = rtnData.totalCount;

              return rtnData;
            } else {
              return () => {
                throw 'Data Loading Error';
              };
            }
          },
          insert: values => {
            return new Promise((resolve, reject) => {
              resolve();
            });
          },
          update: (key, values) => {
            return new Promise((resolve, reject) => {
              resolve();
            });
          },
          remove: key => {
            return new Promise((resolve, reject) => {
              resolve();
            });
          },
          totalCount: loadOptions => {
            return new Promise((resolve, reject) => {
              resolve(0); // 임시로 0 반환
            });
          },
        });
      },
      /**
       * @description: 신규 행 추가시 기본값 설정
       * @param e
       */
      handleInitNewRow(e) {
        e.data.viewFl = 'Y'; // 사용여부
        e.data.emcFl = 'N'; // EMC 사용여부
        e.data.psdualFl = 'N'; // psdualFl
      },
      /**
       * @description: 사이트 삭제
       * @return {Promise<void>}
       */
      async deleteSite() {
        const selectedRows = this.$refs.siteGrid.getInstance.getSelectedRowsData();
        if (selectedRows.length === 0) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '선택된 데이터가 없습니다.' }));
          return;
        }

        if (await this.$_Confirm(this.$_lang('CMN_CFM_DELETE_SELECTED', { defaultValue: '선택한 데이터를 삭제하시겠습니까?' }))) {
          const payload = {
            actionName: 'SITE_LIST_DELETE',
            data: { data: selectedRows.map(row => row.id) },
          };

          const res = await this.CALL_API(payload);
          if (isSuccess(res)) {
            this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUC_DELETE', { defaultValue: '정상적으로 삭제되었습니다.' }));
            this.$refs.siteGrid.refreshData();
          }
        }
      },
      /**
       * @description: 행 유효성 검사
       * @param e
       * @return {Promise<void>}
       */
      async handleRowValidating(e) {
        e.isValid = true;
        const key = e.key;
        if (!this.originalData[key] && e?.oldData) {
          this.originalData[key] = { ...e.oldData };
        }
      },
      /**
       * @description: 저장 이벤트
       * @param e
       * @return {Promise<void>}
       */
      async handleSaving(e) {
        const changes = this.$refs.siteGrid.processGridChangesWithWorkLog(e, this.originalData);
        const res = await this.saveSite(changes);

        if (isSuccess(res)) {
          this.originalData = {}; // 원본 데이터 초기화
          this.$refs.siteGrid.refreshData();
        }
      },
      /**
       * @description: 사이트 저장
       * @param changes
       * @return {Promise<*|e.Response<*, Record<string, *>>|AxiosResponse<T>|AxiosInterceptorManager<AxiosResponse>
       *   |AuthenticatorResponse|AxiosResponse<*>>}
       */
      async saveSite(changes) {
        const payload = {
          actionName: 'SITE_LIST_INSERT',
          data: changes,
        };

        return await this.CALL_API(payload);
      },
    },
    created() {
      this.originalData = {}; // 원본 데이터 초기화
    },
    mounted() {
      this.getSite();
    },
  };
</script>

<style scoped></style>
