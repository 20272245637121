<!--
  PACKAGE_NAME : src\pages\esp\system\menu\favorite-menu-select.vue
  FILE_NAME : favorite-menu-select
  AUTHOR : sskim
  DATE : 2024-09-10
  DESCRIPTION : 즐겨찾기 메뉴 선택 컴포넌트
-->
<template>
  <transition>
    <div ref="contentsWrap" class="container contents-wrap" id="contentsWrap">
      <div class="contents-box page-sub-box clearfix ui-glid-box flex flex-wrap items-center">
        <dx-data-grid
          class="shrink-0"
          ref="menuSelect1"
          :data-source="defaultDatas.menu1"
          :show-borders="true"
          :show-column-headers="true"
          :show-column-lines="false"
          :show-row-lines="true"
          :row-alternation-enabled="false"
          :allow-column-reordering="true"
          :no-data-text="getI18nLoadingMessage()"
          :width="option.width"
          :height="option.height"
          key-expr="id"
          :selected-row-keys="selectedIds.menu1"
          @selection-changed="e => selectDepthMenu(1, e)"
        >
          <dx-column caption="id" data-field="id" :width="80" alignment="center" :visible="false" />
          <dx-column caption="대메뉴" data-field="menuNm" :width="100" alignment="left" :visible="true" />
          <dx-selection mode="single" />
          <dx-filter-row :visible="true" />
          <dx-scrolling mode="virtual" />
        </dx-data-grid>
        <div class="arrow-box"><span class="mdi mdi-chevron-right mdi-24px"></span></div>
        <dx-data-grid
          class="shrink-0"
          :data-source="selectedByPrevDatas.menu2"
          :show-borders="true"
          :show-column-headers="true"
          :show-column-lines="false"
          :show-row-lines="true"
          :row-alternation-enabled="false"
          :allow-column-reordering="true"
          :width="option.width"
          :height="option.height"
          :no-data-text="getI18nNoDataMessage()"
          :selected-row-keys="selectedIds.menu2"
          key-expr="id"
          @selection-changed="e => selectDepthMenu(2, e)"
          :disabled="disabledDepth2"
        >
          <dx-column caption="id" data-field="id" :width="80" alignment="center" :visible="false" />
          <dx-column caption="중메뉴" data-field="menuNm" :width="100" alignment="left" :visible="true" />
          <dx-selection mode="single" show-check-boxes-mode="always" />
          <dx-filter-row :visible="true" />
          <dx-scrolling mode="virtual" />
        </dx-data-grid>
        <div class="arrow-box"><span class="mdi mdi-chevron-right mdi-24px"></span></div>
        <!-- 3depth -->
        <dx-data-grid
          class="shrink-0"
          :data-source="selectedByPrevDatas.menu3"
          :show-borders="true"
          :show-column-headers="true"
          :show-column-lines="false"
          :show-row-lines="true"
          :row-alternation-enabled="false"
          :allow-column-reordering="true"
          :width="option.width"
          :height="option.height"
          :no-data-text="getI18nNoDataMessage()"
          :selected-row-keys="selectedIds.menu3"
          key-expr="id"
          @selection-changed="e => selectDepthMenu(3, e)"
          :disabled="disabledDepth3"
        >
          <dx-column caption="id" data-field="id" :width="80" alignment="center" :visible="false" />
          <dx-column caption="소메뉴" data-field="menuNm" :width="100" alignment="left" :visible="true" />

          <dx-selection mode="single" show-check-boxes-mode="always" />
          <dx-filter-row :visible="true" />
          <dx-scrolling mode="virtual" />
        </dx-data-grid>
      </div>
    </div>
  </transition>
</template>

<script>
  import { DxDataGrid, DxSelection, DxScrolling, DxColumn, DxFilterRow } from 'devextreme-vue/data-grid';

  export default {
    components: {
      DxDataGrid,
      DxSelection,
      DxScrolling,
      DxColumn,
      DxFilterRow,
    },
    data() {
      return {
        option: {
          width: 170,
          height: 265,
        },
        //메뉴 초기 default data
        defaultDatas: {
          menu1: [],
          menu2: [],
          menu3: [],
        },
        selectedByPrevDatas: {
          menu1: [], //선택된 depth1에 해당하는 데이터
          menu2: [], //선택된 depth2에 해당하는 데이터
          menu3: [], //선택된 depth3에 해당하는 데이터
        },
        //선택된 메뉴 데이터
        selectedDatas: {
          menu1: [],
          menu2: [],
          menu3: [],
        },
        //선택된 메뉴 id를 담은 데이터(DB에 저장된 데이터를 출력하기 위해 사용)
        selectedIds: {
          menu1: [],
          menu2: [],
          menu3: [],
        },
        lastSelectedMenu: null,
      };
    },
    computed: {
      /**
       * @description 2depth 메뉴 선택 비활성화 여부
       */
      disabledDepth2() {
        return !this.selectedDatas.menu1.length || !this.hasChildMenus(this.selectedDatas.menu1[0]?.id, 2);
      },
      /**
       * @description 3depth 메뉴 선택 비활성화 여부
       * @return {boolean}
       */
      disabledDepth3() {
        return !this.selectedDatas.menu2.length || !this.hasChildMenus(this.selectedDatas.menu2[0]?.id, 3);
      },
    },
    methods: {
      /**
       * @description 마지막으로 선택된 메뉴 반환
       * @return {null}
       */
      getLastSelectedMenu() {
        return this.lastSelectedMenu;
      },
      /**
       * @description 메뉴 선택 이벤트
       * @param depth
       * @param e
       */
      selectDepthMenu(depth, e) {
        const { selectedRowsData } = e;
        const selectedMenu = selectedRowsData[0] || null;

        // 데이터 업데이트
        this.selectedDatas[`menu${depth}`] = selectedRowsData;

        // 실제 사용자 클릭으로 인한 선택일 때만 lastSelectedMenu 업데이트
        if (selectedRowsData.length > 0) {
          this.lastSelectedMenu = selectedMenu;
          // lastSelectedMenu가 업데이트될 때만 이벤트 발생
          this.$emit('select-menu', selectedMenu);
        }

        // 하위 depth 처리
        if (depth < 3 && selectedMenu) {
          this.updateChildMenus(depth, selectedMenu.id);
        }
      },
      /**
       * @description 하위 메뉴 업데이트
       * @param currentDepth
       * @param parentId
       */
      updateChildMenus(currentDepth, parentId) {
        const nextDepth = currentDepth + 1;

        // 다음 depth의 메뉴 필터링
        this.selectedByPrevDatas[`menu${nextDepth}`] = this.defaultDatas[`menu${nextDepth}`].filter(menu => menu.parentId === parentId);

        // 현재 depth 이후의 모든 하위 depth 초기화
        this.clearDepths(nextDepth + 1);
      },
      /**
       * @description 선택된 메뉴 초기화
       * @param startDepth
       */
      clearDepths(startDepth) {
        for (let depth = startDepth; depth <= 3; depth++) {
          this.selectedByPrevDatas[`menu${depth}`] = [];
          this.selectedDatas[`menu${depth}`] = [];
          this.selectedIds[`menu${depth}`] = [];
        }
      },
      /**
       * @description 메뉴 목록 설정
       */
      setMenuList() {
        const menus = this.$store.getters.getMenuList.filter(d => d.authUseFl === this.$_enums.common.stringUsedFlag.YES.value);
        const menusByDepth = {
          menu1: new Set(),
          menu2: new Set(),
          menu3: new Set(),
        };

        menus.forEach(menu => {
          const depth = menu.menuDepth;
          if (depth >= 1 && depth <= 3) {
            // depth가 1인 경우(대메뉴)는 parentId가 null인 것만 추가
            if (depth === 1) {
              if (menu.parentId === null) {
                menusByDepth.menu1.add(menu);
              } else {
                console.warn(`Warning: Found depth 1 menu with non-null parentId:`, menu);
              }
            } else if (menu.parentId === null) {
              console.warn(`Warning: Found depth ${depth} menu with null parentId:`, menu);
            } else {
              menusByDepth[`menu${depth}`].add(menu);
            }
          }
        });

        const sortByMenuOrd = (a, b) => {
          if (a.menuOrd === null) return 1;
          if (b.menuOrd === null) return -1;
          return a.menuOrd - b.menuOrd;
        };

        this.defaultDatas = {
          menu1: [...menusByDepth.menu1].sort(sortByMenuOrd),
          menu2: [...menusByDepth.menu2].sort(sortByMenuOrd),
          menu3: [...menusByDepth.menu3].sort(sortByMenuOrd),
        };
      },
      /**
       * @description 데이터 초기화
       */
      initData() {
        Object.keys(this.selectedByPrevDatas).forEach(key => {
          this.selectedByPrevDatas[key] = [];
          this.selectedDatas[key] = [];
          this.selectedIds[key] = [];
        });
        this.lastSelectedMenu = null;
        this.$refs.menuSelect1?.instance?.clearSelection();
      },
      /**
       * @description i18n 메시지 반환
       * @return {*}
       */
      getI18nLoadingMessage() {
        return this.$_lang('COMMON.MESSAGE.LOADING');
      },
      /**
       * @description i18n 메시지 반환
       * @return {*}
       */
      getI18nNoDataMessage() {
        return this.$_lang('COMMON.MESSAGE.CMN_NO_DATA');
      },
      /**
       * @description 하위 메뉴 존재 여부 반환
       * @param parentId
       * @param targetDepth
       * @return {boolean}
       */
      hasChildMenus(parentId, targetDepth) {
        if (!parentId) return false;
        return this.defaultDatas[`menu${targetDepth}`].some(menu => menu.parentId === parentId);
      },
    },
    mounted() {
      this.setMenuList();
    },
  };
</script>

<style scoped>
  .contents-title-box {
    height: 40px;
    position: relative;
    border-bottom: 1px solid #ccc;
    line-height: 40px;
  }

  .contents-title-box .contents-title {
    height: 40px;
    padding-left: 5px;
    display: inline-block;
    font-size: 0.9em;
  }

  .contents-box {
    width: 100%;
    padding: 20px 10px;
    background-color: #fff;
  }
</style>
