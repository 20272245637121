<template>
	<div style="height: 100%; width: 100%">
		<div class="popCont widgetModal">
			<tabs ref="tabs" @selectedIndex="tabSelectedIndex" :tabType="1" class="p-0">
				<tab title="데이터 설정">
					<div class="locker_setting_list sub_new_style01 sub_ui_box1">
						<table class="table_form line-bin">
							<caption>
								<strong>조회 그룹</strong>
							</caption>
							<colgroup>
								<col style="width: 140px" />
								<col style="width: auto" />
							</colgroup>
							<tbody>
								<tr v-if="this.widgetProperty.chartType !== 'scoreguard'">
									<th scope="row" class="alT"><label>조회 그룹</label></th>
									<td>
										<div>{{ axisXName }}</div>
									</td>
								</tr>
								<tr
									v-if="
										this.widgetProperty.chartType !== 'scoreguard' &&
										this.widgetProperty.widgetTp.columnGroupList.includes('YMD')
									"
								>
									<th scope="row" class="alT"><label>날짜/시간 유형</label></th>
									<td>
										<DxSelectBox
											placeholder="날짜/시간"
											:items="dataSetting.dateFormatForModal"
											displayExpr="text"
											valueExpr="id"
											v-model="dataSetting.dateGroupCode"
											:styling-mode="stylingMode"
											width="150px"
											:height="30"
											class="mar_ri10"
										>
										</DxSelectBox>
									</td>
								</tr>
								<tr class="columnList">
									<th scope="row" class="alT"><label>조회 항목</label></th>
									<td>
										<div class="clearfix ui-glid-box top-box">
											<div class="fl per100">
												<div id="searchFirst" class="w-full inline-block" style="height: 400px">
													<table class="line-bin" style="padding: 0px">
														<colgroup>
															<col style="width: 45%" />
															<col style="width: 4%" />
															<col style="width: 45%" />
														</colgroup>
														<thead class="sub_title_txt">
															<tr>
																<td>
																	<h2>조회 데이터</h2>
																</td>
																<td>
																	<h2></h2>
																</td>
																<td>
																	<h2>조회 항목</h2>
																</td>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td style="border: 1ch">
																	<DxDataGrid
																		class="grid-box"
																		id="grid"
																		:data-source="dataSetting.deselectDataGrid.columnList"
																		:show-borders="true"
																		:show-column-headers="true"
																		:show-column-lines="false"
																		:show-row-lines="true"
																		:row-alternation-enabled="false"
																		:allow-column-reordering="true"
																		:no-data-text="
																			noDataTextTarget(
																				dataSetting.deselectDataGrid.columnList.length,
																				1,
																			)
																		"
																		:selected-row-keys="dataSetting.deselectDataGrid.selectedItemKeys"
																		@selection-changed="dataSetting.deselectDataGrid.selectionChanged"
																		width="100%"
																		height="300px"
																	>
                                    <DxLoadPanel :enabled="true"/>
                                    <DxScrolling mode="virtual" row-rendering-mode="virtual" use-native="true"/>
																		<DxColumn
																			caption="항목명"
																			data-field="caption"
																			:fixed="false"
																			alignment="center"
																			:visible="true"
																			cell-template="titleTemplate"
																		/>
																		<template #titleTemplate="{ data }">
																			{{ data.value }}
																		</template>
																		<DxColumn
																			caption="적용"
																			cell-template="moveTemplate"
																			:fixed="false"
																			alignment="center"
																			width="95"
																		/>
                                    <template #moveTemplate="{ data }">
                                      <button
                                          class="btn_XS white2 add3"
                                          type="button"
                                          @click="onRemoveItemButton({ data })"
                                      ></button>
																		</template>
																	</DxDataGrid>
																</td>
																<td><img src="@/assets/images/icon/arrow_icon02.png" /></td>
																<td style="border: 1ch">
																	<DxDataGrid
																		class="grid-box"
																		id="grid"
																		:data-source="dataSetting.selectDataGrid.columnList"
																		:show-borders="true"
																		:show-column-headers="true"
																		:show-column-lines="false"
																		:show-row-lines="true"
																		:row-alternation-enabled="false"
																		:allow-column-reordering="true"
																		:no-data-text="
																			noDataTextTarget(
																				dataSetting.selectDataGrid.columnList.length,
																				2,
																			)
																		"
																		:selected-row-keys="dataSetting.selectDataGrid.selectedItemKeys"
																		@selection-changed="dataSetting.selectDataGrid.selectionChanged"
																		width="100%"
																		height="300px"
																	>
                                    <DxLoadPanel :enabled="true"/>
                                    <DxScrolling mode="virtual" row-rendering-mode="virtual" use-native="true"/>
																		<DxColumn
																			caption="항목명"
																			data-field="caption"
																			:fixed="false"
																			alignment="center"
																			:visible="true"
																			cell-template="titleTemplate"
																		/>
																		<template #titleTemplate="{ data }">
																			{{ data.value }}
																		</template>
																		<DxColumn
																			caption="해제"
																			cell-template="moveTemplate"
                                      :fixed="false"
                                      alignment="center"
                                      width="90"
																		/>
																		<template #moveTemplate="{ data }">
																			<button
																				class="btn_XS white2 del1"
																				type="button"
																				@click="onAddItemButton({ data })"
																			></button>
																		</template>
																	</DxDataGrid>
																</td>
															</tr>
														</tbody>
													</table>
												</div>
											</div>
										</div>
									</td>
								</tr>
								<tr v-if="this.widgetProperty.chartType !== 'scoreguard'">
									<th scope="row" class="alT"><label>정렬</label></th>
									<td class="clearfix inlineFlex">
										<DxRadioGroup
											v-model="dataSetting.sortType"
											:items="dataSetting.sort"
											layout="horizontal"
											class="inlineFlex marginAuto"
										/>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</tab>
				<tab title="위젯 설정">
					<div class="locker_setting_list sub_new_style01 sub_ui_box1">
						<table class="table_form line-bin">
							<caption>
								<strong> 위젯유형 </strong>
							</caption>
							<colgroup>
								<col style="width: 140px" />
								<col style="width: auto" />
							</colgroup>
							<tbody>
								<tr>
									<th scope="row" class="alT"><label>위젯유형</label></th>
									<td>
										<div>
											{{ widgetProperty.chartName }}
										</div>
									</td>
								</tr>
								<tr>
									<th scope="row" class="alT"><label>위젯제목</label></th>
									<td>
										<DxTextBox
											v-model="widgetSetting.title"
											:styling-mode="stylingMode"
											class="subheading-content"
											:width="400"
											:height="29"
                      maxLength="30"
											@key-up="$_checkLimitTextLength($event, widgetSetting, limitNumberTexts, 'title')"

										>
										</DxTextBox>
										{{
											limitNumberTexts.textLengths.title
												? limitNumberTexts.textLengths.title
												: widgetSetting.title
												? widgetSetting.title.length
												: 0
										}}/{{ limitNumberTexts.maxLengths.title }}자
									</td>
								</tr>
								<tr v-if="this.widgetProperty.chartType === 'scoreguard'">
									<th scope="row" class="alT"><label>비교 대상</label></th>
									<td class="clearfix inlineFlex">
										<div class="marginAuto rightPadding">
											<DxRadioGroup
												:items="widgetSetting.subjectForRadio"
												v-model="widgetSetting.subjectType"
												layout="horizontal"
											>
											</DxRadioGroup>
										</div>
									</td>
								</tr>
								<tr v-if="this.widgetProperty.chartType !== 'scoreguard'">
									<th scope="row" class="alT">
										<label for="label01"> 조회 기간 </label>
									</th>
									<td class="clearfix dashboardValidation">
										<!-- 최근 조회 -->
                    <div class="inlineFlex rightPadding">최근</div>
                    <div class="inlineDiv">
                      <DxSelectBox
                        v-model="widgetSetting.recent"
                        :data-source="widgetSetting.days"
                        :styling-mode="stylingMode"
                        width="90px"
                        :height="30"
                        class="mar_ri10"
                        @selection-changed="onChangedRecent"
                      >
                      </DxSelectBox>
                      <div class="inlineFlex">일</div>
                    </div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</tab>
			</tabs>
		</div>
	</div>
</template>
<script>
import moment from 'moment';
import DxRadioGroup from 'devextreme-vue/radio-group';
import DxDateBox from 'devextreme-vue/date-box';
import DxTextBox from 'devextreme-vue/text-box';
import { DxDataGrid, DxColumn, DxScrolling, DxLoadPanel } from 'devextreme-vue/data-grid';
import { DxSelectBox } from 'devextreme-vue/select-box';
import Tabs from '@/components/common/tabs.vue';
import Tab from '@/components/common/tab.vue';
export default {
	components: {
		DxTextBox,
		DxDataGrid,
    DxScrolling,
    DxLoadPanel,
		DxColumn,
		Tabs,
		Tab,
		DxRadioGroup,
		DxSelectBox
	},
	props: {
		widgetProperty: Object,
	},
	watch: {
		'widgetSetting.durationType': {
			handler(val) {
				let filterData = val;
				if (filterData) {
					this.widgetProperty.modalDurationType = filterData.id;
				}
			},
			deep: true,
		},
		'dataSetting.selectDataGrid.columnList': {
			handler(val) {
				let filterData = val;
				if (filterData) {
					this.widgetProperty.modalColumnList = this.dataSetting.selectDataGrid.columnList.map(e => {
						return e.dataField;
					});
					this.widgetProperty.modalColumnNameList = this.dataSetting.selectDataGrid.columnList.map(e => {
						return e.caption;
					});
				}
			},
			deep: true,
		},
		'dataSetting.dateGroupCode': {
			handler(val) {
				let filterData = val;
				this.widgetProperty.dateGroupCode = filterData;
				this.dataSetting.dateGroupCode = this.widgetProperty.dateGroupCode;

				if (filterData) {
					// 일 시간별
					if (this.dataSetting.dateGroupCode === this.dataSetting.dateFormat.daily || this.dataSetting.dateGroupCode === this.dataSetting.dateFormat.hour) {
						this.widgetSetting.recent === null ? 1 : this.widgetSetting.recent;
					}
				}
			},
			deep: true,
		},
		'dataSetting.sortType': {
			handler(val) {
				let filterData = val;
				if (filterData) {
					this.widgetProperty.modalSortType = filterData.id;
				}
			},
			deep: true,
		},
		'widgetSetting.title': {
			handler(val) {
				let filterData = val;
				if (filterData) {
					this.widgetProperty.modalTitle = filterData;
				}
			},
			deep: true,
		},
		'widgetSetting.recent': {
			handler(val) {
				let filterData = val;
				if (filterData) {
          // 일, 시간별 외 다른 형식 추가 가능성이 있으므로 분기처리
					if (this.dataSetting.dateGroupCode === this.dataSetting.dateFormat.daily || this.dataSetting.dateGroupCode === this.dataSetting.dateFormat.hour) {
						this.widgetProperty.modalRecent = filterData;
					}
				}
			},
			deep: true,
		},
	},
	data() {
		return {
			// 위젯의 우측 상단 '...'버튼의 '설정'버튼 클릭 시 데이터 설정 탭
			dataSetting: {
				// 날짜/시간 형식
				dateFormatForModal: [
					{ id: 210, text: '일별', codeValue: 'daily' },
					{ id: 213, text: '시간별', codeValue: 'hour' },
				],
        // 날짜/시간 형식
        dateFormat: {
          daily: 210,
          hour: 213,
        },
				dateGroupCode: null,
				sort: [
					{ text: '내림차순', id: 0 },
					{ text: '오름차순', id: 1 },
				],
				sortType: {},
				allColumn: [],
				deselectDataGrid: {
					//columnList 경우, 통계 용어 "측정값"(23.09) > 용어 단순화로 "조회 항목"(23.10)으로 변경
					//측정값이란? 데이터 집계, 양으로 측정할 수 있는 단위 (e.g., A 회사의 연 매출 합계, A 부서의 평균 나이)
					columnList: [],
					selectedItemKeys: [],
					selectionChanged: data => {
						this.dataSetting.grid.selectedItemKeys = data.selectedRowKeys;
					},
				},
				selectDataGrid: {
					columnList: [],
					selectedItemKeys: [],
					selectionChanged: data => {
						this.dataSetting.grid.selectedItemKeys = data.selectedRowKeys;
					},
				},
			},
			// 위젯의 우측 상단 '...'버튼의 '설정'버튼 클릭 시 위젯 설정 탭
			widgetSetting: {
				title: '',
				//조회기간 유형
        durationForModal: [
					{ id: '1130', text: '최근 조회' },
				],
        duration : {
          recent: '1130'
        },
				//선택한 조회기간 유형
				durationType: {},
				subjectForRadio: [
          { id: '1251', text: '어제' },
				],
        // 스코어 카드 위젯 타입일 경우 설정 팝업에서 조회 기간 유형이 아닌 비교 대상이 나온다.
        // 비교 대상
        subject:{
          yesterday:'1251'
        },
				recent: null,
				days: [],
				months: [],
				years: [],
				dayStart: null,
				dayEnd: null,
				startMonth: null,
				startYear: null,
				endMonth: null,
				endYear: null,
				specialDate: null,},
			ctiType: '',
			dateVisible: false,
			targetAxisXs: [],
			axisXName: '',
			now: new Date(),
			flag: false,
			tabs: {
				selectedIndex: this.$store.getters.getTabHists[this.$router.currentRoute.path],
			},
			limitNumberTexts: {
				textLengths: {},
				maxLengths: {
					title: 30,
				},
			},
			stylingMode: 'outlined',
			date: {
				startDate: null,
				endDate: null,
			},
		};
	},
	methods: {
    /** @description : 최근 ( )일 조회일 경우 날짜 재정의 메서드*/
    dayStartChangeWhenRecent() {
      // 최근 ( )일
      const recent = this.widgetSetting.recent;
      let date = new Date();
      const dateGroupCode = this.dataSetting.dateGroupCode;
      let dayStart = '';
      if (dateGroupCode === this.dataSetting.dateFormat.daily || dateGroupCode === this.dataSetting.dateFormat.hour) {
        const daysAgo = date.getDate() - (recent-1);
        //date = date.setDate(daysAgo); 이렇게 설정하는 순간 타임스탬프 형식으로 변경됨
        date.setDate(daysAgo);
        dayStart = moment(date).format('YYYY.MM.DD');
      }
      return dayStart;
    },
    /** @description : 최근 ( )일 선택 시 이벤트*/
		onChangedRecent() {
			const x = { value: { id: this.widgetSetting.durationType.id } };
			this.onChangedDate(x);
		},
    /** @description : 탭 선택 시 이벤트*/
		tabSelectedIndex(index) {
			this.tabs.selectedIndex = index;
		},
    /** @description : 조회항목(columnList)을 정렬하는 메서드*/
    sortInDataGrid(data) {
			data.columnList.sort((a, b) => {
				const captionA = a.caption.toLowerCase(); 
				const captionB = b.caption.toLowerCase();
				if (captionA < captionB) {
					return -1;
				}
				if (captionA > captionB) {
					return 1;
				}
				return 0; // names must be equal
			});
		},
		/** @description : 조회데이터에서 data가 제외되고 조회 항목으로 추가되는 메서드*/
		onRemoveItemButton(data) {
			let moveData = data.data.data;

			if (this.widgetProperty.chartType === 'scoreguard') {
				if (this.dataSetting.selectDataGrid.columnList.length === 1) {
					this.dataSetting.deselectDataGrid.columnList.push(this.dataSetting.selectDataGrid.columnList[0]);
					this.dataSetting.selectDataGrid.columnList = [];
				}
			}

			this.dataSetting.deselectDataGrid.columnList.some(item => item.dataField === moveData.dataField)
				? this.dataSetting.selectDataGrid.columnList.push(moveData)
				: this.$log.debug('있는 요소는 추가하지 않습니다');
			//추가된 요소는 deselect에서 제외되도록 필터
			this.dataSetting.deselectDataGrid.columnList = this.dataSetting.deselectDataGrid.columnList.filter(
				d => d.dataField !== moveData.dataField,
			);
			// 조회 데이터 정렬
			this.sortInDataGrid(this.dataSetting.deselectDataGrid);
			// 조회 항목 정렬
			this.sortInDataGrid(this.dataSetting.selectDataGrid);
		},
    /** @description : 조회항목에서 data가 제외되고 조회 데이터으로 추가되는 메서드*/
		onAddItemButton(data) {
			if (this.dataSetting.selectDataGrid.columnList.length === 1) {
				this.$_Msg('조회 데이터는 1개 이상 선택해야 합니다.');
				return;
			}
			let moveData = data.data.data;
			this.dataSetting.selectDataGrid.columnList.some(item => item.dataField === moveData.dataField)
				? this.dataSetting.deselectDataGrid.columnList.push(moveData)
				: this.$log.debug('있는 요소는 추가하지 않습니다');

			this.dataSetting.selectDataGrid.columnList = this.dataSetting.selectDataGrid.columnList.filter(
				d => d.dataField !== moveData.dataField,
			);
			// 조회 데이터 정렬
			this.sortInDataGrid(this.dataSetting.deselectDataGrid);
			// 조회 항목 정렬
			this.sortInDataGrid(this.dataSetting.selectDataGrid);
		},
    /** @description : dataGrid에 data가 없을 떄의 텍스트를 리턴하는 메서드*/
		noDataTextTarget(length) {
			if (length === 0) {
				return '데이터가 없습니다';
			}
		},
    /** @description : 최근 ( )일 선택에 따른 DB 저장 전 날짜값 변경 이벤트*/
    async onChangedDate(e) {
			let type = '';
			if (typeof e !== 'undefined') {
				type = e.value.id;
				this.widgetProperty.modalDurationType = type;
			} else {
				return;
			}

			//일별, 시간별
			if (this.widgetProperty.dateGroupCode === this.dataSetting.dateFormat.daily || this.widgetProperty.dateGroupCode === this.dataSetting.dateFormat.hour) {
				//최근기간 조회
				if (type === this.widgetSetting.duration.recent) {
					const recent = this.widgetSetting.recent;
					let date = new Date();

					this.widgetProperty.modalDayEnd = this.$_commonlib.formatDate(date, 'YYYYMMDDHHmmss', 'YYYYMMDD');
					const daysAgo = date.getDate() - recent;

					//date = date.setDate(daysAgo); 이렇게 설정하는 순간 타임스탬프 형식으로 변경
					date.setDate(daysAgo);

					this.widgetProperty.modalDayStart = this.$_commonlib.formatDate(date, 'YYYYMMDDHHmmss', 'YYYYMMDD');
					this.widgetSetting.dayStart = this.widgetProperty.modalDayStart;
					this.widgetSetting.dayEnd = this.widgetProperty.modalDayEnd;
				}
			}
		},
    /** @description : 마스터 쿼리를 사용하여 해당하는 위젯의 위젯 템플릿 테이블명으로 선택 가능한 전체 컬럼을 조회*/
    async getXmlColumns() {
			let params = null;
			let payload = { loading: false };
      this.ctiType = this.$_getSystemData('cti_system_type')?.configValue.toLowerCase();
			params = { targetQuery: this.widgetProperty.widgetTp.targetQuery, solution: 'ers', subPath: this.ctiType };
			payload.actionName = 'DASHBOARD_COLUMN_MASTER_LIST_XML';
			payload.data = params;
			const master = await this.CALL_REPORT_API(payload);
			let masterQueryColumns = [];

			if (master.status === 200 && master.data.header.resCode === 'success') {
				masterQueryColumns = master.data.data;
			}

			let masterAxisYs = [];
			let masterAxisXs = [];

			//차원값 전역변수화
			this.targetAxisXs = [];

			let targetAxisYs = [];

      // split 예외처리
			if (this.widgetProperty.columnList !== null && this.widgetProperty.columnList !== 'undefined') {
				if (this.widgetProperty.columnNameList !== null && this.widgetProperty.columnNameList !== 'undefined') {
					if (typeof this.widgetProperty?.columnNameList === 'string') {
						this.widgetProperty.columnNameList = this.widgetProperty?.columnNameList.split(',');
					}
					if (typeof this.widgetProperty?.columnList === 'string') {
						this.widgetProperty.columnList = this.widgetProperty?.columnList.split(',');
					}
					const columnList = this.widgetProperty.columnList;
					const columnNameList = this.widgetProperty.columnNameList;
					this.widgetProperty.modalColumnList = columnList;
					this.widgetProperty.modalColumnNameList = columnNameList;
					targetAxisYs = columnList.map((letter, index) => ({
						dataField: letter,
						caption: columnNameList[index],
					}));
				}
			}

			masterQueryColumns.filter(e => {
				if (e.axis === 'X') {
					masterAxisXs.push(e);
				} else {
					masterAxisYs.push(e);
				}
			});

			const groupNames = this.widgetProperty.widgetTp.columnGroupNameList.split(','); //문자열 검증
			if (groupNames.length > 0) {
				if (groupNames.length === 1) this.axisXName = groupNames[0];
				//조회항목이 두개 이상일 경우를 수정해야함
				//else
				/*
        let axisXNames = [];
        this.targetAxisXs.filter(e => axisXNames.push(e.caption));
        this.axisXName = axisXNames.join();
        */
			} else {
				this.$_Toast('조회항목이 없습니다.');
			}

			this.dataSetting.selectDataGrid.columnList = targetAxisYs;

			const dataFields = targetAxisYs.map(e => e.dataField);

			masterAxisYs.filter((ae, id) => {
				if (!dataFields.includes(ae.dataField)) {
					this.dataSetting.deselectDataGrid.columnList.push(ae);
				}
			});

			// 조회 데이터 정렬
			this.sortInDataGrid(this.dataSetting.deselectDataGrid);
			// 조회 항목 정렬
			this.sortInDataGrid(this.dataSetting.selectDataGrid);

			this.widgetProperty.isCalculationComplete = false;
		},
    createdData() {
      this.widgetSetting.days = Array(30)
          .fill()
          .map((v, i) => i + 1);
    },
    async mountedData() {
      // 클릭한 위젯의 속성값
      let widget = this.widgetProperty;

      // 마운트가 될 때 마다 비워주기
      widget.modalTitle = null;
      widget.modalDurationType = null;
      widget.modalDayStart = null;
      widget.modalDayEnd = null;
      widget.modalSortType = null;
      widget.modalRecent = null;
      widget.modalColumnNameList = [];
      widget.modalColumnList = [];

      // 위젯의 제목
      this.widgetSetting.title = widget.title;
      widget.modalTitle = this.widgetSetting.title;

      // 날짜/시간 유형값
      this.dataSetting.dateGroupCode = widget.dateGroupCode;

      // 스코어카드와 그 외 차드 조건에 대한 분기
      if(widget.widgetTp.chartTypeCd === 1229){
        // 스코어카드 타입의 경우 조회기간이 아니라 비교대상이 출력된다.
        const subjectType = widget.subjectType;
        this.widgetSetting.subjectType = this.widgetSetting.subjectForRadio.find(item => item.id === subjectType);
      }else{
        // 스코어카드 외 위젯유형일 경우 비교대상이 아니라 조회기간이 출력된다.
        // 현재 최근 조회만 선택 가능함 cf) 기획 초기에는 고정 기간 조회, 누적 기간 조회라는 선택항목이 추가적으로 있었음, 추후 추가 가능성 존재
        const durationType = widget.durationType;
        this.widgetSetting.durationType = this.widgetSetting.durationForModal.find(d => d.id === durationType);
        widget.modalDurationType = this.widgetSetting.durationType.id;

        // 속성에 따른 데이터 조회 날짜
        if(durationType === this.widgetSetting.duration.recent){
          this.widgetSetting.dayStart = this.dayStartChangeWhenRecent();
          this.widgetSetting.dayEnd = moment().format('YYYY.MM.DD');

          if ((this.dataSetting.dateGroupCode === this.dataSetting.dateFormat.daily) || (this.dataSetting.dateGroupCode === this.dataSetting.dateFormat.hour)) {
            this.widgetSetting.recent = widget.recent;
          }
        } else {
          this.widgetSetting.recent = 0;
        }
        widget.modalRecent = this.widgetSetting.recent;

        // 조회 날짜를 모달관련 변수로 저장 > 최근 ( )일 값이 변경될 때 마다 변경되는 임시값으로 DB에 저장되기 전의 값
        widget.modalDayStart = this.widgetSetting.dayStart;
        widget.modalDayEnd = this.widgetSetting.dayEnd;
      }

      // columnGroupName(양적 측정 불가능한 값, 차원값) 정렬
      this.dataSetting.sortType = this.dataSetting.sort[widget.sortType];

      // 선택 가능한 다른 조회 항목 리스트
      this.getXmlColumns();
    },
	},
	created() {
		this.createdData();
	},
	async mounted() {
		await this.mountedData();
	},
};
</script>
<style>
.widgetModal .page-sub-box{
  padding: 0;
}

.widgetModal .ecs-tab-box {
    padding-top: 0px;
}
.widgetModal .inline {
	display: inline-block;
}

.popCont .table_form .sub_title_txt tr td {
	border-bottom: none;
}

.radio {
	display: inline-block;
}
.inlineFlex {
	display: inline-flex;
}
.widgetModal .inlineDiv {
	display: contents;
  padding: 0px;
}

.widgetModal .add3, .widgetModal .del1 {
  padding-left: 20px;
}
.leftPadding {
	padding: 0 0 0 10px;
}
.rightPadding {
	padding: 0 10px 0 0;
}
.columnList {
	height: 300px !important;
}
.dashboardValidation .dx-invalid-message .dx-invalid-message-content{
  max-width: 210px!important;
  width: 210px!important;
}
.dashboardValidation .dx-invalid.dx-dropdowneditor-button-visible.dx-editor-outlined .dx-texteditor-input{
  padding-right: 5px!important;
}
</style>
<style scoped>
.inlineDiv {
    .inquiry-period{
        display: inline-block;
    }
}

.marginAuto{
  margin: auto;
}

.popCont > .chart-aco-list-set .unit-list.channel-acco-list-box.scrollbar-dynamic {
  height: 470px !important;
}
</style>
