<template>
	<div class="page-sub-box">
		<div class="locker_setting_list sub_new_style01 sub_ui_box1">
			<Search
				codeKey="search_ipt_group_typ"
				:searchObj="searchType.obj"
				:useKeywordType="true"
				@change-search-type="$_changeSearchType"
				@onSearchClick="$_searchData($event, 'search_ipt_group_typ')"
				ref="searchRef"
			>
				<template v-slot:before>
					<DxSelectBox
							v-model="searchType.customTypes.inoutTypeCd"
							placeholder="서버 선택"
							:items="getInoutType"
							display-expr="codeNm"
							value-expr="id"
							:styling-mode="stylingMode"
							:width="120"
							:height="30"
							@value-changed="$_changeSearchCustomType('inoutTypeCd', $event)"
					/>
				</template>
			</Search>
		</div>

		<esp-dx-data-grid :data-grid="dataGrid" ref="groupGrid" @init-new-row="onInitNewRow" @exporting="onExporting"></esp-dx-data-grid>
	</div>
</template>
<script>
import Search from '@/components/common/search.vue';
import { DxSelectBox } from 'devextreme-vue/select-box';
import ExcelJS from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import saveAs from 'file-saver';
import { DxSwitch } from 'devextreme-vue/switch';
import { isSuccess } from "@/plugins/common-lib";
import CustomStore from "devextreme/data/custom_store";
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid.vue";

export default {
	components: {
    EspDxDataGrid,
		Search,
		DxSelectBox,
	},
	watch: {
	},
	data() {
		return {
			maxGroupOrd: 0,
			config: {
				pageSetting: {
					//pageData pageSetting 관련
					config: {},
				},
				gradeList: null,
			},
			stylingMode: 'outlined', //outlined, underlined, filled
			searchType: {
				obj: {},
				customTypes: {
					inoutTypeCd: null
				},
				paramsData: null,
			},
			dataGrid: {
        callApi: 'CALL_EUC_API',
				excel: {
					title: '그룹정보'
				},
				refName: 'groupGrid',
				allowColumnResizing: true, //컬럼 사이즈 허용
				showBorders: false, //border 유무
				showColumnHeaders: true, //컬럼 헤더 유무
				showColumnLines: false, //컬럼 세로선 유무
				showRowLines: true, //컬럼 가로선 유무
				rowAlternationEnabled: false,
				dataSource: [],
				// width:'200',     // 주석처리시 100%
				height: '650',    // 주석처리시 100%
				apiActionNm: {
					update: 'EUC_DEVICE_GROUP_UPDATE',
					delete: 'EUC_DEVICE_GROUP_DELETE',
          loading: true
				},
				showActionButtons: {
					save: true,
					delete: true,
					excel: true,
				},
				customEvent: {
					initNewRow: true,
					exporting: true,
				},
				grouping: {
					contextMenuEnabled: false,
					autoExpandAll: false,
					allowCollapsing: true,
					expandMode: 'rowClick', // rowClick or buttonClick
				},
				groupPanel: {
					visible: false,
				},
				columnChooser: {
					enabled: false, // 컬럼 Chooser 버튼 사용유무
				},
				loadPanel: {
					enabled: true, // 로딩바 표시 유무
				},
				sorting: {
					mode: 'multiple', // single multiple
				},
				remoteOperations: {
					// 서버사이드 여부
					filtering: false,
					sorting: false,
					grouping: false,
					paging: false,
				},
				paging: {
					// scrolling 미사용시만 적용됨
					enabled: true,
					pageSize: 10,
					pageIndex: 0, // 시작페이지
				},
				pager: {
					visible: true, //페이저 표시 여부
					showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
					allowedPageSizes: [],
					displayMode: 'compact', //표시 모드 : ['full', 'compact']
					showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
					showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
				},
				filterRow: {
					visible: true,
				},
				headerFilter: {
					visible: true,
				},
				editing: {
					allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
					allowAdding: true, // 추가 버튼을 없애고 싶으면 false설정
					allowDeleting: false,
					mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
					startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
					selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
				},
				selecting: {
					mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
					selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
					showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
				},
				columns: [
					{
						caption: '그룹명',
						dataField: 'groupNm',
						width: 150,
						height: 40,
						alignment: 'center',
						visible: true,
						allowEditing: true,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						allowGrouping: false,
						requiredRule: {
							message: '필수 항목입니다.'
						},
					},
					{
						caption: '코드값',
						dataField: 'groupCd',
						width: 90,
						height: 40,
						alignment: 'center',
						visible: true,
						allowEditing: true,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						allowGrouping: false,
						requiredRule: {
							message: '필수 항목입니다.'
						},
					},
					{
						caption: '라벨',
						dataField: 'label',
						width: 150,
						height: 40,
						alignment: 'center',
						visible: true,
						allowEditing: true,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						allowGrouping: false,
						requiredRule: {
							message: '필수 항목입니다.'
						},
					},
					{
						caption: 'DDD',
						dataField: 'ddd',
						width: 150,
						height: 40,
						alignment: 'center',
						visible: true,
						allowEditing: true,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						allowGrouping: false,
						requiredRule: {
							message: '필수 항목입니다.'
						},
					},
					{
						caption: '설명',
						dataField: 'description',
						height: 40,
						alignment: 'center',
						visible: true,
						allowEditing: true,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						allowGrouping: false,
						requiredRule: {
							message: '필수 항목입니다.'
						},
					},
					{
						caption: '패턴',
						dataField: 'pattern',
						height: 40,
						alignment: 'center',
						visible: true,
						allowEditing: true,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						allowGrouping: false,
						customRule: {
							message: '패턴 형식을 확인해주세요.',
							callback: (e) => {
								return this.checkRegExp(e.value);
							}
						},
						requiredRule: {
							message: '필수 항목입니다.'
						},
					},
					{
						caption: 'Regex',
						dataField: 'regex',
						height: 40,
						alignment: 'center',
						visible: true,
						allowEditing: true,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						allowGrouping: false,
						customRule: {
							message: 'Regex 형식을 확인해주세요.',
							callback: (e) => {
								return this.checkRegExp(e.value);
							}
						},
						requiredRule: {
							message: '필수 항목입니다.'
						},
					},
					{
						caption: '내선자리 수',
						dataField: 'extlen',
						width: 115,
						height: 40,
						alignment: 'center',
						visible: true,
						allowEditing: true,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						allowGrouping: false,
					},
					{
						caption: 'IN/OUT',
						dataField: 'inout',
						width: 100,
						height: 40,
						alignment: 'center',
						visible: true,
						allowEditing: true,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						allowGrouping: false,
					},
					{
						caption: '유형',
						dataField: 'inoutTypeCd',
						height: 40,
						alignment: 'center',
						visible: true,
						allowEditing: false,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						allowGrouping: false,
						editorType: 'dxSelectBox',
						cellTemplate: (container, options) => {
							const selectbox = new DxSelectBox({
								propsData: {
									placeholder: '선택',
									items: this.$_getCode('inout_type'),
									displayExpr: 'codeNm',
									valueExpr: 'codeId',
									value: options.value,
									stylingMode: this.stylingMode,
									onValueChanged: (e) => {
										this.$refs.groupGrid.getInstance.cellValue(options.rowIndex, options.columnIndex, e.value);
									},
								},
							});
							selectbox.$mount();
							container.append(selectbox.$el);
						},
					},
					{
						caption: '사이트',
						dataField: 'siteId',
						width: 120,
						height: 40,
						alignment: 'center',
						visible: true,
						allowEditing: false,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						allowGrouping: false,
						editorType: 'dxSelectBox',
						cellTemplate: (container, options) => {
							const selectbox = new DxSelectBox({
								propsData: {
									placeholder: '선택',
									items: this.$store.getters.getSiteList,
									displayExpr: 'siteNm',
									valueExpr: 'id',
									value: options.value,
									stylingMode: this.stylingMode,
									onValueChanged: (e) => {
										this.$refs.groupGrid.getInstance.cellValue(options.rowIndex, options.columnIndex, e.value);
										const tenant = this.getTenantList(e.value)[0];
										const newValue = tenant?.id || null;
										this.$refs.groupGrid.getInstance.cellValue(options.rowIndex, options.columnIndex + 1, newValue);
									},
								},
							});
							selectbox.$mount();
							container.append(selectbox.$el);
						},
						requiredRule: {
							message: '필수 항목입니다.'
						},
					},
					{
						caption: 'Tenant',
						dataField: 'tenantId',
						width: 120,
						height: 40,
						alignment: 'center',
						visible: true,
						allowEditing: false,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						editorType: 'dxSelectBox',
						cellTemplate: (container, options) => {
							const stateTypeList = this.getTenantList(options.data.siteId);
							const selectbox = new DxSelectBox({
								propsData: {
									placeholder: '선택',
									items: stateTypeList,
									displayExpr: 'tenantNm',
									valueExpr: 'id',
									value: options.value,
									stylingMode: this.stylingMode,
									onValueChanged: (e) => {
										this.$refs.groupGrid.getInstance.cellValue(options.rowIndex, options.columnIndex, e.value);
									},
								},
							});
							selectbox.$mount();
							container.append(selectbox.$el);
						},
						requiredRule: {
							message: '필수 항목입니다.'
						},
					},
					{
						caption: '순서',
						dataField: 'groupOrd',
						width: 80,
						height: 40,
						alignment: 'center',
						visible: true,
						allowEditing: true,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						allowGrouping: false,
					},
					{
						caption: '사용 여부',
						dataField: 'viewCd',
						width: 105,
						height: 40,
						alignment: 'center', // left center right
						visible: true,
						allowEditing: false,
						sortOrder: 'none',  // acs desc none
						allowHeaderFiltering: false,
						cellTemplate: (container, options) => {
							const switchBtn = new DxSwitch({
								propsData: {
									value: options.value === this.$_getUsedCode.id,
									onValueChanged: () => {
										this.onChangedViewCd(options.data);
									},
								},
							});
							switchBtn.$mount();
							container.append(switchBtn.$el);
						},
					},
					{
						caption: '등록일',
						dataField: 'regDt',
						width: 200,
						height: 40,
						alignment: 'center',
						visible: true,
						allowEditing: false,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						calculateCellValue: this.formatDt,
					},
				],
			},
		};
	},
	computed: {
		getInoutType(){
			return [{codeId: null, codeNm: '전체'}, ...this.$_getCode('inout_type')];
		}
	},
	methods: {
		async onChangedViewCd(data) {
			let viewCd;
			if (data.viewCd === this.$_getUsedCode.id) {
				//사용이면
				viewCd = this.$_getUnUsedCode.id; //미사용으로 스위치
			} else {
				//미사용이면
				viewCd = this.$_getUsedCode.id; //사용으로 스위치
			}
			if(data.id) {
				const payload = {
					actionName: 'EUC_DEVICE_GROUP_UPDATE',
					data: [{
            id: data.id,
            viewCd
          }],
					loading: true,
				}

				const res = await this.CALL_EUC_API(payload);
				if(isSuccess(res)) {
					this.$_Toast(this.$_lang('CMN_SUC_UPDATE'));
					this.$refs.groupGrid.getInstance.refresh();
				} else {
					this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
				}
			} else {
				data.viewCd = viewCd;
			}
		},
		onExporting(e) {
			const title = this.dataGrid.excel.title;
			const workbook = new ExcelJS.Workbook();
			const worksheet = workbook.addWorksheet(title);

			worksheet.columns = this.dataGrid.columns.map(() => {
				return { width : this.dataGrid.excel.cellwidth ? this.dataGrid.excel.cellwidth : 30 }
			});

			const today = this.$_commonlib.formatDate(new Date(), 'YYYYMMDDHHmmss', 'YYYYMMDDHHmmss');

			exportDataGrid({
				component: e.component,
				worksheet: worksheet,
				keepColumnWidths: false,
				autoFilterEnabled: true,
				topLeftCell: { row: 4, column: 1 },
				customizeCell: ({ gridCell, excelCell }) => {
					if (gridCell.rowType === 'header') {
						//header 영역 설정
						excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'C6EFCE' } };
						excelCell.alignment = {horizontal: 'center', vertical: 'middle'};
					} else {
						const { siteId, tenantId } = gridCell?.data;
						if(gridCell.column.dataField === 'siteId') {
							const site = this.$store.getters.getSiteList.find((site) => site.id === siteId);
							excelCell.value = site.siteNm;
						}

						if(gridCell.column.dataField === 'tenantId') {
							const tenantList = this.getTenantList(siteId);
							const tenant = tenantList.find((tenant) => tenant.id === tenantId);
							excelCell.value = tenant.tenantNm;
						}

						//data 영역 배경색 설정
						if (excelCell.fullAddress.row % 2 === 0) {
							excelCell.fill = {
								type: 'pattern', pattern: 'solid', fgColor: { argb: 'F2F2F2' }, bgColor: { argb: 'F2F2F2' },
							};
						}
					}
					//Cell boader line
					const borderStyle = { style: 'thin', color: { argb: 'FF7E7E7E' } };
					excelCell.border = {
						bottom: borderStyle,
						left: borderStyle,
						right: borderStyle,
						top: borderStyle,
					};
				},
			}).then(() => {
				// title Cell
				const titleRow = worksheet.getRow(2);
				titleRow.height = 40;
				worksheet.mergeCells(2, 1, 2, this.dataGrid.columns.length);
				titleRow.getCell(1).value = title;
				titleRow.getCell(1).font = { size: 22, bold: true };
				titleRow.getCell(1).alignment = { horizontal: 'center', vertical: 'middle' };

				//Grid Header Height
				const hearderRow = worksheet.getRow(4);
				hearderRow.height = 30;

			}).then(() => {
				workbook.xlsx.writeBuffer().then((buffer) => {
					saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${title}_${today}.xlsx`);
				});
			});
			e.cancel = true;
		},
		checkRegExp(str) {
			try {
				new RegExp(str);
				return true;
			} catch (error) {
				return false;
			}
		},
		/** @description : Site의 id로 해당하는 tenant 목록 가져오는 메서드 */
		getTenantList(siteId) {
			return this.$store.getters.getTenantList.filter((tenant) => tenant.siteId === siteId)
		},
		/** @description : 데이터 추가 시 기본 값 적용하기 위함 */
		onInitNewRow(e) {
			e.data.viewCd = this.$_getUsedCode.id;
			if(this.maxGroupOrd === 0) {
				this.maxGroupOrd = this.$refs.groupGrid.totalCount;
			}

			this.maxGroupOrd = this.maxGroupOrd + 1;
			e.data.groupOrd = this.maxGroupOrd;
		},
		async selectDataList(sort = '+groupOrd,-regDt') {
			this.dataGrid.dataSource = new CustomStore({
				key: 'id',
				load: async (loadOptions) => {
					let params = this.$_getDxDataGridParam(loadOptions);
					if (!params.sort) {
						params.sort = sort;
					}

					params = { ...params, ...this.searchType.paramsData };

					const payload = {
						actionName: 'EUC_DEVICE_GROUP_LIST_ALL',
						data: params,
						loading: false,
					};

          const rtnData = {
            data: [],
            totalCount: 0,
          };

          const res = await this.CALL_EUC_API(payload);
          if(isSuccess(res)) {
            rtnData.data = res.data.data;
            rtnData.totalCount = res.data.header.totalCount;
          }
          return rtnData;
				}
			});
		},
		/** @description : 날짜 형식 변환 메서드 */
		formatDt(rowData) {
			if(rowData?.regDt) {
				return this.$_commonlib.formatDate(rowData.regDt, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
			}
		},
		/** @description : 라이프사이클 created시 호출되는 메서드 */
		createdData() {},
		/** @description : 라이프사이클 mounted시 호출되는 메서드 */
		async mountData() {
			this.searchType.paramsData = null;
			await this.selectDataList();
		},
	},
	created() {
		this.createdData();
	},
	mounted() {
		this.mountData();
	},
};
</script>
