<!--
  PACKAGE_NAME : src\pages\esp\auth\search-data-grid.vue
  FILE_NAME : search-data-grid
  AUTHOR : devyoon91
  DATE : 2024-08-29
  DESCRIPTION : 보고서 검색 대상 권한 그리드
-->
<template>
  <div class="max-w-lg mr-2.5">
    <div style="height: 40px">
      <div style="float: right; top: 8px">
        선택 <span class="col_blue2">{{ selectedKeys.length }}</span
        >개 / 전체 <span class="col_blue2">{{ totalCount }}</span
        >개
      </div>
    </div>
    <dx-data-grid
      v-if="dataGrid.dataField"
      :visible="dataGrid.visible"
      :id="dataGrid.dataField"
      :ref="dataGrid.ref"
      :data-source="dataSource"
      :disabled="disabled"
      :show-borders="dataGrid.showBorders"
      :show-column-headers="dataGrid.showColumnHeaders"
      :show-column-lines="dataGrid.showColumnLines"
      :show-row-lines="dataGrid.showRowLines"
      :row-alternation-enabled="dataGrid.rowAlternationEnabled"
      :allow-column-reordering="dataGrid.allowColumnReordering"
      :width="dataGrid.width"
      :height="dataGrid.height"
      :selected-row-keys="target[dataGrid.dataField]"
      :key-expr="dataGrid.keyExpr"
      @selection-changed="handleSelectedRow"
      @row-prepared="handleRowPrepared"
    >
      <template v-for="(column, index2) in dataGrid.columns">
        <dx-column
          v-if="column.dataField"
          :key="index2"
          :caption="column.caption"
          :data-field="column.dataField"
          :group-cell-template="column.groupCellTemplate || null"
          :width="column.width"
          :alignment="column.alignment"
          :visible="column.visible"
        />
      </template>
      <dx-filter-row :visible="true" />
      <dx-selection-grid
        v-if="dataGrid.selection"
        :allow-select-all="dataGrid.selection.allowSelectAll ? dataGrid.selection.allowSelectAll : true"
        :select-all-mode="dataGrid.selection.selectAllMode ? dataGrid.selection.selectAllMode : 'allPages'"
        :show-check-boxes-mode="dataGrid.selection.showCheckBoxesMode ? dataGrid.selection.showCheckBoxesMode : 'always'"
        :mode="dataGrid.selection.mode ? dataGrid.selection.mode : 'multiple'"
      />
      <dx-scrolling v-if="dataGrid.scrolling" :mode="dataGrid.scrolling.mode ? dataGrid.scrolling.mode : 'virtual'" />
      <dx-paging :enabled="false" />
    </dx-data-grid>
  </div>
</template>

<script>
  import { DxCheckBox } from 'devextreme-vue/check-box';
  import { DxColumn, DxDataGrid, DxFilterRow, DxPaging, DxScrolling, DxSelection as DxSelectionGrid } from 'devextreme-vue/data-grid';

  export default {
    components: {
      DxFilterRow,
      DxColumn,
      DxDataGrid,
      DxSelectionGrid,
      DxScrolling,
      DxPaging,
    },
    props: {
      totalCount: {
        type: Number,
        default: 0,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      dataGrid: {
        type: Object,
        default: undefined,
      },
      target: {
        type: Object,
        default: undefined,
      },
      dataSource: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        selectedKeys: [],
      };
    },
    methods: {
      handleSelectedRow(e) {
        this.selectedKeys = e.selectedRowKeys;
        this.$emit('selectedRow', this.target, this.dataGrid.dataField, e.selectedRowKeys);
      },
      /** @description: 그리드 row prepared 이벤트 */
      handleRowPrepared(e) {
        // 그룹 row일 경우 체크박스 생성
        if (e.rowType === 'group') {
          const groupCheckBoxCell = e.cells[0].cellElement;
          groupCheckBoxCell.style.textAlign = 'center';
          const groupData = e.data;
          const dataGridId = e.element.id;
          const checkBox = new DxCheckBox({
            propsData: {
              value: false,
              alignment: 'center',
              onValueChanged: e => {
                this.$emit('valueChanged', e, dataGridId, groupData);
              },
            },
          });
          checkBox.$mount();
          groupCheckBoxCell.append(checkBox.$el);
        }
      },
    },
    mounted() {
      if (this.target[this.dataGrid.dataField]) {
        this.selectedKeys = this.target[this.dataGrid.dataField];
      }
    },
  };
</script>
