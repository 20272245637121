<!--
  PACKAGE_NAME : src/pages/cc/ivr/service-templates/list.vue
  FILE_NAME : list
  AUTHOR : hpmoon
  DATE : 2023-09-07
  DESCRIPTION : 서비스 템플릿 관리
-->
<template>
  <div>
    <!-- Main Layer -->
    <div class="page-sub-box tree-box tree-contents">
      <div class="layout-cut-box clearfix tree-box00">
        <!-- Left Tree Layer -->
        <!-- 서비스코드 템플릿 -->
        <div class="layout-cut-left tree-box01 fl">
          <div class="page-sub-box pad_top20">
            <div class="selectDiv">
              <DxCheckBox
                ref="useCheckBox"
                :text="$_lang('COMPONENTS.SHOW_VIEW_FL', { value: $_lang('CC.WORD.TEMPLATE', { defaultValue: '템플릿' }) })"
                :value="this.checkedViewFl"
                @value-changed="onChangedSvcTpViewFl"
              />
            </div>

            <esp-dx-data-grid
              :data-grid="dataGrid"
              :ref="dataGrid.refName"
              @row-click="onClickRowSvcTp"
            />
          </div>
        </div>
        <!-- /Left Tree Layer -->

        <!-- Right Layer -->
        <!-- 서비스코드 설정 -->
        <div class="layout-cut-right tree-box02 fr">
          <div class="page-sub-box pad_top20">
            <EspTreeGrid
              :tree-grid="treeGrid"
              :ref="treeGrid.refName"
              @init-new-row="onInitNewRowIvrSvc"
              @onEditCanceling="onEditCancelingIvrSvc"
              @row-saved="onSavedIvrSvc"
              @row-removed="onRowRemovedIvrSvc"
            />
          </div>
        </div>
        <!-- /Right Layer -->
      </div>
    </div>
    <!-- /Main Layer -->

    <!-- 서비스템플릿 설정 -->
    <ModalSvcTp
      v-if="modal.ivrSvcTp.visible"
      :is-open="modal.ivrSvcTp.visible"
      :svctp-data="modal.ivrSvcTp.data"
      :maxOrd="modal.maxOrd"
      @saveModal="onSaveModal('ivrSvcTp')"
      @closeModal="onCloseModal('ivrSvcTp')"
    />

  </div>
</template>

<script>
import {DxPopup, DxToolbarItem} from 'devextreme-vue/popup';
import {DxPopover} from 'devextreme-vue/popover';
import {DxColumn, DxSelection, DxSorting, DxRequiredRule, DxLookup} from 'devextreme-vue/data-grid';
import {DxSearchPanel, DxRowDragging, DxEditing, DxScrolling, DxFilterRow} from 'devextreme-vue/tree-list';
import {DxButton} from 'devextreme-vue/button';
import {DxSelectBox} from 'devextreme-vue/select-box';
import DxDropDownButton from 'devextreme-vue/drop-down-button';

import {isSuccess} from "@/plugins/common-lib";
import {DxCheckBox} from "devextreme-vue/check-box";
import EspTreeGrid from "../../../../components/devextreme/esp-dx-tree-grid.vue";
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid.vue";
import ModalSvcTp from '@/pages/cc/ivr/service-templates/modal-svc-tp.vue';

export default {
  components: {
    ModalSvcTp,
    EspDxDataGrid,
    EspTreeGrid,
    DxCheckBox,
    DxSearchPanel,
    DxRowDragging,
    DxPopup,
    DxPopover,
    DxToolbarItem,
    DxColumn,
    DxSelection,
    DxSorting,
    DxRequiredRule,
    DxLookup,
    DxEditing,
    DxScrolling,
    DxFilterRow,
    DxButton,
    DxSelectBox,
    DxDropDownButton,
  },
  props: {},
  watch: {},
  data() {
    return {
      stylingMode: 'outlined',
      modal: {
        ivrSvcTp: {
          visible: false,
          data: {},
          maxOrd: 0,                // 순서 조정시 사용할 max 값
        },
      },
      originSvcTpList: [], // 서비스템플릿 원본 리스트
      isReordered: false, //순서여부(삭제 확인)
      clickedRow: [], //서비스템플릿 클릭한 row
      clickedRowIndex: undefined, //서비스템플릿 클릭한 row index(삭제 확인)
      checkedViewFl: true, //사용중인 템플릿만 보기 체크박스
      childrenCntList: [], // 순서값 계산을 위한 변수(자식 Cnt값)
      dataGrid: { //서비스템플릿 그리드
        callApi: "CALL_CC_API",
        refName: 'svcTpGrid',
        keyExpr: 'id',
        allowColumnResizing: true, //컬럼 사이즈 허용
        showBorders: false, //border 유무
        showColumnHeaders: true, //컬럼 헤더 유무
        showColumnLines: false, //컬럼 세로선 유무
        showRowLines: true, //컬럼 가로선 유무
        rowAlternationEnabled: false,
        dataSource: [],
        apiActionNm: {
          // merge: 'SERVICE_TEMPLATE_LIST_MERGE',
          // delete: 'SERVICE_TEMPLATE_LIST_DELETE',
        },
        showActionButtons: {
          select: false,
          copy: false,
          update: false,
          delete: false,
          customButtons: [
            {
              widget: 'dxButton',
              options: {
                icon: '',
                text: this.$_lang('COMPONENTS.ADD', { defaultValue: '추가' }),
                elementAttr: {class: 'btn_XS default filled add1'},
                width: 60,
                height: 30,
                onClick: () => {
                  this.onOpenModal('ivrSvcTp'); //서비스코드 설정 팝업 오픈
                },
              },
              location: 'before',
            },
            {
              widget: 'dxButton',
              options: {
                icon: '',
                text: this.$_lang('COMPONENTS.EDIT', { defaultValue: '수정' }),
                elementAttr: {class: 'btn_XS default filled add1'},
                width: 60,
                height: 30,
                onClick: () => {
                  if( this.clickedRow.length === 0 ){
                    return this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
                  }
                  this.onOpenModal('ivrSvcTp', this.clickedRow); //서비스코드 설정 팝업 오픈
                },
              },
              location: 'before',
            },
            {
              widget: 'dxButton',
              options: {
                icon: '',
                text: this.$_lang('COMPONENTS.DELETE', { defaultValue: '삭제' }),
                elementAttr: {class: 'btn_XS white light_filled trash'},
                width: 60,
                height: 30,
                onClick: () => {
                  this.deleteTemplate();
                },
              },
              location: 'before',
            },
          ], // 그리드 커스텀 버튼 생성
        },
        grouping: {
          contextMenuEnabled: false,
          autoExpandAll: false,
          allowCollapsing: true,
          expandMode: 'rowClick' // rowClick or buttonClick
        },
        groupPanel: {
          visible: false,
        },
        columnChooser: {
          enabled: false, // 컬럼 Chooser 버튼 사용유무
        },
        loadPanel: {
          enabled: true, // 로딩바 표시 유무
        },
        sorting: {
          mode: 'multiple' // single multiple
        },
        // scrolling: { // 미사용시 주석처리
        //     mode: 'virtual' //스크롤 모드 : ['infinite', 'standard', 'virtual']
        // },
        remoteOperations: { // 서버사이드 여부
          filtering: false,
          sorting: false,
          grouping: false,
          paging: false,
        },
        paging: { // scrolling 미사용시만 적용됨
          enabled: false,
          pageSize: 10,
          pageIndex: 0 // 시작페이지
        },
        pager: {
          visible: false, //페이저 표시 여부
          showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
          allowedPageSizes: [],
          displayMode: "compact", //표시 모드 : ['full', 'compact']
          showInfo: false, //페이지 정보 표시 여부 : full인 경우만 사용 가능
          showNavigationButtons: false //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
        },
        filterRow: {
          visible: true,
        },
        headerFilter: {
          visible: false,
        },
        editing: {
          allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
          allowDeleting: false,
          allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
          mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
          startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
          selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
        },
        customEvent: {
          // saving: true,
          rowClick: true,
        },
        selecting: {
          mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
          selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
          showCheckBoxesMode: 'onClick', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
        },
        columns: [
          {
            caption: 'ID',
            dataField: 'id',
            width: 70,
            height: 40,
            alignment: 'center', // left center right
            visible: false,
            allowEditing: false,
            sortOrder: 'none', // acs desc none
            allowHeaderFiltering: false,
            fixed: false, // 컬럼 fix 시 사용
            fixedPosition: 'left', // left or right
          },
          {
            caption: '서비스템플릿',
            i18n: 'CC.WORD.SVC_TP_TITLE',
            dataField: 'svctpNm',
            height: 40,
            alignment: 'center',
            visible: true,
            allowEditing: true,
            sortOrder: 'none',
            allowHeaderFiltering: false,
            allowGrouping: false,
          },
          {
            caption: '설명',
            i18n: 'CC.WORD.DESCRIPTION',
            dataField: 'description',
            width: 120,
            height: 40,
            alignment: 'center',
            visible: false,
            allowEditing: true,
            sortOrder: 'none',
            allowHeaderFiltering: false,
            allowGrouping: false,
          },
          {
            caption: '순서',
            i18n: 'COMPONENTS.ORDER',
            dataField: 'svctpOrd',
            width: 120,
            height: 40,
            alignment: 'center',
            visible: false,
            allowEditing: true,
            sortOrder: 'none',
            allowHeaderFiltering: false,
            allowGrouping: false,
          },
          {
            caption: '사용여부',
            i18n: 'COMPONENTS.USE_STATUS',
            dataField: 'viewFl',
            width: 120,
            height: 40,
            alignment: 'center',
            visible: false,
            allowEditing: true,
            sortOrder: 'none',
            allowHeaderFiltering: false,
            allowGrouping: false,
          },
        ]
      },
      treeGrid: { //서비스코드
        id: 'serviceList',
        refName: 'serviceList',
        callApi: "CALL_CC_API",
        dataSource: [],
        originDataSource: [], //원본 데이터
        expandedRowKeys: [],
        selectedRowkeys: [],
        apiActionNm: {
          // select: "CC_IVR_DNIS_LIST",
          merge: "CC_IVR_SVC_MERGE",
          delete: "CC_IVR_SVC_DELETE"
        },
        focusedRowKey: null,
        showColumnHeaders: true,
        showRowLines: true,
        showColumnLines: true,
        showBorders: false,
        columnAutoWidth: true,
        keyExpr: "id",
        sort: "+servicecodeOrd",
        sortKey: "servicecodeOrd",
        parentIdExpr: "parentId",
        width: null,
        height: null,
        searchPanel: {
          visible: false,
          placeholder: "",
          searchVisibleColumnsOnly: true,
          width: 200
        },
        filterRow: {
          visible: true
        },
        editing: {
          allowAdding: true,
          allowUpdating: true,
          useIcons: true,
          mode: "batch"
        },
        selection: {
          recursive: true
        },
        rowDragging: {
          allowDropInsideItem: false,
          allowReordering: true,
          showDragIcons: true
        },
        saveButtonId: 'addRootBtn',
        columns: [
          {
            caption: "id",
            dataField: "id",
            allowSorting: false,
            alignment: "left",
            visible: false,
            allowEditing: false,
          },
          {
            caption: "순서",
            dataField: "servicecodeOrd",
            sortOrder: "asc",
            allowSorting: false,
            alignment: "left",
            visible: false,
            allowEditing: false,
          },
          {
            caption: "서비스코드명",
            i18n: "CC.WORD.SERVICECODE_NM",
            dataField: "servicecodeNm",
            allowSorting: false,
            alignment: "left",
            allowEditing: true,
            validationRules: [{type: "required", message: "서비스코드명 입력은 필수입니다"}]
          },
          {
            caption: "서비스코드",
            i18n: "CC.WORD.SERVICECODE",
            dataField: "servicecode",
            allowSorting: false,
            alignment: "center",
            allowEditing: true,
            validationRules: [{type: "required", message: "서비스코드 입력은 필수입니다"}]
          },
          {
            caption: "단계",
            i18n: "CC.COMPONENTS.STEP",
            dataField: "depth",
            allowSorting: false,
            alignment: "center",
            allowEditing: false,
          },
          {
            caption: "공지여부",
            i18n: "CC.WORD.NOTICE_FLAG",
            dataField: "noticeFl",
            allowSorting: false,
            alignment: "center",
            allowEditing: true,
            lookup: {
              dataSource: this.$_enums.common.stringUsedFlag.values,
              valueExpr: "value",
              displayExpr: "label"
            }
          },
          {
            caption: "설명",
            i18n: "CC.WORD.DESCRIPTION",
            dataField: "description",
            allowSorting: false,
            alignment: "left",
            allowEditing: true,
          },
          {
            caption: "사용여부",
            i18n: "COMPONENTS.USE_STATUS",
            dataField: "viewFl",
            allowSorting: false,
            alignment: "center",
            allowEditing: true,
            lookup: {
              dataSource: this.$_enums.common.stringUsedFlag.values,
              valueExpr: "value",
              displayExpr: "label"
            }
          },
          {
            caption: "삭제",
            i18n: "COMPONENTS.DELETE",
            cellTemplate: "removeTemplate",
            alignment: "center",
            allowEditing: false,
            visible: true,
            width: 50,
          },
        ]
      },
    }
  },
  computed: {},

  methods: {
    /** @description: 팝업 열기
     * @param settingType 설정 타입(notice, ibg, setting)
     * @param settingData 모달로 넘길 설정 데이터 */
    onOpenModal(settingType, settingData) {
      this.modal[settingType].visible = true;
      if( settingData ){  //수정시에 설정
        this.modal[settingType].data = settingData;
      }else {
        this.modal[settingType].data = {};
      }
    },
    /**
     * @description : 팝업 저장
     * @param settingType : 설정 타입(notice, ibg, setting)
     */
    onSaveModal(settingType) {
      this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));

      this.selectIvrSvcTp(); //서비스템플릿 조회

      this.modal[settingType].visible = false;
      this.modal[settingType].data = {};
    },
    /**
     * @description : 팝업 닫기
     * @param settingType : 설정 타입(notice, ibg, setting)
     */
    onCloseModal(settingType) {
      this.modal[settingType].visible = false;
      this.modal[settingType].data = {};
    },
    /** @description : 서비스 템플릿 데이터 조회 */
    async selectIvrSvcTp(sort = "+svctpOrd") {
      const params = {sort};
      const payload = {
        actionName: 'CC_IVR_SVC_TP_LIST',
        data: params,
        useErrorPopup: true,
      }
      const res = await this.CALL_CC_API(payload);
      if (isSuccess) {
        const data = res.data.data[0].content;
        this.dataGrid.dataSource = data.filter(d => d.viewFl === 'Y');
        this.originSvcTpList = data;
        this.modal.maxOrd = data.length;
      }
    },
    /** @description: 사용중인 템플릿만 보기 변경 이벤트 */
    onChangedSvcTpViewFl(e) {
      this.checkedViewFl = e.value;
      if (e.value) {
        this.dataGrid.dataSource = this.dataGrid.dataSource.filter(d => d.viewFl === 'Y');
      } else {
        this.dataGrid.dataSource = this.originSvcTpList;
      }
    },
    /** @description : 서비스템플릿 목록 로우 클릭 이벤트 */
    async onClickRowSvcTp(row) {
      this.clickedRow = row.data;
      this.clickedRowIndex = row.rowIndex;

      this.selectIvrSvc(this.clickedRow.id); //서비스코드 조회
    },
    /**  @description: 서비스 템플릿 삭제 */
    async deleteTemplate() {

      //DNIS 설정 여부 확인
      let checkIvrDnisByTemplate = false;
      let payload = {
        actionName: 'CC_IVR_DNIS_LIST',
        data: { svctpId: this.clickedRow.id },
        useErrorPopup: true,
      }

      let res = await this.CALL_CC_API(payload);
      if( isSuccess(res) ){
        if( res.data.data.length > 0 ){
          checkIvrDnisByTemplate = true;
        }
      }

      if (checkIvrDnisByTemplate) {
        return this.$_Toast('IVR Dnis에 할당된 템플릿은 삭제할 수 없습니다.');
      } else {
        const data = this.$refs[this.dataGrid.refName].selectedRowsData;
        let deletedIds = data.map(d => d.id);
        let msgContents;

        if (deletedIds.length > 0) {
          msgContents = `선택한 데이터를 삭제하시겠습니까? <br> 삭제를 진행할 경우 하위 항목도 삭제됩니다.`;
        } else {
          msgContents = `선택한 데이터를 삭제하시겠습니까?`;
        }

        if (await this.$_Confirm(`템플릿에 할당 된 서비스코드 정보도 삭제됩니다. 삭제하시겠습니까?`)) {
          let payload = {
            actionName: 'CC_IVR_SVC_TP_DELETE',
            data: {data: deletedIds},
            useErrorPopup: true,
          }

          let res = await this.CALL_CC_API(payload);
          if (isSuccess(res)) {
            this.$_Toast('템플릿 정보가 삭제되었습니다.');
            this.initPage();
          }
        }
      }
    },
    /** @description: 서비스코드 조회 */
    async selectIvrSvc(svctpId) {
      const params = { svctpId, sort: '+servicecodeOrd' };
      const payload = {
        actionName: 'CC_IVR_SVC_LIST',
        data: params,
        useErrorPopup: true,
      }
      const res = await this.CALL_CC_API(payload);
      if (isSuccess) {
        const data = res.data.data;
        this.treeGrid.dataSource = data.filter(d => d.viewFl === 'Y');
        this.treeGrid.originDataSource = data;
      }
    },
    /** @description: 서비스코드 행 추가시 */
    onInitNewRowIvrSvc(e) {
      e.component.expandRow(78);
      e.component.refresh();
      e.data.svctpId = this.clickedRow.id;
      e.data.noticeFl = 'N';
      e.data.viewFl = 'Y';
      e.data.servicecodeOrd = 1;
      e.data.depth = 1;

      if (e.data.parentId !== -1) {
        let parentNode = e.component.getVisibleRows().find(row => row.data.id === e.data.parentId);
        let cnt = 1;
        cnt += parentNode.node.children.length;
        e.data.depth += parentNode.data.depth;

        const foundIndex = this.childrenCntList.findIndex(item => item.id === e.data.parentId);
        if (foundIndex !== -1) {
          cnt += this.childrenCntList[foundIndex].cnt++;
        } else {
          this.childrenCntList.push({id: e.data.parentId, cnt: 1});
        }
        e.data.servicecodeOrd = cnt;

      } else {
        e.data.servicecodeOrd = e.component.getVisibleRows().filter(row => (row.data.parentId === null || row.data.parentId === -1)).length + 1
      }
    },
    /** @description: 서비스코드 그리드 취소 이벤트 */
    async onEditCancelingIvrSvc(e) {
      this.childrenCntList = [];
      this.isReordered = false;
      /*await this.selectServiceTemplates(this.treeGrid.currentSort).then(res => {
        let checked = {};
        checked = {value: this.checkBoxValue};
        this.onChangedSvcTpViewFl(checked);
        if (this.clickedRowIndex !== undefined && this.clickedRowIndex !== -1) {
          this.treeGrid.dataSource = this.treeGrid.templateList[this.clickedRowIndex].ivrSvc;
        }
      });
      document.querySelector('#addRootBtn').classList.add('dx-state-disabled');

       */
    },
    /** @description: 서비스코드 저장 */
    onSavedIvrSvc() {
      this.selectIvrSvc(this.clickedRow.id);
    },
    /** @description: 서비스코드 삭제 */
    onRowRemovedIvrSvc() {
      this.selectIvrSvc(this.clickedRow.id);
    },
    /** @description : 페이지 인입시 메서드 */
    async initPage() {
      await this.selectIvrSvcTp(); //서비스템플릿 조회
      this.treeGrid.dataSource = [];
      this.treeGrid.originDataSource = [];
      this.clickedRow = [];
    },

    /* @desc: 순서 저장 */
    onSaveSort() {
      if (this.isReordered !== true) {
        return this.$_Toast('순서가 변경된 내역이 없습니다.');
      }

      this.savaTreeSorting(this.treeGrid.dataSource);
    },

    async savaTreeSorting(dataList) {
      let data = dataList;
      let payload;

      payload = {
        actionName: 'CC_IVR_SVC_MERGE',
        data: {data: data},
        loading: true,
      };

      if (await this.$_Confirm('현재 순서를 저장하시겠습니까?')) {
        const res = await this.CALL_CC_API(payload);
        if (isSuccess(res)) {
          if (await this.$_Toast('적용되었습니다')) {
            this.$_setSessionStorageMenu(); //메뉴 sessionStorage 재설정
            this.isReordered = false;
          }
        }
      }
    },

    selectChildrenById(arr, key) {
      const result = this.findIdAndChildren(arr, key);
      return result ? result : {id: key, childs: []};
    },

    findIdAndChildren(arr, key) {
      let output = null;

      arr.forEach(o => {
        if (o.data.id === key) {
          output = {
            id: o.data.id,
            childs: (o.children && this.getArrayOfChildren(o.children)) || [],
          };
        } else if (!output && o.children && o.children.length) {
          const childOutput = this.findIdAndChildren(o.children, key);
          if (childOutput) {
            output = {
              id: childOutput.id,
              childs: childOutput.childs,
            };
          }
        }
      });

      return output;
    },

    getArrayOfChildren(arr) {
      let existingChildren = [];
      arr.forEach(o => {
        existingChildren.push(o.data.id);
        o.children && existingChildren.push(...this.getArrayOfChildren(o.children));
      });

      return existingChildren;
    },

    onDragChange(e) {
      const visibleRows = e.component.getVisibleRows();
      const sourceNode = e.component.getNodeByKey(e.itemData.id);
      let targetNode = visibleRows[e.toIndex].node;

      if (sourceNode.data.parentId !== targetNode.data.parentId) {
        e.cancel = true;
      }
    },
    onReorderRow(e) {
      let vm = this;
      const visibleRows = e.component.getVisibleRows();
      const toDataId = visibleRows[e.toIndex].data.id;
      let ordIndex = 1;
      let reorderRows;
      if (e.itemData.parentId !== null) {
        reorderRows = visibleRows.find(row => row.data.id === e.itemData.parentId).node.children;
      } else {
        reorderRows = visibleRows.filter(row => row.data.parentId === null);
      }
      const fromData = reorderRows.find(row => row.data.id === e.itemData.id);
      const fromDataIndex = reorderRows.findIndex(row => row.data.id === e.itemData.id);
      const toDataIndex = reorderRows.findIndex(row => row.data.id === toDataId);
      reorderRows.splice(fromDataIndex, 1);
      reorderRows.splice(toDataIndex, 0, fromData);

      reorderRows.forEach(child => {
        child.data.servicecodeOrd = ordIndex++;
      });

      e.component.refresh();

      this.isReordered = true;
      document.querySelector('#addRootBtn').classList.remove('dx-state-disabled');
    },






    /* @desc: response 체크 중복되는 로직 제거를 위해 사용 */
    async checkApiResponse(response, message) {
      if (response.status === 200) {
        if (response.data.header.resCode === "success") {
          if (this.$_Toast(message)) {
            await this.selectServiceTemplates(this.treeGrid.currentSort).then(res => {
              let checked = {};
              checked = {value: this.checkBoxValue};
              this.onChangedSvcTpViewFl(checked);
              this.clickedRowIndex = undefined;
              this.treeGrid.dataSource = [];
              this.$refs['templateList'].instance.deselectAll();
              document.querySelector('#addRootBtn').classList.add('dx-state-disabled');
            });
          }
        } else {
          this.$_Toast('작업 실패');
        }
      }
    },
    /* @description : 템플릿 데이터 조회 */
    async selectServiceTemplates(sort = "-svctpOrd") {
      const params = {sort};
      const payload = {
        actionName: 'CC_IVR_SVC_TP_LIST',
        data: params,
        useErrorPopup: true,
      }
      const res = await this.CALL_CC_API(payload);
      if (isSuccess) {
        const data = res.data.data[0].content;
        this.dataGrid.dataSource = data.filter(d => d.viewFl === 'Y');
        this.treeGrid.originTemplateList = data;
        this.modal.maxOrd = data.length;
      }
    },
  },
  created() {
    this.$_styleControl('service-templates-style', false);
  },
  mounted() {
    this.initPage();
  },
  beforeDestroy() {
    this.$_styleControl('service-templates-style', true);
  },
}
</script>

<style lang="scss" scoped>
.selectDiv {
  padding-top: 10px;
}

::v-deep {
  .dx-datagrid-header-panel {
    padding: 10px 0;
  }
}

.tree-box01 {
  width: 25%;
  padding-right: 20px;
  margin-right: 0px;
}

.tree-box02 {
  width: 75%;
  height: 100%;
}

.dx-treelist::v-deep .dx-treelist-header-panel {
  padding: 0px;
}
</style>