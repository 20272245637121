var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',[_c('DxDataGrid',{ref:"vacationUseGrid",staticClass:"grid-box",attrs:{"key-expr":"id","data-source":_vm.dataGrid.dataSource,"show-borders":false,"show-column-headers":true,"show-column-lines":true,"show-row-lines":true,"row-alternation-enabled":false,"hover-state-enabled":true,"word-wrap-enabled":true,"no-data-text":this.$_lang('CMN_NO_DATA')}},[_c('DxLoadPanel',{attrs:{"enabled":true}}),_c('DxScrolling',{attrs:{"mode":"standard"}}),_c('DxHeaderFilter',{attrs:{"visible":true}}),_c('DxFilterRow',{attrs:{"visible":true}}),_c('DxColumn',{attrs:{"caption":"휴가구분","data-field":"vacationTypePtCd","allow-editing":false,"allow-sorting":true,"alignment":"center","visible":true,"calculate-sort-value":data => _vm.calculateSortValue(data, 'vacationTypePtCd')}},[_c('DxLookup',{attrs:{"data-source":_vm.codes.vacationTypePtCd.dataSource,"value-expr":"codeId","display-expr":"codeNm"}})],1),_c('DxColumn',{attrs:{"caption":"휴가구분상세","data-field":"vacationTypeCd","allow-editing":false,"allow-sorting":true,"alignment":"center","visible":true,"calculate-sort-value":data => _vm.calculateSortValue(data, 'vacationTypeCd')}},[_c('DxLookup',{attrs:{"data-source":_vm.codes.vacationTypeCd.dataSource,"value-expr":"codeId","display-expr":"codeNm"}})],1),_c('DxColumn',{attrs:{"caption":"휴가일자","data-field":"vacationYmd","allow-editing":false,"allow-header-filtering":false,"allow-sorting":true,"alignment":"center","visible":true,"width":"180","calculate-display-value":rowData =>
          `${_vm.formatDate(rowData.vacationStartYmd, 'YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD')}
                   ~ 
                  ${_vm.formatDate(rowData.vacationEndYmd, 'YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD')}`,"sort-order":'asc'}}),_c('DxColumn',{attrs:{"caption":"휴가시작시간","data-field":"vacationStartTime","allow-editing":false,"allow-header-filtering":false,"allow-sorting":true,"alignment":"center","visible":true,"calculate-display-value":rowData => {
          return _vm.isEmpty(rowData.vacationStartTime) ? '' : _vm.formatDate(rowData.vacationStartTime, 'HHmm', 'HH:mm');
        }}}),_c('DxColumn',{attrs:{"caption":"휴가종료시간","data-field":"vacationEndTime","allow-editing":false,"allow-header-filtering":false,"allow-sorting":true,"alignment":"center","visible":true,"calculate-display-value":rowData => {
          return _vm.isEmpty(rowData.vacationEndTime) ? '' : _vm.formatDate(rowData.vacationEndTime, 'HHmm', 'HH:mm');
        }}}),_c('DxColumn',{attrs:{"caption":"휴가차감일수","data-field":"vacationDayoff","allow-editing":false,"allow-sorting":true,"alignment":"center","visible":true}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }