<template>
  <div class="page-sub-box">
    <div class="locker_setting_list sub_new_style01 sub_ui_box1">
      <Search
        codeKey="null"
        :customSearchList="searchType.list"
        :searchObj="searchType.obj"
        @change-search-type="$_changeSearchType"
        @onSearchClick="searchData($event)"
      >
      </Search>
    </div>

    <esp-dx-data-grid :data-grid="dataGrid" ref="endUserGrid" />
    <DxPopup
      :show-title="true"
      :title="modal.title"
      :width="modal.width"
      :height="modal.height"
      :drag-enabled="true"
      :resize-enabled="true"
      :show-close-button="true"
      :hide-on-outside-click="false"
      :visible="modal.isOpened"
      @hiding="isOpenModal(false)"
    >
      <ModalSmartCopy
        :isOpened="this.modal.isOpened"
        :rowInfo="this.modal.rowInfo"
        :devicePoolList="this.devicePoolList"
        :modelList="this.modelList"
        @closeModal="closeModal"
      ></ModalSmartCopy>
    </DxPopup>
  </div>
</template>

<script>
  import Search from '@/components/common/search.vue';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { DxButton } from 'devextreme-vue/button';
  import { DxCheckBox } from 'devextreme-vue/check-box';
  import { isSuccess } from '@/plugins/common-lib';
  import { DxPopup } from 'devextreme-vue/popup';
  import ModalSmartCopy from './modal-smartcopy.vue';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: {
      EspDxDataGrid,
      DxSelectBox,
      DxButton,
      Search,
      DxCheckBox,
      DxPopup,
      ModalSmartCopy,
    },
    data() {
      return {
        config: {
          pageSetting: {
            //pageData pageSetting 관련
            config: {},
          },
          gradeList: null,
        },
        modal: {
          isOpened: false,
          rowInfo: {},
          title: 'Smart Copy',
          width: '60%',
          height: '550',
        },
        stylingMode: 'outlined', //outlined, underlined, filled
        searchType: {
          obj: {
            searchTexts: {
              value: '',
            },
          },
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            cm: null,
            model: null,
            status: null,
          },
          paramsData: null,
        },
        searchType: {
          list: [
            {
              codeNm: this.$_lang('UC.WORD.USER_NO', { defaultValue: '사번' }),
              codeValue: 'userid',
            },
            {
              codeNm: this.$_lang('UC.WORD.NUMBER', { defaultValue: '내선번호' }),
              codeValue: 'telephonenumber',
            },
            {
              codeNm: this.$_lang('UC.WORD.DISPLAY_NAME', { defaultValue: '화면표시' }),
              codeValue: 'displayname',
            },
            {
              codeNm: this.$_lang('COMPONENTS.DEPT', { defaultValue: '부서' }),
              codeValue: 'department',
            },
          ],
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            result: null,
          },

          paramsData: null,
        },
        cmList: [],
        devicePoolList: [],
        modelList: [],
        dataGrid: {
          excel: {
            title: '최종사용자 목록',
            // title: this.$_lang('UC.WORD.DEVICE_INFO', { defaultValue: '전화 정보' }),
          },
          callApi: 'CALL_EUC_API',
          refName: 'endUserGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          // height: '605', // 주석처리시 100%
          height: 'calc(100vh - 350px)', // 주석처리시 100%
          apiActionNm: {
            update: 'EUC_ENDUSER_UPDATE',
          },
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
            cellPrepared: true,
          },
          showActionButtons: {
            excel: true, // 엑셀 버튼
            customButtons: [],
          },
          isDuplicateConfigKey: false, //설정키 중복 체크
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: false, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '교환기',
              i18n: 'UC.WORD.EXCHANGER',
              dataField: 'cmNm',
              width: 100,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              calculateDisplayCellValue: rowData => {
                if (rowData?.cmNm) {
                  return this.getCmNm(rowData.cmNm);
                }
                if (rowData.cmNm == null || rowData.cmNm == 'null') {
                  return '';
                }
              },
            },
            {
              caption: '사번',
              i18n: 'UC.WORD.USER_NO',
              dataField: 'userid',
              width: 120,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '이름',
              i18n: 'UC.WORD.FIRST_NAME',
              dataField: 'firstname',
              width: 120,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '중간이름',
              i18n: 'UC.WORD.MIDDLE_NAME',
              dataField: 'middlename',
              width: 120,
              height: 40,
              alignment: 'center',
              visible: false,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '성',
              i18n: 'UC.WORD.LAST_NAME',
              dataField: 'lastname',
              width: 120,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '화면표시',
              i18n: 'UC.WORD.DISPLAY_NAME',
              dataField: 'displayname',
              // width: 150,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
            },
            {
              caption: '부서',
              i18n: 'UC.WORD.DEPARTMENT',
              dataField: 'department',
              // width: 150,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
            },

            {
              caption: '내선번호',
              i18n: 'UC.WORD.NUMBER',
              dataField: 'telephonenumber',
              width: 120,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
            },
            {
              caption: 'JABBER',
              i18n: 'UC.WORD.CSF',
              dataField: 'csf',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              cellTemplate: (container, options) => {
                const divTag = document.createElement('div');
                divTag.classList.add('fl');
                const checkbox = new DxCheckBox({
                  propsData: {
                    value: options.data.csfFlag,
                    disabled: false,
                    onValueChanged: e => {
                      this.changeCheckBox(e, options.data, 'csf');
                    },
                  },
                });
                checkbox.$mount();
                divTag.append(checkbox.$el);
                container.append(divTag);
                container.append(options.value != null ? options.value : '');
              },
            },
            {
              caption: '안드로이드',
              i18n: 'UC.WORD.BOT',
              dataField: 'bot',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              cellTemplate: (container, options) => {
                const divTag = document.createElement('div');
                divTag.classList.add('fl');
                const checkbox = new DxCheckBox({
                  propsData: {
                    value: options.data.botFlag,
                    disabled: false,
                    onValueChanged: e => {
                      this.changeCheckBox(e, options.data, 'bot');
                    },
                  },
                });
                checkbox.$mount();
                divTag.append(checkbox.$el);
                container.append(divTag);
                container.append(options.value != null ? options.value : '');
              },
            },
            {
              caption: '아이폰',
              i18n: 'UC.WORD.TCT',
              dataField: 'tct',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              cellTemplate: (container, options) => {
                const divTag = document.createElement('div');
                divTag.classList.add('fl');
                const checkbox = new DxCheckBox({
                  propsData: {
                    value: options.data.tctFlag,
                    disabled: false,
                    onValueChanged: e => {
                      this.changeCheckBox(e, options.data, 'tct');
                    },
                  },
                });
                checkbox.$mount();
                divTag.append(checkbox.$el);
                container.append(divTag);
                container.append(options.value != null ? options.value : '');
              },
            },
            {
              caption: '태블릿',
              i18n: 'UC.WORD.TAB',
              dataField: 'tab',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              cellTemplate: (container, options) => {
                const divTag = document.createElement('div');
                divTag.classList.add('fl');
                const checkbox = new DxCheckBox({
                  propsData: {
                    value: options.data.tabFlag,
                    disabled: false,
                    onValueChanged: e => {
                      this.changeCheckBox(e, options.data, 'tab');
                    },
                  },
                });
                checkbox.$mount();
                divTag.append(checkbox.$el);
                container.append(divTag);
                container.append(options.value != null ? options.value : '');
              },
            },
            {
              caption: '스마트카피',
              i18n: 'UC.WORD.CREATE',
              dataField: 'smartcopy',
              width: 100,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              allowExporting: false,
              cellTemplate: async (container, options) => {
                const imgTag = document.createElement('img');
                const imgData = this.$_getAttachFileURL(null, 'supercopy.gif');
                imgTag.setAttribute('src', imgData);
                imgTag.style.cursor = 'pointer';
                imgTag.addEventListener('click', async () => {
                  this.isOpenModal(true, options.data);
                });
                container.append(imgTag);
              },
            },
            // {
            // 	caption: '등록일',
            // 	i18n: 'COMPONENTS.REGISTRATION_DATE',
            // 	dataField: 'regDt',
            // 	// width: 150,
            // 	height: 40,
            // 	alignment: 'center',
            // 	visible: true,
            // 	allowEditing: false,
            // 	sortOrder: 'none',
            // 	allowHeaderFiltering: false,
            // 	calculateCellValue: row => this.formatDt(row?.regDt),
            // },
          ],
        },
      };
    },
    computed: {},
    methods: {
      /** @description 커스텀 검색 이벤트 */
      searchData(paramsData) {
        if (paramsData) {
          this.searchType.paramsData = { ...this.searchType.paramsData, ...paramsData };
        } else {
          const codeValues = this.searchType.list.map(d => d.codeValue);
          if (this.searchType.paramsData != null) {
            if (Object.keys(this.searchType.paramsData).length > 0) {
              Object.keys(this.searchType.paramsData).forEach(key => {
                if (codeValues.includes(key)) {
                  delete this.searchType.paramsData[key];
                }
              });
            }
          }
        }
        this.selectDataList();
      },

      /** @description : 라이프사이클 computed에서 this data를 변경하기 위한 메서드 */
      setCustomTypes(key, value) {
        this.searchType.customTypes[key] = value;
      },
      /** @description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {
        this.dataGrid.showActionButtons.customButtons = this.getCustomButtons();
      },
      /** @description : 날짜 형식 변환 메서드 */
      formatDt(date) {
        if (date) {
          return this.$_commonlib.formatDate(date, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
        }
      },
      getCustomButtons() {
        const buttons = [
          {
            widget: 'dxButton',
            options: {
              icon: '',
              // text: this.$_lang('UC.WORD.SYNC'),
              text: '최종사용자 동기화',
              elementAttr: { class: 'btn_XS default filled' },
              // width: 90,
              height: 30,
              onClick: () => {
                this.onSyncDevice();
              },
            },
            location: 'before',
          },
        ];
        return buttons;
      },
      /** @description : 라이프사이클 mounted시 호출되는 메서드 */
      async mountData() {
        await this.selectCmList();
        await this.selectDevicePool();
        await this.selectModelList();
        await this.selectDataList();
      },
      /** @description : 데이터 검색 메서드 */
      async selectDataList(sort = 'regDt,id') {
        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);
            if (!params.sort) {
              params.sort = sort;
            }
            params = { ...params, ...this.searchType.paramsData, ...this.searchType.customTypes };

            const payload = {
              actionName: 'EUC_ENDUSER_LIST_ALL',
              data: params,
              loading: false,
            };

            const rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await this.CALL_EUC_API(payload);
            if (isSuccess(res)) {
              rtnData.data = this.formatDataSource(res.data.data);
              rtnData.totalCount = res.data.header.totalCount;
            }
            return rtnData;
          },
        });
      },
      /**@description 그리드 내부 체크박스 컨트롤 하기 위한 사전 데이터 생성 메서드
       * @param {Array} 그리드 데이터
       */
      formatDataSource(dataSource) {
        if (dataSource.length > 0) {
          let rtnData = dataSource.map(data => {
            return {
              ...data,
              csfFlag: data['csf'] != null ? true : false,
              botFlag: data['bot'] != null ? true : false,
              tctFlag: data['tct'] != null ? true : false,
              tabFlag: data['tab'] != null ? true : false,
            };
          });
          return rtnData;
        } else {
          return dataSource;
        }
      },
      /**@description 교환기 목록 가져오는 메서드 */
      async selectCmList(sort = '+cmOrd') {
        const params = { sort: sort, viewFl: 'Y' };
        const payload = {
          actionName: 'EUC_CM_LIST_ALL',
          data: params,
          loading: false,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          await this.setCmName(res);
        }
      },
      /**@description 교환기 목록에서 불필요 항목 제거하는 메서드 */
      async setCmName(res) {
        const length = res.data.data?.length;
        if (length > 0) {
          this.cmList = res.data.data;
          for (let i = 0; i < this.cmList.length; i++) {
            delete this.cmList[i].id;
            delete this.cmList[i].cmIp;
            delete this.cmList[i].passwd;
            delete this.cmList[i].pinPasswd;
            delete this.cmList[i].regDt;
            delete this.cmList[i].siteId;
            delete this.cmList[i].tenantId;
            delete this.cmList[i].userId;
            delete this.cmList[i].vender;
            delete this.cmList[i].viewFl;
          }
        }
      },
      /** @description 교환기 이름 한글 변환 메서드
       *  @param {String} cm Name
       *  @returns {String} 한글명
       */
      getCmNm(cm) {
        let cmNm = '';
        for (let i = 0; i < this.cmList.length; i++) {
          if (this.cmList[i].cmNm === cm) {
            cmNm = this.cmList[i].description;
          }
        }
        if (cmNm !== '' && cmNm !== null) {
          return cmNm;
        } else {
          return '';
        }
      },

      /**@description devicepool 목록 가져오는 메서드 */
      async selectDevicePool(sort = 'regDt,id') {
        const params = { sort: sort, viewFl: 'Y', smartFl: 'Y' };
        const payload = {
          actionName: 'EUC_DEVICEPOOL_LIST_ALL',
          data: params,
          loading: false,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          if (res.data.data.length > 0) {
            this.devicePoolList = res.data.data;
          }
        }
      },

      /**@description model 목록 가져오는 메서드 */
      async selectModelList(sort = 'regDt,id') {
        const params = { sort: sort, viewFl: 'Y', smartAssignedModel: 'Y' };
        const payload = {
          actionName: 'EUC_MODEL_LIST_ALL',
          data: params,
          loading: false,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          if (res.data.data.length > 0) {
            this.modelList = res.data.data;
          }
        }
      },

      /**@description EndUser 동기화 */
      async onSyncDevice() {
        let confirm = this.$_lang('UC.MESSAGE.START_SYNC');

        if (!(await this.$_Confirm(confirm))) {
          return;
        }
        for (const item of this.cmList) {
          const params = { cmNm: item.cmNm, loginId: this.$store.getters.getLoginId };
          // const params = { cmNm: 'HQ', loginId: this.$store.getters.getLoginId };
          const payload = {
            actionName: 'EUC_ENDUSER_SYNC',
            data: params,
            loading: true,
          };

          const res = await this.CALL_EUC_API(payload);
          if (isSuccess(res)) {
            // await this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_SUCCESS', { defaultValue: '정상적으로 처리되었습니다.' }));
            await this.$refs.endUserGrid.getInstance.refresh();
          } else {
            await this.$_Msg(res.data.header.resMsg);
          }
        }
      },
      /**@description 그리드 내부 타입별 체크박스 변경 이벤트 */
      changeCheckBox(event, data, type) {
        let typeNm = type + 'Flag';
        data[typeNm] = event.value;
      },

      /**@description 팝업 Open/Close */
      isOpenModal(data, rowInfo) {
        // if (data == true && (rowInfo.telephonenumber == null || rowInfo.telephonenumber == '')) {
        // 	this.$_Msg('내선 정보가 없어 생성이 불가능합니다.');
        // 	return;
        // }
        if (data) {
          this.modal.rowInfo = rowInfo;
        }
        this.modal.isOpened = data;
      },
      /**@description 팝업 Close 콜백 */
      closeModal() {
        this.modal.isOpened = false;
        this.$refs.endUserGrid.getInstance.refresh();
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountData();
    },
  };
</script>
