import SockJS from 'sockjs-client';
import Stomp from 'webstomp-client';

export const WEBSOCKET_CONNECT = async ({ commit }, payload) => {
  let reconnectInterval = 5000; // Reconnection attempt interval in milliseconds

  const connect = () => {
    try {
      const socket = new SockJS(`${window.location.origin}/esp/ws`);
      const options = { debug: true, protocols: ['v12.stomp'] };
      const stompClient = Stomp.over(socket, options);

      commit('setStompClient', stompClient);

      stompClient.connect({}, () => {
        console.log("@ 소켓 연결 성공");
        commit('setStompClient', stompClient);

        const sessionId = stompClient.ws._transport.url.split('/')[5];
        commit('setSessionId', sessionId);

        // Handle connection loss and attempt reconnection
        stompClient.ws.onclose = () => { // ui client에서 끊어졌을 때
          console.log("@ 소켓 연결 끊김, 재연결 시도 중...");
          commit('setStompClient', null);
          setTimeout(connect, reconnectInterval); // Recursive call to attempt reconnection
        };
      }, (error) => { // 서버가 끊어졌을 때
        console.error("@ 소켓 연결 실패", error);
        setTimeout(connect, reconnectInterval); // Recursive call to attempt reconnection on failure
      });
    } catch (error) { // 세팅 값 미스로 인한 예외 발생
      console.error("@ 소켓 연결 중 예외 발생", error);
      setTimeout(connect, reconnectInterval); // Recursive call to attempt reconnection on exception
    }
  };

  connect();
};