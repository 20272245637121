<!--
  PACKAGE_NAME : src\pages\ewm\hr\agent\management\list.vue
  FILE_NAME : list
  AUTHOR : bykim
  DATE : 2023-11-28
  DESCRIPTION : 상담원관리 화면
-->
<template>
  <div>
    <div class="page-sub-box content-box">
      <div v-bind:class="leftBoxClassName" ref="leftBox">
        <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" @row-click="onRowClick" />
      </div>
      <Transition name="slide-right">
        <div v-show="showForm" v-bind:class="rightBoxClassName" ref="rightBox">
          <AgentForm
            ref="agtForm"
            class="agent-form-box"
            :auth-id-list="authIdList"
            @isSubmitSuccess="handleSubmitSuccess"
            @showFormDiv="onForm"
          />
        </div>
      </Transition>
    </div>
    <modal-agent-card ref="agentCard" />
  </div>
</template>

<script>
  import { getPastFromToday, isSuccess, formatDate } from '@/plugins/common-lib';
  import { DxButton } from 'devextreme-vue/button';
  import AgentForm from '@/pages/ewm/hr/agent/management/form.vue';
  import ModalAgentCard from './modal-agent-card.vue';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: {
      EspDxDataGrid,
      ModalAgentCard,
      AgentForm,
    },
    data() {
      return {
        showForm: false, // 입력폼 표시여부
        rightBoxClassName: 'right-box',
        leftBoxClassName: 'left-box',
        labelMode: 'outside',
        labelLocation: 'left',
        labelMinColWidth: 200,
        stylingMode: 'outlined', //outlined, underlined, filled
        searchType: {
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            dayStart: getPastFromToday(30, 'days'),
            dayEnd: getPastFromToday(0, 'days'),
          },
          paramsData: { joinDt: '', delFl: 'N' },
        },
        authIdList: [],
        dataGrid: {
          keyExpr: 'hrId', // 그리드 키값
          refName: 'agentGrid',
          callApi: 'CALL_EWM_API',
          excel: {
            title: '상담원 목록',
            autoFilterEnabled: true,
          },
          focusedRowEnabled: true, // 포커스 행 표시 유무
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          columnAutoWidth: false, // 컬럼 사이즈 자동 조절 유무
          columnMinWidth: 80, // 컬럼 최소 사이즈
          dataSource: [],
          apiActionNm: {},
          scrolling: {
            mode: 'standard',
          },
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
            cellPrepared: true,
            rowClick: true,
          },
          showActionButtons: {
            excel: true,
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '등록',
                  hint: '상담원 신규등록',
                  elementAttr: { class: 'btn_XS default filled add1' },
                  width: 60,
                  height: 30,
                  onClick: () => {
                    this.onAddData();
                  },
                },
                location: 'before',
              },
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '삭제',
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 60,
                  height: 30,
                  onClick: () => {
                    this.onDeleteData();
                  },
                },
                location: 'before',
              },
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '일괄 업로드',
                  hint: '엑셀 파일로 상담원을 일괄 등록 합니다.',
                  elementAttr: { class: 'btn_XS white light_filled' },
                  width: 100,
                  height: 30,
                  onClick: () => {
                    // TODO : 김병윤, 엑셀업로드 구현 필요
                    this.$_Msg('일괄 업로드 기능은 준비중입니다.');
                  },
                },
                location: 'before',
              },
            ],
          },
          isDuplicateConfigKey: false, //설정키 중복 체크
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false,
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: false, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: false, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '상담원정보ID',
              dataField: 'hrId',
              visible: false,
              sortOrder: 'none',
            },
            {
              caption: '부서코드',
              dataField: 'deptCdPath',
              visible: false,
              sortOrder: 'none',
            },
            {
              caption: '부서',
              dataField: 'deptNmPath',
              visible: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowFiltering: true,
              allowGrouping: false,
            },
            {
              caption: '상담원',
              dataField: 'agent',
              visible: true,
              sortOrder: 'none',
              allowHeaderFiltering: true,
              allowFiltering: true,
              allowGrouping: false,
              calculateCellValue: this.mergeAgentInfo,
            },
            {
              caption: '직급',
              dataField: 'jikgupNm',
              visible: true,
              sortOrder: 'none',
              alignment: 'center',
              allowHeaderFiltering: true,
              allowFiltering: true,
              allowGrouping: false,
            },
            {
              caption: '직위',
              dataField: 'jikweeNm',
              visible: true,
              sortOrder: 'none',
              alignment: 'center',
              allowHeaderFiltering: true,
              allowFiltering: true,
              allowGrouping: false,
            },
            {
              caption: '업무그룹',
              dataField: 'workgroupNm',
              visible: true,
              sortOrder: 'none',
              alignment: 'center',
              allowHeaderFiltering: true,
              allowFiltering: true,
              allowGrouping: false,
            },
            {
              caption: '계정권한',
              dataField: 'authNm',
              visible: true,
              sortOrder: 'none',
              alignment: 'center',
              allowHeaderFiltering: true,
              allowFiltering: true,
              allowGrouping: false,
              calculateCellValue: rowData => {
                if (rowData?.authId) {
                  const authNm = this.authIdList.find(auth => auth.id === rowData.authId)?.authNm;
                  return authNm ? authNm : '';
                }
              },
            },
            {
              width: 130,
              caption: '재직상태',
              dataField: 'userStateCd',
              visible: true,
              sortOrder: 'none',
              alignment: 'center',
              allowHeaderFiltering: true,
              allowFiltering: false,
              allowGrouping: false,
              lookup: {
                dataSource: this.$_enums.ewm.userState.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
            {
              width: 130,
              caption: '입사일자',
              dataField: 'joinDt',
              format: 'yyyy-MM-dd',
              dataType: 'date',
              visible: true,
              sortOrder: 'none',
              alignment: 'center',
              allowHeaderFiltering: true,
              allowFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '인사카드',
              dataField: 'hrDetailId',
              alignment: 'center', // left center right
              visible: true,
              width: 100,
              allowHeaderFiltering: false,
              allowFiltering: false,
              cellTemplate: (container, options) => {
                let button = new DxButton({
                  propsData: {
                    text: '',
                    icon: 'card',
                    elementAttr: { class: 'white light_filled txt_S' },
                    value: options.data,
                    onClick: () => {
                      this.$refs.agentCard.passProps(options.data.agtid, options.data.agtNm);
                    },
                  },
                });
                button.$mount();
                container.append(button.$el);
              },
            },
            {
              width: 130,
              caption: '표시여부',
              dataField: 'viewFl',
              visible: true,
              sortOrder: 'none',
              alignment: 'center',
              allowHeaderFiltering: true,
              allowFiltering: true,
              allowGrouping: false,
              lookup: {
                dataSource: this.$_enums.common.stringUsedFlag.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
              headerFilter: {
                // headerFilter (blank) 제외
                allowSearch: true,
                dataSource: data => {
                  data.dataSource.postProcess = results => {
                    results.shift();
                    return results;
                  };
                },
              },
            },
          ],
        },
      };
    },
    computed: {},
    methods: {
      /** @description : 상담사 추가  */
      onAddData() {
        this.onForm(true);
        this.$refs.agtForm.initForm(); // 입력폼 초기화
      },
      /** @description : 상담사 삭제  */
      async onDeleteData() {
        const vm = this;
        const selectedRowsData = this.$refs.agentGrid.selectedRowsData;
        if (selectedRowsData?.length) {
          if (
            await this.$_Confirm(
              this.$_lang('COMMON.MESSAGE.CMN_CFM_DELETE_SELECTED', { defaultValue: '선택한 데이터를 삭제하시겠습니까?' }),
            )
          ) {
            let agentIdArr = [];
            selectedRowsData.forEach(row => {
              agentIdArr.push(row['agtid']);
            });

            const payload = {
              actionName: 'EWM_AGENT_LIST_DELETE',
              data: { agtidList: agentIdArr },
              loading: false,
            };

            const res = await vm.CALL_EWM_API(payload);
            if (isSuccess(res)) {
              this.refreshAgentGrid();
            }
          }
        } else {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return false;
        }
      },
      /** @description : 상담사 이름 + 상담사 로그인아이디 병합  */
      mergeAgentInfo(rowData) {
        if (rowData === null || rowData === undefined) {
          return '';
        }
        return `${rowData.agtNm} [${rowData.agtid}]`;
      },
      /**
       * 상담사 데이터 그리드 데이터 설정
       *
       * @return {Promise<*[]>}
       */
      async setDataGrid() {
        let vm = this;
        this.dataGrid.dataSource = new CustomStore({
          key: 'hrId',
          async load(loadOptions) {
            let params = vm.$_getDxDataGridParam(loadOptions);
            params = { ...params, ...vm.searchType.paramsData };

            const payload = {
              actionName: 'EWM_AGENT_SEARCH',
              data: params,
              useErrorPopup: true,
            };

            let rtnData = [];
            const res = await vm.CALL_EWM_API(payload);
            if (isSuccess(res)) {
              rtnData = res.data.data;
            }
            return rtnData;
          },
        });
      },
      /** @description : 날짜 형식 변환  */
      formatDt(rowData) {
        if (rowData?.regDt) {
          return formatDate(rowData.regDt, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD');
        }
      },
      /** @description : 상담원그리드 새로고침  */
      refreshAgentGrid() {
        this.$refs.agentGrid.refreshData();
      },
      /** @description : 상담원 폼 저장 성공 후처리  */
      handleSubmitSuccess() {
        this.refreshAgentGrid();
      },
      /**
       * @description : 입력폼에 상담원 정보 전달
       * @param row gridRow
       * */
      async onRowClick(row) {
        this.onForm(true)
          .then(() => {
            this.$refs.agtForm.loadFormData(row.data);
          })
          .catch(() => {
            this.$_Msg('상담원 정보를 불러오는데 실패했습니다.');
          });
      },
      /**
       * @description : 입력폼 생성
       * @param isShow Boolean
       * @return Promise
       * **/
      async onForm(isShow) {
        if (isShow) {
          this.leftBoxClassName = 'left-box-75';
          this.rightBoxClassName = 'right-box-25';
        } else {
          this.leftBoxClassName = 'left-box';
          this.rightBoxClassName = 'right-box';
        }
        this.showForm = isShow;
      },
      /** @description : 권한 데이터 전체 조회 **/
      async getAuthIdList() {
        const payload = {
          actionName: 'AUTH_LIST',
          useErrorPopup: true,
        };

        let rtnData = [];
        const res = await this.CALL_API(payload);
        if (isSuccess(res)) {
          rtnData = res.data.data;
        }
        return rtnData;
      },
    },
    async mounted() {
      this.authIdList = await this.getAuthIdList();
      await this.setDataGrid();
    },
  };
</script>
<style lang="scss" scoped>
  .page_search_box .inner div {
    display: inline-block;
  }
  .page_search_box .inner > div:not(.box-btn-search) {
    vertical-align: middle;
    padding-right: 10px;
  }
  .page_search_box .spec-type-box {
    display: inline-block;
  }
  .page_search_box .inner .box-btn-search {
    padding-right: 15px;
  }
  .page_search_box #searchTooltip {
    padding-right: 5px;
    font-size: 22px;
    color: #808080;
  }
  .content-box {
    min-height: 800px;
    height: 800px;
  }
  .left-box {
    width: 100%;
    height: 100%;
    padding-right: 10px;
    float: left;
  }
  .left-box-75 {
    width: 75%;
    height: 100%;
    padding-right: 10px;
    float: left;
  }
  .right-box {
    width: 25%;
    height: 100%;
    visibility: hidden;
    border-left: 2px solid #dddddd;
    padding-left: 10px;
    position: relative;
    float: right;
  }
  .right-box-25 {
    width: 25%;
    height: 100%;
    border-left: 2px solid #dddddd;
    padding-left: 10px;
    position: relative;
    float: right;
  }
  .left-box {
    transition: all 0.3s ease;
  }
  .left-box-75 {
    transition: all 0.1s ease;
  }
  .slide-right-enter-active {
    transition: all 0.1s ease;
  }
  .slide-right-leave-active {
    transition: all 1ms ease;
  }
  .slide-right-enter {
    transform: translateX(100%);
    opacity: 0;
  }
  .slide-right-leave-to {
    transform: translateX(100%);
    opacity: 0;
  }
</style>
