import { render, staticRenderFns } from "./esp-dx-data-grid-v2.vue?vue&type=template&id=37b07698&scoped=true"
import script from "./esp-dx-data-grid-v2.vue?vue&type=script&lang=js"
export * from "./esp-dx-data-grid-v2.vue?vue&type=script&lang=js"
import style0 from "./esp-dx-data-grid-v2.vue?vue&type=style&index=0&id=37b07698&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37b07698",
  null
  
)

export default component.exports