var render = function render(){var _vm=this,_c=_vm._self._c;return _c('esp-dx-modal-popup',{attrs:{"option":{
    title: _vm.modal.title,
    width: _vm.modal.width,
    height: _vm.modal.height,
    minWidth: _vm.modal.minWidth,
    minHeight: _vm.modal.minHeight,
    closeOnOutsideClick: _vm.modal.closeOnOutsideClick,
    validationGroup: _vm.modal.validationGroup,
    useSaveBtnDisabled: _vm.modal.useSaveBtnDisabled,
  },"isOpen":_vm.isOpen},on:{"saveModal":_vm.handleSaveModal,"closeModal":_vm.handleCloseModal},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('table',{staticClass:"table_form line-bin"},[_c('caption',[_c('strong',[_vm._v("팝업 데모")])]),_c('colgroup',[_c('col',{staticStyle:{"width":"110px"}}),_c('col',{staticStyle:{"width":"auto"}})]),_c('tbody',[_c('tr',[_c('th',{attrs:{"scope":"row"}},[_c('label',{attrs:{"for":"label5"}},[_vm._v(" 아이디 "),_c('span',{staticClass:"icon_require"})])]),_c('td',[_c('dx-text-box',{staticClass:"mar_ri10",attrs:{"styling-mode":_vm.config.stylingMode,"width":250},model:{value:(_vm.formData.userId),callback:function ($$v) {_vm.$set(_vm.formData, "userId", $$v)},expression:"formData.userId"}},[_c('dx-validator',{attrs:{"validation-group":_vm.modal.validationGroup}},[_c('DxRequiredRule',{attrs:{"message":"아이디은 필수 항목입니다."}})],1)],1)],1)]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_c('label',{attrs:{"for":"label5"}},[_vm._v(" 이름 "),_c('span',{staticClass:"icon_require"})])]),_c('td',[_c('dx-text-box',{staticClass:"mar_ri10",attrs:{"styling-mode":_vm.config.stylingMode,"width":250},model:{value:(_vm.formData.userNm),callback:function ($$v) {_vm.$set(_vm.formData, "userNm", $$v)},expression:"formData.userNm"}},[_c('dx-validator',{attrs:{"validation-group":_vm.modal.validationGroup}},[_c('DxRequiredRule',{attrs:{"message":"이름은 필수 항목입니다."}})],1)],1)],1)])])])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }