<!--
  PACKAGE_NAME : src/pages/esp/system/tenant/list.vue
  FILE_NAME : list2
  AUTHOR : tk1ee
  DATE : 2024-09-25
  DESCRIPTION : 센터 설정
-->
<template>
  <div>
    <div class="page-sub-box locker_setting_list sub_new_style01 sub_ui_box1">
      <div class="cusmain-table-wrap">
        <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" @init-new-row="onInitNewRow" />
      </div>
    </div>
  </div>
</template>

<script>
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';
  import { isSuccess } from '@/plugins/common-lib';

  export default {
    components: {
      EspDxDataGrid,
    },
    data() {
      return {
        dataGrid: {
          refName: 'tenantGrid',
          keyExpr: 'id',
          allowColumnResizing: true, //컬럼 사이즈 허용
          allowReordering: true, //inline속성 느낌
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          hoverStateEnabled: true,
          dataSourceDefaultSortColumn: '+tenantOrd', // 주석처리하면 keyExpr 컬럼으로 sorting됨 + 오름차순 - 내림차순
          dataSource: [],
          apiActionNm: {
            merge: 'TENANT_LIST_INSERT',
          },
          customEvent: {
            initNewRow: true,
          },
          showActionButtons: {
            select: false,
            copy: false,
            delete: false,
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '삭제',
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 60,
                  height: 30,
                  onClick: () => this.onDeleteData(),
                },
                location: 'before',
              },
            ],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: true,
            grouping: false,
            paging: true,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: true,
          },
          pager: {
            visible: true, //페이저 표시 여부
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowDeleting: false,
            allowAdding: true, // 추가 버튼을 없애고 싶으면 false설정
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '순서',
              i18n: 'COMPONENTS.ORDER',
              dataField: 'tenantOrd',
              alignment: 'center', // left center right
              visible: true,
              allowEditing: true,
              sortOrder: 'none', // asc desc none
              allowHeaderFiltering: false,
              allowGrouping: false,
              fixed: true, // 컬럼 fix 시 사용
            },
            {
              caption: '사이트명',
              i18n: 'COMMON.WORD.SITE_NAME',
              dataField: 'siteId',
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              fixed: true,
              lookup: {
                dataSource: this.$store.getters.getSiteList,
                displayExpr: 'siteNm',
                valueExpr: 'id',
              },
              requiredRule: {
                message: '사이트명은 필수입니다.',
              },
            },
            {
              caption: '센터',
              i18n: 'COMMON.WORD.TENANT',
              dataField: 'tenant',
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              fixed: true,
              requiredRule: {
                message: '센터는 필수입니다.',
              },
            },
            {
              caption: '센터명',
              i18n: 'COMMON.WORD.TENANT_NAME',
              dataField: 'tenantNm',
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              fixed: true,
              requiredRule: {
                message: '센터명은 필수입니다.',
              },
            },
            {
              caption: '표시여부',
              i18n: 'COMPONENTS.VIEW_STATUS',
              dataField: 'viewFl',
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              repaintChangesOnly: true,
              fixed: true,
              lookup: {
                dataSource: this.$_enums.common.stringViewFlag.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
          ],
        },
      };
    },
    methods: {
      /** @description: 데이터 조회 메서드 */
      async selectDataList() {
        let vm = this;
        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          async load(loadOptions) {
            const params = vm.$_getDxDataGridParam(loadOptions);

            if (!params.sort) params.sort = vm.dataGrid.dataSourceDefaultSortColumn;

            const payload = {
              actionName: 'TENANT_LIST_ALL',
              data: params,
              useErrorPopup: true,
            };

            const res = await vm.CALL_API(payload);
            let rtnData = {
              data: [],
              totalCount: 0,
            };
            if (isSuccess(res)) {
              rtnData = {
                data: res.data.data,
                totalCount: res.data.header.totalCount,
              };
              vm.$refs[vm.dataGrid.refName].totalCount = rtnData.totalCount;
            }
            return rtnData;
          },
        });
      },
      /** @description : 데이터 삭제 메서드 */
      async onDeleteData() {
        let selectedRowKeys = this.$refs[this.dataGrid.refName].getInstance.getSelectedRowKeys();
        if (selectedRowKeys.length === 0) {
          return this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
        }

        if (
          await this.$_Confirm(
            this.$_lang('COMMON.MESSAGE.CMN_CFM_DELETE_SELECTED', { defaultValue: '선택한 데이터를 삭제하시겠습니까?' }),
          )
        ) {
          const payload = {
            actionName: 'TENANT_LIST_DELETE',
            data: { data: selectedRowKeys },
            loading: true,
            useErrorPopup: true,
          };

          const res = await this.CALL_API(payload);
          if (isSuccess(res)) {
            this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUC_DELETE', { defaultValue: '정상적으로 삭제되었습니다.' }));
            this.$refs[this.dataGrid.refName].refreshData();
          }
        }
      },
      /** @description: 그리드 행 추가시 초기 설정 */
      onInitNewRow(e) {
        e.data.viewFl = 'Y';
      },
    },
    mounted() {
      this.selectDataList();
    },
  };
</script>
