<template>
  <div>
    <table class="table_form line-bin fl" style="width: 100%">
      <thead style="border-top: 1px solid; border-bottom: 1px solid; border-color: rgba(229, 231, 235)">
        <tr>
          <th>JOBID</th>
          <td>{{ rowInfo.id }}</td>
          <th>DISPLAY</th>
          <td>{{ rowInfo.display }}</td>
          <th>등록일</th>
          <td>{{ formatDt(rowInfo) }}</td>
          <th>결과</th>
          <td>{{ rowInfo.result }}</td>
        </tr>
        <!-- <tr>
					
				</tr> -->
        <!-- <tr>
					<th>사유</th>
					<td>{{ this.jsonData.cause }}</td>
				</tr> -->
      </thead>
    </table>
    <!-- <div style="margin: bottom 30px" /> -->
    <table class="table_form line-bin" style="top: 30px">
      <tbody>
        <esp-dx-data-grid :data-grid="dataGrid" ref="workDetailGrid" :auto-width="true"></esp-dx-data-grid>
      </tbody>
    </table>

    <DxPopup
      :show-title="true"
      :title="modal.title"
      :width="modal.width"
      :height="modal.height"
      :drag-enabled="true"
      :resize-enabled="true"
      :show-close-button="true"
      :hide-on-outside-click="false"
      :visible="modal.isOpened"
      @hiding="isOpenModal(false)"
    >
      <template>
        <DxTextArea v-model="formatJson" :styling-mode="stylingMode" :height="550"> </DxTextArea>
        <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          :visible="true"
          :options="{
            elementAttr: {
              class: 'default filled txt_S medium',
            },
            text: '확인',
            //type: 'default',
            width: '120',
            height: '40',
            useSubmitBehavior: true,
            onClick: () => {
              isOpenModal(false);
            },
          }"
        />
      </template>
    </DxPopup>
  </div>
</template>
<script>
  import { DxColumn } from 'devextreme-vue/tree-list';
  import { isSuccess } from '@/plugins/common-lib';
  import DxButton from 'devextreme-vue/button';
  import Search from '@/components/common/search.vue';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { DxTextArea } from 'devextreme-vue/text-area';
  import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
  import CustomStore from "devextreme/data/custom_store";
  import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid.vue";
  export default {
    components: {
      EspDxDataGrid,
      DxColumn,
      DxButton,
      DxSelectBox,
      Search,
      DxTextArea,
      DxPopup,
      DxToolbarItem,
    },
    props: {
      option: {
        default: () => {
          return {
            width: '50%',
            height: '85%',
            minWidth: null,
            minHeight: null,
            saveBtnVisible: false,
            cancelBtnVisible: true,
          };
        },
        type: Object,
      },
      isOpened: {
        type: Boolean,
      },
      cmList: {
        type: Array,
      },
      jobId: {
        type: String,
      },
      rowInfo: {
        type: Object,
      },
    },
    watch: {
      rowInfo() {},
      async isOpened() {
        if (this.isOpened) {
          this.mountedData();
        } else {
          this.formatJson = null;
          this.resetGridDataSource();
        }
      },
    },
    data() {
      return {
        stylingMode: 'outlined', //outlined, underlined, filled
        jsonData: {},
        formatJson: null,
        rowData: null,
        modal: {
          isOpened: false,
          title: '상세보기',
          width: '60%',
          height: '700',
        },
        dataGrid: {
          callApi: 'CALL_EUC_API',
          refName: 'workDetailGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: true, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          disableTotalCount: true,
          selectedRowKeys: [], //선택된 로우키들
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          height: '600', // 주석처리시 100%
          apiActionNm: {
            loading: true,
          },
          customEvent: {
            excel: true,
          },
          showActionButtons: {
            customButtons: [],
          },
          isDuplicateConfigKey: false, //설정키 중복 체크
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: 'Id',
              // i18n: 'UC.WORD.EXCHANGER',
              dataField: 'jobId',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '상세ID',
              // i18n: 'UC.WORD.NUMBER',
              dataField: 'detailId',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowExporing: true,
              fixed: false,
            },
            {
              caption: 'Display',
              // i18n: 'COMPONENTS.DESCRIPTION',
              dataField: 'display',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '결과',
              // i18n: 'UC.WORD.PARTITION',
              dataField: 'result',
              width: 150,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
            },
            {
              caption: '사유',
              // i18n: 'UC.WORD.CSS',
              dataField: 'cause',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '등록일',
              // i18n: 'COMPONENTS.MODIFY_DATE',
              dataField: 'regDt',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              calculateCellValue: this.formatDt,
            },
            {
              caption: '상세내용',
              dataField: 'detail',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              cellTemplate: (container, options) => {
                let button = new DxButton({
                  propsData: {
                    text: '상세내용',
                    icon: '',
                    type: 'info',
                    height: '25',
                    elementAttr: { class: 'btn_XS default filled' },
                    onClick: () => {
                      this.isOpenModal(true, options.data);
                    },
                  },
                });
                button.$mount();
                container.append(button.$el);
              },
            },
          ],
        },
      };
    },

    computed: {},
    methods: {
      /**@description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {},
      /**@description : 라이프사이클 mounted시 호출되는 메서드 */
      async mountedData() {
        await this.selectDataList();
      },

      /** @description : 날짜 형식 변환 메서드 */
      formatDt(rowData) {
        if (rowData?.regDt) {
          return this.$_commonlib.formatDate(rowData.regDt, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
        }
      },
      /**
       * @description 그리드 데이터 초기화
       */
      resetGridDataSource() {
        this.$refs.workDetailGrid.dataSource = [];
        this.$refs.workDetailGrid.clearSelection();
      },
      changeJsonFormat(data) {
        let d = JSON.parse(data);
        let jsonString = JSON.stringify(d, null, 4);
        // jsonString = jsonString.replace(/"(\w+)"\s*:/g, '\n  "$1" : ');

        return jsonString;
      },

      async selectDataList(sort = '-regDt') {
        this.dataGrid.dataSource = new CustomStore({
          key: 'detailId',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);
            if (!params.sort) {
              params.sort = sort;
            }
            params = { ...params, jobId: this.jobId };

            const payload = {
              actionName: 'EUC_WORKHISTORYDETAIL_LIST_ALL',
              data: params,
              loading: false,
            };

            const rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await this.CALL_EUC_API(payload);
            if (isSuccess(res)) {
              rtnData.data = res.data.data;
              rtnData.totalCount = res.data.header.totalCount;
            }
            return rtnData;
          },
        });
      },
      /**@description : 팝업 Open/Close */
      isOpenModal(data, rowInfo) {
        if (rowInfo) {
          this.formatJson = this.changeJsonFormat(rowInfo.jsondata);
        } else {
          this.formatJson = null;
        }
        this.modal.isOpened = data;
      },
    },
    async created() {
      this.createdData();
    },
    async mounted() {
      await this.mountedData();
    },
  };
</script>
