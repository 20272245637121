<!--
  PACKAGE_NAME : src/pages/euc/chineseWall
  FILE_NAME : dept-attributes.vue
  AUTHOR : jhcho
  DATE : 2024-06-11
  DESCRIPTION :
-->
<template>
  <div class="locker_setting_list sub_new_style01 sub_ui_box1">
    <esp-dx-data-grid :data-grid="dataGrid" ref="deptAttributesGrid" />
  </div>
</template>
<script>
  import { isSuccess } from '@/plugins/common-lib';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    props: {
      deptList: {
        type: Array,
        default: () => [],
      },
    },
    components: { EspDxDataGrid },
    data() {
      return {
        dataGrid: {
          disableTotalCount: true,
          callApi: 'CALL_EUC_API',
          excel: {
            title: this.$_lang('UC.WORD.DEPT_ATTRIBUTES', { defaultValue: '부서 속성' }),
          },
          refName: 'deptAttributesGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          height: 'calc(100vh - 225px)', // 주석처리시 100%
          apiActionNm: {},
          showActionButtons: {
            excel: true,
          },
          customEvent: {},
          isDuplicateConfigKey: false, //설정키 중복 체크
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '구분',
              i18n: 'COMPONENTS.TYPE',
              dataField: 'typeNm',
              width: 250,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              multiHeaderNm: '적용대상',
              i18n: 'UC.WORD.APPLY_TARGET',
              columns: [
                {
                  caption: '부서',
                  i18n: 'COMPONENTS.DEPT',
                  dataField: 'deptNm',
                  alignment: 'left',
                  visible: true,
                  allowEditing: false,
                  sortOrder: 'none',
                  allowHeaderFiltering: false,
                  allowGrouping: false,
                },
              ],
            },
          ],
        },
      };
    },
    methods: {
      async selectDataList(sort = '+regDt') {
        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);

            if (!params.sort) {
              params.sort = sort;
            }

            params = { ...params };

            const payload = {
              actionName: 'EUC_CHINESEWALL_DEPT_ATTRIBUTES_SELECT',
              data: params,
              loading: false,
            };

            const res = await this.CALL_EUC_API(payload);
            if (isSuccess(res)) {
              let initialData = [
                { typeNm: this.$_lang('COMPONENTS.TARGET', { defaultValue: '대상' }), deptNm: [] },
                { typeNm: this.$_lang('COMPONENTS.NON_TARGET', { defaultValue: '비대상' }), deptNm: [] },
                { typeNm: this.$_lang('UC.WORD.AFTER_LINE', { defaultValue: '후선' }), deptNm: [] },
              ];

              let groupedData = res.data.data.reduce((acc, item) => {
                let key = '';
                switch (item.gbcd) {
                  case 'NONTGT':
                    key = this.$_lang('COMPONENTS.NON_TARGET', { defaultValue: '비대상' });
                    break;
                  case 'TAGET':
                    key = this.$_lang('COMPONENTS.TARGET', { defaultValue: '대상' });
                    break;
                  case 'AFLINE':
                    key = this.$_lang('UC.WORD.AFTER_LINE', { defaultValue: '후선' });
                    break;
                  default:
                    key = this.$_lang('COMPONENTS.OTHERS', { defaultValue: '기타' });
                }

                let found = acc.find(group => group.typeNm === key);

                const deptNm = this.deptList.find(dept => dept.deptCode === item.deptCd)?.deptNm || item.deptCd;
                if (found) {
                  found.deptNm.push(deptNm);
                } else {
                  acc.push({ typeNm: key, deptNm: [deptNm] });
                }

                return acc;
              }, initialData);

              const deptAttributes = groupedData.map((item, index) => {
                return {
                  id: index + 1,
                  typeNm: item.typeNm,
                  deptNm: item.deptNm.join(', '),
                };
              });

              this.$refs.deptAttributesGrid.totalCount = deptAttributes?.length || 0;

              return deptAttributes;
            }
          },
        });
      },
    },
    async mounted() {
      await this.selectDataList();
    },
    created() {},
  };
</script>
<style scoped></style>