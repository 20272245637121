<template>
	<div class="locker_setting_list sub_new_style01 sub_ui_box1">

		<div class="page_search_box line_bottom_1px">
			<div class="inner alL">
				<DxSelectBox
					v-model="searchType.customTypes.siteId"
					placeholder="사이트 선택"
					:items="getSiteList"
					display-expr="siteNm"
					value-expr="siteId"
					:styling-mode="stylingMode"
					:width="120"
					:height="30"
					@value-changed="$_changeSearchCustomType('siteId', $event)"
				/>
				<DxTextBox
					placeholder="검색어 입력"
					:value="searchText"
					:styling-mode="stylingMode"
					:width="494"
					:height="30"
					@value-changed="(e) => this.searchText = e.value"
				/>
				<DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" />
			</div>
		</div>
		<div class="locker_setting_list sub_new_style01 sub_ui_box1">
			<div class="cusmain-table-wrap">
				<DxTreeList
					ref="deptTreeRef"
					:key-expr="tree.keyExpr"
					:parent-id-expr="tree.parentIdExpr"
					:data-source="tree.dataSource"
					@toolbar-preparing="onToolbarPreparing"
					@content-ready="onContentReady"
					@saving="onSaving"
					filter-mode="fullBranch"
					no-data-text="데이터가 없습니다."
				>
					<DxSearchPanel
						:visible="false"
						:text="searchText"
					/>
					<DxLoadPanel :enabled="true" />
					<DxEditing
						:allow-updating="tree.editing.allowUpdating"
						:allow-deleting="tree.editing.allowDeleting"
						:allow-adding="tree.editing.allowAdding"
						:mode="tree.editing.mode"
						:select-text-on-edit-start="tree.editing.selectTextOnEditStart"
						:start-edit-action="tree.editing.startEditAction"
					/>
					<DxPaging
						:enabled="tree.paging.enabled"
						:page-size="tree.paging.pageSize"
						:page-index="tree.paging.pageIndex"
					/>

					<DxPager
						:visible="tree.pager.visible"
						:show-page-size-selector="tree.pager.showPageSizeSelector"
						:allowed-page-sizes="tree.pager.allowedPageSizes"
						:display-mode="tree.pager.displayMode"
						:show-info="tree.pager.showInfo"
						:show-navigation-buttons="tree.pager.showNavigationButtons"
					/>

					<DxSelection :recursive="tree.recursive" mode="multiple" />

					<DxColumn v-for="(column, index) in tree.columns" :key="index"
						:caption="column.caption"
						:data-field="column.dataField"
						:alignment="column.alignment"
						:cell-template="column.cellTemplate"
						:allow-editing="column.allowEditing"
					>
						<DxLookup
								v-if="column.lookup"
								:data-source="column.lookup.dataSource"
								:display-expr="column.lookup.displayExpr"
								:value-expr="column.lookup.valueExpr"
						/>
					</DxColumn>
				</DxTreeList>
				<DxPopup
						v-if="modal.isOpened"
						:show-title="true"
						:title="modal.initData ? modal.initData.title : null"
						:width="modal.initData ? modal.initData.width : null"
						:height="modal.initData ? modal.initData.height : null"
						:drag-enabled="true"
						:resize-enabled="true"
						:show-close-button="true"
						:hide-on-outside-click="false"
						:visible="modal.isOpened"
						@hiding="isOpenModal(false)"
				>
					<template #content>
						<div>
							<transition>
								<div class="page-sub-box">
									<table class="table_form line-bin">
										<colgroup>
											<col style="width:200px;" />
											<col style="width:auto;" />
											<col style="width:auto;" />
										</colgroup>

										<tbody>
										<tr>
											<th scope="row" class="alT"><label>수신시 설정</label></th>
											<td>
												<DxSelectBox
														v-model="inContentId"
														placeholder="수신시 설정"
														:items="contentList"
														display-expr="description"
														value-expr="id"
														:styling-mode="stylingMode"
														:width="120"
														:height="30"
														:disabled="!inContentSwitch"
												/>
											</td>
											<td>
												<DxSwitch v-model="inContentSwitch"/>
											</td>
										</tr>
										<tr>
											<th scope="row" class="alT"><label>발신시 설정</label></th>
											<td>
												<DxSelectBox
														v-model="outContentId"
														placeholder="발신시 설정"
														:items="contentList"
														display-expr="description"
														value-expr="id"
														:styling-mode="stylingMode"
														:width="120"
														:height="30"
														:disabled="!outContentSwitch"
												/>
											</td>
											<td>
												<DxSwitch v-model="outContentSwitch"/>
											</td>
										</tr>
										</tbody>
									</table>
								</div>
							</transition>
						</div>
					</template>

					<DxToolbarItem
							widget="dxButton"
							toolbar="bottom"
							location="center"
							:visible="modal.initData.hasOwnProperty('buttons') ? ( modal.initData.buttons.hasOwnProperty('save') ? modal.initData.buttons.hasOwnProperty('save') : !modal.initData.buttons.hasOwnProperty('save') ) : false"
							:options="{
						elementAttr: {
							class: 'default filled txt_S medium',
						},
						text: modal.initData.hasOwnProperty('buttons') ? ( modal.initData.buttons.hasOwnProperty('save') ? modal.initData.buttons.save.text : '' ) : '',
						width: '120',
						height: '40',
						onClick: () => onSaveModal(modal.sendData)
					}"
					/>

					<DxToolbarItem
							widget="dxButton"
							toolbar="bottom"
							location="center"
							:visible="modal.initData.hasOwnProperty('buttons') ? ( modal.initData.buttons.hasOwnProperty('cancel') ? modal.initData.buttons.hasOwnProperty('cancel') : !modal.initData.buttons.hasOwnProperty('cancel') ) : false"
							:options="{
						elementAttr: {
							class: 'white filled txt_S medium',
						},
						text: modal.initData.hasOwnProperty('buttons') ? ( modal.initData.buttons.hasOwnProperty('cancel') ? modal.initData.buttons.cancel.text : '' ) : '',
						width: '120',
						height: '40',
						onClick: () => {
							isOpenModal(false);
						}
					}"
					/>
				</DxPopup>
			</div>
		</div>
	</div>

</template>

<script>
import { DxSelectBox } from 'devextreme-vue/select-box';
import {
	DxTreeList,
	DxSelection,
	DxColumn,
	DxPaging,
	DxPager,
	DxEditing,
	DxLoadPanel,
	DxSearchPanel,
	DxLookup
} from 'devextreme-vue/tree-list';
import { isSuccess } from '@/plugins/common-lib';
import { DxButton } from 'devextreme-vue/button';
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxPopup, DxToolbarItem } from "devextreme-vue/popup";
import { DxSwitch } from "devextreme-vue/switch";

export default {
	components: {
		DxTextBox,
		DxSwitch,
		DxToolbarItem,
		DxPopup,
		DxButton,
		DxTreeList,
		DxSelection,
		DxColumn,
		DxSelectBox,
		DxPaging,
		DxPager,
		DxEditing,
		DxLoadPanel,
		DxSearchPanel,
		DxLookup
	},
	data() {
		return {
			inContentId: null,
			outContentId: null,
			inContentSwitch: false,
			outContentSwitch: false,
			modal: {
				isOpened: false,
				currentComponent: null,
				initData: {},
				contentData: null,
			},
			searchText: '',
			infoPushSiteList: [],
			totalCount: 0,
			pageList: [],
			tree: {
				editing: {
					allowUpdating: true,
					mode: 'cell', //수정 모드: ['row', 'cell', 'batch']
					startEditAction: 'click',
					selectTextOnEditStart: false,
				},
				keyExpr: 'dept.deptId',
				parentIdExpr: 'dept.parentId',
				refName: 'deptTreeRef',
				pager: {
					visible: true, //페이저 표시 여부
					showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
					allowedPageSizes: [],
					displayMode: 'compact', //표시 모드 : ['full', 'compact']
					showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
					showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
				},
				paging: {
					enabled: true,
					pageSize: 2000,
					pageIndex: 0, // 시작페이지
				},
				dataSource: [],
				recursive: true,
				columns: [
					{
						caption: '부서명',
						dataField: 'dept.deptNm',
						alignment: 'left',
						allowEditing: false,
						width: 150,
						height: 40,
						searchVisibleColumnsOnly: true,
					},
					{
						caption: '부서명 전체',
						dataField: 'dept.nameTree',
						alignment: 'left',
						allowEditing: false,
						height: 40,
						searchVisibleColumnsOnly: false,
						cellTemplate: (container, options) => {
							const values = options.value.split('|');
							const deptTree = values.join(' ');
							container.append(deptTree);
						},
					},
					{
						caption: '수신시 설정',
						dataField: 'inContentId',
						alignment: 'center',
						allowEditing: true,
						height: 40,
						searchVisibleColumnsOnly: false,
						lookup: {
							dataSource: [],
							displayExpr: 'description',
							valueExpr: 'id',
						},
					},
					{
						caption: '발신시 설정',
						dataField: 'outContentId',
						alignment: 'center',
						allowEditing: true,
						height: 40,
						searchVisibleColumnsOnly: false,
						lookup: {
							dataSource: [],
							displayExpr: 'description',
							valueExpr: 'id',
						},
					},
				],
			},
			userList: [],
			contentList: [],
			config: {
				pageSetting: {
					config: {},
				},
			},
			stylingMode: 'outlined', //outlined, underlined, filled
			searchType: {
				obj: {},
				defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
				defaultValue: 'ROOT',
				customTypes: {
					siteId: null,
				},
				paramsData: null,
			},
		};
	},
	computed: {
		/** @description: U_CODE svr_type 가져오기 */
		getSiteList(){
			const currentPath = this.$router.currentRoute.path;
			const store = this.$store.getters.getSearchHists[currentPath] || [];
			const params = store[0]?.search;

			if(params?.siteId) {
				this.setCustomTypes('siteId', params.siteId);
			} else {
				this.setCustomTypes('siteId', null);
			}

			return [{ siteId: null, site:'전체', siteNm: '전체' }, ...this.infoPushSiteList];
		},
	},
	methods: {
		async onSaveModal(datas) {
			const params = {
				ids: datas.map((data) => data.id),
			}

			if(this.inContentSwitch) {
				params.inContentId = this.inContentId;
			}

			if(this.outContentSwitch) {
				params.outContentId = this.outContentId;
			}

			const payload = {
				data: {
					data: params
				},
				actionName: 'INFOPUSH_DEPT_CONTENT_UPDATE',
				loading: true
			};

			const res = await this.CALL_API(payload);
			if(isSuccess(res)) {
				await this.selectDataList();
				await this.$_Msg("정상 처리되었습니다.");
				this.isOpenModal(false);

			}
		},
		onShowChangePopup(datas){
			this.onOpenModal(
					'ModalCMPasswordChange',
					{
						title: `일괄 변경`,
						//buttons: null,
						width: '500',
						height: '250',
						buttons:{
							save : { text: '저장' },
							cancel : { text: '닫기' },
						}
					},
					datas
			);
		},
		/** @description: 팝업 창 열때 이벤트 */
		onOpenModal(componentNm, componentInitData, sendData){
			this.modal.currentComponent = componentNm;  //set dynamic component name in modal body slot
			this.modal.initData = componentInitData;  //set init modal templet
			this.modal.sendData = sendData;
			this.isOpenModal(true);
		},
		/** @description: 팝업이 열렸는지 체크하는 메서드(true: 열림/false: 닫힘) */
		isOpenModal(data) {
			this.modal.isOpened = data;
			if(!data) {
				this.modal.currentComponent = null;
				this.modal.initData = {};
				this.modal.contentData = null;
				this.inContentId = null;
				this.outContentId = null;
				this.inContentSwitch = false;
				this.outContentSwitch = false;
			}
		},
		async onSaving(e) {
			const params = {};

			await e.changes.forEach((changeData) => {
				const instance = this.$refs.deptTreeRef.instance;
				instance.byKey(changeData.key).done((obj) => {
					const childItems = this.getChildItems(this.tree.dataSource, obj.deptId);
					params.ids = [obj.id, ...childItems.map((item) => item.id)];
					params.inContentId = obj.inContentId;
					params.outContentId = obj.outContentId;
				})
			});

			const payload = {
				data: {
					data: params
				},
				actionName: 'INFOPUSH_DEPT_CONTENT_UPDATE',
				loading: true
			};

			const res = await this.CALL_API(payload);
			if(isSuccess(res)) {
				await this.selectDataList();
				this.$_Msg("정상 처리되었습니다.");
			}
		},
		getChildItems(items, id) {
			const result = [];

			for (const item of items) {
				if (item.dept.parentId === id) {
					result.push(item);
					result.push(...this.getChildItems(items, item.deptId)); // 재귀 호출로 자식의 자식을 찾음
				}
			}

			return result;
		},
		onContentReady(e){
			if(e.component.totalCount() >= 0) {
				const totalCount = e.component.totalCount().toLocaleString();
				document.querySelector('.tet-calr1').innerHTML = totalCount;
			}
			if(this.tree.pager.visible) {
				const pageCount = e.component.pageCount().toLocaleString();
				document.querySelector('.dx-pages-count').innerHTML = pageCount;
			}
		},
		onToolbarPreparing(e) {
			const toolbarItems = e.toolbarOptions.items;
			toolbarItems.push(
				{
					widget: 'dxButton',
						options: {
						icon: '',
						text: '등록',
						elementAttr: { class: 'btn_XS default filled add1' },
						width: 60,
						height: 30,
						onClick: () => {
							this.$store.commit('setDetailParams', { deptData: this.tree.dataSource });
							this.$router.push({ path: '/euc/infopush/setting/dept/update' });
						}
				},
					location: 'before',
				}
			);
			toolbarItems.push(
				{
					widget: 'dxButton',
					options: {
						icon: '',
						text:'삭제',
						showText:'always',
						elementAttr: { class : 'btn_XS white light_filled trash' },
						width: 60,
						height: 30,
						onClick:  async () => {
							const selectedRowsData = this.$refs.deptTreeRef.instance.getSelectedRowsData('all');

							if(!selectedRowsData?.length) {
								this.$_Msg("대상이 선택되어 있지 않습니다.");
								return;
							} else {
								if(await this.$_Confirm('선택한 데이터를 삭제하시겠습니까?')) {
									const payload = {
										actionName: 'INFOPUSH_DEPT_DELETE',
										data: {
											data: selectedRowsData.map((item) => item.id)
										},
										loading: true,
									}

									const res = await this.CALL_API(payload);

									if(isSuccess(res)){
										await this.selectDataList();
									} else {
										this.$_Msg("삭제 실패");
									}
								}
							}
						}
					},
					location: 'before',
					sortIndex: 60,
				}
			);
			toolbarItems.push(
					{
						widget: 'dxButton',
						options: {
							icon: '',
							text: '일괄변경',
							showText:'always',
							elementAttr: { class : 'btn_XS white light_filled' },
							width: 70,
							height: 30,
							onClick:  async () => {
								const selectedRowsData = this.$refs.deptTreeRef.instance.getSelectedRowsData('all');

								if(!selectedRowsData?.length) {
									this.$_Msg("대상이 선택되어 있지 않습니다.");
									return;
								} else {
									this.onShowChangePopup(selectedRowsData);
								}
							}
						},
						location: 'before',
						sortIndex: 60,
					}
			);
			toolbarItems.unshift(
				{
					location: 'after',
					html: `<div class="total-count-item">검색결과 <span class="tet-calr1">${ this.totalCount }</span> 개</div>`,

				}
			)
		},
		onChangePageSize(data){
			if(this.tree.paging.enabled) {
				this.tree.paging.pageSize = data.value;
			}
		},
		/** @description : 라이프사이클 computed에서 this data를 변경하기 위한 메서드 */
		setCustomTypes(key, value) {
			this.searchType.customTypes[key] = value;
		},
		async selectDataList(sort = '+dept.depth,+dept.deptOrd') {
			let params = {};
			if (!params.sort) {
				params.sort = sort;
			}

			params = { ...params, ...this.searchType.paramsData };
			params.pageSize = this.tree.paging.pageSize;
			const payload = {
				actionName: 'INFOPUSH_DEPT_LIST_ALL',
				data: {
					params
				},
				loading: false,
			};

			const res = await this.CALL_API(payload);
			if (isSuccess(res)) {
				this.tree.dataSource = res.data.data;
			}
		},
		async selectContentList() {
			const payload = {
				actionName: 'INFOPUSH_CONTENT_LIST_ALL',
				data: {
					params: {
						viewCd: this.$_getUsedCode.id
					}
				},
				loading: false,
			};

			const res = await this.CALL_API(payload);
			if (isSuccess(res)) {
				this.contentList = [
					{ id: null, description: '미설정' },
					...res.data.data
				];

				this.tree.columns.forEach((column) => {
					if(column?.lookup) {
						column.lookup.dataSource = this.contentList;
					}
				})
			}
		},
		async getInfoPushSiteList() {
			const payload = {
				actionName: 'INFOPUSH_SITE_LIST_ALL',
				data: {
					params: {
						viewCd: this.$_getUsedCode.id,
						sort: '+siteOrd'
					}
				}
			}
			const res = await this.CALL_API(payload);
			if(isSuccess(res)) {
				this.infoPushSiteList = res.data.data;
			}
		},
		mountData() {
			if(this.tree.pager.visible) {
				this.movePagingBox();
			}
			this.getInfoPushSiteList();
			this.selectContentList();
			this.selectDataList();
		},
		async createdData() {
			await this.bindPagingData();
		},
		async bindPagingData() {
			const pageList = this.$_enums.common.pagingSizeList.values;
			this.pageList = pageList;
			this.tree.pager.allowedPageSizes = pageList.map(d => d.codeValue);
		},
		movePagingBox(){
			const instance = this.$refs.deptTreeRef.instance;
			const pager = instance.getView("pagerView").element().dxPager("instance").element()
			const toolbarAfterItem = instance.getView("headerPanel")._toolbar._$afterSection[0];

			const toolbarItem = document.createElement('div');
			toolbarItem.classList.add('dx-item');
			toolbarItem.classList.add('dx-toolbar-item');
			toolbarItem.classList.add('dx-toolbar-button');

			const toolbarItemContent = document.createElement('div');
			toolbarItemContent.classList.add('dx-item-content');
			toolbarItemContent.classList.add('dx-toolbar-item-content');
			toolbarItemContent.appendChild(pager);
			toolbarItem.appendChild(toolbarItemContent);
			toolbarAfterItem.appendChild(toolbarItem);
		},
	},
	created() {
		this.createdData();
	},
	mounted() {
		this.mountData();
	},
};
</script>

<style scoped>
.page_search_box .inner div {
    display: inline-block;
}
.page_search_box .inner > div:not(.box-btn-search) {
    vertical-align: middle;
    margin-right: 10px;
}
.page_search_box .spec-type-box {
    display: inline-block;
}
.page_search_box .inner .box-btn-search {
    margin-right: 15px;
}
.page_search_box #searchTooltip {
    margin-right: 5px;
    font-size: 22px;
    color: #808080;
}
</style>