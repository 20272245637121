<template>
  <div>
    <DxDataGrid
      :ref="gridRefName"
      :dataSource="dataSource"
      :height="computedGridHeight"
      :width="computedGridWidth"
      :show-borders="false"
      :show-row-lines="true"
      :show-column-lines="true"
      :show-column-headers="true"
      :column-resizing-mode="currentMode"
      :column-auto-width="false"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      @toolbar-preparing="onToolbarPreparing"
      @rowPrepared="onRowPrepared"
      @cellPrepared="onCellPrepared"
      class="report-grid-border"
      no-data-text="데이터가 존재하지 않습니다."
    >
      <!--   페이징 및 스크롤 등 그리드 옵션   -->
      <DxPaging
        v-if="usePaging()"
        :enabled="dataGrid.paging.enabled"
        :page-size="dataGrid.paging.pageSize"
        :page-index="dataGrid.paging.pageIndex"
      />

      <DxPager
        v-if="usePaging()"
        :visible="dataGrid.pager.visible"
        :show-page-size-selector="dataGrid.pager.showPageSizeSelector"
        :display-mode="dataGrid.pager.displayMode"
        :show-info="dataGrid.pager.showInfo"
        :show-navigation-buttons="dataGrid.pager.showNavigationButtons"
      />
      <template v-if="usePaging()">
        <DxRemoteOperations
          :filtering="dataGrid.remoteOperations.filtering"
          :sorting="dataGrid.remoteOperations.sorting"
          :grouping="dataGrid.remoteOperations.grouping"
          :paging="dataGrid.remoteOperations.paging"
        />
      </template>

      <DxLoadPanel v-if="usePaging()" :enabled="false" />
      <DxScrolling v-if="usePaging()" mode="virtual" />

      <!--   상단 툴바 리스트   -->
      <template #totalCount>
        <div class="total-count-item">
          검색결과 <span class="tet-calr1">{{ computedTotalCount }}</span> 개
        </div>
      </template>

      <!--   컬럼 셋팅   -->
      <template v-for="(item, i) in columns">
        <template v-if="item.length >= 1 && [null, ''].indexOf(item[0].multiHeaderNm) === -1">
          <DxColumn :caption="item[0].multiHeaderNm" :key="i">
            <DxColumn
              v-for="(item2, i2) in item"
              alignment="center"
              :key="`${i}_${i2}`"
              :width="item.width"
              :caption="item2.caption"
              :dataField="item2.dataField"
              :cellTemplate="cellTemplateByColumnFmt(item2.format)"
            />
          </DxColumn>
        </template>
        <template v-else>
          <DxColumn
            :key="i"
            :cellTemplate="cellTemplateByColumnFmt(item.format)"
            :caption="item.caption"
            :dataField="item.dataField"
            :alignment="item.align"
            :width="item.width"
            :visible="item.visible"
            :allow-fixing="item.fixed"
          ></DxColumn>
        </template>
      </template>

      <!--   컬럼 데이터 템플릿   -->
      <template #dateTemplate="{ data }">
        {{ fmtDate(data.value) }}
      </template>

      <template #percentTemplate="{ data }">
        {{ data.value + '%' }}
      </template>

      <template #timeTemplate="{ data }">
        {{ fmtTime(data.value) }}
      </template>

      <template #numberTemplate="{ data }">
        {{ fmtNumber(data.value) }}
      </template>

      <template #defaultTemplate="{ data }">
        {{ data.value }}
      </template>

      <template #treeTemplate="{ data }">
        <span :style="styleLeftPadding(data.value)">{{ data.value }}</span>
      </template>
    </DxDataGrid>
    <!-- 컬럼 hover 시 말풍선 -->
    <DxPopover width="auto" :visible="showColumnDescription" :target="columnHoverTarget" position="top" :hide-on-outside-click="true">
      {{ columnCaption }} : {{ columnDescription }}
    </DxPopover>
  </div>
</template>

<script>
  import { DxPopover } from 'devextreme-vue/popover';
  import { DxDataGrid, DxColumn, DxPaging, DxLoadPanel, DxScrolling, DxPager, DxRemoteOperations } from 'devextreme-vue/data-grid';
  import { formatDate, isTrue } from '@/plugins/common-lib';
  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxPaging,
      //DxTooltip,
      DxLoadPanel,
      DxScrolling,
      DxPopover,
      DxPager,
      DxRemoteOperations,
    },
    props: {
      gridInfo: Object,
      columns: Array,
      columnsDesc: Array,
      dataSource: Array,
      originColumns: {
        type: Array,
        default: () => [],
      },
    },
    watch: {},
    data() {
      return {
        dataGrid: {
          paging: {
            enabled: true,
            pageSize: 100,
            pageIndex: 0,
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: true,
          },
          rowColor: {},
        },
        gridRefName: 'grid',
        currentMode: 'widget', //컬럼 리사이즈 nextColumn(넓이 내에서) / widget(화면 확장)
        // 컬럼 툴팁
        showColumnDescription: false,
        columnCaption: '',
        columnDescription: '',
        columnHoverTarget: '',
        gridWidth: '600',
        gridHeight: '650',
        // rowspan 정보 (컬럼명: rowspan size, ...)
        rowspanDatas: {},
        //toolbar
        toolbarItems: [],
      };
    },
    computed: {
      grid() {
        return this.$refs[this.gridRefName].instance;
      },
      computedGridHeight() {
        return this.gridInfo?.size?.height || '650';
      },
      computedGridWidth() {
        return this.gridInfo?.size?.width || '100%';
      },
      computedTotalCount() {
        return this.dataSource.length;
      },
    },

    methods: {
      /**
       * 보고서 기본 데이터 세팅(필드 및 보고서 페이지 정보)
       * @returns {Promise<void>}
       */
      async init() {
        this.gridWidth = '100%';
        if (this.usePaging()) await this.movePagingBox();
      },
      /**
       * 보고서 출력 시 rowSpan 참고 데이터 셋팅
       * 특정 열 행 머지 시키는 메서드 (컬럼 중 1개만 가능하며, 해당 컬럼으로 ORDER BY 필요)
       * @param datas: 보고서 조회 결과 데이터
       */
      setRowSpanReportResultData(datas) {
        const mergeCol = this.columns.find(v => isTrue(v.rowMerge));
        if (mergeCol) {
          const targetField = mergeCol.dataField;
          this.rowspanDatas = datas.reduce((acc, v) => {
            if (!acc[v[targetField]]) acc[v[targetField]] = { spanCnt: 0, isUsed: false };
            acc[v[targetField]].spanCnt += 1;
            return acc;
          }, {});
        }
      },
      cellTemplateByColumnFmt(fmt) {
        switch (fmt) {
          case 'fmDate':
            return 'dateTemplate';
          case 'fmNumber':
            return 'numberTemplate';
          case 'fmPercent':
            return 'percentTemplate';
          case 'fmtime' || 'fmTime':
            return 'timeTemplate';
          case 'button':
            return 'buttonTemplate';
          case 'fmTree':
            return 'treeTemplate';
          default:
            return null;
        }
      },
      onRowPrepared(e) {
        try {
          const GID = e.data.GID;
          const MAX_GID = e.data.MAX_GID;
          if (GID !== undefined && 0 < GID) {
            e.rowElement.classList.remove('dx-row-alt');
            e.rowElement.bgColor = GID === MAX_GID ? '#FFEFEF' : '#FFF8ED';
          }
        } catch (error) {}
      },
      onCellPrepared(e) {
        if (e.rowType !== 'data' || e.columnIndex != 0) {
          return;
        }

        const firstColValue = e.value;
        const rowspanInfo = this.rowspanDatas[firstColValue];
        if (rowspanInfo) {
          if (rowspanInfo.isUsed === false) {
            e.cellElement.rowSpan = rowspanInfo.spanCnt;
            e.cellElement.innerHTML = firstColValue;
            e.cellElement.style['border-right'] = '1px #e0e0e0 solid';
            this.rowspanDatas[firstColValue].isUsed = true;
          } else {
            e.cellElement.style.display = 'none';
          }
        }
      },
      usePaging() {
        if (this.gridInfo.paging) {
          return this.convertBool(this.gridInfo.paging);
        }
        return false;
      },
      onToolbarPreparing(e) {
        const toolbar = this.gridInfo?.toolbar || {};
        // 검색결과
        if (toolbar.totalCount) {
          this.toolbarItems.push({
            widget: 'dxTemplate',
            location: 'after',
            template: 'totalCount',
          });
        }
        e.toolbarOptions.items = this.toolbarItems;
      },
      movePagingBox() {
        const pager = this.$refs[this.gridRefName].instance.getView('pagerView').element().dxPager('instance').element();

        const toolbarAfterItem = this.$refs[this.gridRefName].instance.getView('headerPanel')._toolbar._$afterSection[0];
        const toolbarItem = document.createElement('div');
        toolbarItem.classList.add('dx-item');
        toolbarItem.classList.add('dx-toolbar-item');
        toolbarItem.classList.add('dx-toolbar-button');
        const toolbarItemContent = document.createElement('div');
        toolbarItemContent.classList.add('dx-item-content');
        toolbarItemContent.classList.add('dx-toolbar-item-content');
        toolbarItemContent.appendChild(pager);
        toolbarItem.appendChild(toolbarItemContent);
        toolbarAfterItem.appendChild(toolbarItem);
      },
      styleLeftPadding(str) {
        if (!str) return false;
        const firstWord = str.trim().at(0);
        const position = str.indexOf(firstWord);
        return `padding-left: ${(position - 1) * 15}px;`;
      },
      convertBool(str) {
        return [true, 'true', 'TRUE'].includes(str);
      },
      fmtDate(value) {
        return formatDate(value, 'YYYYMMDD', 'YYYY-MM-DD');
      },
      fmtNumber(value) {
        let rtn = parseInt(value);
        return rtn.toLocaleString('ko-KR');
      },
      fmtTime(seconds) {
        const hour = this.lPadZero(parseInt(seconds / 3600));
        const min = this.lPadZero(parseInt((seconds % 3600) / 60));
        const sec = this.lPadZero(parseInt(seconds % 60));
        return `${hour}:${min}:${sec}`;
      },
      lPadZero(num) {
        if (num < 10) {
          return `0${num}`;
        }
        return num;
      },
    },
    async created() {},
    async mounted() {
      await this.init();
    },
    updated() {},
  };
</script>
<style scoped>
  .mr-10 {
    margin-right: 10px;
  }
</style>
<style>
  .dx-header-row td {
    text-align: center !important;
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
    background-color: #f9f9f9;
  }
  .report-grid-border .dx-datagrid-headers {
    border-top: 1px solid #999;
    border-bottom: 1px solid #e0e0e0;
  }
  .report-grid-border .dx-datagrid-headers tr td {
    border-left: 1px solid #e0e0e0;
  }

  .dx-datagrid-headers.dx-header-multi-row .dx-datagrid-content .dx-datagrid-table .dx-row.dx-header-row > td {
    padding-top: 3px;
    padding-bottom: 3px;
    vertical-align: middle !important;
    border-right: 1px solid #e0e0e0;
    font-size: 12px !important;
  }
</style>
