<template>
  <div class="page-sub-box">
    <div class="locker_setting_list sub_new_style01 sub_ui_box1">
      <Search
        codeKey="search_infopush_act"
        :searchObj="searchType.obj"
        :useKeywordType="true"
        @change-search-type="$_changeSearchType"
        @onSearchClick="$_searchData($event, 'search_infopush_act')"
      >
        <template v-slot:before>
          <DxSelectBox
            v-model="searchType.customTypes.siteId"
            placeholder="사이트 선택"
            :items="getSiteList"
            display-expr="siteNm"
            value-expr="siteId"
            :styling-mode="stylingMode"
            :width="120"
            :height="30"
            @value-changed="$_changeSearchCustomType('siteId', $event)"
          />
        </template>
      </Search>
    </div>

    <esp-dx-data-grid
      :data-grid="dataGrid"
      ref="infoPushActionGrid"
      @init-new-row="onInitNewRow"
      @checkDataBeforeSaving="onCheckDataBeforeSaving"
    />
  </div>
</template>

<script>
  import Search from '@/components/common/search.vue';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { DxSwitch } from 'devextreme-vue/switch';
  import { isSuccess } from '@/plugins/common-lib';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  let vm = this;

  export default {
    components: {
      EspDxDataGrid,
      DxSelectBox,
      Search,
    },
    data() {
      return {
        infoPushSiteList: [],
        config: {
          pageSetting: {
            //pageData pageSetting 관련
            config: {},
          },
        },
        stylingMode: 'outlined', //outlined, underlined, filled
        searchType: {
          obj: {
            siteNm: '',
          },
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            siteId: null,
          },
          paramsData: null,
        },
        dataGrid: {
          refName: 'infoPushActionGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          // height:'500',    // 주석처리시 100%
          apiActionNm: {
            select: 'INFOPUSH_ACTION_LIST_ALL',
          },
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
            initNewRow: true,
            cellPrepared: true,
            checkDataBeforeSaving: true,
          },
          showActionButtons: {
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '등록',
                  elementAttr: { class: 'btn_XS default filled add1' },
                  width: 60,
                  height: 30,
                  onClick() {
                    vm.onAddData();
                  },
                },
                location: 'before',
              },
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '삭제',
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 60,
                  height: 30,
                  onClick() {
                    vm.onDeleteData();
                  },
                },
                location: 'before',
              },
            ],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: false, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '사이트명',
              dataField: 'infoPushSite.siteNm',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '서비스 코드',
              dataField: 'svcCode',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              cellTemplate: (container, options) => {
                const aTag = document.createElement('a');
                aTag.innerText = options.value;
                aTag.addEventListener('click', () => {
                  vm.onUpdateData(options.data);
                });
                container.append(aTag);
              },
            },
            {
              caption: '설명',
              dataField: 'description',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: 'In/Out',
              dataField: 'inoutCd',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              lookup: {
                dataSource: this.$_getCode('infopush_inout'),
                displayExpr: 'codeNm',
                valueExpr: 'codeId',
              },
            },
            {
              caption: 'Event',
              dataField: 'eventCd',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              lookup: {
                dataSource: this.$_getCode('infopush_event'),
                displayExpr: 'codeNm',
                valueExpr: 'codeId',
              },
            },
            {
              caption: '프롤로그',
              dataField: 'prologueCd',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              lookup: {
                dataSource: this.$_getCode('infopush_prologue'),
                displayExpr: 'codeNm',
                valueExpr: 'codeId',
              },
            },
            {
              caption: '프롤로그 이미지',
              dataField: 'prologueImg.description',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '에필로그',
              dataField: 'epilogueCd',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              lookup: {
                dataSource: this.$_getCode('infopush_epilogue'),
                displayExpr: 'codeNm',
                valueExpr: 'codeId',
              },
            },
            {
              caption: '에필로그 이미지',
              dataField: 'epilogueImg.description',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '순서',
              dataField: 'actionOrd',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'asc',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '사용 여부',
              dataField: 'viewCd',
              width: 150,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              cellTemplate: (container, options) => {
                const switchBtn = new DxSwitch({
                  propsData: {
                    value: options.value === this.$_getUsedCode.id,
                    onValueChanged: () => {
                      this.onChangedViewCd(options.data);
                    },
                  },
                });
                switchBtn.$mount();
                container.append(switchBtn.$el);
              },
            },
            {
              caption: '등록일',
              dataField: 'regDt',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              calculateCellValue: this.formatDt,
            },
          ],
        },
      };
    },
    computed: {
      /** @description: U_CODE svr_type 가져오기 */
      getSiteList() {
        const currentPath = this.$router.currentRoute.path;
        const store = this.$store.getters.getSearchHists[currentPath] || [];
        const params = store[0]?.search;

        if (params?.siteId) {
          this.setCustomTypes('siteId', params.siteId);
        } else {
          this.setCustomTypes('siteId', null);
        }

        return [{ siteId: null, site: '전체', siteNm: '전체' }, ...this.infoPushSiteList];
      },
    },
    methods: {
      async onChangedViewCd(data) {
        let viewCd;
        if (data.viewCd === this.$_getUsedCode.id) {
          //사용이면
          viewCd = this.$_getUnUsedCode.id; //미사용으로 스위치
        } else {
          //미사용이면
          viewCd = this.$_getUsedCode.id; //사용으로 스위치
        }
        if (data.id) {
          const payload = {
            actionName: 'INFOPUSH_ACTION_UPDATE',
            data: {
              data: [
                {
                  id: data.id,
                  viewCd,
                },
              ],
            },
            loading: false,
          };

          const res = await this.CALL_API(payload);
          if (isSuccess(res)) {
            this.$_Toast(this.$_lang('CMN_SUC_UPDATE'));
            this.$refs.infoPushActionGrid.getInstance.refresh();
          } else {
            this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          }
        } else {
          data.viewCd = viewCd;
        }
      },
      /** @description : 단말 정보 상세 페이지 이동 메서드 */
      onUpdateData(data) {
        this.$store.commit('setDetailParams', { sendData: data });
        this.$router.push({ path: '/euc/infopush/action/update' });
      },
      onAddData() {
        this.$store.commit('setDetailParams', { sendData: null });
        this.$router.push({ path: '/euc/infopush/action/update' });
      },
      async onDeleteData() {
        const selectedRows = this.$refs.infoPushActionGrid.selectedRowsData;
        if (!selectedRows?.length) {
          this.$_Msg('대상이 선택되어 있지 않습니다.');
        } else {
          if (await this.$_Confirm('선택한 데이터를 삭제하시겠습니까?')) {
            const payload = {
              actionName: 'INFOPUSH_ACTION_DELETE',
              data: {
                data: {
                  data: selectedRows,
                },
              },
            };

            const res = await this.CALL_API(payload);
            if (isSuccess(res)) {
              this.$refs.infoPushActionGrid.getInstance.refresh();
            }
          }
        }
      },
      /** @description : 데이터 추가 시 기본 값 적용하기 위함 */
      onInitNewRow(e) {
        e.data.viewCd = this.$_getUsedCode.id;
      },
      /** @description: 시스템설정 값 관리 저장 전 데이터 체크 메서드 */
      async onCheckDataBeforeSaving(e, saveFunction) {
        let configKeyArr = e.changes.map(d => d.data.siteId);
        const payload = {
          actionName: 'INFOPUSH_SITE_LIST_ALL',
          data: {
            params: {
              siteId: configKeyArr.join(','),
            },
          },
        };

        let isDuplicateConfigKey = false;
        const res = await vm.CALL_API(payload);
        if (isSuccess(res)) {
          isDuplicateConfigKey = true;
        }

        if (isDuplicateConfigKey) {
          this.$_Msg('동일한 설정 KEY값이 존재하여 저장할 수 없습니다. ');
        } else {
          saveFunction(e);
        }
      },
      /** @description : 라이프사이클 computed에서 this data를 변경하기 위한 메서드 */
      setCustomTypes(key, value) {
        this.searchType.customTypes[key] = value;
      },
      /** @description : 데이터 검색 메서드 */
      async selectDataList(sort = '-regDt') {
        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);
            if (!params.sort) {
              params.sort = sort;
            }

            params = { ...params, ...this.searchType.paramsData };

            const payload = {
              actionName: 'INFOPUSH_ACTION_LIST_ALL',
              data: {
                params,
              },
              loading: false,
            };

            const rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await this.CALL_API(payload);
            if (isSuccess(res)) {
              rtnData.data = res.data.data;
              rtnData.totalCount = res.data.header.totalCount;
            }
            return rtnData;
          },
          insert: values => {
            console.log(values);
          },
          update: (key, values) => {
            console.log(key, values);
          },
          remove: key => {
            console.log(key);
          },
        });
      },
      /** @description : 날짜 형식 변환 메서드 */
      formatDt(rowData) {
        if (rowData?.regDt) {
          return this.$_commonlib.formatDate(rowData.regDt, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
        }
      },
      /** @description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {
        this.getInfoPushSiteList();
        vm = this;
      },
      /** @description : 라이프사이클 mounted시 호출되는 메서드 */
      mountData() {
        this.searchType.paramsData = null;
      },
      async getInfoPushSiteList() {
        const payload = {
          actionName: 'INFOPUSH_SITE_LIST_ALL',
          data: {
            params: {
              viewCd: this.$_getUsedCode.id,
              sort: '+siteOrd',
            },
          },
        };
        const res = await this.CALL_API(payload);
        if (isSuccess(res)) {
          this.infoPushSiteList = res.data.data;
        }
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountData();
    },
  };
</script>
