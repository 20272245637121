<!--
  PACKAGE_NAME : src/pages/ui/components/modal-demo.vue
  FILE_NAME : modal-demo.vue
  AUTHOR : hmlee
  DATE : 2025-01-16
  DESCRIPTION : 모달 데모 컴포넌트
-->
<template>
  <esp-dx-modal-popup
    :option="{
      title: modal.title,
      width: modal.width,
      height: modal.height,
      minWidth: modal.minWidth,
      minHeight: modal.minHeight,
      closeOnOutsideClick: modal.closeOnOutsideClick,
      validationGroup: modal.validationGroup,
      useSaveBtnDisabled: modal.useSaveBtnDisabled,
    }"
    :isOpen="isOpen"
    @saveModal="handleSaveModal"
    @closeModal="handleCloseModal"
  >
    <template #content>
      <table class="table_form line-bin">
        <caption>
          <strong>팝업 데모</strong>
        </caption>
        <colgroup>
          <col style="width:110px;">
          <col style="width:auto;">
        </colgroup>

        <tbody>
          <tr>
            <th scope="row">
              <label for="label5">
                아이디
                <span class="icon_require"></span>
              </label>
            </th>
            <td>
              <dx-text-box
                v-model="formData.userId"
                :styling-mode="config.stylingMode"
                class="mar_ri10"
                :width="250"
              >
                <dx-validator :validation-group="modal.validationGroup">
                  <DxRequiredRule
                    message="아이디은 필수 항목입니다."
                  />
                </dx-validator>
              </dx-text-box>
            </td>
          </tr>
          <tr>
            <th scope="row">
              <label for="label5">
                이름
                <span class="icon_require"></span>
              </label>
            </th>
            <td>
              <dx-text-box
                v-model="formData.userNm"
                :styling-mode="config.stylingMode"
                class="mar_ri10"
                :width="250"
              >
                <dx-validator :validation-group="modal.validationGroup">
                  <DxRequiredRule
                    message="이름은 필수 항목입니다."
                  />
                </dx-validator>
              </dx-text-box>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </esp-dx-modal-popup>
</template>

<script>
import EspDxModalPopup from "@/components/devextreme/esp-dx-modal-popup.vue";
import {DxTextBox} from 'devextreme-vue/text-box';
import {DxValidator, DxRequiredRule} from 'devextreme-vue/validator';

export default {
  components: {
    EspDxModalPopup,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
  },
  props: {
    isOpen: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      modal: {
        title: '팝업 데모',
        minWidth: '300',
        width: '510',
        minHeight: '300',
        height: '300',
        closeOnOutsideClick: false,
        validationGroup: 'validationGroupModal',
        useSaveBtnDisabled: false, // 저장 중복 클릭 방지
      },
      config: {
        stylingMode: 'outlined', //[outlined, filled, underlined]
      },
      formData: {
        userId: '',
        userNm: '',
      },
    }
  },
  computed: {
  },
  methods: {
    /** @description: 모달 저장 */
    async handleSaveModal() {
      // 저장 중복 클릭 방지 체크
      if (this.modal.useSaveBtnDisabled) {
        return false;
      }
      this.modal.useSaveBtnDisabled = true; // 저장 중복 클릭 방지 설정

      // 1초 후 저장 중복 클릭 방지 해제
      setTimeout(() => {
        this.$emit('saveModal');
        this.modal.useSaveBtnDisabled = false;
      }, 1000);
    },
    /** @description: 모달 닫기 */
    handleCloseModal() {
      this.$emit('closeModal');
    },
  },
  created() {
  },
}
</script>
