<!--
  PACKAGE_NAME : src/components/euc/automation
  FILE_NAME : dn-change-type.vue
  AUTHOR : jhcho
  DATE : 24. 7. 9.
  DESCRIPTION :
-->
<template>
  <div class="container">
    <div class="fl per50">
      <table class="table_form line-bin">
        <caption>
          <strong>변경 전</strong>
        </caption>
        <colgroup>
          <col style="width: 100px;">
          <col style="width: auto;">
        </colgroup>

        <thead class="sub_title_txt">
          <tr>
            <td colspan="2">
              <h2>변경 전</h2>
            </td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <th scope="row">
              <label for="label1">사번</label>
            </th>
            <td>
              {{ originData.userNo }}
            </td>
          </tr>
          <tr>
            <th scope="row">
              <label for="label1">이름</label>
            </th>
            <td>
              {{ originData.userNm }}
            </td>
          </tr>
          <tr>
            <th scope="row">
              <label for="label1">직위</label>
            </th>
            <td>
              {{ originData.gradeNm }}
            </td>
          </tr>
          <tr>
            <th scope="row">
              <label for="label1">부서</label>
            </th>
            <td>
              {{ originData.deptNm }}
            </td>
          </tr>
          <tr>
            <th scope="row">
              <label for="label1">부서약어</label>
            </th>
            <td>
              {{ originData.abbrNm }}
            </td>
          </tr>
          <tr>
            <th scope="row">
              <label for="label2">내선번호</label>
            </th>
            <td>
              {{ originData.dn }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="fr per50">
      <table class="table_form line-bin">
        <caption>
          <strong>변경 후</strong>
        </caption>
        <colgroup>
          <col style="width: 100px;">
          <col style="width: auto;">
        </colgroup>

        <thead class="sub_title_txt">
        <tr>
          <td colspan="2">
            <h2>변경 후</h2>
          </td>
        </tr>
        </thead>

        <tbody>
          <tr>
            <th scope="row">
              <label for="label1">사번</label>
            </th>
            <td>
              {{ originData.userNo }}
            </td>
          </tr>
          <tr>
            <th scope="row">
              <label for="label1">이름</label>
            </th>
            <td>
              <DxTextBox
                  placeholder="이름"
                  v-model="changeData.userNm"
                  :styling-mode="stylingMode"
                  class="mar_ri10"
              />
            </td>
          </tr>
          <tr>
            <th scope="row">
              <label for="label1">직위</label>
            </th>
            <td>
              {{ originData.gradeNm }}
            </td>
          </tr>
          <tr>
            <th scope="row">
              <label for="label1">부서</label>
            </th>
            <td>
              {{ originData.deptNm }}
            </td>
          </tr>
          <tr>
            <th scope="row">
              <label for="label1">부서약어</label>
            </th>
            <td>
              {{ originData.abbrNm }}
            </td>
          </tr>
          <tr>
            <th scope="row">
              <label for="label2">내선번호</label>
            </th>
            <td>
              <DxTextBox
                  placeholder="내선번호"
                  v-model="changeData.phoneNumber"
                  :styling-mode="stylingMode"
                  class="mar_ri10"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { DxTextBox } from "devextreme-vue/text-box";
import { isSuccess } from "@/plugins/common-lib";

export default {
  props: {
    requestItem: {
      type: Object,
      default: () => {
        return {
          userNo: '',
          userNm: '',
          phoneNumber: ''
        }
      }
    }
  },
  components: {
    DxTextBox,
  },
  data() {
    return {
      stylingMode: 'outlined',
      originData: {
        userNo: '',
        userNm: '',
        gradeNm: '',
        deptNm: '',
        abbrNm: '',
        dn: '',
      },
      changeData: {
        userNo: '',
        userNm: '',
        gradeNm: '',
        deptNm: '',
        abbrNm: '',
        phoneNumber: '',
      }
    }
  },
  methods: {
    async selectUserData() {
      const payload = {
        actionName: 'USER_LIST_ALL_VIEW',
        data: {
          userNo: this.requestItem.userNo
        },
        loading: false
      };
      const res = await this.CALL_API(payload);
      if(isSuccess(res)) {
        this.originData = res.data.data[0];
      }
    }
  },
  mounted() {
    this.changeData = this.requestItem;
  },
  created() {
    this.selectUserData();
  }
}
</script>
<style scoped>

</style>