<template>
	<transition>
		<div class="container">
			<table class="table_form line-bin">
				<colgroup>
					<col style="width:100px;" />
					<col style="width:auto;" />
					<col style="width:100px;" />
					<col style="width:auto;" />
				</colgroup>
				<tbody>
					<tr>
						<th scope="row">
							<label for="label5">
								<span>교육계획명</span>
							</label>
						</th>
						<td colspan="3">
							{{ contentData.deps1List.scheNm }}
						</td>
					</tr>
					<tr>
						<th scope="row">
							<label for="label5">
								<span>교육기간</span>
							</label>
						</th>
						<td colspan="3">
							{{ formatDate(contentData.deps1List.ymdStart) }}
							~
							{{ formatDate(contentData.deps1List.ymdEnd) }}
						</td>
					</tr>
					<tr>
						<th scope="row">
							<label for="label5">
								<span>강의명</span>
							</label>
						</th>
						<td colspan="3">
							{{ contentData.deps2List.eduNm }}
						</td>
					</tr>
					<tr>
						<th scope="row">
							<label for="label5">
								<span>강의시간</span>
							</label>
						</th>
						<td>
							{{ formatTime(contentData.deps2List.eduTime) }}
						</td>
						<th scope="row">
							<label for="label5">
								<span>강의유형</span>
							</label>
						</th>
						<td>
							{{ getEduTypeList(contentData.deps2List.typeCd) }}
						</td>
					</tr>
					<tr>
						<th scope="row">
							<label for="label5">
								<span>교육자료</span>
							</label>
						</th>
						<td>
							<template v-if="contentData.deps2List.fileGroupId === null">
								{{ '-' }}
							</template>
							<template v-else>
								<DxButton
									class="download"
									width="30"
									height="30"
									@click="onDataDownload(contentData.deps2List.fileGroupId)"
								/>
							</template>
						</td>
						<th scope="row">
							<label for="label5">
								<span>참여정보</span>
							</label>
						</th>
						<td>
							{{ getJoinCheck(contentData.deps3List.joinCd) }}
						</td>
					</tr>
					<tr>
						<th scope="row">
							<label for="label5">
								<span>피드백<br/>요청내용</span>
							</label>
						</th>
						<td>
							<DxTextArea :height="150" v-model="formData.feedbakRequest" :read-only="true" styling-mode="underlined"> </DxTextArea>
						</td>
						<th scope="row">
							<label for="label5">
								<span>피드백<br/>응답내용</span>
							</label>
						</th>
						<td>
							<DxTextArea :height="150" v-model="formData.feedbakResponse" :read-only="!canModify" styling-mode="underlined"> </DxTextArea>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</transition>
</template>

<script>
import { DxTextArea } from 'devextreme-vue/text-area';
import { DxButton } from 'devextreme-vue/button';
import {formatDate} from "@/plugins/common-lib";


export default {
	components: {
		DxTextArea,
		DxButton,
	},
	props: {
		contentData: {
			default: () => {
				return {};
			},
			type: Object,
		},
	},
	watch: {},
	data() {
		return {
			canModify: true,
			formData: {
				feedbakRequest: '',
				feedbakResponse: '',
			},
		};
	},
	computed: {},
	methods: {
		getEduTypeList(typeCd) {
			let eduType = this.$_getCode('ewm_edu_education_type');
			let result = '';
			eduType.forEach(item => {
				if (item.codeId === typeCd) {
					result = item.codeNm;
				}
			});
			return result;
		},
		getJoinCheck(joinCheck) {
			let join = this.$_getCode('join');
			let result = '';
			join.forEach(item => {
				if (item.codeId === joinCheck) {
					result = item.codeNm;
				}
			});
			return result;
		},
		/** @description : 날짜 형식 변환 메서드 */
		formatDate(date) {
			return formatDate(date, 'YYYYMMDD', 'YYYY-MM-DD');
		},
		/** @description : 시간 형식 변환 메서드 */
		formatTime(time) {
			let hours = Math.floor(time / 60)
				.toString()
				.padStart(2, '0');
			let Minutes = (time % 60).toString().padStart(2, '0');
			return `${hours}:${Minutes}`;
		},
    /**
     * 파일 다운로드
     *
     * @param fileGroupId
     */
		async onDataDownload(fileGroupId) {
      const fileList = await this.$_getListAttachFile(fileGroupId);

      if (!fileList || fileList.length === 0) {
        this.$_Msg('다운로드 할 파일이 없습니다.');
        return;
      }

      fileList.forEach(fileInfo => {
        this.$_downloadAttachFile(fileInfo.fileGroupId, fileInfo.fileName);
      });
		},
	},
	mounted() {
    this.canModify = this.contentData.deps1List.processCd === this.$_enums.ewm.eduEducationProcess.FEEDBACK.value;
    this.formData.feedbakRequest = this.contentData.deps3List.feedbakRequest;
    this.formData.feedbakResponse = this.contentData.deps3List.feedbakResponse;
  },
};
</script>

<style scoped>
.table-style.sec {
	padding-top: 30px;
}

.table-style th,
.table-style td {
	border: 1px solid gray;
	padding: 5px;
	background-color: white;
	height: 100%;
}
</style>
