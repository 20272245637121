import { EnumEntry, EnumMap } from '@/configs/enums/util';

/**
 * @description 보고서 유형 Enum 클래스
 */
class ReportType extends EnumEntry {
  DAILY = {
    ...EnumMap,
    key: 'REPORT_TYPE_DAILY',
    value: 'daily',
    label: '일별',
    group: 'REPORT_TYPE',
    group_label: '보고서 유형',
    equals: value => {
      return this.DAILY.value === value;
    },
  };

  MONTHLY = {
    ...EnumMap,
    key: 'REPORT_TYPE_MONTHLY',
    value: 'monthly',
    label: '월별',
    group: 'REPORT_TYPE',
    group_label: '보고서 유형',
    equals: value => {
      return this.MONTHLY.value === value;
    },
  };

  HOUR = {
    ...EnumMap,
    key: 'REPORT_TYPE_HOUR',
    value: 'hour',
    label: '시간별',
    group: 'REPORT_TYPE',
    group_label: '보고서 유형',
    equals: value => {
      return this.HOUR.value === value;
    },
  };

  I15 = {
    ...EnumMap,
    key: 'REPORT_TYPE_I15',
    value: 'i15',
    label: '15분별',
    group: 'REPORT_TYPE',
    group_label: '보고서 유형',
    equals: value => {
      return this.I15.value === value;
    },
  };

  I30 = {
    ...EnumMap,
    key: 'REPORT_TYPE_MIN_30',
    value: 'i30',
    label: '30분별',
    group: 'REPORT_TYPE',
    group_label: '보고서 유형',
    equals: value => {
      return this.I30.value === value;
    },
  };

  WEEKDAY = {
    ...EnumMap,
    key: 'REPORT_TYPE_WEEKDAY',
    value: 'weekday',
    label: '요일별',
    group: 'REPORT_TYPE',
    group_label: '보고서 유형',
    equals: value => {
      return this.WEEKDAY.value === value;
    },
  };

  DAYTIME = {
    ...EnumMap,
    key: 'REPORT_TYPE_DAYTIME',
    value: 'daytimes',
    label: '일+시간',
    group: 'REPORT_TYPE',
    group_label: '보고서 유형',
    equals: value => {
      return this.DAYTIME.value === value;
    },
  };

  values = [this.DAILY, this.MONTHLY, this.HOUR, this.I15, this.I30, this.DAYTIME, this.WEEKDAY];

  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };
}

/**
 * @description 모든 코드 반환
 * @return {*[]}
 */
function getEnumAllCodes() {
  return [].concat(new ReportType().values);
}

export default {
  reportType: new ReportType(),
  getEnumAllCodes: getEnumAllCodes(),
};
