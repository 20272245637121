<template>
  <div class="flex flex-wrap items-center space-x-1.5 mb-4">
    <DxSelectBox
      placeholder="시"
      v-model="form.startH"
      :data-source="dataSource.times"
      :height="30"
      width="82px"
      :styling-mode="dxStyled"
      :disabled="disabled"
      value-expr="value"
      display-expr="label"
      @value-changed="handleChangedValue"
    />
    <span>:</span>
    <DxSelectBox
      placeholder="분"
      v-model="form.startM"
      :data-source="dataSource.minutes"
      :height="30"
      width="82px"
      :disabled="disabled"
      :styling-mode="dxStyled"
      value-expr="value"
      display-expr="label"
      @value-changed="handleChangedValue"
    />
    <span>~</span>
    <DxSelectBox
      placeholder="시"
      v-model="form.endH"
      :data-source="dataSource.times"
      :height="30"
      width="82px"
      :disabled="disabled"
      :styling-mode="dxStyled"
      value-expr="value"
      display-expr="label"
      @value-changed="handleChangedValue"
    />
    <span>:</span>
    <DxSelectBox
      placeholder="분"
      v-model="form.endM"
      :data-source="dataSource.minutes"
      :height="30"
      :disabled="disabled"
      :styling-mode="dxStyled"
      width="82px"
      value-expr="value"
      display-expr="label"
      @value-changed="handleChangedValue"
    />
  </div>
</template>
<script>
  import { DxSelectBox } from 'devextreme-vue/select-box';

  export default {
    name: 'time-box.vue',
    components: { DxSelectBox },
    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
      changedValue: {
        type: Function,
        required: false,
        default: () => {},
      },
    },
    data() {
      return {
        dxStyled: 'outlined',
        dataSource: {
          times: [],
          minutes: [],
        },
        form: {
          endH: '24',
          endM: '00',
          startH: '00',
          startM: '00',
        },
      };
    },
    methods: {
      /** 시간, 분 등 값 변경 시 실행되는 함수 */
      handleChangedValue() {
        this.changedValue();
      },
      /** 시작 및 종료 시간, 분 (startH, startM, endH, endM) 조회 */
      getFormData() {
        return this.form;
      },
      /** 시간, 분 변경 */
      updateFormData(startTime = '0000', endTime = '2400') {
        if (this.disabled) {
          return; // 비활성화 시 초기값으로 적용
        }
        const startH = startTime.slice(0, 2);
        const startM = startTime.slice(2, 4);
        const endH = endTime.slice(0, 2);
        const endM = endTime.slice(2, 4);
        this.form.endH = endH;
        this.form.endM = endM;
        this.form.startH = startH;
        this.form.startM = startM;
      },
      /** 진입 시 시간 셋팅 */
      initializeTimes() {
        this.dataSource.times = new Array(25).fill().map((v, i) => {
          const time = i < 10 ? `0${i}` : i.toString();
          return { label: time, value: time };
        });
      },
      /** 진입 시 분 셋팅 */
      initializeMinutes() {
        this.dataSource.minutes = ['00', '15', '30', '45'].map(v => ({
          label: v,
          value: v,
        }));
      },
    },
    mounted() {
      this.initializeTimes();
      this.initializeMinutes();
    },
  };
</script>
<style scoped></style>
