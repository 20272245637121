<!--
  PACKAGE_NAME : src/components/report/searchbox/condition/popover-search-condition.vue
  FILE_NAME : popover-search-condition.vue
  AUTHOR : vnemftnsska2
  DATE : 2024/07/09
  DESCRIPTION : 보고서 검색 컴포넌트 내 검색조건 팝오버
-->
<template>
  <transition name="slide">
    <span>
      <div class="popover px-3" :class="[{ hidden: !condition.isShow }]">
        <div class="py-3">
          <DxButton
            text="검색조건 추가"
            class="btn_XS default filled add"
            @click="openSaveModal"
            style="margin: 0; display: block !important"
          />
        </div>
        <div style="height: 300px">
          <DxDataGrid
            id="search-condition-grid"
            key-expr="id"
            :height="300"
            :data-source="condition.list"
            :show-borders="false"
            :show-column-lines="false"
            :show-column-headers="false"
            :show-row-lines="true"
            :column-auto-width="true"
            :hover-state-enabled="true"
            @cell-prepared="onCellPreparedStylingGrid"
            @cell-click="onClickSearchCondition"
            no-data-text="검색 조건이 존재하지 않습니다."
          >
            <DxScrolling mode="virtual" />
            <DxColumn data-field="title" />
            <DxColumn :width="50" cell-template="deleteTemplate" />
            <template #deleteTemplate="{ data }">
              <button class="btn-icon close" type="button" @click="handleClickSearchConditionDelete(data)" />
            </template>
          </DxDataGrid>
        </div>
      </div>
    </span>
  </transition>
</template>

<script>
  import { DxDataGrid, DxColumn, DxScrolling } from 'devextreme-vue/data-grid';
  import { DxButton } from 'devextreme-vue/button';
  import { getResData, isSuccess } from '@/plugins/common-lib';

  export default {
    components: { DxButton, DxScrolling, DxDataGrid, DxColumn },
    props: {
      /** 보고서 정보 */
      info: {
        type: Object,
        default: () => ({}),
      },
      /** 조회 조건 셋팅 */
      onClickSearchCondition: {
        type: Function,
        default: () => {},
      },
      /** 검색 조건 저장 모달 오픈 함수 */
      openSaveModal: {
        type: Function,
        default: () => {},
      },
    },
    data() {
      return {
        condition: {
          list: [], // 검색조건 리스트
          loading: false,
          isShow: false,
        },
      };
    },
    watch: {
      'condition.isShow'(isShow) {
        if (isShow) {
          this.asyncGetSearchCondition();
        }
      },
    },
    methods: {
      /**
       * 해당 컴포넌트 토글 기능
       * @param flag 토글 여부(true | false)
       */
      toggle(flag) {
        this.condition.isShow = flag;
      },
      /** 검색조건 리스트를 가지고 오는 API 실행 */
      async asyncGetSearchCondition() {
        const res = await this.CALL_REPORT_API({
          actionName: 'REPORT_SEARCH_PARAM_LIST',
          data: {
            menuId: this.info.menuId,
            memberId: this.$store.getters.getLoginId,
          },
          loading: false,
        });

        if (isSuccess(res)) {
          this.condition.list = getResData(res);
        }
      },
      /** 그리드 스타일링 */
      onCellPreparedStylingGrid(dxEvent) {
        if (dxEvent.columnIndex > 0) return;
        dxEvent.cellElement.classList.add('condition-row');
      },
      /** 검색조건 삭제 */
      async handleClickSearchConditionDelete({ data }) {
        if (data && data.id) {
          const res = await this.CALL_REPORT_API({
            actionName: 'REPORT_SEARCH_PARAM_DELETE',
            data: {},
            path: `/${data.id}`,
          });

          const toastMessage = isSuccess(res) ? '검색 조건이 삭제되었습니다.' : '작업실패';
          this.$_Toast(toastMessage);

          // 다시 조회
          await this.asyncGetSearchCondition();
        }
      },
    },
    created() {},
    mounted() {},
  };
</script>

<style lang="scss" scoped>
  .popover {
    top: 30px;
    right: -40px;
    width: 300px;
    z-index: 100;
    background: #fff;
    position: absolute;
    border-radius: 5px;
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
  }
</style>
<style>
  .dx-tag-content {
    padding: 3px 30px 3px 8px !important;
  }
  #search-condition-grid .dx-state-hover > .condition-row:hover {
    color: #6ab0b2 !important;
    cursor: pointer !important;
  }
</style>
