<template>
  <transition>
    <div class="grid-box">
      <esp-dx-data-grid
          :data-grid="dataGrid"
          ref="sheetGrid"
          @selection-changed="onSelectionChanged"
      ></esp-dx-data-grid>
    </div>
  </transition>
</template>

<script>
/**
 * 평가표 팝업
 *
 * @Author : Amie-orange
 * @Date : 2024-06-27
 * @Description :  이슈리스트 - 김찬영 차장 요청에 따라 시험 관리 시험지 선택 팝업(ui)과 동일 하게 변경 한다.<br>
 * row 선택 방식에서 '선택' 버튼 방식으로 변경 한다.<br>
 * */
import {isSuccess} from '@/plugins/common-lib';
import CustomStore from "devextreme/data/custom_store";
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid.vue";
let vm;

export default {
  components: {EspDxDataGrid},
  props: {
    modalData: Object,
  },
  watch: {},
  data() {
    return {
      codeMap: {},
      dataGrid: {
        refName: 'sheetGrid',
        showMenuColumns: true,
        allowColumnResizing: true,
        showBorders: true,
        showColumnHeaders: true,
        showColumnLines: false,
        showRowLines: true,
        rowAlternationEnabled: false,
        hoverStateEnabled: true,
        dataSource: [],
        callApi: 'CALL_EWM_API',
        // width:'200',     // 주석처리시 100%
        height:'550',
        showActionButtons: {
          select: false,
        },
        customEvent: {
          selectionChanged: true,
        },
        columnChooser: {
          enabled: false,
        },
        loadPanel: {
          enabled: true,
        },
        sorting: {
          mode: 'multiple',
        },
        scrolling: {
          mode: 'standard', //스크롤 모드 : ['infinite', 'standard', 'virtual']
        },
        remoteOperations: {
          filtering: false,
          sorting: false,
          grouping: false,
          paging: false,
        },
        paging: {
          enabled: false,
          pageSize: 10,
          pageIndex: 0,
        },
        pager: {
          visible: false, //페이저 표시 여부
          showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
          allowedPageSizes: [5, 10, 15, 20], //페이지 사이즈 선택 박스
          displayMode: 'compact', //표시 모드 : ['full', 'compact']
          showInfo: false, //페이지 정보 표시 여부 : full인 경우만 사용 가능
          showNavigationButtons: false, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
        },
        filterRow: {
          visible: true,
        },
        headerFilter: {
          visible: true,
        },
        editing: {
          allowUpdating: false,
          allowDeleting: false,
          allowAdding: false,
          mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
          startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
          selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
        },
        selecting: {
          mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple']
          selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
          showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
        },
        columns: [
          {
            caption: '평가구분',
            dataField: 'evalDivisionCd',
            height: 40,
            alignment: 'center',
            visible: true,
            lookup: {
              dataSource: this.modalData.evalDivisionCdDs,
              displayExpr: 'codeNm',
              valueExpr: 'codeId',
            },
            calculateSortValue: data => vm.calculateSortValue(data, 'evalDivisionCd'),
          },
          {
            caption: '평가표명',
            dataField: 'sheetNm',
            alignment: 'center',
            visible: true,
            allowSorting: true,
            allowHeaderFiltering: false,
          },
          {
            caption: '배점',
            dataField: 'sheetScore',
            alignment: 'center',
            visible: true,
            allowSorting: true,
            allowHeaderFiltering: false,
          },
          {
            caption: '평가이력',
            dataField: 'useFl',
            alignment: 'center',
            visible: true,
            allowSorting: true,
            allowHeaderFiltering: false,
          },
        ],
      },
      gridData: {
        columnDataSource: {
          useFl: [
            {cd: 'Y', nm: '사용'},
            {cd: 'N', nm: '미사용'},
          ],
        },
      },
      dataGridLoopup: {
        evalDivisionCd: {
          dataSource: [],
          displayExpr: 'codeNm',
          valueExpr: 'codeId',
        },
      },
      config: {
        stylingMode: 'outlined', //[outlined, filled, underlined]
      },
      searchType: {
        paramsData: {},
      },
      gridDataSource: null,
      formData: {
        sheetId: null,
        questionTypeCd: null,
        questionNm: null,
        parentId: null,
        questionOrder: null,
        delFl: 'N',
        regId: this.$store.getters.getLoginId,
      },
      limitNumberTexts: {
        textLengths: {},
        maxLengths: {
          questionNm: 85,
        },
      },
    };
  },
  computed: {
    ewmCodes() {
      return this.$_enums.ewm;
    },
  },
  methods: {
    /** @description : 소트설정 */
    calculateSortValue(data, clsCd) {
      if (clsCd == 'evalDivisionCd') {
        return this.dataGridLoopup.evalDivisionCd.dataSource.find(e => data.evalDivisionCd == e.codeId).codeNm;
      } else if (clsCd == 'useFl') {
        return this.$_enums.common.stringUsedFlag.values.find(e => data.useFl == e.value).label;
      }
    },
    async selectDataList() {
      this.dataGrid.dataSource = new CustomStore({
        key: 'id',
        async load(loadOptions) {
          const payload = {
            actionName: 'EWM_EVALUATION_SHEET_LIST',
            data: vm.searchType.paramsData,
            loading: false,
          };
          const res = await vm.CALL_EWM_API(payload);
          if (isSuccess(res)) {
            const rtnData = {
              data: res.data.data,
            };
            return rtnData;
          }
        },
      });
    },
    onSelectionChanged({selectedRowsData}) {
      const data = selectedRowsData[0];
      this.$emit('input', {sheetId: data.id, sheetNm: data.sheetNm});
    },
    /** @description : 라이프사이클 created 호출되는 메서드 */
    async createdData() {
      vm = this;
      this.searchType.paramsData.parentId = this.modalData.parentId;
      this.searchType.paramsData.viewFl = 'Y';
      this.dataGridLoopup.evalDivisionCd.dataSource = this.modalData.evalDivisionCdDs;
    },
    /** @description : 라이프사이클 mounted 호출되는 메서드 */
    mountedData() {
      this.selectDataList();
    },
    /** @description : 라이프사이클 beforeDestroyed 호출되는 메서드 */
    beforeDestroyedData() {
    },
    async initCodeMap() {
      this.codeMap = await this.$_getCodeMapList('root_ewm_eval_division');
    },
  },
  created() {
    this.createdData();
  },
  mounted() {
    this.mountedData();
  },
  beforeDestroy() {
    this.beforeDestroyedData();
  },
  destroyed() {
  },
};
</script>
