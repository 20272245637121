<!--
  PACKAGE_NAME : src\components\devextreme
  FILE_NAME : esp-dx-date-range-box
  AUTHOR : devyoon91
  DATE : 2024-09-20
  DESCRIPTION :
-->
<template>
	<div class="esp-common-search-box">
		<div class="esp-common-search-box-label" :hidden="labelHide">{{ label }}</div>
		<dx-date-box
			v-model="localStartDt"
			:styling-mode="stylingMode"
			:width="width"
			:type="type"
			:dateSerializationFormat="dateSerializationFormat"
			:display-format="displayFormat"
			:dateOutOfRangeMessage="dateOutOfRangeMessage"
			:invalidDateMessage="invalidDateMessage"
			:disabled="disabled"
			:hint="hint"
      :onValueChanged="onValueChanged"
      :on-key-up="(e) => handleKeyUp(e,'start')"
		>
			<dx-validator>
				<dx-required-rule :message="`${label}은(는) 필수입니다.`" />
			</dx-validator>
		</dx-date-box>
		<div class="ui-datepicker period">
			<span class="dash">~</span>
		</div>
		<dx-date-box
			v-model="localEndDt"
			:styling-mode="stylingMode"
			:width="width"
			:type="type"
			:dateSerializationFormat="dateSerializationFormat"
			:display-format="displayFormat"
			:dateOutOfRangeMessage="dateOutOfRangeMessage"
			:invalidDateMessage="invalidDateMessage"
			:disabled="disabled"
			:hint="hint"
      :onValueChanged="onValueChanged"
      :on-key-up="(e) => handleKeyUp(e,'end')"
		>
			<dx-validator>
				<dx-required-rule :message="`${label}은(는) 필수입니다.`" />
			</dx-validator>
    </dx-date-box>
	</div>
</template>

<script>
import { DxDateBox } from 'devextreme-vue/date-box';
import { getPastFromToday } from '@/plugins/common-lib';
import { DxRequiredRule, DxValidator } from 'devextreme-vue/validator';

export default {
	components: { DxRequiredRule, DxValidator, DxDateBox },
	props: {
		label: {
			type: String,
			default: '검색기간',
		},
		labelHide: {
			type: Boolean,
			default: false,
		},
		stylingMode: {
			type: String,
			default: 'outlined',
		},
		startDt: {
			type: String,
			default: getPastFromToday(1, 'days'),
		},
		endDt: {
			type: String,
			default: getPastFromToday(0, 'days'),
		},
		width: {
			type: Number,
			default: 120,
		},
		type: {
			type: String,
			default: 'date',
		},
		dateSerializationFormat: {
			type: String,
			default: 'yyyyMMdd',
		},
		displayFormat: {
			type: String,
			default: 'yyyy.MM.dd',
		},
		dateOutOfRangeMessage: {
			type: String,
			default: '종료일은 시작일보다 크거나 같아야 합니다.',
		},
		invalidDateMessage: {
			type: String,
			default: '날짜 형식이 올바르지 않습니다.',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		hint: {
			type: String,
			default: '날짜를 선택하세요.',
		},
    onValueChanged: {
      type: Function,
      default: () => {},
    },
	},
	data() {
    return {
      localStartDt: this.startDt,
      localEndDt: this.endDt,
      initialStartDate: '',
      initialEndDate: '',
    };
	},
  watch: {
    startDt(newVal) {
      this.localStartDt = newVal;
      this.initialStartDate = newVal;
    },
    endDt(newVal) {
      this.localEndDt = newVal;
      this.initialEndDate = newVal;
    },
    localStartDt(newVal) {
      this.setDate(newVal, 'start');
    },
    localEndDt(newVal) {
      this.setDate(newVal, 'end');
    }
  },
	computed: {},
	methods: {
    setDate(newVal, type) {
      const date = newVal.replace(/\D/g, '');

      if (type === 'start') {
        const endDate = this.localEndDt.replace(/\D/g, '');
        // 시작일이 종료일보다 클 경우 종료일도 시작일과 같게 변경
        if (date > endDate) {
          this.localEndDt = newVal;
        }
        this.localStartDt = newVal;
      } else if (type === 'end') {
        const startDate = this.localStartDt.replace(/\D/g, '');
        // 종료일이 시작일보다 작을 경우 시작일도 종료일과 같게 변경
        if (date < startDate) {
          this.localStartDt = newVal;
        }
        this.localEndDt = newVal;
      }
    },
    handleKeyUp(e, type) {
      const value = e.event.currentTarget.value.replace(/\D/g, '');
      if (value.length === 8) {
        this.setDate(value, type);
      }
    },
    /**
     * 날짜 반환
     * @returns {{startDt: string, endDt: string}}
     */
		getDates() {
			return {
				startDt: this.localStartDt,
				endDt: this.localEndDt,
			};
		},
    /**
     * 시작일 반환
     * @returns {string}
     */
		getStartDate() {
			return this.localStartDt;
		},
    /**
     * 종료일 반환
     * @returns {string}
     */
		getEndDate() {
			return this.localEndDt;
		},
    /**
     * 날짜 초기화
     */
    initDate() {
      this.localStartDt = this.initialStartDate;
      this.localEndDt = this.initialEndDate;
    },
    /**
     * 문자열 날짜 반환 (YYYYMMDD~YYYYMMDD)
     * @returns {string}
     */
    getStringDate() {
      return `${this.localStartDt.replace(/-/g, '')}~${this.localEndDt.replace(/-/g, '')}`;
    },
    /**
     * 문자열 일시 반환 (YYYYMMDDHHMISS~YYYYMMDDHHMISS)
     * @returns {string}
     */
    getStringDateTime() {
      return `${this.localStartDt.replace(/-/g, '')}000000~${this.localEndDt.replace(/-/g, '')}235959`;
    },
	},
	created() {
		this.initialStartDate = this.startDt;
		this.initialEndDate = this.endDt;
	},
};
</script>

<style lang="scss" scoped>
.esp-common-search-box {
	display: inline-flex;
	align-items: center;
	padding-right: 10px;
	.ui-datepicker {
		padding: 0 10px;
		.dash {
			font-size: 20px;
		}
	}
}
.esp-common-search-box-label {
  padding-right: 10px;
}
</style>
