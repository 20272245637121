<!--
  PACKAGE_NAME : src/pages/organize/personal/detail
  FILE_NAME : index.vue
  AUTHOR : jhcho
  DATE : 24. 7. 3.
  DESCRIPTION :
-->
<template>
  <div class="container">
    <div class="page-sub-box">

      <table class="table_form line-bin">
        <caption>
          <strong>{{ $_lang('COMPONENTS.PERSONAL_MANAGEMENT', { defaultValue: '인사관리' }) }}</strong>
        </caption>
        <colgroup>
          <col style="width: 130px;" />
          <col style="width: auto;" />
        </colgroup>
        <thead class="sub_title_txt">
        <tr>
          <td colspan="2">
            <h2>{{ $_lang('COMPONENTS.PERSONAL_INFORMATION', { defaultValue: '인사정보' }) }}</h2>
          </td>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th scope="row" class="alT">
            <label for="label01">
              <span>{{ $_lang('UC.WORD.USER_NO', { defaultValue: '사번' }) }}</span>
              <span class="icon_require">필수항목</span>
            </label>
          </th>
          <td class="clearfix">
            <DxTextBox
                :placeholder="$_lang('UC.WORD.USER_NO', { defaultValue: '사번' })"
                v-model="user.userNo"
                :disabled="updateYn"
                :show-clear-button="true"
                :max-length="10"
                :width="300"
                :height="30"
                styling-mode="outlined"
                class="mar_ri10"
            >
              <DxValidator validation-group="validationGroupName">
                <DxAsyncRule
                    :validation-callback="checkValidUserNo"
                    :message="$_lang('COMMON.MESSAGE.EMPLOYEE_NUMBER_ALREADY_IN_USE', { defaultValue: '이미 사용중인 사번입니다.' })"
                />
                <DxRequiredRule
                    :message="$_lang('COMMON.MESSAGE.REQUIRED_VALUE', { defaultValue: '필수값 입니다.' })"
                />
              </DxValidator>
            </DxTextBox>
          </td>
        </tr>
        <tr>
          <th scope="row" class="alT">
            <label for="label01">
              <span>{{ $_lang('COMPONENTS.NAME', { defaultValue: '이름' }) }}</span>
              <span class="icon_require">필수항목</span>
            </label>
          </th>
          <td class="clearfix">
            <DxTextBox
                :placeholder="$_lang('COMPONENTS.NAME', { defaultValue: '이름' })"
                v-model="user.userNm"
                :disabled="updateYn"
                :show-clear-button="true"
                :max-length="50"
                :width="300"
                :height="30"
                styling-mode="outlined"
                class="mar_ri10"
            >
              <DxValidator validation-group="validationGroupName">
                <DxRequiredRule :message="$_lang('COMMON.MESSAGE.REQUIRED_VALUE', { defaultValue: '필수값 입니다.' })" />
              </DxValidator>
            </DxTextBox>
          </td>
        </tr>
        <tr>
          <th scope="row" class="alT">
            <label for="label7">
              <span>{{ $_lang('COMPONENTS.DEPT', { defaultValue: '부서' }) }}</span>
              <span class="icon_require">필수항목</span>
            </label>
          </th>
          <td>
            <DxButton
                :text="$_lang('COMPONENTS.SELECT', { defaultValue: '선택' })"
                :width="100"
                :height="30"
                class="btn_XS white light_filled"
                @click="onOpenDeptModal"
            />
            <span v-if="user.selectedDeptDatas !== null">
            {{ deptChain }}
          </span>
          </td>
        </tr>
        <tr>
          <th scope="row" class="alT">
            <label for="label7">
              <span>{{ $_lang('COMPONENTS.GRADE', { defaultValue: '직위' }) }}</span>
            </label>
          </th>
          <td>
            <DxSelectBox
                :placeholder="$_lang('COMPONENTS.GRADE', { defaultValue: '직위' })"
                :items="gradeList"
                display-expr="gradeNm"
                value-expr="id"
                v-model="user.gradeId"
                styling-mode="outlined"
                :width="300"
                :height="30"
            >
            </DxSelectBox>
          </td>
        </tr>
        <tr>
          <th scope="row" class="alT">
            <label for="label01">
              <span>{{ $_lang('UC.WORD.NUMBER', { defaultValue: '내선번호' }) }}</span>
              <span class="icon_require">필수항목</span>
            </label>
          </th>
          <td class="clearfix">
            <DxTextBox
                v-model="user.dn"
                :placeholder="$_lang('UC.WORD.NUMBER', { defaultValue: '내선번호' })"
                :show-clear-button="true"
                :mask-rules="/[0-9]/"
                :max-length="11"
                :width="300"
                :height="30"
                styling-mode="outlined"
                class="mar_ri10"
            >
            </DxTextBox>
          </td>
        </tr>
        <tr>
          <th scope="row" class="alT">
            <label for="label01">
              <span>{{ $_lang('COMPONENTS.PHONE_NUMBER', { defaultValue: '전화번호' }) }}</span>
            </label>
          </th>
          <td class="clearfix">
            <DxTextBox
                v-model="user.mobile"
                mask="000-0000-0000"
                :mask-rules="{
                  X: /[02-9]/,
                }"
                :max-length="11"
                styling-mode="outlined"
                :width="300"
                :height="30"
            >
            </DxTextBox>
          </td>
        </tr>
        <tr>
          <th scope="row" class="alT">
            <label for="label01">
              <span>{{ $_lang('UC.WORD.HOT_LINE', { defaultValue: '직통번호' }) }}</span>
            </label>
          </th>
          <td class="clearfix">
            <DxTextBox
                v-model="user.hotLine"
                :max-length="13"
                styling-mode="outlined"
                :width="300"
                :height="30"
            >
            </DxTextBox>
          </td>
        </tr>
        <tr>
          <th scope="row" class="alT">
            <label for="label01">
              <span>{{ $_lang('UC.WORD.REC_PERMISSION', { defaultValue: '녹취 권한' }) }}</span>
            </label>
          </th>
          <td class="clearfix">
            <DxSelectBox
                placeholder="녹취 권한"
                :items="getStringUsedFlag"
                display-expr="label"
                value-expr="value"
                v-model="user.recFl"
                styling-mode="outlined"
                :width="300"
                :height="30"
            >
            </DxSelectBox>
          </td>
        </tr>
        <tr>
          <th scope="row" class="alT">
            <label for="label01">
              <span>{{ $_lang('COMPONENTS.USE_STATUS', { defaultValue: '사용 여부' }) }}</span>
            </label>
          </th>
          <td class="clearfix">
            <DxSelectBox
                placeholder="사용 여부"
                :items="getIntegerUsedFlag"
                display-expr="label"
                value-expr="value"
                v-model="user.viewFl"
                styling-mode="outlined"
                :width="300"
                :height="30"
            >
            </DxSelectBox>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <section class="terms bottom-btn-box">
      <div class="page-sub-box">
        <h2 class="hidden">일반 버튼</h2>
        <div class="bottom-btn-wrap">
          <DxButton
              :text="$_lang('COMPONENTS.SAVE', { defaultValue: '저장' })"
              :width="120"
              :height="40"
              class="default filled txt_S medium"
              validation-group="validationGroupName"
              :use-submit-behavior="true"
              @click="onSave"
          />
          <DxButton
              :text="$_lang('COMPONENTS.CANCEL', { defaultValue: '취소' })"
              :width="120"
              :height="40"
              class="white filled txt_S medium"
              @click="$_goPrePage"
          />
        </div>
      </div>
    </section>

    <DxPopup
        :show-title="true"
        :title="modal.initData ? modal.initData.title : null"
        :min-width="modal.initData ? modal.initData.width : null"
        :width="modal.initData ? modal.initData.width : null"
        :min-height="modal.initData ? modal.initData.height : null"
        :height="modal.initData ? modal.initData.height : null"
        :drag-enabled="true"
        :resize-enabled="true"
        :show-close-button="true"
        :hide-on-outside-click="false"
        :visible="modal.isOpened"
        @hiding="isOpenModal(false)"
    >
      <template #content>
        <div>
          <component
              ref="modalDeptRef"
              :is="modal.currentComponent"
              :contentData="modal.contentData"
              v-model="modal.contentData"
          ></component>
        </div>
      </template>

      <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          :options="{
            elementAttr: {
              class: 'default filled txt_S medium',
            },
            text: $_lang('COMPONENTS.SELECT', { defaultValue: '선택' }),
            width: '120',
            height: '40',
            useSubmitBehavior: true,
            onClick: onSelectedDept,
          }"
      />
      <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          :options="{
            elementAttr: {
              class: 'white filled txt_S medium',
            },
            text: $_lang('COMPONENTS.CANCEL', { defaultValue: '취소' }),
            width: '120',
            height: '40',
            onClick: () => isOpenModal(false),
          }"
      />
    </DxPopup>
  </div>
</template>
<script>
import ModalSelectDept from '@/pages/euc/organize/personal/modal-select-dept.vue';

import { DxRadioGroup } from "devextreme-vue/radio-group";
import { DxAsyncRule, DxRequiredRule, DxValidator} from "devextreme-vue/validator";
import { DxTextBox } from "devextreme-vue/text-box";
import { DxButton } from "devextreme-vue/button";
import { DxSelectBox } from "devextreme-vue/select-box";
import { isSuccess } from "@/plugins/common-lib";
import { DxPopup, DxToolbarItem } from "devextreme-vue/popup";

export default {
  components: {
    ModalSelectDept,
    DxPopup,
    DxToolbarItem,
    DxSelectBox,
    DxButton,
    DxRequiredRule,
    DxAsyncRule,
    DxTextBox,
    DxValidator,
    DxRadioGroup
  },
  data() {
    return {
      modal: {
        isOpened: false,
        currentComponent: null,
        initData: {},
        contentData: null,
      },
      updateYn: true,
      user: {
        id: null,
        userNo: '',
        userNm: '',
        deptId: null,
        gradeId: null,
        dn: '',
        mobile: '',
        hotLine: '',
        display: '',
        syncFl: 1,
      },
      member: {},
      deptList: [],
      gradeList: [],
    }
  },
  watch: {},
  computed: {
    getStringUsedFlag() {
      return this.$_enums.common.stringUsedFlag.values;
    },
    getIntegerUsedFlag() {
      return this.$_enums.common.integerUsedFlag.values;
    },
    deptChain() {
      let deptName = '';
      if(this.user?.deptId) {
        const dept = this.deptList.find(d => d.deptId === this.user.deptId);
        if(dept) {
          const deptNames = dept.nameTree.split('|')
          deptName = deptNames?.join(' > ') || '';
        }
      }
      return deptName;
    },
  },
  methods: {
    /**
     * @description: 저장 버튼 클릭 이벤트
     * */
    async onSave() {
      if(await this.$_Confirm(this.$_lang('COMMON.MESSAGE.CONFIRM_SAVE', { defaultValue: '저장하시겠습니까?' }))) {
        const payload = {
          actionName: 'USER_LIST_INSERT',
          data: {
            data: [this.user],
          },
          loading: true,
        };

        const res = await this.CALL_API(payload);
        if(isSuccess(res)) {
          await this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
          this.$_goPrePage();
        } else {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }), { icon: 'error' });
        }
      }
    },
    /**
     * @description: 부서 선택 팝업에서 선택 버튼 클릭 이벤트
     * */
    onSelectedDept() {
      const result = this.$refs.modalDeptRef.getSelectedDept();
      this.user.deptId = result.deptId;
      this.isOpenModal(false);
    },
    /**
     * @description: 초기 데이터 셋팅
     * */
    async callPromiseAllInitData() {
      const apiList = [
        this.callApiPayloadDeptData(),
        this.callApiPayloadGradeData(),
        this.callApiPayloadUser(),
        this.callApiPayloadMember()
      ];

      await Promise.all(apiList.map(async payload => {
        const res = await this.CALL_API(payload);
        payload.callback(res);
      }));
    },
    /** @description: 계정 데이터 조회 payload */
    callApiPayloadMember() {
      return {
        actionName: 'MEMBER_LIST_ALL',
        data: {
          params: { userId: this.user.id }
        },
        loading: false,
        callback: (res) => {
          if(isSuccess(res)) {
            this.member = res.data.data[0];
          }
        }
      };
    },
    /** @description: 인사 데이터 조회 payload */
    callApiPayloadUser() {
      return {
        actionName: 'USER_LIST_ALL_VIEW',
        data: {
          params: { id: this.user.id }
        },
        loading: false,
        callback: (res) => {
          if(isSuccess(res)) {
            this.user = res.data.data[0];
          }
        }
      };
    },
    /** @description: dept 관련 데이터 조회 payload */
    callApiPayloadDeptData() {
      return {
        actionName: 'DEPT_LIST_ALL_VIEW',
        data: {
          params: { viewFl: 'Y', pageSize: 10000 }
        },
        loading: false,
        callback: (res) => {
          if(isSuccess(res)) {
            let data = res.data.data;
            this.deptList = data.map(d => {
              d.id = d.deptId;
              return { ...d };
            });
          }
        }
      };
    },
    /** @description: grade 관련 데이터 조회 payload */
    callApiPayloadGradeData(sort = '+gradeOrd') {
      return {
        actionName: 'GRADE_LIST_SELECT',
        data: {
          params: { sort, viewFl: 'Y' }
        },
        loading: false,
        callback: (res) => {
          if(isSuccess(res)) {
            this.gradeList = res.data.data;
          }
        }
      };
    },
    onOpenDeptModal() {
      this.onOpenModal(
          'ModalSelectDept',
          {
            title: '부서 선택',
            buttons: {
              save: { text: '선택' },
              cancel: { text: '취소' },
            },
            width: 820,
            height: 500,
          },
      )
    },
    /** @description: 팝업 오픈시 메서드 */
    onOpenModal(componentNm, componentInitData, data) {
      this.modal.currentComponent = componentNm;
      this.modal.initData = componentInitData;
      this.modal.contentData = data;

      this.isOpenModal(true);
    },
    /** @description: 팝업 오픈 체크 메서드 */
    isOpenModal(data) {
      this.modal.isOpened = data;
      if (!data) {
        this.modal.currentComponent = null;
        this.modal.initData = {};
      }
    },
    /** @description : 사번 체크 true/false 리턴 이벤트 */
    async checkValidUserNo(e) {
      const payload = {
        actionName: 'USER_LIST_ALL_VIEW',
        data: {
          data: { userNo: e.value },
        },
        loading: false,
      };

      const res = await this.CALL_API(payload);
      if(isSuccess(res) && res.data.data.length === 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
  },
  async created() {
    this.reqParams = this.$store.getters.getDetailParams;
    if (!this.reqParams) {
      this.$_goPrePage();
      return;
    }

    this.updateYn = this.reqParams?.updateYn === true;

    if(this.updateYn) {
      this.user.id = this.reqParams.id;
    }

    await this.callPromiseAllInitData();
  }
}
</script>
<style scoped>
</style>