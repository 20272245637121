<!--
  PACKAGE_NAME : src/pages/cc/ivr/schedule/modal-dnis.vue
  FILE_NAME : modal-dnis
  AUTHOR : hmlee
  DATE : 2024-09-24
  DESCRIPTION : 대표번호 설정 모달
-->
<template>
  <esp-dx-modal-popup
    :option="{
        title: modal.title,
        width: modal.width,
        height: modal.height,
        minWidth: modal.minWidth,
        minHeight: modal.minHeight,
        closeOnOutsideClick: modal.hideOnOutsideClick,
        useSaveBtnDisabled: modal.useSaveBtnDisabled,
      }"
    :isOpen="isOpen"
    @saveModal="handleSaveModal"
    @closeModal="handleCloseModal"
  >
    <template #content>
      <div class="flex flex-row">
        <div class="basis-2/5 bg-white relative">
          <div class="flex flex-row absolute top-5 z-50">
            <h2 class="text-lg font-semibold">
              {{ $_lang('COMPONENTS.NOT_SETTING', { defaultValue: '미설정' }) }}
              {{ $_lang('CC.WORD.MAIN_NUMBER', { defaultValue: '대표번호' }) }}
            </h2>
            <esp-dx-popover
              target="dnisTooltip"
              :position="tooltip.position"
              :html="tooltip.contents"
            />
            <esp-dx-button
              color="white"
              mode="outlined"
              width="24px"
              height="24px"
              mdi-icon="sync"
              :hint="$_lang('CC.MESSAGE.RESET_MAIN_NUMBER', { defaultValue: '대표번호 설정 초기화' })"
              class="ml-2"
              @click="handleReset"
            />
          </div>
          <esp-dx-data-grid calss="relative" :data-grid="beforeDataGrid" :ref="beforeDataGrid.refName" @row-click="handleClickSelectedRow" />
        </div>
        <div class="basis-1/5 flex flex-col justify-center px-5 space-y-4">
          <esp-dx-button
            color="white"
            mode="outlined"
            width="24px"
            height="24px"
            mdi-icon="chevron-right"
            :hint="$_lang('COMPONENTS.ADD_SETTING', { defaultValue: '설정 추가' })"
            :disabled="button.disabled.clickedArrowType === 2"
            @click="handleSetSelectedDnis"
          />
          <esp-dx-button
            color="white"
            mode="outlined"
            width="24px"
            height="24px"
            mdi-icon="chevron-left"
            :hint="$_lang('COMPONENTS.REMOVE_SETTING', { defaultValue: '설정 해제' })"
            :disabled="button.disabled.clickedArrowType === 1"
            @click="handleRemoveSelectedDnis"
          />
        </div>
        <div class="basis-2/5 bg-white relative">
          <h2 class="text-lg font-semibold absolute top-5 z-50">
            {{ $_lang('COMPONENTS.SETTING', { defaultValue: '설정' }) }}
            {{ $_lang('CC.WORD.MAIN_NUMBER', { defaultValue: '대표번호' }) }}
          </h2>
          <esp-dx-data-grid :data-grid="afterDataGrid" :ref="afterDataGrid.refName" @row-click="handleClickSelectedRow" />
        </div>
      </div>
    </template>
  </esp-dx-modal-popup>
</template>

<script>
  import EspDxModalPopup from "@/components/devextreme/esp-dx-modal-popup.vue";
  import EspDxPopover from "@/components/devextreme/esp-dx-popover.vue";
  import EspDxButton from "@/components/devextreme/esp-dx-button.vue";
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid-v2.vue';
  import { isSuccess } from '@/plugins/common-lib';
  import CustomStore from 'devextreme/data/custom_store';

  export default {
    components: {
      EspDxModalPopup,
      EspDxPopover,
      EspDxButton,
      EspDxDataGrid,
    },
    props: {
      isOpen: {
        default: false,
        type: Boolean,
      },
      dnisData: {
        default: {},
        type: Object,
      },
    },
    data() {
      return {
        config: {
          stylingMode: 'outlined', //[outlined, filled, underlined]
          showClearButton: true,
          width: '100%',
        },
        modal: {
          title: this.$_lang('CC.WORD.MAIN_NUMBER_SETTING', { defaultValue: '대표번호 설정' }),
          minWidth: '300',
          width: '900',
          minHeight: '300',
          height: '580',
          hideOnOutsideClick: false,
          useSaveBtnDisabled: false, // 하단 저장 버튼 비활성화 여부(저장 중복 클릭 방지 설정시 사용)
        },
        tooltip: {
          position: 'top',
          contents: `이미 다른 운영스케줄이 설정된 대표번호는<br>
                  목록에서 노출되지 않습니다.`,
        },
        button: {
          disabled: {
            clickedArrowType: 0, // 0: 초기화(화살표 버튼 안눌렀을 때), 1: 설정추가(오른쪽화살표), 2: 설정해제(왼쪽화살표)
          },
        },
        formData: {}, // 폼 데이터
        dnisSettingChangedRows: [], //설정 대표번호에 설정 추가된(변경된 로우) 데이터
        beforeDataGrid: {
          callApi: 'CALL_CC_API',
          refName: 'beforeIvrDnisGrid',
          dataSourceDefaultSortColumn: '+ivrDnisOrd',
          dataSource: [],
          height: '440', // 주석처리시 100%
          showActionButtons: { // 그리드 버튼 노출 설정값
            update: false,
            delete: false,
          },
          page: {
            enabled: false,
          },
          columns: [
            {
              caption: '사이트',
              i18n: 'COMMON.WORD.SITE',
              dataField: 'siteId',
              lookup: {
                dataSource: this.$store.getters.getSiteList,
                displayExpr: 'siteNm',
                valueExpr: 'id',
              },
            },
            {
              caption: '센터',
              i18n: 'COMMON.WORD.TENANT',
              dataField: 'tenantId',
              lookup: {
                dataSource: this.$store.getters.getTenantList,
                displayExpr: 'tenantNm',
                valueExpr: 'id',
              },
            },
            {
              caption: 'DNIS',
              dataField: 'dnis',
              visible: false,
            },
            {
              caption: '대표번호',
              i18n: 'CC.WORD.MAIN_NUMBER',
              dataField: 'dnisNm',
            },
            {
              caption: '사용여부',
              i18n: 'COMPONENTS.USE_STATUS',
              dataField: 'viewFl',
              lookup: {
                dataSource: this.$_enums.common.stringUsedFlag.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
          ],
        },
        afterDataGrid: {
          callApi: 'CALL_CC_API',
          refName: 'afterIvrDnisGrid',
          dataSourceDefaultSortColumn: '+ivrDnisOrd',
          dataSource: [],
          height: '440', // 주석처리시 100%
          showActionButtons: { // 그리드 버튼 노출 설정값
            update: false,
            delete: false,
          },
          page: {
            enabled: false,
          },
          columns: [
            {
              caption: '사이트',
              i18n: 'COMMON.WORD.SITE',
              dataField: 'siteId',
              lookup: {
                dataSource: this.$store.getters.getSiteList,
                displayExpr: 'siteNm',
                valueExpr: 'id',
              },
            },
            {
              caption: '센터',
              i18n: 'COMMON.WORD.TENANT',
              dataField: 'tenantId',
              lookup: {
                dataSource: this.$store.getters.getTenantList,
                displayExpr: 'tenantNm',
                valueExpr: 'id',
              },
            },
            {
              caption: 'DNIS',
              dataField: 'dnis',
              visible: false,
            },
            {
              caption: '대표번호',
              i18n: 'CC.WORD.MAIN_NUMBER',
              dataField: 'dnisNm',
            },
            {
              caption: '사용여부',
              i18n: 'COMPONENTS.USE_STATUS',
              dataField: 'viewFl',
              lookup: {
                dataSource: this.$_enums.common.stringUsedFlag.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
          ],
        },
      };
    },
    methods: {
      /** @description: 대표번호 툴팁 이벤트 */
      setTooltips() {
        this.tooltip.isVisible = !this.tooltip.isVisible;
      },
      /** @description: 모달 저장 */
      async handleSaveModal() {
        //대표번호 설정이 없는 경우
        if (this.button.disabled.clickedArrowType === 0) {
          return this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
        }

        //저장 중복 클릭 방지 체크
        if( this.modal.useSaveBtnDisabled ) {
          return false;
        }
        this.modal.useSaveBtnDisabled = true; // 저장 중복 클릭 방지 설정

        let dnisScheduleDatas = [];
        if (this.button.disabled.clickedArrowType === 1) {
          //대표번호 설정 추가
          dnisScheduleDatas = this.dnisSettingChangedRows.map(data => {
            return {
              ...data,
              scheduleId: this.formData.id,
            };
          });
        } else if (this.button.disabled.clickedArrowType === 2) {
          //대표번호 설정 해제(삭제)
          dnisScheduleDatas = this.dnisSettingChangedRows.map(data => {
            return {
              ...data,
              scheduleId: null,
            };
          });
        }

        const payload = {
          actionName: 'CC_IVR_DNIS_SCHEDULE_ID',
          data: dnisScheduleDatas,
        };

        const res = await this.CALL_CC_API(payload);
        if (isSuccess(res)) {
          this.$emit('saveModal');
        }
        this.modal.useSaveBtnDisabled = false; // 저장 중복 클릭 방지 해제
      },
      /** @description: 모달 닫기 */
      handleCloseModal() {
        this.$emit('closeModal');
      },
      /** @description: 미설정 대표번호 데이터 조회
       *
       * @param sort
       * @returns {Promise<void>}
       */
      async selectBeforeDataList(sort = '+ivrDnisOrd') {
        this.beforeDataGrid.dataSource = new CustomStore({
          key: 'id',
          load: async(loadOptions) => {
            const params = this.$_getDxDataGridParam(loadOptions);

            if (!params.sort) params.sort = sort;

            const payload = {
              actionName: 'CC_IVR_DNIS_LIST',
              data: params,
            };

            const res = await this.CALL_CC_API(payload);
            let rtnData = {
              data: [],
              totalCount: 0,
            };
            if (isSuccess(res)) {
              const rstData = res.data.data.filter(dnis => dnis.scheduleId === null);
              rtnData = {
                data: rstData,
                totalCount: rstData.length,
              };
              this.$refs[this.beforeDataGrid.refName].totalCount = rtnData.totalCount;
            }
            return rtnData;
          },
        });
      },
      /* * @description: 설정 대표번호 데이터 조회
       * @param sort
       * @returns {Promise<void>}
       */
      async selectAfterDataList(sort = '+ivrDnisOrd') {
        this.afterDataGrid.dataSource = new CustomStore({
          key: 'id',
          load: async(loadOptions) => {
            const params = this.$_getDxDataGridParam(loadOptions);

            if (!params.sort) params.sort = sort;

            //운영스케줄로 설정 대표번호
            params.scheduleId = this.formData.id;

            const payload = {
              actionName: 'CC_IVR_DNIS_LIST',
              data: params,
            };

            const res = await this.CALL_CC_API(payload);
            let rtnData = {
              data: [],
              totalCount: 0,
            };
            if (isSuccess(res)) {
              rtnData = {
                data: res.data.data,
                totalCount: res.data.header.totalCount,
              };
              this.$refs[this.afterDataGrid.refName].totalCount = rtnData.totalCount;
            }
            return rtnData;
          },
        });
      },
      /** @description: 대표번호 설정 초기화 */
      handleReset(e) {
        //그리드 편집 초기화
        this.$refs[this.beforeDataGrid.refName].getInstance.cancelEditData();
        this.$refs[this.afterDataGrid.refName].getInstance.cancelEditData();

        //그리도 선택 초기화
        this.$refs[this.beforeDataGrid.refName].getInstance.deselectAll();
        this.$refs[this.afterDataGrid.refName].getInstance.deselectAll();

        //그리드 초기화
        this.$refs[this.beforeDataGrid.refName].refreshData();
        this.$refs[this.afterDataGrid.refName].refreshData();

        //설정/해제 버튼 초기화
        this.button.disabled.clickedArrowType = 0;

        //설정 dnis에 추가된 데이터 초기화
        this.dnisSettingChangedRows = [];
      },
      /** @description: 그리드 클릭시 체크되는 이벤트 */
      handleClickSelectedRow(e) {
        const selectedRowKeys = e.component.getSelectedRowKeys();
        const index = selectedRowKeys.indexOf(e.key);

        if (index === -1) { //선택되지 않은 경우 추가
          selectedRowKeys.push(e.key);
        } else { //선택된 경우 삭제
          selectedRowKeys.splice(index, 1);
        }

        e.component.selectRows(selectedRowKeys);
      },
      /** @description: 대표번호 설정 추가 */
      handleSetSelectedDnis(e) {
        //미설정 대표번호 선택된 데이터
        const selectedRowsData = this.$refs[this.beforeDataGrid.refName].getInstance.getSelectedRowsData();

        if (selectedRowsData.length === 0) {
          return this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
        }

        //설정 추가 버튼 클릭시
        this.button.disabled.clickedArrowType = 1;

        //그리드 초기화
        this.$refs[this.beforeDataGrid.refName].getInstance.cancelEditData();
        this.$refs[this.afterDataGrid.refName].getInstance.cancelEditData();

        //설정 대표번호에 추가
        this.dnisSettingChangedRows = selectedRowsData;
        selectedRowsData.forEach(row => {
          this.$refs[this.afterDataGrid.refName].getInstance.addRow();
        });
        selectedRowsData.forEach((obj, index) => {
          Object.entries(obj).forEach(([key, value]) => {
            this.$refs[this.afterDataGrid.refName].getInstance.cellValue(index, key, value);
          });
        });

        //미설정 대표번호에서 선택한 데이터 삭제
        let keys = selectedRowsData.map(item => {
          return this.$refs[this.beforeDataGrid.refName].getInstance.getRowIndexByKey(item.id);
        });
        keys.forEach(keyIdx => {
          this.$refs[this.beforeDataGrid.refName].getInstance.deleteRow(keyIdx);
        });
      },
      /** @description: 대표번호 설정 해제 */
      handleRemoveSelectedDnis(e) {
        //설정 대표번호 선택된 데이터
        const selectedRowsData = this.$refs[this.afterDataGrid.refName].getInstance.getSelectedRowsData();

        if (selectedRowsData.length === 0) {
          return this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
        }

        //설정 해제 버튼 클릭시
        this.button.disabled.clickedArrowType = 2;

        //그리드 초기화
        this.$refs[this.beforeDataGrid.refName].getInstance.cancelEditData();
        this.$refs[this.afterDataGrid.refName].getInstance.cancelEditData();

        //미설정 대표번호에 추가
        this.dnisSettingChangedRows = selectedRowsData;
        selectedRowsData.forEach(row => {
          this.$refs[this.beforeDataGrid.refName].getInstance.addRow();
        });
        selectedRowsData.forEach((obj, index) => {
          Object.entries(obj).forEach(([key, value]) => {
            this.$refs[this.beforeDataGrid.refName].getInstance.cellValue(index, key, value);
          });
        });

        //설정 대표번호에서 선택한 데이터 삭제
        const keys = selectedRowsData.map(item => {
          return this.$refs[this.afterDataGrid.refName].getInstance.getRowIndexByKey(item.id);
        });
        keys.forEach(keyIdx => {
          this.$refs[this.afterDataGrid.refName].getInstance.deleteRow(keyIdx);
        });
      },
      /** @description: 데이터 초기 설정 */
      initData() {
        this.formData = this.dnisData ? JSON.parse(JSON.stringify(this.dnisData)) : {};
      }
    },
    async created() {
      await this.initData();
      await this.selectBeforeDataList(); //미설정 대표번호 조회
      await this.selectAfterDataList(); //설정 대표번호 조회
    },
  };
</script>