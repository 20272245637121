<!--
  PACKAGE_NAME : src\pages\esp\system\menu\favorite.vue
  FILE_NAME : favorite
  AUTHOR : devyoon91
  DATE : 2024-08-29
  DESCRIPTION : 메뉴 즐겨찾기
-->
<template>
  <div style="display: block; width: 100%">
    <div class="grid grid-flow-row-dense grid-cols-6">
      <menu-select class="col-span-5" ref="menuSelect" @select-menu="handleSelectMenu" />
      <dx-button
        :text="$_lang('COMPONENTS.ADD')"
        class="btn_XS default filled col-span-1 self-end"
        style="margin-bottom: 15px"
        :disabled="isAddDisabled"
        @click="setMenuToGrid"
      />
    </div>
    <div class="grid grid-flow-row-dense grid-cols-1">
      <!-- 나의 즐겨찾기 메뉴 dxGrid -->
      <dx-data-grid
        ref="favoriteMenu"
        :height="287"
        :data-source="favoriteMenu"
        :onRowPrepared="handleGridRowPrepared"
        key-expr="menuId"
        :no-data-text="$_lang('COMMON.MESSAGE.CMN_NO_DATA')"
      >
        <dx-row-dragging :allow-reordering="true" :on-reorder="handleGridReorder" />
        <dx-sorting mode="none" />
        <dx-scrolling mode="virtual" />
        <dx-column :caption="$_lang('COMMON.WORD.MY_FAVORITE_MENU')" data-field="menuId" cell-template="menuNameTemplate" />
        <dx-column :width="100" :caption="$_lang('COMPONENTS.DELETE')" data-field="id" cell-template="deleteButton" />
        <template #menuNameTemplate="{ data }">
          <div style="text-align: left">
            {{ menuNameFromStore(data.value) }}
          </div>
        </template>

        <template #deleteButton="{ data }">
          <div class="grid place-items-center">
            <button class="btn-icon close" @click="handleDeleteClick(data)"/>
          </div>
        </template>
      </dx-data-grid>
    </div>
  </div>
</template>

<script>
  import MenuSelect from '@/pages/esp/system/menu/favorite-menu-select.vue';
  import { DxDataGrid, DxColumn, DxScrolling, DxRowDragging, DxSorting } from 'devextreme-vue/data-grid';
  import { DxButton } from 'devextreme-vue/button';
  import { isSuccess, isEmpty } from '@/plugins/common-lib';

  const arraysEqualWithOrder = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false;
    }

    for (let i = 0; i < arr1.length; i++) {
      const obj1 = arr1[i];
      const obj2 = arr2[i];

      if (JSON.stringify(obj1) !== JSON.stringify(obj2)) {
        return false;
      }
    }

    return true;
  };

  export default {
    components: {
      MenuSelect,
      DxDataGrid,
      DxColumn,
      DxScrolling,
      DxRowDragging,
      DxSorting,
      DxButton,
    },
    watch: {
      favoriteMenu() {
        this.$refs?.favoriteMenu?.instance?.refresh();
      },
    },
    data() {
      return {
        originMenu: [],
        favoriteMenu: [],
        deleteList: [],
        isChanged: false,
        allowedTypes: [
          this.$_enums.common.menuType.NORMAL_PAGE.value,
          this.$_enums.common.menuType.REPORT.value,
          this.$_enums.common.menuType.LINK.value,
        ],
        currentMenu: null, // 현재 선택된 메뉴 상태 관리
      };
    },
    computed: {
      /**
       * @description 선택된 메뉴 정보
       * @return {null|*}
       */
      selectedMenu() {
        return this.$refs?.menuSelect?.getLastSelectedMenu() ?? null;
      },
      /**
       * @description 메뉴 추가 버튼 비활성화 여부
       * @return {boolean}
       */
      isAddDisabled() {
        return !this.currentMenu || !this.allowedTypes.includes(this.currentMenu.menuTypeCd);
      },
    },
    methods: {
      /**
       * @description store에서 메뉴 정보 찾기
       * @param id
       * @return {*}
       */
      findMenuFromStore(id) {
        return this.$store.getters.getMenuList.find(d => d.id === id);
      },
      /**
       * @description store에서 메뉴명 찾기
       * @param id 메뉴 아이디
       * @return {string|string}
       */
      menuNameFromStore(id) {
        const getMenuPath = menuId => {
          const menu = this.findMenuFromStore(menuId);
          if (!menu) return [];

          const parentPath = menu.parentId ? getMenuPath(menu.parentId) : [];
          return [...parentPath, menu.menuNm];
        };

        const menuPath = getMenuPath(id);
        return menuPath.length > 0 ? menuPath.join(' > ') : '없음';
      },
      /**
       * @description 즐겨찾기 메뉴 순서 변경
       * @param newList
       */
      setReorderFavoriteMenu(newList = []) {
        let order = 1;
        this.favoriteMenu = newList.map(item => {
          return { ...item, order: order++ };
        });
      },
      /**
       * @description 즐겨찾기 메뉴 순서 변경 이벤트
       * @param e
       */
      handleGridReorder(e) {
        const visibleRows = e.component.getVisibleRows();
        const toIndex = this.favoriteMenu.findIndex(item => item.menuId === visibleRows[e.toIndex].data.menuId);
        const fromIndex = this.favoriteMenu.findIndex(item => item.menuId === e.itemData.menuId);
        const newList = [...this.favoriteMenu];

        newList.splice(fromIndex, 1);
        newList.splice(toIndex, 0, e.itemData);

        this.setReorderFavoriteMenu(newList);
      },
      /**
       * @description 그리드 row 스타일 변경 이벤트
       * @param e
       */
      handleGridRowPrepared(e) {
        if (e.rowType === 'data' && isEmpty(e.data.id)) {
          e.rowElement.bgColor = '#e5f7f9';
        }
      },
      /**
       * @description 즐겨찾기 메뉴 삭제 버튼 이벤트
       * @param e
       */
      handleDeleteClick(e) {
        const findId = e.data.menuId;
        const findIndex = this.favoriteMenu.findIndex(item => item.menuId === findId);
        if (findIndex > -1) {
          if (!isEmpty(e.data.id)) {
            this.deleteList.push({ menuId: findId });
          }
          this.favoriteMenu.splice(findIndex, 1);
        }
      },
      /**
       * @description 그리드 즐겨찾기 메뉴 추가
       */
      setMenuToGrid() {
        if (this.favoriteMenu.findIndex(item => item.menuId === this.selectedMenu.id) > -1) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.ALREADY_ADDED_MENU'));
          return;
        }

        this.favoriteMenu.push({
          id: null,
          menuId: this.selectedMenu.id,
          order: null,
        });
      },
      /**
       * @description 즐겨찾기 메뉴 삭제
       * @return {Promise<boolean>}
       */
      async deleteFavoriteMenu() {
        if (this.deleteList.length === 0) {
          return false;
        }

        const payload = {
          actionName: 'MENU_FAVORITE_DELETE',
          data: this.deleteList.map(item => {
            return {
              menuId: item.menuId,
            };
          }),
          loading: true,
          useErrorPopup: true,
        };
        await this.CALL_API(payload);
        this.isChanged = true;
      },
      /**
       * @description 즐겨찾기 메뉴 저장
       * @return {Promise<void>}
       */
      async saveFavoriteMenu() {
        // 기존 메뉴 순서와 다른 경우이거나 신규일때만 저장.
        const originalIndexMap = Object.fromEntries(this.originMenu.map((item, index) => [item.menuId, index]));
        const changedItems = this.favoriteMenu.filter((item, index) => isEmpty(item.id) || originalIndexMap[item.menuId] !== index);

        const payload = {
          actionName: 'MENU_FAVORITE_SAVE',
          data: changedItems.map(item => {
            return {
              menuId: item.menuId,
              order: item.order,
            };
          }),
          loading: true,
          useErrorPopup: true,
        };

        const res = await this.CALL_API(payload);

        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
        }
        this.isChanged = true;
      },
      /**
       * @description 즐겨찾기 메뉴 저장
       * @return {Promise<{closeFlag: boolean, isChanged: boolean}|{closeFlag: boolean, error: *}|{closeFlag: boolean, error}>}
       */
      async handleSaveFavoriteMenu() {
        try {
          if (!arraysEqualWithOrder(this.originMenu, this.favoriteMenu)) {
            if (this.deleteList.length > 0) {
              await this.deleteFavoriteMenu();
            }
            if (this.favoriteMenu.length) {
              await this.saveFavoriteMenu();
            }
            return { closeFlag: true, isChanged: this.isChanged };
          }
          return {
            closeFlag: false,
            error: this.$_lang('COMMON.MESSAGE.CMN_NO_CHANGED', { defaultValue: '변경된 데이터가 없습니다.' }),
          };
        } catch (e) {
          return { closeFlag: false, error: e.message };
        }
      },
      /**
       * @description 데이터 초기화
       * @return {Promise<void>}
       */
      async initData() {
        this.$refs.menuSelect.initData();
        this.setReorderFavoriteMenu(this.$store.getters.getFavoriteMenu);
        this.originMenu = [...this.favoriteMenu];
        this.deleteList = [];
        this.currentMenu = null;
        this.isChanged = false;
      },
      /**
       * @description 선택된 메뉴 변경 이벤트
       * @param menu
       */
      handleSelectMenu(menu) {
        this.currentMenu = menu;
      },
    },
    async mounted() {
      await this.initData();
    },
  };
</script>

<style scoped></style>
