<!--
  PACKAGE_NAME : src\pages\esp\system\server\routing.vue
  FILE_NAME : routing
  AUTHOR : devyoon91
  DATE : 2024-08-29
  DESCRIPTION : 라우팅 관리
-->
<template>
  <div class="page-sub-box">
    <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" @init-new-row="onInitNewRow" />
    <routing-host :is-open="popup.visible" @closeModal="onClosePopup" />
  </div>
</template>

<script>
  import { isSuccess } from '@/plugins/common-lib';
  import ArrayStore from 'devextreme/data/array_store';
  import RoutingHost from '@/pages/esp/system/server/host.vue';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: { EspDxDataGrid, RoutingHost },
    props: {},
    data() {
      return {
        popup: {
          visible: false,
        },
        dataGrid: {
          keyExpr: 'id', // 그리드 키값
          refName: 'refRoutingDataGrid', // 그리드 컴포넌트 참조명
          excel: {
            title: '라우팅관리', // 엑셀 다운로드 시 파일명
            autoFilterEnabled: true, // 엑셀 필터 사용 유무
          },
          focusedRowEnabled: true, // 포커스 행 표시 유무
          allowColumnReordering: false, // 컬럼 위치 변경 유무
          allowColumnResizing: true, // 컬럼 사이즈 조절 유무
          columnResizingMode: 'widget', // 컬럼 사이즈 조절 모드 : ['nextColumn', 'widget']
          columnAutoWidth: false, // 컬럼 사이즈 자동 조절 유무
          columnMinWidth: 100, // 컬럼 최소 사이즈
          showBorders: false, // 그리드 테두리 유무
          showColumnHeaders: true, // 컬럼 헤더 유무
          showColumnLines: false, // 컬럼 세로선 유무
          showRowLines: true, // 컬럼 가로선 유무
          rowAlternationEnabled: false, //행 배경색 교차 유무
          dataSource: [], // 그리드 데이터
          apiActionNm: {
            select: 'ROUTING_ACTIONS_LIST',
            merge: 'ROUTING_ACTIONS_MERGE',
            delete: 'ROUTING_ACTIONS_DELETE',
          }, // api 호출시 사용할 액션명(ESP - API URL 관리) : ['update', 'merge', 'delete', 'select']
          scrolling: {
            mode: 'standard', // 스크롤 모드 : ['standard', 'virtual', 'infinite']
          },
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
            initNewRow: true, // 신규 행 추가 이벤트
            rowClick: true, // 행 클릭 이벤트
            cellPrepared: false, // 셀 이벤트
            toolbarPreparing: false, // 툴바 이벤트
            // saving: true, // 저장 이벤트
          },
          showActionButtons: {
            excel: true, // 엑셀 다운로드 버튼
            delete: true,
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  text: '호스트 추가',
                  elementAttr: { class: 'btn_XS default filled add1' },
                  width: 100,
                  height: 30,
                  onClick: this.openPopup,
                },
                location: 'before',
              },
            ], // 커스텀 버튼
          },
          isDuplicateConfigKey: false, // 설정키 중복 체크
          grouping: {
            contextMenuEnabled: false, // 그룹핑 컨텍스트 메뉴 사용유무
            autoExpandAll: false, // 그룹핑시 전체 펼침 여부
            allowCollapsing: true, // 그룹핑시 접기 허용 여부
            expandMode: 'rowClick', // 그룹핑 펼침 모드 : ['rowClick', 'buttonClick']
          },
          groupPanel: {
            visible: false, // 그룹패널 표시 여부
          },
          columnChooser: {
            enabled: false, // 컬럼 선택 팝업 표시 여부
          },
          loadPanel: {
            enabled: true, // 로딩 패널 표시 여부
          },
          sorting: {
            mode: 'multiple', // 정렬 모드 : ['none', 'single', 'multiple']
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: true,
            grouping: false,
            paging: true,
          },
          paging: {
            enabled: true, // 페이징 사용 유무
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [], //페이지 사이즈 선택 박스
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: false, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: false, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true, // 필터 행 표시 여부
          },
          headerFilter: {
            visible: true, // 헤더 필터 표시 여부
          },
          editing: {
            allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: true, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false, // 삭제 버튼을 없애고 싶으면 false설정(Row 마다 휴지통 생성)
            mode: 'batch', // 행 편집 모드 : ['cell', 'row', 'batch']
            startEditAction: 'click', // 행 편집 시작 액션 : ['click', 'dblClick']
            selectTextOnEditStart: false, // 편집 시작시 텍스트 선택 여부
          },
          selecting: {
            mode: 'multiple', // 행 선택 모드 : ['none', 'single', 'multiple']
            allowSelectAll: true, // 전체 선택 허용 여부
            selectAllMode: 'page', // 전체 선택 모드 : ['page', 'allPages']
            showCheckBoxesMode: 'always', // 체크박스 표시 모드 : ['none', 'onClick', 'onLongTap', 'always']
            deferred: false, // 선택 지연 여부
          },
          searchPanel: {
            visible: false, // 검색 패널 표시 여부
          },
          columns: [
            {
              caption: '호스트 별칭',
              dataField: 'alias',
              visible: true,
              alignment: 'center',
              allowEditing: true,
              allowFiltering: true,
              allowHeaderFiltering: false,
              width: '150',
              lookup: {
                dataSource: [],
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
            {
              caption: '액션명',
              dataField: 'actionNm',
              visible: true,
              alignment: 'left',
              allowEditing: true,
              allowFiltering: true,
              allowHeaderFiltering: false,
              width: '300',
              requiredRule: {
                message: '액션명은 필수입니다.',
              },
            },
            {
              caption: 'METHOD',
              dataField: 'method',
              alignment: 'center',
              visible: true,
              allowEditing: true,
              allowFiltering: true,
              allowHeaderFiltering: false,
              width: '150',
              lookup: {
                dataSource: [{ method: 'GET' }, { method: 'POST' }, { method: 'PUT' }, { method: 'DELETE' }],
                displayExpr: 'method',
                valueExpr: 'method',
              },
              requiredRule: {
                message: 'method는 필수입니다.',
              },
            },
            {
              caption: 'PATH',
              dataField: 'path',
              alignment: 'left',
              visible: true,
              allowFiltering: true,
              allowHeaderFiltering: false,
              requiredRule: {
                message: 'API URL은 필수입니다.',
              },
            },
            {
              caption: '타임아웃(ms)',
              dataField: 'timeout',
              alignment: 'left',
              visible: true,
              allowFiltering: true,
              allowHeaderFiltering: false,
              requiredRule: {
                message: '타임 아웃 설정은 필수입니다.',
              },
            },
            {
              caption: '설명',
              dataField: 'description',
              alignment: 'left',
              visible: true,
              sortOrder: 'none',
              allowEditing: true,
              allowHeaderFiltering: false,
            },
            {
              caption: '작업이력 저장 여부',
              dataField: 'workLogFl',
              visible: true,
              sortOrder: 'none',
              alignment: 'center',
              allowEditing: true,
              allowHeaderFiltering: false,
              lookup: {
                dataSource: this.$_enums.common.stringUsedFlag.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
          ], // 컬럼 정보
        },
      };
    },
    computed: {},
    methods: {
      /**
       * 데이터 초기화
       */
      initData() {
        this.setRoutingAliases();
      },
      /**
       * 라우팅 별칭 목록 조회
       * @returns {Promise<void>}
       */
      async setRoutingAliases() {
        const payload = {
          actionName: 'ROUTING_ALIAS_LIST',
          loading: false,
          useErrorPopup: true,
        };
        const res = await this.CALL_API(payload);
        let aliases = [];
        if (isSuccess(res)) {
          res.data.data.forEach(item => {
            aliases.push({
              label: item.alias,
              value: item.alias,
            });
          });
        } else {
          aliases = [];
        }

        this.dataGrid.columns[0].lookup.dataSource = new ArrayStore({
          data: aliases,
          key: 'value',
        });
      },
      /**
       * 신규 행 추가 이벤트
       * @param e
       */
      onInitNewRow(e) {
        e.data.alias = 'esp';
        e.data.method = 'GET';
        e.data.path = '/';
        e.data.timeout = 5000;
        e.data.workLogFl = 'N';
      },
      /**
       * 팝업 닫기
       */
      onClosePopup() {
        this.popup.visible = false;
        this.dataGrid.refRoutingDataGrid.instance.refresh();
      },
      openPopup() {
        this.popup.visible = true;
      },
    },
    created() {
      this.initData();
    },
    mounted() {},
  };
</script>

<style lang="scss" scoped></style>
