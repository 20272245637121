<template>
    <div class="talk-channel-box clearfix">
        <div class="refresh-time-box fr">
            <span class="refresh-time-text">{{ getLastSearchDate }}</span>
            <button class="btn-icon" type="button" @click="selectDataList">
                <img src="@/assets/images/icon/refresh_icon.png"/>
            </button>
        </div>
        <template v-for="(items, key) in list">
            <CmItem :items="items" :name="key" :key="key" />
        </template>
    </div>
</template>
<script>
import { isSuccess, getPastFromToday } from "@/plugins/common-lib";
import CmItem from "./cm-item.vue";

export default {
    props: {
       dayStart: {
           type: String
       },
       dayEnd: {
           type: String
       }
    },
    components: {
        CmItem
    },
    computed: {
        getLastSearchDate() {
            return this.lastSearchDate;
        },
    },
    data(){
        return {
            lastSearchDate: null,
            list: null
        }
    },
    methods: {
        async selectDataList() {
            const currentTime = this.$_commonlib.moment().subtract(1, 'minutes');
            const hhmm = currentTime.format('HHmm');

            const params = {
                dayStart: this.dayStart,
                dayEnd: this.dayEnd,
                hh24mi: hhmm,
                report_type: 'daily',
                name: 'E_AES_CTI_LINK',
                type: 'master-query'
            };
            const payload = {
                actionName: 'MASTER_QUERY_RESULT_LIST',
                data: { data: params },
                loading: false,
            };

            this.lastSearchDate = `${currentTime.format('YYYY년 MM월 DD일')} ${currentTime.format('HH시 mm분')} 기준`;
            const res = await this.CALL_API(payload);

            if (isSuccess(res)) {
                if(res.data?.data?.length) {
                    this.list = Object.groupBy(res.data.data, (item) => item.CM_NM);
                }
            }
        },
    },
    mounted() {
        this.selectDataList();
    },
}

</script>
<style scoped>
.refresh-time-box {
    margin-bottom: 5px;
    align-items: center;
}
.refresh-time-text {
    color: #00c3c8;
}
</style>