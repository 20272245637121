<!--
  PACKAGE_NAME : src/pages/euc/device/setting/
  FILE_NAME : index.vue
  AUTHOR : jhsim
  DATE : 2024-04-19
  DESCRIPTION :
-->
<template>
  <div class="container">
    <Tabs ref="tabs" @selectedIndex="tabSelectedIndex" :tabType="1">
      <template v-for="(item, index) in getComponent">
        <Tab :title="item.title" :key="`Tab${index}`">
          <div v-if="tabIndex === index" class="locker_setting_list sub_new_style01 sub_ui_box1">
            <div class="cusmain-table-wrap">
              <component :is="item.component" :cmList="cmList" />
            </div>
          </div>
        </Tab>
      </template>
    </Tabs>
  </div>
</template>
<script>
  import Tabs from '@/components/common/tabs.vue';
  import Tab from '@/components/common/tab.vue';
  import HuntList from '@/pages/euc/device/hunt/huntList.vue';
  import HuntManage from '@/pages/euc/device/hunt/huntManage.vue';
  import { isSuccess } from '@/plugins/common-lib';

  export default {
    components: {
      Tabs,
      Tab,
      HuntList,
      HuntManage,
    },
    props: {},
    watch: {},
    data() {
      return {
        tabIndex: 0,
        cmList: [],
        tabs: {
          selectedIndex: this.$store.getters.getTabHists[this.$router.currentRoute.path],
        },
        config: {
          stylingMode: 'outlined', //outlined, underlined, filled
          pageSetting: {
            //pageData pageSetting 관련
            config: {},
            formData: {},
          },
        },
        tabItems: [
          {
            title: '헌트 관리',
            component: HuntManage,
            vender: ['CISCO'],
          },
          {
            title: '헌트 정보',
            component: HuntList,
            vender: ['CISCO'],
          },
        ],
      };
    },
    computed: {
      getVender() {
        return this.$_getSystemData('vender')?.configValue || 'CISCO';
      },
      getComponent() {
        return this.tabItems.filter(item => item.vender.includes(this.getVender));
      },
    },
    methods: {
      async createdData() {
        await this.selectCmList();
      },
      async tabSelectedIndex(index) {
        // cm data 변경 시 aes, svr에서 반영할 수 있도록 하기 위해 v-if 사용
        this.tabIndex = index;
      },
      async selectCmList(sort = '+cmOrd') {
        const params = { sort: sort, viewFl: 'Y' };
        const payload = {
          actionName: 'EUC_CM_LIST_ALL',
          data: params,
          loading: false,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          await this.setCmName(res);
        }
      },
      setCmName(res) {
        const length = res.data.data?.length;
        if (length > 0) {
          this.cmList = res.data.data;
          // FIXME 2024-04-11 jhsim cepm에서 cmNm, cmDescription을 같이주면 삭제
          for (let i = 0; i < this.cmList.length; i++) {
            delete this.cmList[i].id;
            delete this.cmList[i].cmIp;
            delete this.cmList[i].passwd;
            delete this.cmList[i].pinPasswd;
            delete this.cmList[i].regDt;
            delete this.cmList[i].siteId;
            delete this.cmList[i].tenantId;
            delete this.cmList[i].userId;
            delete this.cmList[i].vender;
            delete this.cmList[i].viewFl;
          }
        }
      },
    },
    created() {
      this.createdData();
    },
    mounted() {},
    destroyed() {},
  };
</script>

<style scoped></style>
