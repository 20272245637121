<template>
  <div style="height: 100%; width: 100%">
    <DxBox height="40px" direction="row" width="100%">
      <DxItem :ratio="0" :base-size="150">
        <template #default>
          <div>
            <DxDropDownButton
              :items="themeItems"
              :text="cmOptions.theme"
              @selection-changed="cmOptions.theme = $event.item"
              styling-mode="outlined"
            />
          </div>
        </template>
      </DxItem>
      <DxItem :ratio="1">
        <template #default>
          <div></div>
        </template>
      </DxItem>
      <DxItem :ratio="0" :base-size="65">
        <template #default>
          <div>
            <DxButton text="정렬" :width="60" :height="30" @click="codeBeautify" class="btn_XS light_filled refresh" />
          </div>
        </template>
      </DxItem>
      <DxItem :ratio="0" :base-size="65">
        <template #default>
          <div>
            <DxButton text="복사" :width="60" :height="30" @click="copyClipboard" class="btn_XS light_filled download" />
          </div>
        </template>
      </DxItem>
      <DxItem :ratio="0" :base-size="65">
        <template #default>
          <div>
            <DxButton text="검증" :width="60" :height="30" @click="validateJsonEditor" class="btn_XS light_filled preview" />
          </div>
        </template>
      </DxItem>
    </DxBox>
    <DxBox height="100%" direction="row" width="100%">
      <DxItem :ratio="1">
        <template #default>
          <div>
            <dx-scroll-view id="scrollview" ref="scrollViewWidget">
              <codemirror
                ref="cmEditor"
                v-model="code"
                :options="cmOptions"
                @ready="handleCmReady"
                @focus="handleCmFocus"
                @input="handleCmCodeChange"
                @keydown.tab.prevent="handleTabKeydown"
              />
            </dx-scroll-view>
          </div>
        </template>
      </DxItem>
    </DxBox>
  </div>
</template>

<script>
  import { codemirror } from 'vue-codemirror';
  import { DxBox, DxItem } from 'devextreme-vue/box';
  import DxButton from 'devextreme-vue/button';
  import { DxScrollView } from 'devextreme-vue/scroll-view';

  // import base style
  import 'codemirror/lib/codemirror.css';
  // import language js
  import 'codemirror/mode/javascript/javascript.js';
  import 'codemirror/mode/xml/xml.js';
  import 'codemirror/mode/vue/vue.js';

  // import theme style
  import 'codemirror/theme/monokai.css';
  import 'codemirror/theme/base16-dark.css';
  import 'codemirror/theme/paraiso-light.css';
  import 'codemirror/theme/3024-day.css';
  import 'codemirror/theme/3024-night.css';

  // require active-line.js
  import 'codemirror/addon/selection/active-line.js';

  // styleSelectedText
  import 'codemirror/addon/selection/mark-selection.js';

  // hint
  import 'codemirror/addon/hint/show-hint.js';
  import 'codemirror/addon/hint/show-hint.css';
  import 'codemirror/addon/hint/javascript-hint.js';
  import 'codemirror/addon/hint/xml-hint.js';
  import 'codemirror/addon/edit/closetag.js';

  // highlightSelectionMatches
  import 'codemirror/addon/scroll/annotatescrollbar.js';
  import 'codemirror/addon/search/matchesonscrollbar.js';
  import 'codemirror/addon/search/searchcursor.js';
  import 'codemirror/addon/search/match-highlighter.js';

  // keyMap
  import 'codemirror/mode/clike/clike.js';
  import 'codemirror/addon/edit/matchbrackets.js';
  import 'codemirror/addon/comment/comment.js';
  import 'codemirror/addon/dialog/dialog.js';
  import 'codemirror/addon/dialog/dialog.css';
  import 'codemirror/addon/search/search.js';
  import 'codemirror/keymap/sublime.js';

  // foldGutter
  import 'codemirror/addon/fold/foldgutter.css';
  import 'codemirror/addon/fold/brace-fold.js';
  import 'codemirror/addon/fold/comment-fold.js';
  import 'codemirror/addon/fold/foldcode.js';
  import 'codemirror/addon/fold/foldgutter.js';
  import 'codemirror/addon/fold/indent-fold.js';
  import 'codemirror/addon/fold/markdown-fold.js';
  import 'codemirror/addon/fold/xml-fold.js';

  import DxDropDownButton from 'devextreme-vue/drop-down-button';

  const beautify = require('js-beautify').js;
  const beautify_html = require('js-beautify').html;

  export default {
    components: {
      codemirror,
      DxBox,
      DxItem,
      DxButton,
      DxDropDownButton,
      DxScrollView,
    },
    props: {
      beautyType: String,
      value: String,
    },
    watch: {
      value: {
        handler(val) {
          if (val && typeof val === 'string') {
            this.code = val; // 유효한 문자열 데이터만 처리
          } else {
            this.code = ''; // 기본값 설정
          }
        },
        deep: true,
        immediate: true,
      },
    },
    data() {
      return {
        code: '',
        cmOptions: {
          tabSize: 4,
          styleActiveLine: false,
          styleSelectedText: false,
          line: true,
          lineNumbers: true,
          foldGutter: true,
          gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers'],
          highlightSelectionMatches: { showToken: /\w/, annotateScrollbar: true },
          mode: 'text/javascript',
          hintOptions: {
            completeSingle: false,
          },
          keyMap: 'sublime',
          matchBrackets: true,
          showCursorWhenSelecting: true,
          theme: 'default',
          extraKeys: { Ctrl: 'autocomplete' },
          lineWrapping: true,
          autoCloseTags: true,
        },
        themeItems: ['default', 'monokai', 'paraiso-light', 'base16-dark', '3024-day', '3024-night'],
        beautifyOption: {
          indent_size: 4,
          indent_char: ' ',
          max_preserve_newlines: '4',
          preserve_newlines: false,
          keep_array_indentation: true,
          break_chained_methods: false,
          indent_scripts: 'normal',
          brace_style: 'collapse',
          space_before_conditional: true,
          unescape_strings: false,
          jslint_happy: false,
          end_with_newline: false,
          wrap_line_length: '0',
          indent_inner_html: true,
          comma_first: false,
          e4x: false,
          indent_empty_lines: false,
          autofocus: true,
        },
      };
    },
    methods: {
      /**
       * @description 코드 정렬
       */
      codeBeautify() {
        if (this.beautyType === 'js') {
          this.code = beautify(this.code, this.beautifyOption);
        } else if (this.beautyType === 'html') {
          this.code = beautify_html(this.code, this.beautifyOption);
        }
      },
      /**
       * @description 클립보드에 복사
       */
      copyClipboard() {
        this.$_copyToClipboard(this.code);
      },
      /**
       * @description 코드 에디터 준비 이벤트
       * @param cm
       */
      handleCmReady(cm) {
        this.$nextTick(() => {
          cm.refresh(); // DOM 변화 후 다시 그리기
        });
      },
      /**
       * @description 코드 에디터 포커스 이벤트
       * @param cm
       */
      handleCmFocus(cm) {
      },
      /**
       * @description 코드 변경 이벤트
       * @param newCode
       */
      handleCmCodeChange(newCode) {
        this.code = newCode;
        this.$emit('input', this.code);
      },
      /**
       * @description 탭키 입력시 공백 추가
       * @param event
       */
      handleTabKeydown(event) {
        if (event.keyCode === 9) {
          event.preventDefault();
          const start = this.$refs.cmEditor.getCursor('start');
          const end = this.$refs.cmEditor.getCursor('end');
          const spaces = Array(this.options.tabSize + 1).join(' ');
          this.code = this.code.slice(0, start.ch) + spaces + this.code.slice(end.ch);
          this.$refs.cmEditor.setCursor(start.line, start.ch + this.options.tabSize);
        }
      },
      /**
       * @description 모드 설정
       * @param mode
       */
      setMode(mode) {
        if (mode) {
          this.cmOptions.mode = mode;
        }
      },
      /**
       * @description JSON 검증
       * @return {Promise<boolean>}
       */
      async validateJsonEditor() {
        const pageData = this.code;

        try {
          const jsonStr = JSON.parse(pageData);
          this.$_Msg('검증 완료되었습니다.');
          return typeof jsonStr === 'object';
        } catch (e) {
          this.$_Msg('검증 실패하였습니다.');
          return false;
        }
      },
    },
    computed: {},
    created() {
      this.setMode(this.mode);
    },
  };
</script>
<style scoped></style>
