<template>
  <div class="page-title">
    <h1>{{ currentMenu.menuNm }}</h1>
    <div>
      <button v-if="validateStoreFavoriteMenu" class="rounded-button on-star" @click="deleteFavorite"></button>
      <button v-else class="rounded-button off-star" @click="saveFavorite"></button>
    </div>
    <div class="page-text-map-group fr" v-if="!hidingMenuGroup">
      <span><router-link to="/">홈</router-link></span>
      <span v-for="menu in menuArray" :key="menu.menuNm">
        <router-link v-if="menu.pageUrl" :to="menu.pageUrl">{{ menu.menuNm }}</router-link>
        <span v-else>{{ menu.menuNm }}</span>
      </span>
    </div>
  </div>
</template>

<script>
  export default {
    computed: {
      menuId() {
        return parseInt(this.$route.meta.menuId || this.$route.name) || 0;
      },
      currentMenu() {
        const defaultMenu = {
          menuNm: '종합현황',
          menuDepth: 1,
          menuTypeCd: this.$_enums.common.menuType.NORMAL_PAGE,
        };

        if (this.menuId === 0) {
          return defaultMenu;
        }

        const menu = this.$store.getters.getMenuList.find(menu => menu.id === this.menuId);
        return {
          menuNm: menu.menuNm,
          menuDepth: menu.menuDepth,
          parentId: menu.parentId,
          menuTypeCd: menu.menuTypeCd,
          pageUrl: menu.pageUrl || '', // 기본 경로로 빈 문자열을 설정
        };
      },
      hidingMenuGroup() {
        return this.menuId === 0;
      },
      menuArray() {
        const menuArray = [];
        let menu = this.currentMenu;

        // 현재 메뉴를 배열에 추가
        menuArray.unshift(menu);

        // 상위 메뉴들을 배열에 추가
        while (menu.menuDepth > 1) {
          menu = this.getMenuById(menu.parentId);
          menuArray.unshift(menu);
        }

        return menuArray;
      },
      /**
       * @description 즐겨찾기 메뉴인지 확인합니다.
       * @return {*}
       */
      validateStoreFavoriteMenu() {
        return this.$store.getters.getFavoriteMenu.some(d => d.menuId === this.menuId);
      },
    },
    methods: {
      /**
       * @description 메뉴 ID로 메뉴 정보를 조회합니다.
       * @param id
       * @return {*}
       */
      getMenuById(id) {
        return this.$store.getters.getMenuList.find(menu => menu.id === id);
      },
      /**
       * @description 즐겨찾기 메뉴를 삭제합니다.
       */
      async deleteFavorite() {
        const payload = {
          actionName: 'MENU_FAVORITE_DELETE',
          data: [
            {
              menuId: this.menuId,
            },
          ],
          loading: true,
          useErrorPopup: true,
        };
        await this.toggleFavorite(payload);
      },
      /**
       * @description 즐겨찾기 메뉴를 저장합니다.
       * @return {Promise<void>}
       */
      async saveFavorite() {
        const payload = {
          actionName: 'MENU_FAVORITE_SAVE',
          data: [{ menuId: this.menuId }],
          loading: true,
          useErrorPopup: true,
        };
        await this.toggleFavorite(payload);
      },
      /**
       * @description 즐겨찾기 메뉴를 토글합니다.
       * @param payload
       * @return {Promise<void>}
       */
      async toggleFavorite(payload) {
        await this.CALL_API(payload);
        await this.$store.dispatch('INIT_FAVORITE_MENU');
      },
    },
  };
</script>

<style scoped>
  /* 공통 스타일 정의 */
  .rounded-button {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-size: cover;
    cursor: pointer;
    outline: none;
    border: none;
    top: 5px;
    left: -5px;
  }

  .rounded-button:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }

  .page-title {
    padding: 15px 0 20px 0;
    z-index: 2;
  }

  .page-title > h1 {
    color: #fff;
    float: left;
    height: 30px;
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    padding: 0 20px;
    display: table;
  }

  .page-title:after {
    opacity: 0;
    content: '';
    position: absolute;
    display: block;
    top: calc(50% - -30px);
    width: calc(100% - 0px);
    height: 1px;
    background: #ebebeb;
    margin: 0 0;
  }

  .page-text-map-group {
    top: -5px;
    right: 20px;
  }

  .page-text-map-group span {
    padding: 0 11px 0 3px;
    font-size: 13px;
    min-height: 26px;
    line-height: 15px;
    font-weight: 400;
    color: #fff;
    background: url(../assets/images/etc/arr_right_w.png) no-repeat center right 0;
    background-size: 5px;
  }

  .page-text-map-group span:last-child {
    padding: 0 0 0 3px;
    font-size: 13px;
    min-height: 26px;
    line-height: 15px;
    background: transparent;
    background-size: 5px;
    color: #fff;
    font-weight: 400;
  }
</style>
