<!--
  PACKAGE_NAME : src/pages/euc/phone/request/items
  FILE_NAME : items.vue.vue
  AUTHOR : jhcho
  DATE : 2024-05-21
  DESCRIPTION :
-->
<template>
  <div class="locker_setting_list sub_new_style01 sub_ui_box1">
    <Search
      codeKey="null"
      :customSearchList="searchType.list"
      :searchObj="searchType.obj"
      @change-search-type="$_changeSearchType"
      @onSearchClick="searchData($event)"
    >
      <template v-slot:before>
        <DxSelectBox
          v-model="searchType.customTypes.viewFl"
          :placeholder="$_lang('COMPONENTS.USE_STATUS', { defaultValue: '사용여부' })"
          :items="getViewFlList"
          display-expr="label"
          value-expr="value"
          styling-mode="outlined"
          :width="120"
          :height="30"
          @value-changed="$_changeSearchCustomType('viewFl', $event)"
        />
      </template>
    </Search>
    <esp-dx-data-grid :data-grid="dataGrid" ref="typeGrid" @init-new-row="onInitNewRow" @reorder="onReorder" />
  </div>
</template>
<script>
  import Search from '@/components/common/search.vue';
  import { isSuccess, formatDate, isEmpty } from '@/plugins/common-lib';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: {
      EspDxDataGrid,
      DxSelectBox,
      Search,
    },
    data() {
      return {
        searchType: {
          list: [
            {
              codeNm: this.$_lang('UC.WORD.TYPE_NAME', { defaultValue: '유형명' }),
              codeValue: 'typeNm',
            },
            {
              codeNm: this.$_lang('COMPONENTS.DESCRIPTION', { defaultValue: '설명' }),
              codeValue: 'description',
            },
          ],
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: this.$_lang('COMPONENTS.ALL', { defaultValue: '전체' }) },
          defaultValue: 'ROOT',
          customTypes: {
            viewFl: null,
          },
          paramsData: null,
        },
        dataGrid: {
          excel: {
            title: this.$_lang('UC.WORD.TYPE_MANAGEMENT', { defaultValue: '유형 관리' }),
          },
          callApi: 'CALL_EUC_API',
          refName: 'typeGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          height: 'calc(100vh - 295px)', // 주석처리시 100%
          apiActionNm: {
            loading: true,
            update: 'EUC_PHONE_REQUEST_TYPE_UPDATE',
          },
          showActionButtons: {
            save: true,
            excel: true,
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '삭제',
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 60,
                  height: 30,
                  onClick: () => {
                    this.deleteItems();
                  },
                },
                location: 'before',
              },
            ],
          },
          customEvent: {
            initNewRow: true,
            reorder: true,
          },
          isDuplicateConfigKey: false, //설정키 중복 체크
          dragging: {
            sortColumn: 'typeOrd',
            allowReordering: true,
            dropFeedbackMode: 'push', // 설정하면 dragging 할때 기존리스트가 아래로 움직이는 효과
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: true, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '유형',
              i18n: 'UC.WORD.TYPE',
              dataField: 'typeNm',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              requiredRule: {
                message: '필수값입니다.',
                i18n: 'COMMON.REQUIRED_VALUE',
              },
              cellTemplate: (container, options) => {
                if (options.row.isEditing) {
                  container.innerText = options.value;
                } else {
                  if (options.value) {
                    const aTag = document.createElement('a');
                    aTag.addEventListener('click', () => {
                      this.$store.commit('setDetailParams', options.data);
                      this.$router.push({ path: '/euc/phone/request/type/table' });
                    });
                    aTag.innerText = options.value;
                    container.append(aTag);
                  }
                }
              },
            },
            {
              caption: '설명',
              i18n: 'COMPONENTS.DESCRIPTION',
              dataField: 'description',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              requiredRule: {
                message: '필수값입니다.',
                i18n: 'COMMON.REQUIRED_VALUE',
              },
            },
            {
              caption: '상세 유형의 수',
              i18n: 'UC.WORD.DETAILED_TYPE_COUNT',
              dataField: 'detailCnt',
              width: 130,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '순서',
              i18n: 'COMPONENTS.ORDER',
              dataField: 'typeOrd',
              width: 90,
              height: 40,
              alignment: 'center',
              visible: false,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '사용여부',
              i18n: 'COMPONENTS.USE_STATUS',
              dataField: 'viewFl',
              width: 110,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              lookup: {
                dataSource: this.$_enums.common.stringUsedFlag.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
            {
              caption: '수정자',
              i18n: 'COMPONENTS.MODIFIER',
              dataField: 'editId',
              width: 120,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '수정일',
              i18n: 'COMPONENTS.MODIFY_DATE',
              dataField: 'editDt',
              width: 160,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              calculateCellValue: data => this.formatDt(data.editDt),
            },
          ],
        },
      };
    },
    computed: {
      getViewFlList() {
        return [
          { label: this.$_lang('COMPONENTS.ALL', { defaultValue: '전체' }), value: null },
          ...this.$_enums.common.stringUsedFlag.values,
        ];
      },
    },
    methods: {
      async onReorder(e) {
        const visibleRows = e.component.getVisibleRows();
        const newOrderIndex = visibleRows[e.toIndex].data.typeOrd;

        const params = {
          id: e.itemData.id,
          typeOrd: newOrderIndex - 1,
        };

        const payload = {
          actionName: 'EUC_PHONE_REQUEST_TYPE_UPDATE',
          data: [params],
          loading: false,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          this.$refs.typeGrid.refreshData();
        }
      },
      /**
       * @description 새로운 행이 초기화될 때 호출되는 메서드
       * @param {Object} data - 새로운 행 데이터
       */
      onInitNewRow(data) {
        data.data.viewFl = 'Y';
      },
      async deleteItems() {
        const deletedIds = this.$refs.typeGrid.selectedRowsData.map(d => {
          return { id: d.id };
        });

        if (!deletedIds?.length) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return false;
        }

        if (
          await this.$_Confirm(
            this.$_lang('COMMON.MESSAGE.CMN_CFM_DELETE_SELECTED', { defaultValue: '선택한 데이터를 삭제하시겠습니까?' }),
          )
        ) {
          const payload = {
            actionName: 'EUC_PHONE_REQUEST_TYPE_DELETE',
            data: {
              data: deletedIds,
            },
            loading: true,
          };

          const res = await this.CALL_EUC_API(payload);

          if (isSuccess(res)) {
            this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUC_DELETE', { defaultValue: '정상적으로 삭제되었습니다.' }));
            this.$refs.typeGrid.refreshData();
          } else {
            this.$_Msg(
              `${this.$_lang('COMMON.MESSAGE.CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' })} \n 원인 : [${
                res.header?.resMsg ?? 'HTTP 에러'
              }]`,
            );
          }
        }
      },
      searchData(paramsData) {
        //검색 버튼 클릭시 이벤트
        if (paramsData) {
          this.searchType.paramsData = { ...this.searchType.paramsData, ...paramsData };
        } else {
          //검색 키워드의 값이 없으면
          //검색키는 설정하고, 검색어를 입력하지 않으면, 전체 목록 출력
          let codeValues = this.searchType.list.map(d => d.codeValue);
          if (!isEmpty(this.searchType.paramsData)) {
            Object.keys(this.searchType.paramsData).forEach(key => {
              if (codeValues.includes(key)) {
                delete this.searchType.paramsData[key]; //검색 조건 키 삭제
              }
            });
          }
        }
        this.selectDataList();
      },
      /**
       * @description 데이터 목록 조회
       * @param {string} sort - 정렬
       */
      async selectDataList(sort = '+typeOrd') {
        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);

            if (!params.sort) {
              params.sort = sort;
            }

            params = { ...params, ...this.searchType.paramsData };

            const payload = {
              actionName: 'EUC_PHONE_REQUEST_TYPE_SELECT',
              data: params,
              loading: false,
            };

            const rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await this.CALL_EUC_API(payload);
            if (isSuccess(res)) {
              rtnData.data = res.data.data;
              rtnData.totalCount = res.data.header.totalCount;
            }
            return rtnData;
          },
        });
      },
      /** @description: 일자 데이터 출력 */
      formatDt(date) {
        if (date) {
          return formatDate(date, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
        }
      },
    },
    mounted() {
      this.selectDataList();
    },
    created() {},
  };
</script>
<style scoped></style>