export default {
  MESSAGE: {
    SYSTEM_ERROR: '시스템 오류 입니다. 관리자에게 문의하세요.',
    CHECK_DEPT_DEPTH: '부서 추가는 4단계까지만 가능합니다.',
    CHECK_MENU_DEPTH: '메뉴추가는 4단계까지만 가능합니다.',
    CHANGED_ACTIVE: '선택한 계정을 활성 상태로 변경되었습니다.',
    CMN_CFM_DELETE: '삭제하시겠습니까?',
    CMN_CFM_DELETE_SELECTED: '선택한 데이터를 삭제하시겠습니까?',
    CMN_CFM_DUPLICATE_SELECTED: '선택한 데이터를 복사하시겠습니까?',
    CMN_CFM_SAVE: '저장하시겠습니까?',
    CMN_ERROR: '데이터 처리 중 오류가 발생하였습니다.',
    CMN_ERROR_INTERNAL_SERVER: '서버 오류 입니다.',
    CMN_ERROR_TIMEOUT: '설정된 요청 시간({timeout})ms 보다 초과되었습니다.',
    CMN_NO_ADD: '데이터를 더이상 추가할 수 없습니다.',
    CMN_NO_CHANGED: '변경된 데이터가 없습니다.',
    CMN_NO_DATA: '데이터가 없습니다.',
    CMN_NOT_SELECTED: '대상이 선택되어 있지 않습니다.',
    CMN_ONE_SELECT: '하나의 대상만 선택해주세요.',
    CMN_PREPARING: '준비중입니다.',
    CMN_SUC_DELETE: '정상적으로 삭제되었습니다.',
    CMN_SUC_SAVE: '정상적으로 저장되었습니다.',
    CMN_SUC_UPDATE: '정상적으로 변경되었습니다.',
    CMN_SUC_UPLOAD: '정상적으로 업로드되었습니다.',
    CMN_SUCCESS: '정상적으로 처리되었습니다.',
    DISALLOWED_FILE: '허용하지 않은 파일 형식입니다.',
    EXIST_ACCOUNT: '선택한 대상에 이미 계정이 존재합니다.',
    EXIST_PERSONAL: '선택한 재직상태에 해당하는 인사정보 대상이 존재합니다.',
    EXIST_UNCHANGED_DATA: '변경되지 않은 데이터가 있습니다.',
    CANNOT_BE_DEL_ACCOUNT_AUTH: '사용자에게 부여된 권한이 있어 삭제가 불가능합니다.',
    OVER_SIZE_FILE: '파일이 허용된 크키({size})를 초과하여 업로드 할 수 없습니다.',
    OVER_LICENSE_COUNT: '등록 가능한 라이센스 수를 초과하여<br/>계정을 더 이상 등록할 수 없습니다.',
    PWD_INVALID: '유효하지 않는 패스워드 입니다.',
    PWD_NON_EMPTY: '패스워드는 공백 없이 입력해주세요.',
    PWD_NON_KR: '패스워드에 한글을 사용 할 수 없습니다.',
    REQUIRED_VALUE: '필수값 입니다.',
    REQUIRED_VALUE_IS: '[{value}] 은/는 필수값 입니다.',
    REQUIRED_FOR_CHILD_CODE: '하위 코드 값을 입력하셔야 합니다.',
    REQUIRED_IF_CHILD_CODE_PRESENT: '하위 코드 값을 입력하셨다면,<br/>하위 코드명 데이터를 추가하셔야 합니다.',
    REQUIRED_DEPT_SEARCH: '검색대상노출의 부서 선택은 필수입니다.',
    REQUIRED_DEPT_ADMIN: '사용자유형이 "부서관리자"인 경우<br/>부서 선택은 필수입니다.',
    REQUIRED_FILE_ADDITION: '업로드할 파일을 추가해주세요.',
    REQUIRED_PARENT_CODE: '상위 코드명 데이터를 추가하셔야 합니다.',
    REQUIRED_PARENT_DEPT: '하위부서를 추가할 상위부서를 선택하세요.',
    REQUIRED_PARENT_MENU: '하위메뉴를 추가할 상위메뉴를 선택하세요.',
    REQUIRED_SELECT_USER: '사용자를 선택해주세요.',
    REQUIRED_SELECT_DEPT: '부서를 먼저 선택해주세요.',
    REQUIRED_DATE: '날짜를 선택하세요.',
    SEARCH_DATE_RANGE_ERROR: '종료일은 시작일보다 크거나 같아야 합니다.',
    REQUIRED_DOWNLOAD_REASON: '다운로드 사유를 입력하세요.',
    INVALID_DATE_FORMAT: '날짜 형식이 올바르지 않습니다.',
    PLEASE_SELECT_DATE: '날짜를 선택해주세요.',
    SEARCH_TIME_MAX_RANGE_ERROR: '시작시간은 종료시간보다 작거나 같아야 합니다.',
    SEARCH_TIME_MIN_RANGE_ERROR: '종료시간은 시작시간보다 크거나 같아야 합니다.',
    REQUIRED_VALUE_VALIDATION_ERROR: '필수값을 입력해주세요.',
    PLEASE_SAVE_THE_CHANGES: '변경 사항을 저장하세요.',
    EMPLOYEE_NUMBER_ALREADY_IN_USE: '이미 사용중인 사번입니다.',
    ERROR_AUTH_DATA_FETCH: '권한 데이터 조회 오류',
    NUMBER_ENTER: '숫자만 입력하세요.',
    PLEASE_SELECT_TARGET: '대상을 선택해주세요.',
    PLEASE_SELECT_TO: '{target}을/를 선택해주세요.',
    PLEASE_SELECT_SETTING: '설정을 선택해주세요.',
    CALL_API_FAIL: '데이터 불러오기 실패. 원인 : [{message}]',
    ACTIVATE_SELECTED_ACCOUNT: '선택한 계정을 활성 상태로 전환하시겠습니까?',
    PASSWORD_MISMATCH: '패스워드가 일치하지 않습니다. 다시 확인하세요.',
    NOT_DUPLICATE_VALUE_IS: '[{value}] 값은 중복될 수 없습니다.',
    FORGOT_PASSWORD: '패스워드를 분실한 경우에는 관리자에게 문의하세요.',
    ENTER_ID: '아이디를 입력하세요.',
    ENTER_PASSWORD: '패스워드를 입력하세요.',
    CONFIRM_DELETE_WITH_SUB_PERMISSIONS: '삭제 시 하위권한까지 일괄 삭제됩니다. <br>삭제하시겠습니까?',
    MAIN_SETTING_MENU_TYPE_ERROR: '메뉴 타입이 올바르지 않습니다. (일반 페이지, 보고서만 가능합니다.)',
    WARNING_FORCE_LOGOUT: '잠시후에 자동 로그아웃됩니다. 로그인을 계속 유지하시겠습니까?',
    FORCE_LOGOUT: '일정한 시간동안 미사용하여 로그아웃 되었습니다.',
    NOT_FOUND_SEARCH_DATA: '검색 결과가 존재하지 않습니다.',
    PASSWORD_POLICY_NONE:
      '대문자, 소문자, 숫자, 특수문자 중 {minCategories}가지 이상 조합<br>' +
      '최소 {minLength}자 이상, 최대 {maxLength}자 이하<br>' +
      '연속된 문자나 숫자 {consecutiveLength}자 이상 사용 불가<br>' +
      '사용 가능 특수문자 {specialChars}<br>' +
      '예) Abc123!@#',
    PASSWORD_POLICY_ID:
      '대문자, 소문자, 숫자, 특수문자 중 {minCategories}가지 이상 조합<br>' +
      '최소 {minLength}자 이상, 최대 {maxLength}자 이하<br>' +
      '연속된 문자나 숫자 {consecutiveLength}자 이상 사용 불가<br>' +
      '아이디 포함 불가<br>' +
      '사용 가능 특수문자 {specialChars}<br>' +
      '예) Abc123!@#',
    PASSWORD_POLICY_NAME:
      '대문자, 소문자, 숫자, 특수문자 중 {minCategories}가지 이상 조합<br>' +
      '최소 {minLength}자 이상, 최대 {maxLength}자 이하<br>' +
      '연속된 문자나 숫자 {consecutiveLength}자 이상 사용 불가<br>' +
      '이름 포함 불가<br>' +
      '사용 가능 특수문자 {specialChars}<br>' +
      '예) Abc123!@#',
    PASSWORD_POLICY:
      '대문자, 소문자, 숫자, 특수문자 중 {minCategories}가지 이상 조합<br>' +
      '최소 {minLength}자 이상, 최대 {maxLength}자 이하<br>' +
      '연속된 문자나 숫자 {consecutiveLength}자 이상 사용 불가<br>' +
      '아이디 포함 불가<br>' +
      '이름 포함 불가<br>' +
      '사용 가능 특수문자 {specialChars}<br>' +
      '예) Abc123!@#',
    LOADING: '로딩중...',
    CANNOT_ADD_MENU: '추가할 수 없는 메뉴입니다.',
    ALREADY_ADDED_MENU: '이미 추가된 메뉴입니다.',
    DELETE_WITH_SUB: '하위 {target}도 같이 삭제됩니다. 삭제하시겠습니까?',
    TEXT_BOX_LIMIT: '최대 {limit}자까지 입력 가능합니다.',
    MENU_AUTH_ADD_DESC:
      '최초 메뉴 등록 시 등록자의 권한그룹(상위 권한 포함)에 <br />' +
      '메뉴 권한을 자동으로 추가할수 있습니다. <br />' +
      '추가 권한설정은 권한 관리 메뉴에서만 가능합니다.',
    PASSWORD_EXPIRED_WARNING:
      '{pwdChgDay}일 동안 패스워드를 변경하지 않았습니다. <br />' + '개인정보를 보호하기 위해 패스워드를 변경하세요.',
    PASSWORD_TEMPORARY_WARNING: '패스워드 변경이 필요합니다. <br /> 개인정보를 보호하기 위해 패스워드를 변경하세요.',
    PASSWORD_INVALID: '유효하지 않은 패스워드입니다.',
    DUPLICATE_ID: '이미 사용중인 아이디입니다.',
    IMMUTABLE_KEY_WARNING: '키 값은 변경할 수 없습니다. 삭제 후 재등록해주세요.',
    LOWER_CASE_AND_UNDERSCORE_ONLY: '소문자와 언더스코어(_)만 입력 가능합니다.',
    VALIDATION_ERROR: '유효성 검사 오류',
    ENTER_ROOT_GROUP_KEY: "'ROOT' 코드는 'root_'로 시작하는 그룹키가 필요합니다.",
    GROUP_KEYS_MUST_BE_SAME: '같은 그룹의 그룹키는 모두 동일해야 합니다.',
  },
  WORD: {
    ADD_MAIN_SETTING: '메인설정 추가',
    SETTING_TARGET: '설정 대상',
    MAIN_PAGE_TYPE: '메인 페이지 타입',
    MAIN_PAGE_ID: '메인 페이지 ID',
    SITE: '사이트',
    SITE_NAME: '사이트명',
    TENANT: '센터',
    TENANT_NAME: '센터명',
    CONTENT: '내용',
    SAVE_ID: '아이디 저장',
    TYPE: '유형',
    HOLIDAY_NAME: '공휴일명',
    HOLIDAY: '공휴일',
    LOAD_HOLIDAY: '공휴일 불러오기',
    COMMON: '공통',
    MENU_SEARCH: '메뉴검색',
    MY_FAVORITE_MENU: '나의 즐겨찾기 메뉴',
  },
};
