export default {
  MESSAGE: {
    CHECK_30_DAYS: 'You can check for up to 30 days only.',
    CHECK_12_MONTHS: 'You can check it for up to 12 months only.',
    PLEASE_SELECT_SKILL: 'Please select a skill when searching by skill.',
    FORECAST_EVENT_INFO_MESSAGE1:
      'If there is a significant difference between the predicted and actual values, it is considered an event.',
    FORECAST_EVENT_INFO_MESSAGE2: 'You can also add a date that the user determines as an event in advance.',
    FORECAST_EVENT_INFO_MESSAGE3:
      'Analyzing the cause of the event and managing it by type can further increase the accuracy of call prediction.',
    FORECAST_MODEL_INFO_MESSAGE1:
      'The forecasting model is automatically changed when daily forecasts differ significantly from the actual value for five consecutive times or monthly forecasts for three consecutive times.',
    FORECAST_MODEL_INFO_MESSAGE2:
      'In the inflow history chart, you can check the inflow status over the past two weeks, including consecutive failures.',
    REQUIRED_EVENT_TYPE: 'The event type is required when registering for an event.',
    PLEASE_SELECT_EVENT_TYPE: 'Please select an event type.',
  },
  WORD: {
    FORECASTED_OFFER: 'Forecasted Offer',
    ACTUAL_OFFER: 'Actual Offer',
    FULL_TIME_EQUIVALENT: 'Full Time Equivalent',
    EVENT_TYPE: 'Event Type',
    SELECT_EVENT_TYPE: 'Select Event Type',
    EVENT_DATE: 'Event Date',
    ADDED_DATE: 'Added Date',
    DAILY: 'Daily',
    MONTHLY: 'Monthly',
    BY_SKILL: 'By Skill',
    SKILL_GROUP: 'Skill Group',
    SEARCHING_FORECAST: 'Searching Forecast',
    FORECAST_CYCLE: 'Forecast Cycle',
    REPLACEMENT_DATE: 'Replacement Date',
    FORECAST_FAIL_PERIOD: 'Forecast Failure Period',
    AVERAGE_ACCURACY: 'Average Accuracy',
    MODEL: 'Model',
    HYPERPARAMETERS: 'Hyperparameters',
    OFFER_HISTORY: 'Offer History',
    FORECAST_FAIL_OFFER_HISTORY: 'Forecast Fail Offer History',
    EXCEL: 'Excel',
    ALL_EVENTS_HISTORY: 'All Events History',
  },
};
