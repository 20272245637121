<!--
  PACKAGE_NAME : src\pages\ai\llm-tester\work-config
  FILE_NAME : index-parsing-result
  AUTHOR : hpmoon
  DATE : 2024-12-11
  DESCRIPTION : AI > LLM > LLM Tester > 프로젝트 작업 설정 > 질의 설정 > 인덱스 관리 > 파싱 결과
-->
<template>
  <DxPopup
    :show-title="true"
    :title="modal.title"
    :min-width="modal.minWidth"
    :width="modal.width"
    :min-height="modal.minHeight"
    :height="modal.height"
    :drag-enabled="modal.dragEnabled"
    :resize-enabled="modal.resizeEnabled"
    :hide-on-outside-click="modal.closeOnOutsideClick"
    :show-close-button="modal.showCloseButton"
    :visible="isOpen"
    @hiding="$emit('closeModal')"
  >
    <template #content>
      <div>
        <DxScrollView class="dx-texteditor dx-editor-outlined mar_to10" width="100%" :height="643">
          <div class="pad_5" v-html="convertResult" />
        </DxScrollView>
      </div>
    </template>

    <DxToolbarItem
      widget="dxButton"
      toolbar="bottom"
      location="center"
      :visible="true"
      :options="{
            elementAttr: { class: 'white filled txt_S medium' },
            text: this.$_lang('COMPONENTS.CLOSE', { defaultValue: '닫기' }),
            width: '120',
            height: '40',
            onClick: () => {
              this.$emit('closeModal');
            },
          }"
    />
  </DxPopup>
</template>

<script>
  import { DxPopup, DxToolbarItem } from "devextreme-vue/popup";
  import { DxScrollView } from "devextreme-vue/scroll-view";

  export default {
    components: {
      DxScrollView,
      DxPopup,
      DxToolbarItem,
    },

    props: {
      isOpen: Boolean,
      indexId: String,
    },

    data() {
      return {
        modal: {
          title: this.$_lang('', { defaultValue: '파싱 결과' }),
          minWidth: '900',
          width: '900',
          minHeight: '785',
          height: '785',
          dragEnabled: true,
          resizeEnabled: true,
          closeOnOutsideClick: false,
          showCloseButton: true,
        },
        contentData: '',
      };
    },

    computed: {
      /** @description 파싱 결과 변환 */
      convertResult() {
        return this.contentData.replaceAll('\n', '<br/>');
      },
    },

    /** @description 라이프사이클 created 시 호출되는 메서드 */
    async created() {
      const payload = {
        actionName: 'LLM_TESTER_INDEX_PARSING_RESULT',
        data: {
          id: this.indexId
        },
        loading: true,
      }
      const res = await this.CALL_LLM_TESTER_API(payload);
      if (res.status === 200) {
        this.contentData = res.data;
      } else {
        this.$_Msg(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        return false;
      }
    },
  };
</script>

<style lang="scss" scoped>
  .pad_5 {
    padding: 5px;
  }
</style>