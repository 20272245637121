export default {
  MESSAGE: {
    INPUT_GROUP_NAME: 'Please enter the Group Name.',
    REQUIRED_GROUP_NAME: 'Group name is required.',
    SELECT_GROUP: 'Please select a group.',
    DUPLICATE_NAME: 'This name is already registered.',
    CFM_UPDATE_INFO: 'Would you like to edit the information?',
    CFM_DELETE_SELECTED_WITH_CHILD: `Are you sure you want to delete the selected data?<br/>Child data will also be deleted.`,
    RESET_IBG: 'Reset IBG Settings',
    RESET_MAIN_NUMBER: 'Reset Main Number Settings',
    NO_DELETE_DNIS_NOTICE: 'There is a notice being posted in the DNIS, so it cannot be deleted.',
    NO_DELETE_DNIS_SCHEDULE: 'There is a operating schedule being posted in the DNIS, so it cannot be deleted.',
    START_DATE_OUT_OF_RANGE: 'Start date must be less than or equal to end date.',
    END_DATE_OUT_OF_RANGE: 'The end date must be greater than or equal to the start date.',
    START_TIME_OUT_OF_RANGE: 'Start time must be less than or equal to end time.',
    END_TIME_OUT_OF_RANGE: 'The end time must be greater than or equal to the start time.\n',
    NO_DISABLED_NOT_USED_DNIS_NOTICE: 'Notice set in the representative number cannot be changed to unused state.',
    NO_DISPLAY_USED_DNIS_NOTICE: `The main number for which other announcements have already been established<br>is You are not exposed from the list.`,
    DUPLICATE_REST_TIME: 'Rest time overlaps.',
    DUPLICATE_HOLIDAY_DATE: 'There is a holiday on the same date.<br/>Please check again.',
    DUPLICATE_OPERATION_DATE: 'There is a special operating date the same date.<br/>Please check again.',
  },
  WORD: {
    GROUP_INFO: 'Group Info',
    TH_GROUP_NAME: 'th Group Name',
    GROUP_NAME: 'Group Name',
    CHILD_GROUP: 'Child Group',
    CHILD: 'Child',
    REGISTER_1ST_GROUP: 'Register for a first group',
    REGISTER_2RD_GROUP: 'Register for a secondary group',
    ADD_IBG: 'Add IBG',
    ADD_TEAM: 'Add Team',
    ADD_SKL: 'Add Skill Group',
    IBG: 'IBG',
    IBG_NAME: 'IBG Name',
    TEAM: 'Team',
    TEAM_NAME: 'Team Name',
    SKL: 'Skill Group',
    SKL_NAME: 'Skill Group Name',
    ATTRIBUTE: 'Attribute',
    ATTRIBUTE_NAME: 'Attribute Name',
    CONTACT_TYPE: 'Contact Type',
    CONTACT_TYPE_NAME: 'Contact Type Name',
    NREASON_CODE: 'NReason Code',
    NREASON_NAME: 'NReason Name',
    NREASON_FIELD: 'NReason Field',
    ERS_NAME: 'ERS Name',
    CTI_NAME: 'CTI Name',
    VIEW_DNIS_USE: 'View only DNIS in use',
    OPERATION_SETTINGS: 'Operation Settings',
    IBG_SETTINGS: 'Ibg Settings',
    CONFIG_SETTINGS: 'Config Settings',
    IVR_NAME: 'IVR Name',
    MAIN_NUMBER: 'Main Number',
    MAIN_NUMBER_SETTING: 'Main Number Setting',
    DNIS_TITLE: 'DNI Title',
    SVC_TP_TITLE: 'Service Template',
    OP_SCHEDULE: 'Operation Schedule',
    SERVICECODE: 'Service Code',
    NOTICE: 'Notice',
    DISPLAY_PERIOD: 'Display Period',
    END_TYPE: 'End Type',
    CONFIG_SETTING: 'Config Setting',
    KEY: 'Key',
    VALUE: 'Value',
    DESCRIPTION: 'Description',
    SCHEDULE_NAME: 'Schedule Name',
    BASIC_INFO: 'Basic Info',
    BASIC_OP_TIME_SETTING: 'Basic Operation Time Setting',
    OP_SCHEDULE_NAME: 'Operation Schedule Name',
    OP_DAY_OF_WEEK: 'Operation Day of the Week',
    OP_TIME: 'Operation Time',
    BREAK_TIME_APPLY_TYPE: 'Break Time Apply Type',
    BREAK_TIME: 'Break Time',
    TWENTY_FOUR_HOUR: 'Twenty-four hour',
    WORK_FLAG: 'Work Flag',
    AGT_TEAM_NM: 'Agent Team Name',
    AGT_ID: 'Agent ID',
    AGT_NM: 'Agent Name',
    TEMPLATE: 'Template',
    SERVICECODE_NM: 'Service Code Name',
    NOTICE_FLAG: 'Notice Flag',
  },
};
