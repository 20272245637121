<!--
  PACKAGE_NAME : src/pages/cc/ivr/dnis/modal-notice.vue
  FILE_NAME : modal-notice
  AUTHOR : hmlee
  DATE : 2024-09-03
  DESCRIPTION : 공지사항 설정 모달
-->
<template>
  <DxPopup
    :show-title="showTitle"
    :title="modal.title"
    :min-width="modal.minWidth"
    :width="modal.width"
    :min-height="modal.minHeight"
    :height="modal.height"
    :drag-enabled="modal.dragEnabled"
    :resize-enabled="modal.resizeEnabled"
    :show-close-button="modal.showCloseButton"
    :hide-on-outside-click="modal.hideOnOutsideClick"
    :visible="isOpen"
    @hiding="closeModal"
  >
    <template #content>
      <div class="flex">
        <!-- Sidebar -->
        <div class="w-1/6 p-4 mr-4 rounded-sm bg-gray-100">
          <div class="mt-16 mb-28 font-normal">{{ $_lang('CC.WORD.NOTICE', { defaultValue: '공지사항' }) }} {{ $_lang('COMPONENTS.SELECT', { defaultValue: '선택' }) }}</div>
          <div class="mb-8 font-normal">{{ $_lang('CC.WORD.DISPLAY_PERIOD', { defaultValue: '노출기간' }) }}</div>
          <div class="font-normal">{{ $_lang('CC.WORD.END_TYPE', { defaultValue: '종료여부' }) }}</div>
        </div>

        <div class="w-5/6">
          <DxValidationGroup ref="dnisAddValidate">
          <div class="mb-4">
            <DxSelectBox
              :placeholder="config.selectBox.placeholder"
              :show-clear-button="config.selectBox.showClearButton"
              :styling-mode="config.stylingMode"
              :width="config.selectBox.width"
              :items="config.selectBox.noticeList"
              display-expr="noticeNm"
              value-expr="id"
              :value="formData.id"
              @value-changed="onChangedNotice"
              class="w-full"
            >
            </DxSelectBox>
          </div>

          <div class="mb-4">
            <DxTextArea
              :styling-mode="config.stylingMode"
              :height="config.textArea.height"
              :read-only="config.textArea.readOnly"
              :value="formData.contents"
              class="w-full bg-gray-300 rounded-sm border-gray-400"
            />
          </div>

          <!-- 노출 기간 -->
          <div class="mt-8 mb-8 text-gray-600" ref="visiblePeriod">{{ getVisiblePeriod }}</div>

          <!-- 종료 여부 -->
          <div class="mt-8 mb-8 text-gray-600" ref="endType"> {{ getEndType }}</div>
          </DxValidationGroup>
        </div>

      </div>

    </template>

    <DxToolbarItem
      widget="dxButton"
      toolbar="bottom"
      location="center"
      :visible="true"
      :options="{
					elementAttr: {
						class: 'default filled txt_S medium',
					},
					text: this.$_lang('COMPONENTS.SAVE', { defaultValue: '저장' }),
					width: '120',
					height: '40',
					useSubmitBehavior: true,
					onClick: this.saveModal,
				}"
    />
    <DxToolbarItem
      widget="dxButton"
      toolbar="bottom"
      location="center"
      :visible="true"
      :options="{
					elementAttr: {
						class: 'white filled txt_S medium',
					},
					text: this.$_lang('COMPONENTS.CANCEL', { defaultValue: '취소' }),
					width: '120',
					height: '40',
					onClick: this.closeModal,
				}"
    />
  </DxPopup>
</template>

<script>
import {DxPopup, DxPosition, DxToolbarItem} from 'devextreme-vue/popup';

import {DxTextBox} from 'devextreme-vue/text-box';
import DxTextArea from 'devextreme-vue/text-area';
import {DxButton} from 'devextreme-vue/button';
import {DxSelectBox} from 'devextreme-vue/select-box';

import {isSuccess, formatDate} from "@/plugins/common-lib";
import {DxValidationGroup} from "devextreme-vue/validation-group";
import {DxRequiredRule, DxValidator} from "devextreme-vue/validator";
import {DxNumberBox} from "devextreme-vue/number-box";

export default {
  components: {
    DxValidator, DxNumberBox, DxRequiredRule, DxValidationGroup,
    DxPopup, DxPosition, DxToolbarItem,
    DxTextBox,
    DxTextArea,
    DxButton,
    DxSelectBox,
  },
  props: {
    isOpen: {
      default: false,
      type: Boolean,
    },
    noticeData: {
      default: null,
      type: Object,
    },
  },
  watch: {},
  computed: {
    /** @description: 모달 타이틀 출력 여부 */
    showTitle() {
      return !!this.modal.title;
    },
    /** @description: 노출 기간 */
    getVisiblePeriod() {
      if( this.formData.id && this.formData.startDt ) {
        const startDt = formatDate(this.formData?.startDt, 'YYYYMMDD', 'YYYY.MM.DD');
        const endDt = formatDate(this.formData?.endDt, 'YYYYMMDD', 'YYYY.MM.DD');
        const startTime = formatDate(this.formData?.startTime, 'HHmm', 'HH:mm');
        const endTime = formatDate(this.formData?.endTime, 'HHmm', 'HH:mm');
        return startDt + ' ' + startTime + ' ~ ' + endDt + ' ' + endTime;
      }else {
        return '-';
      }
    },
    /** @description: 종료 여부 */
    getEndType() {
      if( this.formData.endTypeFl ) {
        return this.formData?.endTypeFl === 'Y' ? this.$_enums.cc.stringEndTypeFlag.YES.label : this.$_enums.cc.stringEndTypeFlag.NO.label;
      } else {
        return '-';
      }
    },
  },
  data() {
    return {
      modal: {
        title: this.$_lang('CC.WORD.NOTICE', { defaultValue: '공지사항' })
                +' '+this.$_lang('COMPONENTS.SETTING', { defaultValue: '설정' }),
        minWidth: '300',
        width: '800',
        minHeight: '300',
        height: '400',
        dragEnabled: true,
        resizeEnabled: true,
        showCloseButton: true,
        hideOnOutsideClick: false,
      },
      config: {
        stylingMode: 'outlined', //[outlined, filled, underlined]
        selectBox: {
          placeholder: `${ this.$_lang('CC.WORD.NOTICE', { defaultValue: '공지사항'}) }
                        ${ this.$_lang('COMPONENTS.WORD.SELECT', { defaultValue: '선택'}) }`,
          showClearButton: true,
          width: '100%',
          noticeList: [],
        },
        textArea: {
          height: '100',
          readOnly: true,
          //inputAttr: "{ 'aria-label': 'Notes' }",
          //inputAttr: null,
        }
      },
      formData: {
        id: null,
        contents: null,
        startDt: null,
        startTime: null,
        endDt: null,
        endTime: null,
        endTypeFl: null,
      }
    }
  },
  methods: {
    /** @description: 모달 저장 */
    async saveModal() {
      let dnisData = this.noticeData;
      dnisData.noticeId = this.formData.id;

      const payload = {
        actionName: 'CC_IVR_DNIS_MERGE',
        data: [dnisData],
        useErrorPopup: true,
      }
      const res = await this.CALL_CC_API(payload);
      if (isSuccess(res)) {
        this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE', {defaultValue: '정상적으로 저장되었습니다.'}));
        this.$emit('saveModal');
      }
    },
    /** @description: 모달 닫기 */
    closeModal() {
      //this.initModal();
      this.$emit('closeModal');
    },
    /** @description: 공지사항 정보 리스트 가져오기 */
    async selectNoticeList() {
      const payload = {
        actionName: 'CC_IVR_NOTICE_LIST',
        data: { sort: '+id', viewFl: 'Y' },
        useErrorPopup: true,
      };

      const res = await this.CALL_CC_API(payload);
      let rstData = [];
      if (isSuccess(res)) {
        rstData = res.data.data;
      }
      return rstData;
    },
    /** @description: 공지사항 선택시 이벤트 */
    onChangedNotice(e) {
      if( e.value ) {
        this.formData = this.config.selectBox.noticeList.find(notice => notice.id === e.value);
      }else { //초기화
        this.formData = {
          id: null,
          contents: null,
          startDt: null,
          startTime: null,
          endDt: null,
          endTime: null,
          endTypeFl: null,
        }
      }
    },
    /** @description: 공지사항 데이터 설정 */
    async setNoticeData(){
      this.config.selectBox.noticeList = await this.selectNoticeList();

      if( this.noticeData?.noticeId ){ //공지사항 수정인 경우
        this.formData = this.config.selectBox.noticeList.find(notice => notice.id === this.noticeData.noticeId);
      }
    },
  },
  created() {
    this.setNoticeData();
  },
}
</script>
