import {
	SET_ROUTING_INFO,
	CALL_API,
	INIT_SYSTEM_SETTING,
	INIT_THEME_SETTING,
	REFRESH_TOKEN,
	INIT_FAVORITE_MENU
} from './esp';
import { CALL_EWM_API } from './ewm';
import { CALL_EUC_API } from './uc';
import { CALL_REPORT_API } from './report';
import { CALL_CC_API } from './cc';
import { CALL_LLM_TESTER_API } from './llm-tester';
import { CALL_FORECASTING_API } from './forecasting';
import { CALL_EMC_API } from './emc';
import { LOGOUT, LOGIN, LOGIN_ANOTHER_CHECK, LOGIN_DIRECT } from './auth';
import { WEBSOCKET_CONNECT }	 from	"./websocket";



export const actions = {
	SET_ROUTING_INFO,
	REFRESH_TOKEN,
	CALL_API,
	CALL_EWM_API,
	CALL_EUC_API,
	CALL_REPORT_API,
	CALL_CC_API,
	CALL_LLM_TESTER_API,
	CALL_FORECASTING_API,
	CALL_EMC_API,
	LOGOUT,
	LOGIN,
	LOGIN_ANOTHER_CHECK,
	LOGIN_DIRECT,
	INIT_SYSTEM_SETTING,
	INIT_THEME_SETTING,
	WEBSOCKET_CONNECT,
	INIT_FAVORITE_MENU,
};
