<template>
	<transition>
		<DxDataGrid
			class="grid-box"
			key-expr="id"
			ref="evalStdGrid"
			:data-source="evalStdGrid.dataSource"
			:allow-column-resizing="true"
			:column-resizing-mode="'nextColumn'"
			:show-borders="false"
			:show-column-headers="true"
			:show-column-lines="true"
			:show-row-lines="true"
			:row-alternation-enabled="false"
			:hover-state-enabled="true"
			:word-wrap-enabled="true"
			width="100%"
			height="100%"
			:no-data-text="this.$_lang('CMN_NO_DATA')"
			:selected-row-keys="evalStdGrid.selectedRowKeys"
			@selection-changed="onSelectionChanged"
			@row-click="onRowClick"
			@cell-hover-changed="onCellHoverChanged"
		>
			<DxLoadPanel :enabled="true" />
			<DxScrolling mode="virtual" />
			<DxSelection mode="multiple" :allow-select-all="true" show-check-boxes-mode="always" select-all-mode="allPages" />
			<DxFilterRow :visible="true" />
			<DxHeaderFilter :visible="false" />

			<DxColumn caption="평가기준명" dataField="productionName" :allowEditing="false" :allowFiltering="true" />
			<DxColumn caption="콜건수 가중치">
				<DxColumn
					caption="IB 건수 가중치"
					data-field="inCntWeight"
					alignment="center"
					:allowEditing="false"
					:allowFiltering="true"
					width="140"
				/>
				<DxColumn
					caption="OB 건수 가중치"
					data-field="outCntWeight"
					alignment="center"
					:allowEditing="false"
					:allowFiltering="true"
					width="140"
				/>
			</DxColumn>
			<DxColumn caption="콜시간 가중치">
				<DxColumn
					caption="IB 시간 가중치"
					data-field="inTimeWeight"
					alignment="center"
					:allowEditing="false"
					:allowFiltering="true"
					width="140"
				/>
				<DxColumn
					caption="OB 시간 가중치"
					data-field="outTimeWeight"
					alignment="center"
					:allowEditing="false"
					:allowFiltering="true"
					width="140"
				/>
			</DxColumn>
			<DxColumn caption="평가방식" data-field="evaluationMethod" :allowEditing="false" :allowFiltering="true" alignment="left" />
			<DxColumn
				caption="배점"
				data-field="productionScore"
				:allowEditing="false"
				:allowFiltering="true"
				alignment="center"
				width="100"
			/>
		</DxDataGrid>
	</transition>
</template>

<script>
import {
	DxDataGrid,
	DxColumn,
	DxPaging,
	DxSelection,
	DxFilterRow,
	DxHeaderFilter,
	DxLookup,
	DxLoadPanel,
	DxSorting,
	DxScrolling,
} from 'devextreme-vue/data-grid';
import {cloneObj, isSuccess} from '@/plugins/common-lib';

let vm = this;

export default {
	components: {
		DxDataGrid,
		DxColumn,
		DxPaging,
		DxSelection,
		DxFilterRow,
		DxHeaderFilter,
		DxLookup,
		DxLoadPanel,
		DxSorting,
		DxScrolling,
	},
	props: {
		contentData: {
			type: Array,
			required: true,
		},
	},
	watch: {},
	data() {
		return {
			evalStdGrid: {
				dataSource: [],
				selectedRowsData: [],
				selectedRowKeys: [],
			},
			isSelectionStopped: true,
		};
	},
	computed: {},
	methods: {
		onRowClick(e) {
			let keys = e.component.getSelectedRowKeys();
			let index = keys.indexOf(e.key);

			if (index > -1) {
				keys.splice(index, 1);
			} else {
				keys.push(e.key);
			}
			e.component.selectRows(keys);
		},
		onCellHoverChanged(e) {
			const event = e.event;
			if (event.buttons === 1) {
				if (this.isSelectionStopped) {
					this.isSelectionStopped = false;
					this.selectedRange = {};
				}

				if (this.selectedRange.startRowIndex === undefined) {
					this.selectedRange.startRowIndex = e.rowIndex;
				}

				if (!this.selectedRange.startColumnIndex) {
					this.selectedRange.startColumnIndex = e.columnIndex;
				}

				this.selectedRange.endRowIndex = e.rowIndex;
				this.selectedRange.endColumnIndex = e.columnIndex;

				let start = Math.min(this.selectedRange.startRowIndex, this.selectedRange.endRowIndex);
				let end = Math.max(this.selectedRange.startRowIndex, this.selectedRange.endRowIndex);

				let indexes = [];
				for (let i = start; i <= end; i++) {
					indexes.push(i);
				}
				e.component.selectRowsByIndexes(indexes);
			} else {
				this.isSelectionStopped = true;
			}
		},
		async selectDataList() {
			const payload = {
				actionName: 'EWM_PRODUCTIVITY_EVALCRITERIA_LIST',
				loading: false,
			};

			const res = await vm.CALL_EWM_API(payload);

			if (isSuccess(res)) {
				this.evalStdGrid.dataSource = res.data.data;
				this.evalStdGrid.selectedRowKeys = this.contentData;
			}
		},
		/** @description: 그리드 선택시 변경 관련 이벤트 */
		onSelectionChanged(e) {
			this.evalStdGrid.selectedRowsData = e.selectedRowsData;
		},
		/** @description : 라이프사이클 created 호출되는 메서드 */
		createdData() {
			vm = this;

			this.selectDataList();
		},
		/** @description : 라이프사이클 mounted 호출되는 메서드 */
		mountedData() {
			this.$watch(
				() => {
					return this.evalStdGrid.selectedRowsData;
				},
				val => {
					val = cloneObj(val);
					this.$emit('input', val);
				},
			);
		},
	},
	created() {
		this.createdData();
	},
	mounted() {
		this.mountedData();
	},
};
</script>
