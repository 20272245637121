<!--
  PACKAGE_NAME : src/pages/ui/components/button-demo.vue
  FILE_NAME : button-demo
  AUTHOR : hmlee
  DATE : 2025-01-10
  DESCRIPTION : 코드 에디터와 미리보기를 통한 실시간 뷰 컴포넌트 렌더링
-->
<template>
  <div class="page-sub-box">
    <div class="real-time-editor">
      <div class="editor sub_title_txt">
        <h2>코드 에디터</h2>
        <code-editor v-model="code" beautyType="html" />
      </div>
      <div class="preview sub_title_txt">
        <h2>미리보기</h2>
        <div ref="previewContainer" class="preview-container" />
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import CodeEditor from "@/pages/ui/components/code-editor.vue";
import EspDxButton from "@/components/devextreme/esp-dx-button.vue";
import EspAddButton from "@/components/common/buttons/esp-add-button.vue";
import EspSaveButton from "@/components/common/buttons/esp-save-button.vue";
import EspCancelButton from "@/components/common/buttons/esp-cancel-button.vue";
import EspDeleteButton from "@/components/common/buttons/esp-delete-button.vue";
import EspSortSaveButton from "@/components/common/buttons/esp-sort-save-button.vue";
import EspFolderButton from "@/components/common/buttons/esp-folder-button.vue";
import EspChevronButton from "@/components/common/buttons/esp-chevron-button.vue";
import EspExcelButton from "@/components/common/buttons/esp-excel-button.vue";
import EspFooterButton from "@/components/common/buttons/esp-footer-button.vue";
import EspFlagButton from "@/components/common/buttons/esp-flag-button.vue";

const demoTemplate = `
  <template>
    <div class="page-container btn-demo">
      <div class="title"> 🔸버튼 색상 filled 모드 설정 </div>
      <esp-dx-button
        :i18n="button.i18n"
        :color="button.color"
        :mode="button.mode"
        :size="button.size"
        @click="handleClick"
      />
      <esp-dx-button
        :i18n="button.i18n"
        color="green"
        mode="filled"
      />
      <esp-dx-button
        :i18n="button.i18n"
        color="red"
        mode="filled"
      />
      <esp-dx-button
        :i18n="button.i18n"
        color="white"
        mode="filled"
      />
      <esp-dx-button
        :i18n="button.i18n"
        color="gray"
        mode="filled"
      />
      <esp-dx-button
        :i18n="button.i18n"
        color="black"
        mode="filled"
      />
      <br/>
      <br/>

      <div class="title"> 🔸버튼 모드 outlined 설정 </div>
      <esp-dx-button
        :i18n="button.i18n"
        color="default"
        mode="outlined"
      />
      <esp-dx-button
        :i18n="button.i18n"
        color="green"
        mode="outlined"
      />
      <esp-dx-button
        :i18n="button.i18n"
        color="red"
        mode="outlined"
      />
      <esp-dx-button
        :i18n="button.i18n"
        color="white"
        mode="outlined"
      />
      <esp-dx-button
        :i18n="button.i18n"
        color="gray"
        mode="outlined"
      />
      <esp-dx-button
        :i18n="button.i18n"
        color="black"
        mode="outlined"
      />
      <br/>
      <br/>

      <div class="title"> 🔸버튼 사이즈 옵션 </div>
      <esp-dx-button
        text="x-small"
        size="xs"
      />

      <esp-dx-button
        text="small"
        size="sm"
      />

      <esp-dx-button
        text="medium"
        size="md"
      />

      <esp-dx-button
        text="large"
        size="lg"
      />
      <br/>
      <br/>

      <div class="title"> 🔸버튼 너비 옵션(width 옵션 설정시 width 옵션 우선 적용) </div>
      <esp-dx-button
        text="너비100px"
        width="100"
        size="xs"
      />
      <br/>
      <br/>

      <div class="title"> 🔸버튼 높이 옵션(height 옵션 설정시 height 옵션 우선 적용) </div>
      <esp-dx-button
        text="높이40px"
        height="40"
        size="xs"
      />
      <br/>
      <br/>

      <div class="title"> 🔸다국어(i18n) 옵션 우선 적용(다국어(i18n) 옵션 > text 옵션)</div>
      <esp-dx-button
        :i18n="button.i18n"
        :text="button.text"
        :color="button.color"
        :mode="button.mode"
        :size="button.size"
      />
      <esp-dx-button
        text="텍스트옵션"
        color="default"
        mode="outlined"
      />
      <br/>
      <br/>

      <div class="title "> 🔸mdi 아이콘 링크 : https://pictogrammers.github.io/@mdi/font/2.0.46/ </div>
      <esp-dx-button
        mdi-icon="paw"
        mode="outlined"
      />
      <esp-dx-button
        mdi-icon="cat"
      />
      <esp-dx-button
        mdi-icon="cat"
        mode="outlined"
      />
      <esp-dx-button
        mdi-icon="apple"
      />
      <esp-dx-button
        mdi-icon="apple"
        mode="outlined"
      />
      <esp-dx-button
        mdi-icon="apple"
        mode="outlined"
        height="40"
      />
      <br/>
      <br/>

      <div class="title"> 🔸ESP 전용 아이콘(이미지 아이콘 단독 사용: i18n & text 옵션 미설정 ) </div>
      <esp-dx-button
        color="default"
        mode="filled"
        prefix-icon="add1"
      />
      <esp-dx-button
        color="white"
        mode="outlined"
        prefix-icon="add2"
      />
      <esp-dx-button
        color="white"
        mode="outlined"
        prefix-icon="add3"
      />
      <esp-dx-button
        color="white"
        mode="outlined"
        prefix-icon="del1"
      />
      <esp-dx-button
        color="default"
        mode="filled"
        prefix-icon="modify"
      />
      <esp-dx-button
        color="white"
        mode="outlined"
        prefix-icon="cancel1"
      />
      <esp-dx-button
        color="white"
        mode="outlined"
        prefix-icon="trash"
      />
      <esp-dx-button
        color="default"
        mode="filled"
        prefix-icon="excel"
      />
      <esp-dx-button
        color="default"
        mode="filled"
        prefix-icon="filter"
      />
      <esp-dx-button
        color="default"
        mode="filled"
        prefix-icon="send"
      />
      <br/>
      <br/>

      <esp-dx-button
        color="white"
        mode="outlined"
        prefix-icon="pull-up"
      />
      <esp-dx-button
        color="white"
        mode="outlined"
        prefix-icon="pull-down"
      />
      <esp-dx-button
        color="white"
        mode="outlined"
        prefix-icon="zoomout"
      />
      <esp-dx-button
        color="white"
        mode="outlined"
        prefix-icon="preview"
      />
      <esp-dx-button
        color="white"
        mode="outlined"
        prefix-icon="print"
      />
      <esp-dx-button
        color="white"
        mode="outlined"
        prefix-icon="download"
      />
      <esp-dx-button
        color="white"
        mode="outlined"
        prefix-icon="listen"
      />
      <esp-dx-button
        color="white"
        mode="outlined"
        prefix-icon="question"
      />
      <esp-dx-button
        color="white"
        mode="outlined"
        prefix-icon="refresh"
      />
      <esp-dx-button
        color="white"
        mode="outlined"
        prefix-icon="return"
      />
      <br/>
      <br/>

      <esp-dx-button
        color="white"
        mode="outlined"
        prefix-icon="close"
      />
      <esp-dx-button
        color="white"
        mode="outlined"
        prefix-icon="more"
      />
      <esp-dx-button
        color="white"
        mode="outlined"
        prefix-icon="overflow"
      />
      <esp-dx-button
        color="white"
        mode="outlined"
        prefix-icon="first-arr"
      />
      <esp-dx-button
        color="white"
        mode="outlined"
        prefix-icon="up-arr"
      />
      <esp-dx-button
        color="white"
        mode="outlined"
        prefix-icon="down-arr"
      />
      <esp-dx-button
        color="white"
        mode="outlined"
        prefix-icon="last-arr"
      />

      <esp-dx-button
        color="default"
        mode="filled"
        prefix-icon="send"
        width="40"
      />
      <esp-dx-button
        color="white"
        mode="outlined"
        prefix-icon="zoomout"
        width="40"
      />
      <esp-dx-button
        color="default"
        mode="filled"
        prefix-icon="filter"
        width="50"
        height="30"
      />

      <div class="title"> 🔸ESP 전용 아이콘(이미지 아이콘 + 텍스트 조합) </div>
      <esp-dx-button
        prefix-icon="add1"
        mode="filled"
        i18n="COMPONENTS.ADD"
      />
      <esp-dx-button
        prefix-icon="add2"
        mode="filled"
        color="white"
        i18n="COMPONENTS.ADD"
      />
      <esp-dx-button
        prefix-icon="add3"
        mode="outlined"
        color="gray"
        i18n="COMPONENTS.ADD"
      />
      <esp-dx-button
        prefix-icon="del1"
        mode="filled"
        color="white"
        i18n="COMPONENTS.DELETE"
      />
      <esp-dx-button
        prefix-icon="modify"
        mode="filled"
        i18n="COMPONENTS.EDIT"
      />
      <esp-dx-button
        prefix-icon="cancel1"
        mode="filled"
        color="white"
        i18n="COMPONENTS.CANCEL"
      />
      <esp-dx-button
        prefix-icon="trash"
        mode="filled"
        color="white"
        i18n="COMPONENTS.DELETE"
      />
      <br/>
      <br/>

      <esp-dx-button
        prefix-icon="excel"
        mode="filled"
        color="green"
        i18n="COMPONENTS.EXCEL"
      />
      <esp-dx-button
        prefix-icon="filter"
        mode="filled"
        i18n="COMPONENTS.FILTER"
      />
      <esp-dx-button
        prefix-icon="send"
        mode="filled"
        i18n="COMPONENTS.SEND"
      />
      <esp-dx-button
        prefix-icon="pull-up"
        mode="filled"
        color="white"
        i18n="COMPONENTS.INCREASE"
      />
      <esp-dx-button
        prefix-icon="pull-down"
        mode="filled"
        color="white"
        i18n="COMPONENTS.DECREASE"
      />
      <br/>
      <br/>

      <esp-dx-button
        prefix-icon="zoomout"
        mode="filled"
        color="white"
        i18n="COMPONENTS.EXPANSION"
      />
      <esp-dx-button
        prefix-icon="preview"
        mode="filled"
        color="white"
        i18n="COMPONENTS.PREVIEW"
      />
      <esp-dx-button
        prefix-icon="print"
        mode="filled"
        color="white"
        i18n="COMPONENTS.PRINT"
      />
      <esp-dx-button
        prefix-icon="download"
        mode="filled"
        color="white"
        i18n="COMPONENTS.DOWNLOAD"
      />
      <esp-dx-button
        prefix-icon="listen"
        mode="filled"
        color="white"
        i18n="COMPONENTS.LISTENING"
      />
      <esp-dx-button
        prefix-icon="question"
        mode="filled"
        color="white"
        i18n="COMPONENTS.QUESTION"
      />
      <br/>
      <br/>

      <esp-dx-button
        prefix-icon="refresh"
        mode="filled"
        color="white"
        i18n="COMPONENTS.REFRESH"
      />
      <esp-dx-button
        prefix-icon="return"
        mode="filled"
        color="white"
        i18n="COMPONENTS.RETURN"
      />
      <esp-dx-button
        prefix-icon="close"
        mode="filled"
        color="white"
        i18n="COMPONENTS.CLOSE"
      />
      <esp-dx-button
        prefix-icon="more"
        mode="filled"
        color="white"
        i18n="COMPONENTS.MORE"
      />
       <esp-dx-button
        prefix-icon="overflow"
        mode="filled"
        color="white"
        i18n="COMPONENTS.MORE"
      />
      <br/>
      <br/>

      <esp-dx-button
        prefix-icon="first-arr"
        mode="filled"
        color="white"
        i18n="COMPONENTS.MOVE_TO_TOP"
      />
      <esp-dx-button
        prefix-icon="up-arr"
        mode="filled"
        color="white"
        i18n="COMPONENTS.MOVE_UP"
      />
      <esp-dx-button
        prefix-icon="down-arr"
        mode="filled"
        color="white"
        i18n="COMPONENTS.MOVE_DOWN"
      />
      <esp-dx-button
        prefix-icon="last-arr"
        mode="filled"
        color="white"
        i18n="COMPONENTS.MOVE_TO_BOTTOM"
      />
      <br/>
      <br/>

      <div class="title" style="font-size: 18px;"> 🔶자주 사용하는 버튼 </div>
      <div class="title"> 🔸추가 </div>
      <esp-add-button
        mode="filled"
      />
      <esp-add-button
        mode="outlined"
      />
      <esp-add-button
        text="팀 추가"
        mode="filled"
      />
      <esp-add-button
        text="팀 추가"
        mode="outlined"
      />
      <esp-add-button
        color="red"
        mode="filled"
        size="lg"
      />
      <esp-add-button
        color="red"
        mode="filled"
        height="50"
      />

      <div class="title"> 🔸저장 </div>
      <esp-save-button
        mode="filled"
      />
      <esp-save-button
        color="default"
        mode="filled"
      />
      <esp-save-button
        mode="outlined"
      />
      <esp-save-button
        color="green"
        mode="outlined"
      />
      <esp-save-button
        color="green"
        mode="filled"
        size="lg"
      />
      <esp-save-button
        mode="filled"
        height="50"
      />

      <div class="title"> 🔸취소 </div>
      <esp-cancel-button
        mode="filled"
      />
      <esp-cancel-button
        color="default"
        mode="filled"
      />
      <esp-cancel-button
        mode="outlined"
      />
      <esp-cancel-button
        color="red"
        mode="outlined"
      />
      <esp-cancel-button
        color="black"
        mode="filled"
        size="lg"
      />

      <div class="title"> 🔸삭제 </div>
      <esp-delete-button
      />
      <esp-delete-button
        text="팀 삭제"
      />
      <esp-delete-button
        size="lg"
      />

      <div class="title"> 🔸순서 저장 </div>
      <esp-sort-save-button
        mode="filled"
      />
      <esp-sort-save-button
        color="default"
        mode="filled"
      />
      <esp-sort-save-button
        mode="outlined"
      />

      <div class="title"> 🔸목록 펼치기/접기 </div>
      <esp-folder-button
        mdi-icon="folder-open"
      />
      <esp-folder-button
        mdi-icon="folder"
      />

      <div class="title"> 🔸순서 이동 </div>
      <esp-chevron-button
        mdi-icon="chevron-double-up"
      />
      <esp-chevron-button
        mdi-icon="chevron-up"
      />
      <esp-chevron-button
        mdi-icon="chevron-down"
      />
      <esp-chevron-button
        mdi-icon="chevron-double-down"
      />

      <div class="title"> 🔸엑셀 </div>
      <esp-excel-button
      />
      <esp-excel-button
        color="default"
        mode="filled"
      />

      <div class="title"> 🔸푸터 버튼 </div>
      <esp-footer-button
        color="default"
        mode="filled"
        i18n="COMPONENTS.SAVE"
      />
      <esp-footer-button
        color="white"
        mode="filled"
        i18n="COMPONENTS.CANCEL"
      />

      <div class="title"> 🔸플래그 버튼 (color : green, red, yellow)  </div>
      <esp-flag-button
        color="green"
        i18n="COMPONENTS.NORMAL"
      />

      <esp-flag-button
        color="red"
        i18n="COMPONENTS.ABSENCE"
      />

      <esp-flag-button
        color="yellow"
        i18n="COMPONENTS.LATE"
      />
    </div>
  <\/template>

  <script>
  import EspDxButton from "@/components/devextreme/esp-dx-button.vue";
  import EspAddButton from "@/components/common/buttons/esp-add-button.vue";
  import EspSaveButton from "@/components/common/buttons/esp-save-button.vue";
  import EspCancelButton from "@/components/common/buttons/esp-cancel-button.vue";
  import EspDeleteButton from "@/components/common/buttons/esp-delete-button.vue";
  import EspSortSaveButton from "@/components/common/buttons/esp-sort-save-button.vue";
  import EspFolderButton from "@/components/common/buttons/esp-folder-button.vue";
  import EspChevronButton from "@/components/common/buttons/esp-chevron-button.vue";
  import EspExcelButton from "@/components/common/buttons/esp-excel-button.vue";
  import EspFooterButton from "@/components/common/buttons/esp-footer-button.vue";
  import EspFlagButton from "@/components/common/buttons/esp-flag-button.vue";

  export default {
    components: {
      EspDxButton,
      EspAddButton,
      EspSaveButton,
      EspCancelButton,
      EspDeleteButton,
      EspSortSaveButton,
      EspFolderButton,
      EspChevronButton,
      EspExcelButton,
      EspFooterButton,
      EspFlagButton,
    },
    props: {},
    data() {
      return {
        button: {
          i18n: 'COMPONENTS.CONFIRM', // 버튼 텍스트 다국어 처리 Key (text 옵션보다 우선 적용)
          text: '확인', // 버튼 텍스트(i18n 옵션이 없는 경우 적용)
          color: 'default', // 버튼 컬러(기본 default) / ['default', 'green', 'red', 'white', 'gray', 'black']
          mode: 'filled', // 버튼 타입(기본 filled) / ['filled', 'outlined']
          size: 'sm', // 버튼 크기(기본 small) / ['xs', 'sm', 'md', 'lg']
          width: '', // 버튼 너비(기본 auto) / width 옵션 설정시 width 옵션 우선 적용
          height: '', // 버튼 높이(기본 30) / height 옵션 설정시 height 옵션 우선 적용
        },
      };
    },
    computed: {},
    methods: {
      /** @description: 버튼 클릭 이벤트 */
      handleClick() {
        console.log('버튼 클릭');
      },
    },
    created() {},
    mounted() {},
  };
  <\/script>
`;

export default {
  name: 'button-demo',
  components: {
    CodeEditor,
    EspDxButton,
    EspAddButton,
    EspSaveButton,
    EspCancelButton,
    EspDeleteButton,
    EspSortSaveButton,
    EspFolderButton,
    EspChevronButton,
    EspExcelButton,
    EspFooterButton,
    EspFlagButton,
  },

  watch: {
  },
  data() {
    return {
      // 초기 코드 설정
      code: demoTemplate,
    };
  },
  methods: {
    /** @description: 코드 에디터의 내용을 초기화 */
    handleResetCode() {
      this.code = demoTemplate;
      this.handleApplyCode(this.code);
    },
    /**
     * @description 코드 에디터의 내용을 파싱하여 프리뷰 영역에 컴포넌트를 렌더링
     * @throws {Error} 컴포넌트 생성 또는 마운트 중 발생하는 에러
     */
     handleApplyCode() {
      try {
        // 기존 컴포넌트 초기화
        if (this.currentComponent) {
          this.currentComponent.$destroy();
          this.$refs.previewContainer.innerHTML = '';
        }

        // 코드 파싱 및 컴포넌트 생성
        const { template, data } = this.parseVueComponent(this.code);
        const ComponentClass = this.createNewComponent(template, data?.button);

        // 새로운 마운트 포인트 생성
        const mountPoint = document.createElement('div');
        mountPoint.className = 'preview-mount-point';
        this.$refs.previewContainer.appendChild(mountPoint);

        // Vue 컴포넌트의 새 인스턴스 생성
        this.currentComponent = new ComponentClass({
          parent: this, // 현재 컴포넌트를 부모로 설정
        });

        this.currentComponent.$mount(mountPoint);

      } catch (error) {
        this.$log.error('Error:', error);
        this.$refs.previewContainer.innerHTML = `
          <div class="error">
            <h3>Error:</h3>
            <pre>${error.message}</pre>
          </div>
        `;
      }
    },
    /**
     * @description 컴포넌트 문자열을 파싱하여 template과 data 객체로 분리
     * @param {string} code - Vue 컴포넌트 전체 코드 문자열
     * @returns {Object} 파싱된 컴포넌트 구조
     *         - template: 컴포넌트의 template 부분
     *         - data: 실행된 data 객체
     * @throws {Error} 스크립트나 data 함수를 찾을 수 없는 경우
     */
     parseVueComponent(code) {
      try {
        const templateMatch = code.match(/<template>([\s\S]*?)<\/template>/);
        const template = templateMatch ? templateMatch[1].trim() : '';

        const scriptMatch = code.match(/<script>([\s\S]*?)<\/script>/);
        if (!scriptMatch) {
          throw new Error('스크립트를 찾을 수 없습니다.');
        }

        const dataMatch = scriptMatch[1].match(/data\s*\(\s*\)\s*{\s*return\s*({[\s\S]*?});?\s*}/);
        if (!dataMatch) {
          throw new Error('data 함수를 찾을 수 없습니다.');
        }

        const dataStr = dataMatch[1].replace(/this\./g, 'context.'); // this를 context로 변경
        const dataObj = this.executeComponentData(dataStr); // data 함수 실행

        return {
          template,
          data: dataObj
        };
      } catch (error) {
        this.$log.error('컴포넌트 파싱 에러:', error);
        throw error;
      }
    },
    /**
     * @description Vue 컴포넌트의 data 문자열을 실행하여 실제 data 객체로 변환
     * @param {string} dataStr - Vue 컴포넌트의 data 부분 문자열
     * @param {Object} context - Vue 컴포넌트의 this
     * @returns {Object} 실제 data 객체
     */
    executeComponentData(dataStr, context = this) {
      const evalFn = new Function('context', `
        with(context) {
          return ${dataStr};
        }
      `);

      try {
        return evalFn(context);
      } catch (error) {
        this.$log.error('data 실행 중 에러::', error);
        return {};
      }
    },
    /**
     * @description Vue 컴포넌트를 동적으로 생성하는 메서드
     *              template, components, data를 영역으 컴포넌트 생성
     * @param {string} template - 컴포넌트의 템플릿 문자열
     * @param {Object} data - 컴포넌트의 data 객체
     * @returns {VueComponent} Vue.extend로 생성된 컴포넌트 클래스
     */
    createNewComponent(template, demoTemplate) {
      const baseOptions = {
        template,
        components: { EspDxButton, EspAddButton, EspSaveButton, EspCancelButton, EspDeleteButton, EspSortSaveButton, EspFolderButton, EspChevronButton, EspExcelButton, EspFooterButton, EspFlagButton },
        data() {
          return {
            button: demoTemplate,
          };
        },
        methods: {
          /** @description: 버튼 클릭 이벤트 */
          handleClick() {
            console.log('버튼 클릭');
          },
        },
      };

      return Vue.extend(baseOptions);
    },
  },
  created() {},
  async mounted() {
    // 초기 코드로 컴포넌트 생성
    this.handleApplyCode(this.code);

    this.$store.commit('setPagingHists',  null);
  },
};
</script>

<style scoped>
::v-deep .btn-demo {
  padding-top: 30px;
}

::v-deep .btn-demo .title {
  font-size: 16px;
  font-weight: 500;
  padding-top: 10px;
}

.real-time-editor {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.editor, .preview {
  width: 100%;
  padding: 15px 5px;
}

.preview-container {
  min-height: 400px;
}

@media (min-width: 768px) {
  .real-time-editor {
    flex-direction: row;
  }
  .editor {
    width: 35%;
  }
  .preview {
    width: 65%;
  }
}

.error {
  color: red;
  padding: 10px;
  border: 1px solid red;
  border-radius: 4px;
  background-color: #fff5f5;
}

.error pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 0;
}
</style>
