<!--
  PACKAGE_NAME : src\pages\ewm\approval\process
  FILE_NAME : index
  AUTHOR : devyoon91
  DATE : 2024-05-30
  DESCRIPTION : 결재프로세스 관리
-->
<template>
  <div>
    <div class="page-sub-box ewm">
      <div class="flex gap-x-6 h-auto">
        <div class="w-6/12">
          <h3 class="text-xl font-medium my-4">결재선 목록</h3>
          <div>
            <esp-dx-data-grid
              :data-grid="approvalLineGrid"
              ref="approvalLineGrid"
              @row-click="onRowClickOfApprovalLineGrid"
              @cell-prepared="onGridCellPrepared"
            />
            <DxToolTip :ref="approversTooltipRefName" position="bottom">
              <div>
                <div><b>결재자:</b> {{ toolTipCurrentApprovers }}</div>
              </div>
            </DxToolTip>
          </div>
        </div>
        <div class="w-6/12">
          <h3 class="text-xl font-medium my-4">결재선 등록</h3>
          <div>
            <esp-dx-data-grid :data-grid="approvalLineChangeGrid" ref="approvalLineChangeGrid" />
          </div>
        </div>
      </div>
    </div>
    <ModalAddAgent
      :isOpen="modalAddAgent.visible"
      :showModalTitle="true"
      :selectedIdList="modalAddAgent.selectedIdList"
      saveBtnTxt="선택"
      title="결재자 정보"
      @closeModal="onModalAddAgentClose"
      @saveModal="onModalAddAgentSave"
    />
  </div>
</template>

<script>
  import { on } from 'devextreme/events';
  import DxToolTip from 'devextreme-vue/tooltip';
  import ModalAddAgent from '@/components/ewm/hr/modal-add-agent.vue';
  import { isSuccess } from '@/plugins/common-lib';
  import { DxButton } from 'devextreme-vue/button';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: {
      EspDxDataGrid,
      ModalAddAgent,
      DxToolTip,
    },
    props: {},
    watch: {},
    data() {
      return {
        codeMap: {},
        modalAddAgent: {
          visible: false,
          selectedIdList: [],
          setRowIndex: 0,
        },
        config: {
          stylingMode: 'outlined',
        },
        toolTipCurrentApprovers: '',
        approversTooltipRefName: 'approversTooltip',
        approvalLineGrid: {
          keyExpr: 'approvalLineId',
          refName: 'approvalLineGrid',
          disableTotalCount: true, // 전체 카운트 표시 여부
          allowColumnResizing: false,
          columnResizingMode: 'widget',
          columnAutoWidth: false,
          focusedRowEnabled: false, // 포커스 행 표시 유무
          hoverStateEnabled: true,
          showBorders: true, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          // width:'200',     // 주석처리시 100%
          height: 'calc(100vh - 270px)', // 주석처리시 100%
          dataSource: [],
          scrolling: {
            mode: 'standard',
          },
          callApi: 'CALL_EWM_API',
          apiActionNm: {},
          customEvent: {
            rowClick: true,
            initNewRow: true,
            cellPrepared: true,
          },
          showActionButtons: {
            copy: false,
            select: true,
            update: false,
            delete: false,
            excel: false,
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false,
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: false, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: false, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'none', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '결재구분',
              dataField: 'approvalType',
              alignment: 'center',
              visible: true,
              allowFiltering: false,
              allowHeaderFiltering: false,
              lookup: {
                dataSource: this.$_enums.ewm.approvalType.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
            {
              caption: '1차 결재자',
              dataField: 'primaryApproversStr',
              alignment: 'center',
              visible: true,
              allowFiltering: false,
              allowHeaderFiltering: false,
              calculateCellValue: rowData => this.makeApproverNames(rowData.primaryApprovers, rowData.primaryApproversNames),
            },
            {
              caption: '2차 결재자',
              dataField: 'secondaryApproversStr',
              alignment: 'center',
              visible: true,
              allowFiltering: false,
              allowHeaderFiltering: false,
              calculateCellValue: rowData => this.makeApproverNames(rowData.secondaryApprovers, rowData.secondaryApproversNames),
            },
            {
              caption: '3차 결재자',
              dataField: 'tertiaryApproversStr',
              alignment: 'center',
              visible: true,
              allowFiltering: false,
              allowHeaderFiltering: false,
              calculateCellValue: rowData => this.makeApproverNames(rowData.tertiaryApprovers, rowData.tertiaryApproversNames),
            },
          ],
        },
        approvalLineChangeGrid: {
          keyExpr: 'id',
          refName: 'approvalLineChangeGrid',
          disableTotalCount: true, // 전체 카운트 표시 여부
          allowColumnResizing: false,
          columnResizingMode: 'widget',
          columnAutoWidth: false,
          focusedRowEnabled: false, // 포커스 행 표시 유무
          hoverStateEnabled: true,
          showBorders: true, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          // width:'200',     // 주석처리시 100%
          height: 'calc(100vh - 270px)', // 주석처리시 100%
          dataSource: [],
          scrolling: {
            mode: 'standard',
          },
          callApi: 'CALL_EWM_API',
          apiActionNm: {},
          customEvent: {
            rowClick: true,
            // initNewRow: true,
            // cellPrepared: true,
            initialized: true,
          },
          showActionButtons: {
            copy: false,
            select: false,
            update: false,
            delete: false,
            excel: false,
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '저장',
                  showText: 'always',
                  elementAttr: { class: 'btn_XS default filled' },
                  width: 60,
                  height: 30,
                  onClick: async () => {
                    await this.saveApprovalLine();
                    this.$refs.approvalLineChangeGrid.getInstance.cancelEditData();
                    await this.getApprovalLineList();
                    this.$refs.approvalLineChangeGrid.getInstance.option('dataSource', []);
                  },
                },
                location: 'before',
                sortIndex: 60,
              },
            ],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false,
          },
          pager: {
            visible: false, //페이저 표시 여부
            totalCountVisible: false,
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: false, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: false, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'none', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '순서',
              dataField: 'approverNo',
              alignment: 'center',
              width: '60px',
              visible: true,
              allowFiltering: false,
              allowHeaderFiltering: false,
            },
            {
              caption: '결재자',
              dataField: 'approverArr',
              alignment: 'center',
              visible: false,
              allowFiltering: false,
              allowHeaderFiltering: false,
            },
            {
              caption: '결재자명',
              dataField: 'approverNmArr',
              alignment: 'center',
              visible: true,
              allowFiltering: false,
              allowHeaderFiltering: false,
              cellTemplate: (container, options) => {
                let aTag = document.createElement('a');
                if (options.value == null || options.value === '' || options.value === undefined) {
                  aTag.innerText = '추가';
                } else {
                  aTag.innerText = options.value;
                }
                aTag.addEventListener('click', () => {
                  this.modalAddAgent.setRowIndex = options.rowIndex;
                  this.modalAddAgent.selectedIdList = options?.data?.approverArr;
                  this.modalAddAgent.visible = true;
                });

                const nextButton = new DxButton({
                  propsData: {
                    template: '<span class="mdi mdi-refresh"></span>',
                    height: 15,
                    type: 'button',
                    hint: '초기화',
                    elementAttr: { class: 'btn_XS white outlined mr-0' },
                    onClick: () => {
                      this.$refs.approvalLineChangeGrid.getInstance.cellValue(options.rowIndex, 'approverArr', '');
                      this.$refs.approvalLineChangeGrid.getInstance.cellValue(options.rowIndex, 'approverNmArr', '');
                    },
                  },
                });
                nextButton.$mount();

                const divTag = document.createElement('div');
                divTag.setAttribute('class', 'flex content-center justify-between');
                divTag.appendChild(aTag);
                divTag.appendChild(nextButton.$el);
                container.append(divTag);
              },
            },
          ],
        },
      };
    },
    computed: {},
    methods: {
      /**
       * 데이터 그리드 셀 이벤트
       * @param e
       */
      onGridCellPrepared(e) {
        if (
          e.rowType === 'data' &&
          (e.column.dataField === 'primaryApproversStr' ||
            e.column.dataField === 'secondaryApproversStr' ||
            e.column.dataField === 'tertiaryApproversStr')
        ) {
          const self = this;
          let approvers = '';

          if (e.column.dataField === 'primaryApproversStr') {
            approvers = e?.data?.primaryApproversNames;
          } else if (e.column.dataField === 'secondaryApproversStr') {
            approvers = e?.data?.secondaryApproversNames;
          } else if (e.column.dataField === 'tertiaryApproversStr') {
            approvers = e?.data?.tertiaryApproversNames;
          }

          on(e.cellElement, 'mouseover', arg => {
            this.toolTipCurrentApprovers = approvers?.replaceAll('|', ',') || '';
            self.$refs[this.approversTooltipRefName].instance.show(arg.target);
          });

          on(e.cellElement, 'mouseout', arg => {
            self.$refs[this.approversTooltipRefName].instance.hide();
          });
        }
      },
      /**
       * 결재선 목록 그리드 - 행 클릭 이벤트
       * @param e
       * @return {Promise<void>}
       */
      async onRowClickOfApprovalLineGrid(e) {
        this.$refs.approvalLineChangeGrid.getInstance.cancelEditData();
        this.$refs.approvalLineChangeGrid.getInstance.option('dataSource', [
          {
            id: 1,
            approverNo: '1차',
            approverArr: e.data?.primaryApprovers?.split('|'),
            approverNmArr: this.makeApproverNames(e.data?.primaryApprovers, e.data?.primaryApproversNames, 5),
          },
          {
            id: 2,
            approverNo: '2차',
            approverArr: e.data?.secondaryApprovers?.split('|'),
            approverNmArr: this.makeApproverNames(e.data?.secondaryApprovers, e.data?.secondaryApproversNames, 5),
          },
          {
            id: 3,
            approverNo: '3차',
            approverArr: e.data?.tertiaryApprovers?.split('|'),
            approverNmArr: this.makeApproverNames(e.data?.tertiaryApprovers, e.data?.tertiaryApproversNames, 5),
          },
        ]);
      },
      /**
       * 결재자명을 변환해서 반환 ex) 홍길동 외 2명
       * @param approverIds {string} 결재자ID
       * @param approverNms {string} 결재자명
       * @param limit {number} 제한할 결재자 수
       * @return {string}
       */
      makeApproverNames(approverIds, approverNms, limit) {
        if (!approverIds && !approverNms) {
          return '';
        }

        if (!limit) {
          limit = 3;
        }

        // 홍길동|김철수|이영희 -> 홍길동 외 2명
        let approverIdArr = approverIds.split('|');
        let approverNmArr = approverNms.split('|');
        if (approverIdArr.length >= limit) {
          let remaining = approverIdArr.length - 1;
          return `${approverNmArr[0]}(${approverIdArr[0]}) 외 ${remaining}명`;
        } else {
          let approverNmStr = '';
          approverIdArr
            .join('|')
            .split('|')
            .forEach((id, idx) => {
              approverNmStr += `${approverNmArr[idx]}(${id})`;
              if (idx < approverIdArr.length - 1) {
                approverNmStr += ', ';
              }
            });
          return approverNmStr;
        }
      },
      /**
       * 결재자 정보 모달 닫기
       */
      onModalAddAgentClose() {
        this.modalAddAgent.visible = false;
      },
      /**
       * 결재자 정보 모달 저장
       * @param e
       */
      onModalAddAgentSave(e) {
        this.modalAddAgent.visible = false;
        let approverIds = e.map(item => item.agtid);
        let approverNmStr = e.map(item => item.agtNm).join('|');
        this.$refs.approvalLineChangeGrid.getInstance.cellValue(this.modalAddAgent.setRowIndex, 'approverArr', approverIds);
        this.$refs.approvalLineChangeGrid.getInstance.cellValue(
          this.modalAddAgent.setRowIndex,
          'approverNmArr',
          this.makeApproverNames(approverIds?.join('|'), approverNmStr, 10),
        );
        this.modalAddAgent.selectedIdList = approverIds;
        this.modalAddAgent.setRowIndex = 0;
      },
      /**
       * 결재선 정보 저장
       */
      async saveApprovalLine() {
        const hasEditData = this.$refs.approvalLineChangeGrid.getInstance.hasEditData();

        // 변경된 데이터가 없을 경우
        if (!hasEditData) {
          this.$_Msg(this.$_lang('CMN_NO_CHANGED'));
          return;
        }

        if (await this.$_Confirm('결재선 정보를 저장하시겠습니까?')) {
          const selData = this.$refs.approvalLineGrid.getInstance.getSelectedRowsData();
          if (selData.length === 0) {
            this.$_Msg(this.$_lang('CMN_NO_SELECTED'));
            return;
          }

          const approvers = this.$refs.approvalLineChangeGrid.getInstance.getVisibleRows();
          const payload = {
            actionName: 'EWM_APPROVAL_LINE_MERGE',
            data: {
              approvalLineId: selData[0].approvalLineId,
              approvalType: selData[0].approvalType,
              primaryApprovers: approvers[0].data.approverArr.length > 0 ? approvers[0].data.approverArr?.join('|') : '',
              secondaryApprovers: approvers[1].data.approverArr.length > 0 ? approvers[1].data.approverArr?.join('|') : '',
              tertiaryApprovers: approvers[2].data.approverArr.length > 0 ? approvers[2].data.approverArr?.join('|') : '',
            },
            useErrorPopup: true,
          };

          let rtnData = 0;
          const res = await this.CALL_EWM_API(payload);
          if (isSuccess(res)) {
            this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
            rtnData = selData[0].approvalLineId;
          }
          return rtnData;
        }
      },
      /**
       * 결재선 목록 조회
       * @return {Promise<*>}
       */
      async getApprovalLineList() {
        let vm = this;
        this.approvalLineGrid.dataSource = new CustomStore({
          key: 'approvalLineId',
          async load() {
            const payload = {
              actionName: 'EWM_APPROVAL_LINE_LIST',
              data: {},
              useErrorPopup: true,
            };

            let rtnData = [];
            const res = await vm.CALL_EWM_API(payload);
            if (isSuccess(res)) {
              rtnData = res.data.data;
            }
            return rtnData;
          },
        });
      },
    },
    created() {},
    mounted() {
      this.getApprovalLineList();
    },
  };
</script>

<style lang="scss" scoped>
  ::v-deep {
    #approvalDetail .dx-datagrid-header-panel {
      display: none !important;
    }

    .ewm #approvalSelectbox {
      padding-bottom: 10px;
    }

    .addBtn {
      margin-left: 10px;
    }

    #button-div {
      display: flex;
      justify-content: center;
      height: calc(40% - 78px);
      align-items: flex-end;
    }

    .dx-treelist .dx-treelist-content .dx-treelist-table .dx-row > td:not(.dx-validation-pending):not(.dx-treelist-select-all) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
</style>
