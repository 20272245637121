<template>
  <div class="page-sub-box ewm">
    <div class="locker_setting_list sub_new_style01 sub_ui_box1">
      <div class="page_search_box line_bottom_1px">
        <DxValidationGroup ref="searchValidationGroup">
          <div class="flex space-x-2 inner">
            <div class="mt-2">기준년월</div>
            <DxDateBox
              :edit-enabled="false"
              :styling-mode="stylingMode"
              width="100"
              height="30"
              v-model="searchType.customTypes.dayStart"
              type="date"
              dateSerializationFormat="yyyyMMdd"
              display-format="yyyy.MM"
              dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
              :max="searchType.customTypes.dayEnd"
              maxLength="7"
              invalid-date-message="입력 데이터가 유효하지 않습니다."
              :calendar-options="{
                zoomLevel: 'year',
                minZoomLevel: 'decade',
                maxZoomLevel: 'year',
              }"
              @value-changed="onDayStartChanged"
            >
              <DxValidator>
                <DxRequiredRule message="기준년월은 필수입니다." />
              </DxValidator>
            </DxDateBox>
            <div class="mt-1">~</div>
            <DxDateBox
              :styling-mode="stylingMode"
              width="100"
              height="30"
              v-model="searchType.customTypes.dayEnd"
              type="date"
              dateSerializationFormat="yyyyMMdd"
              display-format="yyyy.MM"
              dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
              maxLength="7"
              :min="searchType.customTypes.dayStart"
              invalid-date-message="입력 데이터가 유효하지 않습니다."
              :calendar-options="{
                zoomLevel: 'year',
                minZoomLevel: 'decade',
                maxZoomLevel: 'year',
              }"
              @value-changed="onDayEndChanged"
            >
              <DxValidator>
                <DxRequiredRule message="기준년월은 필수입니다." />
              </DxValidator>
            </DxDateBox>

            <div class="mt-2">강의유형</div>
            <DxDropDownBox
              v-model="searchType.customTypes.menuSearchDropdown.selectedRowKeys"
              :defer-rendering="true"
              :show-clear-button="true"
              :data-source="codes.eduType.dataSource"
              :placeholder="getPlaceholder()"
              :styling-mode="stylingMode"
              :width="300"
              :height="30"
              :opened="menuSearchDropdown.isGridBoxOpened"
              @opened="onMenuSearchDropdownOpened"
            >
              <template #content="{}">
                <DxDataGrid
                  :selected-row-keys="searchType.customTypes.menuSearchDropdown.selectedRowsData"
                  :data-source="codes.eduType.dataSource"
                  :hover-state-enabled="true"
                  @selection-changed="onGridSelectionChanged($event)"
                  :show-column-headers="true"
                  height="100%"
                  ref="menuDropdownGrid"
                >
                  <DxSelection mode="multiple" />
                  <DxColumn caption="강의유형" data-field="codeNm" />
                </DxDataGrid>
              </template>
            </DxDropDownBox>

            <DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="handleClick" />
          </div>
        </DxValidationGroup>
      </div>
    </div>
    <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" />
  </div>
</template>

<script>
  import { DxDateBox } from 'devextreme-vue/date-box';
  import { DxButton } from 'devextreme-vue/button';
  import DxDropDownBox from 'devextreme-vue/drop-down-box';
  import { DxColumn, DxDataGrid, DxSelection } from 'devextreme-vue/data-grid';
  import { DxRequiredRule, DxValidator } from 'devextreme-vue/validator';
  import { DxValidationGroup } from 'devextreme-vue/validation-group';
  import {formatDate, getPastFromToday, isSuccess} from '@/plugins/common-lib';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: {
      EspDxDataGrid,
      DxDataGrid,
      DxDateBox,
      DxButton,
      DxDropDownBox,
      DxSelection,
      DxColumn,
      DxRequiredRule,
      DxValidator,
      DxValidationGroup,
    },
    props: {},
    watch: {
      'searchType.customTypes.menuSearchDropdown.selectedRowsData': {
        //data with key and list to send to parent component
        handler(val) {
          let filterData = val;
          if (filterData) {
            this.searchType.customTypes.menuSearchDropdown.selectedRowKeys =
              this.searchType.customTypes.menuSearchDropdown.selectedRowsData.map(a => a.id);
          }
          if (!filterData) {
            this.$refs.menuDropdownGrid.instance.deselectAll();
          }
        },
        deep: true,
      },
      'searchType.customTypes.menuSearchDropdown.selectedRowKeys': {
        //data with key and list to send to parent component
        handler(val) {
          if (!val) {
            this.$refs.menuDropdownGrid.instance.deselectAll();
          }
        },
        deep: true,
      },
    },
    data() {
      return {
        stylingMode: 'outlined', //outlined, underlined, filled
        menuSearchDropdown: {
          gridDataSource: [],
          isGridBoxOpened: false,
        },
        searchType: {
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            //menuId: null,
            menuSearchDropdown: {
              selectedRowsData: [],
              selectedRowKeys: [],
            },
            dayStart: getPastFromToday(11, 'months'),
            dayEnd: getPastFromToday(0, 'days'),
          },
          paramsData: null,
        },
        codes: {
          jikgupCd: {
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
          jikweeCd: {
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
          eduType: {
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
        },
        selectedCodeNms: [],
        dataGrid: {
          keyExpr: 'agtId',
          refName: 'educationCompletionGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          columnResizingMode: 'widget',
          columnAutoWidth: true,
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false, //행 배경색
          dataSource: [],
          callApi: 'CALL_EWM_API',
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          scrolling: {
            // 미사용시 주석처리
            mode: 'standard', //스크롤 모드 : ['infinite', 'standard', 'virtual']
          },
          remoteOperations: {
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [], //페이지 사이즈 선택 박스
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: false, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: false,
            allowDeleting: false,
            allowAdding: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'none', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          excel: {
            title: '교육이수 현황'
          },
          showActionButtons: {
            excel: {
              allowExportSelectedData: false,
            },
            customButtons: [],
          },
          columns: [
            {
              caption: '부서정보',
              dataField: 'deptNmPath',
              alignment: 'center',
              visible: true,
              sortOrder: 'none',
              allowFiltering: true,
              allowHeaderFiltering: true,
              allowGrouping: false,
              allowSorting: true,
            },
            {
              caption: '상담사ID',
              dataField: 'agtId',
              visible: false,
              allowSorting: false,
            },
            {
              caption: '상담사',
              dataField: 'agtNm',
              alignment: 'center',
              width: 130,
              visible: true,
              allowFiltering: true,
              allowHeaderFiltering: true,
              allowSorting: true,
              calculateCellValue: rowData => `${rowData.agtNm} [${rowData.agtId}]`,
            },
            {
              caption: '직급',
              dataField: 'jikgupCd',
              alignment: 'center',
              width: 120,
              visible: true,
              allowFiltering: true,
              allowHeaderFiltering: true,
              allowSorting: true,
              calculateSortValue: data => this.calculateSortValue(data, 'jikgupCd'),
              lookup: {},
            },
            {
              caption: '직책',
              dataField: 'jikweeCd',
              alignment: 'center',
              width: 120,
              visible: true,
              allowFiltering: true,
              allowHeaderFiltering: true,
              allowSorting: true,
              calculateSortValue: data => this.calculateSortValue(data, 'jikweeCd'),
              lookup: {},
            },
            {
              caption: '예측교육',
              dataField: 'totalEduTime1074',
              alignment: 'center',
              width: 120,
              visible: false,
              allowSorting: true,
              allowHeaderFiltering: false,
              allowFiltering: true,
              calculateCellValue: rowData =>
                `${Math.floor(rowData.totalEduTime1074 / 60)
                  .toString()
                  .padStart(2, '0')}:${(rowData.totalEduTime1074 % 60).toString().padStart(2, '0')}`,
            },
            {
              caption: '관리자교육',
              dataField: 'totalEduTime1075',
              alignment: 'center',
              width: 120,
              visible: false,
              allowSorting: true,
              allowHeaderFiltering: false,
              allowFiltering: true,
              calculateCellValue: rowData =>
                `${Math.floor(rowData.totalEduTime1075 / 60)
                  .toString()
                  .padStart(2, '0')}:${(rowData.totalEduTime1075 % 60).toString().padStart(2, '0')}`,
            },
            {
              caption: '신입교육',
              dataField: 'totalEduTime1076',
              alignment: 'center',
              width: 120,
              visible: false,
              allowSorting: true,
              allowHeaderFiltering: false,
              allowFiltering: true,
              calculateCellValue: rowData =>
                `${Math.floor(rowData.totalEduTime1076 / 60)
                  .toString()
                  .padStart(2, '0')}:${(rowData.totalEduTime1076 % 60).toString().padStart(2, '0')}`,
            },
            {
              caption: '예금상품교육',
              dataField: 'totalEduTime1077',
              alignment: 'center',
              width: 130,
              visible: false,
              allowSorting: true,
              allowHeaderFiltering: false,
              allowFiltering: true,
              calculateCellValue: rowData =>
                `${Math.floor(rowData.totalEduTime1077 / 60)
                  .toString()
                  .padStart(2, '0')}:${(rowData.totalEduTime1077 % 60).toString().padStart(2, '0')}`,
            },
            {
              caption: '대출상품교육',
              dataField: 'totalEduTime1078',
              alignment: 'center',
              width: 130,
              visible: false,
              allowSorting: true,
              allowHeaderFiltering: false,
              allowFiltering: true,
              calculateCellValue: rowData =>
                `${Math.floor(rowData.totalEduTime1078 / 60)
                  .toString()
                  .padStart(2, '0')}:${(rowData.totalEduTime1078 % 60).toString().padStart(2, '0')}`,
            },
            {
              caption: '합계',
              dataField: 'sumEduTime',
              alignment: 'center',
              width: 110,
              visible: true,
              allowSorting: true,
              allowHeaderFiltering: false,
              allowFiltering: true,
              calculateCellValue: rowData => {
                let sum = 0;
                this.dataGrid.columns.forEach(column => {
                  if (column.dataField.startsWith('totalEduTime') && column.visible) {
                    sum += rowData[column.dataField];
                    return sum;
                  }
                });
                return `${Math.floor(sum / 60)
                  .toString()
                  .padStart(2, '0')}:${(sum % 60).toString().padStart(2, '0')}`;
              },
            },
          ],
        },
      };
    },
    computed: {
      searchValidationGroup: function () {
        return this.$refs['searchValidationGroup'].instance;
      },
    },
    methods: {
      /** @description : 소트설정 */
      calculateSortValue(data, clsCd) {
        if (clsCd === 'jikgupCd') {
          return this.codes.jikgupCd.dataSource.find(e => data.jikgupCd === e.codeId).codeNm;
        } else if (clsCd === 'jikweeCd') {
          return this.codes.jikweeCd.dataSource.find(e => data.jikweeCd === e.codeId).codeNm;
        }
      },
      getPlaceholder() {
        if (this.selectedCodeNms && this.selectedCodeNms.length > 0) {
          return this.selectedCodeNms.join(', ');
        } else {
          return '표시여부 선택';
        }
      },
      /** @description : 드롭다운 메뉴 오픈 이벤트  */
      onMenuSearchDropdownOpened() {
        this.menuSearchDropdown.isGridBoxOpened = true;
      },

      /** @description : 드롭다운 컬럼 선택 이벤트  */
      onGridSelectionChanged(data) {
        this.searchType.customTypes.menuSearchDropdown.selectedRowsData = data.selectedRowKeys;

        // 선택된 항목의 index 값을 가져옴
        const selectedIndexes = this.searchType.customTypes.menuSearchDropdown.selectedRowsData.map(row => row.codeId);

        this.selectedCodeNms = selectedIndexes.map(index => {
          const matchingItem = this.codes.eduType.dataSource.find(item => item.codeId === index);
          return matchingItem ? matchingItem.codeNm : null;
        });

        // 전체 컬럼 false, 선택된 값 없을 시 전체 true
        this.dataGrid.columns.forEach(column => {
          if (column.dataField && column.dataField.startsWith('totalEduTime')) {
            column.visible = false;
          }
        });

        // 선택된 index에 해당하는 컬럼만 보이도록 업데이트
        selectedIndexes.forEach(index => {
          const dataIndex = `totalEduTime${index}`;
          const column = this.dataGrid.columns.find(column => column.dataField === dataIndex);
          if (column) {
            column.visible = true;
          }
        });
      },
      onChangeSearchDay() {
        const regDt = this.searchType.customTypes.dayStart + '000000' + '~' + this.searchType.customTypes.dayEnd + '235959';
        this.searchType.paramsData = { ...this.searchType.paramsData, regDt };

        this.$_setSearchHistsCustomType(this.searchType.paramsData);
      },
      /** @description : 날짜 형식 변환 메서드 */
      formatDt(rowData) {
        if (rowData?.regDt) {
          return formatDate(rowData.regDt, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
        }
      },
      /**
       * 교육이수 현황 조회
       *
       * @param sort
       * @return {Promise<{data: *[], totalCount: number}>}
       */
      async selectDataList(sort = '-agtId') {
        const vm = this;

        if (!this.searchValidationGroup.validate().isValid) {
          return;
        }

        this.dataGrid.dataSource = new CustomStore({
          key: 'agtId',
          async load(loadOptions) {
            let params = vm.$_getDxDataGridParam(loadOptions);
            if (!params.sort) {
              params.sort = sort;
            }

            vm.searchType.paramsData['frScheYmd'] = vm.searchType.customTypes.dayStart;
            vm.searchType.paramsData['toScheYmd'] = vm.searchType.customTypes.dayEnd;

            const payload = {
              actionName: 'EWM_EDUCATION_COMPLETION_LIST',
              data: { ...params, ...vm.searchType.paramsData },
              useErrorPopup: true,
            };

            let rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await vm.CALL_EWM_API(payload);
            if (isSuccess(res)) {
              rtnData.data = res.data.data;
              rtnData.totalCount = res.data.totalCount;
            }
            return rtnData;
          },
        });
      },
      onDayStartChanged(e) {
        this.searchType.customTypes.dayStart = e.value.slice(0, 6) + '01';
      },
      onDayEndChanged(e) {
        this.searchType.customTypes.dayEnd = e.value.slice(0, 6) + '29';
      },
      /** @description : 셀렉트 박스 초기화 */
      async initCodeMap() {
        this.codeMap = await this.$_getCodeMapList('root_ewm_edu_education_type, root_ewm_hr_jikwee, root_ewm_hr_jikgup');
      },
      async handleClick() {
        await this.selectDataList();
      },
    },
    created() {
      this.initCodeMap().then(() => {
        this.codes.eduType.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_edu_education_type'], 2);
        this.codes.jikweeCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_hr_jikwee'], 2);
        this.codes.jikgupCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_hr_jikgup'], 2);

        this.$refs.educationCompletionGrid.getInstance.columnOption('jikweeCd', 'lookup', this.codes.jikweeCd);
        this.$refs.educationCompletionGrid.getInstance.columnOption('jikgupCd', 'lookup', this.codes.jikgupCd);
      });

      this.onChangeSearchDay();
    },
    async mounted() {
      await this.selectDataList();
    },
  };
</script>

<style scoped>
  .sub_new_style01 .page_search_box .inner div {
    display: inline-block;
  }
  .sub_new_style01 .page_search_box .inner > div {
    vertical-align: middle;
    margin-right: 10px;
  }
  .page_search_box .inner .box-btn-search {
    margin-right: 15px;
  }
</style>
