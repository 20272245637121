export default {
  MESSAGE: {
    WORK_SCHEDULE_CANNOT_MODIFY_PAST_DATES: '과거 날짜는 근무변경 할 수 없습니다.',
    SELECT_COUNSELOR_TO_MODIFY: '수정할 상담원을 선택해주세요.',
    CONFIRM_REVOKE_ORDER: '발령을 취소하시겠습니까?',
    REQUIRED_AGENT_ID: '상담원ID를 입력하세요.',
    REQUIRED_AGENT_NM: '상담원명을 입력하세요.',
    REQUIRED_AGENT_DN: '내선번호를 입력하세요.',
    REQUIRED_DEPT: '부서를 선택하세요.',
    REQUIRED_JIKGUP: '직급을 선택하세요.',
    REQUIRED_JIKWEE: '직책를 선택하세요.',
    REQUIRED_AUTH_ID: '계정 권한을 선택하세요.',
    REQUIRED_WORKGROUP: '업무그룹을 선택하세요.',
    REQUIRED_USED_CTI_FL: 'CTI 연동 여부를 선택하세요.',
    REQUIRED_LOGIN_FL: '로그인 연동 여부를 선택하세요.',
  },
  WORD: {
    ATTENDANCE_STATUS_NORMAL: '정상',
    ATTENDANCE_STATUS_LATE: '지각',
    ATTENDANCE_STATUS_EARLY_LEAVE: '조퇴',
    ATTENDANCE_STATUS_ABSENCE: '결근',
  },
};