<!--
  PACKAGE_NAME : src/pages/euc/chineseWall/blackList
  FILE_NAME : black-list.vue
  AUTHOR : jhcho
  DATE : 2024-06-13
  DESCRIPTION :
-->
<template>
  <div class="locker_setting_list sub_new_style01 sub_ui_box1">
    <Search
      codeKey="null"
      :customSearchList="searchType.list"
      :searchObj="searchType.obj"
      @change-search-type="$_changeSearchType"
      @onSearchClick="searchData($event)"
    >
    </Search>
    <esp-dx-data-grid
      :data-grid="dataGrid"
      ref="blackListGrid"
      @saving="onSaving"
      @editing-start="onEditingStart"
      @init-new-row="onInitNewRow"
    />
    <DxPopup
      v-model="modal.isOpened"
      :show-title="true"
      :title="modal.initData ? modal.initData.title : null"
      :width="modal.initData ? modal.initData.width : null"
      :height="modal.initData ? modal.initData.height : null"
      :drag-enabled="true"
      :resize-enabled="true"
      :show-close-button="true"
      :hide-on-outside-click="false"
      :visible="modal.isOpened"
      @hiding="isOpenModal(false)"
    >
      <template #content>
        <div>
          <component
            ref="modalRef"
            v-if="modal.sendData"
            :is="modal.currentComponent"
            :modalData="modal.sendData"
            :isModal="modal.isModal"
          />
        </div>
      </template>

      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :options="{
          elementAttr: {
            class: 'default filled txt_S medium',
          },
          text: $_lang('COMPONENTS.SELECT', { defaultValue: '선택' }),
          width: '120',
          height: '40',
          onClick: () => onSelectedUser(),
        }"
      />

      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :options="{
          elementAttr: {
            class: 'white filled txt_S medium',
          },
          text: $_lang('COMPONENTS.CLOSE', { defaultValue: '닫기' }),
          width: '120',
          height: '40',
          onClick: () => isOpenModal(false),
        }"
      />
    </DxPopup>
  </div>
</template>
<script>
  import Search from '@/components/common/search.vue';
  import { isEmpty, isSuccess } from '@/plugins/common-lib';
  import { DxButton } from 'devextreme-vue/button';
  import ModalUserSelect from '@/components/euc/modal-user-select.vue';
  import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: {
      EspDxDataGrid,
      DxToolbarItem,
      DxPopup,
      Search,
      ModalUserSelect,
    },
    data() {
      return {
        modal: {
          isOpened: false,
          currentComponent: null,
          initData: {},
          contentData: null,
        },
        stylingMode: 'outlined',
        searchType: {
          list: [
            {
              codeNm: this.$_lang('UC.WORD.USER_NO', { defaultValue: '사번' }),
              codeValue: 'reqId',
            },
            {
              codeNm: this.$_lang('COMPONENTS.NAME', { defaultValue: '이름' }),
              codeValue: 'reqNm',
            },
            {
              codeNm: this.$_lang('COMPONENTS.DEPT', { defaultValue: '부서' }),
              codeValue: 'reqDept',
            },
            {
              codeNm: this.$_lang('UC.WORD.SENDER_NUMBER', { defaultValue: '발신자 번호' }),
              codeValue: 'cellerNo',
            },
            {
              codeNm: this.$_lang('COMPONENTS.DESCRIPTION', { defaultValue: '설명' }),
              codeValue: 'reason',
            },
          ],
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {},
          paramsData: null,
        },
        dataGrid: {
          callApi: 'CALL_EUC_API',
          refName: 'blackListGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          height: 'calc(100vh - 295px)', // 주석처리시 100%
          apiActionNm: {
            loading: true,
            delete: 'EUC_BLACKLIST_DELETE',
          },
          excel: {
            title: this.$_lang('UC.WORD.BLACKLIST', { defaultValue: '블랙리스트' }),
          },
          showActionButtons: {
            excel: true,
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '삭제',
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 60,
                  height: 30,
                  onClick: () => {
                    this.deleteItems();
                  },
                },
                location: 'before',
              },
            ],
          },
          customEvent: {
            saving: true,
            initNewRow: true,
            editingStart: true,
          },
          isDuplicateConfigKey: false, //설정키 중복 체크
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: true, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              multiHeaderNm: '신청자',
              i18n: 'UC.WORD.REQUESTER',
              columns: [
                {
                  caption: 'selectUser',
                  dataField: 'selectUser',
                  width: 80,
                  height: 40,
                  alignment: 'center',
                  visible: true,
                  allowEditing: false,
                  sortOrder: 'none',
                  allowHeaderFiltering: false,
                  allowGrouping: false,
                  headerCellTemplate: container => {
                    const icon = document.createElement('i');
                    icon.className = 'dx-icon dx-icon-user';
                    container.append(icon);
                  },
                  cellTemplate: (container, options) => {
                    const button = new DxButton({
                      propsData: {
                        text: this.$_lang('COMPONENTS.SELECT', { defaultValue: '선택' }),
                        elementAttr: { class: 'btn_XS default filled add1 m-0' },
                        width: 60,
                        height: 30,
                        value: options.value,
                        onClick: () => {
                          options.onSelectedUser = user => {
                            const rowIndex = options.component.getRowIndexByKey(options.row.key);
                            options.component.cellValue(rowIndex, 'reqId', user.userNo);
                            options.component.cellValue(rowIndex, 'reqNm', user.userNm);
                            options.component.cellValue(rowIndex, 'reqDept', user.deptNm);
                          };
                          options.title = this.$_lang('UC.WORD.REQUESTER_SELECT', { defaultValue: '요청자 선택' });
                          this.showUserModal(options);
                        },
                      },
                    });

                    button.$mount();
                    container.append(button.$el);
                  },
                },
                {
                  caption: '사번',
                  i18n: 'UC.WORD.USER_NO',
                  dataField: 'reqId',
                  width: 90,
                  height: 40,
                  alignment: 'center',
                  visible: true,
                  allowEditing: true,
                  sortOrder: 'none',
                  allowHeaderFiltering: false,
                  allowGrouping: false,
                },
                {
                  caption: '이름',
                  i18n: 'COMPONENTS.NAME',
                  dataField: 'reqNm',
                  width: 90,
                  height: 40,
                  alignment: 'center',
                  visible: true,
                  allowEditing: true,
                  sortOrder: 'none',
                  allowHeaderFiltering: false,
                  allowGrouping: false,
                },
                {
                  caption: '부서',
                  i18n: 'COMPONENTS.DEPT',
                  dataField: 'reqDept',
                  width: 130,
                  height: 40,
                  alignment: 'center',
                  visible: true,
                  allowEditing: true,
                  sortOrder: 'none',
                  allowHeaderFiltering: false,
                  allowGrouping: false,
                },
              ],
            },
            {
              caption: '발신자 번호',
              i18n: 'UC.WORD.SENDER_NUMBER',
              dataField: 'callerNo',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '제목',
              i18n: 'COMPONENTS.TITLE',
              dataField: 'title',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '설명',
              i18n: 'COMPONENTS.DESCRIPTION',
              dataField: 'description',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '차단 종료일',
              i18n: 'UC.WORD.BLOCK_END_DATE',
              dataField: 'finDt',
              dataType: 'date',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              cellTemplate: (container, options) => {
                if (!options?.value || options.value?.getFullYear() === 9999) {
                  container.innerText = this.$_lang('UC.WORD.INDEFINITE', { defaultValue: '무기한' });
                } else {
                  if (options.value) {
                    const date = options.value;
                    const formattedDate = `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(
                      -2,
                    )}`;
                    container.innerText = formattedDate;
                  }
                }
              },
            },
            {
              caption: '사용여부',
              i18n: 'COMPONENTS.USE_STATUS',
              dataField: 'viewFl',
              width: 120,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              lookup: {
                dataSource: this.$_enums.common.stringUsedFlag.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
            {
              caption: '등록일',
              i18n: 'COMPONENTS.REGISTRATION_DATE',
              dataField: 'regDt',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              calculateCellValue: row => this.formatDt(row.regDt),
            },
          ],
        },
      };
    },
    methods: {
      onInitNewRow(e) {
        e.data.viewFl = 'Y';
      },
      async deleteItems() {
        const deletedIds = this.$refs.blackListGrid.selectedRowsData.map(d => {
          return { id: d.id };
        });

        if (!deletedIds?.length) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return false;
        }

        if (
          await this.$_Confirm(
            this.$_lang('COMMON.MESSAGE.CMN_CFM_DELETE_SELECTED', { defaultValue: '선택한 데이터를 삭제하시겠습니까?' }),
          )
        ) {
          const payload = {
            actionName: 'EUC_BLACKLIST_DELETE',
            data: {
              data: deletedIds,
            },
            loading: true,
          };

          const res = await this.CALL_EUC_API(payload);

          if (isSuccess(res)) {
            await this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_SUC_DELETE', { defaultValue: '선택한 데이터가 삭제되었습니다.' }));
            this.$refs.blackListGrid.refreshData();
          } else {
            this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          }
        }
      },
      onEditingStart(e) {
        if (e.column.name === 'finDt' && e.data?.finDt) {
          const date = this.$_commonlib.moment(e.data.finDt, 'YYYYMMDDHHmmssSSS');
          if (date.toDate().getFullYear() === 9999) {
            e.data.finDt = null;
          }
        }
      },
      async onSaving(e) {
        if (e.changes && e.changes?.length) {
          const changeData = e.changes.map(item => {
            const { key: id, data } = item;
            const returnData = { id, ...data };

            if (item.type === 'insert') {
              returnData.id = null;
            }

            if (Object.prototype.hasOwnProperty.call(returnData, 'finDt') && !returnData?.finDt) {
              returnData.finDt = new Date(Date.UTC(9999, 11, 31, 23, 59, 59, 999));
            }

            return returnData;
          });

          const payload = {
            actionName: 'EUC_BLACKLIST_UPDATE',
            data: changeData,
            loading: true,
          };
          const res = await this.CALL_EUC_API(payload);
          if (isSuccess(res)) {
            this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
            this.$refs.blackListGrid.refreshData();
          } else {
            this.$_Toast(this.$_lang('COMMON.CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          }
        }
      },
      onSelectedUser() {
        const user = this.$refs.modalRef.getSelectUser();
        if (!user?.[0] || !user[0].isUser) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.REQUIRED_SELECT_USER', { defaultValue: '사용자를 선택해주세요.' }), {
            icon: 'info',
          });
          return;
        }
        this.modal.sendData.onSelectedUser(user[0]);

        this.isOpenModal(false);
      },
      showUserModal(options) {
        this.onOpenModal(
          'ModalUserSelect',
          {
            title: options.title,
            width: '1000',
            height: '600',
            buttons: {
              save: { text: this.$_lang('COMPONENTS.SAVE', { defaultValue: '저장' }) },
              cancel: { text: this.$_lang('COMPONENTS.CLOSE', { defaultValue: '닫기' }) },
            },
          },
          options,
        );
      },
      onOpenModal(componentNm, componentInitData, sendData) {
        this.modal.currentComponent = componentNm; //set dynamic component name in modal body slot
        this.modal.initData = componentInitData; //set init modal templet
        this.modal.sendData = sendData;
        this.isOpenModal(true);
      },
      isOpenModal(bool) {
        if (!bool) {
          this.$refs.modalRef.clearSelection();
        }
        this.modal.isOpened = bool;
      },
      searchData(paramsData) {
        if (paramsData) {
          this.searchType.paramsData = { ...this.searchType.paramsData, ...paramsData };
        } else {
          let codeValues = this.searchType.list.map(d => d.codeValue);
          if (!isEmpty(this.searchType.paramsData)) {
            Object.keys(this.searchType.paramsData).forEach(key => {
              if (codeValues.includes(key)) {
                delete this.searchType.paramsData[key];
              }
            });
          }
        }
        this.selectDataList();
      },
      async selectDataList(sort = '-regDt') {
        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);
            if (!params.sort) {
              params.sort = sort;
            }

            params = { ...params, ...this.searchType.paramsData };

            const payload = {
              actionName: 'EUC_BLACKLIST_SELECT',
              data: params,
              loading: false,
            };

            const rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await this.CALL_EUC_API(payload);
            if (isSuccess(res)) {
              rtnData.data = res.data.data;
              rtnData.totalCount = res.data.header.totalCount;
            }
            return rtnData;
          },
          insert: () => {},
          update: () => {},
        });
      },
      /** @description : 날짜 형식 변환 메서드 */
      formatDt(date, toFormat = 'YYYY.MM.DD HH:mm:ss') {
        if (date) {
          return this.$_commonlib.formatDate(date, 'YYYYMMDDHHmmssSSS', toFormat);
        }
      },
    },
    mounted() {
      this.selectDataList();
    },
    created() {},
  };
</script>
<style scoped></style>