<!--
  PACKAGE_NAME : src/components/common
  FILE_NAME : license-tool-tip.vue
  AUTHOR : devyoon91
  DATE : 2024-08-14
  DESCRIPTION : 계정 라이센스 툴팁 컴포넌트
-->
<template>
  <div class="content-info-box01 pad_top10 mar_to10">
      라이센스 보유 현황 : {{ memberLicenseCnt.toLocaleString() }} /
      {{ getSystemMemberLicenseCnt.toLocaleString() }}<br/>
      계약 하에 할당된 라이선스 개수를 초과하여 계정을 생성할 수 없습니다.(휴면/잠금 계정은 라이선스 계정 수에 포함, 삭제 계정은 미포함)
  </div>
</template>

<script>
  export default {
    components: {},
    props: {},
    data() {
      return {
        memberLicenseCnt: 0,
      };
    },
    computed: {
      /** @description: 시스템 설정의 유저 라이센스 개수 */
      getSystemMemberLicenseCnt() {
        return Number(this.$_getSystemData('member_license_cnt').configValue);
      },
    },
    methods: {
      /**
       * @description 유저 라이센스 개수 가져오기
       * @return {Promise<void>}
       */
      async getMemberLicenseCnt() {
        // 유저 라이센스 개수 가져오기
        this.memberLicenseCnt = await this.$_getMemberLicenseCnt();
      },
      /**
       * @description 유저 라이센스 개수 초기화
       */
      async initLicenseCnt() {
        await this.getMemberLicenseCnt();
      },
      /**
       * @description 남은 라이센스 개수
       * @return {Promise<T>}
       */
      async getLeftLicenseCnt() {
        return this.getMemberLicenseCnt().then(
          () => {
            return this.getSystemMemberLicenseCnt - this.memberLicenseCnt;
          },
          () => {
            this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          },
        );
      },
      /**
       * 라이센스 사용 가능 여부
       *
       * @return {Promise<boolean>}
       */
      async isLicenseAvailable() {
        const leftLicenseCnt = await this.getLeftLicenseCnt();
        return leftLicenseCnt > 0;
      },
    },
    created() {},
    mounted() {
      this.getMemberLicenseCnt();
    },
  };
</script>

<style lang="scss" scoped></style>