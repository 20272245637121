<!--
  PACKAGE_NAME : src\components\devextreme\esp-dx-button
  FILE_NAME : esp-dx-button
  AUTHOR : hmlee
  DATE : 2025-01-09
  DESCRIPTION : ESP 버튼 컴포넌트
-->
<template>
  <dx-button
    class="esp-dx-button"
    :visible="optionConfig.visible"
    :text="optionConfig.text"
    :icon="optionConfig.icon"
    :type="optionConfig.type"
    :class="optionConfig.class"
    :template="optionConfig.template"
    :hint="optionConfig.hint"
    :width="optionConfig.width"
    :height="optionConfig.height"
    :disabled="optionConfig.disabled"
    :elementAttr="optionConfig.elementAttr"
    :focus-state-enabled="optionConfig.focusStateEnabled"
    :hover-state-enabled="optionConfig.hoverStateEnabled"
    :validation-group="optionConfig.validationGroup"
    :use-submit-behavior="optionConfig.useSubmitBehavior"
    @click="handleClick"
  >
  </dx-button>
</template>
<script>
  import { DxButton } from 'devextreme-vue/button';

  export default {
    components: {
      DxButton,
    },
    props: {
      option: {
        default: () => ({}),
        type: Object,
      },
      i18n: { // 다국어 처리(text보다 우선 적용)
        default: () => '',
        type: String,
      },
      text: { // 기본 버튼 텍스트
        default: () => '',
        type: String,
      },
      icon: { // 아이콘
        default: () => '',
        type: String,
      },
      mdiIcon: { // mdi 아이콘
        default: () => '',
        type: String,
      },
      prefixIcon: { // prefix 아이콘
        default: () => '',
        type: String,
      },
      type: { // 버튼 타입
        default: () => 'normal',
        type: String,
      },
      color: { // 버튼 컬러
        default: () => '',
        type: String,
      },
      mode: {  // 버튼 타입
        default: () => '',
        type: String,
      },
      size: { // 버튼 크기
        default: () => '',
        type: String,
      },
      template: { // 템플릿
        default: () => '',
        type: String,
      },
      hint: { // 힌트
        default: () => '',
        type: String,
      },
      width: { // 버튼 너비
        default: () => '',
        type: String,
      },
      height: { // 버튼 높이
        default: () => '',
        type: String,
      },
      disabled: { // 비활성화 여부
        default: () => false,
        type: Boolean,
      },
      elementAttr: { // 엘리먼트 속성
        default: () => ({}),
        type: Object,
      },
      focusStateEnabled: { // 포커스 상태 활성화 여부
        default: () => true,
        type: Boolean,
      },
      hoverStateEnabled: { // hover 상태 활성화 여부
        default: () => true,
        type: Boolean,
      },
      validationGroup: { // 유효성 검사 그룹
        default: () => null,
        type: String,
      },
      useSubmitBehavior: { // 제출 동작 사용 여부
        default: () => false,
        type: Boolean,
      },
      isPreset: { // 미리 설정된 버튼(자주 사용하는 버튼) 여부
        default: () => false,
        type: Boolean,
      },
    },
    data() {
      return {
        defaultOption: {
          visible: true, // 타이틀 표시 여부
          text: '', // 타이틀
          icon: '', // 아이콘
          type: 'normal', // 버튼 타입
          class: '', // 엘리먼트 속성
          color: '', // 색상 ['default', 'green', 'red', 'white', 'gray', 'black']
          mode: '', // 타입 ['filled', 'outlined']
          size: '', // 크기 ['xs': xsmall, 'sm': small, 'md': medium, 'lg': large]
          template: '', // 템플릿
          width: '', // 너비
          height: '', // 높이
          elementAttr: {}, // 엘리먼트 속성
          disabled: false, // 비활성화 여부
          focusStateEnabled: true, // 포커스 상태 활성화 여부
          hoverStateEnabled: true, // hover 상태 활성화 여부
          validationGroup: null, // 유효성 검사 그룹
          useSubmitBehavior: false, // 제출 동작 사용 여부
          isPreset: false, // 미리 설정된 버튼(자주 사용하는 버튼) 여부
        },
      };
    },
    computed: {
      /** @description: 옵션 설정 데이터 */
      optionConfig() {
        return {
          ...this.defaultOption,
          ...this.option,
          text: this.btnText,
          class: this.btnClasses,
          icon: this.icon,
          template: this.btnTemplate,
          elementAttr: this.btnElementAttr,
          hint: this.hint,
          width: this.btnWidth,
          height: this.btnHeight,
          disabled: this.disabled,
          focusStateEnabled: this.focusStateEnabled,
          hoverStateEnabled: this.hoverStateEnabled,
          validationGroup: this.validationGroup,
          useSubmitBehavior: this.useSubmitBehavior,
          isPreset: this.isPreset,
        };
      },
      /** @description: 버튼 텍스트(i18n 옵션 우선 적용)
       * i18n 옵션이 있으면 i18n 옵션 우선 적용
       * i18n 옵션이 없다면 text 옵션 적용
       * i18n, text 옵션이 없다면 '' 적용*/
      btnText() {
        // i18n 옵션 우선 적용
        if (this.i18n) {
          return this.$_lang(this.i18n);
        }

        // text 옵션 후 적용
        if (this.text) {
          return this.text;
        }

        // 둘 다 없으면 기본 값
        return '';
      },
      /** @description: 버튼 템플릿 */
      btnTemplate() {
        if (this.mdiIcon) {
          return `<span class="mdi mdi-${ this.mdiIcon }"></span>`;
        } else {  // mdiIcon이 없으면 template 표시
          return this.template;
        }
      },
      /** @description: 버튼 클래스 */
      btnClasses() {
        const classes = [
          this.btnColor, // 색상
          this.btnMode, // 타입
          this.btnSize, // 크기
          this.prefixIcon, // prefix 아이콘
        ];

        if (this.width && !this.isPreset) { // width 옵션이 있으며, 일반 버튼인 경우 padding 제거
          classes.push('no-padding');
        }

        if ( this.prefixIcon && !this.i18n && !this.text ) {  // prefix 아이콘 단독으로 사용하는 경우
          classes.push('icon-only');
        }

        return classes;
      },
      /** @description: 버튼 색상 */
      btnColor() {
        if (this.color) {
          return this.color;
        } else {
          return 'default';
        }
      },
      /** @description: 버튼 모드 */
      btnMode() {
        if (this.mode) {
          return this.mode;
        } else {
          return 'filled';
        }
      },
      /** @description: 버튼 크기 */
      btnSize() {
        if ( this.prefixIcon && !this.i18n && !this.text ) {  // prefix 아이콘 단독으로 사용하는 경우
          return '';
        }

        if (this.size) {
          return `btn-size-${ this.size.toLowerCase() }`;
        } else {
          return 'btn-size-sm';
        }
      },
      /** @description: 버튼 엘리먼트 속성 */
      btnElementAttr() {
        // height 옵션이 없으면 높이 설정
        let height = 30;
        if (this.height) {
          height = this.height;
        }

        const baseAttr = { style: { lineHeight: `${height - 2}px` } };

        // elementAttr이 없거나 빈 객체면 baseAttr 반환
        if (!this.elementAttr || Object.keys(this.elementAttr).length === 0) {
          return baseAttr;
        }

        // elementAttr에 style이 있는 경우
        if (this.elementAttr.style) {
          return {
            ...this.elementAttr,  // 기존 속성 전체 복사
            style: {
              ...this.elementAttr.style,  // 기존 style 복사
              lineHeight: `${height - 2}px`  // lineHeight 덮어쓰기
            }
          };
        }

        // elementAttr에 style이 없는 경우
        return {
          ...this.elementAttr,  // 기존 속성 복사
          style: baseAttr.style  // style 추가
        };
      },
      /** @description: 버튼 너비 */
      btnWidth() {
        // width 옵션이 있으면 width 옵션 우선 적용
        if (this.width) {
          return this.width;
        } else {
          return 'auto';
        }
      },
      /** @description: 버튼 높이 */
      btnHeight() {
        // height 옵션이 있으면 height 옵션 우선 적용
        if (this.height) {
          return this.height;
        }

        // height 옵션이 없다면 size 옵션에 따라 높이 설정
        switch(this.size) {
          case 'xs':
            return '28';
          case 'sm':
            return '28';
          case 'md':
            return '32';
          case 'lg':
            return '34';
          default:
            return '30';
        }
      },
    },
    watch: {
    },
    methods: {
      /**
       * @description 클릭 이벤트
       * @param e
       */
      handleClick(e) {
        this.$emit('click', e);
      },
    },
  };
</script>

<style>
  // 상위 css가 우선 적용되어 scope 적용 불가로 class 지정하여 적용(추후 개선 필요)
  /*** 테마 색상 ***/
  .esp-dx-button.dx-button.default.filled {
    background: var(--themeColor);
    border-color: transparent;
    color: #fff;
  }

  .esp-dx-button.dx-button.default.filled span {
    color: #fff;
  }

  .esp-dx-button.dx-button.default.filled:hover,
  .esp-dx-button.dx-button.default.filled:focus {
    background: var(--themeColorLight);
    cursor: pointer;
  }

  .esp-dx-button.dx-button.default.outlined {
    background: #fff;
  //border: 1px solid var(--themeColor) !important;
    border-color: var(--themeColor);
    color: var(--themeColor);
  }

  .esp-dx-button.dx-button.default.outlined:hover,
  .esp-dx-button.dx-button.default.outlined:focus {
    background: var(--themeColorHover);
    border-color: var(--themeColor);
    cursor: pointer;
  }
  /*** 테마 색상 ***/

  /*** 그린 색상 ***/
  .esp-dx-button.dx-button.green.filled {
    background: #1d6b40;
    border: 1px solid #1d6b40;
    color: #fff;
  }

  .esp-dx-button.dx-button.green.filled span {
    color: #fff;
  }

  .esp-dx-button.dx-button.green.filled:hover,
  .esp-dx-button.dx-button.green.filled:focus {
    background: #1a603a;
    box-shadow: none;
    cursor: pointer;
  }

  .esp-dx-button.dx-button.green.outlined {
    background: #fff;
    border: 1px solid #1d6b40 !important;
  }

  .esp-dx-button.dx-button.green.outlined span {
    color: #1d6b40;
  }

  .esp-dx-button.dx-button.green.outlined:hover,
  .esp-dx-button.dx-button.green.outlined:focus {
    background: rgba(31, 100, 62, 0.05) !important;
    border: 1px solid #1d6b40;
    box-shadow: none;
    cursor: pointer;
  }
  /*** 그린 색상 ***/

  /*** 레드 색상 ***/
  .esp-dx-button.dx-button.red.filled {
    background: #dc3545;
    border: 1px solid #dc3545;
    color: #fff;
  }

  .esp-dx-button.dx-button.red span {
    color: #fff;
  }

  .esp-dx-button.dx-button.red.filled:hover,
  .esp-dx-button.dx-button.red.filled:focus {
    background: #e04957;
    box-shadow: none;
    cursor: pointer;
  }

  .esp-dx-button.dx-button.red.outlined {
    background: #fff;
    border: 1px solid #dc3545;
  }

  .esp-dx-button.dx-button.red.outlined span {
    color: #dc3545;
  }

  .esp-dx-button.dx-button.red.outlined:hover,
  .esp-dx-button.dx-button.red.outlined:focus {
    background: rgba(220, 53, 69, 0.05);
    border: 1px solid #dc3545;
    box-shadow: none;
    cursor: pointer;
  }
  /*** 레드 색상 ***/

  /*** 화이트 색상 ***/
  .esp-dx-button.dx-button.white.filled {
    border: 1px solid #cecece;
  }

  .esp-dx-button.dx-button.white.filled:hover,
  .esp-dx-button.dx-button.white.filled:focus {
    background: #f5f5f5;
    box-shadow: none;
    cursor: pointer;
  }

  .esp-dx-button.dx-button.white.outlined {
    background: #fff;
    border: 1px solid #ccc;
  }

  .esp-dx-button.dx-button.white.outlined:hover,
  .esp-dx-button.dx-button.white.outlined:focus {
    background: #fff;
    border: 1px solid #b3b3b3;
    box-shadow: none;
    cursor: pointer;
  }
  /*** 화이트 색상 ***/

  /*** 그레이 색상 ***/
  .esp-dx-button.dx-button.gray.filled {
    background: #656565;
    border: 1px solid #656565;
    color: #fff;
  }

  .esp-dx-button.dx-button.gray span {
    color: #fff;
  }

  .esp-dx-button.dx-button.gray.filled:hover,
  .esp-dx-button.dx-button.gray.filled:focus {
    background: #757575;  /* 10% 밝은 색상 */
    box-shadow: none;
    cursor: pointer;
  }

  .esp-dx-button.dx-button.gray.outlined {
    background: #fff;
    border: 1px solid #656565;
  }

  .esp-dx-button.dx-button.gray.outlined span {
    color: #656565;
  }

  .esp-dx-button.dx-button.gray.outlined:hover,
  .esp-dx-button.dx-button.gray.outlined:focus {
    background: rgba(101, 101, 101, 0.05);  /* gray 색상의 5% 투명도 */
    border: 1px solid #656565;
    box-shadow: none;
    cursor: pointer;
  }
  /*** 그레이 색상 ***/

  /*** 블랙 색상 ***/
  .esp-dx-button.dx-button.black.filled {
    background: #212121;
    border: 1px solid #212121;
    color: #fff;
  }

  .esp-dx-button.dx-button.black span {
    color: #fff;
  }

  .esp-dx-button.dx-button.black.filled:hover,
  .esp-dx-button.dx-button.black.filled:focus {
    background: #1a1a1a;  /* 10% 더 어두운 */
    box-shadow: none;
    cursor: pointer;
  }

  .esp-dx-button.dx-button.black.outlined {
    background: #fff;
    border: 1px solid #212121;
  }

  .esp-dx-button.dx-button.black.outlined span {
    color: #212121;
  }

  .esp-dx-button.dx-button.black.outlined:hover,
  .esp-dx-button.dx-button.black.outlined:focus {
    background: rgba(33, 33, 33, 0.05);  /* black 색상의 5% 투명도 */
    border: 1px solid #212121;
    box-shadow: none;
    cursor: pointer;
  }
  /*** 블랙 색상 ***/

  /*** 플래그 버튼 ***/
  /*** 그린 버튼 ***/
  .esp-dx-button.dx-button.flag.green {
    background: #d1fae5;
    border: 1px solid #3bd39a;
  }

  .esp-dx-button.dx-button.flag.green span {
    color: #065f46;
  }

  /*** 레드 버튼 ***/
  .esp-dx-button.dx-button.flag.red {
    background: #fee2e2;
    border: 1px solid #f67073;
  }

  .esp-dx-button.dx-button.flag.red span {
    color: #991b1b;
  }

  /*** 옐로우 버튼 ***/
  .esp-dx-button.dx-button.flag.yellow {
    background: #fef3c7;
    border: 1px solid #fbd257;
    color: #92400e;
  }

  .esp-dx-button.dx-button.flag.yellow span {
    color: #92400e;
  }

  .esp-dx-button.dx-button.flag span {
    font-size: 12px;
    font-weight: 500;
  }
  /*** 플래그 버튼 ***/

  /*** 버튼 크기 ***/
  .esp-dx-button.btn-size-xs {
    padding: 0 8px;
    line-height: 26px;
  }
  .esp-dx-button.btn-size-xs span {
    font-size: 12px;
  }

  .esp-dx-button.btn-size-sm {
    padding: 0 10px;
    line-height: 28px;
  }
  .esp-dx-button.btn-size-sm span {
    font-size: 13px;
  }

  .esp-dx-button.btn-size-md {
    padding: 0 12px;
    line-height: 30px;
  }
  .esp-dx-button.btn-size-md span {
    font-size: 14px;
  }

  .esp-dx-button.btn-size-lg {
    padding: 0 14px;
    line-height: 32px;
  }
  .esp-dx-button.btn-size-lg span {
    font-size: 15px;
  }

  .esp-dx-button.dx-button {
    min-width: 20px;
  }

  .esp-dx-button.no-padding {
    padding: 0;
  }

  .esp-dx-button.dx-button .dx-button-text {
    line-height: 0;
  }
  /*** 버튼 크기 ***/

  /*** ESP 전용 아이콘 ***/
  .esp-dx-button.dx-button.add1,
  .esp-dx-button.dx-button.add2,
  .esp-dx-button.dx-button.add3,
  .esp-dx-button.dx-button.del1,
  .esp-dx-button.dx-button.trash,
  .esp-dx-button.dx-button.excel,
  .esp-dx-button.dx-button.filter,
  .esp-dx-button.dx-button.modify,
  .esp-dx-button.dx-button.cancel1,
  .esp-dx-button.dx-button.return,
  .esp-dx-button.dx-button.download,
  .esp-dx-button.dx-button.zoomout,
  .esp-dx-button.dx-button.print,
  .esp-dx-button.dx-button.listen,
  .esp-dx-button.dx-button.refresh,
  .esp-dx-button.dx-button.preview,
  .esp-dx-button.dx-button.more,
  .esp-dx-button.dx-button.close,
  .esp-dx-button.dx-button.overflow,
  .esp-dx-button.dx-button.up-arr,
  .esp-dx-button.dx-button.down-arr,
  .esp-dx-button.dx-button.first-arr,
  .esp-dx-button.dx-button.last-arr,
  .esp-dx-button.dx-button.question,
  .esp-dx-button.dx-button.pull-down,
  .esp-dx-button.dx-button.pull-up,
  .esp-dx-button.dx-button.send {
    padding-left: 26px;
  }

  .esp-dx-button.dx-dropdownbutton.add1,
  .esp-dx-button.dx-dropdownbutton.add2,
  .esp-dx-button.dx-dropdownbutton.add3,
  .esp-dx-button.dx-dropdownbutton.del1,
  .esp-dx-button.dx-dropdownbutton.trash,
  .esp-dx-button.dx-dropdownbutton.excel,
  .esp-dx-button.dx-dropdownbutton.filter,
  .esp-dx-button.dx-dropdownbutton.modify,
  .esp-dx-button.dx-dropdownbutton.cancel1,
  .esp-dx-button.dx-dropdownbutton.return,
  .esp-dx-button.dx-dropdownbutton.download,
  .esp-dx-button.dx-dropdownbutton.zoomout,
  .esp-dx-button.dx-dropdownbutton.print,
  .esp-dx-button.dx-dropdownbutton.listen,
  .esp-dx-button.dx-dropdownbutton.refresh,
  .esp-dx-button.dx-dropdownbutton.preview,
  .esp-dx-button.dx-dropdownbutton.more,
  .esp-dx-button.dx-dropdownbutton.close,
  .esp-dx-button.dx-dropdownbutton.overflow,
  .esp-dx-button.dx-dropdownbutton.up-arr,
  .esp-dx-button.dx-dropdownbutton.down-arr,
  .esp-dx-button.dx-dropdownbutton.first-arr,
  .esp-dx-button.dx-dropdownbutton.last-arr,
  .esp-dx-button.dx-dropdownbutton.question,
  .esp-dx-button.dx-dropdownbutton.pull-down,
  .esp-dx-button.dx-dropdownbutton.pull-up,
  .esp-dx-button.dx-dropdownbutton.send {
    padding-left: 26px;
  }

  .esp-dx-button.dx-dropdownbutton.add1:before,
  .esp-dx-button.dx-button.add1:before {
    position: absolute;
    display: block;
    content: "";
    top: 50%;
    margin-top: 0;
    left: 3px;
    transform: translate(0%, -50%);
    width: 20px;
    height: 20px;
    background: url(../../assets/images/ecs_gnb_sprite.png) no-repeat;
    background-position: -12px -193px;
  }

  .esp-dx-button.dx-dropdownbutton.add2:before,
  .esp-dx-button.dx-button.add2:before {
    position: absolute;
    display: block;
    content: "";
    top: 50%;
    margin-top: 0;
    left: 3px;
    transform: translate(0%, -50%);
    width: 20px;
    height: 20px;
    background: url(../../assets/images/ecs_gnb_sprite.png) no-repeat;
    background-position: -80px -193px;
  }

  .esp-dx-button.dx-dropdownbutton.add3:before,
  .esp-dx-button.dx-button.add3:before {
    position: absolute;
    display: block;
    content: "";
    top: 50%;
    margin-top: 0;
    left: 3px;
    transform: translate(0%, -50%);
    width: 20px;
    height: 20px;
    background: url(../../assets/images/ecs_gnb_sprite.png) no-repeat;
    background-position: -176px -382px;
  }

  .esp-dx-button.dx-dropdownbutton.del1:before,
  .esp-dx-button.dx-button.del1:before {
    position: absolute;
    display: block;
    content: "";
    top: 50%;
    margin-top: 0;
    left: 3px;
    transform: translate(0%, -50%);
    width: 20px;
    height: 20px;
    background: url(../../assets/images/ecs_gnb_sprite.png) no-repeat;
    background-position: -219px -382px;
  }

  .esp-dx-button.dx-dropdownbutton.trash:before,
  .esp-dx-button.dx-button.trash:before {
    position: absolute;
    display: block;
    content: "";
    top: 50%;
    margin-top: 0;
    left: 3px;
    transform: translate(0%, -50%);
    width: 20px;
    height: 20px;
    background: url(../../assets/images/ecs_gnb_sprite.png) no-repeat;
    background-position: -44px -193px;
  }

  .esp-dx-button.dx-dropdownbutton.cancel1:before,
  .esp-dx-button.dx-button.cancel1:before {
    position: absolute;
    display: block;
    content: "";
    top: 50%;
    margin-top: 0;
    left: 3px;
    transform: translate(0%, -50%);
    width: 20px;
    height: 20px;
    background: url(../../assets/images/ecs_gnb_sprite.png) no-repeat;
    background-position: -113px -193px;
  }

  .esp-dx-button.dx-dropdownbutton.excel:before,
  .esp-dx-button.dx-button.excel:before {
    position: absolute;
    display: block;
    content: "";
    top: 50%;
    margin-top: 0;
    left: 3px;
    transform: translate(0%, -50%);
    width: 20px;
    height: 20px;
    background: url(../../assets/images/ecs_gnb_sprite.png) no-repeat;
    background-position: -51px -334px;
    filter: invert(1) brightness(1000%);
  }

  .esp-dx-button.dx-dropdownbutton.filter:before,
  .esp-dx-button.dx-button.filter:before {
    position: absolute;
    display: block;
    content: "";
    top: 50%;
    margin-top: 0;
    left: 3px;
    transform: translate(0%, -50%);
    width: 20px;
    height: 20px;
    background: url(../../assets/images/ecs_gnb_sprite.png) no-repeat;
    background-position: -193px -194px;
  }

  .esp-dx-button.dx-dropdownbutton.modify:before,
  .esp-dx-button.dx-button.modify:before {
    position: absolute;
    display: block;
    content: "";
    top: 50%;
    margin-top: 0;
    left: 3px;
    transform: translate(0%, -50%);
    width: 20px;
    height: 20px;
    background: url(../../assets/images/ecs_gnb_sprite.png) no-repeat;
    background-position: -235px -196px;
  }

  .esp-dx-button.dx-dropdownbutton.return:before,
  .esp-dx-button.dx-button.return:before {
    position: absolute;
    display: block;
    content: "";
    top: 50%;
    margin-top: 0;
    left: 3px;
    transform: translate(0%, -50%);
    width: 20px;
    height: 20px;
    background: url(../../assets/images/ecs_gnb_sprite.png) no-repeat;
    background-position: -10px -334px;
  }

  .esp-dx-button.dx-dropdownbutton.download:before,
  .esp-dx-button.dx-button.download:before {
    position: absolute;
    display: block;
    content: "";
    top: 50%;
    margin-top: 0;
    left: 3px;
    transform: translate(0%, -50%);
    width: 20px;
    height: 20px;
    background: url(../../assets/images/ecs_gnb_sprite.png) no-repeat;
    background-position: -92px -334px;
  }

  .esp-dx-button.dx-dropdownbutton.zoomout:before,
  .esp-dx-button.dx-button.zoomout:before {
    position: absolute;
    display: block;
    content: "";
    top: 50%;
    margin-top: 0;
    left: 3px;
    transform: translate(0%, -50%);
    width: 20px;
    height: 20px;
    background: url(../../assets/images/ecs_gnb_sprite.png) no-repeat;
    background-position: -132px -334px;
  }

  .esp-dx-button.dx-dropdownbutton.print:before,
  .esp-dx-button.dx-button.print:before {
    position: absolute;
    display: block;
    content: "";
    top: 50%;
    margin-top: 0;
    left: 3px;
    transform: translate(0%, -50%);
    width: 20px;
    height: 20px;
    background: url(../../assets/images/ecs_gnb_sprite.png) no-repeat;
    background-position: -177px -334px;
  }

  .esp-dx-button.dx-dropdownbutton.listen:before,
  .esp-dx-button.dx-button.listen:before {
    position: absolute;
    display: block;
    content: "";
    top: 50%;
    margin-top: 0;
    left: 3px;
    transform: translate(0%, -50%);
    width: 20px;
    height: 20px;
    background: url(../../assets/images/ecs_gnb_sprite.png) no-repeat;
    background-position: -220px -334px;
  }

  .esp-dx-button.dx-dropdownbutton.refresh:before,
  .esp-dx-button.dx-button.refresh:before {
    position: absolute;
    display: block;
    content: "";
    top: 50%;
    margin-top: 0;
    left: 3px;
    transform: translate(0%, -50%);
    width: 20px;
    height: 20px;
    background: url(../../assets/images/ecs_gnb_sprite.png) no-repeat;
    background-position: -262px -335px;
  }

  .esp-dx-button.dx-dropdownbutton.preview:before,
  .esp-dx-button.dx-button.preview:before {
    position: absolute;
    display: block;
    content: "";
    top: 50%;
    margin-top: 0;
    left: 3px;
    transform: translate(0%, -50%);
    width: 20px;
    height: 20px;
    background: url(../../assets/images/ecs_gnb_sprite.png) no-repeat;
    background-position: -304px -335px;
  }

  .esp-dx-button.dx-dropdownbutton.more:before,
  .esp-dx-button.dx-button.more:before {
    position: absolute;
    display: block;
    content: "";
    top: 50%;
    margin-top: 0;
    left: 3px;
    transform: rotate(90deg) translate(-50%, 0%);
    width: 20px;
    height: 20px;
    background: url(../../assets/images/ecs_gnb_sprite.png) no-repeat;
    background-position: -107px -272px;
  }

  .esp-dx-button.dx-dropdownbutton.close:before,
  .esp-dx-button.dx-button.close:before {
    position: absolute;
    display: block;
    content: "";
    top: 50%;
    margin-top: 0;
    left: 3px;
    transform: translate(0%, -50%);
    width: 20px;
    height: 20px;
    background: url(../../assets/images/ecs_gnb_sprite.png) no-repeat;
    background-position: -280px -265px;
    background-size: 400px 400px;
  }

  .esp-dx-button.dx-dropdownbutton.overflow:before,
  .esp-dx-button.dx-button.overflow:before {
    position: absolute;
    display: block;
    content: "";
    top: 50%;
    margin-top: 0;
    left: 3px;
    transform: translate(0%, -50%);
    width: 20px;
    height: 20px;
    background: url(../../assets/images/ecs_gnb_sprite.png) no-repeat;
    background-position: -107px -272px;
  }

  .esp-dx-button.dx-dropdownbutton.up-arr:before,
  .esp-dx-button.dx-button.up-arr:before {
    position: absolute;
    display: block;
    content: "";
    top: 50%;
    margin-top: 0;
    left: 3px;
    transform: translate(0%, -50%);
    width: 20px;
    height: 20px;
    background: url(../../assets/images/ecs_gnb_sprite.png) no-repeat;
    background-position: -47px -383px;
  }

  .esp-dx-button.dx-dropdownbutton.down-arr:before,
  .esp-dx-button.dx-button.down-arr:before {
    position: absolute;
    display: block;
    content: "";
    top: 50%;
    margin-top: 0;
    left: 3px;
    transform: translate(0%, -50%);
    width: 20px;
    height: 20px;
    background: url(../../assets/images/ecs_gnb_sprite.png) no-repeat;
    background-position: -84px -383px;
  }

  .esp-dx-button.dx-dropdownbutton.first-arr:before,
  .esp-dx-button.dx-button.first-arr:before {
    position: absolute;
    display: block;
    content: "";
    top: 50%;
    margin-top: 0;
    left: 3px;
    transform: translate(0%, -50%);
    width: 20px;
    height: 20px;
    background: url(../../assets/images/ecs_gnb_sprite.png) no-repeat;
    background-position: -10px -381px;
  }

  .esp-dx-button.dx-dropdownbutton.last-arr:before,
  .esp-dx-button.dx-button.last-arr:before {
    position: absolute;
    display: block;
    content: "";
    top: 50%;
    margin-top: 0;
    left: 3px;
    transform: translate(0%, -50%);
    width: 20px;
    height: 20px;
    background: url(../../assets/images/ecs_gnb_sprite.png) no-repeat;
    background-position: -120px -382px;
  }

  /* 물음표 버튼 */
  .esp-dx-button.dx-dropdownbutton.question:before,
  .esp-dx-button.dx-button.question:before {
    position: absolute;
    display: block;
    content: "";
    top: 50%;
    margin-top: 0;
    left: 3px;
    transform: translate(0%, -50%);
    width: 20px;
    height: 20px;
    background: url("../../assets/images/contents_sprite.png") no-repeat;
    background-position: -214px -14px;
  }


  /* 하향 이미지 */
  .esp-dx-button.dx-dropdownbutton.pull-down:before,
  .esp-dx-button.dx-button.pull-down:before {
    position: absolute;
    display: block;
    content: "";
    top: 50%;
    margin-top: 0;
    left: 3px;
    transform: translate(0%, -50%);
    width: 20px;
    height: 20px;
    background: url("../../assets/images/system-uicons_pull-down.png") no-repeat;
  }

  /* 상향 이미지 */
  .esp-dx-button.dx-dropdownbutton.pull-up:before,
  .esp-dx-button.dx-button.pull-up:before {
    position: absolute;
    display: block;
    content: "";
    top: 50%;
    margin-top: 0;
    left: 3px;
    transform: translate(0%, -50%);
    width: 20px;
    height: 20px;
    background: url("../../assets/images/system-uicons_pull-up.png") no-repeat;
  }

  .esp-dx-button.dx-dropdownbutton.send:before,
  .esp-dx-button.dx-button.send:before {
    position: absolute;
    display: block;
    content: "";
    top: 50%;
    margin-top: 0;
    left: 3px;
    transform: translate(0%, -50%);
    width: 20px;
    height: 20px;
    font-size: 13px;
    background: url("../../assets/images/send.png") no-repeat;
    background-position: 3px 3px;
    background-size: 15px 15px;
  }
  /* 전송 이미지 */

  /*** 아이콘만 있는 버튼 ***/
  .esp-dx-button.dx-button.icon-only {
    min-width: 30px;
  }

  .esp-dx-button.dx-button.icon-only:before {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 0;
  }

  .esp-dx-button.dx-button.icon-only.more:before {
    left: 3px;
    transform: rotate(90deg) translate(-50%, 0%);
  }
  /*** ESP 전용 아이콘 ***/
</style>



