import Top from '@/layouts/top.vue';
import Bottom from '@/layouts/bottom.vue';
import LeftMenu from '@/layouts/left-menu.vue';
import Breadcrumbs from '@/layouts/breadcrumbs.vue';
import enums from '../configs/enums';

/**
 * 동적 라우트 추가
 *
 * @param routerInstance 라우터 인스턴스
 * @param menuList 메뉴 목록
 * @returns {number}
 */
export const setAddDynamicRoute = (routerInstance, menuList) => {
  let routeCount = 0;

  for (const menu of menuList) {
    try {
      // 외부 경로로의 리디렉션 설정
      let pageUrl = menu?.pageUrl;
      if (pageUrl.startsWith('http') || pageUrl.startsWith('https')) {
        // 외부 경로로 리디렉션
        routerInstance.addRoute({
          path: '/' + menu.id, // 외부 경로를 위한 유니크한 경로 설정
          beforeEnter() {
            window.location.href = pageUrl;
          },
        });
      } else {
        // 동일한 경로가 존재하는지 확인 후 라우트 추가
        if (!routerInstance.getRoutes().some(route => route.path === pageUrl)) {
          routerInstance.addRoute(getRouteByMenuType(menu));
        }
      }
      routeCount++;
    } catch (error) {
      console.error(`Failed to add route for ${menu?.pageUrl}:`, error);
    }
  }
  return routeCount;
};

/**
 * @description 메뉴 타입에 따라 컴포넌트를 반환합니다.
 * @param menu 메뉴 정보
 * @return components
 */
export const getRouteByMenuType = menu => {
  let pathUrl = menu?.pageUrl;
  if (!pathUrl.startsWith('/')) {
    pathUrl = '/' + pathUrl;
  }

  if (menu?.menuTypeCd === enums.common.menuType.REPORT.value) {
    return {
      path: pathUrl,
      name: `${menu.menuTypeCd}-${menu.id}`,
      components: {
        default: () => import(/* webpackChunkName: "[request]" */ '../pages/report/wizard-report/default-report-view'),
        top: Top,
        bottom: Bottom,
        left: LeftMenu,
        breadcrumbs: Breadcrumbs,
      },
      meta: { requiresAuth: true, menuId: menu.id }, // FIXME : 동적 라우팅 메뉴의 인증 여부 판단은(requiresAuth), DB 정보에서 불러오는게 맞을 것 같음.
    };
  } else {
    return {
      path: pathUrl,
      name: `${menu.menuTypeCd}-${menu.id}`,
      components: {
        default: () => import(/* webpackChunkName: "[request]" */ '../pages' + pathUrl),
        top: Top,
        bottom: Bottom,
        left: LeftMenu,
        breadcrumbs: Breadcrumbs,
      },
      meta: { requiresAuth: true, menuId: menu.id }, // FIXME : 동적 라우팅 메뉴의 인증 여부 판단은(requiresAuth), DB 정보에서 불러오는게 맞을 것 같음.
    };
  }
};
