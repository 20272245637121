<template>
  <div>
    <template v-if="type == 'DEPT'">
      <table class="table_form line-bin fl" style="width: 100%">
        <thead style="border-top: 1px solid; border-bottom: 1px solid; border-color: rgba(229, 231, 235)">
          <template v-if="dataType == 'CODE'">
            <tr>
              <th>부서명</th>
              <td>{{ this.headerData.deptNm }}</td>
              <th>부서코드</th>
              <td>{{ this.headerData.deptCode }}</td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <th>부서명</th>
              <td>{{ this.rowInfo.description }}</td>
              <th>그룹명</th>
              <td>{{ this.rowInfo.name }}</td>
            </tr>
          </template>
        </thead>
      </table>
    </template>
    <template v-else>
      <table class="table_form line-bin fl" style="width: 100%">
        <thead style="border-top: 1px solid; border-bottom: 1px solid; border-color: rgba(229, 231, 235)">
          <tr>
            <th>그룹명</th>
            <td>{{ this.rowInfo.name }}</td>
            <th>설명</th>
            <td>{{ this.rowInfo.description }}</td>
          </tr>
        </thead>
      </table>
    </template>
    <div class="locker_setting_list sub_new_style01 sub_ui_box1">
      <div class="fl">
        <Search
          codeKey="search_type_pickup"
          :searchObj="searchType.obj"
          :useKeywordType="true"
          @change-search-type="$_changeSearchType"
          @onSearchClick="$_searchData($event, 'search_type_pickup')"
        >
        </Search>
      </div>
    </div>
    <table class="table_form" style="width: 100%; border-top: 0px">
      <tbody>
        <tr>
          <esp-dx-data-grid :data-grid="dataGrid" ref="pickupInfoGrid" :auto-width="true" />
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
  import { isSuccess } from '@/plugins/common-lib';
  import Search from '@/components/common/search.vue';
  import CustomStore from "devextreme/data/custom_store";
  import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid.vue";

  export default {
    components: {
      EspDxDataGrid,
      Search,
    },
    props: {
      option: {
        default: () => {
          return {
            width: '80%',
            height: '85%',
            minWidth: null,
            minHeight: null,
            saveBtnVisible: false,
            cancelBtnVisible: false,
          };
        },
        type: Object,
      },

      rowInfo: {
        type: Object,
      },
      isOpened: {
        type: Boolean,
      },
      cmList: {
        type: Array,
      },
      partitionList: {
        type: Array,
      },
      type: {
        type: String,
      },
      dataType: {
        type: String,
      },
      contentData: {
        type: Object,
      },
    },
    watch: {
      deviceInfo() {},
      async type() {
        this.setPartitionVisible(this.type);
      },
      async contentData() {
        this.headerData = this.contentData;
      },
      async isOpened() {
        if (this.isOpened) {
          await this.mountedData();
        } else {
          this.refreshgGrid();
        }
      },
    },
    data() {
      return {
        headerData: {},
        searchType: {
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          searchTexts: {
            value: this.pickupKeyword,
          },
          customTypes: {
            cm: null,
            model: null,
            status: null,
          },
          paramsData: '',
        },
        visible: false,
        dataGrid: {
          refName: 'pickupInfoGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: true, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: false, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          disableTotalCount: false,
          dataSource: [],
          // callApi : null,
          // width:'200',     // 주석처리시 100%
          //height: '500', // 주석처리시 100%
          height: 'calc(100vh - 350px)', // 주석처리시 100%
          apiActionNm: {
            update: '',
          },
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
            rowUpdating: false,
            cellPrepared: false,
            saving: false,
            initNewRow: false,
            toolbarPreparing: false,
          },
          showActionButtons: {
            excel: false, // 엑셀 버튼
            customButtons: [],
          },
          isDuplicateConfigKey: false, //설정키 중복 체크
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'cell', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '그룹명',
              dataField: 'name',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '파티션',
              dataField: 'routepartitionname',
              width: 150,
              height: 40,
              alignment: 'center', // left center right
              visible: this.visible,
              allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
            },
            {
              caption: '설명',
              dataField: 'description',
              // width: 150,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
            },
            {
              caption: '장치정보',
              dataField: 'mac',
              // width: 150,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
            },
            {
              caption: '내선번호',
              dataField: 'dnorpattern',
              width: 120,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '레이블',
              dataField: 'label',
              width: 120,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
          ],
        },
      };
    },
    computed: {},
    methods: {
      /**@description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {},
      /**@description : 라이프사이클 mounted시 호출되는 메서드 */
      async mountedData() {
        await this.selectDataList();
      },
      /**@description 내선 등록 함수 */
      async selectDataList(sort = '+id') {
        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);
            if (!params.sort) {
              params.sort = sort;
            }
            if (this.type == 'DEPT' && this.dataType == 'CODE') {
              let like = '%' + this.contentData.deptCode + '%';
              params = { ...params, ...this.searchType.paramsData, type: this.type, name: like };
            } else {
              params = { ...params, ...this.searchType.paramsData, type: this.type, name: this.rowInfo.name };
            }
            const payload = {
              actionName: 'EUC_PICKUPDN_LIST_ALL',
              data: params,
              loading: false,
            };


            const rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await this.CALL_EUC_API(payload);
            if (isSuccess(res)) {
              rtnData.data = res.data.data;
              rtnData.totalCount = res.data.header.totalCount;
            }
            return rtnData;
          },
        });
      },
      /**
       * @description 그리드 데이터 초기화
       */
      refreshgGrid() {
        this.dataGrid.dataSource = [];
      },
      setPartitionVisible(data) {
        if (data === 'DEPT') {
          this.visible = true;
        } else {
          this.visible = false;
        }
        this.$refs.pickupInfoGrid.getInstance.refresh();
      },
    },
    async created() {
      this.createdData();
    },
    async mounted() {
      // await this.mountedData();
    },
  };
</script>
