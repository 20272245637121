<!--
  PACKAGE_NAME : src\pages\report\bi\index.vue
  FILE_NAME : index.vue
  AUTHOR : kwmoon
  DATE : 2024-06-21
  DESCRIPTION : BI 보고서 화면
-->
<template>
  <div class="page-sub-box locker_setting_list sub_new_style01 sub_ui_box1">
    <!-- (좌) BI보고서목록, BI보고서권한유저 | (우) 스프레드시트  -->
    <div class="flex justify-between gap-2">
      <div id="sideBar" :class="biMenu.isShow ? 'w-1/5' : 'hidden'">
        <!-- 버튼[보고서추가, 그룹추가] -->
        <div class="mt-5 mb-1.5">
          <DxButton
            text="보고서"
            class="btn_XS default filled add1"
            type="button"
            :height="30"
            @click="handleToggleCommonModal('addBiReport', true)"
          />
          <DxButton
            text="그룹 추가"
            class="btn_XS white light_filled"
            :height="30"
            @click="handleToggleCommonModal('addBiGroupMenu', true)"
          />
        </div>
        <!-- BI 보고서 목록 -->
        <DxTreeList
          id="bi-report-tree"
          ref="biMenuTreeList"
          key-expr="nodeId"
          parent-id-expr="parentId"
          display-expr="menuNm"
          :root-value="-1"
          :data-source="biMenu.list"
          :show-borders="true"
          :show-column-lines="false"
          :column-auto-width="true"
          :show-column-headers="true"
          :columns="biMenu.columns"
          :auto-expand-all="true"
          height="calc(100vh - 220px)"
          width="100%"
          :selectedRowKeys="selectedRowKeys"
          @selection-changed="handleChangeSelectBiMenuTreeList"
          @cell-click="handleClickBiMenuTreeList"
        >
          <DxSorting mode="none" />
          <DxSelection mode="single" />
        </DxTreeList>
        <!-- more 아이콘 클릭 시 뜨는 팝업  -->
        <DxContextMenu
          :ref="biMenu.contextRef"
          :data-source="biMenu.contexts"
          showEvent="dxclick"
          target="#bi-report-tree .more_icon"
          @item-click="clickContextItem"
        />
        <!-- //BI 보고서 목록 -->
      </div>
      <div id="spreadJS" class="mt-5" :class="biMenu.isShow ? 'w-4/5' : 'w-full'">
        <template v-if="spreadJS.instance !== null">
          <component
            v-show="spreadJS.isShow"
            :is="spreadJS.componentNm"
            :styleInfo="spreadJS.styles"
            :spreadOptions="spreadJS.options"
            :config="spreadJS.config"
            @designerInitialized="designerInitialized"
          >
            <gc-spread-sheets ref="spreadContainer" />
          </component>
          <div v-show="spreadJS.isShow === false" class="p-10">
            <p><b>BI보고서 추가</b> 또는 <b>메뉴를 선택</b>해주시기 바랍니다.</p>
          </div>
        </template>
      </div>
    </div>
    <!-- //(좌) BI보고서목록, BI보고서권한유저 | (우) 스프레드시트  -->

    <!-- 팝업 리스트 -->
    <!--데이터 시트 추가-->
    <CustomDxPopup
      :isOpen="spreadJS.popup.add.show"
      :option="spreadJS.popup.add.option"
      @saveModal="applyDataSheet('add')"
      @closeModal="handleToggleCommonModal('addDataSheet', false)"
    >
      <template #content>
        <tabs ref="tabs" @selectedIndex="changedAddModalTab" :showNav="false">
          <tab :title="item.name" :key="item.id" v-for="item in spreadJS.popup.add.tabs">
            <div class="pt-2">
              <!-- 탭1: "보고서 데이터 추가" -->
              <wizardDataTable v-if="item.id === wizardReport.tabNm" :ref="spreadJS.popup.add.ref.wizard" />
              <!-- 탭2: "DB 데이터 추가" -->
              <dbDataTable v-if="item.id === dbData.tabNm" :ref="spreadJS.popup.add.ref.db" />
              <!-- 탭3: "URL 데이터" -->
              <UrlDateTable v-if="item.id === urlData.tabNm" :ref="spreadJS.popup.add.ref.url" />
            </div>
          </tab>
        </tabs>
      </template>
    </CustomDxPopup>
    <!--//데이터 시트 추가-->

    <!-- 보고서/그룹 추가 및 수정 팝업 -->
    <component
      :is="biMenu.popup.componentNm"
      :isOpen="biMenu.popup.show"
      :option="biMenu.popup.options"
      @saveModal="proccessBiMenuPopup"
      @closeModal="handleToggleCommonModal('addBiReport', false)"
    >
      <template #content>
        <table class="table_form line-bin">
          <colgroup>
            <col style="width: 90px" />
            <col style="width: auto" />
          </colgroup>
          <tbody>
            <!-- 그룹 선택은 "보고서 추가" 일 시 에만 사용 -->
            <tr v-if="isAddReportPopup">
              <th class="th-bold">그룹선택*</th>
              <td>
                <DxSelectBox
                  placeholder="그룹 선택"
                  :items="biMenu.categories"
                  display-expr="name"
                  v-model="biMenu.popup.selectedGroup"
                  value-expr="id"
                  width="200px"
                  :styling-mode="stylingMode"
                  :height="30"
                >
                  <DxValidator ref="validGroupNm">
                    <DxRequiredRule message="데이터를 선택해주세요." />
                  </DxValidator>
                </DxSelectBox>
              </td>
            </tr>
            <!-- 항상 보이는 영역이며, 보고서 추가: "보고서명" | 그룹 추가: "그룹명" -->
            <tr>
              <th class="th-bold">{{ biMenu.popup.menuLabel }}*</th>
              <td>
                <DxTextBox width="100%" :placeholder="biMenu.popup.menuLabel" :styling-mode="stylingMode" v-model="biMenu.popup.menuNm">
                  <DxValidator ref="validMenuNm">
                    <DxRequiredRule message="데이터를 입력해주세요." />
                  </DxValidator>
                </DxTextBox>
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </component>
    <!-- //보고서/그룹 추가 및 수정 팝업 -->

    <!--	보고서 전달 유저	-->
    <DxPopup
      :show-title="true"
      :title="modal.initData?.title || null"
      :min-width="modal.initData?.width || null"
      :width="modal.initData?.width || null"
      :min-height="modal.initData?.height || null"
      :height="modal.initData?.height || null"
      :drag-enabled="true"
      :resize-enabled="false"
      :show-close-button="true"
      v-model="modal.isOpened"
      :visible="modal.isOpened"
      :isOpen="modal.isOpened"
      @hiding="handleToggleModal(false)"
    >
      <template #content>
        <component :is="modal.currentComponent" :modalData="modal.sendData" v-model="modal.contentData" />
      </template>

      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="modal?.initData?.buttons?.save !== undefined"
        :options="{
          elementAttr: { class: 'default filled txt_S medium' },
          text: modal?.initData?.buttons?.save?.text || '',
          useSubmitBehavior: true,
          validationGroup: 'modalValidationGroup',
          width: '120',
          height: '40',
          onClick: e => handleClickShareReport(e, modal.sendData),
        }"
      />

      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="!!modal?.initData?.buttons?.cancel"
        :options="{
          elementAttr: { class: 'white filled txt_S medium' },
          text: modal?.initData?.buttons?.cancel?.text || '',
          width: '120',
          height: '40',
          onClick: () => handleToggleModal(false),
        }"
      />
    </DxPopup>

    <!-- 데이터 시트 기간 변경 모달 -->
    <CustomDxPopup
      :isOpen="updateDateModal.isOpened"
      :option="updateDateModal.option"
      @saveModal="handleApplyUpdateDateModal"
      @closeModal="handleCloseUpdateDateModal"
    >
      <template #content>
        <div class="text-center pt-2">
          <DateRangeBox ref="updateRangeDate" :startDt="updateDateModal.startDt" :endDt="updateDateModal.endDt" />
        </div>
      </template>
    </CustomDxPopup>
  </div>
</template>

<script>
  import DxContextMenu from 'devextreme-vue/context-menu';
  import DxTreeView from 'devextreme-vue/tree-view';
  import { DxDateBox } from 'devextreme-vue/date-box';
  import { getPastFromToday, getResData, isEmpty } from '@/plugins/common-lib';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { DxRequiredRule, DxValidator } from 'devextreme-vue/validator';
  import { DxTextBox } from 'devextreme-vue/text-box';
  import { DxButton } from 'devextreme-vue/button';
  import ModalShareMember from '@/pages/report/bi/modal-share-member.vue';
  import ModalSelectTemplate from '@/pages/report/bi/modal-select-template.vue';
  import CustomDxPopup from '@/components/devextreme/esp-dx-modal-popup.vue';
  import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
  import { isSuccess } from '@/plugins/common-lib';
  import { DxColumn, DxSearchPanel, DxSelection, DxTreeList } from 'devextreme-vue/tree-list';
  import { DxFilterRow, DxPaging, DxScrolling, DxSorting } from 'devextreme-vue/data-grid';
  import DxSwitch from 'devextreme-vue/switch';
  import DxDropDownBox from 'devextreme-vue/drop-down-box';
  import Tabs from '@/components/common/tabs.vue';
  import Tab from '@/components/common/tab.vue';
  import SearchBox from '@/components/report/search-box.vue';
  import wizardDataTable from '@/pages/report/bi/wizard-data-table.vue';
  import dbDataTable from '@/pages/report/bi/db-data-table.vue';
  import UrlDateTable from '@/pages/report/bi/url-date-table.vue';
  import { v4 as uuidV4 } from 'uuid';
  import moment from 'moment/moment';
  import DateRangeBox from '@/components/devextreme/esp-dx-date-range-box.vue';
  import { EventBus } from '@/event-bus';

  let vm = null;
  export default {
    components: {
      DateRangeBox,
      UrlDateTable,
      SearchBox,
      DxSearchPanel,
      DxDropDownBox,
      DxSwitch,
      DxColumn,
      DxPaging,
      DxScrolling,
      DxFilterRow,
      DxSorting,
      DxSelection,
      DxTreeList,
      DxToolbarItem,
      DxButton,
      DxTextBox,
      DxRequiredRule,
      DxValidator,
      DxTreeView,
      DxDateBox,
      DxSelectBox,
      DxPopup,
      CustomDxPopup,
      DxContextMenu,
      ModalShareMember,
      ModalSelectTemplate,
      Tabs,
      Tab,
      dbDataTable,
      wizardDataTable,
    },
    props: {},
    watch: {},
    data() {
      return {
        //Common
        stylingMode: 'outlined', //[outlined, filled, underlined]

        //BI SpreadJS 부분
        spreadJS: {
          instance: null,
          containerClass: 'w-9/12',
          isShow: false, //첫 진입 시 false | 목록 추가 시 true (새로운 보고서 추가 시 새로운 메뉴가 포커싱)
          componentNm: 'gc-spread-sheets-designer', //스프레드 시트 컴포넌트 명칭
          //Designer 초기화 시
          workbook: null, //생성된 워크 북 객체
          sheet: null, // 현재 사용하느 시트
          designer: null, // 디자이너 객체
          excelIO: null, // 엑셀 IO 객체
          //설정
          styles: { height: '83vh', width: '100%' },
          options: {
            sheetCount: 1,
          },
          config: null, //디자이너 컴포넌트 Config 정보
          isLoading: false, //로딩 중 다른 작업 방지
          //관련 팝업
          popup: {
            type: 'add', // 'add', 'update'
            add: {
              show: false,
              option: {
                title: '데이터 추가',
                width: '95%',
                height: '90%',
                saveBtnTxt: '적용',
              },
              tabIndex: 0,
              selectedTabId: 'wizard-report',
              tabs: [
                { id: 'wizard-report', name: '보고서 데이터' },
                { id: 'db-data', name: 'DB 데이터' },
                // { id: 'url-data', name: 'URL 데이터' },
              ],
              ref: {
                wizard: 'addWizardRef',
                db: 'addDbRef',
                url: 'addUrlRef',
              },
            },
            update: {
              ref: {
                wizard: 'updateWizardRef',
                db: 'updateDbRef',
                url: 'updateUrlRef',
              },
              show: false,
              type: null, //wizard-report, db-data, url-data
              option: {
                title: '데이터 조건 변경',
                width: '95%',
                height: '85%',
                saveBtnTxt: '적용',
              },
            },
          },
        },
        // 시트 데이터 테이블 (wizard-report, db-data, url-data)
        wizardReport: { tabNm: 'wizard-report' },
        dbData: { tabNm: 'db-data' },
        urlData: { tabNm: 'url-data' },
        // BI보고서 목록
        biGroupCategoryNm: '', //카테고리변경시 사용되는 명칭 변수
        preBiGroupCategoryNm: '', //카테고리변경시 기존 명칭
        status: {
          openConditionModal: false,
          openBiTableSheetModal: false,
        },
        openValidationGroup: '',
        // BI보고서 관련
        currentBiItem: null, //BI메뉴 선택 정보
        menuId: 0,
        menuNm: '',
        reportInfo: undefined,
        reportColumnList: [],
        pageLoaded: false,
        selectedRowKeys: [],
        previousSelectedKeys: [],
        //BI보고서 메뉴 관련 변수
        biMenu: {
          isShow: true, //사이드 메뉴 활성화 여부
          categories: [], // 카테고리
          list: [], // 카테고리 + 보고서 목록
          columns: [
            { dataField: 'menuNm', caption: 'BI보고서 목록', width: '80%' },
            {
              dataField: '',
              caption: '',
              width: '20%',
              cellTemplate: (container, options) => {
                if (options.data.menuDepth < 3) return;
                let div = document.createElement('div');
                div.innerHTML = `<pre> <button class="more_icon btn-icon more" style="float:right;" /></pre>`;
                container.append(div);
              },
            },
          ],
          selectedItem: null,
          contextRef: 'biMenuContext',
          contexts: [
            { id: 'delete-group', text: '그룹 삭제' },
            { id: 'edit-group-name', text: '그룹명 수정' },
            { id: 'add-report', text: '보고서 추가' },
            { id: 'copy-report', text: '보고서 복사' },
            { id: 'delete-report', text: '보고서 삭제' },
            { id: 'share-report', text: '보고서 전달' },
            { id: 'edit-report-name', text: '보고서 수정' },
          ],
          popup: {
            key: 0, // 모달 초기화를 위해 사용
            componentNm: null,
            show: false,
            type: 'add-report', // add-group, add-report, update-group, update-report
            groupNm: '',
            menuNm: '',
            menuLabel: '',
            selectedGroup: null,
            options: {
              title: '보고서 추가',
              width: '500',
              height: '250',
              minWidth: null,
              minHeight: null,
              useSaveBtn: true,
              useCancelBtn: true,
            },
          },
        },
        modalPopup: {
          DxBipopupTitle: '',
          textInputNm: '',
          GroupCategoryType: '',
          isModalOpen: false,
          options: {
            width: '300',
            height: '200',
            minWidth: null,
            minHeight: null,
            saveBtnVisible: false,
            cancelBtnVisible: true,
          },
        },
        modal: {
          isOpened: false,
          currentComponent: null,
          initData: {},
          contentData: null,
        },
        // (시트 공통) 날짜 변경 모달
        updateDateModal: {
          isOpened: false,
          option: {
            title: '데이터 시트 날짜 변경',
            width: '400px',
            height: '180px',
            saveBtnTxt: '적용',
            cancelBtnTxt: '취소',
          },
          startDt: getPastFromToday(0, 'days'),
          endDt: getPastFromToday(0, 'days'),
        },
        printInfo: {
          rowStart: -1,
          rowEnd: -1,
          columnStart: -1,
          columnEnd: 5,
          repeatRowStart: 0,
          repeatRowEnd: 1,
          repeatColumnStart: -1,
          repeatColumnEnd: -1,
          showBorder: false,
          showGridLine: false,
          blackAndWhite: false,
          showRowHeader: 1,
          showColumnHeader: 1,
          headerLeft: '',
          headerLeftImage: '',
          headerCenter: '',
          headerCenterImage: '',
          headerRight: '',
          footerLeft: '',
          headerRightImage: '',
          footerLeftImage: '',
          footerCenter: '',
          footerCenterImage: '',
          footerRight: '',
          footerRightImage: '',
        },
      };
    },
    computed: {
      contextMenu() {
        return this.$refs[this.biMenu.contextRef].instance;
      },
      /**
       * [보고서 추가] 또는 [그룹 추가] 버튼 클릭 시
       * "그룹 선택" selectbox 보이기 여부를 체크하기 위한 함수
       * @returns {boolean}
       */
      isAddReportPopup() {
        return this.biMenu.popup.type.indexOf('report') > -1;
      },
      //"데이터 시트 추가" 시 "보고서 데이터" 컴포넌트
      addWizardDataTable() {
        return this.$refs[this.spreadJS.popup.add.ref.wizard][0];
      },
      updateWizardDataTable() {
        return this.$refs[this.spreadJS.popup.update.ref.wizard];
      },
      /** 데이터 시트 추가 팝업 내  */
      biDataComponent() {
        return this.$refs[this.spreadJS.popup.add.ref.db][0];
      },
    },

    methods: {
      async fetchShareBiReport(selectedRowsData) {
        let userList = selectedRowsData.map(item => item.loginNm);
        const { id: reportId, menuNm } = this.biMenu.selectedItem;

        const strUsers = userList.join(', ');
        let msg = `<pre><span style="font-weight: bold;text-align: center;font-size:15px;">[${menuNm}]</span> 보고서 전달 \n`;
        let userMsg = `[${strUsers}] 님에게`;
        if (selectedRowsData.length > 1) userMsg = `[${userList[0]}]님 외 ${userList.length - 1} 명에게`;
        msg += `${userMsg} 보고서를 전달하시겠습니까?</pre>`;

        if (await this.$_Confirm(msg)) {
          const memberList = selectedRowsData.map(row => row.loginId);
          const res = await this.CALL_REPORT_API({
            actionName: 'BI_REPORT_SHARE',
            data: {
              memberList: memberList,
              id: reportId,
              name: menuNm,
            },
            loading: true,
          });

          const toastMsgCode = isSuccess(res) ? 'CMN_SUC_SAVE' : 'CMN_ERROR';
          this.$_Toast(this.$_lang(toastMsgCode));
        }
      },
      /** 공유하기 모달 저장 버튼 클릭 시 실행 */
      async handleClickShareReport(e, sendData) {
        //해당 모달 컴포넌트에서 데이터 저장
        const res = await new Promise((resolve, reject) => {
          this.$_eventbus.$emit(`${sendData.modal}:onSaveData`, e, resolve, reject);
        });

        try {
          if (res.status === 200) {
            if (sendData.modal === 'ModalShareMember') {
              await this.fetchShareBiReport(res.selectedRowsData);
            } else if (sendData.modal === 'ModalSelectTemplate') {
              await this.setBiTemplateReport(res.selectedRowsData);
              this.handleToggleModal(false);
            }
          }
        } catch (e) {
          this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      /** 템플릿으로 불러온 보고서를 현재 보고서로 설정 */
      async setBiTemplateReport(selectedRowsData) {
        const json = JSON.parse(selectedRowsData.content);
        this.spreadJS.workbook.fromJSON(json);

        const workbook = this.spreadJS.workbook;
        const sheetCount = workbook.getSheetCount();

        // 시트별 최소 작업공간 설정
        for (let i = 0; i < sheetCount; i++) {
          const sheet = workbook.getSheet(i);

          // 현재 설정된 행/열 범위 내에서 검사
          const currentRowCount = sheet.getRowCount();
          const currentColCount = sheet.getColumnCount();

          // 최소 200행, 50열을 유지하며, 그 이상을 넘어갈 경우 20씩 늘림
          const maxRow = currentRowCount > 180 ? currentRowCount + 20 : 200;
          const maxCol = currentColCount > 30 ? currentColCount + 20 : 50;

          sheet.setRowCount(maxRow);
          sheet.setColumnCount(maxCol);
        }
      },
      handleToggleModal(data) {
        this.modal.isOpened = data;
        if (!data) {
          this.modal.currentComponent = null;
          this.modal.initData = {};
        }
      },
      setPrintInfo() {
        let printInfo = this.sheet.printInfo();

        // custom printInfo for default output
        printInfo.showBorder(false);
        printInfo.showGridLine(false);
        printInfo.blackAndWhite(false);
        printInfo.showColumnHeader(this.spreadJS.instance.Spread.Sheets.Print.PrintVisibilityType.hide);
        printInfo.showRowHeader(this.spreadJS.instance.Spread.Sheets.Print.PrintVisibilityType.hide);
        printInfo.orientation(this.spreadJS.instance.Spread.Sheets.Print.PrintPageOrientation.landscape);
        printInfo.paperSize(
          new this.spreadJS.instance.Spread.Sheets.Print.PaperSize(this.spreadJS.instance.Spread.Sheets.Print.PaperKind.a4),
        );
        printInfo.fitPagesTall(-1);
        printInfo.fitPagesWide(1);
      },
      saveToPDF() {
        this.setPrintInfo();
        this.spreadJS.workbook.savePDF(
          function (blob) {
            saveAs(blob, 'download.pdf');
          },
          console.log,
          {
            title: 'Quotation',
            author: 'GrapeCity',
            subject: 'Quotation',
            keywords: 'Quotation',
            creator: 'GrapeCity',
          },
        );
      },
      /** SpreadJs Export 기능 */
      exportXlsx() {
        const options = this.getXlsxOptions();
        const date = getPastFromToday(0, 'days', 'YYYYMMDDHHmmss');
        const fileNm = `${this.biMenu.selectedItem.menuNm}_${date}.xlsx`;
        this.spreadJS.workbook.export(
          function (blob) {
            saveAs(blob, fileNm);
          },
          function (e) {
            console.log(e);
          },
          options,
        );
      },
      /** xlsx 다운로드 옵션 ( TODO: 타입 별 코드 분리 필요 )*/
      getXlsxOptions() {
        const saveOptions = {
          includeBindingSource: true,
          includeStyles: true,
          includeFormulas: true,
          saveAsView: false,
          rowHeadersAsFrozenColumns: false,
          columnHeadersAsFrozenRows: false,
          includeAutoMergedCells: false,
          includeCalcModelCache: false,
          saveR1C1Formula: false,
          includeUnusedNames: true,
          includeEmptyRegionCells: true,
          encoding: 'UTF-8',
          rowDelimiter: '\r\n',
          columnDelimiter: ',',
          sheetIndex: 0,
          row: 0,
          column: 0,
          rowCount: 200,
          columnCount: 20,
        };

        const xlsxConfig = [
          { propName: 'includeBindingSource', type: 'boolean', default: false },
          { propName: 'includeStyles', type: 'boolean', default: true },
          { propName: 'includeFormulas', type: 'boolean', default: true },
          { propName: 'saveAsView', type: 'boolean', default: false },
          { propName: 'rowHeadersAsFrozenColumns', type: 'boolean', default: false },
          { propName: 'columnHeadersAsFrozenRows', type: 'boolean', default: false },
          { propName: 'includeAutoMergedCells', type: 'boolean', default: false },
          { propName: 'includeUnusedNames', type: 'boolean', default: true },
          { propName: 'includeEmptyRegionCells', type: 'boolean', default: true },
        ];

        let options = {};
        xlsxConfig.forEach(prop => {
          let v = saveOptions[prop.propName];
          if (prop.type === 'number') {
            v = +v;
          }
          options[prop.propName] = v;
        });

        return options;
      },
      /** spreadJS 커스텀 커맨드: 메뉴 열기/닫기 (좌측 보고서 목록 영역 토글 함수) */
      handleClickToggleSideBar() {
        this.biMenu.isShow = !this.biMenu.isShow;
      },
      /** [BI 보고서 또는 그룹] 추가 및 수정 : context 아이콘 관련 로직 처리하는 함수 */
      async proccessBiMenuPopup() {
        const popupType = this.biMenu.popup.type;

        let actionNm = '';
        let params = {};
        const menuNm = this.biMenu.popup.menuNm;
        const groupId = this.biMenu.popup.selectedGroup;
        if ('add-report' === popupType) {
          if (isEmpty(groupId)) return this.$_Msg('그룹을 선택해야합니다.');
          if (menuNm === '') return this.$_Msg('추가하려는 보고서명칭을 입력해야합니다.');
          actionNm = 'BI_REPORT_INSERT';
          params = {
            name: menuNm,
            categoryId: groupId,
            content: JSON.stringify(this.spreadJS.workbook.toJSON()),
          };
        } else if ('edit-report-name' === popupType) {
          actionNm = 'BI_REPORT_INFO_MODIFY';
          params = {
            id: this.biMenu.selectedItem.id,
            categoryId: this.biMenu.popup.selectedGroup,
            menuNm: this.biMenu.popup.menuNm,
          };
        } else if ('add-group' === popupType) {
          if (this.$refs.validMenuNm.instance.validate().isValid === false) return;
          actionNm = 'BI_CATEGORY_INSERT';
          params = { categoryNm: menuNm };
        } else if ('edit-group-name' === popupType) {
          if (menuNm === '') return this.$_Msg('변경하려는 그룹 명칭을 입력해야합니다.');
          if (this.biMenu.selectedItem.menuNm === menuNm) return this.$_Msg('변경하려는 그룹 명칭이 기존과 동일합니다.');
          const categoryId = this.biMenu.selectedItem.id;
          actionNm = 'BI_CATEGORY_UPDATE';
          params = { id: categoryId, categoryNm: menuNm };
        } else if ('copy-report' === popupType) {
          const selectedMenuId = this.biMenu.selectedItem.id;
          const groupId = this.biMenu.popup.selectedGroup;
          actionNm = 'BI_REPORT_COPY';
          params = { id: selectedMenuId, categoryId: groupId, name: menuNm };
        } else if ('share-report' === popupType) {
          this.handleToggleCommonModal('shareUserModal', true);
        } else {
          console.warn('존재하지 않는 타입: ', popupType);
          return false;
        }

        const res = await this.CALL_REPORT_API({
          actionName: actionNm,
          data: params,
          loading: true,
        });

        if (isSuccess(res)) {
          await this.setBiGroupMenu();
          this.biMenu.popup.show = false;
          this.biMenu.popup.menuNm = null;
          this.biMenu.popup.groupNm = null;

          if ('add-report' === popupType) {
            const data = getResData(res)[0];

            this.biMenu.selectedItem = data;
            this.$refs.biMenuTreeList.instance.selectRows([data.id], true);
          }

          return this.$_Toast(this.$_lang('CMN_SUC_SAVE'));
        }
        // 오류 발생 시
        this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
      },
      /** BI 보고서 목록 선택, SpreadJS 워크북 데이터 셋팅 */
      async handleChangeSelectBiMenuTreeList(e) {
        if (this.spreadJS.isLoading) {
          const message = '현재 불러오기 이벤트가 실행중이라 다른 메뉴를 선택 할 수 없습니다.';
          this.selectedRowKeys = [...this.previousSelectedKeys];
          return this.$_Toast(message);
        }

        let item = e.selectedRowsData[0];

        // 선택된 보고서 항목 없을 경우 초기화
        if (isEmpty(item)) {
          this.resetBiReport();
        }
        // 카테고리가 아닌 경우
        else if (isEmpty(item.isCategory)) {
          this.previousSelectedKeys = e.selectedRowKeys;
          this.spreadJS.isShow = true;
          await this.$nextTick();

          await this.resetSheet();
          this.updateCommandMap(this.spreadJS.config, 'common');

          const res = await this.CALL_REPORT_API({
            actionName: 'BI_REPORT_DATA_SELECT',
            path: `/${item.id}`,
            loading: true,
          });
          if (isSuccess(res)) {
            this.biMenu.selectedItem = getResData(res)[0];
            this.biMenu.selectedItem.menuNm = item.name;
            this.biMenu.selectedItem.parentId = item.categoryId;

            const { content, blobContent } = this.biMenu.selectedItem;
            this.updateCommandMap(this.spreadJS.config, 'common');
            if (blobContent === null) {
              this.spreadJS.isLoading = true;
              this.spreadJS.workbook.fromJSON(content);
              this.spreadJS.isLoading = false;
            } else {
              const byteCharacters = atob(blobContent);
              const uint8Array = new Uint8Array(byteCharacters.length);
              for (let i = 0; i < byteCharacters.length; i++) {
                uint8Array[i] = byteCharacters.charCodeAt(i);
              }
              const blob = new Blob([uint8Array], { type: 'application/zip' });
              this.spreadJS.isLoading = true;
              this.spreadJS.workbook.open(
                blob,
                e => {
                  vm.spreadJS.isLoading = false;
                },
                e => {
                  vm.spreadJS.isLoading = false;
                  console.log(e); // error callback
                },
                {
                  lazyLoad: true,
                },
              );
            }

            this.spreadJS.workbook.bind(this.spreadJS.instance.Spread.Sheets.Events.PivotTableChanged, function (evt, args) {
              try {
                vm.sheet = this.spreadJS.workbook.getActiveSheet();
                var pt = vm.sheet.pivotTables.get(args.pivotTableName);
                if (pt) {
                  var sheetRow = vm.sheet.getRowCount();
                  var sheetCol = vm.sheet.getColumnCount();
                  var ptRange = pt.getRange();
                  if (ptRange.content) {
                    var ptRowCount = ptRange.content.row + ptRange.content.rowCount;
                    vm.sheet.setRowCount(Math.max(ptRowCount, sheetRow));
                    var ptColCount = ptRange.content.col + ptRange.content.colCount;
                    vm.sheet.setColumnCount(Math.max(ptColCount, sheetCol));
                  }
                }
              } catch (e) {}
            });
          }
        }
      },
      /** @description BI보고서 목록에 대한 "컨텍스트 메뉴"를 동적으로 처리 후 출력 => 메뉴 선택이 변경되는 경우 */
      handleClickBiMenuTreeList(e) {
        if (e.column === undefined || e.column.dataField === 'menuNm') return;
        // ContextMenu Visible 처리
        const item = e.row.data;
        this.biMenu.selectedItem = item; //Context Event 처리 시 사용하기 위해 저장
        const isDefaultCategory = item.isDefaultCategory;
        const isCategory = item.isCategory;

        // 보여줘야할 옵션만 배열에 담기
        let checkIds = [];
        if (isDefaultCategory) {
          // 기본 그룹: "보고서 추가"
          checkIds = ['add-report'];
        } else if (isCategory) {
          // 커스텀 그룹: "그룹 삭제", "그룹명 수정", "보고서 추가"
          checkIds = ['delete-group', 'edit-group-name', 'add-report'];
        } else {
          // BI 보고서 메뉴: "보고서명 수정", "보고서 삭제", "보고서 복사", "보고서 전달"
          // TODO: 보고서 공유에서 보고서 전달로 메뉴명이 바꼈지만, share로 되어있는 메서드는 그대로 사용하고 있음.
          checkIds = ['edit-report-name', 'delete-report', 'copy-report', 'share-report'];
        }

        // 각 context 별 visible 처리
        this.biMenu.contexts.forEach((v, i) => {
          this.contextMenu.option(`items[${i}].visible`, checkIds.includes(v.id));
        });
      },
      /**
       * BI 메뉴 아이템 우측 (...) 버튼 클릭 시
       * 생기는 팝오버 타입에 따라 컨텍스트 메뉴를 동적으로 조절하는 함수.
       * modal 저장 시 proccessBiMenuPopup() 함수가 type에 따라 분기되어 실행됩니다.
       * @param {object} e - 이벤트 객체로, 클릭된 컨텍스트 메뉴 항목에 대한 정보를 포함합니다.
       * @returns {void}
       */
      async clickContextItem(e) {
        const actionType = e.itemData.id;
        const selectedItem = this.biMenu.selectedItem;
        if ('edit-group-name' === actionType) {
          this.biMenu.popup.menuNm = selectedItem.menuNm;
          this.handleToggleCommonModal('editGroupName', true);
        } else if ('delete-group' === actionType) {
          if (await this.$_Confirm('해당 그룹을 삭제하시겠습니까? <br>(삭제시 그룹에 포함된 모든 리포트가 삭제됩니다.) ')) {
            const categoryId = selectedItem.id;
            const res = await this.CALL_REPORT_API({
              actionName: 'BI_CATEGORY_DELETE',
              path: `/${categoryId}`,
              data: {},
              loading: true,
            });
            if (isSuccess(res)) {
              await this.setBiGroupMenu();
              return this.$_Toast(this.$_lang('CMN_SUC_DELETE'));
            }
            this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          }
        } else if ('copy-report' === actionType) {
          this.handleToggleCommonModal('copyBiReport', true);
          this.biMenu.popup.menuNm = `${selectedItem.menuNm}_복사본`;
          this.biMenu.popup.selectedGroup = selectedItem.parentId;
        } else if ('delete-report' === actionType) {
          //보고서 삭제
          if (await this.$_Confirm(`보고서 [${selectedItem.name}] 를 삭제하시겠습니까?`)) {
            const res = await this.CALL_REPORT_API({
              actionName: 'BI_REPORT_DELETE',
              path: `/${selectedItem.id}`,
              data: {},
              loading: true,
            });

            if (isSuccess(res)) {
              await this.setBiGroupMenu();
              this.$_Toast(this.$_lang('CMN_SUC_DELETE'));
              return;
            }
            this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          }
        } else if ('share-report' === actionType) {
          this.handleToggleCommonModal('shareUserModal', true);
        } else if ('add-report' === actionType) {
          this.handleToggleCommonModal('addBiReport', true);
          this.biMenu.popup.selectedGroup = selectedItem.id;
        } else if ('edit-report-name' === actionType) {
          console.log('edit-report-name', selectedItem);
          this.biMenu.popup.menuNm = selectedItem.menuNm;
          this.biMenu.popup.selectedGroup = selectedItem.categoryId;
          this.handleToggleCommonModal('editBiReportNm', true);
        }
      },
      /** 스프레드 시트의 모든 시트를 지우고 새 시트를 추가합니다.*/
      async resetSheet() {
        this.spreadJS.workbook.clearSheets();
        const newSheet = new this.spreadJS.instance.Spread.Sheets.Worksheet('NewSheet');
        this.spreadJS.workbook.addSheet(0, newSheet);
      },
      /** BI 보고서 목록 초기화 */
      resetBiReport() {
        if (this.spreadJS.workbook) {
          this.spreadJS.workbook.clearSheets();
        }
        this.spreadJS.isShow = false;
        this.spreadJS.isLoading = false;
        this.biMenu.selectedItem = null;
        this.biMenu.popup.menuNm = '';
        this.biMenu.popup.selectedGroup = null;

        this.selectedRowKeys = [];
        this.previousSelectedKeys = [];

        this.sheet = null;
      },
      openBiTableSheetModal(openOrClose) {
        this.status.openBiTableSheetModal = openOrClose;
      },
      openConditionModal(openOrClose) {
        this.status.openConditionModal = openOrClose;
      },
      /** 시트 헤더용 포맷 데이터 변환 <br />
       * TODO: 추후 네이밍 일치 필요 */
      transformHeaderData(param) {
        const {
          title,
          // 날짜
          startDt,
          endDt,
          // 시간
          startTime_H,
          startTime_M,
          endTime_H,
          endTime_M,
          // 보고서 유형
          reportType,
          report_type,
        } = param;

        const transObj = {
          title: title,
          startDt: startDt,
          endDt: endDt,
          sHH: startTime_H,
          sMM: startTime_M,
          eHH: endTime_H,
          eMM: endTime_M,
          reportType: reportType || report_type,
        };

        return { ...param, ...transObj };
      },
      /** 데이터 시트 헤더 정보를 그리는 함수 */
      drawDataSheetHeader(sheet, infoObj) {
        this.drawTitleAtDataSheetHeaer(sheet, infoObj.title);
        this.drawUpdateDataSheetHeader(sheet, infoObj);
      },
      /** 시트 보더 스타일 */
      createBlackThinBorderStyle() {
        return new this.spreadJS.instance.Spread.Sheets.LineBorder('black', this.spreadJS.instance.Spread.Sheets.LineStyle.thin);
      },
      /** 데이터 시트 헤더 타이틀 정보 입력 및 스타일 */
      drawTitleAtDataSheetHeaer(sheet, title) {
        this.drawValueAtSheet(sheet, 2, 1, title, () => {
          sheet.addSpan(2, 1, 3, 4);
          const range = sheet.getRange(2, 1, 3, 4);
          range.hAlign(this.spreadJS.instance.Spread.Sheets.HorizontalAlign.center);
          range.vAlign(this.spreadJS.instance.Spread.Sheets.VerticalAlign.center);
          range.font('bold 20px/30px Nanum Gothic');
          range.borderLeft(this.createBlackThinBorderStyle());
          range.borderTop(this.createBlackThinBorderStyle());
          range.borderRight(this.createBlackThinBorderStyle());
          range.borderBottom(this.createBlackThinBorderStyle());
        });
      },
      /** 데이터 시트 헤더 검색 조건 그리는 함수 (타이틀, 조회기간, 보고서유형, 조회일시) */
      drawUpdateDataSheetHeader(sheet, infoObj) {
        const { startDt, endDt, sHH, sMM, eHH, eMM, reportType } = infoObj;

        this.drawValueAtSheet(sheet, 2, 5, '조회 기간');
        const rangeTime = sHH === undefined ? '' : ` (${sHH}:${sMM} ~ ${eHH}:${eMM})`;
        this.drawValueAtSheet(sheet, 2, 6, `${startDt} ~ ${endDt}${rangeTime}`);

        this.drawValueAtSheet(sheet, 3, 5, '보고서 유형');
        this.drawValueAtSheet(sheet, 3, 6, this.getReportTypeName(reportType));

        this.drawValueAtSheet(sheet, 4, 5, '조회 일시');
        this.drawValueAtSheet(sheet, 4, 6, moment().format('YYYY-MM-DD HH:mm:ss'));
      },
      /** 보고서 유형 명칭 반환 함수 */
      getReportTypeName(type) {
        const findReportType = this.$_enums.report.reportType.values.find(item => item.value === type);
        if (findReportType) {
          return findReportType.label;
        }

        return '기간별';
      },
      /**
       * 현재 Active 중인 Sheet에 값 추가 및 스타일링 CallBack 실행
       * (값을 셋팅하는 셀에 적용할 스타일들을 "들여쓰기"로 구분해서 보여주기 위해 만듬)
       * @param sheet: 적용할 시트
       * @param row: 행 넘버 (0부터 시작)
       * @param col: 열 넘버 (0부터 시작)
       * @param value: Any
       * @param styleFn: Style CallBack
       */
      drawValueAtSheet(sheet, row, col, value, styleFn = () => {}) {
        sheet.setValue(row, col, value);
        styleFn();
      },
      /** BiMenu 모달 초기화 */
      initBiMenuModal(bool) {
        if (bool === false) {
          this.biMenu.popup.componentNm = null;
          this.biMenu.popup.menuNm = null;
          this.biMenu.popup.selectedGroup = null;
          return false;
        }
        this.biMenu.popup.componentNm = 'CustomDxPopup';
      },
      /**
       * 해당 페이지 내에서 사용하는 모달 타입 별 토글 처리
       * TODO: 분기 분리 필요
       * */
      handleToggleCommonModal(modalType, bool) {
        // context 모달
        if ('addBiGroupMenu' === modalType) {
          // 그룹 추가
          this.initBiMenuModal(bool);
          this.biMenu.popup.type = 'add-group';
          this.biMenu.popup.options.title = '그룹 추가';
          this.biMenu.popup.options.height = '200';
          this.biMenu.popup.menuLabel = '그룹명';
          this.biMenu.popup.show = bool;
        } else if ('editGroupName' === modalType) {
          // 그룹명 변경
          this.initBiMenuModal(bool);
          this.biMenu.popup.type = 'edit-group-name';
          this.biMenu.popup.menuLabel = '그룹명';
          this.biMenu.popup.options.title = '그룹명 변경';
          this.biMenu.popup.show = true;
        } else if ('addBiReport' === modalType) {
          // 보고서 추가
          this.initBiMenuModal(bool);
          this.biMenu.popup.type = 'add-report';
          this.biMenu.popup.options.title = '보고서 추가';
          this.biMenu.popup.options.height = '250';
          this.biMenu.popup.menuLabel = '보고서명';
          this.biMenu.popup.show = bool;
        } else if ('editBiReportNm' === modalType) {
          // BI보고서 목록 > context 메뉴 > 보고서명 변경
          this.initBiMenuModal(bool);
          this.biMenu.popup.type = 'edit-report-name';
          this.biMenu.popup.options.title = '보고서명 변경';
          this.biMenu.popup.options.height = '250';
          this.biMenu.popup.menuLabel = '보고서명';
          this.biMenu.popup.show = bool;
        } else if ('copyBiReport' === modalType) {
          // BI보고서 목록 > context 메뉴 > 보고서 복사
          this.initBiMenuModal(bool);
          this.biMenu.popup.type = 'copy-report';
          this.biMenu.popup.options.title = '보고서 복사';
          this.biMenu.popup.options.height = '250';
          this.biMenu.popup.menuLabel = '보고서명';
          this.biMenu.popup.show = bool;
        }
        // 보고서 검색 조건
        else if ('addDataSheet' === modalType) {
          // spreadJS > 보고서 검색 조건 > 데이터 시트 추가
          this.spreadJS.popup.type = 'add';
          this.spreadJS.popup.add.show = bool;
        } else if ('editDataSheet' === modalType) {
          // spreadJS > 보고서 검색 조건 > 데이터 시트 수정
          this.spreadJS.popup.type = 'update';
          this.spreadJS.popup.update.show = bool;
        }
        // 공유 모달
        else if ('shareUserModal' === modalType) {
          this.modal.currentComponent = 'ModalShareMember';
          this.modal.initData = {
            title: `전달 가능 계정`,
            width: 1200,
            height: 600,
            buttons: {
              save: { text: '확인' },
              cancel: { text: '취소' },
            },
          };
          this.modal.sendData = { modal: 'ModalShareMember' };
          this.handleToggleModal(true);
        }
        // 템플릿 선택 모달
        else if ('selectTemplateModal' === modalType) {
          this.modal.currentComponent = 'ModalSelectTemplate';
          this.modal.initData = {
            title: `템플릿 불러오기`,
            width: 900,
            height: 700,
            buttons: {
              save: { text: '확인' },
              cancel: { text: '취소' },
            },
          };
          this.modal.sendData = {
            modal: 'ModalSelectTemplate',
            spreadInstance: this.spreadJS.instance,
            spreadExcelIO: this.spreadJS.excelIO,
          };
          this.handleToggleModal(true);
        }
      },
      /**
       * "데이터 추가" 팝업 탭('보고서 데이터 추가', '시트 추가', 'URL 데이터')
       * 변경 시 해당 탭 ID 값 저장하는 함수
       * @param index: 탭 index(0, 1, 2)
       */
      changedAddModalTab(index) {
        const tabId = this.spreadJS.popup.add.tabs[index].id;
        this.spreadJS.popup.add.selectedTabId = tabId;
      },
      /**
       * "데이터 추가" 팝업에서 "적용" 버튼 클릭했을 시 동작하는 함수
       * "위자드 보고서", "시트 추가", "URL 데이터" 탭에 따라
       * 각각 다른 동작을 수행한다.
       * TODO: "URL 데이터" 탭은 추후 개발 예정
       */
      async applyDataSheet(type) {
        const { selectedTabId } = this.spreadJS.popup.add;
        // 데이터 시트 추가
        if (type === 'add') {
          if (this.wizardReport.tabNm === selectedTabId) {
            await this.applySheetByReportData('add');
          } else if (this.dbData.tabNm === selectedTabId) {
            await this.fetchCreateSheetByBiData('add');
          } else if (this.urlData.tabNm === selectedTabId) {
          }
        }
      },
      /** 데이터 시트 내 데이터 테이블명 조회 (없을 시 생성) */
      getDataTableName() {
        this.spreadJS.sheet = this.spreadJS.workbook.getActiveSheet();
        const tables = this.spreadJS.sheet.tables.all();
        if (tables.length === 0) {
          return `esp.${uuidV4().replace(/-/g, '')}`;
        }
        // 수정 시 기존 테이블 사용
        return tables.at(0).name();
      },
      /** workbook 내 시트 추가 */
      createNewSheet(workbook, sheetName) {
        workbook.addSheet(workbook.getSheetCount(), this.createWorkSheetOfSpreadInstance(sheetName));
        workbook.setActiveSheetIndex(workbook.getSheetCount() - 1);
        return workbook.getActiveSheet();
      },
      /** 새로운 시트명 내 특수문자 제거 및 prefix(N) */
      convertNewSheetName(name) {
        // 특수문자 제거
        let rename = name.replace(/[`~!@#$%^&*()|+\-=?;:'"<>\{\}\[\]\\\/ ]/gim, '');

        // 중복 시트명 있는지 체크하여 없으면 사용
        if (isEmpty(this.spreadJS.workbook.getSheetFromName(rename))) {
          return rename; // 존재하지 않을 시 해당 시트명 사용
        }

        // 같은 시트명 존재 시 숫자 붙여서 반환
        const sheetCount = this.spreadJS.workbook.getSheetCount();
        let resultName = `${rename}${sheetCount + 1}`;
        for (let i = 0; i < sheetCount; i++) {
          const newName = `${rename}${i + 1}`;
          if (isEmpty(this.spreadJS.workbook.getSheetFromName(newName))) {
            resultName = newName;
            break;
          }
        }

        return resultName;
      },
      /** "데이터 추가" 팝업에서 "위자드 보고서" 셋팅된 조건 유효성 체크 후 새로운 데이터 시트를 추가하는 함수 */
      async applySheetByReportData(type) {
        //유효성 검사
        const searchBoxRef = type === 'add' ? this.addWizardDataTable : this.updateWizardDataTable;
        if (searchBoxRef === undefined) {
          const errMessage = '메뉴 선택 후 검색 조건을 설정해주세요.';
          this.$_Msg(errMessage);
          throw new Error(errMessage);
        }

        const errMessage = searchBoxRef.validateMessageByParams();
        if (errMessage.trim()) {
          this.$_Msg(errMessage);
          throw new Error(errMessage);
        }

        //데이터 시트 생성
        const { reportId, menuNm, columns, menuId } = searchBoxRef.getReportInfo(); //보고서 ID, 보고서 명
        if (type === 'add') {
          this.createNewSheet(this.spreadJS.workbook, this.convertNewSheetName(menuNm));
        }

        //기초작업
        this.sheet = this.spreadJS.workbook.getActiveSheet(); //현재 시트 담기

        // 1.상단 보고서 정보 그리기
        const searchParams = searchBoxRef.getReportSearchParams(); //셋팅된 조건 가져오기
        this.drawDataSheetHeader(this.sheet, this.transformHeaderData({ ...searchParams.search, title: menuNm }));

        // 2.컬럼 API 및 현재 활성화된 페이지 테이블 추가 셋팅
        const biId = this.biMenu.selectedItem.id;
        const biDataType = 'wizard';
        const dataTableName = this.getDataTableName();
        this.sheet.setColumnCount(columns.length + 10);
        if (type === 'add') {
          this.createDataTable(this.sheet, dataTableName, columns.length);
          this.sheet.frozenRowCount(7); //컬럼 생성 후 7번 row 틀 고정
        }

        // 3.데이터 파라미터 셋팅
        searchBoxRef.updateAllReportParam(0);
        const params = searchBoxRef.getReportSearchParams();
        params.menuNm = menuNm; // 보고서 명
        params.reportId = reportId; // 보고서 ID (XML 파일명))
        params.biId = biId; // BI ID
        params.biTableNm = dataTableName; // tableNm (uuid)
        params.biDataType = biDataType;
        params.paging = false; // 페이징 여부
        params.pagesize = 0; // 페이지 사이즈
        params.currentpage = 0; // 현재 페이지
        params.workSection = this.$_getCode('work_section')?.find(e => e.codeNm === '조회')?.codeValue; // 작업타입

        // 4. 보고서 조회 결과 데이터 가져온 후 바인딩
        const reportResult = await searchBoxRef.asyncGetReportDatas(params);
        const reportTable = this.sheet.tables.findByName(dataTableName);
        reportTable.bind(this.getTableColumnsByXmlColumns(columns), '', reportResult);
        if (type === 'update') this.sheet.resumePaint();

        //열려있던 "데이터 추가 or 수정" 팝업 닫기
        const modalNm = type === 'add' ? 'addDataSheet' : 'editDataSheet';
        this.handleToggleCommonModal(modalNm, false);
      },
      /** 새로운 워크 시트 생성 */
      createWorkSheetOfSpreadInstance(sheetNm) {
        return new this.spreadJS.instance.Spread.Sheets.Worksheet(sheetNm);
      },
      /** 공통 관리 데이터 쿼리를 조회하여 반환하는 API */
      async fetchXmlFileColumnList(params, loading = true) {
        const res = await this.CALL_REPORT_API({
          actionName: 'XML_FILE_COLUMN_LIST',
          data: { ...params },
          loading: loading,
        });

        if (isSuccess(res)) return getResData(res);
        return [];
      },
      /** 공통 관리 데이터 쿼리를 조회하여 반환하는 API */
      async fetchXmlFileResultList(params, loading = true) {
        const res = await this.CALL_REPORT_API({
          actionName: 'XML_FILE_RESULT_LIST',
          data: { data: params },
          loading: loading,
        });
        if (isSuccess(res)) return getResData(res);
        return [];
      },
      /** 데이터 시트 추가: BI 데이터 */
      async fetchCreateSheetByBiData() {
        const params = this.biDataComponent.getFormData();

        // 시트명 설정 및 생성
        const sheet = this.createNewSheet(this.spreadJS.workbook, this.convertNewSheetName(params.description));

        // 헤더 셋팅
        this.drawDataSheetHeader(sheet, this.transformHeaderData({ ...params, title: params.description }));

        // 컬럼 셋팀
        const columns = await this.fetchXmlFileColumnList(params);
        sheet.setColumnCount(columns.length + 10);

        // 테이블 데이터 생성
        const dataTableName = this.getDataTableName();
        const dataTable = this.createDataTable(sheet, dataTableName, columns.length);
        sheet.frozenRowCount(7); //컬럼 생성 후 7번 row 틀 고정

        // 데이터 불러오기
        const dataList = await this.fetchXmlFileResultList({
          ...params,
          // 이력을 저장하기 위한 추가 파라미터
          // (TODO: 중복 값은 메타데이터 정의 후 일치 필요)
          biId: this.biMenu.selectedItem.id,
          biDataType: params.reportType,
          biTableNm: dataTableName,
        });

        const tableColumns = this.getTableColumnsByXmlColumns(columns);
        dataTable.bind(tableColumns, '', dataList);
        this.handleToggleCommonModal('addDataSheet', false);
      },
      /**
       * updateCommandMap 구분 값이 init인 경우
       * config.commandMap 내 초기 객체 넣는 함수
       */
      initCommandMap(config) {
        config.commandMap = {
          toggleSideBar: {
            text: '메뉴\n열기/닫기',
            width: '200px',
            iconClass: 'bi-command-slide-menu',
            bigButton: 'true',
            commandName: 'toggleSideBar',
            execute: async () => {
              this.handleClickToggleSideBar();
            },
          },
          // 데이터
          addDataSheet: {
            text: '데이터\n시트 추가',
            width: '200px',
            iconClass: 'bi-add-sheet-icon',
            bigButton: 'true',
            commandName: 'addDataSheet',
            enableContext: '!disabled',
            execute: async () => {
              this.handleToggleCommonModal('addDataSheet', true);
            },
          },
          // 날짜 변경
          editDate: {
            text: '날짜\n수정',
            width: '200px',
            iconClass: 'bi-command-calendar-time',
            bigButton: 'true',
            commandName: 'editDate',
            enableContext: '!disabled',
            execute: () => {
              vm.updateDateModal.isOpened = true;
            },
          },
          // 데이터 저장
          saveToDB: {
            text: '보고서\n저장',
            width: '200px',
            iconClass: 'bi-command-save-data',
            bigButton: 'true',
            commandName: 'saveToDB',
            enableContext: '!disabled',
            execute: async () => {
              await this.spreadJS.workbook.save(
                blob => {
                  const reader = new FileReader();
                  reader.readAsDataURL(blob);
                  reader.onload = () => {
                    const base64data = reader.result;
                    vm.fetchBlobDataUpdate(base64data);
                  };
                },
                () => {},
                { includeBindingSource: true },
              );
            },
          },
          // 데이터 저장
          downloadXlsx: {
            text: '다운로드\n(xlsx)',
            width: '200px',
            iconClass: 'bi-command-download-xlsx',
            bigButton: 'true',
            commandName: 'downloadXlsx',
            enableContext: '!disabled',
            execute: async () => {
              this.exportXlsx();
            },
          },
          saveToPDF: {
            text: 'PDF 변환',
            width: '200px',
            iconClass: 'bi-command-pdf',
            bigButton: 'true',
            commandName: 'saveToPDF',
            execute: async () => {
              this.saveToPDF();
            },
          },
          // 템플릿 불러오기
          addBiTemplate: {
            text: '템플릿\n불러오기',
            width: '200px',
            iconClass: 'bi-edit-sheet-icon',
            bigButton: 'true',
            commandName: 'addBiTemplate',
            execute: async () => {
              this.handleToggleCommonModal('selectTemplateModal', true);
            },
          },
        };
      },
      /** spreadJS.config.commandMap 설정 변경 */
      updateCommandMap(config, type) {
        if ('init' === type) {
          return this.initCommandMap(config);
        }

        // type === 'common'
        config.commandMap.saveToDB.enableContext = '!disabled';
        config.commandMap.toggleSideBar.enableContext = '!disabled';
        config.commandMap.addDataSheet.enableContext = '!disabled';
        config.commandMap.editDate.enableContext = '!disabled';
        config.commandMap.addBiTemplate.enableContext = '!disabled';
      },
      /**
       * SpreadJS Designer 초기화
       * @param designer
       */
      designerInitialized(designer) {
        this.spreadJS.workbook = designer.getWorkbook();
        this.sheet = this.spreadJS.workbook.getSheet(0); // 첫 번째 시트를 얻습니다.
        this.spreadJS.designer = designer;
        this.spreadJS.config = this.$_commonlib.cloneObj(this.spreadJS.instance.Spread.Sheets.Designer.DefaultConfig);
        this.setInitCustomRibbon();
        /*Default 폰트 세팅(맑은 고딕) [START]*/
        const theme = new this.spreadJS.instance.Spread.Sheets.Theme(
          'koCustomTheme',
          this.spreadJS.instance.Spread.Sheets.ThemeColors.Office,
          '맑은 고딕',
          '맑은 고딕',
        );
        if (this.spreadJS.workbook) this.spreadJS.workbook.sheets.forEach(item => item.currentTheme(theme));
        /*Default 폰트 세팅(맑은 고딕) [END]*/
        this.updateCommandMap(this.spreadJS.config, 'init');
      },
      /** 리본 "보고서 검색조건" 탭 내 버튼 셋팅 */
      setInitCustomRibbon() {
        const horizontal = 'horizontal';

        const funcRibbon = (label, children) => ({
          label: label,
          commandGroup: {
            children: children,
          },
        });

        const reportRibbon = {
          id: 'report-ribbon',
          text: '보고서 검색조건',
          buttonGroups: [
            funcRibbon('보고서 목록', [
              {
                direction: horizontal,
                commands: ['toggleSideBar'],
              },
            ]),
            funcRibbon('데이터', [
              {
                direction: horizontal,
                commands: ['addDataSheet', 'editDate', 'addBiTemplate'], // 'editDataSheet',
              },
            ]),
            funcRibbon('데이터 저장', [
              {
                direction: horizontal,
                commands: ['saveToDB', 'downloadXlsx'],
              },
            ]),
          ],
        };

        this.spreadJS.config.ribbon = [reportRibbon, ...this.spreadJS.instance.Spread.Sheets.Designer.DefaultConfig.ribbon];
      },
      /** 선택된 BI 보고서 Blob 데이터 저장 */
      async fetchBlobDataUpdate(base64Data) {
        const biReportId = this.biMenu.selectedItem.id;
        const res = await this.CALL_REPORT_API({
          actionName: 'BI_REPORT_DATA_UPDATE',
          path: `/${biReportId}`,
          data: { content: base64Data },
          loading: true,
        });
        if (isSuccess(res)) {
          return this.$_Toast(this.$_lang('CMN_SUC_SAVE'));
        }
        return this.$_Toast(this.$_lang('CMN_ERR_SAVE'));
      },
      /** BI 카테고리 및 보고서 셋팅 (좌측 트리리스트) */
      async setBiGroupMenu() {
        // 그룹 리스트
        const categoriesRes = await this.CALL_REPORT_API({
          actionName: 'BI_CATEGORY_LIST_BY_USER',
          loading: true,
        });

        this.biMenu.categories = getResData(categoriesRes);
        const categories = this.biMenu.categories.map(item => ({
          id: item.id,
          nodeId: `GROUP_${item.id}`,
          parentId: -1,
          menuNm: item.name,
          isCategory: true,
          isDefaultCategory: item.defaultFl === 'Y',
        }));

        // 보고서 리스트
        const biReportRes = await this.CALL_REPORT_API({
          actionName: 'BI_REPORT_LIST_BY_USER',
          loading: true,
        });

        //TODO: biMenu.selectedItem 사용 하는 곳에서 name, menuNm 헷갈리게 호출해서 우선 중복 선언
        const biReports = getResData(biReportRes).map(item => ({
          id: item.id,
          nodeId: item.id,
          name: item.name,
          menuNm: item.name,
          parentId: `GROUP_${item.categoryId}`,
          categoryId: item.categoryId,
          defaultFl: 'N',
        }));

        this.biMenu.list = [...categories, ...biReports];
      },
      /** 현재 활성화된 시트 내 테이블을 생성 */
      createDataTable(sheet, name, colSize) {
        const dataTable = sheet.tables.add(name, 6, 1, 1, colSize);
        dataTable.expandBoundRows(true); // 데이터 추가될 시 row 자동 확장기능
        dataTable.autoGenerateColumns(false); // 컬럼 자동 생성 기능
        dataTable.style(this.spreadJS.instance.Spread.Sheets.Tables.TableThemes['light18']);
        return dataTable;
      },
      /** XML에 정의된 보고서 컬럼을 SpreadJS의 테이블 컬럼으로 변환 */
      getTableColumnsByXmlColumns(cols) {
        return cols.map((v, i) => {
          const { dataField, caption, multiHeaderNm, format } = cols[i];
          const columnNm = multiHeaderNm ? `${multiHeaderNm} ${caption}` : caption;
          let fmt = '';
          if (format === 'fmtime') fmt = '[h]:mm:ss';
          else if (format === 'fmNumber') fmt = '#,##0';
          else if (format === 'fmPercent') fmt = '#,##0';
          return new this.spreadJS.instance.Spread.Sheets.Tables.TableColumn(i + 1, dataField, columnNm, fmt);
        });
      },
      /** 워크북이 담고 있는 N개 시트 내 데이터 테이블명 리스트 반환 */
      getTableNameInDataSheets() {
        const dataTableNames = [];
        const workbook = this.spreadJS.workbook;
        const sheetCount = workbook.getSheetCount();
        for (let i = 0; i < sheetCount; i++) {
          const sheet = workbook.getSheet(i);
          const tables = sheet.tables.all();
          if (tables.length > 0 && tables.at(0).name().indexOf('esp.') > -1) {
            dataTableNames.push(tables.at(0).name());
          }
        }

        return dataTableNames;
      },
      /** 날짜 변경 모달 적용 버튼 클릭 시 호출 */
      async handleApplyUpdateDateModal() {
        const startDt = this.$refs.updateRangeDate.getStartDate();
        const endDt = this.$refs.updateRangeDate.getEndDate();
        await this.fetchSheetUpdateByDate(startDt, endDt);
      },
      /** 활성화된 모든 데이터 테이을 가진 시트를 날짜 변경 건으로 재작성 */
      async fetchSheetUpdateByDate(startDt, endDt) {
        EventBus.$emit('app:progress', true);
        // 데이터 시트에서 테이블 명을 가져옴
        const tables = this.getTableNameInDataSheets();
        if (tables.length === 0) {
          return this.$_Msg('데이터 시트를 추가해주시기 바랍니다.');
        }

        // id, name을 이용해 데이트 시트에 사용된 파라미터 리스트 가져옴
        const params = await this.getAllSheetsParameters(this.biMenu.selectedItem.id, tables, false);

        // 셋팅한 날짜로 데이터 시트를 갱신
        await this.updateDataSheets(params, startDt, endDt);

        // 모달 닫기
        this.handleCloseUpdateDateModal();
        EventBus.$emit('app:progress', false);
      },
      /** 워크북 전체 데이터 시트 별 보고서 항목 및 결과로 재작성 */
      async updateDataSheets(params, startDt, endDt) {
        const loading = false;
        for (const data of params) {
          const param = this.updateSheetParams(JSON.parse(data.param), startDt, endDt);

          // TODO: 현재 두 가지 분기로 나눠지며, 추후 함수 하나로 처리하도록 변경 예정
          let columns = null;
          let result = null;
          const type = data.type;
          if ('wizard' === type) {
            columns = (await this.fetchGetReportMenuInfo(param.reportId, loading)?.columns) || [];
            result = await this.fetchReportResults(param, loading);
          } else if ('bi-report' === type) {
            columns = await this.fetchXmlFileColumnList(param, loading);
            result = await this.fetchXmlFileResultList(param, loading);
          }
          this.updateSingleSheet(param, columns, result);
        }
      },
      /** 각 시트를 재작성 하는 함수 */
      updateSingleSheet(param, columns, result) {
        // TODO: UNIQUE 한 값이라 ID가 더 어울림
        // TODO: 개념 맞추려면 DB 부터 전체적으로 변경 필요하기 때문에 보류
        const dataTableName = param.biTableNm;
        const sheet = this.findSheetByDataTableName(dataTableName);
        const table = sheet.tables.findByName(dataTableName);

        this.drawUpdateDataSheetHeader(sheet, this.transformHeaderData(param));
        table.bind(this.getTableColumnsByXmlColumns(columns), '', result);
        sheet.resumePaint();
      },
      /** 워크북 내 일치하는 데이터 테이블명을 가진 시트 찾아서 반환 */
      findSheetByDataTableName(name) {
        const workbook = this.spreadJS.workbook;
        const sheetCount = workbook.getSheetCount();
        for (let i = 0; i < sheetCount; i++) {
          const sheet = workbook.getSheet(i);
          const table = sheet.tables.all().find(table => table.name() === name);

          if (table) {
            return sheet;
          }
        }

        throw new Error(`'${name}' 데이터 테이블을 찾지 못 했습니다.`);
      },
      /** 위자드 보고서 정보 조회 from Id */
      async fetchGetReportMenuInfo(id, loading = true) {
        const res = await this.CALL_REPORT_API({
          actionName: 'REPORT_INFO_BY_ID',
          data: { reportId: id },
          loading: loading,
        });
        if (isSuccess(res)) return getResData(res)?.at(0);
      },
      /** 보고서 조회 결과 데이터 가져오기 */
      async fetchReportResults(params, loading = true) {
        const res = await this.CALL_REPORT_API({
          actionName: 'REPORT_RESULT_LIST',
          data: { data: params },
          loading: loading,
        });
        if (isSuccess(res)) return getResData(res);
        //fail
        return [];
      },
      /** 시트를 재조회할 파라미터를 재작성하여 반환 */
      updateSheetParams(param, startDt, endDt) {
        param.loginId = this.$store.getters.getLoginId;
        param.startDt = startDt;
        param.endDt = endDt;
        return param;
      },
      /** 활성화 된 BI 메뉴 내 존재하는 <데이터 시트> 파라미터 조회 */
      async getAllSheetsParameters(id, names, loading = true) {
        const res = await this.CALL_REPORT_API({
          actionName: 'BI_REPORT_DATA_PARAM_LIST',
          path: `/${id}/param`,
          data: { name: names },
          loading: loading,
        });

        if (isSuccess(res)) {
          return getResData(res);
        }
      },
      /** 공통 날짜 변경 모달 닫기 */
      handleCloseUpdateDateModal() {
        this.updateDateModal.isOpened = false;
      },
      async importSpreadJS() {
        await Promise.all([
          import('@grapecity/spread-sheets/styles/gc.spread.sheets.excel2013white.css'),
          import('@grapecity/spread-sheets-designer/styles/gc.spread.sheets.designer.min.css'),
          import('@grapecity/spread-sheets-print'),
          import('@grapecity/spread-sheets-charts'),
          import('@grapecity/spread-sheets-shapes'),
          import('@grapecity/spread-sheets-slicers'),
          import('@grapecity/spread-sheets-pivot-addon'),
          import('@grapecity/spread-sheets-tablesheet'),
          import('@grapecity/spread-sheets-io'),
          import('@grapecity/spread-sheets-designer-resources-ko'),
          import('@grapecity/spread-sheets-resources-ko'),
          import('@grapecity/spread-sheets-vue'),
          import('@grapecity/spread-sheets-designer-vue'),
        ]);

        const SpreadJS = await import('@grapecity/spread-sheets');
        SpreadJS.Spread.Common.CultureManager.culture('ko-kr');
        SpreadJS.Spread.Sheets.LicenseKey = process.env.VUE_APP_BI_DEPLOY_LICENSE;
        SpreadJS.Spread.Sheets.Designer.LicenseKey = process.env.VUE_APP_BI_DESIGNER_LICENSE;
        this.spreadJS.instance = SpreadJS;

        // 템플릿 등록을 위한 ExcelIO 추가
        const ExcelIO = await import('@grapecity/spread-excelio');
        ExcelIO.LicenseKey = process.env.VUE_APP_BI_DEPLOY_LICENSE; // ExcelIO도 별도로 라이선스 등록 필요
        this.spreadJS.excelIO = new ExcelIO.IO();
      },
    },
    async created() {
      await this.importSpreadJS();
      await this.setBiGroupMenu(); //카테고리 그룹 목록 생성
    },
    mounted() {
      vm = this;
    },
    updated() {},
    destroyed() {},
  };
</script>
<style scoped>
  .th-bold {
    font-weight: 400;
  }
</style>
<style>
  .full-panels .gc-sidePanel-content .gc-flexcontainer .gc-column-set {
    height: 84vh;
  }
  .gc-column-set {
    color: white;
  }
  .gc-column-set,
  .gc-column-set * {
    color: inherit;
  }
  .gc-file-menu-list-new .new-sheet-temmplate-container {
    width: 230px !important;
    height: 210px !important;
    padding: 10px !important;
  }
  .bi-edit-sheet-icon {
    background-image: url('../../../assets/images/bi/editTableSheet.png');
    background-size: 35px 35px;
  }

  .bi-add-sheet-icon {
    background-image: url('../../../assets/images/bi/makeTableSheet.png');
    background-size: 35px 35px;
  }
  .bi-report-search-list-icon {
    background-image: url('../../../assets/images/bi/searchList.png');
    background-size: 35px 35px;
  }

  .bi-command-save-data {
    background-image: url('../../../assets/images/bi/saveTempleteData.png');
    background-size: 35px 35px;
  }

  .bi-command-download-xlsx {
    background-image: url('../../../assets/images/bi/download.png');
    background-size: 38px 38px !important;
  }

  .bi-command-pdf {
    background-image: url('../../../assets/images/bi/pdf.png');
    background-size: 35px 35px;
  }

  .bi-command-slide-menu {
    background-image: url('../../../assets/images/bi/menuSlide.png');
    background-size: 35px 35px;
  }
  .bi-command-calendar-time {
    background-image: url('../../../assets/images/calendar_time.png');
    background-size: 38px 38px !important;
  }

  .gc-drop-down-list {
    z-index: 999 !important;
  }

  .gc-statusbar-statusitem-container span {
    color: white !important;
  }

  #sideBar .dx-treelist-empty-space {
    margin-right: 0px;
  }

  #sideBar .dx-treelist-headers .dx-treelist-table .dx-row > td {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  #sideBar .btn-icon {
    min-height: 12px;
  }

  /* DxTreeList 사용자 관련 */
  /* 검색패널 헤더*/
  #bi-auth-members .dx-treelist-header-panel {
    position: absolute;
    z-index: 1;
    left: 10px;
  }

  #bi-auth-members .dx-toolbar .dx-texteditor {
    width: 230px !important;
  }

  /* 검색패널 header 높이 */
  #bi-auth-members .dx-cell-focus-disabled.dx-treelist-select-all.dx-editor-inline-block {
    height: 54px;
  }
  /* 검색패널 input 영역 배경 투명도 */
  #bi-auth-members .dx-treelist-header-panel,
  #bi-auth-members .dx-toolbar.dx-widget.dx-visibility-change-handler.dx-collection {
    background: none;
  }
  /* input background */
  #bi-auth-members .dx-item-content.dx-toolbar-item-content {
    background: #fff;
  }
  /* 검색창 placeholder 위치 */
  #bi-auth-members .dx-placeholder {
    left: 16px !important;
  }
  /* 검색창 아이콘 위치 */
  #bi-auth-members .dx-toolbar .dx-toolbar-after {
    left: 0;
    margin: 0 auto;
    padding-left: 0;
  }
  /* 전체선택 checkbox 위치 */
  #bi-auth-members .dx-treelist-select-all .dx-checkbox {
    top: 20px;
    left: 20px;
  }
  /* checkbox 위치 관련 */
  #bi-auth-members .dx-treelist-search-panel,
  #bi-auth-members .dx-treelist-rowsview tr:not(.dx-row-focused) .dx-treelist-empty-space {
    margin: 0;
  }
  #bi-auth-members .dx-treelist-rowsview .dx-treelist-empty-space {
    width: 10px;
  }
</style>
