import common from './enums/common';
import ewm from './enums/ewm';
import cc from './enums/cc';
import report from './enums/report';

export default {
  common,
  ewm,
  cc,
  report,
};
