<!--
  PACKAGE_NAME : src\pages\setting\system\menu\modal-menu-page-data.vue
  FILE_NAME : modal-menu-page-data
  AUTHOR : supark
  DATE : 2021-07-28
  DESCRIPTION : pageData 설정 팝업 컴포넌트
-->
<template>
  <dx-popup
    :visible="isOpen"
    :show-title="propOption.showTitle"
    :title="propOption.title"
    :min-width="propOption.minWidth"
    :width="propOption.width"
    :min-height="propOption.minHeight"
    :height="propOption.height"
    :resize-enabled="propOption.resizeEnabled"
    :drag-enabled="propOption.dragEnabled"
    :show-close-button="propOption.showCloseButton"
    :hide-on-outside-click="propOption.closeOnOutsideClick"
    @hiding="closeModal"
  >
    <div class="flex-modal-container">
      <div class="flex-modal-item">
        <div class="editor-style">
          <codemirror-editor :value="cmEditor.pageData" beautyType="js" />
        </div>
      </div>
    </div>

    <dx-toolbar-item
      widget="dxButton"
      toolbar="bottom"
      location="center"
      :options="{
        elementAttr: {
          class: 'default filled txt_S medium',
        },
        text: propOption.saveBtnTxt,
        width: '120',
        height: '40',
        onClick: e => handleSaveModal(e),
      }"
    />
    <dx-toolbar-item
      widget="dxButton"
      toolbar="bottom"
      location="center"
      :options="{
        elementAttr: {
          class: 'white filled txt_XS medium',
        },
        text: propOption.cancelBtnTxt,
        width: '120',
        height: '40',
        onClick: e => handleCloseModal(e),
      }"
    />
  </dx-popup>
</template>

<script>
  import CodemirrorEditor from '@/components/codemirror/codemirror-json-editor.vue';
  import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';

  export default {
    components: {
      DxToolbarItem,
      DxPopup,
      CodemirrorEditor,
    },
    props: {
      isOpen: {
        type: Boolean,
        default: false,
      },
      modalOption: {
        type: Object,
        default: () => ({}),
      },
      menuData: Object,
    },
    watch: {
      modalOption: {
        handler(val) {
          this.propOption = { ...this.propOption, ...val };
        },
        deep: true,
        immediate: true,
      },
      menuData: {
        handler(val) {
          this.cmEditor = {
            menuId: val?.id,
            menuNm: val?.menuNm,
            pageData: val?.pageData,
          };
          this.propOption.title = `${val?.menuNm} - 페이지 데이터 설정`;
        },
        deep: true,
      },
    },
    data() {
      return {
        propOption: {
          showTitle: true,
          title: '페이지 데이터 설정',
          minWidth: 800,
          width: 1200,
          minHeight: 600,
          height: 800,
          resizeEnabled: true,
          dragEnabled: true,
          showCloseButton: true,
          closeOnOutsideClick: true,
          useToolbar: true,
          useSaveBtn: true,
          useCancelBtn: true,
          saveBtnTxt: this.$_lang('COMPONENTS.SAVE', { defaultValue: '저장' }),
          cancelBtnTxt: this.$_lang('COMPONENTS.CANCEL', { defaultValue: '취소' }),
        },
        cmEditor: {
          menuId: null,
          menuNm: null,
          pageData: null,
        },
      };
    },
    methods: {
      /**
       * @description 취소 버튼 클릭 이벤트
       * @param e
       */
      closeModal(e) {
        this.initData();
        this.$emit('closeModal', e);
      },
      /**
       * @description 저장 버튼 클릭 이벤트
       */
      handleSaveModal() {
        const pageData = this.cmEditor.pageData;
        this.initData();
        this.$emit('saveModal', pageData);
      },
      /**
       * @description 취소 버튼 클릭 이벤트
       * @param e
       */
      handleCloseModal(e) {
        this.initData();
        this.$emit('closeModal', e);
      },
      /**
       * @description 데이터 초기화
       */
      initData() {
        this.cmEditor = {
          menuId: null,
          menuNm: null,
          pageData: null,
        };
      },
    },
    mounted() {},
  };
</script>
<style scoped>
  .flex-modal-container {
    display: flex;
    width: 100%;
    height: 100%;
  }

  .flex-modal-item {
    padding-left: 20px;
    padding-right: 20px;
  }

  .flex-modal-item:nth-child(1) {
    flex-grow: 1;
  }

  .flex-modal-item:nth-child(2) {
    flex-shrink: 0;
  }

  .editor-style {
    width: 100%;
    height: 90%;
  }

  .th-bold > tbody > tr > th {
    font-weight: 400;
  }
</style>
