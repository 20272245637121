<!--
  PACKAGE_NAME : src/pages/cc/ivr/schedule/config/modal-holidaytp.vue
  FILE_NAME : modal-holidaytp
  AUTHOR : hmlee
  DATE : 2024-09-25
  DESCRIPTION : 휴일 불러오기 모달
--> 
<template>
  <esp-dx-modal-popup
    :option="{
      title: modal.title,
      width: modal.width,
      height: modal.height,
      minWidth: modal.minWidth,
      minHeight: modal.minHeight,
      closeOnOutsideClick: modal.hideOnOutsideClick,
    }"
    :isOpen="isOpen"
    @saveModal="handleSaveModal"
    @closeModal="handleCloseModal"
  >
    <template #content>
      <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" />
    </template>
  </esp-dx-modal-popup>
</template>

<script>
  import EspDxModalPopup from "@/components/devextreme/esp-dx-modal-popup.vue";
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid-v2.vue';

  import CustomStore from "devextreme/data/custom_store";
  import { isSuccess, formatDate } from "@/plugins/common-lib";

  export default {
    components: {
      EspDxModalPopup,
      EspDxDataGrid,
    },
    props: {
      /** @description: 모달 오픈 여부 */
      isOpen: {
        default: false,
        type: Boolean,
      },
      /** @description: 휴일 리스트 */
      holidaytpList: {
        default: null,
        type: Array,
      },
    },
    data() {
      return {
        modal: {
          title: this.$_lang('COMMON.WORD.LOAD_HOLIDAY', { defaultValue: '휴일 불러오기' }),
          minWidth: '300',
          width: '600',
          minHeight: '300',
          height: '500',
          hideOnOutsideClick: false,
        },
        dataGrid: {
          refName: 'holidaytpGrid',
          keyExpr: 'holidayId',
          hoverStateEnabled: true,
          dataSourceDefaultSortColumn: '+date',
          dataSource: [],
          height: '350', // 주석처리시 100%
          showActionButtons: { // 그리드 버튼 노출 설정값
            update: false,
            delete: false,
          },
          page: {
            enabled: false,
          },
          columns: [
            {
              caption: '유형',
              i18n: 'COMMON.WORD.TYPE',
              dataField: 'holidayTypeCd',
              width: '20%',
              lookup: {
                dataSource: this.$_enums.common.holidayType.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
            {
              caption: '공휴일명',
              i18n: 'COMMON.WORD.HOLIDAY_NAME',
              dataField: 'holidayNm',
            },
            {
              caption: '공휴일',
              i18n: 'COMMON.WORD.HOLIDAY',
              dataField: 'mmdd',
              calculateDisplayCellValue: rowData => {
                let holidayDt;
                if (rowData.holidayTypeCd === this.$_enums.common.holidayType.FIXED.value) {
                  holidayDt = formatDate(rowData.date, 'MMDD', 'MM.DD');
                } else {
                  holidayDt = formatDate(rowData.holidayTm, 'YYYYMMDD', 'YYYY.MM.DD');
                }
                return holidayDt;
              },
            },
          ],
        },
      };
    },
    methods: {
      /** @description: 모달 저장 */
      async handleSaveModal() {
        const selectedRowsData = this.$refs[this.dataGrid.refName].getInstance.getSelectedRowsData();

        if (selectedRowsData.length === 0) {
          return this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', {defaultValue: '대상이 선택되어 있지 않습니다.'}));
        }

        this.$emit('saveModal', selectedRowsData);
      },
      /** @description: 모달 닫기 */
      handleCloseModal() {
        this.$emit('closeModal');
      },
      /** @description: 데이터 조회 */
      async selectDataList() {
        this.dataGrid.dataSource = new CustomStore({
          key: this.dataGrid.keyExpr,
          load: async (loadOptions) => {
            const params = this.$_getDxDataGridParam(loadOptions);

            params.sort = this.dataGrid.dataSourceDefaultSortColumn;

            //중복된 공휴일 날짜 제외
            const dateList = this.checkedHolidayList?.map(day => day.holidayDt.slice(-4));
            if ( dateList?.length > 0 ) {
              params.date = `<>${dateList}`;
            }

            const payload = {
              actionName: 'HOLIDAY_LIST_ALL',
              data: { params },
            };

            let rtnData = {
              data: [],
              totalCount: 0,
            };
            const res = await this.CALL_API(payload);
            if (isSuccess(res)) {
              rtnData = {
                data: res.data.data,
                totalCount: res.data.header.totalCount,
              };
            }
            return rtnData;
          },
        });
      },
      /** @description: 데이터 초기 설정 */
      initData() {
        this.checkedHolidayList = this.holidaytpList ? JSON.parse(JSON.stringify(this.holidaytpList)) : [];
      }
    },
    async created() {
      await this.initData();
      await this.selectDataList();
    },
  };
</script>