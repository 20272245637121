<!--
  PACKAGE_NAME : src\components\devextreme\esp-dx-popover
  FILE_NAME : esp-dx-popover
  AUTHOR : hmlee
  DATE : 2025-01-15
  DESCRIPTION : ESP 팝오버(툴팁) 컴포넌트
-->
<template>
  <div class="popover-container">
    <button
      class="btn-question"
      :id="optionConfig.target"
    />
    <dx-popover
      class="esp-dx-popover"
      :target="`#${optionConfig.target}`"
      :position="optionConfig.position"
      :hide-on-outside-click="optionConfig.hideOnOutsideClick"
      show-event="mouseenter"
      hide-event="mouseleave"
    >
      <span v-html="optionConfig.html"/>
    </dx-popover>
  </div>
</template>
<script>
  import { DxPopover } from "devextreme-vue/popover";

  export default {
    components: {
      DxPopover,
    },
    props: {
      target: { // 팝오버 대상
        default: () => 'tooltips1',
        type: String,
      },
      position: {
        default: () => 'bottom',
        type: String,
      },
      html: { // 팝오버 내용
        default: () => '',
        type: String,
      },
    },
    data() {
      return {
        defaultOption: {
          target: 'tooltips1', // 팝오버 대상
          position: 'bottom', // 팝오버 위치 / ['top', 'bottom', 'left', 'right']
          html: '', // 툴팁 내용
          hideOnOutsideClick: true, // 외부 클릭 시 닫기 여부
        },
      };
    },
    computed: {
      /** @description: 옵션 설정 데이터 */
      optionConfig() {
        return {
          ...this.defaultOption,
          target: this.target,
          position: this.position,
          html: this.html,
        };
      },
    },
    watch: {
    },
    methods: {
    },
  };
</script>

<style scoped>
  .popover-container {
    display: inline-block;
  }

  .popover-container .btn-question {
    width: 17px;
    height: 17px;
    margin-left: 5px;
    margin-bottom: 1px;
    vertical-align: middle;
    background: url(../../assets/images/contents_sprite.png) no-repeat;
    background-position: -216px -16px;
  }
</style>
