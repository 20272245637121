<!--
  PACKAGE_NAME : src\pages\ewm\hr\work\schedule\management.vue
  FILE_NAME : management
  AUTHOR : bykim
  DATE : 2024-03-14
  DESCRIPTION : 근무스케줄 관리
-->
<template>
  <div>
    <div class="page_search_box line_bottom_1px">
      <div class="inner alL">
        <esp-date-range-box
          ref="dateRangeBox"
          label="근무일자"
          :start-dt="searchType.customTypes.dayStart"
          :end-dt="searchType.customTypes.dayEnd"
        />
        <DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="onSearch" />
      </div>
    </div>
    <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" />
    <modal-schedule-appointment
      :is-open="popup.visible"
      :appointment-data="popup.appointmentData"
      @closeModal="onClosePopup"
      @saveModal="onSavePopup"
    />
    <modal-page-attendance-log
      :is-open="attendancePopup.visible"
      @closeModal="onCloseAttendancePopup"
      :schedule-id="attendancePopup.scheduleId"
    />
    <modal-modify-schedule
      :is-open="modifySchedulePopup.visible"
      :appointment-data="modifySchedulePopup.appointmentData"
      @closeModal="onCloseModifySchedulePopup"
      @saveModal="onSaveModifySchedulePopup"
    />
    <modal-modify-attendance
      :is-open="modifyAttendancePopup.visible"
      :agent-data="modifyAttendancePopup.appointmentData"
      :attendance-state="modifyAttendancePopup.attendanceState"
      @closeModal="onCloseModifyAttendancePopup"
      @saveModal="onSaveModifyAttendancePopup"
    />
  </div>
</template>

<script>
  import { getPastFromToday, isSuccess } from '@/plugins/common-lib';
  import ModalScheduleAppointment from '@/pages/ewm/hr/work/schedule/popup/modal-schedule-appointment.vue';
  import { DxButton } from 'devextreme-vue/button';

  import EspDateRangeBox from '@/components/devextreme/esp-dx-date-range-box.vue';
  import moment from 'moment/moment';
  import ModalPageAttendanceLog from '@/pages/ewm/hr/work/schedule/popup/modal-page-attendance-log.vue';
  import ModalModifySchedule from '@/pages/ewm/hr/work/schedule/popup/modal-modify-schedule.vue';
  import ModalModifyAttendance from '@/pages/ewm/hr/work/schedule/popup/modal-modify-attendance.vue';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: {
      EspDxDataGrid,
      ModalModifyAttendance,
      ModalModifySchedule,
      ModalPageAttendanceLog,
      EspDateRangeBox,
      ModalScheduleAppointment,
      DxButton,
    },
    props: {},
    data() {
      return {
        codeMap: {},
        searchType: {
          customTypes: {
            // FIXME : 현재 주간 월 ~ 다음주 금요일 까지 검색 할 수 있도록 설정 필요
            dayStart: getPastFromToday(7, 'days'),
            dayEnd: moment().add(7, 'days').format('YYYYMMDD'),
          },
        },
        popup: {
          // 근무등록 팝업
          visible: false,
          appointmentData: {},
        },
        attendancePopup: {
          // 근태 로그 팝업
          visible: false,
          scheduleId: -1,
        },
        modifySchedulePopup: {
          // 대직 처리 팝업
          visible: false,
          appointmentData: {},
        },
        modifyAttendancePopup: {
          // 출결정보 변경 팝업
          visible: false,
          agentData: {},
          attendanceState: '',
        },
        dataGrid: {
          keyExpr: 'scheduleId', // 그리드 키값
          refName: 'workScheduleGrid', // 그리드 컴포넌트 참조명
          callApi: 'CALL_EWM_API', // 그리드 데이터 호출 API
          excel: {
            title: '근무스케줄', // 엑셀 다운로드 시 파일명
            autoFilterEnabled: false, // 엑셀 필터 사용 유무
          },
          focusedRowEnabled: false, // 포커스 행 표시 유무
          allowColumnReordering: false, // 컬럼 위치 변경 유무
          allowColumnResizing: true, // 컬럼 사이즈 조절 유무
          columnResizingMode: 'widget', // 컬럼 사이즈 조절 모드 : ['nextColumn', 'widget']
          columnAutoWidth: true, // 컬럼 사이즈 자동 조절 유무
          columnMinWidth: 80, // 컬럼 최소 사이즈
          showBorders: false, // 그리드 테두리 유무
          showColumnHeaders: true, // 컬럼 헤더 유무
          showColumnLines: false, // 컬럼 세로선 유무
          showRowLines: true, // 컬럼 가로선 유무
          rowAlternationEnabled: false, //행 배경색 교차 유무
          dataSource: [], // 그리드 데이터
          apiActionNm: {}, // api 호출시 사용할 액션명(ESP - API URL 관리) : ['update', 'merge', 'delete', 'select']
          scrolling: {
            mode: 'standard', // 스크롤 모드 : ['standard', 'virtual', 'infinite']
          },
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
            cellPrepared: false, // 셀 이벤트
            rowClick: true, // 행 클릭 이벤트
            toolbarPreparing: false, // 툴바 이벤트
          },
          showActionButtons: {
            excel: true, // 엑셀 다운로드 버튼
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '일반근무등록',
                  elementAttr: { class: 'default filled txt_XS add1 ml-1' },
                  width: 120,
                  height: 30,
                  onClick: () => {
                    this.onInitialSettingsPopup(this.$_enums.ewm.workCategory.BASIC);
                  },
                },
                location: 'before',
              },
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '시간외근무등록',
                  elementAttr: { class: 'btn_XS white light_filled ml-1' },
                  width: 120,
                  height: 30,
                  onClick: () => {
                    this.onInitialSettingsPopup(this.$_enums.ewm.workCategory.OVER_TIME);
                  },
                },
                location: 'before',
              },
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '대직 처리',
                  elementAttr: { class: 'btn_XS white light_filled' },
                  width: 100,
                  height: 30,
                  onClick: this.onModifyWorkSchedule,
                },
                location: 'before',
              },
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '근무삭제',
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 100,
                  height: 30,
                  onClick: this.onDeleteData,
                },
                location: 'before',
              },
            ], // 그리드 커스텀 버튼 생성
          },
          isDuplicateConfigKey: false, // 설정키 중복 체크
          grouping: {
            contextMenuEnabled: true, // 그룹핑 컨텍스트 메뉴 사용유무
            autoExpandAll: true, // 그룹핑시 전체 펼침 여부
            allowCollapsing: true, // 그룹핑시 접기 허용 여부
            expandMode: 'rowClick', // 그룹핑 펼침 모드 : ['rowClick', 'buttonClick']
          },
          groupPanel: {
            visible: false, // 그룹패널 표시 여부
          },
          columnChooser: {
            enabled: false, // 컬럼 선택 팝업 표시 여부
          },
          loadPanel: {
            enabled: true, // 로딩 패널 표시 여부
          },
          sorting: {
            mode: 'multiple', // 정렬 모드 : ['none', 'single', 'multiple']
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false, // 페이징 사용 유무
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, // 페이지 사이즈 선택 버튼 표시 여부
            allowedPageSizes: [], // 페이지 사이즈 선택 버튼에 표시할 항목
            displayMode: 'compact', // 페이저 표시 모드 : ['adaptive', 'compact', 'full']
            showInfo: false, // 페이지 정보 표시 여부
            showNavigationButtons: false, // 페이지 이동 버튼 표시 여부
          },
          filterRow: {
            visible: true, // 필터 행 표시 여부
          },
          headerFilter: {
            visible: true, // 헤더 필터 표시 여부
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false, // 삭제 버튼을 없애고 싶으면 false설정(Row 마다 휴지통 생성)
            mode: 'batch', // 행 편집 모드 : ['cell', 'row', 'batch']
            startEditAction: 'click', // 행 편집 시작 액션 : ['click', 'dblClick']
            selectTextOnEditStart: false, // 편집 시작시 텍스트 선택 여부
          },
          selecting: {
            mode: 'multiple', // 행 선택 모드 : ['none', 'single', 'multiple']
            selectAllMode: 'page', // 전체 선택 모드 : ['page', 'allPages']
            showCheckBoxesMode: 'always', // 체크박스 표시 모드 : ['none', 'onClick', 'onLongTap', 'always']
            deferred: false, // 선택 지연 여부
            allowSelectAll: false, // 전체 선택 허용 여부
          },
          searchPanel: {
            visible: false, // 검색 패널 표시 여부
          },
          columns: [
            {
              groupIndex: 0,
              caption: '상담원',
              dataField: 'agent',
              visible: true,
              sortOrder: 'none',
              allowHeaderFiltering: true,
              allowFiltering: true,
              allowGrouping: true,
              calculateCellValue: this.mergeAgentInfo,
            },
            {
              dataType: 'date',
              format: 'yyyy-MM-dd',
              caption: '근무일자',
              dataField: 'workDt',
              alignment: 'center',
              visible: true,
              sortOrder: 'none',
              allowHeaderFiltering: true,
              allowFiltering: false,
            },
            {
              caption: '일반근무유형',
              dataField: 'workBasicNm',
              visible: true,
              sortOrder: 'none',
              alignment: 'center',
              allowHeaderFiltering: false,
              allowFiltering: true,
              allowGrouping: false,
              cellTemplate: (container, options) => {
                let aTag = document.createElement('a');
                aTag.innerText = options.value;
                aTag.addEventListener('click', () => {
                  this.onWorkTypePopup(this.$_enums.ewm.workCategory.BASIC, options.data);
                });
                container.append(aTag);
              },
            },
            {
              caption: '일반근무시간',
              dataField: 'workBasicTime',
              visible: true,
              sortOrder: 'none',
              alignment: 'center',
              allowHeaderFiltering: false,
              allowFiltering: true,
              allowGrouping: false,
              calculateCellValue: rowData => {
                return this.mergeWorkTime(rowData, this.$_enums.ewm.workCategory.BASIC.value);
              },
            },
            {
              caption: '시간외근무유형',
              dataField: 'workOverTimeNm',
              visible: true,
              sortOrder: 'none',
              alignment: 'center',
              allowHeaderFiltering: false,
              allowFiltering: true,
              allowGrouping: false,
              cellTemplate: (container, options) => {
                let aTag = document.createElement('a');
                if (options.value == null || options.value === '' || options.value === undefined) {
                  aTag.innerText = this.$_lang('COMPONENTS.ADD', { defaultValue: '추가' });
                } else {
                  aTag.innerText = options.value;
                }
                aTag.addEventListener('click', () => {
                  this.onWorkTypePopup(this.$_enums.ewm.workCategory.OVER_TIME, options.data);
                });
                container.append(aTag);
              },
            },
            {
              caption: '시간외근무시간',
              dataField: 'workOvertime',
              visible: true,
              sortOrder: 'none',
              alignment: 'center',
              allowHeaderFiltering: false,
              allowFiltering: true,
              allowGrouping: false,
              calculateCellValue: rowData => {
                return this.mergeWorkTime(rowData, this.$_enums.ewm.workCategory.OVER_TIME.value);
              },
            },
            {
              caption: '근무시간',
              dataField: 'workTime',
              visible: true,
              sortOrder: 'none',
              alignment: 'center',
              allowHeaderFiltering: false,
              allowFiltering: false,
              allowGrouping: false,
              calculateCellValue: rowData => {
                if (rowData === null || rowData === undefined) {
                  return '';
                }

                if (rowData?.workOverTimeEndTime) {
                  return `${rowData.workBasicStartTime} ~ ${rowData.workOverTimeEndTime}`;
                }

                return `${rowData.workBasicStartTime} ~ ${rowData.workBasicEndTime}`;
              },
            },
            {
              caption: '출결상태',
              dataField: 'attendanceState',
              visible: true,
              sortOrder: 'none',
              alignment: 'center',
              allowHeaderFiltering: false,
              allowFiltering: false,
              allowGrouping: false,
              cellTemplate: (container, options) => {
                // FIXME : onExporting customizeCell 엑셀 다운로드시 해당 셀의 값 부여 필요.
                let div = document.createElement('div');
                let data = options.data;

                // 정상
                if (data.normalFl === 'Y') {
                  let span = document.createElement('span');
                  span.className =
                    'bg-green-100 text-green-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 ' +
                    'dark:text-green-400 border border-green-400 ml-1 cursor-pointer';
                  span.innerText = this.$_lang('EWM.WORD.ATTENDANCE_STATUS_NORMAL', { defaultValue: '정상' });
                  div.appendChild(span);
                }

                // 지각
                if (data.tardinessFl === 'Y') {
                  let span = document.createElement('span');
                  span.className =
                    'bg-yellow-100 text-yellow-800 text-sm font-medium me-2 px-2.5 py-0.5 ' +
                    'rounded dark:bg-gray-700 dark:text-yellow-300 border border-yellow-300 ml-1 cursor-pointer';
                  span.innerText = this.$_lang('EWM.WORD.ATTENDANCE_STATUS_LATE', { defaultValue: '지각' });
                  span.addEventListener('click', () => {
                    this.onModifyAttendance(options.data);
                  });
                  div.appendChild(span);
                }

                // 결근
                if (data.absentFl === 'Y') {
                  let span = document.createElement('span');
                  span.className =
                    'bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 ' +
                    'rounded dark:bg-gray-700 dark:text-red-400 border border-red-400 ml-1 cursor-pointer';
                  span.innerText = this.$_lang('EWM.WORD.ATTENDANCE_STATUS_ABSENCE', { defaultValue: '결근' });
                  span.addEventListener('click', () => {
                    this.onModifyAttendance(options.data);
                  });
                  div.appendChild(span);
                }

                // 조퇴
                if (data.dayoffFl === 'Y') {
                  let span = document.createElement('span');
                  span.className =
                    'bg-purple-100 text-purple-800 text-sm font-medium me-2 px-2.5 py-0.5 ' +
                    'rounded dark:bg-gray-700 dark:text-purple-400 border border-purple-400 ml-1 cursor-pointer';
                  span.innerText = this.$_lang('EWM.WORD.ATTENDANCE_STATUS_EARLY_LEAVE', { defaultValue: '조퇴' });
                  span.addEventListener('click', () => {
                    this.onModifyAttendance(options.data);
                  });
                  div.appendChild(span);
                }
                container.append(div);
              },
            },
            {
              caption: '출결정보 변경',
              dataField: 'attendanceUpdateFl',
              visible: true,
              sortOrder: 'none',
              alignment: 'center',
              allowHeaderFiltering: false,
              allowFiltering: false,
              allowGrouping: false,
              cellTemplate: (container, options) => {
                let aTag = document.createElement('a');
                if (options?.data?.attendanceWorkTimeEditId) {
                  aTag.innerText = this.$_enums.common.stringUsedFlag.YES.value;
                  aTag.addEventListener('click', () => {
                    this.attendancePopup.scheduleId = options?.data?.scheduleId;
                    this.attendancePopup.visible = true;
                  });
                } else {
                  aTag.innerText = this.$_enums.common.stringUsedFlag.NO.value;
                  aTag.setAttribute('style', 'cursor:text; color:#545454 !important; text-decoration:none;');
                }
                container.append(aTag);
              },
            },
            {
              caption: '휴가',
              dataField: 'vacationTypeCd',
              visible: true,
              alignment: 'center',
              allowHeaderFiltering: false,
              allowFiltering: false,
              allowGrouping: false,
              lookup: {
                dataSource: [],
                valueExpr: 'codeId',
                displayExpr: 'codeNm',
              },
            },
          ], // 컬럼 정보
        },
      };
    },
    computed: {},
    methods: {
      async init() {
        this.$refs.dateRangeBox.initDate();
        this.refreshGrid();
        try {
          this.codeMap = await this.$_getCodeMapList('root_ewm_hr_vacation_division');
        } catch (err) {
          console.error(err);
          this.codeMap = [];
        }
        this.dataGrid.columns[9].lookup.dataSource = this.$_fetchCodesByMaxDepth(this.codeMap['root_ewm_hr_vacation_division']);
      },
      /** @description : 상담사 이름 + 상담사 로그인아이디 병합  */
      mergeAgentInfo(rowData) {
        if (rowData === null || rowData === undefined) {
          return '';
        }
        return `${rowData.deptNmPath}>${rowData.agtNm} [${rowData.agtid}]`;
      },
      /**
       * @description : 근무시간 병합
       * @param rowData
       * @param workCategory
       * @return {string}
       */
      mergeWorkTime(rowData, workCategory) {
        if (rowData === null || rowData === undefined) {
          return '';
        }

        let timeStr = '';
        if (workCategory === this.$_enums.ewm.workCategory.BASIC.value) {
          timeStr = `${rowData.workBasicStartTime} ~ ${rowData.workBasicEndTime}`;
        } else if (rowData?.workOverTimeStartTime && rowData?.workOverTimeEndTime) {
          timeStr = `${rowData.workOverTimeStartTime} ~ ${rowData.workOverTimeEndTime}`;
        }
        return timeStr;
      },
      /**
       * @description : 팝업 닫기
       * @param e
       */
      onClosePopup(e) {
        this.popup.visible = false;
        this.popup.appointmentData = {};
      },
      /**
       * @description : 근태 로그 팝업 닫기
       * @param e
       */
      onCloseAttendancePopup(e) {
        this.attendancePopup.visible = false;
      },
      /**
       * @description : 팝업 저장
       * @param e
       */
      onSavePopup(e) {
        console.log('onSavePopup', e);
        if (e.submitList === undefined || e.submitList === null) {
          e.submitList = [];
          e.submitList.push({
            agtid: e.agentData.agtid,
            workDateList: [e.agentData.workDt.replace(/-/g, '')],
            workTypeId: e.workTypeId,
          });
        } else {
          e.submitList.forEach(item => {
            item.workTypeId = e.workTypeId;
          });
        }
        this.onSaving(e.submitList);
        this.popup.visible = false;
        this.popup.appointmentData = {};
      },
      /**
       * @description : 근무스케줄 초기 설정 팝업
       * @param workCategory
       */
      onInitialSettingsPopup(workCategory) {
        if (workCategory === undefined || workCategory === null) {
          this.$_Toast(this.$_lang('SYSTEM_ERROR', { defaultValue: '시스템 오류 입니다. 관리자에게 문의하세요.' }));
          return false;
        }
        this.popup.appointmentData.pageName = 'modal-page-initial-settings';
        this.popup.appointmentData.workCategory = workCategory;
        this.popup.appointmentData.startDate = this.$refs.dateRangeBox.getStartDate();
        this.popup.appointmentData.endDate = this.$refs.dateRangeBox.getEndDate();
        this.popup.visible = true;
      },
      /**
       * @description : 근무유형 팝업
       * @param workCategory
       * @param agentData
       */
      onWorkTypePopup(workCategory, agentData) {
        if (workCategory === undefined || workCategory === null) {
          this.$_Toast(this.$_lang('SYSTEM_ERROR', { defaultValue: '시스템 오류 입니다. 관리자에게 문의하세요.' }));
          return false;
        }

        if (agentData === undefined || agentData === null) {
          this.$_Toast(this.$_lang('SYSTEM_ERROR', { defaultValue: '시스템 오류 입니다. 관리자에게 문의하세요.' }));
          return false;
        }
        this.popup.appointmentData.pageName = 'modal-page-worktype';
        this.popup.appointmentData.workCategory = workCategory;
        this.popup.appointmentData.agentData = agentData;
        this.popup.visible = true;
      },
      /**
       * @description : 근무스케줄 삭제
       */
      async onDeleteData() {
        const vm = this;
        const selectedRowsData = this.$refs[this.dataGrid.refName].getInstance.getSelectedRowsData();
        if (selectedRowsData?.length) {
          if (
            await this.$_Confirm(
              this.$_lang('COMMON.MESSAGE.CMN_CFM_DELETE_SELECTED', { defaultValue: '선택한 데이터를 삭제하시겠습니까?' }),
            )
          ) {
            let ids = [];
            selectedRowsData.forEach(row => {
              ids.push(row['scheduleId']);
            });

            const payload = {
              actionName: 'EWM_WORK_SCHEDULE_LIST_DELETE',
              data: { ids: ids },
              loading: false,
            };

            const res = await vm.CALL_EWM_API(payload);
            if (isSuccess(res)) {
              this.refreshGrid();
            }
          }
        } else {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return false;
        }
      },
      /**
       * @description : 그리드 새로고침
       */
      refreshGrid() {
        this.$refs.workScheduleGrid.refreshData();
        this.$refs.workScheduleGrid.clearSelection();
        this.$refs.workScheduleGrid.clearFilter();
        this.$refs.workScheduleGrid.clearSorting();
      },
      async onSearch() {
        let vm = this;
        this.dataGrid.dataSource = new CustomStore({
          key: 'scheduleId',
          async load() {
            const payload = {
              actionName: 'EWM_WORK_SCHEDULE_LIST',
              data: {
                workDt: `${vm.$refs.dateRangeBox.getStartDate()}~${vm.$refs.dateRangeBox.getEndDate()}`,
              },
              useErrorPopup: true,
            };
            let rtnData = [];
            const res = await vm.CALL_EWM_API(payload);
            if (isSuccess(res)) {
              rtnData = res.data.data;
            }
            return rtnData;
          },
        });
      },
      /**
       * @description : 근무스케줄 저장
       * @param data
       * @return {Promise<void>}
       */
      async onSaving(data) {
        const payload = {
          actionName: 'EWM_WORK_SCHEDULE_BATCH',
          data: data,
          useErrorPopup: true,
        };

        const res = await this.CALL_EWM_API(payload);
        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
          this.refreshGrid();
        }
      },
      /**
       * @description : 근무스케줄 변경 팝업 호출
       */
      onModifyWorkSchedule() {
        const selectedRowsData = this.$refs[this.dataGrid.refName].getInstance.getSelectedRowsData();
        if (selectedRowsData?.length === 1) {
          const selData = selectedRowsData[0];

          // TODO : EWM, 오늘 이전 날짜는 변경 불가 처리는 추후에 확인 필요
          // if (selData?.workDt < moment().format('YYYY-MM-DD')) {
          //   this.$_Msg(this.$_lang('EWM.MESSAGE.WORK_SCHEDULE_CANNOT_MODIFY_PAST_DATES'));
          //   return;
          // }
          this.modifySchedulePopup.visible = true;
          this.modifySchedulePopup.appointmentData.agentData = selData;
        } else if (selectedRowsData?.length > 1) {
          this.$_Msg(this.$_lang('CMN_ONE_SELECT'));
        } else {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
        }
      },
      /**
       * @description : 근무스케줄 변경 팝업 닫기
       */
      onCloseModifySchedulePopup() {
        this.modifySchedulePopup.visible = false;
      },
      /**
       * @description : 근무스케줄 변경 저장
       */
      onSaveModifySchedulePopup() {
        this.modifySchedulePopup.visible = false;
        this.onSearch();
      },
      /**
       * @description : 출결정보 변경 팝업 닫기
       */
      onCloseModifyAttendancePopup() {
        this.modifyAttendancePopup.visible = false;
        this.onSearch();
      },
      /**
       * @description : 출결정보 변경 저장
       */
      onSaveModifyAttendancePopup() {
        this.modifyAttendancePopup.visible = false;
        this.onSearch();
      },
      /**
       * @description : 출결정보 변경 팝업 호출
       * @param agentData 상담원 정보
       */
      onModifyAttendance(agentData) {
        this.modifyAttendancePopup.visible = true;
        this.modifyAttendancePopup.appointmentData = agentData;
      },
    },
    created() {},
    mounted() {
      this.init();
      this.onSearch();
    },
  };
</script>

<style lang="scss" scoped></style>
