<!--
  PACKAGE_NAME : src/pages/cc/cti/team-category
  FILE_NAME : category-list.vue
  AUTHOR : hmlee
  DATE : 2024-07-02
  DESCRIPTION : 좌측 팀그룹 트리리스트 컴포넌트
-->
<template>
  <!-- 트리 리스트 -->
  <div class="layout-cut-left tree-box01 fl">
      <esp-dx-tree-list
        id="treeList"
        :tree-list="treeList"
        :ref="treeList.refName"
        @selection-changed="handleSelectionChanged"
        @row-click="handleClickRow"
      />
  </div>
</template>

<script>
import EspDxTreeList from '@/components/devextreme/esp-dx-tree-list-v2.vue';

export default {
  components: {
    EspDxTreeList,
  },
  props: {
    categoryLevel: {
      type: Number,
      default: 3,
    }
  },
  watch: {},
  data() {
    return {
      config: {
        stylingMode: 'outlined', //[outlined, filled, underlined]
        isViewFl: true,
      },
      modal: {
        isOpened: false,
        currentComponent: null,
        initData: {},
        contentData: null,
      },
      treeList: {
        callApi: 'CALL_CC_API',
        refName: 'treeList',
        width: '350',
        dataSource: [],
        dataSourceDefaultSortColumn: '+depth,+agtteamCtgOrd', // 주석처리하면 keyExpr 컬럼으로 sorting됨 + 오름차순 - 내림차순1
        sortKey: 'agtteamCtgOrd', // 주석처리하면 keyExpr 컬럼으로 sorting됨 + 오름차순 - 내림차순1
        apiActionNm: {
          select: 'CC_AGTTEAM_CATEGORY_LIST',
          merge: 'CC_AGTTEAM_CATEGORY_MERGE',
          delete: 'CC_AGTTEAM_CATEGORY_DELETE',
        },
        showActionButtons: { // 상단 버튼 노출 설정값
          update: false, // 추가/저장/취소 / true가 기본
          delete: { // 삭제 / false가 기본 / 버튼 노출시 멀티 셀렉션 모드로 변경
            enabled: true,
            options: {
              width: 0,
            },
            sortIndex: 60,
          },
          move: { // 이동 / false가 기본
            enabled: true,
            sortIndex: 40,
          },
          sort: { // 순서 저장 / true가 기본
            enabled: true,
            options: {
              width: 0,
              elementAttr: { class: 'btn_XS white outlined' },
            },
            sortIndex: 50,
          },
          toggleExpand: { // 목록 펼치기/접기 / true가 기본
            enabled: true,
            sortIndex: 30,
          },
          customButtons: [],
        },
        filterUseItem: { // 사용중인 항목만 보기 설정 값
          enabled: false, // 사용중인 항목만 보기 / false가 기본
        },
        editing: { // 수정모드
          allowAdding: false, // 상단 추가버튼 / 컬럼 하위추가버튼 표시 여부
          allowUpdating: false, // 수정버튼 표시 여부
          allowDeleting: false, // 삭제버튼 표시 여부
        },
        selection: { //로우 선택 설정
          allowSelectAll: true, //헤더 체크박스 선택(전체선택) 허용 여부
          mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple', 'none']
          recursive: false, //상위 노드 선택시 하위 노드도 선택 여부(true: 하위 노드도 선택, false: 하위 노드 선택 안하고 독립적)
          selectionType: 'excludeRecursive', // 선택된 행의 데이터를 어떻게 가져올지 타입 ['all', 'excludeRecursive', 'leavesOnly']
        },
        columns: [
          {
            caption: '그룹명', // 타이틀명
            i18n: 'CC.WORD.GROUP_NAME', // 다국어 처리
            dataField: 'ctgNm', // 컬럼명
            alignment: 'left', // 정렬
            calculateCellValue: this.ctgNm,
          },
        ],
      },
    }
  },
  computed: {},
  methods: {
    /** @description: 트리메뉴 변경 이벤트 */
    handleSelectionChanged(e) {
      const selectedData = e.component.getSelectedRowsData(this.treeList.selection.selectionType);
      this.$refs[this.treeList.refName].treeListConfig.selectedRowsData = selectedData;
      this.$emit('selectRow', selectedData);
    },
    /** @description : 트리 로우 데이터 클릭 이벤트 */
    handleClickRow(e) {
      // 이전에 클릭된 행의 클래스를 제거
      const previouslyClicked = document.querySelector('.clicked-row');
      if (previouslyClicked) {
        previouslyClicked.classList.remove('clicked-row');
      }

      // 클릭된 행 요소에 클래스 추가
      e.rowElement.classList.add('clicked-row');

      const rowData = e.data;

      if (rowData) {
        this.$refs[this.treeList.refName].treeListConfig.focusedRowData = rowData;
        this.$emit('clickRow', e);
      }
    },
    /** @description: 카테고리 이름 */
    ctgNm(rowData) {
      if (rowData.depth < this.categoryLevel) {
        return rowData.ctgNm;
      } else {
        return rowData.agtTeam?.agtteamNm;
      }
    },
    /** @description: 페이지 초기화 */
    initPage() {
      this.clearData(); //데이터 초기화
      this.$refs[this.treeList.refName].handleSelectData(); //트리리스트 데이터 조회
      this.refreshTreeList(); //트리리스트 refresh
      this.$emit('initClickRow', this.$refs[this.treeList.refName].treeListConfig.focusedRowData); //데이터 초기화
    },
    /** @description: 데이터 초기화 */
    clearData() {
      this.$refs[this.treeList.refName].treeListConfig.selectedRowkeys = [];
      this.$refs[this.treeList.refName].treeListConfig.focusedRowData = {};
    },
    /** @description: treeList refresh 이벤트 */
    refreshTreeList() {
      if (this.$refs[this.treeList.refName]) {
        this.$refs[this.treeList.refName].getInstance.refresh();
        this.$refs[this.treeList.refName].getInstance.deselectAll(); // 선택 해제
      }
    },
  },
  mounted() {
    this.initPage();
  },
}
</script>

<style lang="scss" scoped>
/** 트리리스트 검색 패널 관련 */
::v-deep  #treeList .dx-toolbar .dx-toolbar-after {
  margin: 0 auto;
  padding-left: 0;
  left: 0;
  right: 0;
}

::v-deep #treeList .dx-placeholder {
  padding-left: 20px;
}

::v-deep #treeList .dx-treelist-search-panel {
  margin: 0;
}

::v-deep #treeList .clicked-row > td {
  background: #b3e5fc;
}
</style>