<template>
  <transition>
    <div ref="contentsWrap" class="container contents-wrap" id="contentsWrap">

      <div class="contents-box page-sub-box clearfix ui-glid-box ">
        <table class="table_form line-bin">
          <caption>
            <strong>인사 관리 일괄 등록</strong>
          </caption>
          <colgroup>
            <col style="width:60px;">
            <col style="width:auto;">
          </colgroup>

          <tbody>
          <tr>
            <th scope="row">
              <label for="label01">
                작업구분
              </label>
            </th>
            <td>
              <DxSelectBox
                placeholder="선택"
                :items="getWorkTypeCodes"
                display-expr="codeNm"
                value-expr="codeId"
                v-model="formData.workType"
                :styling-mode="config.stylingMode"
                :width="150"
                :height="30"
              >
              </DxSelectBox>
            </td>
          </tr>
          <tr>
            <th scope="row">
              <label for="label01">
                제목
              </label>
            </th>
            <td>
              <DxTextBox
                v-model="formData.title"
                :max-length="limitNumberTexts.maxLengths.title"
                :styling-mode="config.stylingMode"
                :width="620"
                :height="30"
                class="mar_ri10 alB"
              >
              </DxTextBox>
              {{
                limitNumberTexts.textLengths.title ? limitNumberTexts.textLengths.title : (formData.title ? formData.title.length : 0)
              }}/{{ limitNumberTexts.maxLengths.title }}자
            </td>
          </tr>
          <tr>
            <th scope="row">
              <label for="label01">
                설명
              </label>
            </th>
            <td>
              <DxTextArea
                v-model="formData.description"
                :max-length="limitNumberTexts.maxLengths.description"
                :styling-mode="config.stylingMode"
                :width="620"
                :height="100"
                class="mar_ri10 alB"
              >
              </DxTextArea>
              {{
                limitNumberTexts.textLengths.description ? limitNumberTexts.textLengths.description : (formData.description ? formData.description.length : 0)
              }}/{{ limitNumberTexts.maxLengths.description }}자
            </td>
          </tr>
          <tr>
            <th scope="row">
              <label for="label01">
                첨부파일
              </label>
            </th>
            <td>
              <div class="file-upload-box">
                <div id="dropzone-external" class="flex-box"
                     :class="[config.dragDrop.isDropZoneActive ? 'dx-theme-accent-as-border-color dropzone-active' : 'dx-theme-border-color']">
                  <div class="flex-box dropzone-contents">
                    <span class="dropzone-icon"><i class="mdi mdi-folder-open"></i></span>
                    <span id="dropzoneFile" class="dropzone-text underline-text">
                                                <template v-if="formData.file !== null">
                                                    {{ formData.file.name }}
                                                </template>
                                                <template v-else>
                                                    업로드 파일 드래그 하거나 파일을 불러오세요
                                                </template>
                                            </span>
                  </div>
                </div>
                <DxFileUploader
                  id="file-uploader"
                  dialog-trigger="#dropzone-external"
                  drop-zone="#dropzone-external"
                  :accept="config.dragDrop.accept"
                  :multiple="config.dragDrop.multiple"
                  :upload-mode="config.dragDrop.uploadMode"
                  upload-url="https://js.devexpress.com/Demos/NetCore/FileUploader/Upload"
                  :visible="false"
                  @drop-zone-enter="onDropZoneEnter"
                  @value-changed="onValueChangedFile"
                />
              </div>
            </td>
          </tr>
          </tbody>
        </table>

        <DxButton :text="`${getRegCode.codeNm} 양식 다운로드`" :width="140" :height="30" class="white filled txt_S medium"
                  id="downloadBtn" @click="onDownloadSampleForm"/>

        <div class="">

        </div>
      </div>

      <div class="container-bottom-box content-info-box01">
        데이터가 많을 경우 일괄 등록은 장 시간 소요되며, 처리 결과는 <span class="underline-text" @click="onMoveUploadHistory">일괄 업로드 이력</span>
        메뉴에서 확인하세요.
      </div>
    </div>
  </transition>
</template>

<script>
import {DxTextBox} from 'devextreme-vue/text-box';
import DxTextArea from 'devextreme-vue/text-area';
import {DxButton} from 'devextreme-vue/button';

import {DxSelectBox} from 'devextreme-vue/select-box';

// file drag & drop
import {DxFileUploader} from 'devextreme-vue/file-uploader';
import {isSuccess, downloadFile} from '@/plugins/common-lib';

export default {
  components: {
    DxTextBox,
    DxTextArea,
    DxButton,
    DxSelectBox,
    DxFileUploader,

  },
  props: {
    contentData: Object,
    iconData: Array,
  },
  watch: {},
  data() {
    return {
      config: {
        stylingMode: 'outlined',
        sampleForm: 'NTS.insa_sample_form.xlsx',    //샘플 양식
        //fileUpload
        dragDrop: {
          isDropZoneActive: false,
          textVisible: true,
          progressVisible: false,
          progressValue: 0,
          accept: 'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          multiple: false,
          uploadMode: 'instantly',
          allowedFileExtensions: ['.xlsx', '.xls'],
          maxFileSize: 10000000,
        },
      },
      formData: {
        workType: null,
        id: null,
        appUrl: '/v2/ecs/insa/upload',
        title: null,
        description: null,
        file: null,
        fileNm: null,
      },
      limitNumberTexts: {
        textLengths: {},
        maxLengths: {
          title: 50,
          description: 150,
        }
      },
    }
  },
  computed: {
    /** @description: props 리스트에서 선택한 데이터 */
    selectedRowsData() {
      return this.contentData.selectedRowsData;
    },
    /** @description: props 리스트에서 선택한 데이터 */
    getApiActionNm() {
      return this.contentData.apiActionNm;
    },
    /** @description: 작업구분 코드 리스트 */
    getWorkTypeCodes() {
      return this.$_getCode('work_type');
    },
    /** @description: 작업구분 등록 코드 */
    getRegCode() {
      return this.$_getCode('work_type').find(d => d.codeValue === 'reg');
    },
    /** @description: 작업구분 수정 코드 */
    getEditCode() {
      return this.$_getCode('work_type').find(d => d.codeValue === 'edit');
    },
  },
  methods: {
    /** @description: 기본 양식 다운로드 이벤트 */
    async onDownloadSampleForm(e) {
      let headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
      }

      let formData = new FormData();
      formData.append("subFilePathConfigNm", 'HR_SAMPLE_FORM_DIR');

      let payload = {
        actionName: 'FILE_DOWNLOAD',
        data: formData,
        path: '/' + this.config.sampleForm,
        loading: false,
        headers: headers,
        responseType: 'arraybuffer',
      }

      let res = await this.CALL_API(payload);
      if (isSuccess(res)) {
        downloadFile(res);
      }
    },
    /** @description : 일괄 업로드 이력 페이지로 이동 */
    onMoveUploadHistory() {
      return this.$_Msg(this.$_lang('CMN_PREPARING'));
    },
    /** @description : 파일업로드 드래그드롭 */
    onDropZoneEnter(e) {
      if (e.dropZoneElement.id === 'dropzone-external') {
        this.config.dragDrop.isDropZoneActive = true;
      }
    },
    /** @description: 파일 업로드시  */
    onValueChangedFile(e) {
      const file = e.value;
      if (file.length === 0) {
        return this.$_Msg(`${this.$_lang('DISALLOWED_FILE')}<br/>허용하는 파일 형식은 [${this.config.dragDrop.allowedFileExtensions.toString()}] 입니다.`);
      }

      if (file[0].size > this.config.dragDrop.maxFileSize) {
        return this.$_Msg(this.$_i18n.t('COMMON.OVER_SIZE_FILE', { size: "10MB" }));
      }

      this.formData.file = file[0];
    },
    /** @description: 파일 업로드 메서드 */
    async uploadFile() {
      let file = this.formData.file;
      let formData = new FormData();
      formData.append("file", file);
      formData.append("subFilePathConfigNm", 'HR_UPLOAD_DIR');
      let payload = {
        actionName: 'FILE_UPLOAD',
        data: formData,
        loading: false,
      }

      try {
        let res = await this.CALL_API(payload);
        if (isSuccess(res)) {
          this.formData.fileNm = res.data.pop();
        }
      } catch (error) {
        this.$log.debug(error)
      }
    },
    /** @description : 라이프사이클 creaed시 호출되는 메서드 */
    createdData() {
      this.formData.workType = this.getRegCode.codeId;
    },
    /** @description : 라이프사이클 mounted시 호출되는 메서드 */
    mountedData() {
      this.$_eventbus.$on('ModalAddUser:onSaveData', async (e, resolve) => {

        if (!this.formData.file) {
          return this.$_Msg(this.$_lang('REQUIRED_FILE_ADDITION'));
        }

        if (await this.$_Confirm('일괄 등록 실행 시, 작업을 취소할 수 없습니다.<br/>계속 하시겠습니까?')) {

          //파일 업로드
          await this.uploadFile();

          let data = {
            data: {
              bulkworkType: 'upload',     //API에서 분기 처리
              bulkwork: [this.formData],
            }
          }
          let payload = {
            actionName: 'USER_BULKWORK_LIST_INSERT',
            data: data,
            loading: false,
          }

          let res = await this.CALL_API(payload);
          if (isSuccess(res)) {
            resolve({status: 200, componentNm: 'ModalAddUser'});
          }
        }
      });
    },
    /** @description : 라이프사이클 destroyed시 호출되는 메서드 */
    destroyedData() {
      this.$_eventbus.$off('ModalAddUser:onSaveData');
    },
  },
  created() {
    this.createdData();
  },
  mounted() {
    this.mountedData();
  },
  destroyed() {
    this.destroyedData();
  },
}
</script>

<style scoped>
.page-sub-box {
  padding: 0 0;
}

.table_form td > div {
  display: inline-block;
  vertical-align: middle;
}

.table_form.line-bin th {
  padding: 20px 0;
}

.ui-glid-box > div.fl, .ui-glid-box > div.fr {
  border-right: 0;
}

.ui-glid-box .table_form tbody tr > th {
  min-width: 60px;
}

.contents-box .fl .sub_title_txt tr td {
  padding: 10px 0 10px 15px;
}

.contents-box .fr .sub_title_txt tr td {
  padding: 10px 0 10px 20px;
}

.contents-box #downloadBtn {
  position: absolute;
  top: 14px;
  left: 240px;
}

.contents-wrap .underline-text {
  text-decoration: underline;
  color: #2883f3;
  cursor: pointer;
}

.file-upload-box {
  width: 620px;
  cursor: pointer;
}

/* drag & drop css */
#dropzone-external {
  width: 100%;
  height: 200px;
  /* background-color: rgba(183, 183, 183, 0.1); */
  border-width: 1px;
  border-style: dashed;
  padding: 10px;
}

#dropzone-external > * {
  pointer-events: none;
}

#dropzone-external.dropzone-active {
  border-style: solid;
}

.widget-container > span {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 16px;
}

#dropzone-image {
  max-width: 100%;
  max-height: 100%;

}

.dropzone-contents > span {
  font-size: 14px;
  /* opacity: 0.5; */
}

.dropzone-contents .dropzone-icon > i {
  font-size: 30px;
  color: #50586e;
}

#upload-progress {
  display: flex;
  margin-top: 10px;
}

.flex-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content-info-box01 {
  margin-top: 10px;
}
</style>
