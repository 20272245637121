<template>
	<div class="container">
		<div class="page-sub-box ewm">
			<div class="locker_setting_list sub_new_style01 sub_ui_box1">
				<div class="page_search_box line_bottom_1px">
					<div class="inner alL">
						<div>
							기준년월
						</div>
						<div class="ui-datepicker-item">
							<DxDateBox
								styling-mode="outlined"
								:width="120"
								v-model="searchType.customTypes.frScheYmd"
								type="date"
								dateSerializationFormat="yyyyMMdd"
								display-format="yyyy.MM"
								date-out-of-range-message="시작일은 종료일보다 작거나 같아야 합니다."
								:max="searchType.customTypes.toScheYmd"
							>
							</DxDateBox>
						</div>
						<div class="ui-datepicker period">
							<span class="dash">~</span>
						</div>
						<div class="ui-datepicker-item">
							<DxDateBox
								styling-mode="outlined"
								:width="120"
								v-model="searchType.customTypes.toScheYmd"
								type="date"
								dateSerializationFormat="yyyyMMdd"
								display-format="yyyy.MM"
								date-out-of-range-message="종료일은 시작일보다 크거나 같아야 합니다."
								:min="searchType.customTypes.frScheYmd"
							>
							</DxDateBox>
						</div>

						<DxButton
							text="검색"
							class="btn_M box-btn-search"
							type="button"
							:height="30"
							@click="
								e => {
									if (isEmpty(searchType.customTypes.frScheYmd) ||
										isEmpty(searchType.customTypes.toScheYmd)) {
										this.$_Msg('기준년월 기간을 선택하세요');
										return;
									}
									selectEvalSummaryGridDataList();
									selectTrendTwelveMonthsDataList();
								}
							"
						/>
					</div>
				</div>
			</div>
			<div class="flex flex-col gap-y-6 divide-y">
				<div>
					<h3 class="text-xl font-medium mb-4">평가요약</h3>
					<div>
						<DxDataGrid
							key-expr="key"
							class="grid-box eval-grid"
							ref="evalScheduleGrid"
							:data-source="evalSummaryGridData.dataSource"
							:allow-column-resizing="true"
							:column-resizing-mode="'nextColumn'"
							:show-borders="false"
							:show-column-headers="true"
							:show-column-lines="true"
							:show-row-lines="true"
							:row-alternation-enabled="false"
							:hover-state-enabled="true"
							:word-wrap-enabled="true"
							:no-data-text="this.$_lang('CMN_NO_DATA')"
							width="100%"
							height="calc(100vh - 60%)"
						>
							<DxLoadPanel :enabled="true" />
							<DxScrolling
								mode="standard"
							/>
							<!-- <DxSelection
								mode="single"
								:allow-select-all="false"
								show-check-boxes-mode="always"
								select-all-mode="allPages"
							/> -->
							<DxColumn
								caption="평가구분"
								data-field="evalDivisionCd"
								alignment="center"
								:allowEditing="false"
								:allow-sorting="true"
							>
								<DxLookup
									:data-source="dataGridLoopup.evalDivisionCd"
									value-expr="codeId"
									display-expr="codeNm"
								/>
							</DxColumn>
							<!-- <template #evalDivisionCdTemplate="{ data }">
								<span>{{ evalSummaryGridData.dataSource != null && data.rowIndex != 0
									&& evalSummaryGridData.dataSource[data.rowIndex - 1].evalDivisionCd == data.data.evalDivisionCd ? ''
										: dataGridLoopup.evalDivisionCd.filter(z => data.data.evalDivisionCd == z.codeId)[0].codeNm }}</span>
							</template> -->
							<DxColumn
								caption="평가계획명"
								data-field="scheNm"
								:allowEditing="false"
								:allow-sorting="true"
							/>
							<DxColumn
								caption="평가표"
								data-field="sheetNm"
								:allowEditing="false"
								:allow-sorting="true"
							/>
							<DxColumn
								caption="전체 평균점수"
								data-field="allAvgScore"
								alignment="center"
								:allowEditing="false"
								:allow-sorting="true"
							/>
							<DxColumn
								caption="총점"
								data-field="totalSore"
								alignment="center"
								:allowEditing="false"
								:allow-sorting="true"
							/>
							<DxColumn
								caption="평가표 상위구성"
								data-field="questionParentGroup"
								alignment="center"
								:allowEditing="false"
								:allow-sorting="true"
							/>
							<DxColumn
								caption="평가표 하위구성"
								data-field="questionGroup"
								alignment="center"
								:allowEditing="false"
								:allow-sorting="true"
							/>
							<DxColumn
								caption="배점"
								data-field="questionScore"
								alignment="center"
								:allowEditing="false"
								:allow-sorting="true"
							/>
							<DxColumn
								caption="평가점수"
								data-field="score"
								alignment="center"
								:allowEditing="false"
								:allow-sorting="true"
							/>
						</DxDataGrid>
					</div>
				</div>
				<div>
					<h3 class="text-xl font-medium my-4">12개월간 평가점수 추이</h3>
					<div>
						<div class="mt-4">
							<DxDataGrid
								key-expr="key"
								class="grid-box eval-grid"
								ref="trendTwelveMonthsGrid"
								:data-source="trendTwelveMonthsGridData.dataSource"
								:allow-column-resizing="true"
								:column-resizing-mode="'nextColumn'"
								:show-borders="false"
								:show-column-headers="true"
								:show-column-lines="true"
								:show-row-lines="true"
								:row-alternation-enabled="false"
								:hover-state-enabled="true"
								:word-wrap-enabled="true"
								:no-data-text="this.$_lang('CMN_NO_DATA')"
								width="100%"
								height="150"
							>
								<DxLoadPanel :enabled="true" />
								<DxScrolling
									mode="standard"
								/>
								<!-- <DxSelection
									mode="single"
									:allow-select-all="false"
									show-check-boxes-mode="always"
									select-all-mode="allPages"
								/> -->
								<DxColumn
									caption="평가구분"
									data-field="evalDivisionCd"
									alignment="center"
									:allowEditing="false"
									:allow-sorting="true"
								>
									<DxLookup
										:data-source="dataGridLoopup.evalDivisionCd"
										value-expr="codeId"
										display-expr="codeNm"
									/>
								</DxColumn>
								<DxColumn v-for="index in 12" :key="index"
									:caption="getTrendTwelveMonthsGridCaption(index)"
									:data-field="`totalScore_${index}`"
									:allow-sorting="false"
									alignment="center"
									:calculate-display-value="rowData => rowData[`totalScore_${index}`] ?
										rowData[`totalScore_${index}`] : '-'"
								/>
							</DxDataGrid>
						</div>
					</div>
				</div>
				<div>
					<h3 class="text-xl font-medium my-4">12개월간 평가점수 추이(chart)</h3>
					<div>
						<div class="mt-4">
							<DxChart
								id="trendTwelveMonthsChart"
								:data-source="trendTwelveMonthsChartData.dataSource"
								>
								<DxCommonSeriesSettings
									type="line"
									argument-field="scheYm"
								/>
								<DxSeries
									v-for="(s, index) in trendTwelveMonthsChartData.series" :key="index"
									value-field="totalScore"
									:name="s.evalDivisionNm"
									:ignore-empty-points="true"
								>
									<DxLabel
										:visible="false"
									/>
									<DxPoint
										:size="10"
									/>
								</DxSeries>
								<DxMargin :bottom="0"/>
								<DxArgumentAxis
									:value-margins-enabled="false"
									discrete-axis-division-mode="crossLabels"
								>
									<DxGrid :visible="true" />
								</DxArgumentAxis>
								<DxLegend
									vertical-alignment="bottom"
									horizontal-alignment="center"
									item-text-position="bottom"
								/>
								<DxExport :enabled="false" />
								<DxTooltip :enabled="true" />
							</DxChart>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { DxButton } from 'devextreme-vue/button';
import { DxDateBox } from 'devextreme-vue/date-box';
import {
	DxColumn,
	DxDataGrid,
	DxLoadPanel,
	DxLookup,
	DxScrolling,
} from 'devextreme-vue/data-grid';
import {
	DxChart,
	DxSeries,
	DxArgumentAxis,
	DxCommonSeriesSettings,
	DxExport,
	DxGrid,
	DxLabel,
	DxMargin,
	DxLegend,
	DxPoint,
	DxTooltip,
} from 'devextreme-vue/chart';
import {cloneObj, getPastFromToday, isEmpty, isSuccess} from '@/plugins/common-lib';
import moment from "moment";

let vm = null;

export default {
	components: {
		DxButton,
		DxDateBox,

		DxColumn,
		DxDataGrid,
		DxLoadPanel,
		DxLookup,
		DxScrolling,

		DxChart,
		DxSeries,
		DxArgumentAxis,
		DxCommonSeriesSettings,
		DxExport,
		DxGrid,
		DxLabel,
		DxMargin,
		DxLegend,
		DxPoint,
		DxTooltip,

	},
	props: {},
	watch: {},
	data() {
		return {
			codeMap: {},
			dataGridLoopup: {
				evalDivisionCd: [],
			},
			config: {
				stylingMode: 'outlined', //[outlined, filled, underlined]
			},
			searchType: {
				obj: {},
				defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
				defaultValue: 'ROOT',
				customTypes: {
					frScheYmd: getPastFromToday(11, 'months'),
					toScheYmd: getPastFromToday(0, 'days'),
					isFeedback: false,
				},
				paramsData: {},
			},
			evalSummaryGridData: {
				dataSource: null,
				selectedRowsData: [],
				selectedRowKeys: [],
			},
			trendTwelveMonthsGridData: {
				dataSource: null,
				dataFieldIdx: {},
				trendTwelveMonthsGridColumns: []
			},
			trendTwelveMonthsChartData: {
				dataSource: null,
				series: [],
				trendMonths: [],
			},
		};
	},
	computed: {},
	methods: {
    isEmpty,
		/** @description : 초기 12개월간 평가점수 추이 그리드 캡션 설정 */
		getTrendTwelveMonthsGridCaption(index) {
			return moment(getPastFromToday(0, 'days')).subtract(12 - index, 'months').format('YYYY-MM');
		},
		/** @description : 평가요약 조회 */
		async selectEvalSummaryGridDataList() {
			vm.searchType.paramsData['frScheYmd'] = vm.searchType.customTypes.frScheYmd;
			vm.searchType.paramsData['toScheYmd'] = vm.searchType.customTypes.toScheYmd;

			const payload = {
				actionName: 'EWM_EVALUATION_PERSONAL_REPORT_EVAL_SUMMARY',
				data: vm.searchType.paramsData,
				loading: false,
			};

			const res = await vm.CALL_EWM_API(payload);
			if (isSuccess(res)) {
				this.evalSummaryGridData.dataSource = res.data.data;
			}
		},
		/** @description : 12개월간 평가점수 추이 조회 */
		async selectTrendTwelveMonthsDataList() {
			vm.searchType.paramsData['frScheYmd'] = moment(vm.searchType.customTypes.toScheYmd).subtract(11, 'months').format("YYYYMMDD");
			vm.searchType.paramsData['toScheYmd'] = vm.searchType.customTypes.toScheYmd;

			this.setTrendTwelveMonthsGridColumns(vm.searchType.customTypes.toScheYmd, 11);

			const payload = {
				actionName: 'EWM_EVALUATION_PERSONAL_REPORT_TREND_12M',
				data: vm.searchType.paramsData,
				loading: false,
			};

			const res = await vm.CALL_EWM_API(payload);
			if (isSuccess(res)) {
				const evalDivisionCds = [...new Set(res.data.data.map(z => z.evalDivisionCd))];

				const trendTwelveMonthsGridDataSource = evalDivisionCds.map((z, index) => {
					const rowData = {
						key: index + 1,
						evalDivisionCd: z,
						evalDivisionNm: this.dataGridLoopup.evalDivisionCd.find(el => el.codeId == z).codeNm
					};

					res.data.data.filter(el => el.evalDivisionCd == z)
						.forEach(e => {
							const dataFieldIdx = this.trendTwelveMonthsGridData.dataFieldIdx[e.scheYm];
							rowData[`totalScore_${dataFieldIdx}`] = e.totalScore;
							rowData['targetAgtId'] = e.targetAgtId;
					});

					return rowData;
				});

				this.trendTwelveMonthsGridData.dataSource = trendTwelveMonthsGridDataSource;

				this.trendTwelveMonthsChartData.series = trendTwelveMonthsGridDataSource;
				
				const chartDs = [];
				evalDivisionCds.forEach(z => {
					cloneObj(res.data.data);
					
					this.trendTwelveMonthsChartData.trendMonths.forEach(y => {
						
						const dataIndex = res.data.data.findIndex(re => re.evalDivisionCd == z && re.scheYm == y);
						
						chartDs.push({
							evalDivisionCd: z,
							evalDivisionNm: this.dataGridLoopup.evalDivisionCd.find(el => el.codeId == z).codeNm,
							scheYm: y,
							totalScore: dataIndex > -1 ? res.data.data[dataIndex].totalScore : null,
						});
					});
				});

				this.trendTwelveMonthsChartData.dataSource = chartDs;
			}
		},
		/** @description : 12개월 평가 추이 그리드 캡션 세팅 */
		setTrendTwelveMonthsGridColumns(toScheYmd, pastTrendMonths) {
			this.trendTwelveMonthsChartData.trendMonths = [];

			let count = 0;
			while(count <= pastTrendMonths) {
				const scheYmd = moment(toScheYmd).subtract(pastTrendMonths - count, 'months').format("yyyy-MM");
				this.trendTwelveMonthsGridData.dataFieldIdx[scheYmd] = count + 1;
				this.trendTwelveMonthsChartData.trendMonths.push(scheYmd);

				this.$refs.trendTwelveMonthsGrid.instance.columnOption(`totalScore_${count + 1}`, 'caption', scheYmd);

				count++;
			}
		},
		/** @description : 코드 로드 */
		async initCodeMap() {
			this.codeMap = await this.$_getCodeMapList('root_ewm_eval_division');
		},
		async createdData() {
			vm = this;

			await this.initCodeMap().then(() => {
				this.dataGridLoopup.evalDivisionCd = this.$_fetchCodesByDepth(this.codeMap['root_ewm_eval_division'], 2);
			});

			this.selectEvalSummaryGridDataList();
			this.selectTrendTwelveMonthsDataList();
		}
	},
	created() {
		this.createdData();
	},
	mounted() {},
};
</script>
<style lang="scss" scoped>
.page_search_box .inner div {
	display: inline-block;
}
.page_search_box .inner > div:not(.box-btn-search) {
	vertical-align: middle;
	margin-right: 10px;
}
.page_search_box .spec-type-box {
	display: inline-block;
}
.page_search_box .inner .box-btn-search {
	margin-right: 15px;
}
.page_search_box #searchTooltip {
	margin-right: 5px;
	font-size: 22px;
	color: #808080;
}
#trendTwelveMonthsChart {
	height: 300px;
}
</style>
