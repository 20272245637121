<!--
  PACKAGE_NAME : src\pages\esp\user\management\list.vue
  FILE_NAME : list
  AUTHOR : devyoon91
  DATE : 2024-08-29
  DESCRIPTION : 계정 관리
-->
<template>
  <div class="page-sub-box">
    <Tabs ref="tabs" @selectedIndex="tabSelectedIndex" :tabType="1" tab-css-class="tab-custom-css">
      <Tab title="활성계정 관리">
        <license-tool-tip ref="licenseToolTipActive" />
        <esp-dx-data-grid :data-grid="dataGrid.actAccount" :ref="dataGrid.actAccount.refName" />
      </Tab>
      <Tab title="잠금계정 관리">
        <license-tool-tip ref="licenseToolTipLocked" />
        <esp-dx-data-grid :data-grid="dataGrid.lckAccount" :ref="dataGrid.lckAccount.refName" />
      </Tab>
      <Tab title="휴면계정 관리">
        <license-tool-tip ref="licenseToolTipDormant" />
        <esp-dx-data-grid :data-grid="dataGrid.slpAccount" :ref="dataGrid.slpAccount.refName" />
      </Tab>
      <Tab title="삭제계정 관리">
        <license-tool-tip ref="licenseToolTipDeleted" />
        <esp-dx-data-grid :data-grid="dataGrid.delAccount" :ref="dataGrid.delAccount.refName" />
      </Tab>
    </Tabs>

    <DxPopup
      :show-title="true"
      :title="modal.initData ? modal.initData.title : null"
      :min-width="modal.initData ? modal.initData.width : null"
      :width="modal.initData ? modal.initData.width : null"
      :min-height="modal.initData ? modal.initData.height : null"
      :height="modal.initData ? modal.initData.height : null"
      :drag-enabled="true"
      :resize-enabled="true"
      :show-close-button="true"
      :hide-on-outside-click="false"
      :visible="modal.isOpened"
      @hiding="isOpenModal(false)"
    >
      <template #content>
        <div>
          <component :is="modal.currentComponent" :contentData="modal.contentData" v-model="modal.contentData"></component>
        </div>
      </template>

      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="
          modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('save')
              ? modal.initData.buttons.hasOwnProperty('save')
              : !modal.initData.buttons.hasOwnProperty('save')
            : false
        "
        :options="{
          elementAttr: {
            class: 'default filled txt_S medium',
          },
          text: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('save')
              ? modal.initData.buttons.save.text
              : ''
            : '',
          width: '120',
          height: '40',
          useSubmitBehavior: true,
          onClick: e => {
            onConfirmModal(e);
          },
        }"
      />
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="
          modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('cancel')
              ? modal.initData.buttons.hasOwnProperty('cancel')
              : !modal.initData.buttons.hasOwnProperty('cancel')
            : false
        "
        :options="{
          elementAttr: {
            class: 'white filled txt_S medium',
          },
          text: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('cancel')
              ? modal.initData.buttons.cancel.text
              : ''
            : '',
          width: '120',
          height: '40',
          onClick: () => {
            isOpenModal(false);
          },
        }"
      />
    </DxPopup>
  </div>
</template>

<script>
  import Tabs from '@/components/common/tabs.vue';
  import Tab from '@/components/common/tab.vue';
  import ModalActiveMember from '@/pages/esp/user/management/modal-active-member.vue';
  import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
  import { DxTextBox } from 'devextreme-vue/text-box';
  import { DxButton } from 'devextreme-vue/button';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { DxSwitch } from 'devextreme-vue/switch';
  import { DxPopover } from 'devextreme-vue/popover';
  import { isSuccess } from '@/plugins/common-lib';
  import LicenseToolTip from '@/components/common/license-tool-tip.vue';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid-v2.vue';

  export default {
    components: {
      EspDxDataGrid,
      LicenseToolTip,
      DxPopover,
      Tabs,
      Tab,
      ModalActiveMember,
      DxPopup,
      DxToolbarItem,
      DxTextBox,
      DxButton,
      DxSelectBox,
      DxSwitch,
    },
    data() {
      return {
        tabs: {
          selectedIndex: this.$store.getters.getTabHists[this.$router.currentRoute.path],
        },
        modal: {
          isOpened: false,
          currentComponent: null,
          initData: {},
          contentData: null,
        },
        dataGrid: {
          //활성계정
          actAccount: {
            refName: 'actAccountGrid',
            dataSource: [],
            showActionButtons: {
              select: false,
              update: false,
              delete: false,
              customButtons: [
                {
                  widget: 'dxButton',
                  options: {
                    icon: '',
                    text: '추가',
                    elementAttr: { class: 'btn_XS default filled add1' },
                    width: 60,
                    height: 30,
                    onClick: () => {
                      this.handleRouteAddMemberPage();
                    },
                  },
                  location: 'before',
                },
                {
                  widget: 'dxButton',
                  options: {
                    icon: '',
                    text: '삭제',
                    elementAttr: { class: 'btn_XS white light_filled trash' },
                    width: 60,
                    height: 30,
                    onClick: () => {
                      this.deleteMember();
                    },
                  },
                  location: 'before',
                },
              ],
            },
            headerFilter: {
              visible: false,
            },
            editing: {
              allowUpdating: false,
              allowDeleting: false,
            },
            columns: [
              {
                i18n: 'COMPONENTS.LOGIN_ID',
                caption: '아이디',
                dataField: 'loginId',
              },
              {
                i18n: 'COMPONENTS.NAME',
                caption: '이름',
                dataField: 'loginNm',
                cellTemplate: (container, options) => {
                  let aTag = document.createElement('a');
                  aTag.innerText = options.value;
                  aTag.addEventListener('click', () => {
                    this.handleRouteConfigPage(options.data);
                  });
                  container.append(aTag);
                },
              },
              {
                i18n: 'COMPONENTS.AUTH',
                caption: '권한',
                dataField: 'authNm',
                calculateCellValue: rowData => {
                  if (rowData.authNm === null) {
                    return null;
                  }
                  return rowData.authNm;
                },
              },
              {
                i18n: 'COMPONENTS.REGISTRANT',
                caption: '등록자',
                dataField: 'regId',
              },
              {
                i18n: 'COMPONENTS.REGISTRATION_DATE_TIME',
                dataType: 'date',
                dataField: 'regDt',
                format: 'yyyy-MM-dd HH:mm:ss',
                caption: '등록일시',
              },
              {
                i18n: 'COMPONENTS.MODIFIER',
                caption: '수정자',
                dataField: 'editId',
              },
              {
                i18n: 'COMPONENTS.MODIFY_DATE_TIME',
                caption: '수정 일시',
                dataType: 'date',
                dataField: 'editDt',
                format: 'yyyy-MM-dd HH:mm:ss',
              },
            ],
          },
          //잠금계정
          lckAccount: {
            refName: 'lckAccountGrid',
            dataSource: [],
            showActionButtons: {
              select: false,
              update: false,
              delete: false,
              customButtons: [
                {
                  widget: 'dxButton',
                  options: {
                    icon: '',
                    text: '계정 활성화',
                    elementAttr: { class: 'btn_XS white light_filled' },
                    height: 30,
                    onClick: () => {
                      this.handleOpenModalActiveMemberState();
                    },
                  },
                  location: 'before',
                },
                {
                  widget: 'dxButton',
                  options: {
                    icon: '',
                    text: '삭제',
                    elementAttr: { class: 'btn_XS white light_filled trash' },
                    width: 60,
                    height: 30,
                    onClick: () => {
                      this.deleteMember();
                    },
                  },
                  location: 'before',
                },
              ],
            },
            headerFilter: {
              visible: false,
            },
            editing: {
              allowUpdating: false,
              allowDeleting: false,
            },
            columns: [
              {
                i18n: 'COMPONENTS.LOGIN_ID',
                caption: '아이디',
                dataField: 'loginId',
              },
              {
                i18n: 'COMPONENTS.NAME',
                caption: '이름',
                dataField: 'loginNm',
              },
              {
                i18n: 'COMPONENTS.AUTH',
                caption: '권한',
                dataField: 'authNm',
                calculateCellValue: rowData => {
                  if (rowData.authNm === null) {
                    return null;
                  }
                  return rowData.authNm;
                },
              },
              {
                i18n: 'COMPONENTS.REGISTRANT',
                caption: '등록자',
                dataField: 'regId',
              },
              {
                i18n: 'COMPONENTS.REGISTRATION_DATE_TIME',
                dataType: 'date',
                dataField: 'regDt',
                format: 'yyyy-MM-dd HH:mm:ss',
                caption: '등록일시',
              },
              {
                i18n: 'COMPONENTS.MODIFIER',
                caption: '수정자',
                dataField: 'editId',
              },
              {
                i18n: 'COMPONENTS.MODIFY_DATE_TIME',
                caption: '수정 일시',
                dataType: 'date',
                dataField: 'editDt',
                format: 'yyyy-MM-dd HH:mm:ss',
              },
            ],
          },
          //휴면계정
          slpAccount: {
            refName: 'slpAccountGrid',
            dataSource: [],
            showActionButtons: {
              select: false,
              update: false,
              delete: false,
              customButtons: [
                {
                  widget: 'dxButton',
                  options: {
                    icon: '',
                    text: '계정 활성화',
                    elementAttr: { class: 'btn_XS white light_filled' },
                    height: 30,
                    onClick: () => {
                      this.saveActiveMemberState();
                    },
                  },
                  location: 'before',
                },
                {
                  widget: 'dxButton',
                  options: {
                    icon: '',
                    text: '삭제',
                    elementAttr: { class: 'btn_XS white light_filled trash' },
                    width: 60,
                    height: 30,
                    onClick: () => {
                      this.deleteMember();
                    },
                  },
                  location: 'before',
                },
              ],
            },
            headerFilter: {
              visible: false,
            },
            editing: {
              allowUpdating: false,
              allowDeleting: false,
            },
            columns: [
              {
                i18n: 'COMPONENTS.LOGIN_ID',
                caption: '아이디',
                dataField: 'loginId',
              },
              {
                i18n: 'COMPONENTS.NAME',
                caption: '이름',
                dataField: 'loginNm',
              },
              {
                i18n: 'COMPONENTS.AUTH',
                caption: '권한',
                dataField: 'authNm',
                calculateCellValue: rowData => {
                  if (rowData.authNm === null) {
                    return null;
                  }
                  return rowData.authNm;
                },
              },
              {
                i18n: 'COMPONENTS.REGISTRANT',
                caption: '등록자',
                dataField: 'regId',
              },
              {
                i18n: 'COMPONENTS.REGISTRATION_DATE_TIME',
                dataType: 'date',
                dataField: 'regDt',
                format: 'yyyy-MM-dd HH:mm:ss',
                caption: '등록일시',
              },
              {
                i18n: 'COMPONENTS.MODIFIER',
                caption: '수정자',
                dataField: 'editId',
              },
              {
                i18n: 'COMPONENTS.MODIFY_DATE_TIME',
                caption: '수정 일시',
                dataType: 'date',
                dataField: 'editDt',
                format: 'yyyy-MM-dd HH:mm:ss',
              },
            ],
          },
          //삭제계정
          delAccount: {
            refName: 'delAccountGrid',
            dataSource: [],
            showActionButtons: {
              select: false,
              update: false,
              delete: false,
              customButtons: [
                {
                  widget: 'dxButton',
                  options: {
                    icon: '',
                    text: '계정 활성화',
                    elementAttr: { class: 'btn_XS white light_filled' },
                    height: 30,
                    onClick: () => {
                      this.saveActiveMemberState();
                    },
                  },
                  location: 'before',
                },
              ],
            },
            headerFilter: {
              visible: false,
            },
            editing: {
              allowUpdating: false,
              allowDeleting: false,
            },
            columns: [
              {
                i18n: 'COMPONENTS.LOGIN_ID',
                caption: '아이디',
                dataField: 'loginId',
              },
              {
                i18n: 'COMPONENTS.NAME',
                caption: '이름',
                dataField: 'loginNm',
              },
              {
                i18n: 'COMPONENTS.AUTH',
                caption: '권한',
                dataField: 'authNm',
                calculateCellValue: rowData => {
                  if (rowData.authNm === null) {
                    return null;
                  }
                  return rowData.authNm;
                },
              },
              {
                i18n: 'COMPONENTS.REGISTRANT',
                caption: '등록자',
                dataField: 'regId',
              },
              {
                i18n: 'COMPONENTS.REGISTRATION_DATE_TIME',
                dataType: 'date',
                dataField: 'regDt',
                format: 'yyyy-MM-dd HH:mm:ss',
                caption: '등록일시',
              },
              {
                i18n: 'COMPONENTS.MODIFIER',
                caption: '수정자',
                dataField: 'editId',
              },
              {
                i18n: 'COMPONENTS.MODIFY_DATE_TIME',
                caption: '수정 일시',
                dataType: 'date',
                dataField: 'editDt',
                format: 'yyyy-MM-dd HH:mm:ss',
              },
            ],
          },
        },
      };
    },
    methods: {
      /**
       * @description: 선택된 탭에 따른 데이터 가져오기
       * @return {*|*[]}
       */
      getSelectedRowsDataByTab() {
        if (this.tabs.selectedIndex === 0) {
          // 활성계정 관리
          return this.$refs.actAccountGrid.getInstance.getSelectedRowsData();
        } else if (this.tabs.selectedIndex === 1) {
          // 잠금계정 관리
          return this.$refs.lckAccountGrid.getInstance.getSelectedRowsData();
        } else if (this.tabs.selectedIndex === 2) {
          // 휴면계정 관리
          return this.$refs.slpAccountGrid.getInstance.getSelectedRowsData();
        } else if (this.tabs.selectedIndex === 3) {
          // 삭제계정 관리
          return this.$refs.delAccountGrid.getInstance.getSelectedRowsData();
        }
        return [];
      },
      /** @description: 선택된 탭 index */
      async tabSelectedIndex(index) {
        this.tabs.selectedIndex = index;
        await this.setLicenseToolTipResetCount(); // 라이센스 툴팁 초기화
        if (this.tabs.selectedIndex === 0) {
          this.dataGrid.actAccount.dataSource = this.makeDataSource(
            `${this.$_enums.common.memberState.ACTIVE.value},${this.$_enums.common.memberState.TEMPORARY.value}`,
            '-regDt',
          );
        } else if (this.tabs.selectedIndex === 1) {
          this.dataGrid.lckAccount.dataSource = this.makeDataSource(this.$_enums.common.memberState.LOCKED.value, '-editDt');
        } else if (this.tabs.selectedIndex === 2) {
          this.dataGrid.slpAccount.dataSource = this.makeDataSource(this.$_enums.common.memberState.DORMANT.value, '-editDt');
        } else if (this.tabs.selectedIndex === 3) {
          this.dataGrid.delAccount.dataSource = this.makeDataSource(this.$_enums.common.memberState.DELETED.value, '-editDt');
        }
      },
      /** @description: 팝업 오픈시 메서드 */
      onOpenModal(componentNm, componentInitData, data) {
        this.modal.currentComponent = componentNm;
        this.modal.initData = componentInitData;
        this.modal.contentData = data;
        this.isOpenModal(true);
      },
      /** @description: 팝업 오픈 체크 메서드 */
      isOpenModal(data) {
        this.modal.isOpened = data;
        if (!data) {
          this.modal.currentComponent = null;
          this.modal.initData = {};
        }
      },
      /** @description: 팝업 확인 버튼 이벤트 */
      async onConfirmModal(e) {
        //해당 모달 컴포넌트에서 데이터 저장
        let promise = new Promise((resolve, reject) => {
          this.$_eventbus.$emit(`${this.modal.currentComponent}:onSaveData`, e, resolve, reject);
        });

        promise
          .then(res => {
            // use res
            if (res === 200) {
              this.isOpenModal(false);
              this.refreshMemberGridList();
            } else {
              this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
            }
          })
          .catch(() => {
            this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          });
      },
      /** @description" 계정 리스트 grid refresh 이벤트 */
      async refreshMemberGridList() {
        if (this.tabs.selectedIndex === 0) {
          // 활성계정 관리
          this.$refs.actAccountGrid.refreshData();
          this.$refs.actAccountGrid.getInstance.deselectAll(); //선택 해제
        } else if (this.tabs.selectedIndex === 1) {
          // 잠금계정 관리
          this.$refs.lckAccountGrid.refreshData();
          this.$refs.lckAccountGrid.getInstance.deselectAll(); //선택 해제
        } else if (this.tabs.selectedIndex === 2) {
          // 휴면계정 관리
          this.$refs.slpAccountGrid.refreshData();
          this.$refs.slpAccountGrid.getInstance.deselectAll(); //선택 해제
        } else if (this.tabs.selectedIndex === 3) {
          // 삭제계정 관리
          this.$refs.delAccountGrid.refreshData();
          this.$refs.delAccountGrid.getInstance.deselectAll(); //선택 해제
        }
        //유저 라이센스 개수 가져오기
        this.memberLicenseCnt = await this.$_getMemberLicenseCnt();
      },
      /** @description: 활성계정관리 리스트 조회 API 호출 메서드 */
      makeDataSource(memberStateCd, sortStr) {
        const vm = this;
        return new CustomStore({
          key: 'id',
          async load(loadOptions) {
            const params = vm.$_getDxDataGridParam(loadOptions);
            const payload = {
              actionName: 'MEMBER_LIST_ALL',
              data: {
                ...params,
                sort: sortStr,
                memberStateCd: memberStateCd,
              },
              loading: false,
            };

            const res = await vm.CALL_API(payload);
            if (isSuccess(res)) {
              const data = res.data.data;
              return {
                data: data,
                totalCount: res.data.header.totalCount,
              };
            }
          },
        });
      },
      /** @description: 계정 활성화 일괄 변경 이벤트  */
      async saveActiveMemberState() {
        const selectRows = this.getSelectedRowsDataByTab();

        if (selectRows.length === 0) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }

        //계정 라이센스 수 체크
        if (!(await this.validateLeftLicense(selectRows.length))) {
          return;
        }

        if (
          await this.$_Confirm(
            this.$_lang('COMMON.MESSAGE.ACTIVATE_SELECTED_ACCOUNT', { defaultValue: '선택한 계정을 활성화하시겠습니까?' }),
          )
        ) {
          await this.updateActiveState();
        }
      },
      /** @description: 계정 상태 "활성"으로 변경 */
      async updateActiveState() {
        const payload = {
          actionName: 'MEMBER_STATE_ACTIVATION',
          data: this.getSelectedRowsDataByTab().map(item => {
            return {
              memberId: item.id,
              loginPwd: '',
            };
          }),
        };

        const res = await this.CALL_API(payload);
        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('CMN_SUC_UPDATE', { defaultValue: '정상적으로 변경되었습니다.' }));
          await this.setLicenseToolTipResetCount();
          await this.refreshMemberGridList();
        }
      },
      /** @description: 잠금계정관리 계정 활성화 메서드 */
      async handleOpenModalActiveMemberState() {
        const selectRows = this.getSelectedRowsDataByTab();
        if (selectRows.length === 0) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }

        // 계정 라이센스 수 체크
        if (!(await this.validateLeftLicense(selectRows.length))) {
          return;
        }

        this.onOpenModal(
          'ModalActiveMember',
          {
            title: '계정 활성화',
            buttons: {
              save: { text: '확인' },
              cancel: { text: '취소' },
            },
            width: 550,
            height: 450,
          },
          {
            selectedRowsData: this.getSelectedRowsDataByTab(),
          },
        );
      },
      /** @description: 계정관리 삭제 메서드 */
      async deleteMember() {
        const selectRows = this.getSelectedRowsDataByTab();
        if (selectRows.length === 0) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }

        if (await this.$_Confirm(this.$_lang('CMN_CFM_DELETE_SELECTED', { defaultValue: '선택한 데이터를 삭제하시겠습니까?' }))) {
          const params = selectRows.map(item => {
            return {
              id: item.id,
              authId: item.authId,
              loginId: item.loginId,
              loginNm: item.loginNm,
              loginPwd: item.loginPwd,
              memberState: this.$_enums.common.memberState.DELETED.value,
            };
          });

          const payload = {
            actionName: 'MEMBER_LIST_INSERT',
            data: params,
          };

          const res = await this.CALL_API(payload);
          if (isSuccess(res)) {
            this.$_Toast(this.$_lang('CMN_SUC_DELETE', { defaultValue: '선택한 데이터가 삭제되었습니다.' }));
            await this.setLicenseToolTipResetCount();
            await this.refreshMemberGridList();
          }
        }
      },
      /** @description : 계정 추가 페이지 이동 */
      async handleRouteAddMemberPage() {
        // 라이센스 체크
        if (!(await this.validateLeftLicense(this.getSelectedRowsDataByTab().length))) {
          return;
        }

        await this.$router.push({ path: '/esp/user/management/config', query: { isUpdateMember: false } });
      },
      /** @description : 계정 수정 페이지 이동 */
      handleRouteConfigPage(data) {
        this.$router.push({
          path: '/esp/user/management/config',
          query: { id: data.id, isUpdateMember: true },
        });
      },
      /**
       * @description 남은 라이센스 개수
       * @param requestCount
       * @return {Promise<boolean>}
       */
      async validateLeftLicense(requestCount) {
        const leftLicenseCnt = await this.getLeftLicenseCnt();
        if (leftLicenseCnt === 0 || requestCount > leftLicenseCnt) {
          this.$_Msg(
            this.$_lang('COMMON.MESSAGE.OVER_LICENSE_COUNT', {
              defaultValue: '등록 가능한 라이센스 수를 초과하여<br/>계정을 더 이상 등록할 수 없습니다.',
            }),
          );
          return false;
        }
        return true;
      },
      /**
       * @description 라이센스 툴팁 초기화
       */
      async setLicenseToolTipResetCount() {
        if (this.tabs.selectedIndex === 0) {
          // 활성계정 관리
          await this.$refs.licenseToolTipActive.initLicenseCnt();
        } else if (this.tabs.selectedIndex === 1) {
          // 잠금계정 관리
          await this.$refs.licenseToolTipLocked.initLicenseCnt();
        } else if (this.tabs.selectedIndex === 2) {
          // 휴면계정 관리
          await this.$refs.licenseToolTipDormant.initLicenseCnt();
        } else if (this.tabs.selectedIndex === 3) {
          // 삭제계정 관리
          await this.$refs.licenseToolTipDeleted.initLicenseCnt();
        }
      },
      /**
       * @description 남은 라이센스 개수
       * @return {Promise<void>}
       */
      async getLeftLicenseCnt() {
        if (this.tabs.selectedIndex === 0) {
          // 활성계정 관리
          return await this.$refs.licenseToolTipActive.getLeftLicenseCnt();
        } else if (this.tabs.selectedIndex === 1) {
          // 잠금계정 관리
          return await this.$refs.licenseToolTipLocked.getLeftLicenseCnt();
        } else if (this.tabs.selectedIndex === 2) {
          // 휴면계정 관리
          return await this.$refs.licenseToolTipDormant.getLeftLicenseCnt();
        } else if (this.tabs.selectedIndex === 3) {
          // 삭제계정 관리
          return await this.$refs.licenseToolTipDeleted.getLeftLicenseCnt();
        }
      },
    },
    created() {},
    mounted() {},
  };
</script>

<style scoped>
  .tab-custom-css {
    padding: 0;
  }
</style>
