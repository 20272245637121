export default {
  MESSAGE: {
    APPROVED: 'Approval has been processed.',
    UNSAVED: 'There is unsaved data.',
    CHECK_PICKUP_DEPT: 'Name Must Start With `PG-D_`.',
    CHECK_PICKUP_ETC: 'Name Must Start With `PG-P_`.',
    CHECK_ADD_COUNT: 'Adding Data is only possible up to 10.',
    CHECK_EXCHANGER_TYPE: 'Only One Exchanger Type Must Be Entered.',
    START_SYNC: 'Do You Want To Synchronize DB Data?',
    CHANGE_LIST_ORDER: 'The HuntList Order Changes As Well.',
    REQUIRED_SELECT_HUNTPILOT: 'Please select a HuntPilot first.',
    REQUIRED_SELECT_HUNTLIST: 'Please select a HuntList first.',
    REQUIRED_SELECT_LINEGROUP: 'Please select a LineGroup first.',
    PILOT_LIST_ONE_SELECT: 'Please select only one target HuntPilot and HuntList.',
    PILOT_LIST_MISS_MATCH: 'This HuntList does not belong to the HuntPilot',
    DESCRIPTION_ENTER: 'Please Enter A Description.',
    NUMBER_ENTER: 'Please Enter Only Numbers.',
    CHANGED_DATA: 'There Is Changed Data.',
    REQUIRED_EXCHANGER_CHECK: 'Please Exchanger Check First.',
    ENCRYPTED_FILE_UPLOAD_NOT_ALLOWED: 'Encrypted Files Cannot Be Uploaded.',
    DN_ENTER: 'Please Enter Dial Number.',
    DEVICE_NAME_ENTER: 'Please Enter Device Name.',
    CHECK_MAC_ADDR: 'MAC Address Cannot Be Duplicated.',
    NO_PERSON_INFO: 'There is no personnel information for the logged in user.',
    REQUIRED_TITLE: 'Please Enter A Title.',
    REQUIRED_APPROVER: 'Please Select An Approver.',
    REQUIRED_REQUEST_ITEMS: 'Please Enter Request Items.',
    PLACEHOLDER_EXISTENCE_CONFIRMATION_QUERY: 'Please Enter Existence Confirmation SQL.',
    PLACEHOLDER_DELETE_QUERY: 'Please Enter Delete SQL.',
    PLACEHOLDER_FILE_DELETE_DEFAULT_PATH: 'Default path when deleting a file (after /home/ecss/api/file, Ex: /report/excel)',
    MAIN_SETTING_MENU_TYPE_ERROR: '',
  },
  WORD: {
    DUE_DATE: 'Due Date',
    USER_NO: 'Employee Number',
    REQUESTER: 'Requester',
    APPROVER: 'Approver',
    APPROVAL_DATE: 'Approval Date',
    APPROVAL_FL: 'Approval Status',
    WORKER: 'Worker',
    REQUESTER_INFO: 'Requester Information',
    USER_SELECT: 'Select User',
    APPROVAL_SELECT: 'Select Approver',
    WORKER_SELECT: 'Select Worker',
    APPLY_TARGET: 'Apply Target',
    TYPE_MANAGEMENT: 'Type Management',
    DETAILED_TYPE: 'Detailed Type',
    DETAILED_TYPE_MANAGEMENT: 'Detailed Type Management',
    DETAILED_TYPE_COUNT: 'Number of Detailed Types',
    TYPE: 'Type',
    TYPE_NAME: 'Type Name',
    REQUEST_TYPE: 'Request Type',
    AFTER_LINE: 'After Line',
    DEPT_ATTRIBUTES: 'Department Attributes',
    BLOCKING_POLICY: 'Blocking Policy',
    EXCEPTION_POLICY: 'Exception Policy',
    EXCEPTION_SETTING: 'Exception Setting',
    ALLOW: 'Allow',
    BLOCK: 'Block',
    EXCHANGER: 'Exchanger',
    DEPT_NAME: 'Dept Name',
    DEPT_CODE: 'Dept Code',
    PATTERN: 'Pattern',
    PARTITION: 'Partition',
    DEPT_NAME_SEARCH: 'Department Name Search',
    UNUSED: 'Unused',
    VIEW_DEPT_USE: 'View Only Departments In Use',
    CREATE_DEPT_PICKUP: 'Create PickupGroup By Department',
    CREATE_ETC_PICKUP: 'Create PickupGroup By Separately',
    DEPT_PICKUP_INFO: 'PickupGroup Information By Department',
    CREATE_DEPT: 'Create By Department',
    PICKUP_MANAGE: 'PickupGroup Management',
    REQUESTER_SELECT: 'Requester Select',
    SENDER: 'Sender',
    SENDER_NUMBER: 'Sender Number',
    SENDER_DEPT: 'Sender Department',
    RECEIVER: 'Receiver',
    RECEIVER_NUMBER: 'Receiver Number',
    RECEIVER_DEPT: 'Receiver Department',
    BLOCK_DATE: 'Block Date',
    BLOCK_END_DATE: 'Block End Date',
    BLACK_LIST: 'Black List',
    BLACK_LIST_HISTORY: 'Black List History',
    INDEFINITE: 'Indefinite',
    PROGRESS_STATUS: 'Progress Status',
    DISTRIBUTIONALGORITHM: 'Distribution Algorithm',
    RNAREVERSIONTIMEOUT: 'Rna Reversion Timeout',
    HUNTGROUP_SYNC: 'Huntgroup Sync',
    DEVICE_SYNC: 'Device Sync',
    LINE_SYNC: 'Line Sync',
    LINEGROUPDN_SET: 'LineGroupDn Set',
    PILOT_LIST_SET: 'Pilot/List Add',
    LIST_LINEGROUP_SET: 'List/LineGroup Set',
    LINEGROUP_ADD: 'LineGroup Add',
    CHECK: 'Check',
    HUNTPILOT: 'HuntPilot',
    HUNTLIST: 'HuntList',
    LINEGROUP: 'LineGroup',
    LINEGROUPDN: 'LineGroupDn',
    SAVE_ORDER: 'Save Order',
    TOTAL: 'Total',
    COUNT: '',
    ALL_LIST: 'All List',
    PREPAGE: 'Pre Page',
    MODEL: 'Model',
    STATUS: 'Status',
    DEVICE_INFO: 'Device Info ',
    DEVICE_INFO_DETAIL: 'Device Info Details',
    LINE_INFO: 'Line Info',
    DEVICE_NAME: 'Device Name',
    DEVICEPOOL: 'DevicePool',
    SOFTKEY: 'SoftKey',
    NUMBER: 'Number',
    LABEL: 'Label',
    SERIAL: 'Serial',
    PHONEBUTTON: 'Button',
    APPLY: 'Apply',
    EXPANSION_MODULE: 'Expansion Module',
    EXPANSION_MODULE_SET: 'Expansion Module Set',
    PICKUPGROUP_SET: 'PickupGroup Set',
    LINE_SET: 'Line Set',
    CSS: 'Css',
    FORWARDNO: 'Forward Number',
    PICKUPGROUP: 'PickupGroup Name',
    REC: 'Rec',
    REC_PERMISSION: 'Rec Permission',
    EXCEL_DOWNLOAD: 'Excel Download',
    EXCEL_UPLOAD: 'Excel Upload',
    EXCHANGER_SELECT: 'Exchanger Select',
    EXCHANGER_CHECK: 'Exchanger Check',
    EXCHANGER_APPLY: 'Exchanger Apply',
    INDEX: 'Index',
    LINE_ADD: 'Line Add',
    TARGET_DEVICE: 'Target Device',
    NEW_DEVICE: 'New Device',
    CONTACT: 'Contact',
    ALERTINGNAME: 'Alerting Name',
    EXTERNALCALLCONTROLPROFILE: 'External Call Control Profile',
    DISPLAY: 'Display',
    DISPLAY_NAME: 'Display Name',
    FIRST_NAME: 'First Name',
    MIDDLE_NAME: 'Middle Name',
    LASG_NAME: 'Last Name',
    CSF: 'PC JABBER',
    BOT: 'Android',
    TCT: 'IPhone',
    TAB: 'Tablet',
    CHECK_EXISTING_DEVICE: 'Delete The Existing Device',
    GROUP_NUMBER: 'Group Number',
    REG_NUMBER: 'Registered Number',
    LICENSE_STATUS: 'License Status',
    LICENSE_HISTORY: 'License History',
    LICENSE: 'License',
    APUSRASSIGNEDMODEL: 'Apusr Assigned Model',
    SMARTASSIGNEDMODEL: 'Smart Assigned Model',
    MODEL_SYNC: 'Model Sync',
    LICENSE_SYNC: 'License Sync',
    SYNC: 'Synchronization',
    CALL_MANAGER_GROUP: 'Call Manager Group',
    REGION: 'Region',
    DATETIME_SETTING: 'Date Time Setting',
    CREATE: 'Create',
    WORK_ID: 'Work ID',
    RECENT_WORK_HISTORY: 'Recent Work History',
    EXCEL_FILE: 'Excel File',
    OTHERS_FILE: 'Others File',
    ASCII_ALERTINGNAME: 'Ascii Alerting Name',
    HUNTINFO: 'Hunt Infomation',
    PILOT_DESCRIPTION: 'Pilot Description',
    LIST_DESCRIPTION: 'List Description',
    AUTOMATION_MANAGEMENT: 'Automation Management',
    AUTOMATION_SETTING: 'Automation Setting',
    AUTOMATION_CODE: 'Automation Code',
    TRANSFORM_NUMBER: 'Transform Number',
    EXISTENCE_CONFIRMATION_QUERY: 'Existence Confirmation Query',
    DELETE_QUERY: 'Delete Query',
    FILE_DELETE_FLAG: 'File Delete Flag',
    STORAGE_PERIOD: 'Storage Period (Month)',
    DATA_MANAGEMENT_HISTORY: 'Data Management History',
    HOT_LINE: 'Hot Line',
    MEMO: 'Memo',
    START_DATE: 'Start Date',
    END_DATE: 'End Date',
    FORWARD_NUMBER: 'Forward Number',
    RESET: 'Reset',
  },
};
