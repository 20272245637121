<!--
  PACKAGE_NAME : src/pages/euc/data-management
  FILE_NAME : index.vue
  AUTHOR : jhcho
  DATE : 24. 7. 23.
  DESCRIPTION :
-->
<template>
  <div class="page-sub-box">
    <div class="locker_setting_list sub_new_style01 sub_ui_box1">
      <Search
        codeKey="search_type_faq"
        :searchObj="searchType.obj"
        :useKeywordType="true"
        @change-search-type="$_changeSearchType"
        @onSearchClick="$_searchData($event, 'search_type_faq')"
      />
    </div>

    <esp-dx-data-grid :data-grid="dataGrid" ref="cleanGrid" />
    <DxPopup
      v-if="modal.isOpened"
      :show-title="true"
      :title="modal.initData ? modal.initData.title : null"
      :width="modal.initData ? modal.initData.width : null"
      :height="modal.initData ? modal.initData.height : null"
      :drag-enabled="true"
      :resize-enabled="true"
      :show-close-button="true"
      :hide-on-outside-click="false"
      :visible="modal.isOpened"
      @hiding="isOpenModal(false)"
    >
      <template #content>
        <div>
          <transition>
            <div class="page-sub-box">
              <table class="table_form line-bin">
                <colgroup>
                  <col style="width: 150px" />
                  <col style="width: auto" />
                  <col style="width: 150px" />
                  <col style="width: auto" />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row" class="alT">
                      <label>{{ $_lang('COMPONENTS.TITLE', { defaultValue: '제목' }) }} *</label>
                    </th>
                    <td colspan="3">
                      <DxTextBox class="mr-20" v-model="modal.contentData.title" :show-clear-button="true" :styling-mode="stylingMode" />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" class="alT">
                      <label>{{ $_lang('UC.WORD.EXISTENCE_CONFIRMATION_QUERY', { defaultValue: '존재 확인 질의문' }) }} *</label>
                    </th>
                    <td colspan="3">
                      <DxTextArea
                        v-model="modal.contentData.isSql"
                        :placeholder="
                          $_lang('UC.MESSAGE.PLACEHOLDER_EXISTENCE_CONFIRMATION_QUERY', {
                            defaultValue: '존재 확인 질의문을 입력해 주세요.',
                          })
                        "
                        :styling-mode="stylingMode"
                        class="mar_ri10 alB"
                        :height="200"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" class="alT">
                      <label>{{ $_lang('UC.WORD.DELETE_QUERY', { defaultValue: '삭제 질의문' }) }} *</label>
                    </th>
                    <td colspan="3">
                      <DxTextArea
                        v-model="modal.contentData.cleanSql"
                        :placeholder="$_lang('UC.MESSAGE.PLACEHOLDER_DELETE_QUERY', { defaultValue: '삭제 SQL을 입력해 주세요.' })"
                        :styling-mode="stylingMode"
                        class="mar_ri10 alB"
                        :height="200"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" class="alT">
                      <label>{{ $_lang('UC.WORD.FILE_DELETE_FLAG', { defaultValue: '파일 삭제 유무' }) }}</label>
                    </th>
                    <td>
                      <DxSelectBox
                        :placeholder="$_lang('UC.WORD.FILE_DELETE_FLAG', { defaultValue: '파일 삭제 유무' })"
                        :items="getStringUsedFlag"
                        display-expr="label"
                        value-expr="value"
                        v-model="modal.contentData.fileFl"
                        :styling-mode="stylingMode"
                      />
                    </td>
                    <th scope="row" class="alT">
                      <label>{{ $_lang('COMPONENTS.PATH', { defaultValue: '경로' }) }}</label>
                    </th>
                    <td>
                      <DxTextBox
                        :placeholder="
                          $_lang('UC.MESSAGE.PLACEHOLDER_FILE_DELETE_DEFAULT_PATH', {
                            defaultValue: '파일 삭제시 기본 패스( /home/ecss/api/file 이후, Ex : /report/excel )',
                          })
                        "
                        v-model="modal.contentData.path"
                        :styling-mode="stylingMode"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" class="alT">
                      <label>{{ $_lang('COMPONENTS.ORDER', { defaultValue: '순서' }) }} *</label>
                    </th>
                    <td>
                      <DxNumberBox
                        class="mr-20"
                        v-model="modal.contentData.cleanOrd"
                        :show-clear-button="true"
                        :styling-mode="stylingMode"
                        :min="1"
                      />
                    </td>
                    <th scope="row" class="alT">
                      <label>{{ $_lang('UC.WORD.STORAGE_PERIOD', { defaultValue: '보관기간 (개월)' }) }}</label>
                    </th>
                    <td>
                      <DxNumberBox class="mr-20" v-model="modal.contentData.month" :show-clear-button="true" :styling-mode="stylingMode" />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" class="alT">
                      <label>{{ $_lang('COMPONENTS.USE_STATUS', { defaultValue: '사용여부' }) }} *</label>
                    </th>
                    <td>
                      <DxSelectBox
                        placeholder="사용여부"
                        :items="getStringUsedFlag"
                        display-expr="label"
                        value-expr="value"
                        v-model="modal.contentData.viewFl"
                        :styling-mode="stylingMode"
                        :width="200"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </transition>
        </div>
      </template>

      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :options="{
          elementAttr: {
            class: 'default filled txt_S medium',
          },
          text: $_lang('COMPONENTS.SAVE', { defaultValue: '저장' }),
          width: '120',
          height: '40',
          onClick: () => onSave(),
        }"
      />

      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :options="{
          elementAttr: {
            class: 'white filled txt_S medium',
          },
          text: $_lang('COMPONENTS.CANCEL', { defaultValue: '취소' }),
          width: '120',
          height: '40',
          onClick: () => isOpenModal(false),
        }"
      />
    </DxPopup>
  </div>
</template>

<script>
  import Search from '@/components/common/search';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { isSuccess } from '@/plugins/common-lib';
  import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
  import { DxTextBox } from 'devextreme-vue/text-box';
  import { DxNumberBox } from 'devextreme-vue/number-box';
  import { DxTextArea } from 'devextreme-vue/text-area';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: {
      EspDxDataGrid,
      DxTextArea,
      DxPopup,
      DxTextBox,
      DxToolbarItem,
      DxSelectBox,
      Search,
      DxNumberBox,
    },
    data() {
      return {
        stylingMode: 'outlined',
        modal: {
          isOpened: false,
          currentComponent: null,
          initData: {},
          contentData: {
            viewFl: 'Y',
            cleanOrd: null,
            fileFl: 'N',
            title: '',
            isSql: '',
            cleanSql: '',
            path: '',
            month: null,
          },
        },
        searchType: {
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {},
          paramsData: null,
        },
        dataGrid: {
          refName: 'cleanGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          height: 'calc(100vh - 225px)', // 주석처리시 100%
          apiActionNm: {},
          customEvent: {},
          showActionButtons: {
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '등록',
                  elementAttr: { class: 'btn_XS default filled add1' },
                  width: 60,
                  height: 30,
                  onClick: () => {
                    this.onOpenModal(
                      'componentNm',
                      {
                        title: this.$_lang('COMPONENTS.DETAIL', { defaultValue: '상세보기' }),
                        width: '50vw',
                        height: 'calc(100vh - 100px)',
                      },
                      {
                        viewFl: 'Y',
                        cleanOrd: null,
                        fileFl: 'N',
                        title: '',
                        isSql: '',
                        cleanSql: '',
                      },
                    );
                  },
                },
                location: 'before',
              },
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: this.$_lang('COMPONENTS.DELETE', { defaultValue: '삭제' }),
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 60,
                  height: 30,
                  onClick: () => {
                    this.deleteItems();
                  },
                },
                location: 'before',
              },
            ],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '제목',
              i18n: 'COMPONENTS.TITLE',
              dataField: 'title',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              cellTemplate: (container, options) => {
                const aTag = document.createElement('a');
                aTag.innerText = options.value;
                aTag.addEventListener('click', () => {
                  this.onOpenModal(
                    'componentNm',
                    { title: '데이터 상세', width: '1000', height: '800' },
                    {
                      ...options.data,
                      viewFl: options.data.viewFl,
                    },
                  );
                });
                container.append(aTag);
              },
            },
            {
              caption: '존재 확인 질의문',
              i18n: 'UC.WORD.EXISTENCE_CONFIRMATION_QUERY',
              dataField: 'isSql',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '삭제 질의문',
              i18n: 'UC.WORD.DELETE_QUERY',
              dataField: 'cleanSql',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '파일 삭제 유무',
              i18n: 'UC.WORD.FILE_DELETE_FLAG',
              dataField: 'fileFl',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              lookup: {
                dataSource: this.$_enums.common.stringUsedFlag.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
            {
              caption: '순서',
              i18n: 'COMPONENTS.ORDER',
              dataField: 'cleanOrd',
              width: 100,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '사용 여부',
              i18n: 'COMPONENTS.USE_STATUS',
              dataField: 'viewFl',
              width: 120,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              lookup: {
                dataSource: this.$_enums.common.stringUsedFlag.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
            {
              caption: '등록일',
              i18n: 'COMPONENTS.REG_DATE',
              dataField: 'regDt',
              width: 200,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              calculateCellValue: this.formatDt,
            },
          ],
        },
      };
    },
    computed: {
      getStringUsedFlag() {
        return this.$_enums.common.stringUsedFlag.values;
      },
    },
    methods: {
      async deleteItems() {
        const deletedIds = this.$refs.cleanGrid.selectedRowsData.map(d => {
          return { id: d.id };
        });

        if (!deletedIds?.length) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return false;
        }

        if (
          await this.$_Confirm(
            this.$_lang('COMMON.MESSAGE.CMN_CFM_DELETE_SELECTED', { defaultValue: '선택한 데이터를 삭제하시겠습니까?' }),
          )
        ) {
          const payload = {
            actionName: 'EUC_DATA_MANAGEMENT_DELETE',
            data: deletedIds,
            loading: true,
          };

          const res = await this.CALL_EUC_API(payload);
          if (isSuccess(res)) {
            await this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUC_DELETE', { defaultValue: '선택한 데이터가 삭제되었습니다.' }));
            this.$refs.cleanGrid.refreshData();
          } else {
            this.$_Msg(`데이터 삭제 실패 \n 원인 : [${res.header?.resMsg ?? 'HTTP 에러'}]`);
          }
        } else {
          return false;
        }
      },
      async onSave() {
        const params = { ...this.modal.contentData };
        const payload = {
          actionName: 'EUC_DATA_MANAGEMENT_UPDATE',
          data: [params],
          loading: true,
          useErrorPopup: true,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          await this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
          this.$refs.cleanGrid.refreshData();
          this.isOpenModal(false);
        }
      },
      onOpenModal(componentNm, componentInitData, contentData) {
        this.modal.currentComponent = componentNm;
        this.modal.initData = componentInitData;
        this.modal.contentData = contentData;
        this.isOpenModal(true);
      },
      isOpenModal(data) {
        this.modal.isOpened = data;
        if (!data) {
          this.modal.currentComponent = null;
          this.modal.initData = {};
          this.modal.contentData = null;
        }
      },
      async selectDataList(sort = '+cleanOrd') {
        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);
            if (!params.sort) {
              params.sort = sort;
            }

            params = { ...params, ...this.searchType.paramsData };

            const payload = {
              actionName: 'EUC_DATA_MANAGEMENT_SELECT',
              data: params,
              loading: false,
            };

            const rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await this.CALL_EUC_API(payload);
            if (isSuccess(res)) {
              rtnData.data = res.data.data;
              rtnData.totalCount = res.data.header.totalCount;
            }
            return rtnData;
          },
        });
      },
      /** @description : 날짜 형식 변환 메서드 */
      formatDt(rowData) {
        if (rowData?.regDt) {
          return this.$_commonlib.formatDate(rowData.regDt, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
        }
      },
    },
    created() {
      this.selectDataList();
    },
  };
</script>

<style scoped></style>