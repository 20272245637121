<!--
  PACKAGE_NAME : src/pages/euc/device/hunt
  FILE_NAME : /huntList.vue
  AUTHOR : jhsim
  DATE : 2024-05-01
  DESCRIPTION : 헌트정보 , 엑셀다운로드 시 전체다운로드만 지원
-->
<template>
  <div class="page-sub-box">
    <div class="locker_setting_list sub_new_style01 sub_ui_box1">
      <!-- <Search
				codeKey="search_type_line"
				:searchObj="searchType.obj"
				:useKeywordType="true"
				@change-search-type="$_changeSearchType"
				@onSearchClick="$_searchData($event, 'search_type_line')"
			>
			</Search> -->
      <Search
        codeKey="null"
        :customSearchList="searchType.list"
        :searchObj="searchType.obj"
        @change-search-type="$_changeSearchType"
        @onSearchClick="searchData($event)"
      >
      </Search>
    </div>

    <esp-dx-data-grid :data-grid="dataGrid" ref="huntGrid" @exporting="onExporting" />
  </div>
</template>
<script>
  import Search from '@/components/common/search.vue';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { isSuccess, formatDate } from '@/plugins/common-lib';
  import { exportDataGrid } from 'devextreme/excel_exporter';
  import ExcelJS from 'exceljs';
  import saveAs from 'file-saver';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';
  export default {
    components: {
      EspDxDataGrid,
      DxSelectBox,
      Search,
      ExcelJS,
      saveAs,
    },
    props: {
      cmList: {
        type: Array,
      },
    },
    data() {
      return {
        config: {
          pageSetting: {
            //pageData pageSetting 관련
            config: {},
          },
        },
        stylingMode: 'outlined', //outlined, underlined, filled
        searchType: {
          list: [
            {
              codeNm: '헌트파일럿 패턴',
              codeValue: 'pattern',
            },
            {
              codeNm: '헌트파일럿 설명',
              codeValue: 'description',
            },
            {
              codeNm: '헌트리스트 설명',
              codeValue: 'listdescription',
            },
            {
              codeNm: '회선그룹',
              codeValue: 'name',
            },
            {
              codeNm: '내선번호',
              codeValue: 'dnpattern',
            },
          ],
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {},
          paramsData: null,
        },
        totalCount: 0,
        excelExportAll: false, // 엑셀 전체 다운로드 플래그
        dataGrid: {
          excel: {
            title: '헌트정보',
          },
          refName: 'huntGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          // height:'500',    // 주석처리시 100%
          height: 'calc(100vh - 450px)', // 주석처리시 100%
          apiActionNm: {},
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
            exporting: true,
          },

          showActionButtons: {
            excel: {
              allowExportSelectedData: false,
              exportButtonText: {
                //엑셀 다운로드 정보
                exportAll: '전체 다운로드',
              },
            }, // 엑셀 버튼
            customButtons: [],
          },
          isDuplicateConfigKey: false, //설정키 중복 체크
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: false, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '헌트파일럿 패턴',
              // i18n: 'UC.WORD.PATTERN',
              dataField: 'pattern',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '헌트파일럿 설명',
              // i18n: 'UC.WORD.PILOT_DESCRIPTION',
              dataField: 'description',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowExporing: true,
              fixed: false,
              fixedPosition: 'left',
            },

            {
              caption: '헌트리스트',
              // i18n: 'UC.WORD.HUNTLIST',
              dataField: 'huntlistname',
              // width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '헌트리스트 설명',
              // i18n: 'UC.WORD.LIST_DESCRIPTION',
              dataField: 'listdescription',
              width: 200,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '회선그룹',
              i18n: 'UC.WORD.LINEGROUP',
              dataField: 'name',
              width: 200,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },

            {
              caption: '내선번호',
              i18n: 'UC.WORD.NUMBER',
              dataField: 'dnpattern',
              width: 150,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
            },
            {
              caption: '파티션',
              i18n: 'UC.WORD.PARTITION',
              dataField: 'routepartitionname',
              width: 150,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
            },
            {
              caption: '수정일',
              i18n: 'COMPONENTS.MODIFY_DATE',
              dataField: 'editDt',
              width: 200,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              calculateCellValue: this.formatDt,
            },
          ],
        },
      };
    },
    computed: {},
    methods: {
      /** @description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {
        this.dataGrid.showActionButtons.customButtons = this.getCustomButtons();
      },
      /**@description 커스텀 검색 이벤트 */
      searchData(paramsData) {
        if (paramsData) {
          this.searchType.paramsData = { ...this.searchType.paramsData, ...paramsData };
        } else {
          const codeValues = this.searchType.list.map(d => d.codeValue);
          if (this.searchType.paramsData != null) {
            if (Object.keys(this.searchType.paramsData).length > 0) {
              Object.keys(this.searchType.paramsData).forEach(key => {
                if (codeValues.includes(key)) {
                  delete this.searchType.paramsData[key];
                }
              });
            }
          }
        }
        this.selectDataList();
      },
      getCustomButtons() {
        const buttons = [
          {
            widget: 'dxButton',
            options: {
              icon: '',
              text: '헌트 동기화',
              elementAttr: { class: 'btn_XS default filled ' },
              height: 30,
              onClick: () => {
                this.onSyncHuntgroup();
              },
            },
            location: 'before',
          },
        ];

        return buttons;
      },
      /** @description : 라이프사이클 mounted시 호출되는 메서드 */
      async mountData() {
        await this.selectDataList();
      },
      /** @description : 데이터 검색 메서드 */
      async selectDataList(sort = '+regDt,+id') {
        this.dataGrid.dataSource = new CustomStore({
          key: 'customId', //고유키가 없어 별도로 생성
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);

            if (!params.sort) {
              params.sort = sort;
            }
            params = { ...params, ...this.searchType.paramsData };

            const payload = {
              actionName: 'EUC_HUNT_LIST_ALL',
              data: params,
              loading: false,
            };

            const rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await this.CALL_EUC_API(payload);
            if (isSuccess(res)) {
              rtnData.data = res.data.data;
              rtnData.totalCount = res.data.header.totalCount;
              //고유키가 없어 별도로 생성'
              rtnData.data = rtnData.data.map(data => {
                data.customId = `${data.huntpilotId}-${data.huntlistId}-${data.linegroupId}-${data.dnpattern}`;
                return data;
              });
              this.excelExportAll = false;
              this.totalCount = 0;
            }
            return rtnData;
          },
        });
      },
      /** @description : 날짜 형식 변환 메서드 */
      formatDt(rowData) {
        if (rowData?.editDt) {
          return this.$_commonlib.formatDate(rowData.editDt, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
        }
      },
      async onSyncHuntgroup() {
        let confirm = this.$_lang('UC.MESSAGE.START_SYNC');
        if (!(await this.$_Confirm(confirm))) {
          return;
        }
        for (const item of this.cmList) {
          const params = { cmNm: item.cmNm, loginId: this.$store.getters.getLoginId, type: 'RELATED' };
          const payload = {
            actionName: 'EUC_HUNTGROUP_SYNC',
            data: params,
            loading: true,
          };
          const res = await this.CALL_EUC_API(payload);
          if (isSuccess(res)) {
            // await this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_SUCCESS', { defaultValue: '정상적으로 처리되었습니다.' }));
            await this.selectDataList();
          } else {
            if (res.data.data.length == 0) {
              await this.$_Msg(res.data.header.resMsg);
            } else {
              await this.$_Msg('교환기 : ' + item.cmNm + ' ' + res.data.header.resMsg);
            }
          }
        }
      },
      /** @description: 엑셀 다운로드 이벤트 */
      onExporting(e) {
        this.excelExportAll = true; //엑셀다운로드 플래그 변경
        this.totalCount = e.component.totalCount();
        //Grid Excel Export
        const title = this.dataGrid.excel.title;
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(title);

        //Excel Width 값 설정 dataGrid.excel.cellwidth 값에 따라 결정(없으면 Default : 30)
        let columnsArr = [];
        this.dataGrid.columns.forEach(() => {
          columnsArr.push({ width: this.dataGrid.excel.cellwidth ? this.dataGrid.excel.cellwidth : 30 });
        });
        worksheet.columns = columnsArr;

        let today = formatDate(new Date(), 'YYYYMMDDHHmmss', 'YYYYMMDDHHmmss');

        exportDataGrid({
          component: e.component,
          worksheet: worksheet,
          keepColumnWidths: false,
          autoFilterEnabled: this.dataGrid.excel.autoFilterEnabled ? this.dataGrid.excel.autoFilterEnabled : false, //자동필터 설정 여부
          topLeftCell: { row: 4, column: 1 },
          customizeCell: ({ gridCell, excelCell }) => {
            if (gridCell.rowType === 'header') {
              //header 영역 설정
              excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'C6EFCE' } };
              excelCell.alignment = { horizontal: 'center', vertical: 'middle' };
            } else {
              //data 영역 배경색 설정
              if (excelCell.fullAddress.row % 2 === 0) {
                excelCell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'F2F2F2' },
                  bgColor: { argb: 'F2F2F2' },
                };
              }
            }

            const borderStyle = { style: 'thin', color: { argb: 'FF7E7E7E' } };
            excelCell.border = {
              bottom: borderStyle,
              left: borderStyle,
              right: borderStyle,
              top: borderStyle,
            };
          },
        })
          .then(() => {
            const titleRow = worksheet.getRow(2);
            titleRow.height = 40;
            if (e.format === 'xlsx') {
              worksheet.mergeCells(2, 1, 2, this.dataGrid.columns.length);
            }
            titleRow.getCell(1).value = title;
            titleRow.getCell(1).font = { size: 22, bold: true };
            titleRow.getCell(1).alignment = { horizontal: 'center', vertical: 'middle' };

            const hearderRow = worksheet.getRow(4);
            hearderRow.height = 30;
          })
          .then(() => {
            if (e.format === 'csv') {
              workbook.csv.writeBuffer().then(buffer => {
                saveAs(new Blob([buffer], { type: 'text/csv' }), `${title}_${today}.csv`);
              });
            } else {
              workbook.xlsx.writeBuffer().then(buffer => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${title}_${today}.xlsx`);
              });
            }
          })
          .then(() => {
            // 다운로드 이력 저장
            e.onSaveHistory();
          });
        e.cancel = true;
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountData();
    },
  };
</script>
