<!--
  PACKAGE_NAME : src/pages/cc/cti/team-category/modal
  FILE_NAME : modal-team.vue
  AUTHOR : hmlee
  DATE : 2024-07-02
  DESCRIPTION : 팀그룹 추가 모달 컴포넌트
-->
<template>
  <esp-dx-modal-popup
    :option="{
      title: modal.title,
      width: modal.width,
      height: modal.height,
      minWidth: modal.minWidth,
      minHeight: modal.minHeight,
      closeOnOutsideClick: modal.hideOnOutsideClick,
      validationGroup: modal.validationGroup,
      useSaveBtnDisabled: modal.useSaveBtnDisabled,
    }"
    :isOpen="isOpen"
    @saveModal="handleSaveModal"
    @closeModal="handleCloseModal"
  >
    <template #content>
      <div class="grid grid-cols-1 gap-x-8">
        <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" />
      </div>
    </template>
  </esp-dx-modal-popup>
</template>

<script>
import EspDxModalPopup from "@/components/devextreme/esp-dx-modal-popup.vue";
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid-v2.vue";
import {isSuccess} from "@/plugins/common-lib";
import CustomStore from "devextreme/data/custom_store";

export default {
  components: {
    EspDxModalPopup,
    EspDxDataGrid,
  },
  props: {
    isOpen: {
      default: false,
      type: Boolean,
    },
    teamData: {
      default: null,
      type: Object,
    },
  },
  data() {
    return {
      modal: {
        title: `${this.$_lang('CC.WORD.CHILD', {defaultValue: '하위'})} ${this.$_lang('CC.WORD.ADD_TEAM', {defaultValue: '팀 추가'})}`,
        minWidth: '300',
        width: '800',
        minHeight: '300',
        height: '650',
        dragEnabled: true,
        resizeEnabled: true,
        showCloseButton: true,
        hideOnOutsideClick: false,
        useSaveBtnDisabled: false, // 하단 저장 버튼 비활성화 여부(저장 중복 클릭 방지 설정시 사용)
      },
      duplicatedIds: {}, // 중복된 id를 담는 객체
      formData: {}, // 저장할 데이터
      dataGrid: {
        refName: 'agtteamGrid',
        dataSource: [],
        // width:'200', //주석처리시 100%
        height: '500', //주석처리시 100%
        apiActionNm: {},
        showActionButtons: {
          delete: false,
        },
        scrolling: { //미사용시 주석처리
          mode: 'standard' //스크롤 모드 : ['infinite', 'standard', 'virtual']
        },
        page: { //scrolling 미사용시만 적용됨
          enabled: false,
        },
        editing: {
          allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
          allowDeleting: false,
          allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
        },
        columns: [
          {
            caption: this.$_lang('COMMON.WORD.SITE', {defaultValue: '사이트'}),
            dataField: 'siteId',
            width: 110,
            allowEditing: false,
            lookup: {
              dataSource: this.$store.getters.getSiteList,
              displayExpr: "siteNm",
              valueExpr: "id",
            },
          },
          {
            caption: this.$_lang('COMMON.WORD.TENANT', {defaultValue: '센터'}),
            dataField: 'tenantId',
            width: 110,
            alignment: 'center', //left center right
            allowEditing: false,
            lookup: {
              dataSource: this.$store.getters.getTenantList,
              displayExpr: "tenantNm",
              valueExpr: "id",
            },
          },
          {
            caption: `${this.$_lang('CC.WORD.TEAM', {defaultValue: '팀'})} ID`,
            dataField: 'id',
            width: 100,
            allowEditing: false,
          },
          {
            caption: this.$_lang('CC.WORD.TEAM_NAME', {defaultValue: '팀명'}),
            dataField: 'agtteamNm',
          },
          {
            caption: this.$_lang('CC.WORD.CTI_NAME', {defaultValue: 'CTI 이름'}),
            dataField: 'agtteam',
          },
        ]
      },
    }
  },
  methods: {
    /** @description: 모달 저장 */
    async handleSaveModal() {
      const selectedRowsData = this.$refs[this.dataGrid.refName].getInstance.getSelectedRowsData();
      if (selectedRowsData.length === 0) {
        return this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', {defaultValue: '대상이 선택되어 있지 않습니다.'}));
      }

      //저장 중복 클릭 방지 체크
      if( this.modal.useSaveBtnDisabled ) {
        return false;
      }
      this.modal.useSaveBtnDisabled = true; // 저장 중복 클릭 방지 설정

      let maxOrd = this.formData.agtteamCtgOrd;
      const savedData = selectedRowsData.map(d => {
         const teamData = {
          parentId: this.formData.parentId,
          siteId: this.formData.siteId,
          agtteamId: d.id,
          depth: this.formData.depth,
          agtteamCtgOrd: maxOrd,
          viewFl: this.$_enums.common.stringUsedFlag.YES.value,
        };
        maxOrd++;

        return teamData;
      });

      const payload = {
        actionName: 'CC_AGTTEAM_CATEGORY_MERGE',
        data: savedData,
      }
      const res = await this.CALL_CC_API(payload);
      if (isSuccess(res)) {
        this.$emit('saveModal');
        this.modal.useSaveBtnDisabled = false; // 저장 중복 클릭 방지 해제
      }
    },
    /** @description: 모달 닫기 */
    handleCloseModal() {
      this.$emit('closeModal');
    },
    /** @description : 이미 추가한 하위 팀그룹 id를 가져오는 메서드 */
    async selectChildIds() {
      const payload = {
        actionName: 'CC_AGTTEAM_CATEGORY_LIST',
      }

      const res = await this.CALL_CC_API(payload);
      if (isSuccess(res)) {
        this.duplicatedIds = (res.data.data)?.map(d => d.agtteamId);
      }
    },
    /** @description : 하위 팀그룹 조회 메서드 */
    async selectDataList(sort = '+id') {
      this.dataGrid.dataSource = new CustomStore({
        key: 'id',
        load: async(loadOptions) => {
          const params = this.$_getDxDataGridParam(loadOptions);

          if (!params.sort)
            params.sort = sort;

          params.viewFl = 'Y';

          //params.id = `<>5012,5013,5016,5020`;
          if (Object.keys(this.duplicatedIds).length > 0) {
            params.id = `<>${this.duplicatedIds}`;
          }

          const payload = {
            actionName: 'CC_AGTTEAM_LIST',
            data: params,
          }

          const res = await this.CALL_CC_API(payload);
          if (isSuccess(res)) {
            const rtnData = {
              data: res.data.data,
              totalCount: res.data.header.totalCount,
            }

            this.$refs[this.dataGrid.refName].totalCount = rtnData.totalCount;

            return rtnData;
          }
        }
      });
    },
    /** @description: 데이터 초기화 */
    initData() {
      // 초기값 설정
      const initFormData = {
        parentId: -1,
        siteId: this.$store.getters.getSiteList?.[0]?.id || '',
        depth: 1,
        agtteamCtgOrd: 0,
      };

      this.formData = {
        ...(this.teamData || initFormData),
      };
    },
  },
  async created() {
    await this.initData();
    await this.selectChildIds();
    await this.selectDataList();
  },
}
</script>
