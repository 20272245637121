<template>
  <div class="locker_setting_list sub_new_style01 sub_ui_box1">
    <div class="page-sub-box">
      <Search
        codeKey="search_type_device_hunt"
        :searchObj="searchType.obj"
        :useKeywordType="true"
        @change-search-type="$_changeSearchType"
        @onSearchClick="$_searchData($event, 'search_type_device_hunt')"
      >
        <template v-slot:before>
          <DxSelectBox
            v-model="searchType.customTypes['herNum']"
            :items="codeList"
            display-expr="codeNm"
            value-expr="codeValue"
            :styling-mode="stylingMode"
            :width="150"
            :height="30"
            @value-changed="$_changeSearchCustomType('herNum', $event)"
          />
        </template>
      </Search>
      <div style="padding-top: 10px">
        <!-- <DxButton
					@click="onSyncHuntgroup"
					text="헌트그룹 동기화"
					:width="120"
					:height="30"
					class="dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled fl"
					styling-mode="contained"
					type="default"
				/> -->
      </div>
      <table class="table_form line-bin">
        <colgroup>
          <col style="width: 25%" />
          <col style="width: 20%" />
          <col style="width: 15%" />
          <col style="width: 35%" />
        </colgroup>
        <tr>
          <td style="padding: 0 5">
            <!-- <div style="margin: bottom 30px">
							<span style="font-weight: bold; font-size: 16px">파일럿</span>
							<span class="fr" style="font-weight: bold"
								>총 <font class="col_blue2">{{ this.huntpilotGrid.selectedRowCnt }}</font> /
								<font class="col_blue2">{{ this.huntpilotGrid.totalCount }}</font
								>개
							</span>
						</div> -->
            <esp-dx-data-grid
              :data-grid="huntpilotGrid"
              ref="huntpilotGrid"
              @selection-changed="onSelectionChanged($event, 'huntpilot')"
              @toolbar-preparing="onToolbarPreparing($event, 'huntpilot')"
              @reorder="onChangePilotOrd"
            >
            </esp-dx-data-grid>
            <div style="margin: bottom 30px; padding-top: 10px">
              <DxButton
                text="순서저장"
                :height="30"
                class="dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled fl"
                styling-mode="contained"
                type="default"
                @click="onUpdateOrder"
              />
              <!-- :width="100" -->
              <DxButton
                text="저장"
                :height="30"
                class="dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled fl"
                styling-mode="contained"
                type="default"
                @click="onUpdateHerNum"
              />
              <!-- :width="50" -->
            </div>
          </td>
          <td style="padding: 0 5">
            <!-- <div style="margin: bottom 30px">
							<span style="font-weight: bold; font-size: 16px">리스트</span>
							<span class="fr" style="font-weight: bold"
								>총 <font class="col_blue2">{{ this.huntlistGrid.selectedRowCnt }}</font> /
								<font class="col_blue2">{{ this.huntlistGrid.totalCount }}</font
								>개
							</span>
						</div> -->
            <esp-dx-data-grid
              :data-grid="huntlistGrid"
              ref="huntlistGrid"
              @selection-changed="onSelectionChanged($event, 'huntlist')"
              @toolbar-preparing="onToolbarPreparing($event, 'huntlist')"
            ></esp-dx-data-grid>
            <div style="margin: bottom 30px; padding-top: 40px"></div>
          </td>
          <td style="padding: 0 5">
            <!-- <div style="margin: bottom 30px">
							<span style="font-weight: bold; font-size: 16px">회선그룹</span>
							<span class="fr" style="font-weight: bold"
								>총 <font class="col_blue2">{{ this.linegroupGrid.selectedRowCnt }}</font> /
								<font class="col_blue2">{{ this.linegroupGrid.totalCount }}</font
								>개
							</span>
						</div> -->
            <esp-dx-data-grid
              :data-grid="linegroupGrid"
              ref="linegroupGrid"
              @selection-changed="onSelectionChanged($event, 'linegroup')"
              @toolbar-preparing="onToolbarPreparing($event, 'linegroup')"
            ></esp-dx-data-grid>
            <div style="margin: bottom 30px; padding-top: 40px"></div>
          </td>
          <td style="padding: 0 5">
            <!-- <div style="margin: bottom 30px">
							<span style="font-weight: bold; font-size: 16px">회선번호</span>
							<span class="fr" style="font-weight: bold"
								>총 <font class="col_blue2">{{ this.linegroupDnGrid.selectedRowCnt }}</font> /
								<font class="col_blue2">{{ this.linegroupDnGrid.totalCount }}</font
								>개
							</span>
						</div> -->
            <esp-dx-data-grid
              :data-grid="linegroupDnGrid"
              ref="linegroupDnGrid"
              @selection-changed="onSelectionChanged($event, 'linegroupDn')"
              @content-ready="onContentReady"
            ></esp-dx-data-grid>
            <div style="margin: bottom 30px; padding-top: 40px"></div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
  import Search from '@/components/common/search.vue';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { DxButton } from 'devextreme-vue/button';
  import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
  import { DxCustomRule, DxRequiredRule, DxValidator } from 'devextreme-vue/validator';
  import { DxTextBox } from 'devextreme-vue/text-box';
  import { isSuccess } from '@/plugins/common-lib';
  import CustomStore from "devextreme/data/custom_store";
  import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid.vue";

  export default {
    components: {
      EspDxDataGrid,
      DxTextBox,
      DxCustomRule,
      DxButton,
      DxRequiredRule,
      DxValidator,
      DxSelectBox,
      DxPopup,
      DxToolbarItem,
      Search,
    },
    data() {
      return {
        config: {
          pageSetting: {
            //pageData pageSetting 관련
            config: {},
          },
        },
        dragData: {
          huntpilot: [],
          linegroup: [],
          linegroupDn: [],
        },
        flag: {
          search: false,
        },
        contentReady: false,
        codeList: [],
        cmList: [],
        rowInfo: {
          pilot: {},
          list: {},
          linegroup: {},
          linegroupDn: [],
        },
        stylingMode: 'outlined', //outlined, underlined, filled
        searchType: {
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            herNum: null,
          },
          paramsData: null,
        },
        huntpilotGrid: {
          // callApi: 'CALL_EUC_API',
          refName: 'huntpilotGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          allowReordering: true,
          showBorders: true, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          disableTotalCount: false,
          toolbarItems: [],
          selectedRowCnt: 0,
          totalCount: 0,
          dataSource: [],
          width: '400', // 주석처리시 100%
          // height: '550', // 주석처리시 100%
          height: 'calc(100vh - 360px)', // 주석처리시 100%
          apiActionNm: {
            // update: '',
          },
          dragging: {
            sortColumn: 'huntpilotOrd',
            allowReordering: true,
            dropFeedbackMode: 'push', // 설정하면 dragging 할때 기존리스트가 아래로 움직이는 효과
          },
          customEvent: {
            // initNewRow: true,
            selectionChanged: true,
            reorder: true,
            saving: true,
            rowUpdating: true,
            rowUpdated: true,
            toolbarPreparing: false,
          },
          showActionButtons: {
            customButtons: [],
          },
          isDuplicateConfigKey: false, //설정키 중복 체크
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: false,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'single', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'cell', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: 'Her 번호',
              dataField: 'herNum',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowExporing: true,
              fixed: false,
              allowFiltering: false,
              cellTemplate: async (container, options) => {
                let newCodeList = this.codeList.filter(d => d.parentId != null);
                const selectbox = new DxSelectBox({
                  propsData: {
                    placeholder: '선택',
                    items: newCodeList,
                    displayExpr: 'codeNm',
                    valueExpr: 'codeValue',
                    value: options.value,
                    stylingMode: this.stylingMode,
                    onValueChanged: e => {
                      this.$refs.huntpilotGrid.getInstance.cellValue(
                        options.rowIndex,
                        options.columnIndex,
                        e.value,
                      );
                      this.$refs.huntpilotGrid.getInstance.saveEditData();
                    },
                  },
                });
                selectbox.$mount();
                container.append(selectbox.$el);
              },
            },
            {
              caption: '패턴',
              dataField: 'pattern',
              width: 100,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowExporing: true,
              allowFiltering: false,
              fixed: false,
              requiredRule: {
                message: '필수 항목입니다.',
              },
            },
            {
              caption: '설명',
              dataField: 'description',
              // width: 250,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              allowFiltering: false,
            },
          ],
        },
        huntlistGrid: {
          callApi: 'CALL_EUC_API',
          refName: 'huntlistGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: true, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          disableTotalCount: false,
          toolbarItems: [],
          selectedRowCnt: 0,
          totalCount: 0,
          // width: '400', // 주석처리시 100%
          // height: '550', // 주석처리시 100%
          height: 'calc(100vh - 360px)', // 주석처리시 100%
          apiActionNm: {
            loading: true,
          },
          customEvent: {
            initNewRow: true,
            rowClick: false,
            selectionChanged: true,
            toolbarPreparing: false,
          },
          showActionButtons: {
            customButtons: [],
          },
          isDuplicateConfigKey: false, //설정키 중복 체크
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'single', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '이름',
              dataField: 'name',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowExporing: true,
              fixed: false,
              requiredRule: {
                message: '필수 항목입니다.',
              },
            },
            {
              caption: '설명',
              dataField: 'description',
              // width: 200,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
          ],
        },
        linegroupGrid: {
          callApi: 'CALL_EUC_API',
          refName: 'linegroupGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          allowReordering: false,
          showBorders: true, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          disableTotalCount: false,
          toolbarItems: [],
          selectedRowCnt: 0,
          totalCount: 0,
          // width: '400', // 주석처리시 100%
          // height: '550', // 주석처리시 100%
          height: 'calc(100vh - 360px)', // 주석처리시 100%
          apiActionNm: {
            loading: true,
          },
          customEvent: {
            initNewRow: true,
            rowClick: false,
            selectionChanged: true,
            reorder: false,
            toolbarPreparing: false,
          },
          dragging: {
            sortColumn: 'selectionorder',
            allowReordering: false,
            dropFeedbackMode: 'push', // 설정하면 dragging 할때 기존리스트가 아래로 움직이는 효과
          },
          showActionButtons: {
            // delete: true,
            customButtons: [],
          },
          isDuplicateConfigKey: false, //설정키 중복 체크
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '이름',
              dataField: 'name',
              // width: 200,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowExporing: true,
              fixed: false,
              requiredRule: {
                message: '필수 항목입니다.',
              },
            },
          ],
        },
        linegroupDnGrid: {
          callApi: 'CALL_EUC_API',
          refName: 'linegroupDnGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          allowReordering: false,
          showBorders: true, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          disableTotalCount: false,
          toolbarItems: [],
          selectedRowCnt: 0,
          totalCount: 0,
          // width: '400', // 주석처리시 100%
          // height: '550', // 주석처리시 100%
          height: 'calc(100vh - 360px)', // 주석처리시 100%
          apiActionNm: {
            loading: true,
          },
          customEvent: {
            initNewRow: true,
            rowClick: false,
            selectionChanged: true,
            reorder: false,
            contentReady: false,
          },
          dragging: {
            sortColumn: 'lineselectionorder',
            allowReordering: false,
            dropFeedbackMode: 'push', // 설정하면 dragging 할때 기존리스트가 아래로 움직이는 효과
          },
          showActionButtons: {
            customButtons: [],
          },
          isDuplicateConfigKey: false, //설정키 중복 체크
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: true,
            pageSize: 50,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '패턴',
              dataField: 'pattern',
              width: 100,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowExporing: true,
              fixed: false,
              requiredRule: {
                message: '필수 항목입니다.',
              },
            },
            {
              caption: '파티션',
              dataField: 'routepartitionname',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '전체리스트',
              dataField: 'list',
              // width: 250,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowFiltering: false,
              allowGrouping: false,
            },
          ],
        },
      };
    },
    computed: {
      getHerNumberList() {
        const currentPath = this.$router.currentRoute.path;
        const store = this.$store.getters.getSearchHists[currentPath] || [];
        const params = store[0]?.search;

        const result = this.$_getCode('search_type_device_hunt').filter(d => d.delFl === 'N');
        const svrInx = params?.['herNum'] ? params['herNum'] : result[0].codeValue;
        this.setCustomTypes('herNum', svrInx);
        return result;
      },
    },
    methods: {
      /** @description 그리드 필터링 제외후 pilot 조회 */
      // async clearFilterAndSelect() {
      // 	this.searchType.paramsData = null;
      // 	this.searchKey++;
      // 	await this.$refs.huntpilotGrid.clearFilter();
      // 	await this.$refs.huntlistGrid.clearFilter();
      // 	await this.$refs.linegroupGrid.clearFilter();
      // 	await this.$refs.linegroupDnGrid.clearFilter();
      // 	await this.selectDataList();
      // },

      /** @description : 라이프사이클 computed에서 this data를 변경하기 위한 메서드 */
      setCustomTypes(key, value) {
        this.searchType.customTypes[key] = value;
      },

      getCustomButtons(data) {
        const buttons = [];
        const name = 'UC.WORD.' + data;
        buttons.push({
          location: 'before',
          html:
            `<div style="margin: bottom 30px">
							<span style="font-weight: bold; font-size: 16px">` +
            // this.$_lang('UC.WORD.LINEGROUPDN') +
            this.$_lang(name) +
            `</span></div>`,
        });
        return buttons;
      },
      /** @description : 헌트 파일럿 리스트 조회 */
      async selectDataList(sort = '+huntpilotOrd') {
        await this.resetAllGridList();
        let params = {};
        if (!params.sort) {
          params.sort = sort;
        }
        params = { ...params, ...this.searchType.paramsData };
        if (params?.herNum == 'hernum') {
          delete params['herNum'];
        }

        if (params?.currentpage) {
          delete params['currentpage'];
        }
        const payload = {
          actionName: 'EUC_HUNTPILOT_LIST_ALL',
          data: params,
          loading: false,
        };
        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          if (typeof params.herNum != 'undefined') {
            this.flag.search = true;
          } else {
            this.flag.search = false;
          }
          const rtnData = {
            data: res.data.data,
            totalCount: res.data.header.totalCount,
          };
          // 드래깅 데이터 초기화
          this.dragData.huntpilot = [];
          this.$refs.huntpilotGrid.totalCount = rtnData.totalCount;
          this.huntpilotGrid.dataSource = res.data.data;
          await this.$refs.huntpilotGrid.clearSelection();
          this.huntlistGrid.dataSource = [];
          this.repaintAllGrid();
        }
      },
      /** @description : 헌트리스트 리스트 조회 */
      async selectHuntlist(sort, pilotId) {
        this.huntlistGrid.dataSource = new CustomStore({
          key: 'id',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);
            if (!params.sort) {
              params.sort = sort;
            }
            if (params?.herNum == 'hernum') {
              delete params['herNum'];
            }
            if (pilotId !== null) {
              params.huntpilotId = pilotId;
            }
            if (params?.currentpage) {
              delete params['currentpage'];
            }
            params = { ...params, ...this.searchType.paramsData };

            const payload = {
              actionName: 'EUC_HUNTLIST_LIST_ALL',
              data: params,
              loading: false,
            };

            const rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await this.CALL_EUC_API(payload);
            if (isSuccess(res)) {
              rtnData.data = res.data.data;
              rtnData.totalCount = res.data.header.totalCount;

              await this.$refs.huntlistGrid.clearSelection();
              this.repaintAllGrid();
            }
            return rtnData;
          },
        });
      },
      /** @description : 회선그룹 리스트 조회 */
      async selectLinegroup(sort, huntlistId) {
        this.linegroupGrid.dataSource = new CustomStore({
          key: 'id',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);
            if (!params.sort) {
              params.sort = sort;
            }
            if (params?.herNum == 'hernum') {
              delete params['herNum'];
            }
            if (huntlistId !== null) {
              params.huntlistId = huntlistId;
            }
            if (params?.currentpage) {
              delete params['currentpage'];
            }
            params = { ...params, ...this.searchType.paramsData };

            const payload = {
              actionName: 'EUC_LINEGROUP_LIST_ALL',
              data: params,
              loading: false,
            };

            const rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await this.CALL_EUC_API(payload);
            if (isSuccess(res)) {
              rtnData.data = res.data.data;
              rtnData.totalCount = res.data.header.totalCount;
              await this.$refs.linegroupGrid.clearSelection();
              this.repaintAllGrid();
            }
            return rtnData;
          },
        });
      },
      /** @description : 회선번호 리스트 조회 */
      async selectLinegroupDn(sort, linegroupId) {
        this.linegroupDnGrid.dataSource = new CustomStore({
          key: 'id',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);
            if (!params.sort) {
              params.sort = sort;
            }
            if (params?.herNum == 'hernum') {
              delete params['herNum'];
            }
            if (linegroupId !== null) {
              params.linegroupId = linegroupId;
            }

            params = { ...params, ...this.searchType.paramsData };

            const payload = {
              actionName: 'EUC_LINEGROUPDN_LIST_ALL',
              data: params,
              loading: false,
            };

            const rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await this.CALL_EUC_API(payload);
            if (isSuccess(res)) {
              rtnData.data = res.data.data;
              rtnData.totalCount = res.data.header.totalCount;

              await this.$refs.linegroupDnGrid.clearSelection();
              this.repaintAllGrid();
              return rtnData;
            }
          },
        });
      },
      async onSyncHuntgroup() {
        let confirm = this.$_lang('UC.MESSAGE.START_SYNC');
        if (!(await this.$_Confirm(confirm))) {
          return;
        }
        for (const item of this.cmList) {
          const params = { cmNm: item.cmNm, loginId: this.$store.getters.getLoginId, type: 'RELATED' };
          const payload = {
            actionName: 'EUC_HUNTGROUP_SYNC',
            data: params,
            loading: true,
          };

          const res = await this.CALL_EUC_API(payload);
          if (isSuccess(res)) {
            // await this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_SUCCESS', { defaultValue: '정상적으로 처리되었습니다.' }));
          } else {
            if (res.data.data.length == 0) {
              await this.$_Msg(res.data.header.resMsg);
            } else {
              await this.$_Msg('교환기 : ' + item.cmNm + ' ' + res.data.header.resMsg);
            }
          }
        }
      },
      /**@description			그리드 selectRow 이벤트 함수
       * @param 	{Object}	그리드 selction 이벤트 데이터
       * @param 	{String}	그리드 ref 이름
       * @returns {}
       */
      onSelectionChanged(e, type) {
        switch (type) {
          case 'huntpilot':
            this.huntpilotGrid.selectedRowCnt = e.selectedRowsData.length;
            if (this.huntpilotGrid.selectedRowCnt > 0) {
              let selectRowsData = e.selectedRowsData;
              let selectRowKeys = [];
              selectRowsData.forEach(data => {
                selectRowKeys.push(data.id);
              });

              this.selectHuntlist('+huntlistOrd', selectRowKeys);
            } else if (this.huntpilotGrid.selectedRowCnt === 0) {
              //하위 그리드 초기화
              this.huntlistGrid.dataSource = [];
              this.$refs.huntlistGrid.totalCount = 0;
              this.huntlistGrid.selectedRowCnt = 0;
              this.linegroupGrid.dataSource = [];
              this.$refs.linegroupGrid.totalCount = 0;
              this.linegroupGrid.selectedRowCnt = 0;
              this.linegroupDnGrid.dataSource = [];
              this.$refs.linegroupDnGrid.totalCount = 0;
            }
            break;
          case 'huntlist':
            this.huntlistGrid.selectedRowCnt = e.selectedRowsData.length;
            if (this.huntlistGrid.selectedRowCnt > 0) {
              this.selectLinegroup('+selectionorder', e.selectedRowKeys);
            } else if (this.huntlistGrid.selectedRowCnt === 0) {
              //하위 그리드 초기화
              this.linegroupGrid.dataSource = [];
              this.linegroupGrid.selectedRowCnt = 0;
              this.$refs.linegroupGrid.totalCount = 0;
              this.linegroupDnGrid.dataSource = [];
              this.linegroupDnGrid.selectedRowCnt = 0;
              this.$refs.linegroupDnGrid.totalCount = 0;
            }
            break;
          case 'linegroup':
            this.linegroupGrid.selectedRowCnt = e.selectedRowsData.length;
            if (this.linegroupGrid.selectedRowCnt > 0) {
              this.selectLinegroupDn('+lineselectionorder', e.selectedRowKeys);
            } else if (this.linegroupGrid.selectedRowCnt === 0) {
              //하위 그리드 초기화
              this.linegroupDnGrid.dataSource = [];
              this.linegroupDnGrid.selectedRowCnt = 0;
              this.$refs.linegroupDnGrid.totalCount = 0;
            }
            break;
          case 'linegroupDn':
            this.linegroupDnGrid.selectedRowCnt = e.selectedRowsData.length;
            break;
          default:
            break;
        }
        this.repaintAllGrid();
      },

      /** @description	그리드 순서 변경 이벤트
       *  @param {Object}	그리드 순서 변경 이벤트
       *  @param {type}	그리드 타입
       */
      onReorder(e, type) {
        if (type == 'huntpilot') {
          let data = [];
          if (this.dragData.huntpilot.length > 0) {
            data = this.dragData.huntpilot;
          } else {
            data = this.getGridDataSource('huntpilotGrid');
          }

          const visibleRows = e.component.getVisibleRows();
          const toIndex = data.findIndex(item => item.id === visibleRows[e.toIndex].data.id);
          const fromIndex = data.findIndex(item => item.id === e.itemData.id);

          const newDataSource = [...data];
          newDataSource.splice(fromIndex, 1);
          newDataSource.splice(toIndex, 0, e.itemData);

          if (fromIndex < toIndex) {
            // 아래로 이동할 때
            for (let i = fromIndex; i <= toIndex; i++) {
              newDataSource[i].huntpilotOrd = i + 1;
            }
          } else {
            // 위로 이동할 때
            for (let i = toIndex; i <= fromIndex; i++) {
              newDataSource[i].huntpilotOrd = i + 1;
            }
          }
          // 최종적으로 전체 배열의 ord 값을 순서대로 재설정
          newDataSource.forEach((item, index) => {
            item.huntpilotOrd = index + 1;
          });
          this.dragData.huntpilot = newDataSource;
          this.huntpilotGrid.dataSource = newDataSource;
        }
      },

      /** @description: 그리드 content ready, 최초한번만 동작하도록 flag값 비교*/
      onContentReady(e) {
        if (this.contentReady) {
          return;
        }
        const toolbarElement = e.component.element().querySelector('.dx-toolbar-before');
        let span = document.createElement('span');
        span.classList.add('dx-item', 'dx-toolbar-item', 'dx-toolbar-button');
        span.innerText = this.$_lang('UC.WORD.LINEGROUPDN');
        span.style.fontWeight = 'bold';
        span.style.fontSize = '16px';
        toolbarElement.append(span);
        this.contentReady = true;
      },

      /**@description grid toolbar event
       * @param {String} grid ref name
       */
      onToolbarPreparing(e, type) {
        const toolbarItems = e.toolbarOptions.items;
        // Adds a new item
        if (type == 'huntpilot') {
          toolbarItems.push({
            location: 'before',
            html:
              `<div style="margin: bottom 30px">
							<span style="font-weight: bold; font-size: 16px">` +
              this.$_lang('UC.WORD.HUNTPILOT') +
              `</span></div>`,
          });
          toolbarItems.push({
            location: 'after',
            html:
              `<span class="fr" style="font-weight: bold">` +
              this.$_lang('UC.WORD.TOTAL') +
              ' ' +
              `<font class="col_blue2">` +
              this.huntpilotGrid.selectedRowCnt +
              ' ' +
              `</font> / <font class="col_blue2">` +
              this.$refs.huntpilotGrid.totalCount +
              `</font>` +
              ' ' +
              this.$_lang('UC.WORD.COUNT') +
              `</span>`,
          });
        }

        if (type == 'huntlist') {
          toolbarItems.push({
            location: 'before',
            html:
              `<div style="margin: bottom 30px">
							<span style="font-weight: bold; font-size: 16px">` +
              this.$_lang('UC.WORD.HUNTLIST') +
              `</span></div>`,
          });
          toolbarItems.push({
            location: 'after',
            html:
              `<span class="fr" style="font-weight: bold">` +
              this.$_lang('UC.WORD.TOTAL') +
              ' ' +
              `<font class="col_blue2">` +
              this.huntlistGrid.selectedRowCnt +
              ' ' +
              `</font> / <font class="col_blue2">` +
              this.$refs.huntlistGrid.totalCount +
              `</font>` +
              ' ' +
              this.$_lang('UC.WORD.COUNT') +
              `</span>`,
          });
        }

        if (type == 'linegroup') {
          toolbarItems.push({
            location: 'before',
            html:
              `<div style="margin: bottom 30px">
							<span style="font-weight: bold; font-size: 16px">` +
              this.$_lang('UC.WORD.LINEGROUP') +
              `</span></div>`,
          });
          toolbarItems.push({
            location: 'after',
            html:
              `<span class="fr" style="font-weight: bold">` +
              this.$_lang('UC.WORD.TOTAL') +
              ' ' +
              `<font class="col_blue2">` +
              this.linegroupGrid.selectedRowCnt +
              ' ' +
              `</font> / <font class="col_blue2">` +
              this.$refs.linegroupGrid.totalCount +
              `</font>` +
              ' ' +
              this.$_lang('UC.WORD.COUNT') +
              `</span>`,
          });
        }
        //toolbar sort
        e.toolbarOptions.items = toolbarItems.sort((a, b) => a.sortIndex - b.sortIndex);
      },

      /**@description 그리드 전체 repaint */
      async repaintAllGrid() {
        this.$refs.huntpilotGrid.repaintData();
        this.$refs.huntlistGrid.repaintData();
        this.$refs.linegroupGrid.repaintData();
      },

      /** @description	 refs 별 그리드데이터 리턴하는 함수
       *  @param {String}  refs 이름
       * 	@returns {Array} 그리드 dataSource
       */
      getGridDataSource(refs) {
        let data = this.$refs[refs].getItems;
        return data;
      },
      /** @description	순서 저장 이벤트
       *  @param {}
       */
      async onUpdateOrder() {
        if (this.flag.search) {
          this.$_Msg(
            '검색조건으로 검색 한 뒤에는 순서저장을 실행할수 없습니다. 조건없이 전체 조회 후 다시 시도해주세요.',
          );
          return false;
        }
        if (this.dragData.huntpilot.length === 0) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NO_CHANGED', { defaultValue: '변경된 데이터가 없습니다.' }));
          return false;
        }
        // const params = { pilotList: this.dragData.huntpilot };
        const params = this.dragData.huntpilot;
        const payload = {
          actionName: 'EUC_HUNTPILOT_UPDATE',
          data: params,
          loading: true,
        };

        const confirm = '리스트 순서가 같이 변경됩니다. 파일럿 순서를 변경하시겠습니까? ';
        if (await this.$_Confirm(confirm)) {
          const res = await this.CALL_EUC_API(payload);
          if (isSuccess(res)) {
            await this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE'));
            await this.selectDataList();
            // await this.clearFilterAndSelect();
          } else {
            await this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_ERROR'));
          }
        }
      },

      /**@description			헌트 파일럿 그리드 순서 변경 이벤트
       * @param 	{Object}	그리드 dragging 이벤트 데이터
       * @returns {}
       */
      onChangePilotOrd(e) {
        let data = [];
        if (this.dragData.huntpilot.length > 0) {
          data = this.dragData.huntpilot;
        } else {
          data = this.getGridDataSource('huntpilotGrid');
        }

        const visibleRows = e.component.getVisibleRows();
        const toIndex = data.findIndex(item => item.id === visibleRows[e.toIndex].data.id);
        const fromIndex = data.findIndex(item => item.id === e.itemData.id);

        const newDataSource = [...data];
        newDataSource.splice(fromIndex, 1);
        newDataSource.splice(toIndex, 0, e.itemData);

        if (fromIndex < toIndex) {
          // 아래로 이동할 때
          for (let i = fromIndex; i <= toIndex; i++) {
            newDataSource[i].huntpilotOrd = i + 1;
          }
        } else {
          // 위로 이동할 때
          for (let i = toIndex; i <= fromIndex; i++) {
            newDataSource[i].huntpilotOrd = i + 1;
          }
        }

        // 최종적으로 전체 배열의 ord 값을 순서대로 재설정
        newDataSource.forEach((item, index) => {
          item.huntpilotOrd = index + 1;
        });
        this.dragData.huntpilot = newDataSource;
        this.huntpilotGrid.dataSource = newDataSource;
      },
      /** @description	Her 번호 저장 함수
       *  @param {}
       */
      async onUpdateHerNum() {
        const selectRows = this.$refs.huntpilotGrid.getInstance.getSelectedRowsData();
        if (selectRows.length === 0) {
          await this.$_Msg('선택한 항목이없습니다.');
          return;
        }
        // const params = { pilotList: selectRows };
        const params = selectRows;
        const payload = {
          actionName: 'EUC_HERNUM_UPDATE',
          data: params,
          loading: false,
        };

        const confirm = 'Her 번호를 저장하시겠습니까? ';
        if (await this.$_Confirm(confirm)) {
          const res = await this.CALL_EUC_API(payload);
          if (isSuccess(res)) {
            await this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE'));
            await this.selectDataList();
          } else {
            await this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_ERROR'));
          }
        }
      },
      async selectCmList(sort = '+cmOrd') {
        const params = { sort: sort, viewFl: 'Y' };
        const payload = {
          actionName: 'EUC_CM_LIST_ALL',
          data: params,
          loading: false,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          await this.setCmName(res);
        }
      },
      setCmName(res) {
        const length = res.data.data?.length;
        if (length > 0) {
          this.cmList = res.data.data;
          // FIXME 2024-04-11 jhsim cepm에서 cmNm, cmDescription을 같이주면 삭제
          for (let i = 0; i < this.cmList.length; i++) {
            delete this.cmList[i].id;
            delete this.cmList[i].cmIp;
            delete this.cmList[i].passwd;
            delete this.cmList[i].pinPasswd;
            delete this.cmList[i].regDt;
            delete this.cmList[i].siteId;
            delete this.cmList[i].tenantId;
            delete this.cmList[i].userId;
            delete this.cmList[i].vender;
            delete this.cmList[i].viewFl;
          }
        }
      },
      /**@description 파티션 목록 가져오는 메서드 */
      async selectPartitionList(sort = 'regDt,id') {
        const params = { sort: sort, viewFl: 'Y' };
        const payload = {
          actionName: 'EUC_PARTITION_LIST_ALL',
          data: params,
          loading: false,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          if (res.data.data.length > 0) {
            this.partitionList = res.data.data;
          }
        }
      },
      async selectIptCode(sort = 'id') {
        const params = { sort: sort, codeKey: 'hernum', viewFl: 'Y' };
        const payload = {
          actionName: 'EUC_CODE_LIST_ALL',
          data: params,
          loading: false,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          let arr = [];
          res.data.data.forEach(data => {
            // if (data.parentId != null) {
            arr.push(data);
            // }
          });
          this.codeList = arr;
        }
      },

      /**@description 전체 그리드 초기화 */
      async resetAllGridList() {
        this.huntlistGrid.dataSource = [];
        this.huntlistGrid.selectedRowCnt = 0;
        this.$refs.huntlistGrid.totalCount = 0;
        this.linegroupGrid.dataSource = [];
        this.linegroupGrid.selectedRowCnt = 0;
        this.$refs.linegroupGrid.totalCount = 0;
        this.linegroupDnGrid.dataSource = [];
        this.linegroupDnGrid.selectedRowCnt = 0;
        this.$refs.linegroupDnGrid.totalCount = 0;
        this.dragData.huntpilot = [];
        await this.$refs.huntpilotGrid.clearFilter();
        await this.$refs.huntlistGrid.clearFilter();
        await this.$refs.linegroupGrid.clearFilter();
        await this.$refs.linegroupDnGrid.clearFilter();
        // await this.repaintAllGrid();
      },
      /** @description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {
        this.huntpilotGrid.showActionButtons.customButtons = this.getCustomButtons('HUNTPILOT');
        this.huntlistGrid.showActionButtons.customButtons = this.getCustomButtons('HUNTLIST');
        this.linegroupGrid.showActionButtons.customButtons = this.getCustomButtons('LINEGROUP');
        this.linegroupDnGrid.showActionButtons.customButtons = this.getCustomButtons('LINEGROUPDN');
      },
      /** @description : 라이프사이클 mounted시 호출되는 메서드 */
      async mountData() {
        this.searchType.paramsData = null;
        await this.selectCmList();
        await this.selectIptCode();
        await this.selectDataList();
        let params = this.codeList[0].codeValue;
        this.setCustomTypes('herNum', params);
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountData();
    },
  };
</script>
<style scoped>
  .sub_new_style01 .page_search_box .inner div {
    display: inline-block;
  }
  .table_form tbody td {
    border: 1px solid #ddd;
    /* padding: 10px 20px; */
  }
  .table_form tbody th {
    border: 1px solid #ddd;
    /* padding: 10px 20px; */
  }
</style>
