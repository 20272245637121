<template>
  <aside>
    <div class="side-top right_log">
      <button type="button" data-menu="" @click="toggleSidePannel">
        <img src="@/assets/images/lnb_icon1.png" alt="ESP" />
      </button>
    </div>
    <!-- side pannel icon -->
    <div class="pannel-area" style="height: calc(100% - 60px)">
      <dx-scroll-view width="100%" height="100%">
        <ul>
          <li v-for="item in treeMenuList" :key="item.id" :class="[item.menuIcon, { sel: item.selected }]" @click="goLinkUrl(item)">
            <button type="button" class="side-obj" :data-menu="item.id">
              <img
                :src="getIconSrc(item.menuIcon)"
                alt=""
                class="lnb_icon_box filter-themes"
                :style="{ filter: item.menuIcon === 'menu-favorite' ? 'none' : '' }"
              />
              <span class="m_txt">{{ item.menuNm }}</span>
            </button>
          </li>
        </ul>
      </dx-scroll-view>
    </div>
    <!-- side pannel 모음 -->
    <div class="pannel-group">
      <div v-for="item in treeMenuList" :key="item.menuIcon + item.id">
        <div
          :id="`side_${item.id}`"
          :class="[item.menuTypeCd === enums.common.menuType.LINK.value ? '' : 'side-pannel', item.selected ? 'show' : '']"
        >
          <div class="pannel-cont">
            <dx-scroll-view width="100%" height="100%">
              <div class="tbl-acco">
                <dl>
                  <div v-for="data in item.children" :key="data.menuNm + 'title' + data.id">
                    <dt :class="data.selected ? 'sel' : ''" @click="onSideDtClick($event, item.id)">
                      <a>
                        <span class="title">{{ data.menuNm }}</span>
                      </a>
                    </dt>
                    <dd :style="data.selected ? 'display: block;' : ''" :key="data.menuNm + 'submenu' + data.id">
                      <span
                        v-for="menu in data.children"
                        :key="data.id + menu.id"
                        class="cursor-pointer"
                        :class="menu.selected ? 'on' : ''"
                      >
                        <a @click="goMenuUrl(menu, $event)">
                          {{ menu.menuNm }}
                        </a>
                      </span>
                    </dd>
                  </div>
                </dl>
              </div>
            </dx-scroll-view>
          </div>
        </div>
        <div id="app1" :key="'app1' + item.id">
          <window-popup v-model="windowPopup" :urls="url">팝업창의 내용입니다.</window-popup>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
  import { DxScrollView } from 'devextreme-vue/scroll-view';
  import { isEmpty } from '@/plugins/common-lib';
  import WindowPopup from './window-popup.vue';
  import enums from '@/configs/enums';

  const $ = window.$;

  export default {
    components: { DxScrollView, WindowPopup },
    data() {
      return {
        drawer: true,
        menuList: null,
        selectedItems: null,
        animationDuration: 300,
        multiple: true,
        collapsible: true,
        windowPopup: false,
        url: '',
        treeMenuList: [],
        menuIcons: [],
      };
    },
    computed: {
      enums() {
        return enums;
      },
    },
    watch: {
      treeMenuList() {
        // 사이드판넬 이벤트 추가
        this.$nextTick(() => {
          this.actSidePannel();
        });
      },
      '$store.getters.getFavoriteMenu': {
        handler(newValue, oldValue) {
          if (newValue?.length || oldValue?.length) {
            this.treeMenuList = this.setTreeMenuList();
          }
        },
        deep: true,
      },
      '$route.path'() {
        this.$nextTick(() => {
          this.treeMenuList = this.setTreeMenuList();
        });
      },
    },
    methods: {
      /**
       * @description 메뉴 데이터 트리 구조로 변환
       * @return {Array}
       */
      setTreeMenuList() {
        const currentPath = this.$route.path;
        let menuId = this.$route.meta.menuId;
        let menuList = this.getMergedMenuList();
        menuList = this.setSelectedState(menuList); // selected 상태 추가
        this.setSelectParentAndCurrentMenus(menuList, menuId, currentPath); // 현재 메뉴와 상위 메뉴 선택

        // 메뉴 데이터를 트리 구조로 변환하여 저장 후 반환
        this.menuList = this.convertToTreeStructure(menuList);
        return this.menuList;
      },
      /**
       * @description 즐겨찾기 메뉴와 스토어의 메뉴 리스트를 병합
       * @return {Array}
       */
      getMergedMenuList() {
        if (this.$store.getters.getFavoriteMenu?.length) {
          const favoriteMenu = this.setFavoriteMenu();
          return [...favoriteMenu, ...this.$store.getters.getMenuList];
        }
        return this.$store.getters.getMenuList;
      },
      /**
       * @description 메뉴 권한 필터와 기본 상태(selected 초기화) 적용
       * @param menuList
       * @return {Array}
       */
      setSelectedState(menuList) {
        return menuList
          .filter(menu => menu.authUseFl === 'Y') // 권한 사용 여부 체크
          .map(menu => ({ ...menu, selected: false })); // 선택 상태 초기화
      },
      /**
       * @description 현재 메뉴와 모든 상위 메뉴를 선택 처리 (n-depth 지원)
       * @param menuList
       * @param menuId
       * @param currentPath
       */
      setSelectParentAndCurrentMenus(menuList, menuId, currentPath) {
        if (!currentPath || currentPath === '/') {
          this.$parent.getWrapElement().classList.remove('left-show'); // 경로 없을 시 메뉴 숨김
          return;
        }

        const targetMenu = menuList.find(menu => menu.id === menuId || menu.pageUrl === currentPath);

        if (targetMenu) {
          this.setSelectedParentMenus(menuList, targetMenu); // 상위 메뉴 선택
          this.setUnSelectedMenus(menuList, targetMenu); // 형제 메뉴 선택 해제
        }
      },
      /**
       * @description 재귀적으로 상위 메뉴를 선택하는 로직 (n-depth 지원)
       * @param menuList
       * @param target
       */
      setSelectedParentMenus(menuList, target) {
        let current = target;

        while (current) {
          current.selected = true; // 현재 메뉴 선택
          current = menuList.find(menu => menu.id === current.parentId); // 상위 메뉴 탐색
        }
      },
      /**
       * @description 동일 parentId를 가지는 형제 메뉴 선택 해제
       * @param menuList
       * @param selectedMenu
       */
      setUnSelectedMenus(menuList, selectedMenu) {
        const siblings = menuList.filter(menu => menu.parentId === selectedMenu.parentId);

        siblings.forEach(menu => {
          if (menu.id !== selectedMenu.id) {
            menu.selected = false;
          }
        });
      },
      /**
       * @description 메뉴 데이터를 트리 구조로 변환
       * @param menuList
       * @return {items}
       */
      convertToTreeStructure(menuList) {
        return this.$_commonlib.makeTreeData(menuList);
      },
      closeMenu() {
        const menu = document.getElementById('myMenu');
        const backdrop = document.querySelector('.backdrop-menu');

        if (!menu.classList.contains('open')) {
          return false;
        }

        const closeMenuEvent = new CustomEvent('closeMenu', {
          detail: {
            menu: menu,
          },
        });
        document.dispatchEvent(closeMenuEvent);

        menu.classList.remove('open');
        if (backdrop) {
          backdrop.remove();
        }

        document.querySelectorAll('.header.side-menu-right').forEach(header => {
          header.classList.remove('side-menu-right');
        });

        document.body.classList.remove('side-menu');
      },
      // 클릭 시 메뉴 on 제거, 메뉴영역 활성화시엔 토글
      toggleSidePannel() {
        const menu = this.menuList[0];
        const isFirstMenuSelected = this.$route.path === '/' && !this.menuList[0].selected;
        if (isFirstMenuSelected) {
          this.goLinkUrl(menu);
          menu.selected = true;
        }

        this.$parent.getWrapElement().classList.toggle('left-show');
      },
      onSideDtClick(event, id) {
        const accoList1 = $(`#side_${id}.side-pannel`).find('.tbl-acco');
        const accoTitle1 = accoList1.find('dt');
        const accoCont1 = accoList1.find('dd');

        if ($(event.target).closest('dt').hasClass('sel')) {
          accoTitle1.removeClass('sel');
          accoCont1.slideUp(200);
        } else {
          accoTitle1.removeClass('sel');
          accoCont1.slideUp(200);
          $(event.target).closest('dt').addClass('sel');
          $(event.target).closest('dt').next('dd').slideDown(200);
        }
      },
      goLinkUrl(menu) {
        if (menu.menuTypeCd === enums.common.menuType.LINK.value && menu.pageUrl) {
          this.windowPopup = true;
          this.url = menu.pageUrl.replace('#user#', this.$store.getters.getLoginId);
        }

        // 모바일에서 탭이동 이벤트 적용
        document.querySelectorAll('.ms_menu').forEach(item => item.classList.remove('on'));
        document.querySelector(`#side_${menu.id}`).classList.add('on');
      },
      goMenuUrl(menu, event) {
        if (event.ctrlKey) {
          this.windowPopup = true;
          this.url = menu.pageUrl;
        } else {
          // 메뉴 이동 시, 하이라이트 추가
          $('.tbl-acco dd  SPAN').removeClass('on');
          event.currentTarget.parentNode.classList.add('on');

          if (menu.menuTypeCd === enums.common.menuType.LINK.value && menu.pageUrl != null) {
            // 링크 메뉴
            this.windowPopup = true;
            this.url = this.makeUrl(menu.pageUrl);
          } else {
            this.$router.push(menu.pageUrl);
          }
        }
      },
      // 클릭 시 메뉴 on 제거, 메뉴영역 활성화시엔 토글,
      actSidePannel() {
        const menuObj = $('.side-obj');
        const pnlObj = $('.side-pannel');
        const _$store = this.$store;

        menuObj.off('click').on('click', function () {
          // [클릭시] 왼쪽 2차 메뉴 호출
          $('#wrap').addClass('left-show');

          let pnlCate = $(this).data('menu');
          if ($(this).parent('li').hasClass('sel')) {
            /** 선택된 대메뉴로 다시 들어갈때 기존 펼처저 있는 메뉴 유지 **/
            menuObj.parent('li').removeClass('sel');
            pnlObj.removeClass('show');

            switch (pnlCate) {
              case 'dashboards': // ECS 대시보드 슬라이드
                break;
              case 'operationmng': // ECS 운영관리 슬라이드
                break;
              case 'report': // ECS 보고서 슬라이드
                break;
              case 'monitoring': // ECS 모니터링 슬라이드
                break;
              case 'preferences': // ECS 환경설정 슬라이드
                break;
            }

            $(this).parent('li').addClass('sel');
            $('#side_' + pnlCate).addClass('show');
          } else {
            /** 선택된 대메뉴로 가 아닌 펼처야 할 메뉴 펼칠때 다른 메뉴위치 초기화 **/
            menuObj.parent('li').removeClass('sel');
            pnlObj.removeClass('show');
            switch (pnlCate) {
              case 'dashboards': // ECS 대시보드 슬라이드
                break;
              case 'operationmng': // ECS 운영관리 슬라이드
                break;
              case 'report': // ECS 보고서 슬라이드
                break;
              case 'monitoring': // ECS 모니터링 슬라이드
                break;
              case 'preferences': // ECS 환경설정 슬라이드
                break;
            }

            $('#side_' + pnlCate).on('', function () {
              // 접근성 관련 작업 - 사이드패널 관련 focus 및 tabindex 추가
              $(this).find('a, button').attr({ tabIndex: '0' });
              $(this).find('a, button').eq(0).focus();
            });

            $(this).parent('li').addClass('sel');
            $('#side_' + pnlCate).addClass('show');
          }
        });

        menuObj.off('mouseenter, mouseover').on('mouseenter, mouseover', function () {
          // [마우스] 오버시 왼쪽 2차 메뉴 호출
          if (_$store.getters.getIsShowMyLayerLayout) return;

          $('#wrap').addClass('left-show');
          let pnlCate = $(this).data('menu');
          if ($(this).parent('li').hasClass('sel')) {
            /** 선택된 대메뉴로 다시 들어갈때 기존 펼처저 있는 메뉴 유지 **/
            menuObj.parent('li').removeClass('sel');
            pnlObj.removeClass('show');

            switch (pnlCate) {
              case 'dashboards': // ECS 대시보드 슬라이드
                break;
              case 'operationmng': // ECS 운영관리 슬라이드
                break;
              case 'report': // ECS 보고서 슬라이드
                break;
              case 'monitoring': // ECS 모니터링 슬라이드
                break;
              case 'preferences': // ECS 환경설정 슬라이드
                break;
            }

            $(this).parent('li').addClass('sel');
            $('#side_' + pnlCate).addClass('show');
          } else {
            /** 선택된 대메뉴로 가 아닌 펼처야 할 메뉴 펼칠때 다른 메뉴위치 초기화 **/
            menuObj.parent('li').removeClass('sel');
            pnlObj.removeClass('show');

            switch (pnlCate) {
              case 'dashboards': // ECS 대시보드 슬라이드
                break;
              case 'operationmng': // ECS 운영관리 슬라이드
                break;
              case 'report': // ECS 보고서 슬라이드
                break;
              case 'monitoring': // ECS 모니터링 슬라이드
                break;
              case 'preferences': // ECS 환경설정 슬라이드
                break;
            }

            $('#side_' + pnlCate).on('', function () {
              // 접근성 관련 작업 - 사이드패널 관련 focus 및 tabindex 추가
              $(this).find('a, button').attr({ tabIndex: '0' });
              $(this).find('a, button').eq(0).focus();
            });

            $(this).parent('li').addClass('sel');
            $('#side_' + pnlCate).addClass('show');
          }

          // 접근성 관련 작업 - 사이드패널 관련 inter-action 추가
          $(document)
            .find('body')
            .off('mouseover.side-pannel')
            .on('mouseover.side-pannel', function (e) {
              // [마우스] 오버시 왼쪽 2차 메뉴 닫기
              if (!$('aside').has(e.target).length && !_$store.getters.getIsShowMyLayerLayout && !$('.popDim').has(e.target).length) {
                pnlObj.find('a, button').attr({ tabIndex: '-1' });
                $('#wrap').removeClass('left-show');
              }
            });
        });
      },

      showMyProfile() {
        let btnObj = $('header').find('.btn-profile');
        let profile_prop = false;
        btnObj.on('click', function () {
          if (!profile_prop) {
            profile_prop = true;
            $('header').find('.btn-profile').addClass('sel');
            $('header').find('.my-profile').addClass('show');
            // 접근성 관련 작업 - tabindex 변경
            $('header').find('.my-profile').find('a').attr({ tabIndex: '0' });
          } else {
            profile_prop = false;
            $('header').find('.btn-profile').removeClass('sel');
            $('header').find('.my-profile').removeClass('show');
            // 접근성 관련 작업 - tabindex 변경
            $('header').find('.my-profile').find('a').attr({ tabIndex: '-1' });
          }
          $(document)
            .on('click.myprofile')
            .on('click.myprofile', function (e) {
              if (
                !$('header .my-profile').has(e.target).length &&
                e.target.className.indexOf('btn-profile') < 0 &&
                e.target.className.indexOf('my-profile') < 0
              ) {
                profile_prop = false;
                $('header').find('.btn-profile').removeClass('sel');
                $('header').find('.my-profile').removeClass('show');
                // 접근성 관련 작업 - tabindex 변경
                $('header').find('.my-profile').find('a').attr({ tabIndex: '-1' });
              }
            });
          // 접근성 관련 작업 - 내정보 포커스 관련 inter-action 추가
          $(document)
            .find('body')
            .off('focus.myprofile')
            .on('focus.my-profile', function (e) {
              if (!$('.my-profile').has(e.target).length && e.target.className.indexOf('btn-profile') < 0) {
                profile_prop = false;
                $('header').find('.btn-profile').removeClass('sel');
                $('header').find('.my-profile').removeClass('show');
                $('header').find('.my-profile').find('a').attr({ tabIndex: '-1' });
              }
            });
        });
      },
      /**
       * 즐겨찾기 메뉴 설정
       *
       * @return {*}
       */
      setFavoriteMenu() {
        const menuList = this.$store.getters.getMenuList || [];
        const favoriteParentMenu = {
          id: 99999999,
          menuDepth: 1,
          menuNm: '즐겨찾기',
          menuOrd: -1,
          menuTypeCd: enums.common.menuType.NORMAL_MENU.value,
          menuIcon: 'menu-favorite',
          viewFl: enums.common.stringViewFlag.YES.value,
          parentId: null,
          authUseFl: enums.common.stringViewFlag.YES.value,
        };
        const favoriteParentMenu2 = {
          id: 999999999,
          menuDepth: 2,
          menuNm: '나의 즐겨찾기',
          menuOrd: -1,
          menuTypeCd: enums.common.menuType.NORMAL_MENU.value,
          menuIcon: null,
          viewFl: enums.common.stringViewFlag.YES.value,
          parentId: favoriteParentMenu.id,
          authUseFl: enums.common.stringViewFlag.YES.value,
        };

        return this.$store.getters.getFavoriteMenu
          .map(item => item.menuId)
          .reduce(
            (result, id) => {
              const findMenu = menuList.find(menu => menu.id === id);
              if (!isEmpty(findMenu)) {
                result.push({ ...findMenu, parentId: favoriteParentMenu2.id, menuDepth: 3 });
              }
              return result;
            },
            [favoriteParentMenu, favoriteParentMenu2],
          );
      },
      getStoreData(keys) {
        const storeState = this.$store.state;
        const result = {};
        keys.forEach(key => {
          if (Object.prototype.hasOwnProperty.call(storeState, key)) {
            result[key] = storeState[key];
          }
        });
        return result;
      },
      getUrl(url, type) {
        const obj = new URL(url);
        return obj[type];
      },
      makeQuery(data) {
        const params = new URLSearchParams(data);
        return params.toString();
      },
      makeUrl(url) {
        const keys = this.getUrl(url, 'search').replace('?', '').split(',');
        const hardQueryString = keys.filter(key => key.indexOf('=') > -1);
        const hardKeys = hardQueryString.reduce((map, item) => {
          const [key, value] = item.split('=');
          map[key] = value;
          return map;
        }, {});
        const data = this.getStoreData(keys);
        const queryString = this.makeQuery({ ...data, ...hardKeys });
        const port = this.getUrl(url, 'port') ? ':' + this.getUrl(url, 'port') : '';
        return this.getUrl(url, 'protocol') + '//' + this.getUrl(url, 'hostname') + port + this.getUrl(url, 'pathname') + '?' + queryString;
      },
      /**
       * 메뉴 아이콘 SRC 반환
       *
       * @param value
       * @return {*}
       */
      getIconSrc(value) {
        if (!value) {
          return '';
        }

        const icon = this.menuIcons.find(icon => icon.value === value);
        if (icon) {
          return icon?.src;
        } else if (value === 'menu-favorite') {
          return require('@/assets/images/menu-favorite.svg');
        }
        return '';
      },
    },
    mounted() {
      this.menuIcons = this.$_theme.menuIcons;
      this.treeMenuList = this.setTreeMenuList();
      this.showMyProfile();
    },
  };
</script>

<style scoped>
  .wrap .pannel-group {
    display: none;
  }

  .wrap.left-show .pannel-group {
    display: block;
  }

  .side-obj:after {
    content: '';
    position: absolute;
    bottom: -14px;
    left: 14px;
    width: 44px;
    height: 1px;
    background: #e9ecef;
  }
</style>
