<!--
  PACKAGE_NAME : src\pages\esp\user\management\config.vue
  FILE_NAME : config
  AUTHOR : devyoon91
  DATE : 2024-08-29
  DESCRIPTION : 계정 등록/수정 페이지
-->
<template>
  <div>
    <div class="page-sub-box clearfix ui-glid-box top-box">
      <div class="fl per50">
        <table class="table_form line-bin">
          <caption>
            <strong>계정관리</strong>
          </caption>
          <colgroup>
            <col style="width: 130px" />
            <col style="width: auto" />
          </colgroup>

          <thead class="sub_title_txt">
            <tr>
              <td colspan="2">
                <h2>계정 정보</h2>
              </td>
            </tr>
          </thead>

          <tbody>
            <tr>
              <th scope="row">
                <label for="label5">
                  <span>아이디</span>
                  <span class="icon_require">필수항목</span>
                </label>
              </th>
              <td class="clearfix">
                <dx-text-box
                  placeholder="사용자ID"
                  v-model="formData.loginId"
                  :disabled="config.isUpdateMember"
                  :show-clear-button="true"
                  :max-length="50"
                  width="80%"
                  :height="30"
                  styling-mode="outlined"
                  class="mar_ri10"
                >
                  <dx-validator validation-group="validationGroupName">
                    <dx-async-rule :validation-callback="checkValidLoginId" message="이미 사용하고 있는 ID 입니다." />
                    <dx-required-rule message="사용자ID는 필수입니다." />
                    <dx-custom-rule :validation-callback="checkEngAndNumber" message="영문, 숫자로만 구성되어야 합니다. 다시 입력하세요." />
                  </dx-validator>
                </dx-text-box>
              </td>
            </tr>

            <tr>
              <th scope="row">
                <label for="label5">
                  <span>이름</span>
                  <span class="icon_require">필수항목</span>
                </label>
              </th>
              <td class="clearfix">
                <dx-text-box
                  v-model="formData.loginNm"
                  placeholder="사용자명"
                  :show-clear-button="true"
                  :max-length="50"
                  width="80%"
                  :height="30"
                  styling-mode="outlined"
                  class="mar_ri10"
                >
                  <dx-validator validation-group="validationGroupName">
                    <dx-required-rule message="사용자명은 필수입니다." />
                  </dx-validator>
                </dx-text-box>
              </td>
            </tr>

            <tr v-if="getAuthType === 'DEFAULT' && config.isUpdateMember && formData.changedPwdFl">
              <th scope="row">
                <label for="label5">
                  <span>패스워드 변경<br />유형</span>
                  <span class="icon_require">필수항목</span>
                  <button
                    class="btn-question"
                    id="tooltips1"
                    @mouseenter="setTooltips('changePwdType')"
                    @mouseleave="setTooltips('changePwdType')"
                  />
                  <dx-popover target="#tooltips1" :visible="config.isTooltip.changePwdType" :hide-on-outside-click="false">
                    <span
                      >‘새 패스워드’는 사용자가 수정된 패스워드를 계속 사용하도록 허용합니다.<br />
                      ‘1회용 임시 패스워드’는 사용자의 로그인 직후 패스워드 변경이 반드시 필요합니다.
                    </span>
                  </dx-popover>
                </label>
              </th>
              <td class="clearfix">
                <dx-select-box
                  v-model="formData.passwordType"
                  placeholder="패스워드 변경 유형"
                  :items="config.passwordTypeList"
                  display-expr="title"
                  value-expr="value"
                  @value-changed="handlePasswordType"
                  styling-mode="outlined"
                  width="80%"
                  :height="30"
                >
                  <dx-validator validation-group="validationGroupName">
                    <dx-required-rule message="패스워드 변경 유형은 필수값입니다." />
                  </dx-validator>
                </dx-select-box>
              </td>
            </tr>

            <tr v-if="getAuthType === 'DEFAULT'">
              <th scope="row">
                <label for="label5">
                  <span>패스워드</span>
                  <span class="icon_require">필수항목</span>
                  <button
                    v-if="formData.changedPwdFl"
                    class="btn-question"
                    id="tooltips2"
                    @mouseenter="setTooltips('changePwd')"
                    @mouseleave="setTooltips('changePwd')"
                  />
                  <dx-popover
                    v-if="formData.changedPwdFl"
                    target="#tooltips2"
                    :visible="config.isTooltip.changePwd"
                    :close-on-outside-click="false"
                  >
                    <span v-html="config.passwordPolicyToolTip" />
                  </dx-popover>
                </label>
              </th>
              <td class="clearfix">
                <dx-text-box
                  v-model="formData.loginPwd"
                  placeholder="패스워드"
                  mode="password"
                  :disabled="formData.isDisabledPwd"
                  :show-clear-button="true"
                  :max-length="50"
                  styling-mode="outlined"
                  width="80%"
                  :height="30"
                  class="mar_ri10"
                  style="vertical-align: middle"
                >
                  <dx-validator ref="passwordValidateRef" validation-group="passwordValidateGroup">
                    <dx-required-rule message="패스워드는 필수값입니다." />
                    <template>
                      <dx-custom-rule :validation-callback="checkPrePwd" message="이전 패스워드를 다시 사용할 수 없습니다." />
                    </template>
                  </dx-validator>
                </dx-text-box>
                <dx-button
                  v-if="config.isUpdateMember"
                  :text="config.passwdChangeBtnName"
                  style="margin-left: 10px; vertical-align: middle"
                  :width="100"
                  :height="30"
                  class="default filled txt_S medium"
                  :use-submit-behavior="false"
                  @click="onClickPasswordChange"
                />
              </td>
            </tr>

            <tr v-if="getAuthType === 'DEFAULT'">
              <th scope="row">
                <label for="label5">
                  <span>패스워드 확인</span>
                  <span class="icon_require">필수항목</span>
                </label>
              </th>
              <td class="clearfix">
                <dx-text-box
                  v-model="formData.loginPwdConfirm"
                  placeholder="패스워드 확인"
                  mode="password"
                  :show-clear-button="true"
                  :disabled="formData.isDisabledPwd"
                  :max-length="50"
                  styling-mode="outlined"
                  width="80%"
                  :height="30"
                  class="mar_ri10"
                >
                  <dx-validator ref="passwordConfirmValidateRef" validation-group="passwordValidateGroup">
                    <dx-required-rule message="패스워드 확인값은 필수값입니다." />
                    <dx-compare-rule :comparison-target="passwordComparison" message="패스워드값과 일치하지 않습니다." />
                  </dx-validator>
                </dx-text-box>
              </td>
            </tr>

            <tr>
              <th scope="row">
                <label for="label5">
                  <span>계정 권한</span>
                  <span class="icon_require">필수항목</span>
                  <template>
                    <button
                      class="btn-question"
                      id="tooltips2"
                      @mouseenter="setTooltips('selectAuth')"
                      @mouseleave="setTooltips('selectAuth')"
                    ></button>

                    <dx-popover target="#tooltips2" :visible="config.isTooltip.selectAuth" :hide-on-outside-click="false">
                      <span>권한을 선택하여 메뉴와 검색 대상 노출을 자동 선택할 수 있습니다.</span>
                    </dx-popover>
                  </template>
                </label>
              </th>
              <td class="clearfix">
                <dx-drop-down-box
                  ref="authIdDropDown"
                  v-model="formData.authId"
                  :data-source="config.authList"
                  display-expr="authNm"
                  value-expr="id"
                  placeholder="계정 권한 선택"
                  styling-mode="outlined"
                  width="80%"
                  :height="30"
                  class="mar_ri10"
                  :input-attr="{ 'aria-label': 'Owner' }"
                  :defer-rendering="false"
                  :show-clear-button="false"
                  :opened="config.isTreeBoxOpened"
                  @opened="onOpened"
                >
                  <template #content="{}">
                    <dx-tree-view
                      :data-source="config.authList"
                      data-structure="plain"
                      key-expr="id"
                      display-expr="authNm"
                      parent-id-expr="parentId"
                      :expand-all-enabled="true"
                      :select-by-click="true"
                      selection-mode="single"
                      @item-selection-changed="onChangedAuth"
                    />
                  </template>
                  <dx-validator validation-group="validationGroupName">
                    <dx-compare-rule :comparison-target="compareAuth" message="계정권한은 필수값입니다." />
                  </dx-validator>
                </dx-drop-down-box>
              </td>
            </tr>

            <tr v-if="config.isUpdateMember">
              <th scope="row">
                <label for="label5">
                  <span>계정 상태</span>
                </label>
              </th>
              <td>
                {{ formData.memberStateNm }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <section class="terms bottom-btn-box">
      <div class="page-sub-box">
        <div class="bottom-btn-wrap">
          <dx-button
            :text="config.saveBtnName"
            :width="120"
            :height="40"
            class="default filled txt_S medium"
            validation-group="validationGroupName"
            :use-submit-behavior="true"
            @click="saveMember"
          />
          <dx-button :text="config.cancelBtnName" :width="120" :height="40" class="white filled txt_S medium" @click="handleCancelEvent" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import { DxAsyncRule, DxCompareRule, DxCustomRule, DxRequiredRule, DxValidator } from 'devextreme-vue/validator';
  import { DxTextBox } from 'devextreme-vue/text-box';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { DxButton } from 'devextreme-vue/button';
  import { DxPopover } from 'devextreme-vue/popover';
  import DxTreeView from 'devextreme-vue/tree-view';

  import { getRegexPattern, isSuccess, encryptPassword } from '@/plugins/common-lib';
  import DxDropDownBox from 'devextreme-vue/drop-down-box';
  import {stringToBoolean} from "../../../../plugins/common-lib";

  export default {
    components: {
      DxDropDownBox,
      DxValidator,
      DxRequiredRule,
      DxCompareRule,
      DxAsyncRule,
      DxCustomRule,
      DxTextBox,
      DxSelectBox,
      DxButton,
      DxPopover,
      DxTreeView,
    },
    data() {
      return {
        config: {
          isTreeBoxOpened: false, // 계정권한 selectbox tree 오픈 여부
          isUpdateMember: false, // 계정 수정 여부
          saveBtnName: this.$_lang('COMPONENTS.SAVE', { defaultValue: '저장' }),
          cancelBtnName: this.$_lang('COMPONENTS.CANCEL', { defaultValue: '취소' }),
          passwdChangeBtnName: this.$_lang('COMPONENTS.PASSWORD_CHANGE', { defaultValue: '패스워드 변경' }),
          authList: [], // 권한 리스트
          isTooltip: {
            changePwdType: false, // 패스워드 변경 유형 여부
            changePwd: false, // 패스워드 툴팁
            selectAuth: false, // 권한선택 여부
          },
          passwordPolicyToolTip: '', // 패스워드 정책 문구
          passwordTypeList: [
            //패스워드 변경 유형 여부
            {
              title: '새 패스워드',
              value: 'NEW',
            },
            {
              title: '1회용 임시 패스워드',
              value: 'TEMP',
            },
          ],
        },
        formData: {
          id: null, // Member Entity Id
          loginId: '', // 사용자ID
          loginNm: '', // 사용자명
          loginPwd: '', // 패스워드 필드
          loginPwdConfirm: '', // 패스워드 확인 필드
          prePwd: '', // 패스워드 변경 전 패스워드
          authId: -1, // NumberFormatException 때문에 -1로 선언
          changedPwdFl: true, // 패스워드 변경 여부
          isDisabledPwd: true, // 패스워드 입력창 비활성화 여부
          memberStateCd: null, // 계정 상태 코드
          memberStateNm: null, // 계정 상태 명
          passwordType: null, // 패스워드 변경 유형
        },
      };
    },
    watch: {
      '$route.query': {
        handler: 'initData',
        deep: true,
      }
    },
    computed: {
      /**
       * @description 시스템설정 사용자 인증 방식 가져오기
       * @return {*|string|string}
       */
      getAuthType() {
        return this.$_getSystemData('auth_type')?.configValue || 'DEFAULT';
      },
      /**
       * @description 시스템설정 기본 패스워드 가져오기
       * @return {*|string}
       */
      getSystemDefaultPwd() {
        return this.$_getSystemData('default_pwd').configValue;
      },
    },
    methods: {
      /** @description : 패스워드 변경 버튼 클릭 이벤트 */
      onClickPasswordChange() {
        // 패스워드 변경 버튼 클릭시 패스워드 변경 여부에 따라 패스워드 입력창 활성화/비활성화
        if (!this.formData.changedPwdFl) {
          this.config.passwdChangeBtnName = this.$_lang('COMPONENTS.CANCEL', { defaultValue: '취소' });
          this.formData.passwordType = null;
          this.formData.changedPwdFl = true;
          this.formData.isDisabledPwd = true;
          this.formData.loginPwd = '';
          this.formData.loginPwdConfirm = '';
        } else {
          this.config.passwdChangeBtnName = this.$_lang('COMPONENTS.PASSWORD_CHANGE', { defaultValue: '패스워드 변경' });
          this.formData.passwordType = null;
          this.formData.changedPwdFl = false;
          this.formData.isDisabledPwd = true;
          this.formData.loginPwd = '';
          this.formData.loginPwdConfirm = '';
          this.$nextTick(() => {
            this.$refs.passwordValidateRef?.instance.reset(); // 패스워드 validate 초기화
            this.$refs.passwordConfirmValidateRef?.instance.reset(); // 패스워드 확인 validate 초기화
          });
        }
      },
      /** @description: 패스워드 변경 유형 이벤트 */
      handlePasswordType(e) {
        if (e.value === 'NEW') {
          // 새 패스워드
          this.formData.changedPwdFl = true;
          this.formData.isDisabledPwd = false;
          this.formData.loginPwd = '';
          this.formData.loginPwdConfirm = '';
        } else if (e.value === 'TEMP') {
          // 1회용 임시 패스워드
          this.formData.changedPwdFl = true;
          this.formData.isDisabledPwd = true;
          this.formData.loginPwd = this.getSystemDefaultPwd;
          this.formData.loginPwdConfirm = this.getSystemDefaultPwd;
        }
      },
      /** @description: 툴팁 이벤트 */
      setTooltips(key) {
        this.config.isTooltip[key] = !this.config.isTooltip[key];
      },
      /** @description : 아이디 체크 true/false 리턴 이벤트 */
      checkValidLoginId(e) {
        return this.checkLoginId(e.value);
      },
      /** @description : 아이디 체크 API 호출 메서드  */
      async checkLoginId(loginId) {
        const payload = {
          actionName: 'MEMBER_LIST_ALL',
          data: { loginId: loginId },
        };

        const res = await this.CALL_API(payload);
        if (isSuccess(res)) {
          return res.data.data.length === 0;
        }
        return false;
      },
      /** @description: 영문,숫자인지 체크하는 메서드 */
      checkEngAndNumber(e) {
        return !!getRegexPattern('checkEngAndNumber').test(e.value);
      },
      /** @description : 패스워드 일치하는지 체크하는 이벤트 */
      passwordComparison() {
        return this.formData.loginPwd;
      },
      /** @description: 권한 선택 여부 validate */
      compareAuth() {
        return this.formData.authId < 0 ? 'false' : this.formData.authId;
      },
      /** @description: 변경될 패스워드와 이전 패스워드 체크 */
      checkPrePwd() {
        const changedPwd = encryptPassword(this.formData.loginId, this.formData.loginPwd, this.$store.getters.getEncryptionType);
        return changedPwd !== this.formData.prePwd;
      },
      /** @description : 권한 selectbox 변경 이벤트
       */
      onChangedAuth(e) {
        if (e.itemData) {
          this.formData.authId = e.itemData.id;
          this.config.isTreeBoxOpened = false;
        }
      },
      /** @description : 메뉴권한 selectbox 오픈 이벤트 */
      onOpened() {
        this.config.isTreeBoxOpened = true;
      },
      /** @description : 저장/수정 버튼 클릭시 데이터 저장 */
      async saveMember(e) {
        if (!e.validationGroup.validate().isValid) {
          return;
        }

        let loginPwd = this.formData.loginPwd;
        const loginId = this.formData.loginId;
        const loginNm = this.formData.loginNm;

        // 패스워드 유효성 체크
        if (this.formData.changedPwdFl && !this.validatePassword(loginPwd, loginId, loginNm)) {
          return;
        }

        // 아이디 중복 체크
        if (!this.config.isUpdateMember && !(await this.checkLoginId(loginId))) {
          this.$_Toast(this.$_lang('COMMON.MESSAGE.DUPLICATE_ID', { defaultValue: '이미 사용하고 있는 ID 입니다.' }));
          return;
        }

        if (await this.$_Confirm(this.$_lang('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' }))) {
          //패스워드 hash 설정
          if (this.formData.changedPwdFl) {
            loginPwd = encryptPassword(loginId, loginPwd, this.$store.getters.getEncryptionType);
          }

          const payload = {
            actionName: 'MEMBER_LIST_INSERT',
            data: [
              {
                id: this.formData?.id,
                authId: this.formData?.authId,
                loginId: loginId,
                loginNm: loginNm,
                loginPwd: loginPwd,
                passwordType: this.formData.passwordType, // 패스워드 변경 유형
                memberState:
                  this.formData.passwordType === 'TEMP' || !this.config.isUpdateMember
                    ? this.$_enums.common.memberState.TEMPORARY.value
                    : this.formData.memberStateCd,
              },
            ],
            useErrorPopup: true,
          };

          const res = await this.CALL_API(payload);
          if (isSuccess(res)) {
            this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
            await this.$_setSessionStorageMenu(); //메뉴 sessionStorage 재설정
            await this.$_setStoreSiteTenant(); //사이트,센터 store 재설정
            await this.$router.push('/esp/user/management/list');
          }
        }
      },
      /**
       * @description 패스워드 유효성 체크
       * @param loginPwd
       * @param loginId
       * @param loginNm
       * @return {boolean}
       */
      validatePassword(loginPwd, loginId, loginNm) {
        const passwordValidCheckMessage = this.$_validatePasswordMessage(loginPwd, loginId, loginNm);

        // 패스워드 유효성 체크
        if (passwordValidCheckMessage !== '') {
          this.$_Toast(passwordValidCheckMessage);
          return false;
        }
        return true;
      },
      /** @description : 취소 버튼 클릭시 리스트 페이지로 이동 */
      handleCancelEvent() {
        this.$router.push('/esp/user/management/list');
      },
      /**
       * @description 패스워드 정책 툴팁 내용 설정
       */
      setPasswordPolicyToolTip() {
        const [consecutiveLength, includeLoginId, includeLoginNm, minLength, maxLength, minCategories, specialChars] =
          this.$_getPwdSystemData();

        let messageId = 'COMMON.MESSAGE.PASSWORD_POLICY_NONE';
        if (includeLoginId && includeLoginNm) {
          messageId = 'COMMON.MESSAGE.PASSWORD_POLICY';
        } else if (includeLoginId) {
          messageId = 'COMMON.MESSAGE.PASSWORD_POLICY_ID';
        } else if (includeLoginNm) {
          messageId = 'COMMON.MESSAGE.PASSWORD_POLICY_NAME';
        }

        this.config.passwordPolicyToolTip = this.$_lang(messageId, {
          consecutiveLength,
          minLength,
          maxLength,
          minCategories,
          specialChars,
          defaultValue: `대문자, 소문자, 숫자, 특수문자 중 ${minCategories}가지 이상 조합<br>
                        최소 ${minLength}자 이상, 최대 ${maxLength}자 이하<br>
                        연속된 문자나 숫자 ${consecutiveLength}자 이상 사용 불가<br>
                        ${includeLoginId ? '아이디 포함 불가' : ''}<br>
                        ${includeLoginNm ? '이름 포함 불가' : ''}<br>
                        사용 가능 특수문자 ${specialChars}<br>
                        예) Abc123!@#`,
        });
      },
      /**
       * @description 데이터 초기화
       * @return {Promise<void>}
       */
      async initData() {
        const query = this.$route.query;
        if (!query) {
          this.$_goPrePage();
          return;
        }

        this.config.isUpdateMember = query ? stringToBoolean(query?.isUpdateMember) : false;
        // 수정일 경우 멤버 데이터 조회
        if (this.config.isUpdateMember) {
          this.config.saveBtnName = this.$_lang('COMPONENTS.SAVE', { defaultValue: '저장' });

          const member = await this.getMemberById(Number(query?.id))
          if (member) {
            this.formData.id = member?.id;
            this.formData.loginNm = member?.loginNm;
            this.formData.loginId = member?.loginId;
            this.formData.authId = member?.authId !== null ? Number(member?.authId) : null;
            this.formData.prePwd = member?.prePwd;
            this.formData.memberStateCd = member?.memberStateCd;

            // 계정 상태 명 설정
            this.$_enums.common.memberState.values.filter(item => {
              if (item.value === member?.memberStateCd) {
                this.formData.memberStateNm = item.label;
              }
            });
          }
        }

        this.formData.changedPwdFl = !this.config.isUpdateMember;
        this.formData.isDisabledPwd = this.config.isUpdateMember;
        this.setPasswordPolicyToolTip(); // 패스워드 정책 툴팁 내용 설정
        await this.setAuthList(); // 권한 정보 설정
      },
      /**
       * @description 멤버 데이터 조회 (id 조건)
       * @param id
       * @return {Promise<null>}
       */
      async getMemberById(id) {
        const payload = {
          actionName: 'MEMBER_LIST_ALL',
          data: { memberId: id },
          loading: true,
        };

        const res = await this.CALL_API(payload);
        if (isSuccess(res)) {
          return res.data.data[0];
        }
        return null;
      },
      /** @description: 권한 정보 가져오기 */
      async setAuthList() {
        const payload = {
          actionName: 'AUTH_MENU_SUB_LIST',
          data: { authId: this.$store.getters.getAuthId },
          loading: true,
        };

        const res = await this.CALL_API(payload);
        if (isSuccess(res)) {
          this.config.authList = res.data.data;
        }
      },
    },
    async created() {
      await this.initData();
    },
    destroyed() {
      this.destroyedData();
    },
  };
</script>

<style scoped>
  .ui-glid-box > div.fl,
  .ui-glid-box > div.fr {
    border-right: 0;
  }

  .top-box {
    height: auto;
  }

  .top-box > div {
    height: auto;
  }

  .table_form td > div {
    display: inline-block;
    vertical-align: middle;
  }

  .table_form td .empty-box {
    width: 10px;
  }
</style>
