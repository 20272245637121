<!--
  PACKAGE_NAME : src\pages\report\ewm
  FILE_NAME : event-history
  AUTHOR : hpmoon
  DATE : 2024-10-25
  DESCRIPTION : 보고서 > 예측 > 예측 조회 > 이벤트 전체 이력
-->
<template>
  <DxPopup
      :show-title="true"
      :title="modal.title"
      :min-width="modal.minWidth"
      :width="modal.width"
      :min-height="modal.minHeight"
      :height="modal.height"
      :drag-enabled="modal.dragEnabled"
      :resize-enabled="modal.resizeEnabled"
      :hide-on-outside-click="modal.closeOnOutsideClick"
      :show-close-button="modal.showCloseButton"
      :visible="isOpen"
      @hiding="closeModal"
  >
    <template #content>
      <div>
        <EspDxDataGrid :data-grid="dataGrid" id="eventHistoryGrid" ref="eventHistoryGrid" @saving="onSaveData" @editor-preparing="onEditorPreparing" />
        <div class="content-info-box01 pad_top10 mar_to10">
          {{ $_lang('FORECASTING.MESSAGE.FORECAST_EVENT_INFO_MESSAGE1', {defaultValue: '예측값과 실제값 사이에 큰 차이가 발생하는 경우에는 이벤트로 간주됩니다.'}) }}
          {{ $_lang('FORECASTING.MESSAGE.FORECAST_EVENT_INFO_MESSAGE2', {defaultValue: '사용자가 미리 이벤트로 판단되는 일자를 추가할 수도 있습니다.'}) }}<br/>
          {{ $_lang('FORECASTING.MESSAGE.FORECAST_EVENT_INFO_MESSAGE3', {defaultValue: '이벤트의 원인을 분석하고 유형별로 관리하면, 콜 예측의 정확성을 더욱 높일 수 있습니다.'}) }}

        </div>
      </div>
    </template>
  </DxPopup>
</template>

<script>
import { DxPopup } from 'devextreme-vue/popup';
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid.vue";
import { isEmpty } from "@/plugins/common-lib";
import moment from "moment/moment";

export default {
  components: {
    DxPopup,
    EspDxDataGrid,
  },

  props: {
    isOpen: Boolean,
  },

  watch: {

  },

  data() {
    return {
      modal: {
        title: this.$_lang('FORECASTING.WORD.ALL_EVENTS_HISTORY', {defaultValue: '이벤트 전체 이력'}),
        minWidth: '900',
        width: '900',
        minHeight: '560',
        height: '560',
        dragEnabled: true,
        resizeEnabled: true,
        showCloseButton: true,
        closeOnOutsideClick: false,
      },

      config: {
        stylingMode: 'outlined', //[outlined, filled, underlined]
      },

      dataGrid: {
        disableTotalCount: false,
        refName: 'eventHistoryGrid',
        allowColumnResizing: true, //컬럼 사이즈 허용
        showBorders: false, //border 유무
        showColumnHeaders: true, //컬럼 헤더 유무
        showColumnLines: false, //컬럼 세로선 유무
        showRowLines: true, //컬럼 가로선 유무
        rowAlternationEnabled: false,
        dataSource: [],
        // width: '100%',     // 주석처리시 100%
        height: '400',    // 주석처리시 100%
        apiActionNm: {},
        showActionButtons: {
          customButtons: [
            {
              widget: 'dxButton',
              options: {
                icon: '',
                text: this.$_lang('COMPONENT.DELETE', { defaultValue: '삭제' }),
                elementAttr: { class: 'btn_XS white light_filled trash' },
                width: 60,
                height: 30,
                onClick: () => this.onDeleteData(),
              },
              location: 'before',
            },
          ],
        },
        customEvent: {
          saving: true,
          editorPreparing: true,
        },
        grouping: {
          contextMenuEnabled: false,
          autoExpandAll: false,
          allowCollapsing: true,
          expandMode: 'rowClick', // rowClick or buttonClick
        },
        groupPanel: {
          visible: false,
        },
        columnChooser: {
          enabled: false, // 컬럼 Chooser 버튼 사용유무
        },
        loadPanel: {
          enabled: true, // 로딩바 표시 유무
        },
        sorting: {
          mode: 'multiple', // single multiple
        },
        remoteOperations: {
          // 서버사이드 여부
          filtering: false,
          sorting: false,
          grouping: false,
          paging: false,
        },
        paging: {
          // scrolling 미사용시만 적용됨
          enabled: true,
          pageSize: 10,
          pageIndex: 0, // 시작페이지
        },
        pager: {
          visible: true, //페이저 표시 여부
          showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
          allowedPageSizes: [],
          displayMode: 'compact', //표시 모드 : ['full', 'compact']
          showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
          showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
        },
        filterRow: {
          visible: true,
        },
        headerFilter: {
          visible: false,
        },
        editing: {
          allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
          allowAdding: true, // 추가 버튼을 없애고 싶으면 false설정
          allowDeleting: false,
          mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
          startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
          selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
        },
        selecting: {
          mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
          selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
          showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
        },
        columns: [
          {
            caption: '이벤트 날짜',
            i18n: 'FORECASTING.WORD.EVENT_DATE',
            dataField: 'dt_date',
            height: 40,
            alignment: 'center', // left center right
            visible: true,
            allowEditing: true,
            allowSorting: true,
            sortOrder: 'none', // acs desc none
            allowHeaderFiltering: false,
            fixed: false, // 컬럼 fix 시 사용
            fixedPosition: 'left', // left or right
            dataType: 'date',
            format: 'yyyy-MM-dd',
            cellTemplate: (container, options) => {
              if (!isEmpty(options.value)) {
                const dateValue = moment(options.value, 'YYYYMMDD');
                const formattedDate = dateValue.format('YYYY-MM-DD');
                container.append(formattedDate);
              }
            },
            requiredRule: {
              message: this.$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS', { value: this.$_lang('FORECASTING.WORD.EVENT_DATE', {defaultValue: '이벤트 날짜'}) }),
            },
          },
          {
            caption: '예측 콜',
            i18n: 'FORECASTING.WORD.FORECASTED_OFFER',
            dataField: 'forecasted_offer',
            height: 40,
            alignment: 'center',
            visible: true,
            allowEditing: false,
            sortOrder: 'none',
            allowHeaderFiltering: false,
            allowSorting: true,
            cellTemplate: (container, option) => {
              if (!isEmpty(option.value)) {
                container.append(parseInt(option.value).toLocaleString('ko-KR'));
              }
            },
          },
          {
            caption: '실제 콜',
            i18n: 'FORECASTING.WORD.ACTUAL_OFFER',
            dataField: 'actual_offer',
            height: 40,
            alignment: 'center',
            visible: true,
            allowEditing: false,
            sortOrder: 'none',
            allowHeaderFiltering: false,
            allowSorting: true,
            cellTemplate: (container, option) => {
              if (!isEmpty(option.value)) {
                container.append(parseInt(option.value).toLocaleString('ko-KR'));
              }
            },
          },
          {
            caption: '이벤트 유형',
            i18n: 'FORECASTING.WORD.EVENT_TYPE',
            dataField: 'event.code',
            height: 40,
            alignment: 'center',
            visible: true,
            allowEditing: true,
            sortOrder: 'none',
            allowHeaderFiltering: false,
            allowSorting: true,
            lookup: {
              dataSource: [],
              displayExpr: "codeNm",
              valueExpr: "codeValue"
            },
            cellTemplate: (container, options) => {
              let displayText = options.column.lookup.calculateCellValue(options.value);

              if (isEmpty(displayText)) {
                displayText = '-';
              } else if (options.data.forecasted_offer - options.data.actual_offer > 0) {
                container.setAttribute('style', 'color: blue !important; text-align: center');
              } else if (options.data.forecasted_offer - options.data.actual_offer < 0) {
                container.setAttribute('style', 'color: red !important; text-align: center');
              }

              container.append(displayText);
            },
          },
          {
            caption: '추가 일자',
            i18n: 'FORECASTING.WORD.ADDED_DATE',
            dataField: 'event.insert_date',
            height: 40,
            alignment: 'center', // left center right
            visible: true,
            allowEditing: false,
            allowSorting: true,
            sortOrder: 'desc', // acs desc none
            allowHeaderFiltering: false,
            fixed: false, // 컬럼 fix 시 사용
            fixedPosition: 'left', // left or right
            dataType: 'date',
            format: 'yyyy-MM-dd',
            cellTemplate: (container, options) => {
              if (!isEmpty(options.value)) {
                const dateValue = moment(options.value, 'YYYYMMDD');
                const formattedDate = dateValue.format('YYYY-MM-DD');
                container.append(formattedDate);
              }
            },
          },

        ],
      },
    };
  },

  computed: {

  },

  methods: {
    /** @description 데이터 조회 */
    async selectDataList() {
      const payload = {
        actionName: 'FORECASTING_LIST',
        data: {
          frequency: 'daily',
          base_data: 'event'
        },
        loading: true,
      }

      const res = await this.CALL_FORECASTING_API(payload);
      if(res.status === 200) {
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].event.code = res.data[i].event.code === 'fail' ? null : res.data[i].event.code;
        }

        this.dataGrid.dataSource = res.data;
      } else {
        this.$_Msg(this.$_lang('CMN_ERROR'));
      }
    },

    /** @description 데이터 저장 */
    async onSaveData(e) {
      e.cancel = true; // false 셋팅하면 grid에 binding된 data가 변경되어버림

      // 변경된 값이 없으면 메시지 출력
      if (e?.changes.length === 0) {
        this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NO_CHANGED'));
        return;
      }

      let isDuplicateDate = false;

      e.changes.forEach(data => {
        if (data.type === 'insert' && isEmpty(data.data.event?.code)) {
          this.$_Msg(this.$_lang('FORECASTING.MESSAGE.REQUIRED_EVENT_TYPE', { defaultValue: '이벤트 등록 시 이벤트 유형은 필수 입니다.' }));
        }
      });

      const saveData = e.changes.map(data => {
        if (data.type === 'update') {
          return {
            id: data.key.event.id,
            dt_date: this.formatDt(data.key.dt_date),
            code: data.data.event.code
          }
        } else {
          return {
            dt_date: this.formatDt(data.data.dt_date),
            code: data.data.event.code
          }
        }
      });

      const changeDateArr = saveData.filter(data => isEmpty(data.id)).map(data => data.dt_date);
      const removeDuplicateDateArr = new Set(changeDateArr);
      if (changeDateArr.length !== removeDuplicateDateArr.size) {
        isDuplicateDate = true;
      }

      if (!isDuplicateDate) {
        const payload = {
          actionName: 'FORECASTING_EVENT_DATE_EXIST',
          data: {
            dt_dates: changeDateArr.join('+'),
          },
          loading: false,
        };
        const res = await this.CALL_FORECASTING_API(payload);
        if (res.status === 200) {
          const getDateArr = res.data.filter(d => d.exists);

          if (getDateArr.length > 0) {
            isDuplicateDate = true;
          }
        } else {
          this.$_Msg(this.$_lang('CMN_ERROR'), { icon: 'error' });
          return;
        }
      }

      if (isDuplicateDate) {
        this.$_Msg(
          this.$_lang('COMMON.MESSAGE.NOT_DUPLICATE_VALUE_IS', {
            value: this.$_lang('FORECASTING.WORD.EVENT_DATE', { defaultValue: '이벤트 날짜' }),
          }),
        );
        return;
      }

      if (await this.$_Confirm(this.$_lang('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' }))) {
        const payload = {
          actionName: 'FORECASTING_EVENT_SAVE',
          data: saveData,
          loading: true,
        };

        const res = await this.CALL_FORECASTING_API(payload);
        if (res.status === 200) {
          this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
          e.component.cancelEditData();
          await this.selectDataList();
        } else {
          this.$_Msg(this.$_lang('CMN_ERROR'));
        }
      }
    },

    /** @description 데이터 삭제 */
    async onDeleteData() {
      if (await this.$_Confirm(this.$_lang('COMMON.MESSAGE.CMN_CFM_DELETE_SELECTED', { defaultValue: '선택한 데이터를 삭제하시겠습니까?' })) ) {
        const selectedRowsData = this.$refs.eventHistoryGrid.selectedRowsData.map(data => {
          return {
            id: data.event.id
          };
        });

        const payload = {
          actionName: 'FORECASTING_EVENT_DELETE',
          data: selectedRowsData,
          loading: true,
        };

        const res = await this.CALL_FORECASTING_API(payload);
        if (res.status === 200) {
          this.$_Toast(this.$_lang('CMN_SUC_DELETE', { defaultValue: '삭제 성공' }), { icon: 'success' });
          await this.selectDataList();
        } else {
          this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '삭제 실패' }));
        }

      }
    },

    /** @description 셀 편집 전 처리 메서드 */
    onEditorPreparing(e) {
      // 기존 데이터는 이벤트 날짜 수정 불가 처리
      if (e.parentType === 'dataRow' && e.dataField === 'dt_date' && !e.row?.isNewRow) {
        e.editorOptions.readOnly = true;
      }
    },

    /** @description 날짜 변환 메서드 */
    formatDt(date) {
      return this.$_commonlib.formatDate(date, 'YYYYMMDDHHmmssSSS', 'YYYY-MM-DD');
    },

    /** @description 모달 닫기 */
    closeModal() {
      this.$emit('closeModal');
    },

    /** @description 라이프사이클 created시 호출되는 메서드 */
    async createdData() {
      await this.selectDataList();

      this.dataGrid.columns[3].lookup.dataSource = this.$_getCode('forecasting_event');
    },
  },

  created() {
    this.createdData();
  },

  mounted() {
  },
};
</script>

<style lang="scss" scoped>
</style>