<template>
  <transition>
    <esp-dx-data-grid :data-grid="dataGrid" ref="metricsResultGrid" />
  </transition>
</template>

<script>
  import { isSuccess } from '@/plugins/common-lib';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  let vm;

  export default {
    components: { EspDxDataGrid },
    props: {
      modalData: Object,
    },
    watch: {},
    data() {
      return {
        totalScoreHeader: 0,
        dataGrid: {
          refName: 'metricsResultGrid',
          allowColumnResizing: false, //컬럼 사이즈 허용
          columnResizingMode: 'widget',
          columnAutoWidth: false,
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          disableTotalCount: true,
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          // height:'500',    // 주석처리시 100%
          scrolling: {
            mode: 'standard',
          },
          callApi: 'CALL_EWM_API',
          customEvent: {
            cellHoverChanged: false,
            cellPrepared: false,
            checkDataBeforeSaving: false,
            initNewRow: false,
            rowClick: false,
            rowInserted: false,
            rowInserting: false,
            saving: false,
          },
          showActionButtons: {
            copy: false,
            select: false,
            update: false,
            delete: false,
            excel: false,
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false,
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: false, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: false, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          /*
        selecting: {
          mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple']
          selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
          showCheckBoxesMode: 'none', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
        },*/
          columns: [
            //성과실적계획에 따라 보여지는게 다른 상세보기
          ],
        },
      };
    },
    computed: {},
    methods: {
      async bindTargetScoreGridDataSource(detail) {
        const { targetScoreResults } = detail;
        /* const rows = targetScoreResults.map(targetscore => {
				const { kpiPerformScheId, kpiPerformStdId, kpiPerformMetricsId, score } = targetscore;
				const id = `id_${kpiPerformScheId}_${kpiPerformStdId}_${kpiPerformMetricsId}`;
				return { [id]: score };
			}); */

        const row = {};
        let totalScore = 0;
        targetScoreResults.forEach(targetscore => {
          const { kpiPerformScheId, kpiPerformStdId, kpiPerformMetricsId, score, editScore } = targetscore;
          row[`id_${kpiPerformScheId}_${kpiPerformStdId}_${kpiPerformMetricsId}`] = editScore ? editScore : score;

          totalScore += row[`id_${kpiPerformScheId}_${kpiPerformStdId}_${kpiPerformMetricsId}`];
        });
        row['totalScore'] = totalScore;

        const rows = [row];

        console.log('rows', rows);

        this.$set(this.dataGrid, 'dataSource', rows);
      },
      async makeGridHeader(detail) {
        const { headerResults } = detail;
        const kpiPerformScheId = vm.modalData.kpiPerformScheId;
        const multiColumns = headerResults.map(headerData => vm.createMultiColumn(headerData, kpiPerformScheId));

        multiColumns.push({
          caption: `총점(${this.totalScoreHeader})`,
          dataField: 'totalScore',
          alignment: 'center',
          dataType: 'number',
          allowHeaderFiltering: false,
        });

        this.$set(this.dataGrid, 'columns', multiColumns);
      },
      createMultiColumn(headerData, kpiPerformScheId) {
        const { kpiPerformStdId, metrics } = headerData;
        let totalMetricsScore = 0;
        const columns = metrics.map(metric => {
          totalMetricsScore += metric.maxScore;
          let dataFieldId = `id_${kpiPerformScheId}_${kpiPerformStdId}_${metric.kpiPerformMetricsId}`;
          return this.createColumn(metric, dataFieldId);
        });

        this.totalScoreHeader += totalMetricsScore;

        return columns.length > 1 ? { multiHeaderNm: `${headerData.performStdNm}[${totalMetricsScore}]`, columns: columns } : columns[0];
      },
      createColumn(metrics, dataFieldId) {
        const { metricsNm, maxScore } = metrics;
        return {
          caption: `${metricsNm}[${maxScore}]`,
          alignment: 'center',
          dataField: dataFieldId,
          allowEditing: false,
          sortOrder: 'none',
          allowHeaderFiltering: false,
          calculateDisplayCellValue: rowData => rowData[dataFieldId],
        };
      },

      async selectDataList() {
        this.findDetailByKpiPerformScheId()
          .then(detail => {
            vm.makeGridHeader(detail);
            return detail;
          })
          .then(detail => {
            vm.bindTargetScoreGridDataSource(detail);
          });
      },
      async findDetailByKpiPerformScheId() {
        const kpiPerformScheId = vm.modalData.kpiPerformScheId;
        const payload = {
          actionName: 'EWM_PERSONAL_PERFORMANCE_RESULT_DETAIL_SELECT',
          path: '/' + kpiPerformScheId,
          loading: false,
        };
        const res = await vm.CALL_EWM_API(payload);
        if (isSuccess(res)) {
          return res.data.data?.[0];
        } else {
          this.$_Msg(`데이터 조회 실패 \n 원인 : [${res.header?.resMsg ?? 'HTTP 에러'}]`);
        }
      },

      /** @description : 라이프사이클 created 호출되는 메서드 */
      createdData() {
        vm = this;

        this.selectDataList();
      },
      /** @description : 라이프사이클 mounted 호출되는 메서드 */
      mountedData() {},
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountedData();
    },
  };
</script>
