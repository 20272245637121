<!--
  PACKAGE_NAME : src\pages\esp\auth\modal-add-auth.vue
  FILE_NAME : modal-add-auth
  AUTHOR : devyoon91
  DATE : 2024-08-29
  DESCRIPTION : 권한 추가
-->
<template>
  <esp-dx-modal-popup
    :option="{
      title: modal.title,
      width: modal.width,
      height: modal.height,
      minWidth: modal.minWidth,
      minHeight: modal.minHeight,
      closeOnOutsideClick: modal.closeOnOutsideClick,
      validationGroup: modal.validationGroup,
      useSaveBtnDisabled: modal.useSaveBtnDisabled,
    }"
    :isOpen="isOpen"
    @saveModal="handleSaving"
    @closeModal="handleClose"
  >
    <template #content>
      <table class="table_form line-bin">
        <caption>
          <strong>권한 추가</strong>
        </caption>
        <colgroup>
          <col style="width: 150px" />
          <col style="width: auto" />
        </colgroup>

        <tbody>
        <tr>
          <th scope="row">
            <label>권한명 <span class="icon_require">필수항목</span></label>
          </th>
          <td>
            <dx-text-box
              :width="325"
              stylingMode="outlined"
              class="alB"
              placeholder="권한명을 입력해주세요."
              :max-length="20"
              v-model="formData.authNm"
            >
              <dx-validator :validation-group="modal.validationGroup">
                <dx-required-rule message="권한명을 입력해주세요." />
              </dx-validator>
            </dx-text-box>
          </td>
        </tr>
        <tr v-if="formData.parentIdList.length > 0">
          <th scope="row">
            <label>상위권한 <span class="icon_require">필수항목</span></label>
          </th>
          <td>
            <div>
              <dx-drop-down-box
                :readOnly="editMode"
                placeholder="선택"
                class="check-type"
                display-expr="authNm"
                value-expr="id"
                :width="325"
                :show-clear-button="true"
                :items="formData.parentIdList"
                :value="formData.parentId"
                :opened="isTreeBoxOpened"
              >
                <dx-validator v-if="!editMode" :validation-group="modal.validationGroup">
                  <dx-required-rule message="상위권한을 선택해주세요." />
                </dx-validator>

                <template #content="{}">
                  <dx-tree-view
                    :data-source="formData.parentIdList"
                    data-structure="plain"
                    key-expr="id"
                    display-expr="authNm"
                    parent-id-expr="parentId"
                    selection-mode="single"
                    :expand-all-enabled="true"
                    :select-by-click="true"
                    @item-selection-changed="handleItemSelectionChanged"
                  />
                </template>
              </dx-drop-down-box>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </template>
  </esp-dx-modal-popup>
</template>

<script>
  import EspDxModalPopup from "@/components/devextreme/esp-dx-modal-popup.vue";
  import { DxTextBox } from 'devextreme-vue/text-box';
  import { DxRequiredRule, DxValidator } from 'devextreme-vue/validator';
  import DxDropDownBox from 'devextreme-vue/drop-down-box';
  import DxTreeView from 'devextreme-vue/tree-view';
  import {isSuccess} from "@/plugins/common-lib";

  export default {
    components: {
      EspDxModalPopup,
      DxTreeView,
      DxDropDownBox,
      DxRequiredRule,
      DxValidator,
      DxTextBox,
    },
    props: {
      isOpen: {
        type: Boolean,
        default: false,
      },
      isEdit: {
        type: Boolean,
        default: false,
      },
      authInfo: {
        type: Object,
        default: () => ({}),
      },
      authDataList: {
        type: Array,
        default: () => [],
      },
      modalData: Object,
    },
    data() {
      return {
        modal: {
          title: '권한 추가',
          minWidth: '550',
          width: '550',
          minHeight: '370',
          height: '370',
          closeOnOutsideClick: false,
          validationGroup: 'validationGroupModal',
          useSaveBtnDisabled: false, // 저장 중복 클릭 방지
        },
        isTreeBoxOpened: false, // TreeBox 오픈여부
        editMode: true, // 수정모드
        formData: {
          authNm: '',
          parentIdList: [],
          parentId: null,
          selectAuthInfo: {},
        },
      };
    },
    methods: {
      /**
       * @description 저장 이벤트
       * @return {Promise<boolean>}
       */
      async handleSaving(e) {

        const param = {
          id: this.editMode ? this.formData.selectAuthInfo.id : null, // id 값이 있으면 수정, 없으면 신규
          parentId: this.editMode ? this.formData.selectAuthInfo.parentId : this.formData.parentId, // 수정모드일 경우 부모권한은 그대로 유지
          authNm: this.formData.authNm,
          regId: this.$store.getters.getLoginId,
        };

        const res = await this.saveAuth(param);

        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
          this.$emit('saveModal');
          this.modal.useSaveBtnDisabled = false; // 저장 중복 클릭 방지 해제
        }
      },
      /**
       * @description 신규 권한 추가
       * @param param {Object} 권한 정보
       * @return {Promise<void>}
       */
      async saveAuth(param) {
        if (!param?.parentId) {
          this.$_Toast(this.$_lang('SYSTEM_ERROR', { defaultValue: '시스템 오류 입니다. 관리자에게 문의하세요.' }));
          return;
        }

        const payload = {
          actionName: 'AUTH_SAVE',
          data: [
            {
              id: param.id,
              authNm: param.authNm,
              parentId: param.parentId,
              reportFl: this.$_enums.common.stringUsedFlag.YES.value, // FIXME : 변경필요 (임시) 검색대상권한 기본값 Y 설정하였음(원래는 N), 세부 권한 설정 숨기고 나서 수정한 내용.
              dataFl: this.$_enums.common.stringUsedFlag.NO.value,
            },
          ],
          useErrorPopup: true,
          loading: true,
        };

        return await this.CALL_API(payload);
      },
      /**
       * @description 모달 닫기 이벤트
       */
      handleClose() {
        this.formData.authNm = '';
        this.formData.parentIdList = [];
        this.formData.parentId = null;
        this.formData.selectAuthInfo = {};
        this.isTreeBoxOpened = false;

        this.$emit('closeModal');
      },
      /**
       * @description 권한명 선택시 이벤트
       * @param e
       */
      handleItemSelectionChanged(e) {
        if (e.itemData) {
          this.formData.parentId = e.itemData.id;
          this.isTreeBoxOpened = false;
        }
      },
      /** @description 데이터 초기 설정 */
      initData() {
        this.formData.selectAuthInfo = this.authInfo;

        if (this.isEdit) {
          this.modal.title = '권한 수정';
          this.formData.authNm = this.authInfo.authNm;
          this.formData.parentId = this.authInfo.parentId; // 수정은 부모권한을 그대로 유지
        } else {
          this.modal.title = '권한 추가';
          this.formData.parentId = this.authInfo?.id || null; // 선택한 권한을 부모 권한으로 설정
        }

        this.editMode = this.isEdit; // 수정모드 설정

        if (this.authDataList) {
          this.formData.parentIdList = JSON.parse(JSON.stringify(this.authDataList));
        }
      },
    },
    created() {
      this.initData();
    }
  };
</script>

<style scoped></style>
