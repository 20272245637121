<!--
  PACKAGE_NAME : src/pages/esp/auth
  FILE_NAME : menu-auth-tree-list.vue
  AUTHOR : devyoon91
  DATE : 2024-12-10
  DESCRIPTION : 메뉴권한 트리리스트 컴포넌트
-->
<template>
  <div>
    <esp-dx-tree-list :tree-list="treeList" :ref="treeList.refName" @selection-changed="handleSelectionChanged" @saving="handleSave" />
  </div>
</template>

<script>
  import { isSuccess } from '../../../plugins/common-lib';
  import EspDxTreeList from '../../../components/devextreme/esp-dx-tree-list-v2.vue';

  export default {
    components: { EspDxTreeList },
    data() {
      return {
        selectedAuthId: null,
        treeList: {
          keyExpr: 'id',
          refName: 'menuAuthTreeList',
          height: 'calc(100vh - 223px)',
          recursive: false, // 트리 재귀 선택 기능 활성화
          dataSource: [],
          showActionButtons: {
            sort: false,
            toggleExpand: false,
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  text: this.$_lang('COMPONENTS.SAVE', { defaultValue: '저장' }),
                  elementAttr: { class: 'default filled txt_S medium' },
                  width: 60,
                  height: 30,
                  onClick: e => {
                    this.handleSave(e);
                  },
                },
                location: 'after',
              },
            ],
          },
          filterRow: {
            visible: false,
          },
          editing: {
            allowUpdating: false,
            allowDeleting: false,
            allowAdding: false,
          },
          selection: {
            //로우 선택 설정
            allowSelectAll: true, //헤더 체크박스 선택(전체선택) 허용 여부
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple', 'none']
            recursive: true, //상위 노드 선택시 하위 노드도 선택 여부(true: 하위 노드도 선택, false: 하위 노드 선택 안하고 독립적)
          },
          rowDragging: {
            enabled: false,
            allowDropInsideItem: false, // 드래그로 아이템 안에 드롭 허용 여부
            allowReordering: false, // 드래그로 순서 변경 허용 여부
            showDragIcons: false, // 드래그 아이콘 표시 여부
          },
          columns: [
            { dataField: 'id', caption: 'id', visible: false },
            { dataField: 'parentId', caption: 'parentId', visible: false },
            { dataField: 'menuNm', caption: '메뉴', alignment: 'left', allowSorting: false },
          ],
        },
        updateData: {}, // 권한 업데이트 대상 객체
        treeDataSourceMap: null, // DataSource Tree Map(캐싱) 하기 위한 객체(초기화 null 선언 필요)
        isInInitialization: false, // 초기화 작업 진행 여부
      };
    },
    methods: {
      /**
       * @description 초기 선택 상태 반환
       * @param rowKey
       * @return {boolean}
       */
      getInitialSelectionState(rowKey) {
        // find 순차적 사용은 성능저하 문제로 Map 으로 캐싱 처리
        if (!this.treeDataSourceMap) {
          this.treeDataSourceMap = new Map(this.getTreeDataSource().map(item => [item.id, item]));
        }

        const node = this.treeDataSourceMap.get(rowKey);

        if (!node) {
          return false;
        }
        return node.authUseFl === this.$_enums.common.stringUsedFlag.YES.value;
      },
      /**
       * row 상태 업데이트를 처리하는 유틸리티 함수
       * @param rowKey {String|Number} 행 ID
       * @param initialState {Boolean} 초기 상태
       * @param isSelected {Boolean} 현재 선택 여부
       */
      updateRowState(rowKey, initialState, isSelected) {
        // 선택 상태가 초기 상태와 같으면 updateData 에서 제거
        if (isSelected === initialState) {
          delete this.updateData[rowKey];
        } else {
          this.updateData[rowKey] = { selected: isSelected };
        }
      },
      /**
       * 선택/해제 로직 처리 함수
       * @param rowKeys {Array} 처리할 rowKeys 배열
       * @param isSelected {Boolean} 선택/해제 여부 (true: 선택, false: 해제)
       */
      async setSelection(rowKeys, isSelected) {
        const treeListInstance = this.$refs.menuAuthTreeList.getInstance; // TreeList 인스턴스

        // rowKeys 처리: 병렬로 처리하여 반복 시간 단축
        await Promise.all(
          rowKeys.map(async rowKey => {
            // 초기 상태 가져오기
            const initialState = this.getInitialSelectionState(rowKey);

            // 현재 rowKey 상태 업데이트
            this.updateRowState(rowKey, initialState, isSelected);

            // 관련된 자식 ID 처리
            const relatedIds = await this.getAllRelatedAuthIds(rowKey);

            // 관련된 항목도 병렬로 처리
            await Promise.all(
              relatedIds.map(async id => {
                const relatedInitialState = this.getInitialSelectionState(id);
                this.updateRowState(id, relatedInitialState, isSelected);
              }),
            );

            // 부모 노드 전체 처리 (재귀적으로 처리)
            await this.updateAllParents(treeListInstance, rowKey, isSelected);
          }),
        );
      },
      /**
       * @description 상위 모든 부모 노드 상태를 업데이트
       * @param treeListInstance {Object} TreeList 인스턴스
       * @param rowKey {String|Number} 현재 노드의 키
       * @param isSelected {Boolean} 선택 여부
       */
      async updateAllParents(treeListInstance, rowKey, isSelected) {
        const node = treeListInstance.getNodeByKey(rowKey);

        if (node && node.parent) {
          // 부모 노드 존재 확인
          const parentKey = node.parent.key;

          if (parentKey === -1) {
            return;
          }

          // 부모 초기 상태 가져오기
          const parentInitialState = this.getInitialSelectionState(parentKey);

          // 부모 노드 상태 업데이트
          this.updateRowState(parentKey, parentInitialState, isSelected);

          // 재귀적으로 상위 부모 탐색
          await this.updateAllParents(treeListInstance, parentKey, isSelected);
        }
      },
      /**
       * 메뉴권한 트리 선택 변경 이벤트 핸들러
       * @param e {Object} 이벤트 데이터
       */
      async handleSelectionChanged(e) {
        if (this.isInInitialization) {
          // 초기화 작업 중이면 무시
          return;
        }

        if (e.currentSelectedRowKeys?.length > 0) {
          // "선택된 항목"이 있는 경우
          await this.setSelection(e.currentSelectedRowKeys, true);
        }

        if (e.currentDeselectedRowKeys?.length > 0) {
          // "선택 해제된 항목"이 있는 경우
          await this.setSelection(e.currentDeselectedRowKeys, false);
        }
      },
      /**
       * 현재 권한과 관련된 모든 권한 id들을 반환
       *
       * @param authId
       * @return {Promise<any[]>}
       */
      async getAllRelatedAuthIds(authId) {
        const relatedIds = new Set();
        const queue = [authId]; // 초기 큐에 authId를 추가

        while (queue.length > 0) {
          const currentId = queue.shift(); // 큐에서 현재 id를 꺼냄

          if (relatedIds.has(currentId)) {
            continue; // 이미 처리된 id는 무시
          }

          relatedIds.add(currentId); // 관련된 id Set에 현재 id 추가

          // 현재 id와 관련된 자식 id들을 찾음
          const children = this.getTreeDataSource().filter(node => node.parentId === currentId);

          // 자식 id들을 큐에 추가
          for (const child of children) {
            queue.push(child.id);
          }
        }

        return Array.from(relatedIds); // 중복 배제 후 관계된 id들을 배열로 변환하여 반환
      },
      /**
       * @description 트리 데이터 조회
       * @return {*}
       */
      getTreeDataSource() {
        return this.$refs.menuAuthTreeList.getInstance.option('dataSource');
      },
      /**
       * @description 트리 데이터 설정
       * @param data
       */
      setTreeDataSource(data) {
        this.$refs.menuAuthTreeList.getInstance.option('dataSource', data);
      },
      /**
       * @description 인스턴스 반환
       * @return {*}
       */
      getInstance() {
        return this.$refs.menuAuthTreeList.getInstance;
      },
      /**
       * @description 트리 리스트 초기화
       * @param authId 권한 ID
       * @return {Promise<void>}
       */
      async setAuthMenuTreeList(authId) {
        this.initData(); // 데이터 초기화
        this.selectedAuthId = authId; // 선택된 권한 ID 설정

        const res = await this.getMenuList();

        if (isSuccess(res)) {
          await this.$refs.menuAuthTreeList.getInstance.option('dataSource', res.data.data);
        }

        // onContentReady 이벤트에서 선택 로직을 실행
        this.$nextTick(() => {
          const treeListInstance = this.$refs.menuAuthTreeList.getInstance;
          if (treeListInstance) {
            treeListInstance.option('onContentReady', async () => {
              this.isInInitialization = true; // 초기화 작업 진행 중
              // 데이터 로드 완료 후 진행
              await this.setTreeDataSelection(res); // 데이터 row 선택
              this.isInInitialization = false; // 초기화 완료
            });
          }
        });
      },
      /**
       * @description 메뉴 리스트 조회
       * @return {Promise<void>}
       */
      async getMenuList() {
        const payload = {
          actionName: 'MENU_LIST_ALL',
          data: {
            authId: this.selectedAuthId,
            viewFl: this.$_enums.common.stringViewFlag.YES.value,
            authPermFl: this.$_enums.common.stringUsedFlag.YES.value,
            isCompact: true, // 간략 조회 여부
          },
          useErrorPopup: true,
        };
        return await this.CALL_API(payload);
      },
      /**
       * @description 트리 데이터 선택
       * @return {Promise<void>}
       */
      async setTreeDataSelection() {
        this.$refs.menuAuthTreeList.getInstance.clearSelection();
        const dataSource = await this.getTreeDataSource();

        // 부모노드를 선택하면 자식노드도 같이 선택 되므로 자식노드만 선택 하도록 처리
        const selectChildrenNodeIds = dataSource
          .filter(d => {
            const node = this.$refs.menuAuthTreeList.getInstance.getNodeByKey(d.id);

            if (!node || !node.data) {
              return false;
            }
            // 자식 노드가 없고 사용 여부가 'Y'인 경우
            return !node.hasChildren && node.data.authUseFl === this.$_enums.common.stringUsedFlag.YES.value;
          })
          .map(d => d.id);
        await this.$refs.menuAuthTreeList.getInstance.selectRows(selectChildrenNodeIds, false);
      },
      /**
       * @description 저장 이벤트
       * @param e
       */
      handleSave(e) {
        this.updateAuthMenuUseFlag();
      },
      /**
       * @description 권한 메뉴 사용여부 수정
       */
      async updateAuthMenuUseFlag() {
        if (Object.keys(this.updateData).length === 0) {
          this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_NO_CHANGED', { defaultValue: '변경된 데이터가 없습니다.' }));
          return;
        }

        const payload = {
          actionName: 'AUTH_MENU_USE_FL_UPDATE',
          data: Object.entries(this.updateData).map(([menuId, { selected }]) => {
            return {
              menuId,
              useFl: selected ? this.$_enums.common.stringUsedFlag.YES.value : this.$_enums.common.stringUsedFlag.NO.value,
            };
          }),
          path: this.selectedAuthId,
          loading: true,
        };

        const res = await this.CALL_API(payload);

        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
          await this.setAuthMenuTreeList(this.selectedAuthId);
        }
      },
      /**
       * @description 컴포넌트 데이터 초기화
       */
      initData() {
        this.selectedAuthId = null;
        this.treeList.dataSource = [];
        this.updateData = {};
        this.treeDataSourceMap = null;
        this.isInInitialization = false;
      },
    },
    mounted() {
      this.initData();
    },
  };
</script>

<style lang="scss" scoped>
  ::v-deep .bigtabBox.on {
    height: 100%;
  }

  ::v-deep .fr_wrap {
    height: 100%;
    margin-top: 20px !important;
    margin-left: 10px;
  }
</style>
