<template>
  <div>
    <table class="table_form line-bin fl" style="width: 100%">
      <thead style="border-top: 1px solid; border-bottom: 1px solid; border-color: rgba(229, 231, 235)">
        <tr>
          <th>{{ $_lang('UC.WORD.EXCHANGER') }}</th>
          <td>{{ getCmNm(this.deviceInfo.cmname) }}</td>
          <th>{{ $_lang('UC.WORD.DEVICE_NAME') }}</th>
          <td>{{ this.deviceInfo.name }}</td>
        </tr>
      </thead>
    </table>
    <div class="locker_setting_list sub_new_style01 sub_ui_box1">
      <div class="" style="padding-top: 50px">
        <Search
          codeKey="search_type_line"
          :searchObj="searchType.obj"
          :useKeywordType="true"
          @change-search-type="$_changeSearchType"
          @onSearchClick="$_searchData($event, '	search_type_line')"
        >
          <template v-slot:before>
            <DxSelectBox
              v-model="searchType.customTypes['cm']"
              :placeholder="$_lang('UC.WORD.EXCHANGER')"
              :items="getCmList"
              display-expr="codeNm"
              value-expr="codeValue"
              :styling-mode="stylingMode"
              :width="120"
              :height="30"
              @value-changed="$_changeSearchCustomType('cm', $event)"
            />
          </template>
        </Search>
      </div>
    </div>
    <table class="table_form line-bin">
      <tbody>
        <div class="lineGrid">
          <esp-dx-data-grid
            :data-grid="dataGrid"
            ref="lineGrid"
            :auto-width="true"
            :selected-row-keys="dataGrid.selectedRowKeys"
          />
        </div>
      </tbody>
    </table>
    <DxPopup
      :show-title="true"
      :title="modal.title"
      :width="modal.width"
      :height="modal.height"
      :drag-enabled="true"
      :resize-enabled="true"
      :show-close-button="true"
      :hide-on-outside-click="false"
      :visible="modal.isOpened"
      @hiding="isOpenModal(false)"
    >
      <ModalAddLine
        :isOpened="this.modal.isOpened"
        :cmList="this.cmList"
        :partitionList="this.partitionList"
        :cssList="this.cssList"
        :pickupGroupList="this.pickupGroupList"
        @closeModal="closeModal"
      ></ModalAddLine>
    </DxPopup>
  </div>
</template>
<script>
  import { DxPopup } from 'devextreme-vue/popup';
  import { isSuccess } from '@/plugins/common-lib';
  import ModalAddLine from '../line/modal-add-line.vue';
  import Search from '@/components/common/search.vue';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import CustomStore from "devextreme/data/custom_store";
  import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid.vue";
  export default {
    components: {
      EspDxDataGrid,
      DxPopup,
      DxSelectBox,
      Search,
      ModalAddLine,
    },
    props: {
      option: {
        default: () => {
          return {
            width: '50%',
            height: '85%',
            minWidth: null,
            minHeight: null,
            saveBtnVisible: false,
            cancelBtnVisible: true,
          };
        },
        type: Object,
      },

      deviceInfo: {
        type: Object,
      },
      rowInfo: {
        type: Object,
      },
      isOpened: {
        type: Boolean,
      },
      cmList: {
        type: Array,
      },
      partitionList: {
        type: Array,
      },
      cssList: {
        type: Array,
      },
      pickupGroupList: {
        type: Array,
      },
    },
    watch: {
      async isOpened() {
        if (this.isOpened) {
          await this.selectDataList();
          this.searchType.paramsData = {};
        } else {
          this.resetGridDataSource();
        }
      },
    },
    data() {
      return {
        modal: {
          isOpened: false,
          // title: '내선번호 추가',
          title: this.$_lang('UC.WORD.LINE_ADD'),
          width: '60%',
          height: '600',
        },
        dataGrid: {
          callApi: 'CALL_EUC_API',
          refName: 'lineGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: true, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          disableTotalCount: false,
          selectedRowKeys: [], //선택된 로우키들
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          height: '500', // 주석처리시 100%
          apiActionNm: {
            loading: true,
          },
          customEvent: {
            rowPrepared: true,
            contentReady: true,
          },
          showActionButtons: {
            customButtons: [],
          },
          isDuplicateConfigKey: false, //설정키 중복 체크
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '교환기',
              i18n: 'UC.WORD.EXCHANGER',
              dataField: 'cmNm',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              calculateDisplayCellValue: rowData => {
                if (rowData?.cmNm) {
                  return this.getCmNm(rowData.cmNm);
                }
                if (rowData.cmNm == null || rowData.cmNm == 'null') {
                  return '';
                }
              },
            },
            {
              caption: '내선번호',
              i18n: 'UC.WORD.NUMBER',
              dataField: 'pattern',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowExporing: true,
              fixed: false,
            },
            {
              caption: '표시(경고이름)',
              i18n: 'COMPONENTS.DESCRIPTION',
              dataField: 'description',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '파티션',
              i18n: 'UC.WORD.PARTITION',
              dataField: 'routepartitionname',
              width: 150,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
            },
            {
              caption: '발신검색',
              i18n: 'UC.WORD.CSS',
              dataField: 'css',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '당겨받기',
              i18n: 'UC.WORD.PICKUPGROUP',
              dataField: 'callpickupgroupname',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
          ],
        },
        stylingMode: 'outlined', //outlined, underlined, filled
        searchType: {
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          searchTexts: {
            value: this.pickupKeyword,
          },
          customTypes: {
            cm: null,
            model: null,
            status: null,
          },
          paramsData: null,
        },
      };
    },
    computed: {
      getCmList() {
        const currentPath = this.$router.currentRoute.path;
        const store = this.$store.getters.getSearchHists[currentPath] || [];
        const params = store[0]?.search;

        const result = this.$_getCode('search_type_device_cm').filter(d => d.delFl === 'N');
        const svrInx = params?.['cm'] ? params['cm'] : result[0].codeValue;
        this.setCustomTypes('cm', svrInx);
        return result;
      },
    },
    methods: {
      /**@description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {
        this.dataGrid.showActionButtons.customButtons = this.getCustomButtons();
      },
      /**@description : 라이프사이클 mounted시 호출되는 메서드 */
      async mountedData() {},
      getCustomButtons() {
        const buttons = [
          {
            widget: 'dxButton',
            options: {
              icon: '',
              text: this.$_lang('COMPONENTS.ADD'),
              elementAttr: { class: 'btn_XS default filled add1' },
              width: 80,
              height: 30,
              onClick: () => {
                this.isOpenModal(true);
              },
            },
            location: 'before',
          },
        ];

        buttons.push({
          widget: 'dxButton',
          options: {
            icon: '',
            text: this.$_lang('UC.WORD.LINE_SYNC'),
            elementAttr: { class: 'btn_XS default filled ' },
            width: 80,
            height: 30,
            onClick: () => {
              this.onSyncLine();
            },
          },
          location: 'before',
        });
        buttons.push({
          widget: 'dxButton',
          options: {
            icon: '',
            text: this.$_lang('UC.WORD.APPLY'),
            elementAttr: { class: 'btn_XS default filled ' },
            width: 60,
            height: 30,
            onClick: () => {
              this.onSetLine();
            },
          },
          location: 'before',
        });
        return buttons;
      },
      async selectDataList(sort = 'regDt,id') {
        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);

            if (!params.sort) {
              params.sort = sort;
            }
            params = {
              ...params,
              ...this.searchType.paramsData,
              ...this.searchType.customTypes,
              loginId: this.$store.getters.getLoginId,
            };
            /**검색조건이 0(전체) 일경우 파라미터 삭제 */
            if (params?.['cm'] === '0') {
              delete params['cm'];
            }

            const payload = {
              actionName: 'EUC_LINE_LIST_ALL',
              data: params,
              loading: false,
            };

            const rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await this.CALL_EUC_API(payload);
            if(isSuccess(res)) {
              rtnData.data = res.data.data;
              rtnData.totalCount = res.data.header.totalCount;
            }
            return rtnData;
          },
        });
      },
      /** @description : 라이프사이클 computed에서 this data를 변경하기 위한 메서드 */
      setCustomTypes(key, value) {
        this.searchType.customTypes[key] = value;
      },
      /** @description 그리드 데이터 초기화 */
      resetGridDataSource() {
        this.dataGrid.dataSource = [];
        this.$refs.lineGrid.clearSelection();
      },
      /** @description: 그리드 content ready */
      onContentReady(e) {
        let keys = [];
        const gridData = e.component.getDataSource()._items;
        for (let i = 0; i < gridData.length; i++) {
          if (this.rowInfo.pickupgroupname === gridData[i].name) {
            keys.push(gridData[i].id);
          }
        }
        e.component.selectRows(keys);
      },
      /**@description : 라인 적용버튼 클릭 */
      async onSetLine() {
        const selectedRowCnt = this.$refs.lineGrid.selectedRowsData?.length;
        if (selectedRowCnt > 1) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_ONE_SELECT'));
        } else if (selectedRowCnt === 0) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
        } else if (selectedRowCnt === 1) {
          const confirm = this.$_lang('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' });
          if (!(await this.$_Confirm(confirm))) {
            return;
          }
          const lineData = this.$refs.lineGrid.selectedRowsData[0];
          this.$emit('closeLine', this.rowInfo, lineData, 'line');
        } else {
          await this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_ERROR_INTERNAL_SERVER', { defaultValue: '서버 오류 입니다.' }));
        }
      },
      /**@description : 내선관리 모달 Open/Close */
      isOpenModal(bool) {
        this.modal.isOpened = bool;
      },
      /**@description : 내선관리 모달 close 콜백함수 */
      async closeModal(flag) {
        if (flag) {
          await this.isOpenModal(false);
          await this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_SUCCESS', { defaultValue: '정상적으로 처리되었습니다.' }));
          await this.selectDataList();
        }
      },
      /**
       * @description 전화기 전체 동기화
       */
      async onSyncLine() {
        let confirm = this.$_lang('UC.MESSAGE.START_SYNC');
        if (!(await this.$_Confirm(confirm))) {
          return;
        }
        for (const item of this.cmList) {
          const params = { cmNm: item.cmNm, loginId: this.$store.getters.getLoginId };
          const payload = {
            actionName: 'EUC_LINE_SYNC',
            data: params,
            loading: true,
          };

          const res = await this.CALL_EUC_API(payload);
          if (isSuccess(res)) {
            // await this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_SUCCESS', { defaultValue: '정상적으로 처리되었습니다.' }));
            await this.$refs.lineGrid.getInstance.refresh();
          } else {
            if (res.data.data.length == 0) {
              await this.$_Msg(res.data.header.resMsg);
            } else {
              await this.$_Msg('교환기 : ' + item.cmNm + ' ' + res.data.header.resMsg);
            }
          }
        }
      },
      /** @description 교환기 이름 한글 변환 메서드
       *  @param {String} cm Name
       *  @returns {String} 한글명
       */
      getCmNm(cm) {
        let cmNm = '';
        for (let i = 0; i < this.cmList.length; i++) {
          if (this.cmList[i].cmNm === cm) {
            cmNm = this.cmList[i].description;
          }
        }
        if (cmNm !== '' && cmNm !== null) {
          return cmNm;
        } else {
          return '';
        }
      },
    },
    async created() {
      this.createdData();
    },
    async mounted() {
      await this.mountedData();
    },
  };
</script>
