<!--
  PACKAGE_NAME : src\components\devextreme
  FILE_NAME : esp-dx-modal-popup
  AUTHOR : devyoon91
  DATE : 2024-09-20
  DESCRIPTION : ESP 공통 모달 팝업 컴포넌트
-->
<template>
  <dx-popup
    :visible="isOpen"
    :show-title="optionConfig.showTitle"
    :title="optionConfig.title"
    :min-width="optionConfig.minWidth"
    :width="optionConfig.width"
    :min-height="optionConfig.minHeight"
    :height="optionConfig.height"
    :resize-enabled="optionConfig.resizeEnabled"
    :drag-enabled="optionConfig.dragEnabled"
    :show-close-button="optionConfig.showCloseButton"
    :hide-on-outside-click="optionConfig.closeOnOutsideClick"
    @hiding="closeModal"
  >
    <div class="popup-toolbar" :class="toolbarClasses">
      <esp-footer-button
        v-if="optionConfig.useToolbar && optionConfig.useSaveBtn"
        color="default"
        mode="filled"
        i18n="COMPONENTS.SAVE"
        :validation-group="optionConfig.validationGroup"
        :disabled="optionConfig.useSaveBtnDisabled"
        @click="saveModal"
      />
      <esp-footer-button
        v-if="optionConfig.useToolbar && optionConfig.useCancelBtn"
        color="white"
        mode="filled"
        i18n="COMPONENTS.CANCEL"
        @click="closeModal"
      />
    </div>

    <!-- SLOT -->
    <div class="popup-content" :class="contentClasses">
      <slot name="content"></slot>
    </div>
  </dx-popup>
</template>
<script>
  import { DxPopup } from 'devextreme-vue/popup';
  import EspFooterButton from '@/components/common/buttons/esp-footer-button.vue';

  export default {
    components: {
      DxPopup,
      EspFooterButton,
    },
    props: {
      option: {
        default: () => this.defaultOption,
        type: Object,
      },
      isOpen: {
        default: false,
        type: Boolean,
      },
    },
    data() {
      return {
        optionConfig: {}, // 팝업 옵션 설정값
        defaultOption: {
          //팝업 설정
          showTitle: true, // 타이틀 표시 여부
          title: '팝업 타이틀', // 타이틀
          width: '80%', // 너비
          height: '85%', // 높이
          minWidth: null, // 최소 너비
          minHeight: null, // 최소 높이
          resizeEnabled: true, // 리사이즈 가능 여부
          dragEnabled: true, // 드래그 가능 여부
          showCloseButton: true, // 닫기 버튼 표시 여부
          closeOnOutsideClick: true, // 외부 클릭 시 닫기 여부
          //툴바 설정
          toolbar: 'bottom', // 툴바 위치 (top, bottom)
          location: 'center', // 툴바 버튼 위치 (before, center, after)
          useToolbar: true, // 하단 툴바 사용 여부
          useSaveBtn: true, // 하단 저장 버튼 사용 여부
          useSaveBtnDisabled: false, // 하단 저장 버튼 비활성화 여부(저장 중복 클릭 방지 설정시 사용)
          useCancelBtn: true, // 하단 취소 버튼 사용 여부
          saveBtnTxt: '저장', // 저장 버튼 텍스트
          cancelBtnTxt: '취소', // 취소 버튼 텍스트
          validationGroup: null, // 유효성 검사 그룹
        },
      };
    },
    computed: {
      /** @description: 툴바 클래스 */
      toolbarClasses() {
        return {
          [`toolbar-${this.optionConfig.toolbar}`]: true,
          [`location-${this.optionConfig.location}`]: true,
        };
      },
      /** @description: 컨텐츠 클래스 */
      contentClasses() {
        return {
          [`content-with-${this.optionConfig.toolbar}-toolbar`]: true,
        };
      },
    },
    watch: {
      isOpen: {
        handler(isOpen) {
          if (isOpen) {
            this.optionConfig = { ...this.defaultOption, ...this.option };
          }
        },
        immediate: true,
        deep: true,
      },
    },
    methods: {
      /**
       * @description 저장 버튼 클릭 이벤트
       * @param e
       */
      saveModal(e) {
        // validation 체크
        const validationGroup = e.validationGroup;
        if (validationGroup && !validationGroup?.validate()?.isValid) {
          return;
        }

        this.$emit('saveModal', e);
      },
      /**
       * @description 취소 버튼 클릭 이벤트
       * @param e
       */
      closeModal(e) {
        if (this.isOpen === false) {
          return;
        }
        this.$emit('closeModal', e);
      },
    },
  };
</script>

<style scoped>
  .popup-toolbar {
    position: absolute;
    left: 0;
    right: 0;
    height: 60px;
    padding: 10px;
    display: flex;
    align-items: center;
    background-color: #fff;
    z-index: 100;
  }

  /* toolbar position */
  .toolbar-top {
    top: 0;
  }

  .toolbar-bottom {
    bottom: 0;
  }

  /* location position */
  .location-before {
    justify-content: flex-start;
    padding-left: 20px;
  }

  .location-center {
    justify-content: center;
  }

  .location-after {
    justify-content: flex-end;
    padding-right: 20px;
  }

  /* button gap */
  .popup-toolbar :deep(.dx-button) {
    margin: 0 4px;
  }

  /* 컨텐츠 영역 */
  .popup-content {
    position: relative;
    height: 100%;
    overflow: auto;
  }

  .content-with-top-toolbar {
    padding-top: 60px; /* toolbar 높이만큼 padding 추가 */
  }

  .content-with-bottom-toolbar {
    padding-bottom: 60px; /* toolbar 높이만큼 padding 추가 */
  }
</style>
