<!--
  PACKAGE_NAME : src\components\devextreme\esp-cancel-button
  FILE_NAME : esp-cancel-button
  AUTHOR : hmlee
  DATE : 2025-01-13
  DESCRIPTION : ESP 취소 버튼 컴포넌트
-->
<template>
  <esp-dx-button
    :visible="optionConfig.visible"
    :text="optionConfig.text"
    :type="optionConfig.type"
    :color="optionConfig.color"
    :mode="optionConfig.mode"
    :size="optionConfig.size"
    :template="optionConfig.template"
    :hint="optionConfig.hint"
    :width="optionConfig.width"
    :height="optionConfig.height"
    :disabled="optionConfig.disabled"
    :elementAttr="optionConfig.elementAttr"
    :focus-state-enabled="optionConfig.focusStateEnabled"
    :hover-state-enabled="optionConfig.hoverStateEnabled"
    :validation-group="optionConfig.validationGroup"
    :use-submit-behavior="optionConfig.useSubmitBehavior"
    :is-preset="true"
    @click="handleClick"
  >
  </esp-dx-button>
</template>
<script>
  import EspDxButton from "@/components/devextreme/esp-dx-button.vue";

  export default {
    components: {
      EspDxButton,
    },
    props: {
      option: {
        default: () => ({}),
        type: Object,
      },
      color: { // 버튼 컬러
        default: () => '',
        type: String,
      },
      mode: {  // 버튼 타입
        default: () => '',
        type: String,
      },
      size: { // 버튼 크기
        default: () => '',
        type: String,
      },
    },
    data() {
      return {
        defaultOption: {
          visible: true, // 타이틀 표시 여부
          text: '', // 타이틀
          type: 'normal', // 버튼 타입
          class: '', // 엘리먼트 속성
          color: '', // 색상 ['default', 'green', 'red', 'white', 'gray', 'black']
          mode: '', // 타입 ['filled', 'outlined']
          size: '', // 크기 ['xs': xsmall, 'sm': small, 'md': medium, 'lg': large]
          template: '', // 템플릿
          width: '60', // 너비
          height: '30', // 높이
          elementAttr: {}, // 엘리먼트 속성
          disabled: false, // 비활성화 여부
          focusStateEnabled: true, // 포커스 상태 활성화 여부
          hoverStateEnabled: true, // hover 상태 활성화 여부
          validationGroup: null, // 유효성 검사 그룹
          useSubmitBehavior: false, // 제출 동작 사용 여부
        },
      };
    },
    computed: {
      /** @description: 옵션 설정 데이터 */
      optionConfig() {
        return {
          ...this.defaultOption,
          ...this.option,
          text: this.$_lang('COMPONENTS.CANCEL', { defaultValue: '취소' }),
          color: this.btnColor,
          mode: this.btnMode,
          size: this.btnSize,
          width: this.btnWidth,
          height: this.btnHeight,
        };
      },
      /** @description: 버튼 클래스 */
      btnClasses() {
        return [
          this.btnColor, // 타입
          this.btnMode, // 타입
          this.btnSize, // 크기
        ]
      },
      /** @description: 버튼 모드 */
      btnColor() {
        let color = '';
        switch(this.mode) {
          case 'filled':
            if (this.color) {
              color = this.color;
            } else { // 색상이 없으면 기본값 white
              color = 'white';
            }
            return color;
          case 'outlined':
            if (this.color) {
              color = this.color;
            } else { // 색상이 없으면 기본값 white
              color = 'white';
            }
            return color;
          default:
            return 'default';
        }
      },
      /** @description: 버튼 모드 */
      btnMode() {
        switch (this.mode) {
          case 'filled':
            return 'filled';
          case 'outlined':
            return 'outlined';
          default:
            return 'filled';
        }
      },
      /** @description: 버튼 크기 */
      btnSize() {
        if (this.size) {
          return `${ this.size.toLowerCase() }`;
        } else {
          return 'sm';
        }
      },
      /** @description: 버튼 너비 */
      btnWidth() {
        // size 옵션에 따라 너비 설정
        switch (this.size) {
          case 'xs':
            return '58';
          case 'sm':
            return '60';
          case 'md':
            return '62';
          case 'lg':
            return '64';
          default:
            return '60';
        }
      },
      /** @description: 버튼 높이 */
      btnHeight() {
        // size 옵션에 따라 높이 설정
        switch (this.size) {
          case 'xs':
            return '28';
          case 'sm':
            return '30';
          case 'md':
            return '32';
          case 'lg':
            return '34';
          default:
            return '30';
        }
      },
    },
    watch: {
    },
    methods: {
      /**
       * @description 클릭 이벤트
       * @param e
       */
      handleClick(e) {
        this.$emit('click', e);
      },
    },
  };
</script>
