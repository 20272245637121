<!--
  PACKAGE_NAME : src/pages/euc/contact
  FILE_NAME : contact.vue
  AUTHOR : jhcho
  DATE : 2024-06-10
  DESCRIPTION :
-->
<template>
  <div class="locker_setting_list sub_new_style01 sub_ui_box1">
    <Search
      codeKey="null"
      :customSearchList="searchType.list"
      :searchObj="searchType.obj"
      @change-search-type="$_changeSearchType"
      @onSearchClick="searchData($event)"
    >
      <template v-slot:before>
        <DxSelectBox
          v-model="searchType.customTypes.groupId"
          placeholder="그룹 선택"
          :items="getGroupList"
          display-expr="groupNm"
          value-expr="id"
          :styling-mode="stylingMode"
          :width="120"
          :height="30"
          @value-changed="$_changeSearchCustomType('groupId', $event)"
        />
      </template>
    </Search>
    <esp-dx-data-grid :data-grid="dataGrid" ref="contactGrid" />
    <DxPopup
      :show-title="true"
      :title="modal.initData ? modal.initData.title : null"
      :min-width="modal.initData ? modal.initData.width : null"
      :width="modal.initData ? modal.initData.width : null"
      :min-height="modal.initData ? modal.initData.height : null"
      :height="modal.initData ? modal.initData.height : null"
      :drag-enabled="true"
      :resize-enabled="true"
      :show-close-button="true"
      :hide-on-outside-click="false"
      v-model="modal.isOpened"
      :visible="modal.isOpened"
      @hiding="isOpenModal(false)"
    >
      <template #content>
        <component ref="modalExcelUploadRef" :is="modal.currentComponent" :modalData="modal.sendData" v-model="modal.contentData" />
      </template>
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :options="{
          elementAttr: {
            class: 'white filled txt_S medium',
          },
          text: $_lang('COMPONENTS.CLOSE', { defaultValue: '닫기' }),
          width: '120',
          height: '40',
          onClick: () => isOpenModal(false),
        }"
      />
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :options="{
          elementAttr: {
            class: 'default filled txt_S medium',
          },
          text: $_lang('COMPONENTS.SAVE', { defaultValue: '저장' }),
          width: '120',
          height: '40',
          onClick: () => onSaveModal(),
        }"
      />
    </DxPopup>
  </div>
</template>
<script>
  import Search from '@/components/common/search.vue';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { isEmpty, isSuccess } from '@/plugins/common-lib';
  import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
  import ModalExcelUpload from '@/pages/euc/contact/modal-excel-upload.vue';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: {
      EspDxDataGrid,
      DxToolbarItem,
      DxPopup,
      DxSelectBox,
      Search,
      ModalExcelUpload,
    },
    data() {
      return {
        modal: {
          isOpened: false,
          currentComponent: null,
          initData: {},
          contentData: null,
        },
        stylingMode: 'outlined',
        searchType: {
          list: [
            {
              codeNm: '이름',
              codeValue: 'personNm',
            },
            {
              codeNm: '직위',
              codeValue: 'gradeNm',
            },
            {
              codeNm: '회사명',
              codeValue: 'companyNm',
            },
            {
              codeNm: '부서명',
              codeValue: 'deptNm',
            },
            {
              codeNm: '연락처1',
              codeValue: 'number1',
            },
            {
              codeNm: '연락처2',
              codeValue: 'number2',
            },
            {
              codeNm: '메모',
              codeValue: 'memo',
            },
          ],
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            groupId: null,
          },
          paramsData: null,
        },
        dataGrid: {
          excel: {
            title: this.$_lang('UC.WORD.CONTACT', { defaultValue: '주소록' }),
          },
          callApi: 'CALL_EUC_API',
          refName: 'contactGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          height: 'calc(100vh - 350px)', // 주석처리시 100%
          apiActionNm: {
            loading: true,
            update: 'EUC_CONTACT_UPDATE',
            delete: 'EUC_CONTACT_DELETE',
          },
          showActionButtons: {
            save: !this.$store.getters.getIsAdminUser,
            delete: !this.$store.getters.getIsAdminUser,
            excel: true,
            csv: false,
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: 'upload',
                  width: '40px',
                  height: '30px',
                  elementAttr: {
                    class: 'mr-1 white light_filled dx-shape-standard',
                  },
                  onClick: () => {
                    this.onUploadFile();
                  },
                },
                location: 'before',
              },
            ],
          },
          customEvent: {
            initNewRow: true,
          },
          isDuplicateConfigKey: false, //설정키 중복 체크
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: !this.$store.getters.getIsAdminUser, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: !this.$store.getters.getIsAdminUser, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '사번',
              dataField: 'regId',
              width: 100,
              height: 40,
              alignment: 'center',
              visible: false, // root 사용자만 보이도록 설정
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '그룹명',
              dataField: 'groupId',
              width: 200,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              lookup: {
                dataSource: [],
                displayExpr: 'groupNm',
                valueExpr: 'id',
                allowClearing: true,
              },
            },
            {
              caption: '이름',
              dataField: 'personNm',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              requiredRule: {
                message: '필수값 입니다.',
                i18n: 'COMMON.REQUIRED_VALUE',
              },
            },
            {
              caption: '직위',
              dataField: 'gradeNm',
              width: 130,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '회사',
              dataField: 'companyNm',
              width: 130,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '부서명',
              dataField: 'deptNm',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '연락처1',
              dataField: 'number1',
              width: 200,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              requiredRule: {
                message: '필수값 입니다.',
                i18n: 'COMMON.REQUIRED_VALUE',
              },
            },
            {
              caption: '연락처2',
              dataField: 'number2',
              width: 200,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '메모',
              dataField: 'memo',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
          ],
        },
        groupList: [],
      };
    },
    watch: {
      groupList: {
        handler: function (val) {
          this.dataGrid.columns.find(item => item.dataField === 'groupId').lookup.dataSource = val;
        },
        deep: true,
      },
    },
    computed: {
      getGroupList() {
        return [{ id: null, groupNm: '전체' }, ...this.groupList];
      },
    },
    methods: {
      async onSaveModal() {
        const data = await this.$refs.modalExcelUploadRef.getExcelData();
        let cnt = 0;
        while (cnt < data.length) {
          this.$refs.contactGrid.getInstance.addRow();
          cnt++;
        }

        data.forEach((item, index) => {
          if (item?.groupNm) {
            const group = this.groupList.find(group => group.groupNm === item.groupNm);
            if (group) {
              item.groupId = group.id;
            }
          }

          const fields = this.dataGrid.columns.map(column => column.dataField);
          fields.forEach(field => {
            this.$refs.contactGrid.getInstance.cellValue(index, field, item[field]);
          });
        });

        this.isOpenModal(false);
      },
      /** @description: 팝업이 열렸는지 체크하는 메서드(true: 열림/false: 닫힘) */
      isOpenModal(data) {
        this.modal.isOpened = data;
        if (!data) {
          this.modal.currentComponent = null;
          this.modal.initData = {};
          this.modal.contentData = null;
        }
      },
      /** @description: 팝업 창 열때 이벤트 */
      onOpenModal(componentNm, componentInitData, sendData) {
        this.modal.currentComponent = componentNm; //set dynamic component name in modal body slot
        this.modal.initData = componentInitData; //set init modal templet
        this.modal.sendData = sendData;
        this.isOpenModal(true);
      },
      onUploadFile() {
        this.onOpenModal('modal-excel-upload', {
          title: this.$_lang('UC.MESSAGE.EXCEL_UPLOAD', { defaultValue: '엑셀 등록' }),
          width: 700,
          height: 450,
        });
      },
      searchData(paramsData) {
        //검색 버튼 클릭시 이벤트
        if (paramsData) {
          this.searchType.paramsData = { ...this.searchType.paramsData, ...paramsData };
        } else {
          //검색 키워드의 값이 없으면
          //검색키는 설정하고, 검색어를 입력하지 않으면, 전체 목록 출력
          let codeValues = this.searchType.list.map(d => d.codeValue);
          if (!isEmpty(this.searchType.paramsData)) {
            Object.keys(this.searchType.paramsData).forEach(key => {
              if (codeValues.includes(key)) {
                delete this.searchType.paramsData[key]; //검색 조건 키 삭제
              }
            });
          }
        }
        this.selectDataList();
      },
      async selectDataList(sort = '-regDt') {
        if (this.$store.getters.getIsAdminUser) {
          const userNoColumn = this.dataGrid.columns.find(item => item.dataField === 'regId');
          userNoColumn.visible = true;
        }
        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);
            if (!params.sort) {
              params.sort = sort;
            }

            if (!this.$store.getters.getIsAdminUser) {
              params.regId = this.$store.getters.getLoginId;
            }

            params = { ...params, ...this.searchType.paramsData };

            const payload = {
              actionName: 'EUC_CONTACT_SELECT',
              data: params,
              loading: false,
            };

            const rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await this.CALL_EUC_API(payload);
            if (isSuccess(res)) {
              rtnData.data = res.data.data;
              rtnData.totalCount = res.data.header.totalCount;
            }
            return rtnData;
          },
        });
      },
      async selectGroupDataList() {
        const payload = {
          actionName: 'EUC_CONTACT_GROUP_SELECT',
          data: {
            regId: this.$store.getters.getLoginId,
            sort: '+groupOrd',
            viewFl: 'Y',
            pagesize: 9999,
          },
          loading: false,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          this.groupList = res.data.data;
        }
      },
    },
    mounted() {
      this.selectDataList();
    },
    created() {
      this.selectGroupDataList();
    },
  };
</script>
<style scoped></style>