<!--
  PACKAGE_NAME : src/pages/euc/device/hunt
  FILE_NAME : /huntManage.vue
  AUTHOR : jhsim
  DATE : 2024-05-01
  DESCRIPTION : 헌트관리
-->
<template>
  <div class="locker_setting_list sub_new_style01 sub_ui_box1">
    <div class="page-sub-box" style="padding: 0">
      <DxButton
        @click="onSyncHuntgroup"
        text="헌트 동기화"
        :height="30"
        class="dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled fl"
        styling-mode="contained"
        type="default"
      />
      <!-- :text="this.$_lang('UC.WORD.HUNTGROUP_SYNC')" -->
      <!-- :width="120" -->
      <DxButton
        @click="selectAllList"
        :text="this.$_lang('UC.WORD.CHECK')"
        :height="30"
        class="dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled fl"
        styling-mode="contained"
        type="default"
      />
      <!-- :width="60" -->
      <DxButton
        :text="this.$_lang('UC.WORD.LINEGROUPDN_SET')"
        :height="30"
        class="dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled fr"
        styling-mode="contained"
        type="default"
        @click="onClickListLinegroupDn"
      />
      <!-- :width="150" -->
      <DxButton
        :text="this.$_lang('UC.WORD.LIST_LINEGROUP_SET')"
        :height="30"
        class="dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled fr"
        styling-mode="contained"
        type="default"
        @click="onClickListLinegroup"
      />
      <!-- :width="150" -->
      <!-- <DxButton
        :text="this.$_lang('UC.WORD.LINEGROUP_ADD')"
        :height="30"
        class="dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled fr"
        styling-mode="contained"
        type="default"
        @click="isOpenModal(true, 'linegroup')"
      /> -->
      <!-- :width="150" -->
      <DxButton
        :text="this.$_lang('UC.WORD.PILOT_LIST_SET')"
        :height="30"
        class="dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled fr"
        styling-mode="contained"
        type="default"
        @click="isOpenModal(true, 'pilotlist')"
      />
      <!-- :width="150" -->
      <table class="table_form line-bin">
        <colgroup>
          <col style="width: 20%" />
          <col style="width: 20%" />
          <col style="width: 15%" />
          <col style="width: 44%" />
        </colgroup>
        <tr>
          <td style="padding: 0 5">
            <!-- <div style="margin: bottom 30px">
							<span style="font-weight: bold; font-size: 16px">{{ this.$_lang('UC.WORD.HUNTPILOT') }}</span>
							<span class="fr" style="font-weight: bold"
								>{{ this.$_lang('UC.WORD.TOTAL') }}
								<font class="col_blue2">{{ this.huntpilotGrid.selectedRowCnt }}</font> /
								<font class="col_blue2">{{ this.huntpilotGrid.totalCount }}</font>
								{{ this.$_lang('UC.WORD.COUNT') }}
							</span>
						</div> -->
            <esp-dx-data-grid
              :data-grid="huntpilotGrid"
              ref="huntpilotGrid"
              @selection-changed="onSelectionChanged($event, 'huntpilot')"
              @toolbar-preparing="onToolbarPreparing($event, 'huntpilot')"
              @reorder="onChangePilotOrd"
            >
            </esp-dx-data-grid>
            <div style="margin: bottom 30px; padding-top: 10px">
              <DxButton
                :text="this.$_lang('COMPONENTS.DELETE')"
                :height="30"
                class="btn_XS white light_filled trash fl"
                styling-mode="outlined"
                type="default"
                @click="onDeletePilotList"
              />
              <!-- :width="70" -->
              <DxButton
                :text="this.$_lang('UC.WORD.SAVE_ORDER')"
                :height="30"
                class="dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled fl"
                styling-mode="contained"
                type="default"
                @click="onUpdateOrder('huntpilot')"
              />
              <!-- :width="100" -->
            </div>
          </td>
          <td style="padding: 0 5">
            <!-- <div style="margin: bottom 30px">
							<span style="font-weight: bold; font-size: 16px">{{ this.$_lang('UC.WORD.HUNTLIST') }}</span>
							<span class="fr" style="font-weight: bold"
								>{{ this.$_lang('UC.WORD.TOTAL') }}
								<font class="col_blue2">{{ this.huntlistGrid.selectedRowCnt }}</font> /
								<font class="col_blue2">{{ this.huntlistGrid.totalCount }}</font>
								{{ this.$_lang('UC.WORD.COUNT') }}
							</span>
						</div> -->
            <esp-dx-data-grid
              :data-grid="huntlistGrid"
              ref="huntlistGrid"
              @selection-changed="onSelectionChanged($event, 'huntlist')"
              @toolbar-preparing="onToolbarPreparing($event, 'huntlist')"
            ></esp-dx-data-grid>
            <div style="margin: bottom 30px; padding-top: 40px"></div>
          </td>
          <td style="padding: 0 5">
            <!-- <div style="margin: bottom 30px">
							<span style="font-weight: bold; font-size: 16px">{{ this.$_lang('UC.WORD.LINEGROUP') }}</span>
							<span class="fr" style="font-weight: bold"
								>{{ this.$_lang('UC.WORD.TOTAL') }}
								<font class="col_blue2">{{ this.linegroupGrid.selectedRowCnt }}</font> /
								<font class="col_blue2">{{ this.linegroupGrid.totalCount }}</font
								>{{ this.$_lang('UC.WORD.COUNT') }}
							</span>
						</div> -->
            <esp-dx-data-grid
              :data-grid="linegroupGrid"
              ref="linegroupGrid"
              @selection-changed="onSelectionChanged($event, 'linegroup')"
              @toolbar-preparing="onToolbarPreparing($event, 'linegroup')"
              @reorder="onChangeLineOrd"
            ></esp-dx-data-grid>
            <div style="margin: bottom 30px; padding-top: 10px">
              <DxButton
                :text="this.$_lang('UC.WORD.SAVE_ORDER')"
                :height="30"
                class="dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled fl"
                styling-mode="contained"
                type="default"
                @click="onUpdateOrder('linegroup')"
              />
              <!-- :width="100" -->
            </div>
          </td>
          <td style="padding: 0 5">
            <!-- <div style="margin: bottom 30px; top: -40px">
							<span style="font-weight: bold; font-size: 16px">{{ this.$_lang('UC.WORD.LINEGROUPDN') }}</span>
							<span class="fr" style="font-weight: bold"
								>{{ this.$_lang('UC.WORD.TOTAL') }}
								<font class="col_blue2">{{ this.linegroupDnGrid.selectedRowCnt }}</font> /
								<font class="col_blue2">{{ this.linegroupDnGrid.totalCount }}</font
								>{{ this.$_lang('UC.WORD.COUNT') }}
							</span>
						</div> -->
            <esp-dx-data-grid
              :data-grid="linegroupDnGrid"
              ref="linegroupDnGrid"
              @selection-changed="onSelectionChanged($event, 'linegroupDn')"
              @reorder="onChangeLineDnOrd"
            ></esp-dx-data-grid>
            <!-- @content-ready="onContentReady" -->
            <!-- @toolbar-preparing="onToolbarPreparing" -->
            <div style="margin: bottom 30px; padding-top: 10px">
              <DxButton
                :text="this.$_lang('UC.WORD.SAVE_ORDER')"
                :height="30"
                class="dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled fl"
                styling-mode="contained"
                type="default"
                @click="onUpdateOrder('linegroupDn')"
              />
              <!-- :width="100" -->
            </div>
          </td>
        </tr>
      </table>
      <DxPopup
        :show-title="true"
        :title="modal.pilotlist.title"
        :width="modal.pilotlist.width"
        :height="modal.pilotlist.height"
        :drag-enabled="true"
        :resize-enabled="true"
        :show-close-button="true"
        :hide-on-outside-click="false"
        :visible="modal.pilotlist.isOpened"
        @hiding="isOpenModal(false, 'pilotlist')"
      >
        <ModalAddPilotList
          :cmList="this.cmList"
          :partitionList="this.partitionList"
          :callManagerList="this.callManagerList"
          :isOpened="this.modal.pilotlist.isOpened"
          @closeModal="closeModal('pilotlist')"
        ></ModalAddPilotList>
      </DxPopup>
      <DxPopup
        :show-title="true"
        :title="modal.linegroup.title"
        :width="modal.linegroup.width"
        :height="modal.linegroup.height"
        :drag-enabled="true"
        :resize-enabled="true"
        :show-close-button="true"
        :hide-on-outside-click="false"
        :visible="modal.linegroup.isOpened"
        @hiding="isOpenModal(false, 'linegroup')"
      >
        <ModalAddLineGroup
          :cmList="this.cmList"
          :isOpened="this.modal.linegroup.isOpened"
          @closeModal="closeModal('linegroup')"
        ></ModalAddLineGroup>
        <!-- :partitionList="this.partitionList" -->
      </DxPopup>
      <DxPopup
        :show-title="true"
        :title="modal.listline.title"
        :width="modal.listline.width"
        :height="modal.listline.height"
        :drag-enabled="true"
        :resize-enabled="true"
        :show-close-button="true"
        :hide-on-outside-click="false"
        :visible="modal.listline.isOpened"
        @hiding="isOpenModal(false, 'listline')"
      >
        <ModalSetListLineGroup
          :cmList="this.cmList"
          :rowInfo="this.rowInfo"
          :isOpened="this.modal.listline.isOpened"
          @closeModal="closeModal('listline')"
        ></ModalSetListLineGroup>
      </DxPopup>
      <DxPopup
        :show-title="true"
        :title="modal.linegroupDn.title"
        :width="modal.linegroupDn.width"
        :height="modal.linegroupDn.height"
        :drag-enabled="true"
        :resize-enabled="true"
        :show-close-button="true"
        :hide-on-outside-click="false"
        :visible="modal.linegroupDn.isOpened"
        @hiding="isOpenModal(false, 'linegroupDn')"
      >
        <ModalSetLineDn
          :cmList="this.cmList"
          :pickupGroupList="this.pickupGroupList"
          :rowInfo="this.rowInfo"
          :lineGroupId="this.rowInfo.linegroup.id"
          :isOpened="this.modal.linegroupDn.isOpened"
          @closeModal="closeModal('linegroupDn')"
        ></ModalSetLineDn>
      </DxPopup>
    </div>
  </div>
</template>
<!-- toolbar -->
<script>
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { DxButton } from 'devextreme-vue/button';
  import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
  import { DxCustomRule, DxRequiredRule, DxValidator } from 'devextreme-vue/validator';
  import { DxTextBox } from 'devextreme-vue/text-box';
  import { DxSwitch } from 'devextreme-vue/switch';
  import { isSuccess } from '@/plugins/common-lib';
  import ModalAddPilotList from './modal-add-pilotlist.vue';
  import ModalAddLineGroup from './modal-add-linegroup.vue';
  import ModalSetListLineGroup from './modal-set-listlinegroup.vue';
  import ModalSetLineDn from './modal-set-linedn.vue';
  import CustomStore from "devextreme/data/custom_store";
  import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid.vue";

  export default {
    components: {
      EspDxDataGrid,
      DxTextBox,
      DxCustomRule,
      DxButton,
      DxRequiredRule,
      DxValidator,
      DxSelectBox,
      DxPopup,
      DxToolbarItem,
      ModalAddPilotList,
      ModalAddLineGroup,
      ModalSetListLineGroup,
      ModalSetLineDn,
    },
    props: {
      cmList: {
        type: Array,
      },
    },
    data() {
      return {
        config: {
          pageSetting: {
            //pageData pageSetting 관련
            config: {},
          },
        },
        dragData: {
          huntpilot: [],
          linegroup: [],
          linegroupDn: [],
        },
        flag: {
          huntpilot: false,
          linegroup: false,
          linegroupDn: false,
        },
        lineGroupId: '0',
        contentReady: false,
        modal: {
          pilotlist: {
            isOpened: false,
            title: this.$_lang('UC.WORD.PILOT_LIST_SET'),
            width: '70%',
            height: '650',
          },
          listline: {
            isOpened: false,
            title: this.$_lang('UC.WORD.LIST_LINEGROUP_SET'),
            width: '70%',
            height: '700',
          },
          linegroup: {
            isOpened: false,
            // title: '회선그룹 추가',
            title: this.$_lang('UC.WORD.LINEGROUP_ADD'),
            width: '40%',
            height: '650',
          },
          linegroupDn: {
            isOpened: false,
            // title: '회선번호 설정',
            title: this.$_lang('UC.WORD.LINEGROUPDN_SET'),
            width: '50%',
            height: '850',
          },
        },
        // cmList: [],
        partitionList: [],
        pickupGroupList: [],
        callManagerList: [],
        rowInfo: {
          pilot: {},
          list: {},
          linegroup: {},
          linegroupDn: [],
        },
        stylingMode: 'outlined', //outlined, underlined, filled
        searchType: {
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            siteId: null,
          },
          paramsData: null,
        },
        huntpilotGrid: {
          callApi: 'CALL_EUC_API',
          refName: 'huntpilotGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          allowReordering: true,
          showBorders: true, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          disableTotalCount: false,
          toolbarItems: [],
          selectedRowCnt: 0,
          totalCount: 0,
          dataSource: [],
          // width: '400', // 주석처리시 100%
          // height: '550', // 주석처리시 100%
          height: 'calc(100vh - 360px)', // 주석처리시 100%
          apiActionNm: {
            // update: '',
          },
          dragging: {
            sortColumn: 'huntpilotOrd',
            allowReordering: true,
            dropFeedbackMode: 'push', // 설정하면 dragging 할때 기존리스트가 아래로 움직이는 효과
          },
          customEvent: {
            initNewRow: true,
            rowClick: false,
            toolbarPreparing: false,
            selectionChanged: true,
            reorder: true,
            saving: true,
          },
          showActionButtons: {
            customButtons: [],
          },
          isDuplicateConfigKey: false, //설정키 중복 체크
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: false,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'single', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '패턴',
              i18n: 'UC.WORD.PATTERN',
              dataField: 'pattern',
              width: 100,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowExporing: true,
              fixed: false,
            },
            {
              caption: '설명',
              i18n: 'COMPONENTS.DESCRIPTION',
              dataField: 'description',
              // width: 250,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
          ],
        },
        huntlistGrid: {
          callApi: 'CALL_EUC_API',
          refName: 'huntlistGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: true, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          disableTotalCount: false,
          toolbarItems: [],
          selectedRowCnt: 0,
          totalCount: 0,
          // width: '400', // 주석처리시 100%
          //   height: '550', // 주석처리시 100%
          height: 'calc(100vh - 360px)', // 주석처리시 100%
          apiActionNm: {
            loading: true,
          },
          customEvent: {
            toolbarPreparing: false,
            initNewRow: true,
            rowClick: false,
            selectionChanged: true,
          },
          showActionButtons: {
            // delete: true,
            customButtons: [],
          },
          isDuplicateConfigKey: false, //설정키 중복 체크
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'single', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '이름',
              i18n: 'COMPONENTS.NAME',
              dataField: 'name',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowExporing: true,
              fixed: false,
            },
            {
              caption: '설명',
              i18n: 'COMPONENTS.DESCRIPTION',
              dataField: 'description',
              // width: 200,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
          ],
        },
        linegroupGrid: {
          callApi: 'CALL_EUC_API',
          refName: 'linegroupGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          allowReordering: true,
          showBorders: true, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          disableTotalCount: false,
          toolbarItems: [],
          selectedRowCnt: 0,
          totalCount: 0,
          // width: '400', // 주석처리시 100%
          //   height: '550', // 주석처리시 100%
          height: 'calc(100vh - 360px)', // 주석처리시 100%
          apiActionNm: {
            loading: true,
          },
          customEvent: {
            initNewRow: true,
            rowClick: false,
            selectionChanged: true,
            reorder: true,
            toolbarPreparing: false,
          },
          dragging: {
            sortColumn: 'selectionorder',
            allowReordering: true,
            dropFeedbackMode: 'push', // 설정하면 dragging 할때 기존리스트가 아래로 움직이는 효과
          },
          showActionButtons: {
            // delete: true,
            customButtons: [],
          },
          isDuplicateConfigKey: false, //설정키 중복 체크
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '이름',
              i18n: 'COMPONENTS.NAME',
              dataField: 'name',
              // width: 200,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowExporing: true,
              fixed: false,
            },
          ],
        },
        linegroupDnGrid: {
          callApi: 'CALL_EUC_API',
          refName: 'linegroupDnGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          allowReordering: true,
          showBorders: true, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // disableTotalCount: true,
          disableTotalCount: false,
          toolbarItems: [
            {
              location: 'before',
              html:
                `<div style="margin: bottom 30px">
							<span style="font-weight: bold; font-size: 16px">` +
                this.$_lang('UC.WORD.LINEGROUP') +
                `</span></div>`,
            },
          ],
          selectedRowCnt: 0,
          totalCount: 0,
          // width: '400', // 주석처리시 100%
          //   height: '550', // 주석처리시 100%
          height: 'calc(100vh - 360px)', // 주석처리시 100%
          apiActionNm: {
            loading: true,
          },
          customEvent: {
            initNewRow: true,
            rowClick: false,
            selectionChanged: true,
            reorder: true,
            toolbarPreparing: false,
          },
          dragging: {
            sortColumn: 'lineselectionorder',
            allowReordering: true,
            dropFeedbackMode: 'push', // 설정하면 dragging 할때 기존리스트가 아래로 움직이는 효과
          },
          showActionButtons: {
            // delete: true,
            customButtons: [],
          },
          isDuplicateConfigKey: false, //설정키 중복 체크
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            // enabled: false,
            enabled: true,
            pageSize: 50,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '패턴',
              i18n: 'UC.WORD.PATTERN',
              dataField: 'pattern',
              width: 100,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowExporing: true,
              fixed: false,
            },
            {
              caption: '파티션',
              i18n: 'UC.WORD.PARTITION',
              dataField: 'routepartitionname',
              width: 200,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '전체리스트',
              i18n: 'UC.WORD.ALL_LIST',
              dataField: 'list',
              // width: 250,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowFiltering: false,
              allowGrouping: false,
            },
          ],
        },
      };
    },
    computed: {},
    methods: {
      /** @description : 헌트 파일럿 리스트 조회 */
      async selectHuntpilot(sort = '+huntpilotOrd') {
        this.huntpilotGrid.dataSource = new CustomStore({
          key: 'id',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);
            if (!params.sort) {
              params.sort = sort;
            }

            params = { ...params, ...this.searchType.paramsData };

            if (params?.currentpage) {
              delete params['currentpage'];
            }
            const payload = {
              actionName: 'EUC_HUNTPILOT_LIST_ALL',
              data: params,
              loading: false,
            };

            const rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await this.CALL_EUC_API(payload);
            if (isSuccess(res)) {
              if (typeof params.pattern != 'undefined' || typeof params.description != 'undefined') {
                this.flag.huntpilot = true;
              } else {
                this.flag.huntpilot = false;
              }

              rtnData.data = res.data.data;
              rtnData.totalCount = res.data.header.totalCount;
              // 드래깅 데이터 초기화
              this.dragData.huntpilot = [];
              await this.$refs.huntpilotGrid.clearSelection();
              await this.repaintAllGrid();
            }
            return rtnData;
          },
        });
      },
      /** @description : 헌트리스트 리스트 조회 */
      async selectHuntlist(sort, pilotId) {
        this.huntlistGrid.dataSource = new CustomStore({
          key: 'id',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);
            if (!params.sort) {
              params.sort = sort;
            }
            if (pilotId !== null) {
              params.huntpilotId = pilotId;
            }
            if (params?.currentpage) {
              delete params['currentpage'];
            }
            params = { ...params, ...this.searchType.paramsData };

            const payload = {
              actionName: 'EUC_HUNTLIST_LIST_ALL',
              data: params,
              loading: false,
            };

            const rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await this.CALL_EUC_API(payload);
            if (isSuccess(res)) {
              rtnData.data = res.data.data;
              rtnData.totalCount = res.data.header.totalCount;

              await this.$refs.huntlistGrid.clearSelection();
              await this.repaintAllGrid();
            }
            return rtnData;
          },
        });
      },
      /** @description : 회선그룹 리스트 조회 */
      async selectLinegroup(sort, huntlistId) {
        this.linegroupGrid.dataSource = new CustomStore({
          key: 'id',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);
            if (!params.sort) {
              params.sort = sort;
            }

            if (huntlistId !== null) {
              params.huntlistId = huntlistId;
            }
            if (params?.currentpage) {
              delete params['currentpage'];
            }
            params = { ...params, ...this.searchType.paramsData };

            const payload = {
              actionName: 'EUC_LINEGROUP_LIST_ALL',
              data: params,
              loading: false,
            };

            const rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await this.CALL_EUC_API(payload);
            if (isSuccess(res)) {
              if (typeof params.name != 'undefined') {
                this.flag.linegroup = true;
              } else {
                this.flag.linegroup = false;
              }

              rtnData.data = res.data.data;
              rtnData.totalCount = res.data.header.totalCount;

              this.dragData.linegroup = [];
              await this.$refs.linegroupGrid.clearSelection();
              await this.repaintAllGrid();
            }
            return rtnData;
          },
        });
      },
      /** @description : 회선번호 리스트 조회 */
      async selectLinegroupDn(sort, linegroupId) {
        this.linegroupDnGrid.dataSource = new CustomStore({
          key: 'id',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);
            if (!params.sort) {
              params.sort = sort;
            }
            if (linegroupId !== null) {
              params.linegroupId = linegroupId;
            }

            params = { ...params, ...this.searchType.paramsData };

            const payload = {
              actionName: 'EUC_LINEGROUPDN_LIST_ALL',
              data: params,
              loading: false,
            };

            const rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await this.CALL_EUC_API(payload);
            if (isSuccess(res)) {
              if (typeof params.pattern != 'undefined' || typeof params.routepartitionname != 'undefined') {
                this.flag.linegroupDn = true;
              } else {
                this.flag.linegroupDn = false;
              }

              rtnData.data = res.data.data;
              rtnData.totalCount = res.data.header.totalCount;

              this.dragData.linegroupDn = [];
              await this.$refs.linegroupDnGrid.clearSelection();
              await this.repaintAllGrid();
            }
            return rtnData;
          },
        });
      },
      /**@description 헌트관련 데이터(헌트파일럿 , 헌트리스트 , 라인그룹 , 라인넘버) 동기화 */
      async onSyncHuntgroup() {
        let confirm = this.$_lang('UC.MESSAGE.START_SYNC');
        if (!(await this.$_Confirm(confirm))) {
          return;
        }
        for (const item of this.cmList) {
          const params = { cmNm: item.cmNm, loginId: this.$store.getters.getLoginId, type: 'RELATED' };
          const payload = {
            actionName: 'EUC_HUNTGROUP_SYNC',
            data: params,
            loading: true,
          };

          const res = await this.CALL_EUC_API(payload);
          if (isSuccess(res)) {
            // await this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_SUCCESS', { defaultValue: '정상적으로 처리되었습니다.' }));
            await this.selectHuntpilot();
          } else {
            if (res.data.data.length == 0) {
              await this.$_Msg(res.data.header.resMsg);
            } else {
              await this.$_Msg('교환기 : ' + item.cmNm + ' ' + res.data.header.resMsg);
            }
          }
        }
      },
      /**@description			그리드 selectRow 이벤트 함수
       * @param 	{Object}	그리드 selction 이벤트 데이터
       * @param 	{String}	그리드 ref 이름
       * @returns {}
       */
      onSelectionChanged(e, type) {
        switch (type) {
          case 'huntpilot':
            this.huntpilotGrid.selectedRowCnt = e.selectedRowsData.length;
            if (this.huntpilotGrid.selectedRowCnt > 0) {
              if (typeof e.selectedRowKeys[0] == 'number') {
                this.selectHuntlist('+huntlistOrd', e.selectedRowKeys);
              } else {
                let selectRowKeys = e.selectedRowsData.map(item => {
                  return item.id;
                });
                this.selectHuntlist('+huntlistOrd', selectRowKeys);
              }
            } else if (this.huntpilotGrid.selectedRowCnt === 0) {
              //하위 그리드 초기화
              this.resetDataGrid('huntpilot');
            }
            break;
          case 'huntlist':
            this.huntlistGrid.selectedRowCnt = e.selectedRowsData.length;
            if (this.huntlistGrid.selectedRowCnt > 0) {
              if (typeof e.selectedRowKeys[0] == 'number') {
                // this.selectHuntlist('+huntlistOrd', e.selectedRowKeys);
                this.selectLinegroup('+selectionorder', e.selectedRowKeys);
              } else {
                let selectRowKeys = e.selectedRowsData.map(item => {
                  return item.id;
                });
                this.selectLinegroup('+selectionorder', selectRowKeys);
                // this.selectHuntlist('+huntlistOrd', selectRowKeys);
              }
            } else if (this.huntlistGrid.selectedRowCnt === 0) {
              //하위 그리드 초기화
              this.resetDataGrid('huntlist');
            }
            break;
          case 'linegroup':
            this.linegroupGrid.selectedRowCnt = e.selectedRowsData.length;
            if (this.linegroupGrid.selectedRowCnt > 0) {
              if (typeof e.selectedRowKeys[0] == 'number') {
                this.selectLinegroupDn('+lineselectionorder', e.selectedRowKeys);
              } else {
                let selectRowKeys = e.selectedRowsData.map(item => {
                  return item.id;
                });
                this.selectLinegroupDn('+lineselectionorder', selectRowKeys);
              }
            } else if (this.linegroupGrid.selectedRowCnt === 0) {
              //하위 그리드 초기화
              this.resetDataGrid('linegroup');
            }
            break;
          case 'linegroupDn':
            this.linegroupDnGrid.selectedRowCnt = e.selectedRowsData.length;
            break;
          default:
            break;
        }
        this.repaintAllGrid();
      },

      /** @description	그리드 순서 변경 이벤트
       *  @param {Object}	그리드 순서 변경 이벤트
       *  @param {type}	그리드 타입
       */
      onReorder(e, type) {
        if (type == 'huntpilot') {
          let data = [];
          if (this.dragData.huntpilot.length > 0) {
            data = this.dragData.huntpilot;
          } else {
            data = this.getGridDataSource('huntpilotGrid');
          }

          const visibleRows = e.component.getVisibleRows();
          const toIndex = data.findIndex(item => item.id === visibleRows[e.toIndex].data.id);
          const fromIndex = data.findIndex(item => item.id === e.itemData.id);

          const newDataSource = [...data];
          newDataSource.splice(fromIndex, 1);
          newDataSource.splice(toIndex, 0, e.itemData);

          if (fromIndex < toIndex) {
            // 아래로 이동할 때
            for (let i = fromIndex; i <= toIndex; i++) {
              newDataSource[i].huntpilotOrd = i + 1;
            }
          } else {
            // 위로 이동할 때
            for (let i = toIndex; i <= fromIndex; i++) {
              newDataSource[i].huntpilotOrd = i + 1;
            }
          }
          // 최종적으로 전체 배열의 ord 값을 순서대로 재설정
          newDataSource.forEach((item, index) => {
            item.huntpilotOrd = index + 1;
          });
          this.dragData.huntpilot = newDataSource;
          this.huntpilotGrid.dataSource = newDataSource;
        }
      },
      /**@description grid toolbar event
       * @param {String} grid ref name
       */
      onToolbarPreparing(e, type) {
        const toolbarItems = e.toolbarOptions.items;
        // Adds a new item
        if (type == 'huntpilot') {
          toolbarItems.push({
            location: 'before',
            html:
              `<div style="margin: bottom 30px">
							<span style="font-weight: bold; font-size: 16px">` +
              this.$_lang('UC.WORD.HUNTPILOT') +
              `</span></div>`,
          });
          toolbarItems.push({
            location: 'after',
            html:
              `<span class="fr" style="font-weight: bold">` +
              this.$_lang('UC.WORD.TOTAL') +
              ' ' +
              `<font class="col_blue2">` +
              this.huntpilotGrid.selectedRowCnt +
              ' ' +
              `</font> / <font class="col_blue2">` +
              this.$refs.huntpilotGrid.totalCount +
              `</font>` +
              ' ' +
              this.$_lang('UC.WORD.COUNT') +
              `</span>`,
          });
        }

        if (type == 'huntlist') {
          toolbarItems.push({
            location: 'before',
            html:
              `<div style="margin: bottom 30px">
							<span style="font-weight: bold; font-size: 16px">` +
              this.$_lang('UC.WORD.HUNTLIST') +
              `</span></div>`,
          });
          toolbarItems.push({
            location: 'after',
            html:
              `<span class="fr" style="font-weight: bold">` +
              this.$_lang('UC.WORD.TOTAL') +
              ' ' +
              `<font class="col_blue2">` +
              this.huntlistGrid.selectedRowCnt +
              ' ' +
              `</font> / <font class="col_blue2">` +
              this.$refs.huntlistGrid.totalCount +
              `</font>` +
              ' ' +
              this.$_lang('UC.WORD.COUNT') +
              `</span>`,
          });
        }

        if (type == 'linegroup') {
          toolbarItems.push({
            location: 'before',
            html:
              `<div style="margin: bottom 30px">
							<span style="font-weight: bold; font-size: 16px">` +
              this.$_lang('UC.WORD.LINEGROUP') +
              `</span></div>`,
          });
          toolbarItems.push({
            location: 'after',
            html:
              `<span class="fr" style="font-weight: bold">` +
              this.$_lang('UC.WORD.TOTAL') +
              ' ' +
              `<font class="col_blue2">` +
              this.linegroupGrid.selectedRowCnt +
              ' ' +
              `</font> / <font class="col_blue2">` +
              this.$refs.linegroupGrid.totalCount +
              `</font>` +
              ' ' +
              this.$_lang('UC.WORD.COUNT') +
              `</span>`,
          });
        }
        //toolbar sort
        e.toolbarOptions.items = toolbarItems.sort((a, b) => a.sortIndex - b.sortIndex);
      },

      /** @description	 refs 별 그리드데이터 리턴하는 함수
       *  @param {String}  refs 이름
       * 	@returns {Array} 그리드 dataSource
       */
      getGridDataSource(refs) {
        let data = this.$refs[refs].getItems;
        return data;
      },
      /** @description	순서 저장 이벤트
       *  @param {type}	그리드 타입
       */
      async onUpdateOrder(type) {
        if (type === 'huntpilot') {
          if (this.flag.huntpilot) {
            await this.$_Msg(
              '검색조건으로 검색 한 뒤에는 순서저장을 실행할수 없습니다. 조건없이 전체 조회 후 다시 시도해주세요.',
            );
            return false;
          }
          if (this.dragData.huntpilot.length === 0) {
            this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NO_CHANGED'));
            return false;
          }
          // const params = { pilotList: this.dragData.huntpilot };
          const params = this.dragData.huntpilot;
          const payload = {
            actionName: 'EUC_HUNTPILOT_UPDATE',
            data: params,
            loading: true,
          };

          const confirm =
            this.$_lang('UC.MESSAGE.CHANGE_LIST_ORDER') + this.$_lang('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' });
          if (!(await this.$_Confirm(confirm))) {
            return;
          }
          const res = await this.CALL_EUC_API(payload);
          if (isSuccess(res)) {
            await this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE'));
            await this.selectHuntpilot();
          } else {
            await this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_ERROR'));
          }
        }
        if (type === 'linegroup') {
          if (this.flag.linegroup) {
            await this.$_Msg(
              '검색조건으로 검색 한 뒤에는 순서저장을 실행할수 없습니다. 조건없이 전체 조회 후 다시 시도해주세요.',
            );
            return false;
          }
          if (this.dragData.linegroup.length === 0) {
            this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NO_CHANGED'));
            return false;
          }
          const selectRows = this.$refs.huntlistGrid.getInstance.getSelectedRowsData();
          const selectPilotRows = this.$refs.huntpilotGrid.getInstance.getSelectedRowsData();
          let listName = '';
          let listId = 0;
          let cmNm = '';
          if (selectRows?.length === 1) {
            listName = selectRows[0].name;
            listId = selectRows[0].id;
            cmNm = selectPilotRows.filter(d => d.id == selectRows[0].huntpilotId)[0].cmNm;
          } else {
            this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_ONE_SELECT'));
            return;
          }
          const parseData = [];
          this.dragData.linegroup.forEach(item => {
            parseData.push({ LINEGROUP: item.name, ORDER: item.selectionorder });
          });

          const params = {
            cmNm: cmNm,
            huntlistId: listId,
            type: 'HUNTLIST',
            huntlist: listName,
            // linegroupList: parseData,
            linegroupList: JSON.stringify(parseData),
            loginId: this.$store.getters.getLoginId,
          };
          const payload = {
            actionName: 'EUC_LINEGROUP_UPDATE',
            data: params,
            loading: true,
          };
          const confirm = this.$_lang('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' });
          if (!(await this.$_Confirm(confirm))) {
            return;
          }
          const res = await this.CALL_EUC_API(payload);
          if (isSuccess(res)) {
            await this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_SUCCESS', { defaultValue: '정상적으로 처리되었습니다.' }));
          } else {
            await this.$_Msg(res.data.header.resMsg);
          }
        }
        if (type === 'linegroupDn') {
          if (this.flag.linegroupDn) {
            await this.$_Msg(
              '검색조건으로 검색 한 뒤에는 순서저장을 실행할수 없습니다. 조건없이 전체 조회 후 다시 시도해주세요.',
            );
            return false;
          }
          if (this.dragData.linegroupDn.length === 0) {
            this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NO_CHANGED'));
            return false;
          }
          const selectRows = this.$refs.linegroupGrid.getInstance.getSelectedRowsData();
          let linegroupName = '';

          if (selectRows?.length === 1) {
            linegroupName = selectRows[0].name;
          } else {
            // this.$_Msg('회선그룹은 한건만 선택해주세요.');
            this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_ONE_SELECT'));
            return;
          }

          const parseData = [];
          this.dragData.linegroupDn.forEach(item => {
            parseData.push({
              PATTERN: item.pattern,
              ROUTEPARTITION: item.routepartitionname,
              ORDER: item.lineselectionorder,
            });
          });
          const params = {
            cmNm: this.dragData.linegroupDn[0]?.cmNm,
            huntpilotId: this.dragData.linegroupDn[0]?.huntpilotId,
            type: 'LINEGROUP',
            linegroup: linegroupName,
            linegroupDnList: JSON.stringify(parseData),
            loginId: this.$store.getters.getLoginId,
          };
          const payload = {
            actionName: 'EUC_LINEGROUPDN_UPDATE',
            data: params,
            loading: false,
          };
          // const confirm = '순서를 변경하시겠습니까? ';
          const confirm = this.$_lang('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' });
          if (!(await this.$_Confirm(confirm))) {
            return;
          }
          const res = await this.CALL_EUC_API(payload);
          if (isSuccess(res)) {
            await this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_SUCCESS', { defaultValue: '정상적으로 처리되었습니다.' }));
          } else {
            await this.$_Msg(res.data.header.resMsg);
          }
        }
        await this.selectAllList();
      },

      /**@description			헌트 파일럿 그리드 순서 변경 이벤트
       * @param 	{Object}	그리드 dragging 이벤트 데이터
       * @returns {}
       */
      onChangePilotOrd(e) {
        let data = [];
        if (this.dragData.huntpilot.length > 0) {
          data = this.dragData.huntpilot;
        } else {
          data = this.getGridDataSource('huntpilotGrid');
        }

        const visibleRows = e.component.getVisibleRows();
        const toIndex = data.findIndex(item => item.id === visibleRows[e.toIndex].data.id);
        const fromIndex = data.findIndex(item => item.id === e.itemData.id);

        const newDataSource = [...data];
        newDataSource.splice(fromIndex, 1);
        newDataSource.splice(toIndex, 0, e.itemData);

        if (fromIndex < toIndex) {
          // 아래로 이동할 때
          for (let i = fromIndex; i <= toIndex; i++) {
            newDataSource[i].huntpilotOrd = i + 1;
          }
        } else {
          // 위로 이동할 때
          for (let i = toIndex; i <= fromIndex; i++) {
            newDataSource[i].huntpilotOrd = i + 1;
          }
        }

        // 최종적으로 전체 배열의 ord 값을 순서대로 재설정
        newDataSource.forEach((item, index) => {
          item.huntpilotOrd = index + 1;
        });
        this.dragData.huntpilot = newDataSource;
        this.huntpilotGrid.dataSource = newDataSource;
      },

      /**@description			회선그룹 그리드 순서 변경 이벤트
       * @param 	{Object}	그리드 dragging 이벤트 데이터
       * @returns {}
       */
      onChangeLineOrd(e) {
        const selectRows = this.$refs.huntlistGrid.getInstance.getSelectedRowsData();
        if (selectRows?.length === 0) {
          // this.$_Msg('리스트를 선택 한 뒤에 순서 조정이 가능합니다.');
          this.$_Msg(this.$_lang('UC.MESSAGE.REQUIRED_SELECT_HUNTLIST'));
          return;
        } else if (selectRows.length > 1) {
          // this.$_Msg('리스트를 하나만 선택하세요.');
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_ONE_SELECT'));
          return;
        }
        let data = [];
        if (this.dragData.linegroup.length > 0) {
          data = this.dragData.linegroup;
        } else {
          data = this.getGridDataSource('linegroupGrid');
        }

        const visibleRows = e.component.getVisibleRows();
        const toIndex = data.findIndex(item => item.id === visibleRows[e.toIndex].data.id);
        const fromIndex = data.findIndex(item => item.id === e.itemData.id);

        const newDataSource = [...data];
        newDataSource.splice(fromIndex, 1);
        newDataSource.splice(toIndex, 0, e.itemData);
        if (fromIndex < toIndex) {
          // 아래로 이동할 때
          for (let i = fromIndex; i <= toIndex; i++) {
            newDataSource[i].selectionorder = i + 1;
          }
        } else {
          // 위로 이동할 때
          for (let i = toIndex; i <= fromIndex; i++) {
            newDataSource[i].selectionorder = i + 1;
          }
        }

        // 최종적으로 전체 배열의 ord 값을 순서대로 재설정
        newDataSource.forEach((item, index) => {
          item.selectionorder = index + 1;
        });
        this.dragData.linegroup = newDataSource;
        this.linegroupGrid.dataSource = newDataSource;
      },

      /**@description			회선번호 그리드 순서 변경 이벤트
       * @param 	{Object}	그리드 dragging 이벤트 데이터
       * @returns {}
       */
      onChangeLineDnOrd(e) {
        const selectRows = this.$refs.linegroupGrid.getInstance.getSelectedRowsData();
        if (selectRows?.length === 0) {
          // this.$_Msg('회선그룹을 선택 한 뒤에 순서 조정이 가능합니다.');
          this.$_Msg(this.$_lang('UC.MESSAGE.REQUIRED_SELECT_LINEGROUP'));
          return;
        } else if (selectRows.length > 1) {
          // this.$_Msg('회선그룹을 하나만 선택하세요.');
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_ONE_SELECT'));
          return;
        }
        let data = [];
        if (this.dragData.linegroupDn.length > 0) {
          data = this.dragData.linegroupDn;
        } else {
          data = this.getGridDataSource('linegroupDnGrid');
        }

        const visibleRows = e.component.getVisibleRows();
        const toIndex = data.findIndex(item => item.id === visibleRows[e.toIndex].data.id);
        const fromIndex = data.findIndex(item => item.id === e.itemData.id);

        const newDataSource = [...data];
        newDataSource.splice(fromIndex, 1);
        newDataSource.splice(toIndex, 0, e.itemData);

        if (fromIndex < toIndex) {
          // 아래로 이동할 때
          for (let i = fromIndex; i <= toIndex; i++) {
            newDataSource[i].lineselectionorder = i + 1;
          }
        } else {
          // 위로 이동할 때
          for (let i = toIndex; i <= fromIndex; i++) {
            newDataSource[i].lineselectionorder = i + 1;
          }
        }

        // 최종적으로 전체 배열의 ord 값을 순서대로 재설정
        newDataSource.forEach((item, index) => {
          item.lineselectionorder = index + 1;
        });
        this.dragData.linegroupDn = newDataSource;
        this.linegroupDnGrid.dataSource = newDataSource;
      },

      /**@description			그리드 전체선택 이벤트
       * @param 	{String}	그리드 ref 이름
       * @returns {}
       */
      // onClickAllSelect(event, type) {
      // 	this.$refs[type].getInstance.selectAll();
      // },
      /**@description			그리드 전체해제 이벤트
       * @param 	{String}	그리드 ref 이름
       * @returns {}
       */
      // onClickDeSelect(event, type) {
      // 	this.$refs[type].getInstance.deselectAll();
      // },

      /**@description	리스트/회선그룹 설정 버튼 클릭 이벤트 ,  파일럿 , 리스트 1건씩 선택 후 실행 가능.
       * @param 	{}
       * @returns {}
       */
      async onClickListLinegroup() {
        const selectPilotRows = this.$refs.huntpilotGrid.getInstance.getSelectedRowsData();
        const selectListRows = this.$refs.huntlistGrid.getInstance.getSelectedRowsData();
        if (selectPilotRows?.length == 0 || typeof selectPilotRows?.length == 'undefined') {
          this.$_Msg(this.$_lang('UC.MESSAGE.REQUIRED_SELECT_HUNTPILOT'));
          // this.$_Msg('파일럿 항목을 선택하세요.');
          return;
          REQUIRED_SELECT_HUNTPILOT;
        } else if (selectPilotRows?.length > 1) {
          // this.$_Msg('파일럿 항목을 하나만 선택하세요.');
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_ONE_SELECT'));
          return;
        }

        if (selectListRows?.length == 0 || typeof selectListRows?.length == 'undefined') {
          // this.$_Msg('리스트 항목을 선택하세요.');
          this.$_Msg(this.$_lang('UC.MESSAGE.REQUIRED_SELECT_HUNTLIST'));
          return;
        } else if (selectListRows?.length > 1) {
          // this.$_Msg('리스트 항목을 하나만 선택하세요.');
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_ONE_SELECT'));
          return;
        }

        if (selectListRows?.length == 1 && selectPilotRows?.length == 1) {
          this.rowInfo.pilot = selectPilotRows;
          this.rowInfo.list = selectListRows;
          await this.isOpenModal(true, 'listline');
        }

        // await this.isOpenModal(true , 'listline');
      },
      /**@description	회선번호 설정 버튼 클릭 이벤트 ,  회선번호 1건 선택 후 실행 가능.
       * @param 	{}
       * @returns {}
       */
      async onClickListLinegroupDn() {
        const selectLineGroupRows = this.$refs.linegroupGrid.getInstance.getSelectedRowsData();
        const selectHuntPilotRows = this.$refs.huntpilotGrid.getInstance.getSelectedRowsData();
        const selectLineGroupDnRows = this.$refs.linegroupDnGrid.getInstance.getSelectedRowsData();
        if (selectLineGroupRows?.length == 0 || typeof selectLineGroupRows?.length == 'undefined') {
          // this.$_Msg('회선그룹 항목을 선택하세요.');
          this.$_Msg(this.$_lang('UC.MESSAGE.REQUIRED_SELECT_LINEGROUP'));
          return;
        } else if (selectLineGroupRows?.length > 1) {
          // this.$_Msg('회선그룹 항목을 하나만 선택하세요.');
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_ONE_SELECT'));
          return;
        }

        if (selectLineGroupRows?.length == 1) {
          const linegroupDnRows = this.$refs.linegroupDnGrid.getItems;
          this.rowInfo.pilot = selectHuntPilotRows[0];
          this.rowInfo.linegroup = selectLineGroupRows[0];
          // this.rowInfo.linegroupDn = selectLineGroupDnRows;
          this.rowInfo.linegroupDn = linegroupDnRows;
          this.isOpenModal(true, 'linegroupDn');
        }

        // await this.isOpenModal(true , 'listline');
      },
      /**@description			모달 on of 스위치
       * @param 	{boolean}	모달 실행 , 종료
       * @param 	{String}	모달 창 종류 hunt , linegroup , linegroupdn
       */
      isOpenModal(data, type) {
        this.modal[type].isOpened = data;
        // this.modal.isOpened = data;
      },
      /**@description			모달 on of 스위치
       * @param 	{String}	모달 창 종류 hunt , linegroup , linegroupdn
       */
      async closeModal(type) {
        switch (type) {
          case 'pilotlist': // 파일럿/리스트 추가 모달 종료
            await this.isOpenModal(false, 'pilotlist');
            break;
          case 'listline': // 리스트/회선그룹 설정 모달 종료
            await this.isOpenModal(false, 'listline');
            this.rowInfo.pilot = {};
            this.rowInfo.list = {};
            break;
          case 'linegroup': // 회선그룹 추가 모달 종료
            await this.isOpenModal(false, 'linegroup');
            break;
          case 'linegroupDn': //회선번호 추가 모달 종료
            await this.isOpenModal(false, 'linegroupDn');
            this.rowInfo.linegroup = {};
            this.rowInfo.pilot = {};
            break;
          default:
            break;
        }
      },
      /**@description 파일럿 / 리스트 삭제 */
      async onDeletePilotList() {
        const selectPilotRows = this.$refs.huntpilotGrid.getInstance.getSelectedRowsData();
        const selectListRows = this.$refs.huntlistGrid.getInstance.getSelectedRowsData();

        if (selectPilotRows?.length == 0) {
          this.$_Msg(this.$_lang('UC.MESSAGE.REQUIRED_SELECT_HUNTPILOT'));
          return;
        }

        if (selectListRows?.length == 0) {
          this.$_Msg(this.$_lang('UC.MESSAGE.REQUIRED_SELECT_HUNTLIST'));
          return;
        }

        if (selectPilotRows?.length > 1 || selectListRows?.length > 1) {
          this.$_Msg(this.$_lang('UC.MESSAGE.PILOT_LIST_ONE_SELECT'));
          return;
        }

        if (selectPilotRows[0].id != selectListRows[0].huntpilotId) {
          this.$_Msg(this.$_lang('UC.MESSAGE.PILOT_LIST_MISS_MATCH'));
          return;
        }

        let cmNm = '';
        let parseData = [];
        let huntlistName = '';

        selectListRows.forEach(item => {
          huntlistName = item.name;
        });
        selectPilotRows.forEach(item => {
          cmNm = item.cmNm;
          parseData.push({
            PATTERN: item.pattern,
            ROUTEPARTITION: item.routepartitionname,
            HUNTLIST: huntlistName,
          });
        });

        const params = {
          loginId: this.$store.getters.getLoginId,
          type: 'PILOTLIST',
          cmNm: cmNm,
          list: JSON.stringify(parseData),
        };
        const payload = {
          actionName: 'EUC_PILOTLIST_DELETE',
          data: params,
          loading: true,
        };

        let confirm = this.$_lang('COMMON.MESSAGE.CMN_CFM_DELETE_SELECTED');

        if (!(await this.$_Confirm(confirm))) {
          return;
        }

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          await this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_SUCCESS', { defaultValue: '정상적으로 처리되었습니다.' }));
          await this.selectAllList();
        } else {
          await this.$_Msg(res.data.header.resMsg);
        }
      },
      /**@description 파티션 목록 가져오는 메서드 */
      async selectPartitionList(sort = 'regDt,id') {
        const params = {
          pageSize: 10,
          currentpage: 1,
          sort: sort,
          viewFl: 'Y',
        };
        const payload = {
          actionName: 'EUC_PARTITION_LIST_ALL',
          data: params,
          loading: false,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          if (res.data.data.length > 0) {
            this.partitionList = res.data.data;
          }
        }
      },
      async selectPickupGroupList(sort = 'regDt,id') {
        let params = { sort: sort, viewFl: 'Y' };

        const payload = {
          actionName: 'EUC_PICKUPGROUP_LIST_ALL',
          data: params,
          loading: false,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          if (res.data.data.length > 0) {
            this.pickupGroupList = res.data.data;
          }
        }
      },
      /**@description 하위 그리드 초기화 dataSource , selectedRowCnt , totalCnt
       * @param {String} Type : 그리드 명
       */
      resetDataGrid(type) {
        if (type == null || typeof type == 'undefined') {
          return;
        }
        switch (type) {
          case 'huntpilot':
            this.huntlistGrid.dataSource = [];
            this.huntlistGrid.selectedRowCnt = 0;
            this.$refs.huntlistGrid.totalCount = 0;
            this.linegroupGrid.dataSource = [];
            this.linegroupGrid.selectedRowCnt = 0;
            this.$refs.linegroupGrid.totalCount = 0;
            this.linegroupDnGrid.dataSource = [];
            this.linegroupDnGrid.selectedRowCnt = 0;
            this.$refs.linegroupDnGrid.totalCount = 0;
            break;
          case 'huntlist':
            this.linegroupGrid.dataSource = [];
            this.linegroupGrid.selectedRowCnt = 0;
            this.$refs.linegroupGrid.totalCount = 0;
            this.linegroupDnGrid.dataSource = [];
            this.linegroupDnGrid.selectedRowCnt = 0;
            this.$refs.linegroupDnGrid.totalCount = 0;
            break;
          case 'linegroup':
            this.linegroupDnGrid.dataSource = [];
            this.linegroupDnGrid.selectedRowCnt = 0;
            this.$refs.linegroupDnGrid.totalCount = 0;
            break;
          case 'default':
            break;
        }
      },
      /**@description 전체 조회 */
      async selectAllList() {
        this.huntlistGrid.dataSource = [];
        this.linegroupGrid.dataSource = [];
        this.linegroupDnGrid.dataSource = [];
        this.dragData.huntpilot = [];
        this.dragData.linegroup = [];
        this.dragData.linegroupDn = [];
        await this.$refs.huntpilotGrid.clearFilter();
        await this.$refs.huntlistGrid.clearFilter();
        await this.$refs.linegroupGrid.clearFilter();
        await this.$refs.linegroupDnGrid.clearFilter();
        await this.selectHuntpilot();
        // await this.selectHuntlist('+huntlistOrd', null);
        // await this.selectLinegroup('+selectionorder', null);
        // await this.selectLinegroupDn('+lineselectionorder', null);
      },
      /**@description 그리드 전체 repaint */
      async repaintAllGrid() {
        this.$refs.huntpilotGrid.repaintData();
        this.$refs.huntlistGrid.repaintData();
        this.$refs.linegroupGrid.repaintData();
      },
      /** @description: 그리드 content ready, 최초한번만 동작하도록 flag값 비교*/
      // onContentReady(e) {
      //   if (this.contentReady) {
      //     return;
      //   }
      //   const toolbarElement = e.component.element().querySelector('.dx-toolbar-before');
      //   let span = document.createElement('span');
      //   span.classList.add('dx-item', 'dx-toolbar-item', 'dx-toolbar-button');
      //   span.innerText = this.$_lang('UC.WORD.LINEGROUPDN');
      //   span.style.fontWeight = 'bold';
      //   span.style.fontSize = '16px';
      //   toolbarElement.append(span);
      //   this.contentReady = true;
      // },
      /**@description IPT CODE 가져오는 메서드 */
      async selectIptCode(sort = 'codeOrd') {
        const params = { sort: sort, codeKey: 'callmanagergroup', viewFl: 'Y' };
        const payload = {
          actionName: 'EUC_CODE_LIST_ALL',
          data: params,
          loading: false,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          this.callManagerList = res.data.data;
          for (let i = 0; i < this.callManagerList.length; i++) {
            if (this.callManagerList[i].parentId == null) {
              this.callManagerList.splice(i, 1);
            }
          }
        }
      },
      getCustomButtons(data) {
        const buttons = [];
        const name = 'UC.WORD.' + data;
        buttons.push({
          location: 'before',
          html:
            `<div style="margin: bottom 30px">
							<span style="font-weight: bold; font-size: 16px">` +
            // this.$_lang('UC.WORD.LINEGROUPDN') +
            this.$_lang(name) +
            `</span></div>`,
        });
        return buttons;
      },
      /** @description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {
        this.huntpilotGrid.showActionButtons.customButtons = this.getCustomButtons('HUNTPILOT');
        this.huntlistGrid.showActionButtons.customButtons = this.getCustomButtons('HUNTLIST');
        this.linegroupGrid.showActionButtons.customButtons = this.getCustomButtons('LINEGROUP');
        this.linegroupDnGrid.showActionButtons.customButtons = this.getCustomButtons('LINEGROUPDN');
      },
      /** @description : 라이프사이클 mounted시 호출되는 메서드 */
      async mountData() {
        this.searchType.paramsData = null;
        await this.selectPartitionList();
        await this.selectPickupGroupList();
        await this.selectIptCode();
        await this.selectHuntpilot();
        // await this.selectHuntlist('+huntlistOrd', null);
        // await this.selectLinegroup('+selectionorder', null);
        // await this.selectLinegroupDn('+lineselectionorder', null);
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountData();
    },
  };
</script>
<style scoped>
  .sub_new_style01 .page_search_box .inner div {
    display: inline-block;
  }
  .table_form tbody td {
    border: 1px solid #ddd;
    /* padding: 10px 20px; */
  }
  .table_form tbody th {
    border: 1px solid #ddd;
    /* padding: 10px 20px; */
  }
</style>
