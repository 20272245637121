<template>
  <div>
    <table class="table_form line-bin fl" style="width: 100%">
      <thead style="border-top: 1px solid; border-bottom: 1px solid; border-color: rgba(229, 231, 235)">
        <tr>
          <th>{{ $_lang('UC.WORD.DISPLAY_NAME') }}</th>
          <td>{{ this.rowInfo.displayname }}</td>
          <th>{{ $_lang('UC.WORD.USER_NO') }}</th>
          <td>{{ this.rowInfo.userid }}</td>
          <th>{{ $_lang('COMPONENTS.DEPT') }}</th>
          <td>{{ this.rowInfo.department }}</td>
        </tr>
        <tr>
          <th>{{ $_lang('UC.WORD.TYPE') }}</th>
          <td colspan="3">
            <DxCheckBox
              v-model="rowInfo.csfFlag"
              class="checkbox fl"
              text="PC JABBER"
              :height="30"
              style="padding-top: 10px"
              @value-changed="changeRowInfo($event, 'csf')"
            />
            <DxCheckBox
              v-model="rowInfo.botFlag"
              class="checkbox fl"
              text="Android"
              :height="30"
              style="padding-top: 10px; margin-left: 10px"
              @value-changed="changeRowInfo($event, 'bot')"
            />
            <DxCheckBox
              v-model="rowInfo.tctFlag"
              class="checkbox fl"
              text="IPhone"
              :height="30"
              style="padding-top: 10px; margin-left: 10px"
              @value-changed="changeRowInfo($event, 'tct')"
            />
            <DxCheckBox
              v-model="rowInfo.tabFlag"
              class="checkbox fl"
              text="TABLET"
              :height="30"
              style="padding-top: 10px; margin-left: 10px"
              @value-changed="changeRowInfo($event, 'tab')"
            />
          </td>
          <th>{{ $_lang('UC.WORD.NUMBER') }}</th>
          <td>
            <DxTextBox
              v-model="dn"
              :placeholder="$_lang('UC.MESSAGE.DN_ENTER')"
              :styling-mode="config.stylingMode"
              :width="200"
              @value-changed="onChangeInput($event)"
            >
              <DxValidator @validated="onValidated">
                <DxNumericRule message="숫자만 입력 가능합니다." />
              </DxValidator>
            </DxTextBox>

            <DxButton
              @click="onUpdateDn()"
              :text="$_lang('UC.WORD.APPLY')"
              :height="30"
              class="dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled"
              styling-mode="contained"
              type="default"
              style="margin-left: 10px"
            />
            <!-- :width="60" -->
          </td>
        </tr>
      </thead>
    </table>
    <table class="table_form line-bin">
      <tbody>
        <esp-dx-data-grid :data-grid="dataGrid" ref="smartcopyGrid" :auto-width="true" />
        <div style="padding: 0px">
          <DxCheckBox
            v-model="isRemoveCd"
            @value-changed="isRemoveFlag"
            class="checkbox fl"
            :text="$_lang('UC.WORD.CHECK_EXISTING_DEVICE')"
            :height="30"
            style="padding-top: 10px"
          />
        </div>
      </tbody>
    </table>
  </div>
</template>
<script>
  import { DxLookup, DxNumericRule } from 'devextreme-vue/data-grid';
  import { isSuccess } from '@/plugins/common-lib';
  import DxButton from 'devextreme-vue/button';
  import { DxCheckBox } from 'devextreme-vue/check-box';
  import { DxTextBox } from 'devextreme-vue/text-box';
  import { DxValidator } from 'devextreme-vue/validator';
  import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid.vue";
  export default {
    components: {
      EspDxDataGrid,
      DxButton,
      DxLookup,
      DxCheckBox,
      DxTextBox,
      DxValidator,
      DxNumericRule,
    },
    props: {
      option: {
        default: () => {
          return {
            width: '50%',
            height: '85%',
            minWidth: null,
            minHeight: null,
            saveBtnVisible: false,
            cancelBtnVisible: true,
          };
        },
        type: Object,
      },
      rowInfo: {
        type: Object,
      },
      isOpened: {
        type: Boolean,
      },
      devicePoolList: {
        type: Array,
      },
      modelList: {
        type: Array,
      },
    },
    watch: {
      async isOpened() {
        if (this.isOpened) {
          this.dn = this.rowInfo.telephonenumber;
          if (this.dn == null || typeof this.dn == 'undefined') {
            this.rowInfo.csfFlag = false;
            this.rowInfo.botFlag = false;
            this.rowInfo.tctFlag = false;
            this.rowInfo.tabFlag = false;
            await this.$_Msg('내선번호가 없습니다. 내선번호를 확인해주세요.');
          }
          await this.selectIptCode();
          await this.setDataSource();
        } else {
          await this.resetGridDataSource();
        }
      },
    },
    data() {
      return {
        flag: {
          select: false,
          rowUpate: false,
        },
        config: {
          stylingMode: 'outlined', //[outlined, filled, underlined]
          isRemoveCd: true,
        },
        validationStatus: true,
        dn: '',
        isRemoveCd: true,
        smartcopyList: [],
        componentkey: 0,
        searchKeyword: '',
        stylingMode: 'outlined', //outlined, underlined, filled
        dataGrid: {
          // callApi: 'CALL_EUC_API',
          refName: 'smartcopyGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: true, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          disableTotalCount: true,
          selectedRowKeys: [], //선택된 로우키들
          selectedRowsData: [],
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          height: '300', // 주석처리시 100%
          //   height: 'calc(100vh - 600px)', // 주석처리시 100%
          apiActionNm: {
            loading: true,
            update: '',
          },
          customEvent: {
            rowPrepared: true,
            contentReady: true,
            selectionChanged: true,
            saving: true,
          },
          showActionButtons: {
            customButtons: [],
          },
          isDuplicateConfigKey: false, //설정키 중복 체크
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'cell', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '유형',
              i18n: 'UC.WORD.TYPE',
              dataField: 'type',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: 'MAC',
              dataField: 'mac',
              width: 200,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowExporing: true,
              fixed: false,
            },
            {
              caption: '내선번호',
              i18n: 'UC.WORD.NUMBER',
              dataField: 'dn',
              width: 120,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '레이블',
              i18n: 'UC.WORD.LABEL',
              dataField: 'label',
              width: 120,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              allowSorting: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '모델',
              i18n: 'UC.WORD.MODEL',
              dataField: 'model',
              width: 250,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowExporing: true,
              fixed: false,
            },
            {
              caption: '설명',
              i18n: 'COMPONENTS.DESCRIPTION',
              dataField: 'description',
              // width: 200,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              allowSorting: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
          ],
        },
      };
    },
    computed: {},
    methods: {
      /**@description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {
        this.dataGrid.showActionButtons.customButtons = this.getCustomButtons();
      },
      /**@description : 라이프사이클 mounted시 호출되는 메서드 */
      async mountedData() {
        this.selectIptCode();
      },
      getCustomButtons() {
        const buttons = [
          {
            widget: 'dxButton',
            options: {
              icon: '',
              text: this.$_lang('COMPONENTS.SAVE'),
              elementAttr: {
                class:
                  'dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled fr',
              },
              // width: 60,
              height: 30,
              onClick: () => {
                this.smartcopy();
              },
            },
            location: 'after',
          },
        ];
        return buttons;
      },
      /** @description 조건 만족여부 설정 */
      onValidated(e) {
        this.validationStatus = e.isValid;
      },
      /**@description 그리드 데이터 초기화 */
      async resetGridDataSource() {
        this.dataGrid.dataSource = [];
        await this.$refs.smartcopyGrid.clearSelection();
      },
      /**@description 기존단말 삭제여부 체크박스 선택 이벤트 */
      isRemoveFlag(event) {
        this.isRemoveCd = event.value;
      },
      /**@description 유형별 checkbox 선택 이벤트 */
      async changeRowInfo(event, type) {
        this.rowInfo[type + 'Flag'] = event.value;
        if (this.rowInfo.telephonenumber != null) {
          await this.setDataSource();
        }
      },
      /**@description 내선 입력창 change 이벤트 */
      onChangeInput(event) {
        this.dn = event.value;
      },
      /**@description 내선 변경 후 grid data 변경 */
      async onChangeGridData() {
        let gridData = this.dataGrid.dataSource;
        if (gridData?.length > 0) {
          gridData.forEach(data => {
            data.mac = data.type + this.dn;
            data.dn = this.dn;
          });
        }
      },
      /**@description IPT CODE 가져오는 메서드 */
      async selectIptCode(sort = 'codeOrd') {
        const params = { sort: sort, codeKey: 'smartcopybasicmodel', viewFl: 'Y' };
        const payload = {
          actionName: 'EUC_CODE_LIST_ALL',
          data: params,
          loading: false,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          this.smartcopyList = res.data.data;
          for (let i = 0; i < this.smartcopyList.length; i++) {
            if (this.smartcopyList[i].parentId == null) {
              this.smartcopyList.splice(i, 1);
            }
          }
        }
      },
      /** @description : Modal Open 이후 grid DataSource Set 함수 */
      async setDataSource() {
        let data = this.rowInfo;
        let dataSource = [];
        if (data.csfFlag) {
          let item = this.smartcopyList.filter(data => data.codeNm == 'CSF');
          dataSource.push({
            type: 'CSF',
            mac: 'CSF' + data.telephonenumber,
            description: '',
            label: '',
            targetMac: item[0].codeValue,
            dn: data.telephonenumber,
            model: item[0].description,
          });
        }
        if (data.botFlag) {
          let item = this.smartcopyList.filter(data => data.codeNm == 'BOT');
          dataSource.push({
            type: 'BOT',
            mac: 'BOT' + data.telephonenumber,
            description: '',
            label: '',
            targetMac: item[0].codeValue,
            dn: data.telephonenumber,
            model: item[0].description,
          });
        }
        if (data.tctFlag) {
          let item = this.smartcopyList.filter(data => data.codeNm == 'TCT');
          dataSource.push({
            type: 'TCT',
            mac: 'TCT' + data.telephonenumber,
            description: '',
            label: '',
            targetMac: item[0].codeValue,
            dn: data.telephonenumber,
            model: item[0].description,
          });
        }
        if (data.tabFlag) {
          let item = this.smartcopyList.filter(data => data.codeNm == 'TAB');
          dataSource.push({
            type: 'TAB',
            mac: 'TAB' + data.telephonenumber,
            description: '',
            label: '',
            targetMac: item[0].codeValue,
            dn: data.telephonenumber,
            model: item[0].description,
          });
        }
        this.dataGrid.dataSource = dataSource;
      },
      /** @description 커스텀 조건식 */
      customValidation(e) {
        const value = e.value;
        // 조건: 값이 숫자
        return !isNaN(value);
      },
      /**@description smartcopy 메서드 */
      async smartcopy() {
        let gridData = this.dataGrid.dataSource;

        if (this.$refs.smartcopyGrid.hasEditData()) {
          await this.$_Msg('수정중인 항목이있습니다. ');
          return;
        }

        if (gridData.length == 0) {
          await this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NO_DATA'));
          return;
        }
        if (gridData[0].dn == null || gridData[0].dn == '') {
          await this.$_Msg('내선번호가 없습니다. 내선번호 확인 후 다시 시도해주세요.');
          return;
        }
        let assignedList = await this.getAssignedList();
        let assignedModels = await this.getAssignedModels();
        let list = [];
        gridData.forEach(data => {
          list.push({
            TYPE: data.type,
            TARGET_MAC: data.targetMac,
            MAC: data.mac,
            DESCRIPTION: data.description,
            DN: [{ INDEX: '1', LABEL: data.label != '' ? data.label : data.dn, NUMBER: data.dn }],
          });
        });
        const params = {
          cmNm: this.rowInfo.cmNm,
          loginId: this.$store.getters.getLoginId,
          loginUserId: this.rowInfo.userid,
          remove: this.isRemoveCd == true ? 'Y' : 'N',
          assignedModels: assignedModels,
          assignedList: JSON.stringify(assignedList),
          list: JSON.stringify(list),
        };
        const payload = {
          actionName: 'EUC_DEVICE_SMARTCOPY',
          data: params,
          loading: true,
        };
        let confirm = this.$_lang('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' });

        if (!(await this.$_Confirm(confirm))) {
          return;
        }
        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          await this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_SUCCESS', { defaultValue: '정상적으로 처리되었습니다.' }));
          this.$emit('closeModal');
        } else {
          await this.$_Msg(res.data.header.resMsg);
        }
      },
      /** @description ENDUSER 내선번호 변경 */
      async onUpdateDn() {
        if (!this.validationStatus) {
          await this.$_Msg('내선번호는 숫자만 입력 가능합니다.');
          return;
        }

        if (this.dn == '' || this.dn == null) {
          await this.$_Msg('내선번호를 입력하세요.');
          return;
        }

        let copyRow = this.rowInfo;
        copyRow.telephonenumber = this.dn;
        let parseData = [];
        parseData.push(copyRow);
        let params = parseData;
        const payload = {
          actionName: 'EUC_ENDUSER_UPDATE',
          data: params,
          loading: true,
        };
        let confirm = this.$_lang('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' });

        if (!(await this.$_Confirm(confirm))) {
          return;
        }
        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          await this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_SUCCESS', { defaultValue: '정상적으로 처리되었습니다.' }));
          await this.onChangeGridData();
        } else {
          await this.$_Msg(res.data.header.resMsg);
        }
      },
      async getAssignedModels() {
        let data = this.modelList.map(item => item.name).join(',');
        return data;
      },
      async getAssignedList() {
        let data = this.devicePoolList.map(item => {
          return { USER: item.appuser, DEVICE_POOL: item.name };
        });
        return data;
      },
    },
    async created() {
      this.createdData();
    },
    async mounted() {
      await this.mountedData();
    },
  };
</script>
