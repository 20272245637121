var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('esp-dx-modal-popup',{attrs:{"option":{
      title: '권한 추가',
      width: '50vw',
      height: 'calc(100vh - 300px)',
      minWidth: '50vw',
      minHeight: 'calc(100vh - 300px)',
    },"isOpen":_vm.popupVisible},on:{"closeModal":function($event){return _vm.onClose(false)},"saveModal":_vm.onSave},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('esp-dx-data-grid',{ref:_vm.dataGrid.refName,attrs:{"data-grid":_vm.dataGrid}})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }