<!--
  PACKAGE_NAME : src\pages\ai\llm-tester\work-config
  FILE_NAME : system-prompt
  AUTHOR : hpmoon
  DATE : 2024-09-12
  DESCRIPTION : AI > LLM > LLM Tester > 프로젝트 작업 설정 > 시스템 프롬프트
-->
<template>
  <div style="width:100%; padding-top: 4px">
    <DxButton :text="'+ '+$_lang('LLM_TESTER.WORD.PROMPT_ADD', {defaultValue: '새 프롬프트 추가'})" class="btn_XS default filled" type="button" :height="30" style="margin-bottom: 10px" @click="onOpenModal('add')" />
    <div class="page_search_box line_top_1px line_bottom_1px">
      <div class="inner alL">
        <span style="margin-right: 10px;">{{ $_lang('LLM_TESTER.WORD.SYSTEM_PROMPT', { defaultValue: '시스템 프롬프트' }) }}</span>
        <DxSelectBox
          styling-mode="outlined"
          ref="promptSelectBox"
          display-expr="name"
          value-expr="id"
          :items="promptList"
          :value="selectedPrompt?.id"
          :placeholder="$_lang('LLM_TESTER.WORD.SELECT_PROMPT', {defaultValue: '프롬프트 선택'})"
          width="250px"
          class="mr-4"
          @item-click="onItemClick"
        />
        <DxButton :text="$_lang('COMPONENTS.DELETE', {defaultValue: '삭제'})" :disabled="!isSelectedPrompt" class="btn_XS white light_filled trash" type="button" :height="30" @click="onDeletePrompt" />
        <DxButton :text="$_lang('LLM_TESTER.WORD.COPY_ADD', {defaultValue: '새로 저장'})" :disabled="!isSelectedPrompt" class="btn_XS default filled" type="button" :height="30" @click="onOpenModal('copyAdd')" />
      </div>
    </div>
    <div class="pad_top10">
      <table class="table_form line-bin" v-if="isSelectedPrompt">
        <colgroup>
          <col style="width: 130px" />
          <col style="width: auto" />
        </colgroup>

        <tbody>
        <tr>
          <th scope="row">
            <label for="label5">{{ $_lang('LLM_TESTER.WORD.CONTENT', { defaultValue: '내용' }) }} <span class="icon_require">필수항목</span></label>
          </th>
          <td>
            <DxTextArea
              id="promptTextArea"
              v-model="selectedPrompt.content"
              :max-length="limitNumberTexts.maxLengths.content"
              :styling-mode="config.stylingMode"
              class="mar_ri10 alB"
              width="80%"
              :height="config.textAreaHeight"
              @key-up="onKeyUpContent($event)"

            >
              <DxValidator>
                <DxRequiredRule :message="$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS', { value: $_lang('LLM_TESTER.WORD.CONTENT', {defaultValue: '내용'}), defaultValue: '[내용] 은/는 필수값 입니다' })" />
              </DxValidator>
            </DxTextArea>
            <span>
              {{
                limitNumberTexts.textLengths.content
                  ? limitNumberTexts.textLengths.content
                  : selectedPrompt.content
                    ? selectedPrompt.content.length
                    : 0
              }}
            </span>/{{ limitNumberTexts.maxLengths.content }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <!-- 새 프롬프트 추가 -->
    <SystemPromptAdd
      v-if="modal.add.visible"
      :is-open="modal.add.visible"
      :project_id="project_id"
      @saveModal="onSaveModal('add')"
      @closeModal="onCloseModal('add')"
    />

    <!-- 프롬프트 새로 저장 -->
    <SystemPromptCopyAdd
      v-if="modal.copyAdd.visible"
      :is-open="modal.copyAdd.visible"
      :project_id="project_id"
      :content="selectedPrompt.content"
      @saveModal="onSaveModal('copyAdd')"
      @closeModal="onCloseModal('copyAdd')"
    />
  </div>
</template>

<script>
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { DxButton } from 'devextreme-vue/button';
  import { DxTextArea } from 'devextreme-vue/text-area';
  import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
  import SystemPromptAdd from "@/pages/ai/llm-tester/work-config/system-prompt-add.vue";
  import SystemPromptCopyAdd from "@/pages/ai/llm-tester/work-config/system-prompt-copy-add.vue";

  export default {
    components: {
      DxSelectBox,
      DxButton,
      DxTextArea,
      DxValidator,
      DxRequiredRule,
      SystemPromptAdd,
      SystemPromptCopyAdd
    },

    watch: {},

    props: {
      project_id: String,
    },

    data() {
      return {
        isSelectedPrompt: true,  // 프롬프트 선택 여부
        promptList: [],          // 프롬프트 리스트
        selectedPrompt: {        // 선택한 프롬프트
          id: null,
          content: null,
        },
        originContent: null,     // 프롬프트 내용 원본
        isChangeContent: false,  // 프롬프트 내용 변경 여부
        limitNumberTexts: {
          textLengths: {},
          maxLengths: {
            content: 2000,
          },
        },

        config: {
          stylingMode: 'outlined', //[outlined, filled, underlined]
          textAreaHeight: 0,
        },

        modal: {
          add: {
            visible: false,
          },
          copyAdd: {
            visible: false,
          }
        },
      }
    },

    computed: {},

    methods: {
      /** @description 데이터 조회 메서드 */
      async selectDataList() {
        this.isChangeContent = false;
        const payload = {
          actionName: 'LLM_TESTER_SYSTEM_PROMPT_LIST',
          data: {
            project_id: this.project_id
          },
          loading: true,
        }
        const res = await this.CALL_LLM_TESTER_API(payload);
        if (res.status === 200) {
          this.promptList = res.data;
        } else {
          this.$_Msg(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          return false;
        }

        if (this.promptList.length === 0) {
          this.isSelectedPrompt = false;
        } else {
          this.selectedPrompt = JSON.parse(JSON.stringify(this.promptList[0]));
          this.isSelectedPrompt = true;
          this.originContent = this.selectedPrompt.content;
        }
      },

      /** @description 프롬프트 선택 메서드 */
      async onItemClick(e) {
        if (this.isChangeContent) {
          if (!await this.$_Confirm(this.$_lang('LLM_TESTER.MESSAGE.UNSAVED_PROMPT_CHANGE_ALERT', { defaultValue: '작업한 프롬프트 내용이 저장되지 않았습니다. <br>변경 하시겠습니까?' }))) {
            this.$refs.promptSelectBox.instance.option('value', this.selectedPrompt.id);
            return;
          }
        }
        this.selectedPrompt = JSON.parse(JSON.stringify(e.itemData));
        this.originContent = this.selectedPrompt.content;
        this.isChangeContent = false;
      },

      /** @description TextArea 입력값 체크 메서드 */
      onKeyUpContent(e) {
        this.$_checkLimitTextLength(e, this.selectedPrompt, this.limitNumberTexts, 'content');
        this.isChangeContent = e.event.currentTarget.value !== this.originContent;
      },

      /**
       * @description 팝업 열기
       * @param modalType 팝업 모달 타입(add, copyAdd)
       * */
      onOpenModal(modalType) {
        this.modal[modalType].visible = true;
      },

      /**
       * @description 팝업 저장
       * @param modalType 팝업 모달 타입(add, copyAdd)
       * */
      onSaveModal(modalType) {
        this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
        this.selectDataList();
        this.modal[modalType].visible = false;
      },

      /**
       * @description 팝업 닫기
       * @param modalType 팝업 모달 타입(add, copyAdd)
       * */
      onCloseModal(modalType) {
        this.modal[modalType].visible = false;
      },

      /** @description 프롬프트 삭제 */
      async onDeletePrompt() {
        if (await this.$_Confirm(this.$_lang('CMN_CFM_DELETE_SELECTED', { defaultValue: '선택한 데이터를 삭제하시겠습니까?' }))) {
          const payload = {
            actionName: 'LLM_TESTER_SYSTEM_PROMPT_DELETE',
            data: {
              id: this.selectedPrompt.id,
            },
            loading: true,
          }
          const res = await this.CALL_LLM_TESTER_API(payload);
          if (res.status === 200) {
            this.$_Toast(this.$_lang('CMN_SUC_DELETE', { defaultValue: '정상적으로 삭제되었습니다' }), { icon: 'success' });
            await this.selectDataList();
          } else {
            this.$_Msg(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
            return false;
          }
        }
      },

      /** @description TextArea 높이 계산 */
      setTextAreaHeight(initHeight = 0) {
        let height = this.topElement("#promptTextArea") + (100 + initHeight);
        this.config.textAreaHeight = "calc(100vh - " + height + "px)";
      },
      topElement(e) {
        const divElement = document.querySelector(e);
        const rect = divElement.getBoundingClientRect();
        return rect.top;
      },
    },

    /** @description 라이프사이클 created 시 호출되는 메서드 */
    created() {
      this.selectDataList();
    },

    /** @description 라이프사이클 mounted 시 호출되는 메서드 */
    mounted() {
      this.$nextTick(() => {
        this.setTextAreaHeight(-22);
      });
    },
  }
</script>

<style lang="scss" scoped>
</style>