<template>
	<transition>
		<div ref="contentsWrap" class="container contents-wrap" id="contentsWrap">
			<div class="page-sub-box">
				<div class="sub_new_style01 sub_ui_box2 locker_setting_list">
					<!--"sub_new_style01" class적용시 상단라인 삭제-->
					<!--순서조정-->
					<div class="head-btn-box01 margin0">
						<div class="head-btn-left">
							<div class="top-pageing-box">
								<DxCheckBox
									class="checkbox"
									v-model="config.isAllDept"
									text="부서 전체 검색"
									@value-changed="onChangedAllDept"
								>
								</DxCheckBox>
							</div>
						</div>
						<div class="head-btn-right"></div>
					</div>
					<!--//순서조정-->

					<div class="cusmain-table-wrap">
						<DxScrollView
							id="scrollview"
							ref="scrollViewWidget"
							:scroll-by-content="config.scroll.scrollByContent"
							:scroll-by-thumb="config.scroll.scrollByThumb"
							:show-scrollbar="config.scroll.showScrollbar"
							:bounce-enabled="config.scroll.pullDown"
							:width="config.scroll.width"
							:height="config.scroll.height"
						>
							<table class="table_list" style="width: 100%; height: 100%;">
								<caption>
									<strong>부서 검색 조건</strong>
								</caption>
								<colgroup>
									<col style="width:20px" />
									<col style="width:auto" />
									<col style="width:30px" />
								</colgroup>
								<thead>
									<tr>
										<th scope="col">No</th>
										<th scope="col">조회 부서</th>
										<th scope="col"></th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(item, index) in grid.data" :key="index">
										<td>
											{{ index + 1 }}
										</td>
										<td>
											<DxSelectBox
												v-model="item.deptDepth1"
												:placeholder="deptTitleByDepth('depth1') + ' 선택'"
												:items="formData.defaultDatas.deptDepth1"
												display-expr="deptNm"
												value-expr="id"
												:show-clear-button="config.showClearButton"
												@value-changed="selectionChangedDepth($event, item, 1)"
												:styling-mode="config.stylingMode"
												:width="config.width"
											>
											</DxSelectBox>

											<DxSelectBox
												v-model="item.deptDepth2"
												:placeholder="deptTitleByDepth('depth2') + ' 선택'"
												:items="item.selectedByPrevDatas.deptDepth2"
												display-expr="deptNm"
												value-expr="id"
												:show-clear-button="config.showClearButton"
												@value-changed="selectionChangedDepth($event, item, 2)"
												:disabled="disabledDepth2(item)"
												:styling-mode="config.stylingMode"
												:width="config.width"
											>
											</DxSelectBox>

											<DxSelectBox
												v-model="item.deptDepth3"
												:placeholder="deptTitleByDepth('depth3') + ' 선택'"
												:items="item.selectedByPrevDatas.deptDepth3"
												display-expr="deptNm"
												value-expr="id"
												:show-clear-button="config.showClearButton"
												@value-changed="selectionChangedDepth($event, item, 3)"
												:disabled="disabledDepth2(item) || disabledDepth3(item)"
												:styling-mode="config.stylingMode"
												:width="config.width"
											>
											</DxSelectBox>

											<DxSelectBox
												v-model="item.deptDepth4"
												:placeholder="deptTitleByDepth('depth4') + ' 선택'"
												:items="item.selectedByPrevDatas.deptDepth4"
												display-expr="deptNm"
												value-expr="id"
												:show-clear-button="config.showClearButton"
												@value-changed="selectionChangedDepth($event, item, 4)"
												:disabled="disabledDepth2(item) || disabledDepth3(item) || disabledDepth4(item)"
												:styling-mode="config.stylingMode"
												:width="config.width"
											>
											</DxSelectBox>
										</td>
										<td>
											<DxButton
												v-if="index === 0"
												text="추가"
												class="btn_XS white light_filled add2"
												:width="60"
												:height="30"
												@click="onAddRowData"
											></DxButton>
											<DxButton
												v-else
												text="삭제"
												class="btn_XS white light_filled del1"
												:width="60"
												:height="30"
												@click="onRemoveRow(item)"
											></DxButton>
										</td>
									</tr>
								</tbody>
							</table>
						</DxScrollView>
					</div>
				</div>
				<DxCheckBox class="checkbox" v-model="config.isSaved" text="설정 계속 저장" @value-changed="onChangedUsedFlag">
				</DxCheckBox>
			</div>
		</div>
	</transition>
</template>

<script>
import { DxScrollView } from 'devextreme-vue/scroll-view';
import { DxCheckBox } from 'devextreme-vue/check-box';
import { DxButton } from 'devextreme-vue/button';
import { DxSelectBox } from 'devextreme-vue/select-box';

import { isSuccess, cloneObj } from "@/plugins/common-lib";

export default {
	components: {
		DxScrollView,
		DxCheckBox,
		DxButton,
		DxSelectBox,
	},
	props: {
		contentData: Object,
		iconData: Array,
	},
	watch: {},
	data() {
		return {
			config: {
				pageSetting: {
					config: {},
					formData: {},
				},
				stylingMode: 'outlined',
				width: 120,
				isSaved: false,
				isAllDept: false,
				scroll: {
					scrollByContent: false,
					scrollByThumb: false,
					showScrollbar: 'onHover', //[onScroll, onHover, always, never]
					pullDown: false,
					width: 680,
					height: 250,
				},
				showClearButton: true,
			},
			grid: {
				data: [],
				rowDefaultData: {
					//이전 뎁스에서 선택된 id값과 매칭되는 데이터
					selectedByPrevDatas: {
						//deptDepth1: null,             //선택된 depth1에 해당하는 데이터
						deptDepth2: null, //depth1에서 선택된 id 값과 매칭되는 depth2에 해당하는 데이터
						deptDepth3: null, //depth2에서 선택된 id 값과 매칭되는 depth3에 해당하는 데이터
						deptDepth4: null, //depth3에서 선택된 id 값과 매칭되는 depth4에 해당하는 데이터
						//deptDepth5: [],             //선택된 depth5에 해당하는 데이터
					},
					//선택한 데이터
					selectedDatas: {
						deptDepth1: null, //선택된 depth1에 해당하는 데이터
						deptDepth2: null, //선택된 depth2에 해당하는 데이터
						deptDepth3: null, //선택된 depth3에 해당하는 데이터
						deptDepth4: null, //선택된 depth4에 해당하는 데이터
					},
					//선택한 데이터의 id 값들
					selectedIds: {
						deptDepth1: null, //선택된 depth1에 해당하는 데이터 id 값들
						deptDepth2: null, //선택된 depth2에 해당하는 데이터 id 값들
						deptDepth3: null, //선택된 depth3에 해당하는 데이터 id 값들
						deptDepth4: null, //선택된 depth4에 해당하는 데이터 id 값들
					},
				},
			},
			formData: {
				deptDatas: [],
				//부서 초기 default data
				defaultDatas: {
					deptDepth1: [],
					deptDepth2: [],
					deptDepth3: [],
					deptDepth4: [],
				},
			},
			tree: {
				originDeptList: [],
				deptList: [],
				selectedRowkeys: [],
				focusedRowKey: null,
				selectedRowsData: [],
				recursive: true,
				mode: 'multiple',
				selectionMode: 'all', // 'all' or 'excludeRecursive' or 'leavesOnly'
				allowDropInsideItem: false,
				allowReordering: true,
				showDragIcons: true,
				selectedData: null,
			},
		};
	},
	computed: {
		/** @description: props 리스트에서 선택한 데이터 */
		selectedRowsData() {
			return this.contentData.selectedRowsData;
		},
		/** @description: 부서 타이틀 가져오기 */
		deptTitles() {
			return this.$_getCode('euc_dept_title').filter(d => d.delFl === 'N');
		},
	},
	methods: {
		/** @description: 뎁스별 부서 타이틀 */
		deptTitleByDepth(depth) {
			return this.deptTitles.find(d => d.codeValue === depth).codeNm;
		},
		/** @description: 부서전체검색 체크 이벤트 */
		onChangedAllDept(e) {
			//console.log('e >>>', e);
		},
		/** depth2 disabled */
		disabledDepth2(item) {
			if (item.selectedByPrevDatas.deptDepth2 === null) {
				return true;
			} else {
				return false;
			}
		},
		/** depth3 disabled */
		disabledDepth3(item) {
			if (item.selectedByPrevDatas.deptDepth3 === null) {
				return true;
			} else {
				return false;
			}
		},
		/** depth4 disabled */
		disabledDepth4(item) {
			if (item.selectedByPrevDatas.deptDepth4 === null) {
				return true;
			} else {
				return false;
			}
		},
		/** @description: 로우 데이터 추가시 */
		onAddRowData() {
			let addRowData = cloneObj(this.grid.rowDefaultData);
			this.grid.data.push(addRowData);
		},
		/** @description: 로우 데이터 삭제시 */
		async onRemoveRow(rowData) {
			if (await this.$_Confirm('해당 데이터를 삭제하시겠습니까?')) {
				this.grid.data = this.grid.data.filter(d => d !== rowData);
			} else {
				return false;
			}
		},
		/** @description: depth별 부서 로우 선택시 이벤트 */
		selectionChangedDepth(e, item, depth) {
			if (e.event) {
				depth = Number(depth);
				item.currentDepth = depth;
				item.selectedIds[`deptDepth${depth}`] = e.value;
				item.selectedDatas[`deptDepth${depth}`] = this.formData.defaultDatas[`deptDepth${depth}`].filter(d => d.id === e.value);

				//마지막 depth를 제외하고 하위 부서 데이터 설정
				if (depth < this.deptTitles.length) {
					let depthDatas = this.formData.defaultDatas[`deptDepth${depth + 1}`].filter(
						d => item.selectedIds[`deptDepth${depth}`] === d.parentId,
					);
					item.selectedByPrevDatas[`deptDepth${depth + 1}`] = depthDatas.length > 0 ? depthDatas : null;
				}

				//해당 값이 null 이면 하위 데이터 값도 null로 처리
				if (e.value === null) {
					//grid.data.deptDepth* 관련 초기화
					Object.keys(item).forEach((key, index) => {
						if (key.includes('deptDepth')) {
							if (key.split('deptDepth')[1] > depth) {
								item[key] = null;
							}
						}
					});

					//grid.data.selectedDatas 관련 데이터 초기화
					Object.keys(item.selectedDatas).forEach(key => {
						if (key.split('deptDepth')[1] > depth) {
							item.selectedDatas[key] = [];
						}
					});

					//grid.data.selectedIds 관련 데이터 초기화
					Object.keys(item.selectedIds).forEach(key => {
						if (key.split('deptDepth')[1] > depth) {
							item.selectedIds[key] = null;
						}
					});
				}
			}
		},
		/** @description: 사용중인 부서만 보기 체크 */
		onChangedUsedFlag(e) {
			//console.log(e)
			if (e.value) {
				this.tree.deptList = this.tree.deptList.filter(d => d.viewFl === 'Y');
			} else {
				this.tree.deptList = this.tree.originDeptList;
			}
		},
		/** @description: 부서 리스트 조회 */
		async selectDeptList() {
			let payload;
			let res;

			let paramData = { viewFl: 'Y', pagesize: 100000 };
			payload = {
				actionName: 'DEPT_LIST_ALL_VIEW',
				data: { params: paramData },
				loading: true,
			};

			res = await this.CALL_API(payload);
            if( isSuccess(res) ) {
                let data = res.data.data.map(d => {
                    d.id = d.deptId;
                    return { ...d };
                });
                this.formData.deptDatas = cloneObj(data);
                this.formData.defaultDatas.deptDepth1 = this.formData.deptDatas.filter(d => d.depth === 1);
                this.formData.defaultDatas.deptDepth2 = this.formData.deptDatas.filter(d => d.depth === 2);
                this.formData.defaultDatas.deptDepth3 = this.formData.deptDatas.filter(d => d.depth === 3);
                this.formData.defaultDatas.deptDepth4 = this.formData.deptDatas.filter(d => d.depth === 4);
            }
		},
		/** @description: 트리메뉴 변경 이벤트 */
		selectionChangedData(e) {
			const selectedData = e.component.getSelectedRowsData(this.tree.selectionMode);
			this.tree.selectedRowsData = selectedData;
		},
		/** @description : 트리 로우 데이터 클릭 이벤트 */
		onClickRow(row) {
			let rowData = row.data;

			if (rowData) {
				this.formData.data = rowData;
				this.tree.focusedRowKey = rowData.id;
			}
		},
		/** @description: 부서 데이터 없을 경우 출력 메서드 */
		noDataText(length) {
			if (length === 0) {
				return '추가된 부서가 없습니다.';
			}
		},
		/** @description : 라이프사이클 creaed시 호출되는 메서드 */
		async createdData() {
      await this.selectDeptList(); //부서 리스트 조회
      console.log('this.contentData.deptIdDepthDatas : ', this.contentData.deptIdDepthDatas)
      if (Object.keys(this.contentData.deptIdDepthDatas).length === 0) {
        //필터 계속 저장 아닐시 초기 데이터 셋팅
        let gridDefaultData = cloneObj(this.grid.rowDefaultData);
        //this.grid.data.push(gridDefaultData);
        this.grid.data = [gridDefaultData];
      } else {
        //설정 계속 저장시 초기화
        this.grid.data = [];
      }

      //설정계속저장
      this.config.isSaved = this.contentData.isSaved;

			//부서 데이터 설정
			let deptData = {};
			Object.entries(this.contentData.deptIdDepthDatas).forEach(([key, values]) => {
        const depth = Number(key.slice(-1));
				if (values.includes(',')) {
					deptData[`deptDepth${depth}`] = values.split(',');
				} else {
					deptData[`deptDepth${depth}`] = [values];
				}
			});

			let index = 0;
			Object.entries(deptData).forEach(([key, values]) => {
        const depth = Number(key.slice(-1));

				values.forEach(value => {
					this.onAddRowData(); //로우 데이터 추가
					this.grid.data[index].currentDepth = depth;

          let selectedDatas = [];
          for(const key in this.formData.defaultDatas) {
            const selectedData = this.formData.defaultDatas[key].filter(d => d.id === Number(value));
            selectedDatas = selectedDatas.concat(selectedData);
          }

					if (selectedDatas[0].codeTree.includes('|')) {
						//부서 뎁스가 1 이상인 경우
						let deptList = selectedDatas[0].codeTree.split('|');
						deptList.forEach((deptId, deptIdx) => {
							const depth = deptIdx + 1;

							//뎁스별 부서 데이터 바인딩 처리
							this.setBindingDepthDept(depth, deptId, index);
						});
					} else {
						//부서 뎁스가 1인 경우
						//뎁스별 부서 데이터 바인딩 처리
						this.setBindingDepthDept(depth, value, index);
					}

					index++;
				});
			});
		},
		/** @description : 뎁스별 부서 데이터 바인딩 처리 */
		setBindingDepthDept(depth, value, index) {
			let deptId = Number(value);
			let selectedDatas = this.formData.defaultDatas[`deptDepth${depth}`].filter(d => d.id === Number(value));

			this.grid.data[index][`deptDepth${depth}`] = deptId;
			this.grid.data[index].selectedIds[`deptDepth${depth}`] = deptId;
			this.grid.data[index].selectedDatas[`deptDepth${depth}`] = selectedDatas;
			let depthDatas = this.formData.defaultDatas[`deptDepth${depth + 1}`].filter(d => d.parentId === deptId);
			this.grid.data[index].selectedByPrevDatas[`deptDepth${depth + 1}`] = depthDatas.length > 0 ? depthDatas : null;
		},
    async saveDeptData(resolve) {
      //부서 빈 값 filter 처리
      const deptList = this.grid.data.filter(d => d.selectedIds.deptDepth1);

      //조회부서 체크
      let isSearchDept = deptList.length > 0 || this.config.isAllDept;

      if (!isSearchDept) return this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));

      let deptDatas = {};
      if (deptList?.length) {
        if (Object.prototype.hasOwnProperty.call(deptList[0], 'currentDepth')) {
          const selectedDatas = deptList.map(d => {
            return { deptId: d.selectedIds[`deptDepth${d.currentDepth}`] };
          });

          //뎁스별 배열로 셋팅
          const selectedDeptDatas = selectedDatas.reduce((acc, obj, i) => {
            const key = Object.keys(obj)[0]; // 첫 번째 key를 가져옴
            const val = obj[key]; // 해당 key의 value를 가져옴

            if (!acc[key]) {
              acc[key] = []; // 새로운 key가 등장하면 빈 배열을 만듦
            }

            acc[key].push(val); // key가 같은 객체끼리 배열에 추가

            return acc;
          }, {});

          //뎁스별 배열을 문자열로 변환
          Object.entries(selectedDeptDatas).forEach(([key, val]) => {
            deptDatas[key] = val.join(',');
          });
        }
      }
      const rst = { selectedDeptDatas: deptDatas, isAllDept: this.config.isAllDept, isSaved: this.config.isSaved };

      resolve({
        status: 200,
        componentNm: 'ModalFilterDept',
        rst: rst,
      });
    },
		/** @description : 라이프사이클 mounted시 호출되는 메서드 */
		mountedData() {
      this.$_eventbus.$on('ModalFilterDept:onSaveData', async (_, resolve) => {
        await this.saveDeptData(resolve);
      });
		},
		/** @description : 라이프사이클 destroyed시 호출되는 메서드 */
		destroyedData() {
			this.$_eventbus.$off('ModalFilterDept:onSaveData');
		},
	},
	created() {
		this.createdData();
	},
	mounted() {
		this.mountedData();
	},
	destroyed() {
		this.destroyedData();
	},
};
</script>

<style scoped>
.page-sub-box .treemenu-set {
	min-height: 0;
}
.page-sub-box .checkbox {
	margin-top: 3px;
	float: right;
}

.sub_ui_box2 .table_list tbody tr td {
	padding: 6px 10px;
}

.page-sub-box table tbody td > div:not(:last-child) {
	margin-right: 7px;
}
</style>
<style></style>
