import mixin from './mixin';
import * as commonLib from './common-lib';
import { EventBus } from '@/event-bus';
import enums from '@/configs/enums';
import theme from '@/configs/theme';
import EspDxFileUploader from '@/components/devextreme/esp-dx-file-uploader.vue';
import loaderManager from './esp-loader-manager';

export default {
    install(Vue) {
    if (this.installed) return;
      this.installed = true;

    // 기본 플러그인 설정
    Vue.prototype.$_commonlib = commonLib;
    Vue.prototype.$_eventbus = EventBus;
    Vue.prototype.$_enums = enums;
    Vue.prototype.$_theme = theme;
    Vue.mixin(mixin);
    Vue.prototype.$_UploaderClass = Vue.extend(EspDxFileUploader);

    // 로더 기능 추가 - 함수형으로 직접 바인딩
    Vue.prototype.$_Loader = loaderManager;
    }
}