<template>
	<div>
		<div class="page-sub-box ewm">
			<div class="locker_setting_list sub_new_style01 sub_ui_box1">
				<div class="page_search_box line_bottom_1px pb-0 mb-0">
					<div class="flex space-x-2 inner">
						<div class="mt-2">
							기준년월
						</div>
						<DxDateBox
							:edit-enabled="false"
							:styling-mode="stylingMode"
							width="100"
							v-model="searchType.customTypes.dayStart"
							type="date"
							display-format="yyyy.MM"
							dateSerializationFormat="yyyyMMdd"
							date-out-of-range-message="시작일은 종료일보다 작거나 같아야 합니다."
							:max="searchType.customTypes.dayEnd"
							maxLength="7"
							invalid-date-message="입력 데이터가 유효하지 않습니다."
							:calendar-options="{
								zoomLevel: 'year',
								minZoomLevel: 'year',
								maxZoomLevel: 'year',
							}"
							@value-changed="onDayStartChanged"
						>
							<DxValidator validation-group="validationSearch">
								<DxRequiredRule message="기준년월은 필수입니다." />
							</DxValidator>
						</DxDateBox>
						<div class="mt-1">
							~
						</div>
						<DxDateBox
							:styling-mode="stylingMode"
							width="100"
							v-model="searchType.customTypes.dayEnd"
							type="date"
							display-format="yyyy.MM"
							dateSerializationFormat="yyyyMMdd"
							dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
							maxLength="7"
							:min="searchType.customTypes.dayStart"
							invalid-date-message="입력 데이터가 유효하지 않습니다."
							:calendar-options="{
								zoomLevel: 'year',
								minZoomLevel: 'year',
								maxZoomLevel: 'year',
							}"
							@value-changed="onDayEndChanged"
						>
							<DxValidator validation-group="validationSearch">
								<DxRequiredRule message="기준년월은 필수입니다." />
							</DxValidator>
						</DxDateBox>
						<div class="mt-2">
							성명
						</div>
						<DxButton
							:styling-mode="stylingMode"
							:text="searchType.customTypes.targetName"
							:width="120"
							:height="30"
							class="btn_XS white light_filled"
							@click="onOpen"
						>
						</DxButton>
						<DxTextBox
							:styling-mode="textStylingMode"
							v-model="searchType.customTypes.targetId"
							:width="120"
							:height="30"
							:read-only="true"
							class="mar_ri10"
						>
						</DxTextBox>
						<DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="selectDataList" />
						<DxButton 
							class="btn_XS green ligt_filled" 
							:height="30" 
							icon="export"
							@click="onExportingCheck">
						</DxButton>
					</div>
				</div>
			</div>

			<div class="h-2/5">
				<div class="sub_title_txt my-4 flex">
					<h2>시험정보</h2>
					<div class="flex justify-end flex-grow">
						<div class="total-count-item">
							검색결과 <span class="tet-calr1">{{ reportGridData.totalCount }}</span> 개
						</div>
					</div>
				</div>

				<DxDataGrid
					class="grid-box"
					ref="reportGridData"
					:data-source="reportGridData.dataSource"
					:allow-column-resizing="true"
					:column-resizing-mode="'nextColumn'"
					:show-borders="false"
					:show-column-headers="true"
					:show-column-lines="true"
					:show-row-lines="true"
					:row-alternation-enabled="false"
					:hover-state-enabled="true"
					:word-wrap-enabled="true"
					:no-data-text="this.$_lang('CMN_NO_DATA')"
					@row-click="onClickRow"
					width="100%"
					height="300"
				>
					<DxFilterRow :visible="true" />
					<DxScrolling mode="virtual" />
					<DxSelection mode="single" />
					<DxHeaderFilter :visible="true" />

					<DxColumn
						caption="시험구분"
						alignment="center"
						data-field="examSheetType"
						width="120"
						:allow-sorting="false"
						:allowHeaderFiltering="true"
						:allowFiltering="true"
					>
						<DxLookup :data-source="codes.examSheetType.dataSource" value-expr="value" display-expr="label" />
					</DxColumn>
					<DxColumn
						caption="시험계획"
						alignment="center"
						data-field="scheNm"
						:allow-sorting="false"
						:allowHeaderFiltering="false"
						:allowFiltering="true"
						:calculate-display-value="rowData => `[${rowData.scheYmd}] ${rowData.scheNm}`"
					/>
					<DxColumn
						caption="시험지"
						data-field="examNm"
						alignment="center"
						:allow-sorting="false"
						:allowHeaderFiltering="false"
						:allowFiltering="false"
					/>
					<DxColumn
						caption="점수"
						data-field="myScore"
						alignment="center"
						width="100"
						:allow-sorting="false"
						:allowHeaderFiltering="false"
						:allowFiltering="false"
						:calculate-display-value="rowData => `${rowData.myScore}/${rowData.totalScore}`"
					>
					</DxColumn>
					<DxColumn
						caption="순위"
						data-field="myRanking"
						alignment="center"
						width="100"
						:allow-sorting="false"
						:allowHeaderFiltering="false"
						:allowFiltering="false"
						:calculate-display-value="rowData => `${rowData.myRanking}/${rowData.totalRanking}`"
					>
					</DxColumn>
					<DxColumn caption="정답분포">
						<DxColumn
							caption="객관식"
							data-field="myChoiceScore"
							alignment="center"
							width="100"
							:allow-sorting="false"
							:allowHeaderFiltering="false"
							:allowFiltering="false"
							:calculate-display-value="rowData => `${rowData.myChoiceScore}/${rowData.totalChoiceScore}`"
						/>
						<DxColumn
							caption="O/X"
							data-field="myOxScore"
							alignment="center"
							width="100"
							:allow-sorting="false"
							:allowHeaderFiltering="false"
							:allowFiltering="false"
							:calculate-display-value="rowData => `${rowData.myOxScore}/${rowData.totalOxScore}`"
						/>
						<DxColumn
							caption="단답형"
							data-field="myShortScore"
							alignment="center"
							width="100"
							:allow-sorting="false"
							:allowHeaderFiltering="false"
							:allowFiltering="false"
							:calculate-display-value="rowData => `${rowData.myShortScore}/${rowData.totalShortScore}`"
						/>
						<DxColumn
							caption="서술형"
							data-field="myLongScore"
							alignment="center"
							width="100"
							:allow-sorting="false"
							:allowHeaderFiltering="false"
							:allowFiltering="false"
							:calculate-display-value="rowData => `${rowData.myLongScore}/${rowData.totalLongScore}`"
						/>
					</DxColumn>
				</DxDataGrid>
			</div>

			<div class="h-2/5">
				<div class="sub_title_txt my-4 flex">
					<h2>문항정보</h2>
					<div class="flex justify-end flex-grow">
						<div class="total-count-item">
							검색결과 <span class="tet-calr1">{{ reportDetailGridData.totalCount }}</span> 개
						</div>
					</div>
				</div>
				<DxDataGrid
					class="grid-box"
					ref="reportDetailGridData"
					:data-source="reportDetailGridData.dataSource"
					:allow-column-resizing="true"
					:column-resizing-mode="'nextColumn'"
					:show-borders="false"
					:show-column-headers="true"
					:show-column-lines="true"
					:show-row-lines="true"
					:row-alternation-enabled="false"
					:hover-state-enabled="true"
					:word-wrap-enabled="true"
					:no-data-text="this.$_lang('CMN_NO_DATA')"
					width="100%"
					height="300"
				>
					<DxFilterRow :visible="true" />
					<DxHeaderFilter :visible="true" />
					<DxScrolling mode="virtual" />

					<DxColumn
						caption="문항번호"
						alignment="center"
						width="80"
						data-field="questionOrd"
						:allow-sorting="false"
						:allowHeaderFiltering="false"
						:allowFiltering="false"
					/>
					<DxColumn
						caption="문항내용"
						alignment="center"
						data-field="questionNm"
						:allow-sorting="false"
						:allowHeaderFiltering="false"
						:allowFiltering="true"
						cell-template="qustionNmTemplate"
					/>
					<DxColumn
						caption="문항 정답율"
						data-field="answerRate"
						alignment="center"
						width="100"
						:allow-sorting="false"
						:allowHeaderFiltering="false"
						:allowFiltering="false"
						:calculate-display-value="rowData => `${rowData.answerRate}%`"
					/>
					<DxColumn
						caption="배점"
						data-field="score"
						alignment="center"
						width="100"
						:allow-sorting="false"
						:allowHeaderFiltering="false"
						:allowFiltering="false"
					>
					</DxColumn>
					<DxColumn
						caption="정답유형"
						data-field="answerTypeCd"
						alignment="center"
						width="120"
						:allow-sorting="false"
						:allowHeaderFiltering="true"
						:allowFiltering="true"
					>
						<DxLookup :data-source="codes.answerTypeCd.dataSource" value-expr="codeId" display-expr="codeNm" />
					</DxColumn>
					<DxColumn
						caption="정답유무"
						data-field="isAnswer"
						alignment="center"
						width="120"
						:allow-sorting="false"
						:allowHeaderFiltering="true"
						:allowFiltering="false"
					>
						<DxLookup :data-source="codes.isAnswer.dataSource" value-expr="codeId" display-expr="codeNm" />
					</DxColumn>
					<DxColumn
						caption="입력/선택 답안"
						data-field="targetAnswers"
						alignment="center"
						width="150"
						:allow-sorting="false"
						:allowHeaderFiltering="false"
						:allowFiltering="false"
					>
					</DxColumn>
					<DxColumn
						caption="모범 답안"
						data-field="answers"
						alignment="center"
						width="150"
						:allow-sorting="false"
						:allowHeaderFiltering="false"
						:allowFiltering="false"
					>
					</DxColumn>

					<template #qustionNmTemplate="{ data }">
						<div v-html="data.data.questionNm"></div>
					</template>
				</DxDataGrid>
			</div>

			<!-- Agent Modal -->
			<modal-add-agent
				:isOpen="agent.popupVisible"
				:showModalTitle="true"
				:selectedIdList="agent.selectedIdList"
				@closeModal="onClose(false)"
				@saveModal="onSave"
				:minimumSelectionLength="agent.minimumSelectionLength"
				:maximumSelectionLength="agent.maximumSelectionLength"
				:saveBtnTxt="agent.saveBtnTxt"
			/>
			<!-- Agent Modal -->
			<!-- Modal Layer -->
			<DxPopup
					:show-title="true"
					:title="modal.initData ? modal.initData.title : null"
					:min-width="modal.initData ? modal.initData.width : null"
					:width="modal.initData ? modal.initData.width : null"
					:min-height="modal.initData ? modal.initData.height : null"
					:height="modal.initData ? modal.initData.height : null"
					:drag-enabled="true"
					:resize-enabled="true"
					:show-close-button="true"
					:hide-on-outside-click="false"
					v-model="modal.isOpened"
					:visible="modal.isOpened"
					@hiding="isOpenModal(false)"
					class="text-left"
			>
      <template #content>
        <div>
          <component  
                      ref="reasonModalRef"
                      v-model="modal.contentData"
                      :is="modal.currentComponent"
                      :contentData="modal.contentData"
                      :isModal="modal.isModal"
          ></component>
        </div>
      </template>
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="true"
        :options="{
          elementAttr: {
            class: 'white filled txt_S medium',
          },
          text: this.$_lang('COMPONENTS.CLOSE', { defaultValue: '닫기' }),
          width: '120',
          height: '40',
          onClick: () => this.isOpenModal(false),
        }"
      />
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="true"
        :options="{
          elementAttr: {
            class: 'default filled txt_S medium',
          },
          text: this.$_lang('COMPONENTS.SAVE', { defaultValue: '저장' }),
          width: '120',
          height: '40',
          onClick: () => this.onDownloadReason(),
        }"
      />
    </DxPopup>
    <!-- /Modal Layer --> 
		</div>
	</div>
</template>

<script>
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxButton } from 'devextreme-vue/button';
import { DxDateBox } from 'devextreme-vue/date-box';
import {DxPopup, DxToolbarItem} from 'devextreme-vue/popup';
import ModalDownloadReason from '@/components/common/esp-modal-download-reason.vue';
import {
	DxDataGrid,
	DxColumn,
	DxSelection,
	DxFilterRow,
	DxHeaderFilter,
	DxLookup,
	DxScrolling,
} from 'devextreme-vue/data-grid';
import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
import validationEngine from 'devextreme/ui/validation_engine';
import { getPastFromToday, isSuccess, formatDate } from '@/plugins/common-lib';
import ModalAddAgent from '@/components/ewm/hr/modal-add-agent.vue';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';

let vm = null;

export default {
	components: {
		DxPopup,
		DxToolbarItem,
		DxTextBox,
		DxButton,
		DxDateBox,
		DxDataGrid,
		DxColumn,
		DxSelection,
		DxFilterRow,
		DxHeaderFilter,
		DxLookup,
		DxScrolling,
		DxRequiredRule,
		DxValidator,
		ModalAddAgent,
		ModalDownloadReason,
	},
	props: {},
	watch: {},
	data() {
		return {
			textStylingMode: 'filled',
			stylingMode: 'outlined', //outlined, underlined, filled
			searchType: {
				obj: {},
				defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
				defaultValue: 'ROOT',
				customTypes: {
					dayStart: getPastFromToday(11, 'months'),
					dayEnd: getPastFromToday(0, 'days'),
					targetName: '',
					targetId: '',
				},
				paramsData: null,
			},
      showActionButtons: {
        excel: true,
        customButtons: [],
      },
			title: '성적표',
      dataGrid1: {
        excel: {
          title: '시험정보'
        },
        columns: [
          {}, {}, {}, {}, {}, {},
        ],
      },
      dataGrid2: {
        excel: {
          title: '문항정보'
        },
        columns: [
          {}, {cellwidth: 100}, {}, {}, {}, {}, {}, {}
        ],
      },
			modal: {
        isOpened: false,
        initData: {},
        contentData: null,
        componentName: '',
        currentComponent: null,
      },
			codes: {
				examSheetType: {
					dataSource: [],
					displayExpr: 'label',
					valueExpr: 'value',
				},
				answerTypeCd: {
					dataSource: [],
					displayExpr: 'codeNm',
					valueExpr: 'codeId',
				},
				isAnswer: {
					dataSource: [
						{ codeId: 0, codeNm: '정답' },
						{ codeId: 1, codeNm: '부분정답' },
						{ codeId: 2, codeNm: '오답' },
					],
					displayExpr: 'codeNm',
					valueExpr: 'codeId',
				},
			},
			agent: {
				popupVisible: false,
				selectedIdList: [],
				targetSelectedIdList: [],
				minimumSelectionLength: 1,
				maximumSelectionLength: 1,
				saveBtnTxt: '선택',
			},
			reportGridData: {
				dataSource: null,
				selectedRowsData: [],
				selectedRowKeys: [],
				totalCount: 0,
			},
			reportDetailGridData: {
				dataSource: null,
				selectedRowsData: [],
				selectedRowKeys: [],
				totalCount: 0,
			},
		};
	},
	computed: {},
	methods: {
		/**
		 * @description: 엑셀 다운로드 사유 입력 모달 이벤트
		 */
		onDownloadReason() {
			let event = this.modal.contentData;
			const reason = this.$refs.reasonModalRef.reason;
			if (reason.trim() === '') {
				this.$_Msg(
					this.$_lang('COMMON.MESSAGE.REQUIRED_DOWNLOAD_REASON', {
						defaultValue: '다운로드 사유를 입력하세요.',
					}),
				);
				event.cancel = true;
			} else {
				event = this.makeSaveHistory(event, reason);
				event.cancel = false;
				this.onExporting(event);
				this.isOpenModal(false);
			}
		},
		makeSaveHistory(event, reason = '') {
			const user = {
				userNo: this.$store.getters.getUserInfo?.userNo || this.$store.getters.getLoginId,
				userNm: this.$store.getters.getUserInfo?.userNm || this.$store.getters.getLoginNm,
				deptNm: this.$store.getters.getUserInfo?.deptNm || '',
				gradeNm: this.$store.getters.getUserInfo?.gradeNm || '',
			};

			const payload = {
				actionName: 'FILE_DOWNLOAD_HISTORY_UPDATE',
				data: [
					{
						...user,
						reason,
						fileType: 'EXCEL',
						fileNm: this.title?.title,
					},
				],
				loading: false,
			};

			event.onSaveHistory = async fileNm => {
				payload.data[0].fileNm = fileNm || this.dataGrid.excel?.title;
				return await this.CALL_API(payload);
			};
			return event;
		},
    onExportingCheck(e) {
      const useDownReason = this.$_getSystemData('use_excel_download_reason')?.configValue === 'Y';
      e = this.makeSaveHistory(e);
      if (useDownReason) {
        e.cancel = true;
        this.onOpenModal(
          'ModalDownloadReason',
          {
            title: this.$_lang('COMPONENTS.DOWNLOAD_REASON', { defaultValue: '다운로드 사유' }),
            width: '600',
            height: '400',
          },
          e,
        );
      } else {
        this.onExporting(e);
      }
    }, 
		/** @description: 팝업 열기 공통 함수 */
    onOpenModal(componentNm, componentInitData, data) {
      this.modal.currentComponent = componentNm;
      this.modal.initData = componentInitData;
      this.modal.contentData = data;
      this.isOpenModal(true);
    },
		/** @description: 팝업이 열렸는지 체크하는 메서드(true: 열림/false: 닫힘) */
		isOpenModal(data) {
			this.modal.isOpened = data;
			if (!data) {
				this.modal.currentComponent = null;
				this.modal.initData = {};
				this.modal.contentData = null;
			}
		},
		/** @description: 엑셀 다운로드 이벤트 */
		onExporting(e) {
			// Grid Excel Export
			const title1 = this.dataGrid1.excel.title;
			const title2 = this.dataGrid2.excel.title;
			const workbook = new ExcelJS.Workbook();
			const worksheet1 = workbook.addWorksheet(title1);
			const worksheet2 = workbook.addWorksheet(title2);

			// // 첫 번째 그리드 설정
			let columnsArr1 = [];
			this.dataGrid1.columns.forEach(col => {
				columnsArr1.push({ width: col.cellwidth ? col.cellwidth : 30 });
			});
			worksheet1.columns = columnsArr1;

			// // 두 번째 그리드 설정
			let columnsArr2 = [];
			this.dataGrid2.columns.forEach(col => {
				columnsArr2.push({ width: col.cellwidth ? col.cellwidth : 30 });
			});
			worksheet2.columns = columnsArr2;

			let today = formatDate(new Date(), 'YYYYMMDDHHmmss', 'YYYYMMDDHHmmss');

			const headerStyle = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'C6EFCE' } };
			const borderStyle = { style: 'thin', color: { argb: 'FF7E7E7E' } };

			const border = {
						bottom: borderStyle,
						left: borderStyle,
						right: borderStyle,
						top: borderStyle,
					};
			const alignment = { horizontal: 'center', vertical: 'middle' };
			const dataBackgroundStyle = {
								type: 'pattern',
								pattern: 'solid',
								fgColor: { argb: 'F2F2F2' },
								bgColor: { argb: 'F2F2F2' },
							};

			// 첫 번째 그리드 데이터 내보내기
			exportDataGrid({
				component: this.$refs.reportGridData.instance,
				worksheet: worksheet1,
				keepColumnWidths: false,
				autoFilterEnabled: this.dataGrid1.excel.autoFilterEnabled ? this.dataGrid1.excel.autoFilterEnabled : false, // 자동필터 설정 여부
				topLeftCell: { row: 4, column: 1 },
				customizeCell: ({ gridCell, excelCell }) => {
					if (gridCell.rowType === 'header') {
						// header 영역 설정
						excelCell.fill = headerStyle;
						excelCell.alignment = alignment;
					} else {
						// data 영역 배경색 설정
						if (excelCell.fullAddress.row % 2 === 0) {
							excelCell.fill = dataBackgroundStyle;
						}
					}

					excelCell.border = border
				},
			})
				.then(() => {
					const titleRow = worksheet1.getRow(2);
					titleRow.height = 40;
					if (e.format === 'xlsx') {
						worksheet1.mergeCells(2, 1, 2, this.dataGrid1.columns.length);
					}
					titleRow.getCell(1).value = title1;
					titleRow.getCell(1).font = { size: 22, bold: true };
					titleRow.getCell(1).alignment = alignment

					const headerRow = worksheet1.getRow(4);
					headerRow.height = 30;
				});

			// 두 번째 그리드 데이터 내보내기
			exportDataGrid({
				component: this.$refs.reportDetailGridData.instance,
				worksheet: worksheet2,
				keepColumnWidths: false,
				autoFilterEnabled: this.dataGrid2.excel.autoFilterEnabled ? this.dataGrid2.excel.autoFilterEnabled : false, // 자동필터 설정 여부
				topLeftCell: { row: 4, column: 1 },
				customizeCell: ({ gridCell, excelCell }) => {
					if (gridCell.rowType === 'header') {
						// header 영역 설정
						excelCell.fill = headerStyle;
						excelCell.alignment = alignment;
					} else {
						// data 영역 배경색 설정
						if (excelCell.fullAddress.row % 2 === 0) {
							excelCell.fill = dataBackgroundStyle;
						}
					}

					excelCell.border = border;
				},
			})
				.then(() => {
					const titleRow = worksheet2.getRow(2);
					titleRow.height = 40;
					if (e.format === 'xlsx') {
						worksheet2.mergeCells(2, 1, 2, this.dataGrid2.columns.length);
					}
					titleRow.getCell(1).value = title2;
					titleRow.getCell(1).font = { size: 22, bold: true };
					titleRow.getCell(1).alignment = { horizontal: 'center', vertical: 'middle' };

					const headerRow = worksheet2.getRow(4);
					headerRow.height = 30;
				})
				.then(() => {
					let fileName;
					if (e.format === 'csv') {
						fileName = `${this.title}_${today}.csv`;
						workbook.csv.writeBuffer().then(buffer => {
							saveAs(new Blob([buffer], { type: 'text/csv' }), fileName);
						});
					} else {
						fileName = `${this.title}_${today}.xlsx`;
						workbook.xlsx.writeBuffer().then(buffer => {
							saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName);
						});
					}
					return fileName;
				})
				.then(fileName => {
					// 다운로드 이력 저장
					e.onSaveHistory(fileName);
				});
			e.cancel = true;
      
    },
		arrayEquals(arr1, arr2) {
			arr1.sort();
			arr2.sort();
			if (arr1.length === 0 && arr2.length === 0) {
				return false;
			}
			return arr1.length === arr2.length && arr1.every((v, i) => v === arr2[i]);
		},
		onOpen() {
			this.agent.popupVisible = true;
			this.agent.selectedIdList = this.agent.targetSelectedIdList;
		},
		onClose() {
			this.agent.popupVisible = false;
			this.agent.selectedIdList = [];
		},
		onSave(value) {
			this.searchType.customTypes.targetName = value[0].agtNm;
			this.searchType.customTypes.targetId = value[0].agtid;
			this.agent.targetSelectedIdList = value[0].agtid;
			this.onClose();
		},
		async onClickRow(data) {
			const targetId = this.searchType.customTypes.targetId;
			const scheId = data.data.scheId;
			const payload = {
				actionName: 'EWM_EXAM_REPORT_SCHE_QUESTIONS',
				path: '/' + scheId + '/target/' + targetId + '/questions',
				loading: false,
				useErrorPopup: true,
			};
			const res = await vm.CALL_EWM_API(payload);
			if (isSuccess(res)) {
				res.data.data.forEach(d => {
					// 정답
					if (this.arrayEquals(d.targetAnswers, d.answers)) {
						d.isAnswer = 0;
					}
					// 부분정답
					else if (d.feedbackScore > 0) {
						d.isAnswer = 1;
					}
					// 오답
					else {
						d.isAnswer = 2;
					}
					// <p> 태그와 </p> 태그를 제거
					res.data.data.forEach(item => {
						item.questionNm = item.questionNm.replace(/<\/?p>/g, '');
					});
					//d.targetAnswers?.reverse();
					//d.answers?.reverse();
				});
				this.reportDetailGridData.totalCount = res.data.data.length;
				this.reportDetailGridData.dataSource = res.data.data;
				console.log(res.data);
				console.log(res.data.data);
			} else {
				return () => {
					throw 'Data Loading Error';
				};
			}
		},
		/** @description : 데이터 검색 메서드 */
		async selectDataList() {
			if (!validationEngine.validateGroup('validationSearch').isValid) {
				return;
			}

			if (!vm.searchType.customTypes.targetId) {
				this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
				return;
			}

			const payload = {
				actionName: 'EWM_EXAM_REPORT_CARD',
				data: {
					startDt: this.searchType.customTypes.dayStart,
					endDt: this.searchType.customTypes.dayEnd,
					targetId: this.searchType.customTypes.targetId,
				},
				loading: false,
				useErrorPopup: true,
			};
			const res = await vm.CALL_EWM_API(payload);
			if (isSuccess(res)) {
				this.reportGridData.totalCount = res.data.data.length;
				this.reportGridData.dataSource = res.data.data;
			} else {
				return () => {
					throw 'Data Loading Error';
				};
			}
		},
		onDayStartChanged(e){
			const changed = e.value.slice(0, 6)+'01';
			this.searchType.customTypes.dayStart = changed;
		},
		onDayEndChanged(e){
			const changed = e.value.slice(0, 6)+'29';
			this.searchType.customTypes.dayEnd = changed;
		},
		/** @description : 코드 로드 */
		async initCodeMap() {
			this.codeMap = await this.$_getCodeMapList('root_edu_exam_type,root_ewm_edu_exam_answer_type');
		},
		/** @description : 라이프사이클 created 호출되는 메서드 */
		createdData() {
			vm = this;

			this.initCodeMap().then(() => {
				this.codes.examSheetType.dataSource = this.$_enums.ewm.examSheetType.values;

				this.codes.answerTypeCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_edu_exam_answer_type'], 2);
			});

			// this.onChangeSearchDay();
			// this.selectDataList();
		},
		/** @description : 라이프사이클 mounted 호출되는 메서드 */
		mountedData() {},
	},
	created() {
		this.createdData();
	},
	mounted() {
		this.mountedData();
	},
};
</script>

<style lang="scss" scoped>
.sub_new_style01 .page_search_box .inner div {
	display: inline-block;
}
.sub_new_style01 .page_search_box .inner > div {
	vertical-align: middle;
	margin-right: 10px;
}
.page_search_box .inner .box-btn-search {
	margin-right: 15px;
}

::v-deep .dx-texteditor-input {
  text-align: center;
}
::v-deep textarea.dx-texteditor-input {
  text-align: left;
}
</style>
