<!--
  PACKAGE_NAME : src/pages/cc/skl/history/skl-assignment-hist.vue
  FILE_NAME : skl-assignment-hist
  AUTHOR : hpmoon
  DATE : 2023-10-30
  DESCRIPTION : 스킬배정 이력
-->
<template>
  <div class="container">
    <div class="page-sub-box sub_new_style01 sub_ui_box1">
      <!--"sub_new_style01" class적용시 상단라인 삭제-->
      <!--검색영역-->
      <Search
        codeKey="search_type_agt"
        :searchObj="searchType.obj"
        :useKeywordType="true"
        @change-search-type="$_changeSearchType"
        @onSearchClick="e => onSearchData(e, 'search_type_agt')"
      >
        <template v-slot:before>
          <div class="ui-datepicker-item">
            <DxDateBox
              :edit-enabled="false"
              :styling-mode="config.stylingMode"
              :width="120"
              v-model="searchType.customTypes.dayStart"
              type="date"
              display-format="yyyy.MM.dd"
              @value-changed="onChangeSearchDay"
              dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
            >
            </DxDateBox>
          </div>
          <div class="ui-datepicker period">
            <span class="dash">~</span>
          </div>
          <div class="ui-datepicker-item">
            <DxDateBox
              :styling-mode="config.stylingMode"
              :width="120"
              v-model="searchType.customTypes.dayEnd"
              type="date"
              display-format="yyyy.MM.dd"
              @value-changed="onChangeSearchDay"
              dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
            >
            </DxDateBox>
          </div>
        </template>
      </Search>
      <!-- //검색영역-->

      <esp-dx-data-grid :data-grid="dataGrid" ref="dataGrid" @cell-hover-changed="onCellHoverChanged" />
      <DxPopover width="300px" :visible="popoverVisible" :target="hoverTarget" position="top" :hide-on-outside-click="true">
        <ul>
          <li v-for="(skl, i) in this.popoverData">
            <strong>{{ skl.sklNm }} ({{ skl.priority }})</strong><br />
          </li>
        </ul>
      </DxPopover>
    </div>
  </div>
</template>

<script>
  import Search from '@/components/common/search.vue';
  import DxDateBox from 'devextreme-vue/date-box';
  import { isSuccess, formatDate, getPastFromToday, cloneObj } from '@/plugins/common-lib';
  import { DxPopover } from 'devextreme-vue/popover';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  let vm = this;

  export default {
    components: {
      EspDxDataGrid,
      DxPopover,
      DxDateBox,
      Search,
    },
    data() {
      return {
        config: {
          api: {
            select: 'SKL_ASSIGN_HISTORY',
          },
          stylingMode: 'outlined', //[outlined, filled, underlined]
        },
        searchType: {
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            dayStart: getPastFromToday(30, 'days'),
            dayEnd: getPastFromToday(0, 'days'),
            reqDt: null,
          },
          paramsData: null,
        },
        popoverVisible: false,
        popoverData: [],
        hoverTarget: '',
        dataGrid: {
          refName: 'dataGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          wordWrapEnabled: true, //줄바꿈 허용
          dataSource: [],
          // width: '200',     // 주석처리시 100%
          height: null, // 주석처리시 100%
          apiActionNm: {},
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
            optionChanged: true,
            cellHoverChanged: true,
          },
          showActionButtons: {},
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: false, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          // scrolling: { // 미사용시 주석처리
          //     mode: 'virtual' //스크롤 모드 : ['infinite', 'standard', 'virtual']
          // },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowDeleting: false,
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'none', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '부서',
              dataField: 'deptCd',
              width: 250,
              height: 40,
              alignment: 'left', // left center right
              visible: true,
              allowEditing: false,
              allowSorting: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
              lookup: {
                dataSource: [],
                displayExpr: 'namePath',
                valueExpr: 'id',
                disabled: true,
              },
            },
            {
              caption: '상담원',
              dataField: 'agtNm',
              width: 200,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              allowSorting: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
            },
            {
              multiHeaderNm: '스킬',
              columns: [
                {
                  caption: '변경 전',
                  dataField: 'beforeSklList',
                  // width: '50%',
                  height: 40,
                  alignment: 'left', // left center right
                  visible: true,
                  allowEditing: false,
                  sortOrder: 'none', // acs desc none
                  allowHeaderFiltering: false,
                  fixed: false, // 컬럼 fix 시 사용
                  fixedPosition: 'left', // left or right
                  multiHeaderNm: '스킬',
                  calculateCellValue: this.calcBeforeSklList,
                },
                {
                  caption: '변경 후',
                  dataField: 'afterSklList',
                  // width: '50%',
                  height: 40,
                  alignment: 'left', // left center right
                  visible: true,
                  allowEditing: false,
                  sortOrder: 'none', // acs desc none
                  allowHeaderFiltering: false,
                  fixed: false, // 컬럼 fix 시 사용
                  fixedPosition: 'left', // left or right
                  multiHeaderNm: '스킬',
                  calculateCellValue: this.calcAfterSklList,
                },
              ],
            },
            {
              caption: '수정자',
              dataField: 'editId',
              width: 200,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '수정일시',
              dataField: 'editDt',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              calculateCellValue: this.formatEditDt,
            },
          ],
        },
        searchList: ['title', 'abcd'],
        startDate: null,
        endDate: null,
        stateCode: null,
        searchOpt: null,
        searchData: null,
        sortColumn: 'reqDt',
        sortOrderDesc: true,
      };
    },

    computed: {},
    methods: {
      /** @description: 행 mouseover 이벤트 정의. 스킬리스트 표출을 위해 사용*/
      onCellHoverChanged(e) {
        this.popoverVisible = false;
        if (e.rowType == 'data' && e.eventType === 'mouseover') {
          if (e.columnIndex === 2) {
            this.hoverTarget = e.row.cells[e.columnIndex].cellElement;
            this.popoverData = e.row.data.beforeSklList;
            this.popoverVisible = true;
          } else if (e.columnIndex === 3) {
            this.hoverTarget = e.row.cells[e.columnIndex].cellElement;
            this.popoverData = e.row.data.afterSklList;
            this.popoverVisible = true;
          }
        }
      },
      /** @description: 스킬리스트 커스텀 */
      calcBeforeSklList(rowData) {
        return this.formatSklList(rowData.beforeSklList);
      },
      /** @description: 스킬리스트 커스텀 */
      calcAfterSklList(rowData) {
        return this.formatSklList(rowData.afterSklList);
      },
      /** @description: 스킬리스트 포멧 */
      formatSklList(orgSklList) {
        let sklList = '';
        orgSklList.forEach(skl => {
          sklList += `${skl.sklNm}(${skl.priority}), `;
        });
        sklList = sklList.slice(0, sklList.length - 2);
        return sklList;
      },
      /** @description: 날짜 포멧 */
      formatEditDt(rowData) {
        return rowData.editDt ? formatDate(rowData.editDt, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss') : '-';
      },
      /** @description: 날짜 포멧 */
      getFormatDate(date) {
        let year = date.getFullYear(); //yyyy
        let month = 1 + date.getMonth(); //M
        month = month >= 10 ? month : '0' + month; //month 두자리로 저장
        let day = date.getDate(); //d
        day = day >= 10 ? day : '0' + day; //day 두자리로 저장
        return year + '' + month + '' + day; //'-' 추가하여 yyyy-mm-dd 형태 생성 가능
      },
      /** @description : 검색 조회 커스텀 메서드 */
      onSearchData(paramsData, codeKey) {
        this.$_Toast('검색 기능 구현 예정');
      },
      async selectDataList(sort = '-reqDt') {
        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          async load(loadOptions) {
            let params = vm.$_getDxDataGridParam(loadOptions);
            if (!params.sort) {
              params.sort = sort;
            }

            params = { ...params, ...vm.searchType.paramsData };
            const payload = {
              actionName: vm.config.api.select,
              data: { params },
              loading: false,
            };

            // const res = await vm.CALL_API(payload);
            let res = await { data: { data: [], header: { totalCount: 0, resCode: 'success' } }, status: 200 };

            if (isSuccess(res)) {
              /////////// 임시로 데이터 넣음. 실데이터 받아오는로직으로 변경해야함 //////////////
              let skl_list_payload = {
                actionName: 'CC_SKL_LIST',
                data: { params: { sort: '+sklOrd' } },
                loading: false,
                useErrorPopup: true,
              };
              let skl_list_res = await vm.CALL_API(skl_list_payload);

              let member_payload = {
                actionName: 'MEMBER_LIST_ALL',
                loading: false,
                useErrorPopup: true,
              };
              let member_res = await vm.CALL_API(member_payload);

              let agent_payload = {
                actionName: 'EWM_AGENT_SEARCH',
                loading: false,
                useErrorPopup: true,
              };
              let agent_res = await vm.CALL_EWM_API(agent_payload);

              var startDate = new Date();

              for (let i = 1; i < 11; i++) {
                let agent = agent_res.data.data[Math.floor(Math.random() * agent_res.data.data.length)];
                res.data.data[i - 1] = {
                  id: i,
                  deptCd: agent.deptCd,
                  agtNm: `${agent.agtNm}(${agent.agtid})`,
                  beforeSklList: [],
                  afterSklList: [],
                  editId: null,
                  editDt: null,
                };

                res.data.data[i - 1].beforeSklList = cloneObj(skl_list_res.data.data);
                res.data.data[i - 1].beforeSklList.forEach(skl => (skl.priority = getPriority()));
                res.data.data[i - 1].beforeSklList = res.data.data[i - 1].beforeSklList.filter(skl => skl.priority !== 0);

                res.data.data[i - 1].afterSklList = cloneObj(skl_list_res.data.data);
                res.data.data[i - 1].afterSklList.forEach(skl => (skl.priority = getPriority()));
                res.data.data[i - 1].afterSklList = res.data.data[i - 1].afterSklList.filter(skl => skl.priority !== 0);

                let editId = Math.floor(Math.random() * member_res.data.data.length);
                editId = `${member_res.data.data[editId].loginNm}(${member_res.data.data[editId].loginId})`;

                let h = Math.floor(Math.random() * (19 - 9 + 1) + 9);
                h = h >= 10 ? h + '' : '0' + h;
                let m = Math.floor(Math.random() * 59);
                m = m >= 10 ? m + '' : '0' + m;
                let s = Math.floor(Math.random() * 59);
                s = s >= 10 ? s + '' : '0' + s;

                let timestamp = vm.getFormatDate(startDate) + h + m + s;

                res.data.data[i - 1].editId = editId;
                res.data.data[i - 1].editDt = timestamp;
                startDate.setDate(startDate.getDate() - 1);

                function getPriority() {
                  let priority = Math.floor(Math.random() * 100) + 1;
                  if (priority > 10) priority = 0;
                  return priority;
                }
              }
              /////////// 임시로 데이터 넣음. 실데이터 받아오는로직으로 변경해야함 //////////////

              const rtnData = {
                data: res.data.data,
                totalCount: res.data.header.totalCount,
              };

              vm.$refs.dataGrid.totalCount = rtnData.totalCount;
              return rtnData;
            }
          },
        });
      },
      onChangeSearchDay() {
        const reqDt = this.searchType.customTypes.dayStart + '000000' + '~' + this.searchType.customTypes.dayEnd + '235959';
        this.searchType.paramsData = { ...this.searchType.paramsData, reqDt };

        this.$_setSearchHistsCustomType(this.searchType.paramsData);
      },
      /** @description: LookUpData 세팅 */
      async setLookUpData() {
        let payload = {
          actionName: 'EWM_AGENT_CODE_LIST',
          data: { params: { rootNm: 'ewm_hr_dept' } },
          loading: false,
          useErrorPopup: true,
        };
        let res = await vm.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.deptLookUp = res.data.data[0].codeMap.ewm_hr_dept;
        } else {
          return;
        }

        this.dataGrid.columns[0].lookup.dataSource = this.deptLookUp;
      },
      /** @description: grid 높이 조정 */
      setGridHeight() {
        this.dataGrid.height = window.innerHeight - 309;
      },
      /** @description : 라이프사이클 created 호출되는 메서드 */
      createdData() {
        vm = this;
        this.setLookUpData();
      },
      /** @description : 라이프사이클 mounted 호출되는 메서드 */
      mountedData() {
        vm = this;
        this.onChangeSearchDay();
        this.selectDataList();

        this.setGridHeight();
        window.addEventListener('resize', this.setGridHeight);
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountedData();
    },
  };
</script>

<style lang="scss" scoped>
  ::v-deep {
    .dx-freespace-row > td {
      border-left: 0 !important;
      border-right: 0 !important;
    }
  }
</style>