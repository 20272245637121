<!--
  PACKAGE_NAME : src/pages/cc/ivr/schedule/config/default-info.vue
  FILE_NAME : default-info
  AUTHOR : hmlee
  DATE : 2024-10-8
  DESCRIPTION : 운영스케줄관리 설정 - 기본정보 컴포넌트
-->
<template>
  <table class="table_form line-bin">
    <caption>
      <strong>{{ $_lang('CC.WORD.BASIC_INFO', { defaultValue: '기본 정보' }) }}</strong>
    </caption>

    <colgroup>
      <col style="width: 130px" />
      <col style="width: auto" />
    </colgroup>

    <thead class="sub_title_txt">
      <tr>
        <td colspan="2"><h2>{{ $_lang('CC.WORD.BASIC_INFO', { defaultValue: '기본 정보' }) }}</h2></td>
      </tr>
    </thead>

    <tbody>
      <tr>
        <th scope="row">
          <label for="label5">{{ $_lang('CC.WORD.OP_SCHEDULE_NAME', { defaultValue: '운영 스케줄명' }) }} <span class="icon_require"></span></label>
        </th>
        <td>
          <dx-text-box
            v-model="formBasicInfo.scheduleNm"
            :max-length="config.limitNumberTexts.maxLengths.scheduleNm"
            :styling-mode="config.stylingMode"
            class="mar_ri10"
            :width="390"
            @key-up="$_checkLimitTextLength($event, formBasicInfo, config.limitNumberTexts, 'scheduleNm')"
          >
            <dx-validator>
              <dx-required-rule :message="$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS', { value: $_lang('CC.WORD.OP_SCHEDULE_NAME', {defaultValue: '운영 스케줄명'}) })" />
            </dx-validator>
          </dx-text-box>
          {{
            config.limitNumberTexts.textLengths.scheduleNm
              ? config.limitNumberTexts.textLengths.scheduleNm
              : formBasicInfo.scheduleNm
              ? formBasicInfo.scheduleNm.length
              : 0
          }}/{{ config.limitNumberTexts.maxLengths.scheduleNm }}자
        </td>
      </tr>
      <tr>
        <th scope="row" class="alT"><label for="label7">{{ $_lang('CC.WORD.DESCRIPTION', { defaultValue: '설명' }) }}</label></th>
        <td>
          <dx-text-area
            v-model="formBasicInfo.description"
            :max-length="config.limitNumberTexts.maxLengths.description"
            :styling-mode="config.stylingMode"
            class="mar_ri10 alB"
            :width="390"
            :height="80"
            @key-up="$_checkLimitTextLength($event, formBasicInfo, config.limitNumberTexts, 'description')"
          >
          </dx-text-area>
          {{
            config.limitNumberTexts.textLengths.description
              ? config.limitNumberTexts.textLengths.description
              : formBasicInfo.description
              ? formBasicInfo.description.length
              : 0
          }}/{{ config.limitNumberTexts.maxLengths.description }}자
        </td>
      </tr>
      <tr>
        <th scope="row">{{ $_lang('CC.WORD.OP_DAY_OF_WEEK', { defaultValue: '운영 요일' }) }}</th>
        <td>
          <div>
            <span class="check-type col" v-for="(item, index) in config.days" :key="index">
              <dx-check-box
                :text="item.label"
                v-model="item.isChecked"
                :data="item.value"
                :value="item.value"
                @value-changed="handleChangedDaysType"
              />
            </span>
          </div>
        </td>
      </tr>
      <tr>
        <th scope="row">{{ $_lang('COMPONENTS.USE_STATUS', { defaultValue: '사용여부' }) }}</th>
        <td>
          <dx-switch @value-changed="handleChangedViewFl($event)" :value="getViewFl" />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { DxRequiredRule, DxValidator } from 'devextreme-vue/validator';
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxTextArea } from 'devextreme-vue/text-area';
import { DxCheckBox } from 'devextreme-vue/check-box';
import { DxSwitch } from 'devextreme-vue/switch';

export default {
	components: {
		DxValidator,
		DxRequiredRule,
		DxTextBox,
		DxTextArea,
		DxCheckBox,
		DxSwitch,
	},
	props: {
    /** @description: 선택된 요일 */
    checkedDays: {
      type: Array,
    },
    /** @description: 기본 정보 */
    basicInfo: {
      type: Object,
    },
  },
	data() {
		return {
      config: {
        stylingMode: 'outlined', //[outlined, filled, underlined]
        days: [], //운영요일
        limitNumberTexts: {
          textLengths: {},
          maxLengths: {
            scheduleNm: 50,
            description: 200,
          },
        },
      },
      formBasicInfo: {}, // props 로컬 데이터
      formCheckedDays: [], // props 로컬 데이터
		};
	},
	computed: {
    /** @description: 사용여부 */
    getViewFl() {
      return this.formBasicInfo.viewFl === 'Y';
    },
	},
  watch: {
    /** @description: 선택된 요일 데이터 */
    checkedDays: {
      handler(newValue) {
        this.formCheckedDays = [...newValue];
        this.getDayTypeList();
      },
      immediate: true,
    },
    /** @description: 기본 정보 데이터 */
    basicInfo: {
      handler(newValue) {
        this.formBasicInfo = JSON.parse(JSON.stringify(newValue));
      },
      immediate: true, // 컴포넌트 생성 시 즉시 실행
      deep: true // 깊은 감시
    },
  },
	methods: {
    /** @description : 운영요일 리스트 정보 가져오기 */
    getDayTypeList() {
      this.config.days = this.$_enums.common.integerDayOfWeek.values.map(day => {
        if( this.formCheckedDays.some(checkedDay => checkedDay === day.value) ){
          day.isChecked = true;
        } else {
          day.isChecked = false;
        }
        return { ...day };
      }).sort((a, b) => a.sort - b.sort);
    },
		/** @description : 운영요일 Checkbox 변경시 이벤트 */
		handleChangedDaysType() {
      const checkedDayList = this.config.days.filter(d => d.isChecked);
      const checkedDays = checkedDayList.map(day => day.value);
      this.$emit('changedDaysType', checkedDays);
		},
    /** @description : 사용여부 이벤트 */
    handleChangedViewFl(e) {
      if (e.value) {
        this.formBasicInfo.viewFl = 'Y';
      } else {
        this.formBasicInfo.viewFl = 'N';
      }
    },
	},
  created() {
    this.getDayTypeList();
  },
};
</script>
<style lang="scss" scoped>
.table_form tbody td {
  padding: 5px 20px;
}
</style>
